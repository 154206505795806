Lmit.Wise.Data = function(config) {
    this.config = config;
    this.state = 'started';
    this.index = null;
    this.name = this.config.name;
    this.code = this.config.code || Lmit.Utils.generateCode();
    this.operation = this.config.operation || this.config.id;
    this.indicator_id = this.config.id;
    this.kind = this.config.kind;
    this.uri = this.config.url;
    this.analyze = null;
    this.heatmap = null; // heatmap data configuration parameters
    this.mode = this.config.mode || 'realtime';
    this.period = config.period;
    this.analyze = config.analyze;
    this.heatmap = config.analyze;
    this.wrapper = null;
    this.tariff_id = config.tariff_id;
    this.resolution = config.resolution;
    this.attributes = config.attributes || {};
    this.observers = null;
    this.value = null;
    this.request_id = null;
    this.unit = config.unit;
    this.isHomologous = config.isHomologous;
    this.init();
};

Lmit.Wise.Data.prototype = {

    init: function() {
        this.observers = [];
        if (this.config.observers) {
            this.config.observers.forEach(observer => this.register(observer));
        }
    },

    register: function(item) {
        this.observers.push(item);
        return true;
    },

    notifyObservers: function(message) {
        this.observers.forEach(observer => observer.onDataUpdate(message, this.value, this.code));
    },

    getWrapperForAttribute: function(_attr) {
        let wop = false;
        _.each(this.attributes, function(a) {
            if (a[0] === _attr[0]) {
                wop = a;
            }
        });
        return wop;
    },

    attrs: function(args) {
        if (args.mode) {
            this.mode = args.mode;
        }
        if (args.request_id) {
            this.request_id = args.request_id;
        }
        if (args.period && !this.isHomologous) {
            this.period = args.period;
        }
        if (args.resolution) {
            this.resolution = args.resolution;
        }
        if (args.analyze) {
            this.analyze = args.analyze;
        }
        if (args.heatmap) {
            this.heatmap = args.heatmap;
        }
        if (args.ind_id) {
            this.ind_id = args.ind_id;
        }
        this.wrapper = args.wrapper || null;
        return this.fetch();
    },

    dataInfo: function() {
        let info = this.config;
        if (this.index === null || this.index === 0) { // hammer for unit resetting (metadata ratios) - only on the first data set
            this.observers.forEach(observer => {
                if (observer instanceof Lmit.Wise.Chart.Controller) {
                    info.unit = observer.indicatorController.data.unit;
                }
            });
        }
        info.index = this.index;
        info.code = this.code;
        return info;
    },

    parseOperation: function() {
        if (this.wrapper) {
            const wop = this.getWrapperForAttribute(this.wrapper);
            if (wop) {
                return '(' + this.operation + ')/' + wop[1];
            } else {
                this.notifyObservers('insufficient_data');
                return false;
            }
        }
        return this.operation;
    },

    fetch: function() {
        return new Promise((resolve, reject) => {
            const operation = this.parseOperation();
            //only do the operation if it has operations on
            if (!operation) {
                return reject();
            }

            const timezone = WiseMetering.indicators.get(this.ind_id || this.indicator_id).timezone();

            // TODO: try to make sense of this
            const from = this.period.from && !this.isHomologous ? ParamsService.from : this.period.from;
            const to = this.period.to && !this.isHomologous ? ParamsService.to : this.period.to;

            let request = {
                from: moment.tz(from, 'YYYY.MM.DD', timezone).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                mode: this.mode,
                to: moment.tz(to, 'YYYY.MM.DD', timezone).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            };

            if (this.mode === 'analysis' && this.analyze) {
                request.average = this.analyze[1] === 'true';
                request.grouped_by = this.analyze[0].join(',');
            } else {
                request.components = 'false';
                request.rate = ParamsService.rate;
            }

            this.notifyObservers('sent');

            if (this.kind === 'cost') {
                request = {
                    rate: ParamsService.rate,
                    from: ParamsService.from.replace(/\./g, '-'),
                    to: ParamsService.to.replace(/\./g, '-')
                };

                const successFn = response => {
                    let data = response.total;
                    if ([900, 3600].includes(request.rate)) {
                        const from = moment.tz(ParamsService.from, 'YYYY.MM.DD', timezone).unix();

                        data = data.map((value, index) => [from + index * request.rate, value]);
                    }

                    let result = {};
                    result[this.dataInfo().code] = {
                        data,
                        from: request.from,
                        start: request.from,
                        to: request.to,
                        end: request.to,
                        rate: request.rate,
                        info: this.dataInfo(),
                        args: request,
                        request_id: this.request_id
                    };
                    this.value = result;
                    this.notifyObservers('success');
                    return resolve();
                };

                const indicator = WiseMetering.indicators.get(this.config.operation);

                // Get data BY HAND!
                $.ajax({
                    url: `/indicators/${indicator.id}/costs`,
                    type: 'GET',
                    data: request,
                    success: successFn
                });
            } else {
                $.ajax({
                    url: `/indicators/${this.config.id}/data`,
                    type: 'GET',
                    async: true,
                    data: request,
                    contentType: 'application/json',
                    dataType: 'json'
                })
                .done(result => {
                    if (request.mode === 'realtime' && Array.isArray(result.data)) {
                        if ([900, 3600].includes(request.rate)) {
                            result.data = result.data.map(object => object['v']);
                        } else {
                            result.data = result.data.map(object => [object['t'], object['v']]);
                        }
                    }
                    const results = {
                        [this.dataInfo().code]: result
                    };
                    Object.keys(results).forEach(key => {
                        results[key].info = this.dataInfo();
                        results[key].args = request;
                        results[key].request_id = this.request_id;
                        results[key].isHomologous = this.isHomologous;
                    });
                    this.value = results;
                    this.notifyObservers('success');
                    return resolve();
                })
                .fail(data => {
                    this.notifyObservers('error', data);
                    return reject();
                });
            }
        });
    }
};
