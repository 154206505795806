WiseMetering.Views.ZoneConfigStatus = Backbone.Marionette.Layout.extend({
    template: 'templates/zones/config_status',

    events: {
        'click div#edit_details': 'editDetails',
        'click .edit-panorama': 'editPanorama'
    },

    modelEvents: {
        'change': 'render'
    },

    serializeData: function() {
        return {
            status: this.model.get('configuration_status').value.toFixed(0),
            missingConfigurations: this.model.get('configuration_status').missing_configurations,
            isActive: this.model.get('active'),
            isActivated: this.model.isActivated()
        };
    }
});

