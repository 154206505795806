module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<b>' +
((__t = ( month )) == null ? '' : __t) +
' ' +
((__t = ( year )) == null ? '' : __t) +
'</b>\n<br>\n<span style="font-weight:bold; color:' +
((__t = ( colors[1] )) == null ? '' : __t) +
'">Contracted</span> ' +
((__t = ( requestedValue )) == null ? '' : __t) +
'\n';
 if (realValue) { ;
__p += '\n    <br>\n    <span style="font-weight:bold; color:' +
((__t = ( colors[0] )) == null ? '' : __t) +
'">Maximum Measured</span> ' +
((__t = ( realValue )) == null ? '' : __t) +
'\n';
 } ;
__p += '\n';
 if (percentage) { ;
__p += '\n    <span style="font-weight:bold; color:' +
((__t = ( positive ? WiseMetering.SemaphorColors.red : WiseMetering.SemaphorColors.green )) == null ? '' : __t) +
'">\n    (' +
((__t = ( positive ? '+' : '' )) == null ? '' : __t) +
'' +
((__t = ( percentage )) == null ? '' : __t) +
')\n</span>\n';
 } ;
__p += '\n';

}
return __p
}