Lmit.Wise.Chart.Defaults = {
    defaultPlotColors: [
      "#224c4f", //dark green
      "#8bc53f", //green
      "#f59d24", //orange
      "#6eb3e3", //blue
      "#c974ad", //magenta
      "#f5d124", //yellow
      "#e94547", //red
      "#797979", //mid grey
      "#000000", //black
      "#4d7d9e", //dark blue
      "#688715", //dark green
      "#A33031", //dark red
      "#d8d8d8", //light grey
      "#99c9eb", //light blue
    ],
    defaultPieChartColors: [
      "#c0392b",//normal
      "#2980b9",
      "#1ABC9C",
      "#27AE60",
      "#F1C40F",
      "#E67E22",
      "#95A5A6",
      "#34495E",
      "#e6b0aa", //lighter
      "#a9cce3",
      "#a3e4d7",
      "#a9dfbf",
      "#f9e79f",
      "#f5cba7",
      "#D5DBDB",
      "#ABB2B9",
      "#641e16", //darker
      "#154360",
      "#0e6251",
      "#145a32",
      "#7d6608",
      "#784212",
      "#4D5656",
      "#1B2631",
    ],
    minutes:           ["00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"],
    hour:              ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
    month:             ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    day_of_week:       ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    day_type:          ["Weekday", "Saturday", "Sunday", "Holidays"],
    day_of_month:      ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
    year:              ["2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"]
};
