WiseMetering.Model.Etl = WiseMetering.Model.extend({
    urlRoot: '/etls',

    // http://stackoverflow.com/a/9402377/1787187
    activate: function(opts) {
        let options = { url: this.url() + '/activate', type: 'POST' };
        _.extend(options, opts);
        return (this.sync || Backbone.sync).call(this, null, this, options);
    },

    disable: function(opts) {
        let options = { url: this.url() + '/disable', type: 'POST' };
        _.extend(options, opts);
        return (this.sync || Backbone.sync).call(this, null, this, options);
    },

    getHumanEtlRate: function(seconds) {
        let days = Math.floor(seconds / (3600 * 24));
        let hours = Math.floor(seconds % (3600 * 24) / 3600);
        let minutes = Math.floor(seconds % 3600 / 60);

        let dayText = days > 0 ? (days === 1 ? ` ${i18next.t('common.day')}` : days + i18next.t('common.days')) : null;
        let hourText = hours > 0 ? (hours === 1 ? ` ${i18next.t('common.hour')}` : `${hours} ${i18next.t('common.hours')}`) : null;
        let minuteText = minutes > 0 ? (minutes === 1 ? ` ${i18next.t('common.minute')}` : `${minutes} ${i18next.t('common.minutes')}`) : null;

        return i18next.t('etls.rate_recursion', { rate: (dayText || hourText || minuteText) });
    },

    indicators: function() {
        return WiseMetering.indicators.where({ etl_id: this.id });
    }
});
