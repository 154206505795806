module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="accordion-group">\n    <div class="accordion-heading">\n        <div class="accordion-toggle">\n            <div class="svg_icon">\n                ' +
((__t = ( WiseMetering.icon('indicators') )) == null ? '' : __t) +
'\n            </div>\n            ' +
((__t = ( i18next.t('glossary.indicators') )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <div class="accordion-body accordion-body-fix">\n        <div class="accordion-inner" id="indicators"></div>\n    </div>\n</div>\n';

}
return __p
}