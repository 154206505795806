module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="grp_ctrl wmui-chart-toolbar" style="border-width:1px 0; border-style:solid; border-color:rgb(227, 227, 227); border-left:0; margin:10px; width: auto;">\n    <div class="wmui-chart-control-date_time">\n        <ul class="ctrl date_time-selector">\n            <li class="label">From:</li>\n            <li class="field border">\n                <label>\n                    <input class="date_time_from" maxlength="10" name="date-time-from" type="text"/>\n                </label>\n            </li>\n            <li class="label">To:</li>\n            <li class="field border">\n                <label>\n                    <input class="date_time_to" maxlength="10" name="date-time-to" type="text"/>\n                </label>\n            </li>\n            <li>\n                <div class="submit-time wmui-btn" style="margin: 0 5px">Ok</div>\n            </li>\n        </ul>\n    </div>\n    <div class="wmui-chart-control-tariff" id="select-tariff-section" style="float:right">\n        <ul class="ctrl" style="display:flex;align-items:center">\n            <li class="label">' +
((__t = ( i18next.t('glossary.tariff') )) == null ? '' : __t) +
':</li>\n            <li class="border">\n                <select class="select_tariff" id="select-tariff" style="font-size:80%;"></select>\n            </li>\n        </ul>\n    </div>\n    <div class="costs-loader" style="padding:0"></div>\n</div>\n<div class="tables-wrapper">\n\n\n<div class=\'half-size-table cost\'>\n    <table class="dataTable" id="costs-table">\n        <div id="costs-title" class="half-table-title-wrapper"></div>\n        <thead role="row">\n        <tr>\n            <th class="ui-state-default sorting_disabled">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.component') )) == null ? '' : __t) +
'\n                </div>\n            </th>\n            <th class="ui-state-default sorting_disabled align-right">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.consumption') )) == null ? '' : __t) +
'\n                </div>\n            </th>\n            <th class="ui-state-default sorting_disabled align-right">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
'\n                </div>\n            </th>\n        </tr>\n        </thead>\n        <tbody></tbody>\n    </table>\n</div>\n<div class=\'half-size-table simulate\'>\n    <table class="dataTable" id="simulate-table">\n        <div id="simulate-title" class="half-table-title-wrapper"></div>\n        <thead role="row">\n        <tr>\n            <th class="ui-state-default sorting_disabled">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.component') )) == null ? '' : __t) +
'\n                </div>\n            </th>\n            <th class="ui-state-default sorting_disabled align-right">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.consumption') )) == null ? '' : __t) +
'\n                </div>\n            </th>\n            <th class="ui-state-default sorting_disabled align-right">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
'\n                </div>\n            </th>\n        </tr>\n\n        </thead>\n        <tbody></tbody>\n    </table>\n</div>\n</div>\n';

}
return __p
}