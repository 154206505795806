// Wise Indicator
Lmit.Wise.Chart.Control.Event = function(controller, container, options) {
    this.options = options;
    this.controller = controller;
    this.container = container;
    this.view = null;
    this.code = Lmit.Utils.generateCode();
    this.active = false;
    this.init();
};

Lmit.Wise.Chart.Control.Event.prototype = {
    init: function() {
        this.view = $('<div>').addClass('wmui-chart-control-event');
        this.container.append(this.view);
        this.events = [
            { mode: 'building', iconName: 'buildings', title: i18next.t('glossary.buildings') },
            { mode: 'indicator', iconName: 'indicators', title: i18next.t('glossary.indicators') }
        ];
        if (WiseMetering.currentUser.get('manager')) {
            this.events.push({ mode: 'add', iconName: 'add', title: i18next.t('common.add') });
        }
        this.build();
    },

    idle: function() {
        this.view.fadeOut(400);
    },

    enable: function() {
        this.view.fadeIn(400);
    },

    build: function() {
        const ctrl = $('<ul>').addClass('ctrl');
        ctrl.append($('<li>').addClass('label').text(i18next.t('glossary.markers') + ':'));
        this.events.forEach(function(item) {
            const eventCtrl = $(`<li title="${item.title.capitalize()}">`).addClass('icon mode').attr({ 'data-mode': item.mode }).append(WiseMetering.icon(item.iconName));
            ctrl.append(eventCtrl);
            this.bindItemEvent(eventCtrl, this);
        }.bind(this));
        this.view.append(ctrl);
    },

    bindItemEvent: function(item, ref) {
        const that = this;
        item.click(function() {
            if ($(this).attr('data-mode') === 'add') {
                new WiseMetering.Views.NewMarker({
                    model: new WiseMetering.Model.Marker,
                    indicator_id: ref.controller.uid
                });
            } else {
                const isCurrent = $(this).hasClass('current');
                $('div.wmui-chart-control-event > ul.ctrl > li.mode.current').removeClass('current');
                that.hide();

                if (isCurrent) {
                    $(this).removeClass('current');
                    that.active = false;
                    that.hide();
                } else {
                    $(this).addClass('current');
                    that.active = true;
                    that.show($(this).attr('data-mode'));
                }
            }
        });
    },

    show: function(context) {
        this.fetch(context);
    },

    hide: function() {
        this.removePlot();
        if (this.controller.plotBands.length > 0) {
            this.controller.chart.removePlotBand('_event_period_');
            $.mbx.close();
        }
    },

    fetch: function(mode) {
        if (this.active) {
            if (!mode) {
                mode = $('div.wmui-chart-control-event > ul.ctrl > li.mode.current').attr('data-mode');
            }

            const
                from = this.controller.period.from.getTime() / 1000,
                to = this.controller.period.to.getTime() / 1000,
                indicator = WiseMetering.indicators.get(this.options.id),
                buildingId = indicator.get('building_id');

            if (mode === 'building' && !buildingId) {
                return;
            }

            let markers = WiseMetering.markers.filter(function(marker) {
                if (marker.get('state') === 'discarded') {
                    return false;
                }

                if (marker.get('marker_at') < from || marker.get('marker_at') > to) {
                    return false;
                }

                if (mode === 'building' && marker.get('building_id') === buildingId) {
                    return true;
                }

                return mode === 'indicator' && marker.get('indicator_id') === indicator.id;
            });

            this.processDataAndPlot(markers);
        }
    },

    processDataAndPlot: function(markers) {
        const params = {
            type: 'scatter',
            name: 'Events',
            id: this.code,
            code: this.code,
            allowPointSelect: false,
            kind: 'event',
            color: '#8BC53F',
            yAxis: this.controller.chart.chart.get('event').options.index,
            showInLegend: false,
            data: []
        };

        markers.forEach(function(marker) {
            const markerAttributes = {
                name: marker.get('name'),
                from: WiseMetering.utils.formatDate(marker.get('marker_at')),
                id: marker.id,
                d: marker.get('end_date') ? marker.get('end_date') * 1000 : null,
                y: 0,
                x: marker.get('marker_at') * 1000,
                hasMeasurableImpact: !!marker.get('reference_period_start')
            };

            if (marker.get('reference_period_start')) { // impact measurement
                markerAttributes.referencePeriodStart = marker.get('reference_period_start') * 1000;
                markerAttributes.referencePeriodEnd = marker.get('reference_period_end') * 1000;
            }

            params.data.push(markerAttributes);
        });

        this.plot(params);
    },

    plot: function(params) {
        this.controller.chart.chart.addSeries(params);
    },

    removePlot: function() {
        this.controller.chart.removeSeriesById(this.code);
    },

    chart: function() {
        return this.controller.chart.chart;
    }

};
