module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<input id=\'indicator-operation-value\' name=\'indicator[operation]\' type=\'hidden\' value=\'' +
((__t = ( operation )) == null ? '' : __t) +
'\'>\n<dt>\n    <label for="indicators-list">' +
((__t = ( i18next.t('glossary.indicators') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'indicators-list\'></select>\n</dd>\n';

}
return __p
}