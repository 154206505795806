module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class="stepped zero">\n    <div>\n        ';
 if (WiseMetering.userPreferences.language() === 'pt') { ;
__p += '\n            <p>\n                Pode fazer o ‘download’ de um ficheiro de exemplo <a style=\'font-weight:bold;color:#8bc53f\' href=\'' +
((__t = ( WiseMetering.apiUrl )) == null ? '' : __t) +
'/static/example.csv\'>aqui</a>.\n            </p>\n            <b>Notas importantes:</b>\n            <ul>\n                <li>\n                    - Só pode carregar ficheiros CSV ou ZIP.\n                </li>\n                <li>\n                    - O tamanho máximo do ficheiro é de <b>128 MB</b>.\n                </li>\n                <li>\n                    - Nenhum indicador pode existir no edifício antes de importar o ficheiro.\n                </li>\n                <li>\n                    - Todas as datas devem estar no formato <b>AAAA-MM-DD HH:MM</b>.\n                </li>\n                <li>\n                    - Todos os valores devem ser <b><span style="color: #e94547;">valores de contagens</span></b>, não valores de consumo.\n                </li>\n                <li>\n                    - Todos os valores devem estar em unidades SI (Wh, litro, etc).\n                </li>\n            </ul>\n        ';
 } else if (WiseMetering.userPreferences.language() === 'es') { ;
__p += '\n            <p>\n                Puedes descargar un archivo de ejemplo <a style=\'font-weight:bold;color:#8bc53f\' href=\'' +
((__t = ( WiseMetering.apiUrl )) == null ? '' : __t) +
'/static/example.csv\'>aquí</a>.\n            </p>\n            <b>Notas importantes</b>\n            <ul>\n                <li>\n                    - Solo puedes cargar archivos CSV o ZIP.\n                </li>\n                <li>\n                    - El tamaño máximo del archivo es de <b>128 MB</b>.\n                </li>\n                <li>\n                    - No puede existir ningún indicador en el edificio antes de importar el archivo.\n                </li>\n                <li>\n                    - Todas las fechas deben estar en el formato <b>AAAA-MM-DD HH:MM</b>.\n                </li>\n                <li>\n                    - Todos los valores deben ser <b><span style="color: #e94547;">valores de conteo</span></b>, no valores de consumo.\n                </li>\n                <li>\n                    - Todos los valores deben estar en unidades SI (Wh, litro, etc.).\n                </li>\n            </ul>\n        ';
 } else { ;
__p += '\n            <p>\n                You can download a sample file <a style=\'font-weight:bold;color:#8bc53f\' href=\'' +
((__t = ( WiseMetering.apiUrl )) == null ? '' : __t) +
'/static/example.csv\'>here</a>.\n            </p>\n            <b>Important Notes:</b>\n            <ul>\n                <li>\n                    - You can only upload CSV or ZIP files.\n                </li>\n                <li>\n                    - The maximum file size is <b>128 MB</b>.\n                </li>\n                <li>\n                    - No indicator can exist in the building before importing the file.\n                </li>\n                <li>\n                    - All dates must be in the format <b>YYYY-MM-DD HH:MM</b>.\n                </li>\n                <li>\n                    - All values must be <b><span style="color: #e94547;">counter values</span></b>, not consumption values.\n                </li>\n                <li>\n                    - All values must be in SI units (Wh, liter, etc.).\n                </li>\n            </ul>\n        ';
 } ;
__p += '\n        <br/>\n        <div id="errors" style="color: red; max-height: 120px; overflow-y: auto;"></div>\n    </div>\n\n    <dt>\n        <label for="building">Building</label>\n    </dt>\n    <dd>\n        <select class=\'text\' id=\'building\'>\n            <option value="">' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n            ';
 _.each(buildings, function(building) { ;
__p += '\n                <option value="' +
((__t = ( building.id )) == null ? '' : __t) +
'">' +
((__t = ( building.name )) == null ? '' : __t) +
'</option>\n            ';
 }) ;
__p += '\n        </select>\n    </dd>\n\n    <dt>\n        <label for="indicator-kind">Indicator Kind</label>\n    </dt>\n    <dd>\n        <select class=\'text\' id=\'indicator-kind\'>\n            <option value="">' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n            ';
 _.each(indicatorKinds, function(indicatorKind) { ;
__p += '\n                <option value="' +
((__t = ( indicatorKind.slug )) == null ? '' : __t) +
'">' +
((__t = ( indicatorKind.name )) == null ? '' : __t) +
'</option>\n            ';
 }) ;
__p += '\n        </select>\n    </dd>\n\n    <dt>\n        <label for="ratio">Indicator Ratio</label>\n    </dt>\n    <dd>\n        <input class="text" id="ratio" type="number" value="1"/>\n    </dd>\n\n    <dt>\n        <label>File</label>\n    </dt>\n    <dd>\n        <input accept=".csv,.zip" class="text" id="file" style="width: calc(100% - 5px);" type="file"/>\n    </dd>\n</dl>\n';

}
return __p
}