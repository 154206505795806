WiseMetering.Views.NewInvoice = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/invoices/new',
    className: 'mbx-info text-left',
    form_prefix: 'invoice',
    title: () => i18next.t('modal.title.invoices.new'),

    ui: {
        consumption: 'input#invoice-consumption',
        cost: 'input#invoice-cost',
        from: 'input#invoice-from',
        to: 'input#invoice-to',
        uploadFile: '#upload-file'
    },

    events: {
        'change input#invoice-from': 'onChangeFrom'
    },

    afterSave: function() {
        if (this.model.file) {
            let formData = new FormData();
            formData.append('file', this.model.file);
            this.collection.get(this.model.id).uploadFile(formData).fail((response) => {
                let message = JSON.parse(response.responseText)?.errors?.file || i18next.t('invoices.failed_to_update');
                WiseMetering.layout.showTipper('error', message, 5000);
            });
        }
    },

    getFormData() {
        let data = this.formSerializer();

        if (this.model.unit() === 'kWh') {
            data.consumption = data.consumption * 1000;
        }

        this.model.file = this.ui.uploadFile.prop('files')[0];

        return data;
    },

    onChangeFrom() {
        let from = moment(this.ui.from.val()),
            to;

        if (from.date() === 1) {
            to = from.endOf('month').format('YYYY-MM-DD');
        } else {
            to = new Date(from.year(), from.month() + 1, from.date() - 1);
        }

        this.ui.to.datepicker('setDate', to);
    },

    onRender: function() {
        this.ui.from.datepicker(this._datePickerAttributes());
        this.ui.to.datepicker(this._datePickerAttributes());
        this.ui.cost.attr('placeholder', i18next.t('invoices.please_insert_cost', { currency: WiseMetering.getCurrency() }));
    },

    _datePickerAttributes: function() {
        return {
            dateFormat: 'yy-mm-dd',
            changeDay: true,
            changeMonth: true,
            changeYear: true
        };
    }
});
