module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('companies') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('glossary.company') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                <div class=\'per-box\'>\n                    <dl>\n                        <dt>ID:</dt>\n                        <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
':</dt>\n                        <dd>\n                            <a href=\'#\' class=\'link company\'>' +
((__t = ( name )) == null ? '' : __t) +
'</a>\n                        </dd>\n                        <dt>' +
((__t = ( i18next.t('common.address') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( address )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('common.vat_number') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( vat )) == null ? '' : __t) +
'</dd>\n                    </dl>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}