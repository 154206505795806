WiseMetering.Views.IndexWeatherApiIntegration = WiseMetering.Views.Content.extend({
    icon: 'hub',
    name: () => 'Weather API',

    createTable: function() {
        this.table = new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: WiseMetering.indicators.weatherApiIndicators(),

            modelSerializer: function(model) {
                let color = 'orange', title = i18next.t('ui.table.unused');
                if (model.get('used')) {
                    color = 'green';
                    title = i18next.t('ui.table.in_use');
                } else if (model.hasFolder()) {
                    color = 'blue';
                    title = i18next.t('ui.table.used_in_folder');
                }
                const used = { color, title };

                let type = model.get('type');
                if (type === 'complex') {
                    type = `${type} (${model.get('operation_type')})`;
                }

                return {
                    building: model.building()?.get('name') || null,
                    id: model.id,
                    kind: model.kind().get('name'),
                    name: model.get('name'),
                    type,
                    updated_at: WiseMetering.utils.formatDate(model.get('updated_at')),
                    used
                };
            },
            columns: {
                used: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[color],
                        title: title.capitalize()
                    }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                kind: i18next.t('glossary.indicator_kind'),
                building: i18next.t('glossary.building'),
                updated_at: i18next.t('glossary.last_change')
            },
            id: 'integration_services',
            toolbar_actions: {
                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => new WiseMetering.Views.IndicatorModalDetails({ model })
                },
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
    },

    onShow: function() {
        this.listenTo(WiseMetering.integrationServices, 'add', this.createTable.bind(this));
        this.createTable();
    },

    updateFields: function() {
    }
});
