import navigate from '@/utils/navigate';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import React from 'react';

const Component = props => {
    const { scheduled, total } = props.data;

    return (
        <Card>
            <CardContent sx={{ height: 260 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Reports
                </Typography>
                <Typography variant="h5" component="div">
                    Total: {total}
                </Typography>
                <br/>
                <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    Scheduled: {scheduled}
                </Typography>
            </CardContent>
            <CardActions sx={{ height: 20 }}>
                <Button onClick={() => navigate('/reports')}>View Reports</Button>
            </CardActions>
        </Card>
    );
};

export default Component;
