module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (tiers) { ;
__p += '\n<div class="btn-group btn-group-' +
((__t = ( object )) == null ? '' : __t) +
'" role="group" aria-label="...">\n    ';
 _.each(tiers, function(tier) { ;
__p += '\n        <button type="button" class="btn btn-tiers btn-' +
((__t = ( tier.name )) == null ? '' : __t) +
'" value="' +
((__t = ( tier.id )) == null ? '' : __t) +
'">\n            ' +
((__t = ( tier.name )) == null ? '' : __t) +
'\n        </button>\n    ';
 }); ;
__p += '\n</div>\n';
 } else { ;
__p += '\n    <div>' +
((__t = ( i18next.t('organizations.no_type_selected') )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n';

}
return __p
}