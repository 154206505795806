WiseMetering.Views.MapSearch = Backbone.Marionette.ItemView.extend({
    MAX_RESULTS: 10,
    events: {
        'keyup input': 'search',
        'click .buildings-table': 'navigate',
        'click .result': 'navigate'
    },
    template: 'templates/ui/map/search',
    timeout: null,
    ui: {
        buildingsTable: '.buildings-table',
        results: '.results',
        searchBox: 'input',
        searchIcon: '.icon-searching',
        searchSummary: '.search-summary'
    },

    navigate: function(event) {
        Backbone.history.navigate(event.currentTarget.dataset.href, { trigger: true });
    },

    onShow: function() {
        window.MapService.events.on('map:search:end', this.showResults.bind(this));
        this.search();
        this.showResults();
    },

    search: function() {
        const query = this.ui.searchBox.val() || '';
        this.ui.searchSummary.hide();
        this.ui.searchIcon.show();
        window.MapService.events.trigger('map:search:start', query);
    },

    serializeData: function() {
        return {
            MAX_RESULTS: this.MAX_RESULTS,
            filter: window.MapService.filter
        };
    },

    showResults: function() {
        this.ui.results.empty();
        this.ui.searchIcon.hide();

        if (window.MapService.filter === '') {
            this.showSummary(this.collection.length);
            return;
        }

        const buildingIds = [...window.MapService.buildingIds];
        this.showSummary(buildingIds.length);

        for (let i = 0; i < buildingIds.length && i < this.MAX_RESULTS; i++) {
            const building = this.collection.get(buildingIds[i]);
            this.ui.results.append(JST['templates/ui/map/result']({
                address: building.get('address').full_address,
                href: building.nodeUrl(),
                name: building.get('name'),
                tags: building.get('tag_names').join(', ')
            }));
        }
    },

    showSummary: function(length) {
        let i18key = length === 1 ? 'glossary.building' : 'glossary.buildings';
        this.ui.searchSummary.html(`${length} ${i18next.t(i18key)}`).show();
    }
});
