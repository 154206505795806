module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="mbx-info text-left">\n    <dl class="stepped zero">\n        <dt>\n            <label for="contract-name">' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'&nbsp;*</label>\n        </dt>\n        <dd>\n            <input class="text" id="contract-name" name="contract[name]" type="text">\n        </dd>\n        <dt>\n            <label for="contract-tariff">' +
((__t = ( i18next.t('glossary.tariff') )) == null ? '' : __t) +
'&nbsp;*</label>\n        </dt>\n        <dd>\n            <select class="text" id="contract-tariff" name="contract[tariff_id]"></select>\n        </dd>\n        <dt>\n            <label for="contract-start">' +
((__t = ( i18next.t('common.start') )) == null ? '' : __t) +
'&nbsp;*</label>\n        </dt>\n        <dd>\n            <input class="text" id="contract-start" name="contract[start]" type="date">\n        </dd>\n        <dt>\n            <label for="contract-finish">' +
((__t = ( i18next.t('common.finish') )) == null ? '' : __t) +
'&nbsp;*</label>\n        </dt>\n        <dd>\n            <input class="text" id="contract-finish" name="contract[finish]" type="date">\n        </dd>\n        <div id="contract-extra"></div>\n    </dl>\n</div>\n';

}
return __p
}