WiseMetering.Views.ZoneAdd = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/zones/add',
    className: 'mbx-info text-left',
    form_prefix: 'zone',
    title: () => i18next.t('modal.title.zones.new'),

    serializeData: function() {
        const json = this.model.toJSON();
        json.isBuilding = this.model.isBuilding();
        return json;
    },

    onRender: function() {
        const type = this.model.isBuilding() ? 'building' : 'zone';
        this.$('select#zone_category_id').append(
            WiseMetering.utils.optionsFromModels(
                WiseMetering.zoneCategories.where({ [type]: true }),
                'name',
                this.model.get('category_id')
            )
        );
        if (this.model.isBuilding()) {
            this.$('select#zone_tariff_id').append(
                WiseMetering.utils.optionsFromModels(
                    WiseMetering.tariffs.models,
                    'name',
                    this.model.get('tariff_id')
                )
            );
        }
    }
});
