WiseMetering.Views.NewElectricityMapsEtl = Backbone.Marionette.ItemView.extend({
    template: 'templates/etls/types/electricity_maps',

    events: {
        'change #etl_extra': 'updateDescription'
    },

    onRender: function() {
        this.updateDescription();
    },

    filterIndicators: function() {
        return WiseMetering.indicators.filter(function(indicator) {
            if (!indicator.get('building_id')) {
                return false;
            }

            const
                slug = indicator.get('kind_slug'),
                timezone = indicator.building()?.get('timezone');

            return slug === 'active_energy' && timezone === 'Europe/Lisbon';
        });
    },

    updateDescription: function() {
        const
            selectedType = this.$('#etl_extra').val(),
            description = i18next.t(`integration_partners.partners.electricity_maps.${selectedType}_description`),
            title = i18next.t(`integration_partners.partners.electricity_maps.${selectedType}_title`),
            warningContainer = this.$('#etl_extra_container'),
            warningElement = this.$('#etl_extra_warning');

        if (selectedType == 'direct' && warningElement.length === 0){
            const warningText = i18next.t(`integration_partners.partners.electricity_maps.warning`);

            warningContainer.prepend(`<p id="etl_extra_warning" class="modal-description-warning">${warningText}</p>`);
        } else {
            warningElement.remove();
        }

        this.$('#title_text').text(title);
        this.$('#etl_extra_description').text(description);
    },

    serializeData: function() {
        const indicators = this.filterIndicators();

        return {
            indicators: _.groupBy(indicators, indicator => indicator.get('building_name')),
            rate: WiseMetering.etlRates[0],
            start_at: '2010-01-01',
            username: 'electricity_maps',
            secret: 'electricity_maps',
            mode: 'local'
        };
    }
});
