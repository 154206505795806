import { cn } from '@/utils';
import * as React from 'react';

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
    return (
            (<input
                    type={type}
                    className={cn(
                            'tw-flex tw-h-10 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium file:tw-text-foreground placeholder:tw-text-muted-foreground focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50',
                            className
                    )}
                    ref={ref}
                    {...props} />)
    );
});
Input.displayName = 'Input';

export { Input };
