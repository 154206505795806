WiseMetering.Views.MarkersTabs = WiseMetering.Views.Content.extend({
    icon: 'markers',

    name: function() {
        return i18next.t('glossary.markers');
    },

    tabs: function() {
        return [
            {
                id: 'pending',
                name: i18next.t('states.pending'),
                view: WiseMetering.Views.IndexPending
            },
            {
                id: 'active',
                name: i18next.t('states.active'),
                active: true,
                view: WiseMetering.Views.IndexActive
            },
            {
                id: 'discarded',
                name: i18next.t('states.discarded'),
                view: WiseMetering.Views.IndexDiscarded,
                dropdownTab: true
            }
        ];
    }
});
