WiseMetering.Model.IntegrationPartner = WiseMetering.Model.extend({
    urlRoot: '/integration_partners',

    getJsonFormatted: function() {
        const json = this.toJSON();
        json.description = i18next.t(`integration_partners.partners.${this.get('slug')}.description`) || 'n/a';
        json.benefits = i18next.t(`integration_partners.partners.${this.get('slug')}.benefits`) || 'n/a';
        json.default_tier = this.tiers('default_tier') || i18next.t(`integration_partners.no_default_tier`);
        json.tier_requirement = this.tiers('tier_requirement') || i18next.t(`integration_partners.no_tier_requirement`);
        return json;
    },

    getIndexURL: function() {
        return `/integration_partners/${this.id}/integrations`;
    },

    getClassName: function() {
        let capitalise = word => word.charAt(0).toUpperCase() + word.slice(1);
        return this.get('slug').split('_').map(word => capitalise(word)).join('');
    },

    tiers: function(tier) {
        if (this.get(tier) === 'essentials') {
            return 'Essentials, Standard, Advanced and Premium';
        } else if (this.get(tier) === 'standard') {
            return 'Standard, Advanced and Premium';
        } else if (this.get(tier) === 'advanced') {
            return 'Advanced and Premium';
        } else if (this.get(tier) === 'premium') {
            return 'Premium';
        }
    }
});
