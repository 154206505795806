module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'opportunity-title\'>' +
((__t = ( i18next.t('glossary.title') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'opportunity-title\' name=\'opportunity[title]\'>\n        </dd>\n\n        <dt>\n            <label for=\'opportunity-description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text\' id=\'opportunity-description\' name=\'opportunity[description]\'></textarea>\n        </dd>\n\n        <dt>\n            <label for=\'opportunity-utility-kind\'>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'opportunity-utility-kind\' name=\'opportunity[utility_kind_id]\'>\n                <option value=\'\' selected=\'selected\' disabled>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 availableUtilityKinds.forEach(function (utilityKind) { ;
__p += '\n                    <option value="' +
((__t = ( utilityKind.id )) == null ? '' : __t) +
'">' +
((__t = ( utilityKind.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <div id=\'opportunity-indicator-container\' style=\'display:none\'>\n            <dt>\n                <label for=\'opportunity-indicator\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select id=\'opportunity-indicator\' name=\'opportunity[indicator_id]\' class=\'indicators text\'></select>\n            </dd>\n\n            <dt>\n                <label for=\'opportunity-priority\'>' +
((__t = ( i18next.t('common.priority') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'opportunity-priority\' name=\'opportunity[priority]\'>\n                    <option value=\'1\'>1</option>\n                    <option value=\'2\'>2</option>\n                    <option value=\'3\'>3</option>\n                </select>\n            </dd>\n\n            <dt>\n                <label for=\'opportunity-consumption\'>' +
((__t = ( i18next.t('common.consumption') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'opportunity-consumption\' name=\'opportunity[consumption]\'>\n            </dd>\n\n            <dt>\n                <label for=\'opportunity-cost\'>' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'opportunity-cost\' name=\'opportunity[cost]\'>\n            </dd>\n\n            <dt>\n                <label for=\'opportunity-capex\'>' +
((__t = ( i18next.t('common.capex') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'opportunity-capex\' name=\'opportunity[capex]\'>\n            </dd>\n\n            <dt>\n                <label for=\'upload-file\'>' +
((__t = ( i18next.t('common.file') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' accept=\'.xls, .xlsx, .csv\' id=\'upload-file\' name=\'file\' type=\'file\'/>\n                <span id=\'spinner\' style=\'display:none;color:#224C4F\'>\n                    <i class=\'far fa-spinner fa-spin\'></i> ' +
((__t = ( i18next.t('opportunities.uploading_file') )) == null ? '' : __t) +
'\n                </span>\n            </dd>\n        </div>\n    </dl>\n</form>\n';

}
return __p
}