module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mbx-info green" style="margin: 4px;">\n    Please drag and drop each widget to set the desired order.\n</div>\n<div class="sortable">\n    ';
 widgets.forEach(function(widget) { ;
__p += '\n    <div class="member" data-value="' +
((__t = ( widget.type )) == null ? '' : __t) +
'">\n        <label>\n            ' +
((__t = ( widget.name )) == null ? '' : __t) +
'\n            <input type="checkbox" data-value="' +
((__t = ( widget.type )) == null ? '' : __t) +
'" ' +
((__t = ( widget.active ? 'checked="checked"' : '' )) == null ? '' : __t) +
'/>\n        </label>\n    </div>\n    ';
 }) ;
__p += '\n</div>\n';

}
return __p
}