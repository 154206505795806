module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'basic-widget grid-' +
((__t = ( widgetPreferences.gridSize )) == null ? '' : __t) +
' hidden-' +
((__t = ( widgetPreferences.hidden )) == null ? '' : __t) +
'\' data-id="' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'" id="wrapper-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'">\n    <div class=\'options-region\' id=\'region-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'\'></div>\n    ';
 if(widgetPreferences.hidden){ ;
__p += '\n        <div class="widget-hidden">\n            <div class="widget-hidden-icon">\n                ' +
((__t = ( WiseMetering.icon('hidden') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    ';
 } ;
__p += '\n    <div class=\'title\'>\n        <div class="title-text">\n            ' +
((__t = ( title )) == null ? '' : __t) +
'\n        </div>\n        <span class=\'basic-widget-options\' id=\'options-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'\'>' +
((__t = ( WiseMetering.icon('edit') )) == null ? '' : __t) +
'</span>\n        ';
 if ( useNormalization ) { ;
__p += '\n            <div class=\'basic-widget-normalization\'>' +
((__t = ( i18next.t('glossary.normalization').capitalize() )) == null ? '' : __t) +
':\n                <span id=\'widget-no-normalization\' class="basic-widget-underline"> none</span>\n                <span id=\'widget-normalization\'> m²</span>\n            </div>\n        ';
 } ;
__p += '\n    </div>\n    <div class=\'widget-content\'>\n        <div class=\'chart\' id=\'' +
((__t = ( id )) == null ? '' : __t) +
'\'></div>\n    </div>\n</div>\n';

}
return __p
}