WiseMetering.Views.CardsContainer = Backbone.Marionette.CollectionView.extend({
    tagName: 'div',
    className: 'wmui-card-view',
    itemView: WiseMetering.Views.Card,

    itemViewOptions: function(model) {
        const
            data = {},
            model_info = this.options.card(model);

        for (const [key, value] of Object.entries(model_info)) {
            data[key] = value;
        }

        return { data };
    },

    initialize: function() {
        this.render();
    }
});
