import en from './en.json';
import es from './es.json';
import pt from './pt.json';

const loadLocales = async function(lng) {
    // todo: handle language not in the list

    await i18next
    .init({
        debug: true,
        lng,
        resources: {
            en: { translation: en },
            es: { translation: es },
            pt: { translation: pt }
        }
    });
};

export default loadLocales;
