module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'form_content\' style=\'padding:10px 5px;\'>\n    <form>\n        <dl class=\'stepped\' style=\'padding: 0 !important;\'>\n            <div style=\'float: left; padding-right: 15px;\'>\n                <dt>\n                    <label for=\'alarm_schedule_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <input value="' +
((__t = ( name )) == null ? '' : __t) +
'" maxlength=\'30\' name=\'alarm_schedule[name]\' style=\'width:150px;\'>\n                </dd>\n            </div>\n            <div style=\'float:left; padding-left:25px; border-left:1px solid #CCCCCC;\'>\n                <dt>\n                    <label>' +
((__t = ( i18next.t('glossary.schedule') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <div id=\'schedule\'></div>\n                </dd>\n            </div>\n        </dl>\n    </form>\n</div>\n';

}
return __p
}