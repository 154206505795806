WiseMetering.Views.NewBaseEtl = Backbone.Marionette.ItemView.extend({
    template: 'templates/etls/types/base',

    ui: {
        startAt: '#etl_start_at'
    },

    onRender: function() {
        this.ui.startAt.datepicker({
            dateFormat: 'yy-mm-dd',
            changeDay: true,
            changeMonth: true,
            changeYear: true,
            minDate: moment().add(-1, 'years').startOf('year').format('YYYY-MM-DD')
        });
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.etl_rates = WiseMetering.etlRates.reduce((a, v) => ({ ...a, [v]: this.model.getHumanEtlRate(v) }), {});
        json.start_at = moment().format('YYYY-MM-DD');
        return json;
    }
});
