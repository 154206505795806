WiseMetering.Views.IndexGlobalIndicators = WiseMetering.Views.TableIndex.extend({
    icon: 'global',

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'global-indicators',
            collection: WiseMetering.indicators.globalIndicators(),
            modelSerializer: function(model) {
                const json = model.toJSON();
                json.kind = model.kind().get('name');
                return json;
            },
            columns: {
                name: i18next.t('common.name'),
                type: i18next.t('common.type'),
                kind: i18next.t('glossary.indicator_kind')
            },
            toolbar_actions: {
                show: {
                    view_call: model => new WiseMetering.Views.IndicatorModalDetails({ model })
                },
                open_indicator: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                }
            }
        });
    },

    name: function() {
        return i18next.t('glossary.global_indicators');
    }
});
