WiseMetering.Views.CircuitEdit = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/circuits/edit',
    form_prefix: 'circuit',
    title: () => i18next.t('modal.title.circuits.edit'),
    className: 'mbx-info text-left',
    width: 470,
    ui: {
        actuator: '#circuit-actuator'
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.hasActuators = this.model.building().hasActuators();
        return json;
    },

    onRender: function() {
        const usedActuatorIds = WiseMetering.circuits.pluck('actuator_id').filter(id => id !== this.model.get('actuator_id'));
        const actuators = [];

        WiseMetering.actuators.each(function(actuator) {
            if (!usedActuatorIds.includes(actuator.id) && this.model.get('building_id') === actuator.get('building_id')) {
                actuators.push(actuator);
            }
        }.bind(this));

        this.ui.actuator.append(WiseMetering.utils.optionsFromModels(actuators, 'name', this.model.get('actuator_id')));
    }
});
