WiseMetering.Views.EditScheduledReport = WiseMetering.Views.Modal.Save.extend({
    events: {
        'click #add-notification': 'onAddNotification',
        'click #remove-notification': 'removeNotification'
    },
    form_prefix: 'report',
    template: 'templates/reports/edit',
    title: () => i18next.t('modal.title.scheduled_reports.edit'),
    width: '40%',

    addNotification: function(userId) {
        this.$('#notifications').append(JST['templates/reports/notification']({ userId }));
    },

    getFormData: function() {
        const data = this.formSerializer() || {};
        if (!data.user_ids) {
            data.user_ids = [];
        }
        return data;
    },

    onAddNotification: function() {
        this.addNotification();
    },

    onRender: function() {
        if (!this.model.get('user_ids')) {
            return;
        }

        this.model.get('user_ids').forEach(userId => this.addNotification(userId));
    },

    removeNotification: function(event) {
        event.preventDefault();
        $(event.currentTarget).parent().remove();
    }
});
