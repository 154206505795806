WiseMetering.Views.Ui.Widget.Trend = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/trend',
    colors: WiseMetering.WidgetColors.Default,

    initialize: function(params) {
        params.unit = params.unit ? params.unit : 'Wh';
        WiseMetering.Views.Ui.Widget.Base.prototype.initialize.call(this, params);
    },

    drawChart: function() {
        const
            months = i18next.t('highcharts.shortMonths', { returnObjects: true }),
            now = moment(),
            currentYear = now.year(),
            currentMonth = now.month(),
            { colors, unit, period, data } = this,
            { current, homologous } = data;

        this.chart = new Highcharts.Chart(jQuery.extend(true, {}, this.defaultChartOptions, {
            xAxis: {
                categories: i18next.t('highcharts.shortMonths', { returnObjects: true }),
                lineColor: '#797979'
            },
            yAxis: {
                labels: {
                    formatter: point => formatValue(point.value, unit)
                }
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                useHTML: true,
                labelFormatter: function() {
                    this.legendItem.symbol.hide();

                    const current = period === 'ytd' ? data.current.reduce((acc, value) => acc + value, 0) : data.current[currentMonth],
                        homologous = period === 'ytd' ? data.homologous.reduce((acc, value) => acc + value, 0) : data.homologous[currentMonth];

                    let showDeviation = true;
                    if (period === 'ytd') {
                        showDeviation = data.current.filter(v => v !== null).length === data.homologous.filter(v => v !== null).length;
                    } else if (period === 'mtd') {
                        showDeviation = homologous !== 0;
                    }

                    const deviation = showDeviation ? formatValue((current - homologous) / homologous * 100, '%') : null;

                    return JST['templates/ui/widgets/trend_label']({
                        colors,
                        type: period.toUpperCase(),
                        current: {
                            formatted: formatValue(current, unit),
                            title: currentYear,
                            value: current
                        },
                        deviation: deviation,
                        homologous: {
                            formatted: formatValue(homologous, unit),
                            is_valid: homologous > 0,
                            title: currentYear - 1,
                            value: homologous
                        }
                    });
                }
            },
            tooltip: {
                formatter: function() {
                    const month = months.indexOf(this.x),
                        isCurrentYear = this.series.name === currentYear,
                        currentValue = (isCurrentYear ? current[month] : homologous[month]) || 0,
                        isHomologous = homologous[month] && homologous[month] > 0;

                    let deviation;

                    if (isCurrentYear) {
                        deviation = isHomologous ? (current[month] - homologous[month]) / homologous[month] * 100 : null;
                    }

                    return JST['templates/ui/widgets/trend_tooltip']({
                        color: isCurrentYear ? colors[0] : colors[2],
                        currentValue: formatValue(currentValue, unit),
                        deviation: deviation ? formatValue(deviation, '%') : null,
                        month: this.x,
                        positive: deviation > 0,
                        year: isCurrentYear ? currentYear : currentYear - 1
                    });
                }
            },
            series: [
                {
                    name: currentYear - 1,
                    type: 'column',
                    data: homologous,
                    color: this.colors[2]
                },
                {
                    name: currentYear,
                    type: 'column',
                    data: current,
                    color: this.colors[0],
                    linkedTo: ':previous'
                }
            ]
        }));
    }
});
