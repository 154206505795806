WiseMetering.Views.UtilitiesIndex = WiseMetering.Views.TableIndex.extend({
    icon: 'utilities',
    name: () => i18next.t('glossary.utilities'),

    onRender: function() {
        this.listenTo(WiseMetering.contracts, 'sync', this.onShow);
    },

    createTable: function() {
        const collection = this.model.utilities();

        const table = new WiseMetering.Views.Table({
            id: 'utilities',
            collection: collection,
            modelSerializer: function(model) {
                return {
                    contracts: model.contracts().length,
                    id: model.id,
                    indicator: model.indicator(),
                    name: model.get('name'),
                    updatedAt: WiseMetering.utils.formatDate(model.get('updated_at')),
                    updater: model.updater()?.get('name') || 'System',
                    utilityKind: model.utilityKind().get('name')
                };
            },
            columns: {
                name: i18next.t('common.name'),
                utilityKind: i18next.t('glossary.utility_kind'),
                contracts: i18next.t('glossary.contracts'),
                indicator: {
                    title: i18next.t('glossary.indicator'),
                    mRender: function(indicator) {
                        return `<a id='${indicator.get('id')}' href='${indicator.nodeUrl()}'>${indicator.get('name')}</a>`;
                    }
                },
                updater: i18next.t('glossary.updated_by'),
                updatedAt: i18next.t('glossary.updated_at')
            },
            toolbar_actions: {
                new: {
                    view_call: () => {
                        new WiseMetering.Views.NewUtility({
                            collection: WiseMetering.utilities,
                            model: new WiseMetering.Model.Utility({
                                building_id: this.model.get('building_id')
                            })
                        });
                    }
                },
                edit: {
                    view_call: model => new WiseMetering.Views.EditUtility({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.users.destroy')
                    })
                },
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                }
            }
        });

        this.listenTo(this.model, 'change:children:addUtility', function(node) {
            collection.add(node);
        });

        this.listenTo(this.model, 'change:children:removeUtility', function(node) {
            collection.remove(node);
        });

        return table;
    }
});
