(function ($) {

	$.stt = function(options){
		return $.stt.def.init(options);
	};

	$.stt.def = {
		init: function (options) {
			let std = $('<div>').addClass('stt crn_all none').append(options.text);
			let position = options.target.offset();
			std.append($('<div>').addClass("stt_arr_down").append($("<div>").addClass("stt_arr_down_inner")));
			std.appendTo($('body'));
			std.css({
                left: (position.left - (std.width()/2) - 3) + "px",
                top: (position.top - std.height() - options.target.height()) + "px"
            });
            return this;
		}
	};

})(jQuery);
