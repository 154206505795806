module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'search-box\'>\n    <div class=\'icon-searching\'>\n        <i class=\'far fa-spinner fa-spin\'>&nbsp;</i>\n    </div>\n    <div class=\'search-summary\'></div>\n    <input placeholder=\'' +
((__t = ( i18next.t('ui.map_search.placeholder') )) == null ? '' : __t) +
'\' type=\'search\' value=\'' +
((__t = ( filter )) == null ? '' : __t) +
'\'>\n    <div class=\'results\'></div>\n</div>\n';

}
return __p
}