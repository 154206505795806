WiseMetering.Views.IndexSeusByBuilding = WiseMetering.Views.TableIndex.extend({
    icon: 'seus',
    name: () => i18next.t('glossary.seus'),

    createTable: function createTable() {
        let collection = this.model.seus(),
            table = new WiseMetering.Views.Table({
                id: 'seus',
                collection: collection,
                modelSerializer: function(model) {
                    const json = model.toJSON();
                    json.relevance = `${model.relevance().toFixed(2)}%`;
                    return json;
                },
                columns: {
                    name:  i18next.t('common.name'),
                    indicator_id: {
                        title:  i18next.t('glossary.indicators'),
                        mRender: id => WiseMetering.indicators.get(id).get('name')
                    },
                    variable_id: {
                        title:  i18next.t('seus.independent_variable'),
                        mRender: id => id ? WiseMetering.indicators.get(id).get('name') : null
                    },
                    relevance:  i18next.t('common.relevance'),
                },
                sort: {
                    column: 'from',
                    order: 'desc'
                },
                toolbar_actions: {
                    new: {
                        view_call: () => {
                            let indicators = WiseMetering.indicators.where({ building_id: this.model.building().id});
                            new WiseMetering.Views.AddSeu({
                                collection: WiseMetering.seus,
                                indicators: new WiseMetering.Collection.Indicators,
                                variables: new WiseMetering.Collection.Indicators(indicators),
                                model: new WiseMetering.Model.Seu({ building_id: this.model.building().id })
                            });
                        }
                    },
                    edit: {
                        view_call: model => {
                            new WiseMetering.Views.EditSeu({
                                indicators: new WiseMetering.Collection.Indicators,
                                variables: new WiseMetering.Collection.Indicators(model.indicators()),
                                model
                            })
                        }
                    },
                    delete: {
                        view_call: model => new WiseMetering.Views.DestroySeu({model})
                    },
                    show: {
                        view_call: model => Backbone.history.navigate(model.nodeUrl(), {trigger: true})
                    }
                }
            });

        this.listenTo(this.model, 'change:children:addSeu', function (node) {
            collection.add(node);
        });

        this.listenTo(this.model, 'change:children:removeSeu', function (node) {
            collection.remove(node);
        });

        return table
    }
});
