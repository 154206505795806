module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'map-control-button\' title=\'' +
((__t = ( i18next.t('ui.map_search.center_map') )) == null ? '' : __t) +
'\'>\n    ' +
((__t = ( WiseMetering.icon('center') )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}