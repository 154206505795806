Lmit.Wise.Chart.Control.homologous = function(controller, container, options) {
    this.controller = controller;
    this.container = container;
    this.settings = options.settings;
    this.period = options.period;
    this.showHomologous = 'hide';
    this.view = null;
    this.init();
};

Lmit.Wise.Chart.Control.homologous.prototype = {

    init: function() {
        this.view = $('<div>').addClass('wmui-chart-control-resolution');
        this.container.append(this.view);
        this.build();
    },

    build: function() {
        let element = $('<ul>').addClass('ctrl');
        element.append($('<li>').addClass('label').text(`${i18next.t('common.homologous')}:`));
        const option = $('<li>').addClass('mode').append(WiseMetering.icon('eye'));
        element.append(option);
        this.bindItemEvent(option);
        this.view.append(element);
    },

    bindItemEvent: function(item) {
        item.click(() => {
            const isCurrent = $(item).hasClass('current');
            if (isCurrent) {
                $(item).removeClass('current');
                this.setHomologous('hide');
            } else {
                $(item).addClass('current');
                this.setHomologous('show');
            }
        });
    },

    enable: function() {
        this.view.fadeIn(400);
    },

    idle: function() {
        this.view.fadeOut(400);
    },

    resetView: function() {
        this.view.find('ul').children('li').removeClass('current');
        this.controller.onRemoveHomologous();
        this.showHomologous = 'hide';
    },

    setHomologous: function(type) {
        if (this.showHomologous === type) {
            return;
        }

        this.showHomologous = type;
        if (type === 'show') {
            this.controller.onHomologousUpdate(type, this.getHomologousParams());
        } else {
            this.controller.onRemoveHomologous();
        }
    },

    getHomologousParams: function() {
        return {
            name: `${this.settings.name} [${i18next.t('common.homologous')}]`,
            color: '#8bc53f',
            id: this.settings.id,
            operation: this.settings.operation,
            kind: this.settings.kind,
            url: this.settings.url,
            period: null,
            unit: this.settings.unit,
            attributes: this.settings.attributes,
            isHomologous: true
        };
    }
};
