module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="interval">\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">\n            ' +
((__t = ( i18next.t("common.interval") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("common.from") )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("common.to") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tar[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][start]" type="text" value="' +
((__t = ( interval.start )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class="to" name="tar[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][finish]" type="text" value="' +
((__t = ( interval.finish )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            ' +
((__t = ( i18next.t("tariffs.power_per_day", { currency: WiseMetering.getCurrency() }) )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th>\n            ' +
((__t = ( i18next.t("tariffs.peak_power") )) == null ? '' : __t) +
'\n        </th>\n        <th>\n            ' +
((__t = ( i18next.t("tariffs.required_power") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input name="tar[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][power][peak]" type="text" value="' +
((__t = ( interval.power?.peak )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input name="tar[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][power][contracted]" type="text" value="' +
((__t = ( interval.power?.contracted )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            ' +
((__t = ( i18next.t("tariffs.reactive_per_unit", { currency: WiseMetering.getCurrency() }) )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("glossary.capacitive") )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("glossary.inductive") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input name="tar[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][capacitive]" type="text" value="' +
((__t = ( interval.capacitive )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input name="tar[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][inductive]" type="text" value="' +
((__t = ( interval.inductive )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    ';
 ["summer", "winter"].forEach(function(period) { ;
__p += '\n        <tr>\n            <th class="head" colspan="2">\n                ' +
((__t = ( i18next.t("tariffs.active_per_unit", { currency: WiseMetering.getCurrency() }) )) == null ? '' : __t) +
'\n                - ' +
((__t = ( i18next.t(`common.${period}`).capitalize() )) == null ? '' : __t) +
'\n            </th>\n            <tr>\n                <th>\n                    ' +
((__t = ( i18next.t("widgets.partial_active_costs.cheia") )) == null ? '' : __t) +
'\n                </th>\n                <th>\n                    ' +
((__t = ( i18next.t("widgets.partial_active_costs.ponta") )) == null ? '' : __t) +
'\n                </th>\n            </tr>\n            <tr>\n                <td>\n                    <input name="tar[intervals][' +
((__t = ( period )) == null ? '' : __t) +
'][cheia]" type="text" value="' +
((__t = ( interval[period].cheia )) == null ? '' : __t) +
'">\n                </td>\n                <td>\n                    <input name="tar[intervals][' +
((__t = ( period )) == null ? '' : __t) +
'][ponta]" type="text" value="' +
((__t = ( interval[period].ponta )) == null ? '' : __t) +
'">\n                </td>\n            </tr>\n            <tr>\n                <th>\n                    ' +
((__t = ( i18next.t("widgets.partial_active_costs.supervazio") )) == null ? '' : __t) +
'\n                </th>\n                <th>\n                    ' +
((__t = ( i18next.t("widgets.partial_active_costs.vazio") )) == null ? '' : __t) +
'\n                </th>\n            </tr>\n            <tr>\n                <td>\n                    <input name="tar[intervals][' +
((__t = ( period )) == null ? '' : __t) +
'][supervazio]" type="text" value="' +
((__t = ( interval[period].supervazio )) == null ? '' : __t) +
'">\n                </td>\n                <td>\n                    <input name="tar[intervals][' +
((__t = ( period )) == null ? '' : __t) +
'][vazio]" type="text" value="' +
((__t = ( interval[period].vazio )) == null ? '' : __t) +
'">\n                </td>\n            </tr>\n        </tr>\n    ';
 }); ;
__p += '\n</table>\n';

}
return __p
}