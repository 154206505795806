WiseMetering.Views.CircuitScheduleHistory = Backbone.Marionette.ItemView.extend({
    template: 'templates/circuits/schedules/history',
    ui: {
        schedule: '#schedule',
        versions: '#schedule-versions'
    },
    events: {
        'change #schedule-versions': 'updateSchedule'
    },
    versions: {},

    onRender: function() {
        $.getJSON(`/circuits/${this.model.id}/schedule_versions`, function(data) {
            this.updateVersions(data);
        }.bind(this));
        this.setSchedule(this.model.get('schedule'));
    },

    updateSchedule: function(event) {
        const id = event.target.value;

        if (id === '') {
            this.setSchedule(this.model.get('schedule'));
        } else {
            this.setSchedule(this.versions[id]);
        }
    },

    setSchedule: function(values) {
        let periods = ['off', 'on'];
        if (this.model.get('system_category_name') === 'Lighting') {
            periods = [...periods, 'sun'];
        }

        const view = new WiseMetering.Views.CircuitSchedule({ editable: false, periods, values });

        this.ui.schedule.html(view.el);
    },

    updateVersions: function(data) {
        const timezone = this.model.building().get('timezone');

        data.forEach(function(version) {
            const updater = WiseMetering.users.get(version.created_by);
            let updaterName = 'System';
            if (updater) {
                updaterName = updater.get('name');
            }

            version.name = `Modified @ ${WiseMetering.utils.formatDate(version.created_at, timezone)} by ${updaterName}`;

            this.versions[version.id] = version;
        }.bind(this));

        this.ui.versions.prop('disabled', false).html(WiseMetering.utils.optionsFromArray(data));
    }
});
