WiseMetering.Views.IndexIntegrationPartners = WiseMetering.Views.CardsIndex.extend({
    icon: 'hub',
    name: () => i18next.t('glossary.hub'),
    bannerInfo: () => {
        const org = WiseMetering.currentOrganization;
        if (org.is('premium')) {
            return {
                icon: 'unknown',
                message: i18next.t('organizations.licences_to_unlock', { licenses: org.get('available_integrations') }),
            };
        };
    },

    createCards: function() {
        return new WiseMetering.Views.CardsContainer({
            id: 'integration_partners',
            collection: WiseMetering.integrationPartners,
            card: function(model) {
                const cardInfo = {};

                cardInfo.attributes = [
                    { key: 'description', label: i18next.t('common.description') },
                    { key: 'benefits', label: i18next.t('common.benefits') },
                    { key: 'default_tier', label: i18next.t('integration_partners.default_tier')},
                    { key: 'tier_requirement_name', label: i18next.t('integration_partners.tier_requirement')},
                ];
                cardInfo.image = `/images/integration-partners/${model.get('slug')}.png`;
                cardInfo.linkedin = model.get('linkedin');
                cardInfo.state = {
                    slug: model.get('state'),
                    translated: i18next.t(`states.${model.get('state')}`)
                };
                if (model.get('state') === 'upcoming') {
                    cardInfo.button = null;
                } else {
                    if (WiseMetering.licenses.findWhere({ integration_partner_id: model.id })) {
                        cardInfo.button = {
                            name: 'manage',
                            label: i18next.t('ui.actions.manage'),
                            href: model.getIndexURL(),
                            icon: 'cog'
                        };
                    } else {
                        cardInfo.button = {
                            name: 'activate',
                            label: i18next.t('ui.actions.activate'),
                            href: model.getIndexURL(),
                            icon: 'outlets'
                        };
                    };
                }
                cardInfo.title = model.get('name');
                cardInfo.website = model.get('homepage');

                return cardInfo;
            }
        });
    }
});
