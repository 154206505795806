WiseMetering.Views.Crud.Base = Backbone.Marionette.Layout.extend({
    class: 'crud',
    buttons: [],
    buttonsList: ["activate", "cancel", "edit", "save"],
    title: null,
    template: 'templates/ui/crud/base',

    regions: {
        header: '#crud-header',
        errors: '#crud-errors',
        body: '#crud-body',
        footer: '#crud-footer'
    },

    events: {
        'click #activate': 'activate',
        'click #cancel': 'cancel',
        'click #close': 'close',
        'click #discard': 'discard',
        'click #edit': 'edit',
        'click #no': 'close',
        'click #ok': 'ok',
        'click #open': 'open',
        'click #reset': 'reset',
        'click #save': 'save',
        'click #validate': 'validate',
        'click #yes': 'yes'
    },

    initialize: function(attributes) {
        $.extend(this, attributes);
    },

    attributes: function() {
        return {
            id: 'wm-crud',
            class: 'crud'
        };
    },

    serializeData: function() {
        const leftButtons = this.buttons.filter(btn => !btn.float || btn.float === 'left'),
              rigthButtons = this.buttons.filter(btn => btn.float === 'right');

        return {
            buttonsList: this.buttonsList,
            leftButtons: typeof (this.buttons) === 'function' ? this.buttons() : leftButtons.map(el => el.name),
            rightButtons: rigthButtons.map(el => el.name),
            title: typeof (this.title) === 'function' ? this.title() : this.title
        };
    },

    cancel() {
        Backbone.history.history.back();
    },

    edit: function() {
    },

    save: function() {
    },

    activate: function() {
    }
});
