import * as React from 'react';
import {
    Select as ShadSelect,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/components/ui/select';

export function Select({
    onChange,
    options = [],
    placeholder = "Select an option",
    value
}) {
    return (
        <ShadSelect value={value} onValueChange={onChange}>
            <SelectTrigger className='tw-ml-0 tw-mt-[-8px]'>
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
                {options.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>
                        {label}
                    </SelectItem>
                ))}
            </SelectContent>
        </ShadSelect>
    );
}
