module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<input id=\'indicator-operation\' name=\'indicator[operation]\' type=\'hidden\' value=\'' +
((__t = ( operation )) == null ? '' : __t) +
'\'>\n\n<dt>\n    <label for="indicator-search">' +
((__t = ( i18next.t('ui.search_box') )) == null ? '' : __t) +
'</label>\n</dt>\n\n<dd>\n    <input class=\'text\' id=\'indicator-search\' placeholder=\'' +
((__t = ( i18next.t('indicators.search_placeholder') )) == null ? '' : __t) +
'\'>\n</dd>\n<dt>\n    <label>' +
((__t = ( i18next.t('glossary.indicators') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd id=\'indicators-list\'></dd>\n\n<dt style="margin-top: 5px; text-align: right;">\n    <a id="set-advanced" style="cursor: pointer">' +
((__t = ( i18next.t('indicators.switch_to_advanced') )) == null ? '' : __t) +
'</a>\n</dt>\n';

}
return __p
}