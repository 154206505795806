module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'holiday_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'holiday_name\' name=\'holiday[name]\'>\n        </dd>\n        <dt>\n            <label for=\'holiday_date\'>' +
((__t = ( i18next.t('common.date') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input autocomplete=\'off\' class=\'text\' id=\'holiday_date\' name=\'holiday[date]\'>\n        </dd>\n        <dt>\n            <label for=\'holiday_country_code\'>' +
((__t = ( i18next.t('common.country') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'holiday_country_code\' name=\'holiday[country_code]\'>\n                ';
 WiseMetering.currentOrganization.get('buildings_country_codes').forEach(function (country_code) { ;
__p += '\n                    <option value=\'' +
((__t = ( country_code )) == null ? '' : __t) +
'\'>' +
((__t = ( country_code )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <label for=\'holiday_city\'>' +
((__t = ( i18next.t('common.city') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'holiday_city\' name=\'holiday[city]\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}