module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <div id=\'report-description-section\' class=\'mbx-info green\' style=\'margin: 0; text-align: justify;\'></div>\n    <dt>\n        <label for=\'report-language\'>Language</label>\n    </dt>\n    <dd>\n        <select class=\'text\' id=\'report-language\' name=\'report[language]\'>\n            <option value=\'\' disabled selected>- Select option -</option>\n            <option value=\'pt\'>Portuguese</option>\n            <option value=\'en\'>English</option>\n            <option value=\'es\'>Spanish</option>\n        </select>\n    </dd>\n\n    <dt>\n        <label for=\'report-type\'>Type</label>\n    </dt>\n    <dd>\n        <select class=\'text\' id=\'report-type\' name=\'report[type]\'>\n            <option value=\'\' disabled selected style=\'color:red\'>- Select option -</option>\n            ';
 if (building_id) { ;
__p += '\n                ';
 reports.forEach(function(report) { ;
__p += '\n                    <option value=\'' +
((__t = ( report.value )) == null ? '' : __t) +
'\' ';
 if (!report.active) { ;
__p += ' disabled ';
 } ;
__p += '>\n                        ' +
((__t = ( report.title )) == null ? '' : __t) +
' ';
 if (!report.active) { ;
__p += '(' +
((__t = ( i18next.t('reports.not_available') )) == null ? '' : __t) +
') ';
 } ;
__p += '\n                    </option>\n                ';
 }) ;
__p += '\n            ';
 } else { ;
__p += '\n                ';
 reports.forEach(function(group) { ;
__p += '\n                    <optgroup label=\'' +
((__t = ( group[0] )) == null ? '' : __t) +
'\'>\n                        ';
 group[1].forEach(function(report) { ;
__p += '\n                            <option value=\'' +
((__t = ( report.value )) == null ? '' : __t) +
'\' ';
 if (!report.active) { ;
__p += ' disabled ';
 } ;
__p += '>\n                                ' +
((__t = ( report.title )) == null ? '' : __t) +
' ';
 if (!report.active) { ;
__p += '(' +
((__t = ( i18next.t('reports.not_available') )) == null ? '' : __t) +
') ';
 } ;
__p += '\n                            </option>\n                        ';
 }) ;
__p += '\n                    </optgroup>\n                ';
 }) ;
__p += '\n            ';
 } ;
__p += '\n        </select>\n    </dd>\n\n    <div id=\'report-utility-kind-section\' style=\'display:none\'>\n        <dt>\n            <label for=\'report-utility-kind\'>UtilityKinds</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'report-utility-kind\' name=\'report[options][utility_kind]\'>\n                <option value=\'\' disabled selected>- Select option -</option>\n                ';
 utility_kinds.forEach(function(utility_kind) { ;
__p += '\n                    <option value=\'' +
((__t = ( utility_kind.slug )) == null ? '' : __t) +
'\'>' +
((__t = ( utility_kind.name )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n    </div>\n\n    <div id=\'report-comparison-section\' style=\'display:none\'>\n        <dt>\n            <label for=\'report-comparison\'>Objective Comparison</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'report-comparison\' name=\'report[options][comparison]\'>\n                <option value=\'\' disabled selected>- Select option -</option>\n                <option value=\'consumption\'>Consumption</option>\n                <option value=\'cost\' ';
 if (!WiseMetering.currentOrganization.hasStandardPermissions()) { ;
__p += ' disabled ';
 } ;
__p += '>\n                    ' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
' ';
 if (!WiseMetering.currentOrganization.hasStandardPermissions()) { ;
__p += '(' +
((__t = ( i18next.t('reports.not_available') )) == null ? '' : __t) +
') ';
 } ;
__p += '\n                </option>\n            </select>\n        </dd>\n    </div>\n\n    <div id=\'report-folder-section\' style=\'display:none\'>\n        <dt>\n            <label for=\'report-folder\'>Folder</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'report-folder\' name=\'report[folder_id]\'>\n                <option value=\'\' disabled selected>- Select option -</option>\n                ';
 folders.forEach(function(folder) { ;
__p += '\n                    <option value=\'' +
((__t = ( folder.id )) == null ? '' : __t) +
'\'>' +
((__t = ( folder.name )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n    </div>\n\n    <div id=\'report-details-section\' style=\'display:none\'>\n        <dt>\n            <label for=\'report-details\'>Detail Level</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'report-details\' name=\'report[options][details]\'>\n                <option value=\'\' disabled selected>- Select option -</option>\n                <option value=\'brief\'>Brief Version</option>\n                <option value=\'extended\'>Extended Version</option>\n            </select>\n        </dd>\n    </div>\n\n    <div id=\'report-file-section\' style=\'display:none\'>\n        <dt>\n            <label for=\'report-file\'>File</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'report-file\' name=\'report[options][file]\'>\n                <option value=\'\' disabled selected>- Select option -</option>\n                <option value=\'pdf\'>pdf</option>\n                <option value=\'xls\'>xls</option>\n            </select>\n        </dd>\n    </div>\n\n    <div id=\'report-frequency-section\' style=\'display: none\'>\n        <dt>\n            <label for=\'report-frequency\'>Frequency</label>\n        </dt>\n        <dd>\n            <select id=\'report-frequency\' name=\'report[frequency]\' class=\'text\'>\n                <option value=\'\' disabled selected>- Select option -</option>\n                ';
 frequencies.forEach(function(frequency) { ;
__p += '\n                    <option value=\'' +
((__t = ( frequency.value )) == null ? '' : __t) +
'\'>' +
((__t = ( frequency.title )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n    </div>\n\n    <div>\n        <dt>\n            <label>Notifications</label>\n        </dt>\n        <dd>\n            <div class=\'wmui-btn\' id=\'add-notification\'>\n                Add\n            </div>\n        </dd>\n        <div id=\'notification-list\' style=\'margin-top: 5px\'></div>\n    </div>\n</form>\n';

}
return __p
}