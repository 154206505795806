module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'circuit-name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'circuit-name\' name=\'circuit[name]\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'/>\n        </dd>\n        ';
 if (hasActuators) { ;
__p += '\n            <dt>\n                <label for=\'circuit-actuator\'>' +
((__t = ( i18next.t('glossary.actuators') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'circuit-actuator\' name=\'circuit[actuator_id]\'>\n                    <option value=\'\'>&nbsp;</option>\n                </select>\n            </dd>\n        ';
 } ;
__p += '\n        <dt>\n            <label for=\'circuit_description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text\' id=\'circuit_description\' name=\'circuit[description]\'>' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}