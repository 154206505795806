module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <div style="' +
((__t = ( schedule_id ? 'width:200px; max-width:200px; min-width:200px; float:left; padding:25px 15px 0 10px;' : '' )) == null ? '' : __t) +
'">\n        <dl class=\'stepped zero\'>\n            ';
 if (WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n                <dt>ID</dt>\n                <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n            ';
 } ;
__p += '\n            <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n            <dt>' +
((__t = ( i18next.t('glossary.active') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( active ? i18next.t('common.yes') : i18next.t('common.no') )) == null ? '' : __t) +
'</dd>\n            <dt>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( indicator )) == null ? '' : __t) +
'</dd>\n            <dt>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( type )) == null ? '' : __t) +
'</dd>\n            <dt>' +
((__t = ( i18next.t('glossary.notifications') )) == null ? '' : __t) +
'</dt>\n            <dd id="notifications"></dd>\n            <dt>\n                <ul class="toolbar regular" style="background: none; border:none; padding-left: 0 !important;">\n                    <div class="wmui-btn" id=\'add-notification\' style="float: left; margin:0 !important;">\n                        ' +
((__t = ( i18next.t('alarms.add_notification') )) == null ? '' : __t) +
'\n                    </div>\n                </ul>\n            </dt>\n            ';
 if (renderIntegrationAssets) { ;
__p += '\n                <div class="alarm-integration-assets">\n                    <div class="title" id="title">\n                        ' +
((__t = ( i18next.t('integration_assets.integrate_assets') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="content">\n                        <dd id="integration-assets"></dd>\n                        <dt>\n                            <ul class="toolbar regular" style="background: none; border:none; padding-left: 0 !important;">\n                                <div class="wmui-btn" id=\'add-asset\' style="float: left; margin:0 !important;">\n                                    ' +
((__t = ( i18next.t('alarms.add_asset') )) == null ? '' : __t) +
'\n                                </div>\n                            </ul>\n                        </dt>\n                    </div>\n                </div>\n            ';
 } ;
__p += '\n        </dl>\n    </div>\n    <div style=\'float:left; padding-left:25px; border-left:1px solid #CCCCCC;\'>\n        <dl class=\'stepped\' id=\'alarm-schedule\'>\n            <dt style=\'margin-top: 15px;\'>\n                <label>' +
((__t = ( i18next.t('ui.table.alarm_schedule') )) == null ? '' : __t) +
'</label>\n            </dt>\n        </dl>\n    </div>\n</form>\n';

}
return __p
}