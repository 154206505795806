WiseMetering.Views.ShowAllocation = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/allocations/show',
    className: 'mbx-info text-left',
    width: 500,
    title: () => i18next.t('modal.title.allocations.show'),
    buttons: ['edit', 'close'],

    events: {
        'click #indicator': 'onIndicatorLinkClicked',
        'click #tariff': 'onTariffLinkClicked'
    },

    serializeData: function() {
        let
            json = this.model.toJSON(),
            indicator = WiseMetering.indicators.get(json.indicator_id);

        json.from = this.model.getDate('from');
        json.to = this.model.getDate('to');
        json.createdAt = this.model.getDateTime('created_at');
        json.updatedAt = this.model.getDateTime('updated_at');
        json.indicatorName = indicator.get('name');
        json.tariffName = WiseMetering.tariffs.get(json.tariff_id).get('name');

        return json;
    },

    edit: function() {
        this.block();
        const tenant = WiseMetering.tenants.get(this.model.get('tenant_id'));
        _.defer(function() {
            new WiseMetering.Views.EditAllocation({
                model: this.model,
                collection: WiseMetering.allocations.activeAllocations(tenant.id),
                indicators: new WiseMetering.Collection.Indicators,
                tariffs: new WiseMetering.Collection.Tariffs,
                tenant: tenant
            });
        }.bind(this));
    },

    onIndicatorLinkClicked: function(event) {
        event.preventDefault();

        this.modal.close();
        Backbone.history.navigate(`/indicators/${this.model.get('indicator_id')}/plot${location.search}`, { trigger: true });
    },

    onTariffLinkClicked: function(event) {
        event.preventDefault();

        this.modal.close();
        Backbone.history.navigate(`/tariffs`, { trigger: true });
    }
});
