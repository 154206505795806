WiseMetering.Views.Ui.Widget.PieChart = WiseMetering.Views.Ui.Widget.Base.extend({
    colors: WiseMetering.WidgetColors.MultiColor,
    templateID: 'templates/ui/widgets/basic_chart',

    build: function() {
        this.drawChart();
    },

    drawChart: function() {
        const data = _.map(this.data, function(child, index) {
            const color = child.color ? child.color : this.colors[index],
                  isZero = child.isZero ? true : false;

            return {
                color: color,
                isZero: isZero,
                name: child.name,
                y: child.value,
                unit: this.unit
            };
        }.bind(this));

        const useArea = this.widgetPreferences.normalization,
              total = useArea ? null : data.reduce((acc, value) => acc + value.y, 0),
              isZero = (data.length === 1 && data[0].isZero) ? true : false
              centerValue = isZero ? 0 : total,
              unit = this.unit;

        const chartOptions = jQuery.extend(true, this.defaultChartOptions, {
            chart: {
                height: `${this.widgetPreferences.height}px`
            },
            exporting: {
                enabled: false,
                allowHTML: true,
                chartOptions: {
                    title: { text: this.title },
                    legend: { enabled: false }
                }
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter: function() {
                            if (this.percentage <= 0) return;
                            const position = this.point.dataLabel?.dataLabelPosition?.alignment || 'left';
                            return `
                                <div style="text-align: ${position}">
                                    ${position === 'left' ? `<span style="display: inline-block; width: 6px; height: 6px; margin: 5px; background: ${this.point.color};"></span>` : ``}
                                    <div style="display: inline-block;">
                                        <span>${this.point.name} (${formatValue(this.percentage)}%)</span><br>
                                    </div>
                                    ${position === 'right' ? `<span style="display: inline-block; width: 6px; height: 6px; margin: 5px; background: ${this.point.color};"></span>` : ``}
                                </div>
                            `;
                        },
                        style: {
                            fontSize: '1em'
                        },
                        connectorWidth: 0
                    },
                    innerSize: '75%',
                    size: '75%'
                }
            },
            series: [{ type: 'pie', name: null, data, colors: this.colors }],
            title: {
                text: centerValue ? formatValue(centerValue, this.options.unit) : '',
                align: 'center',
                verticalAlign: 'middle',
                y: 15,
                style: {
                    fontWeight: 'bold'
                }
            },
            tooltip: {
                formatter: function() {
                    return `
                        <span style="font-weight:bold; color: ${this.point.color}">${this.point.name}</span>
                        ${this.point.isZero ? 0 : formatValue(this.y, this.point.unit)}${useArea ? '/m²' : ''} <b>(${(this.percentage).toFixed(2)}%)</b>
                    `;
                }
            },
            containerProps: {style: { height: "100%" } }
        });

        this.chart = new Highcharts.Chart(chartOptions);
    }
});
