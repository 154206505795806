WiseMetering.Views.EtlIndicator = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/types/etl',

    serializeData: function() {
        return {
            buildings: WiseMetering.zones.buildings(),
            etls: WiseMetering.etls,
            isNew: this.model.isNew(),
            etl_reference: this.model.get('etl_reference'),
            ratio: this.model.get('ratio'),
            stopped: this.model.get('stopped')
        };
    }
});
