WiseMetering.Views.NewHoliday = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'holiday',
    template: 'templates/holidays/new',
    title: () => i18next.t('modal.title.holidays.new'),

    onRender: function() {
        this.$('#holiday_date').datepicker({
            dateFormat: 'dd/mm/yy',
            changeMonth: true,
            changeYear: true
        });
    }
});
