WiseMetering.Views.EditPanorama = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/zones/panorama',
    title: 'Edit Panorama',
    width: 800,

    createPanorama: function() {
        const { model } = this;
        this.panorama = new google.maps.StreetViewPanorama(document.getElementById('street-view'), {
            position: {
                lat: model.get('latitude'),
                lng: model.get('longitude')
            },
            pov: {
                heading: model.get('heading'),
                pitch: model.get('pitch'),
                zoom: model.get('zoom')
            },
            visible: true,
            draggable: false,
            zoom: 0,
            zoomControl: false,
            motionTracking: false,
            motionTrackingControl: false,
            addressControl: false,
            fullscreenControl: false,
            linksControl: false,
            panControl: false
        });

        this.panorama.addListener('position_changed', this.changeAddress.bind(this));
        this.panorama.addListener('pov_changed', this.changeAddress.bind(this));
    },

    changeAddress: function() {
        const
            { panorama, model } = this,
            { heading, pitch, zoom } = panorama.getPov(),
            latitude = panorama.getPosition().lat(),
            longitude = panorama.getPosition().lng();

        model.set({ heading, latitude, longitude, pitch, zoom });

        if (model.hasChanged()) {
            this.changed = true;
        }
    },

    onShow: function() {
        this.changed = false;
        setTimeout(this.createPanorama.bind(this), 0);
    },

    save: function() {
        if (this.changed) {
            this.model.save({
                heading: this.model.get('heading'),
                latitude: this.model.get('latitude'),
                longitude: this.model.get('longitude'),
                pitch: this.model.get('pitch'),
                zoom: this.model.get('zoom'),
            }, { wait: true, patch: true }).done(function() {
                this.options.building.set({ address: this.model.attributes });
                this.modal.close();
            }.bind(this));
        } else {
            this.modal.close();
        }
    }

});
