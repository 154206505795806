WiseMetering.Views.IndexCircuitsByBuilding = WiseMetering.Views.TableIndex.extend({
    icon: 'circuits',
    name: () => i18next.t('glossary.circuits'),

    createTable: function createTable() {
        const collection = this.model.circuits();

        let table = new WiseMetering.Views.Table({
            id: 'circuits',
            collection: collection,
            columns: {
                name: i18next.t('common.name'),
                system_category_name: i18next.t('glossary.circuit_type')
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.CircuitAdd({
                        collection: WiseMetering.circuits,
                        model: new WiseMetering.Model.Circuit({
                            zone_id: this.model.type() === 'root-object' ? this.model.building().id : this.model.id
                        })
                    })
                },
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.circuits.destroy'),
                        afterDestroy: function() {
                            WiseMetering.actuators.fetch();
                            WiseMetering.indicators.fetch();
                        }
                    })
                }
            }
        });

        this.listenTo(this.model, 'change:children:addCircuit', function(node) {
            collection.add(node);
        });

        this.listenTo(this.model, 'change:children:removeCircuit', function(node) {
            collection.remove(node);
        });

        return table;
    }
});
