WiseMetering.Views.ZoneDetailsTab = Backbone.Marionette.Layout.extend({
    template: 'templates/zones/tab',
    className: 'dashboard full-height',

    regions: {
        summaryInfo: '#summary-info',
        configurationStatus: '#configuration-status',
        details: '#details-accordion',
        attrs: '#attributes-accordion',
        location: '#location-accordion',
        indicators: '#indicators-accordion',
        zones: '#zones-accordion',
        circuits: '#circuits-accordion',
        folders: '#folders-accordion',
        system: '#system-info-accordion'
    },

    serializeData: function() {
        return {
            configured: this.model.get('configuration_status') ? this.model.get('configuration_status').value === 100 : null
        };
    },

    onRender() {
        if (this.model.isBuilding()) {
            this.configurationStatus.show(new WiseMetering.Views.ZoneConfigStatus({ model: this.model }));
            this.summaryInfo.show(new WiseMetering.Views.ZoneSummaryInfo({ model: this.model }));
            this.location.show(new WiseMetering.Views.ZoneLocationAccordion({ model: this.model }));
        } else {
            this.circuits.show(new WiseMetering.Views.CircuitAccordion({ model: this.model }));
            this.folders.show(new WiseMetering.Views.FolderAccordion({ model: this.model }));
            this.zones.show(new WiseMetering.Views.ZoneAccordion({ model: this.model }));
        }

        this.attrs.show(new WiseMetering.Views.ZoneAttributesAccordion({ model: this.model }));
        this.details.show(new WiseMetering.Views.ZoneDetailsAccordion({ model: this.model }));
        this.indicators.show(new WiseMetering.Views.IndicatorAccordion({ model: this.model }));
        this.system.show(new WiseMetering.Views.SystemInfoAccordion({ model: this.model }));
    }
});
