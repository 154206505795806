WiseMetering.Views.IndexAlarmSchedule = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/layout/empty',

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'templates',
            collection: WiseMetering.alarmSchedules,
            columns: {
                count: {
                    mRender: count => {
                        return JST['templates/ui/components/circle']({
                            color: WiseMetering.Colors[count ? 'green' : 'orange'],
                            title: `Used by ${count} alarm${count === 1 ? '' : 's'}`
                        });
                    },
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                periods: i18next.t('glossary.periods'),
                updated_at: i18next.t('glossary.last_change'),
                updater: i18next.t('glossary.modified_by')
            },
            modelSerializer: function(model) {
                const json = model.toJSON();
                json.count = model.alarms().length;
                json.periods = json.periods.join(' | ');
                json.updated_at = WiseMetering.utils.formatDate(json.updated_at);
                json.updater = model.updater().name();
                return json;
            },
            toolbar_actions: {
                show: {
                    label: 'Show',
                    view_call: model => new WiseMetering.Views.ShowAlarmSchedule({ model })
                },
                new: {
                    label: 'New',
                    view_call: () => new WiseMetering.Views.NewAlarmSchedule({
                        collection: WiseMetering.alarmSchedules,
                        model: new WiseMetering.Model.AlarmSchedule
                    })
                },
                edit: {
                    view_call: model => new WiseMetering.Views.EditAlarmSchedule({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.alarm_schedules.destroy')
                    })
                }
            }
        });
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.table = this.createTable();
        $(this.el).html(this.table.el);
    }
});
