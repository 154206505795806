module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<a href=\'/map\' target=\'_blank\'>\n    <div class=\'map-control-button\' title="' +
((__t = ( i18next.t('ui.map_search.center_map') )) == null ? '' : __t) +
'">\n        ' +
((__t = ( WiseMetering.icon('link') )) == null ? '' : __t) +
'\n    </div>\n</a>\n';

}
return __p
}