import * as React from 'react';

const ContentArea = ({ children }) => {
    return (
        <div className="tw-basis-3/4">
            { children }
        </div>
    );
};

export { ContentArea };
