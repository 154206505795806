WiseMetering.Model.Invoice = WiseMetering.Model.extend({
    urlRoot: '/invoices',

    fileLink: function() {
        return this.get('file') ? WiseMetering.apiUrl + this.url() + '/file' : null;
    },

    invoiceValidationStatus: function() {
        if (this.get('check').status === 'checked') {
            return 'Validation checked, deviation < 2,5%';
        } else if (this.orangeDeviationCondition()) {
            return 'Validation not checked, deviation < 5%';
        } else if (this.get('check').status === 'not_checked') {
            return 'Validation not checked, deviation >= 5%';
        }

        return 'Validation process failed';
    },

    orangeDeviationCondition: function() {
        const threshold = 5,
            consumption = Math.abs(this.get('check').consumption),
            cost = Math.abs(this.get('check').cost),
            notChecked = this.get('check').status === 'not_checked';

        return notChecked && consumption < threshold && cost < threshold;
    },

    removeFile: function() {
        return this.save({}, {
            url: this.url() + '/remove_file',
            wait: true,
            patch: true
        });
    },

    statusColor: function() {
        if (this.get('check').status === 'checked') {
            return 'green';
        } else if (this.orangeDeviationCondition()) {
            return 'orange';
        } else if (this.get('check').status === 'not_checked') {
            return 'red';
        }

        return 'blue';
    },

    statusIcon: function() {
        if (this.get('check').status === 'checked') {
            return 'check';
        } else if (this.orangeDeviationCondition()) {
            return 'exclamation';
        } else if (this.get('check').status === 'not_checked') {
            return 'times';
        }

        return 'question';
    },

    uploadFile: function(formData) {
        return this.save({}, {
            url: this.url(),
            contentType: false,
            data: formData,
            processData: false
        });
    },

    unit: function() {
        return this.utility().unit();
    },

    utility: function() {
        return WiseMetering.utilities.get(this.get('utility_id'));
    }
});
