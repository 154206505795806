module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class=\'item ';
 if ( buildingStatus || buildingStatus === 'n/a' ) { ;

 } else if ( !isActivated ) { ;
__p += 'activate';
 } else { ;
__p += 'deactivated';
 } ;
__p += '\'>\n    ';
 if (noCaret) { ;
__p += '\n        <div class=\'icon tree_handler hidden\'></div>\n        <div class=\'spacer\'></div>\n    ';
 } else if (hasChildren) { ;
__p += '\n        <div class=\'icon tree_handler\'>\n            <i class="fa fa-chevron-right" aria-hidden="true"></i>\n        </div>\n        <div class=\'spacer hidden\'></div>\n    ';
 } else { ;
__p += '\n        <div class=\'icon tree_handler\'>\n            <i class="fa fa-chevron-right" style="color:lightgray" aria-hidden="true"></i>\n        </div>\n        <div class=\'spacer hidden\'></div>\n    ';
 } ;
__p += '\n\n    ';
 if (icon) { ;
__p += '\n        <div class=\'svg_icon\'>\n            ' +
((__t = ( WiseMetering.icon(icon) )) == null ? '' : __t) +
'\n        </div>\n    ';
 } ;
__p += '\n\n    <div class=\'title\'>\n        <div class=\'tree\'>\n            <span>' +
((__t = ( name )) == null ? '' : __t) +
'</span>\n            ';
 if (showLock) { ;
__p += '\n                <span class=\'lock\'>' +
((__t = ( WiseMetering.icon('lock') )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</li>\n';

}
return __p
}