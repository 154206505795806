WiseMetering.Views.IndexEvent = WiseMetering.Views.TableIndex.extend({
    icon: 'events',
    name: () => i18next.t('glossary.events'),

    layout: _.template(`
        <div class='grp_ctrl wmui-chart-toolbar' style='border-bottom: 1px solid #f3f3f3;'>
            <div class='wmui-chart-control-date_time'>
                <ul class='ctrl date_time-selector'>
                <li class='label'><%= i18next.t('common.from') %>:</li>
                <li class='field border'>
                    <input id='date_time_from' maxlength='10' name='date-time-from' type='textfield' value=''>
                </li>
                <li class='label'><%= i18next.t('common.until') %>:</li>
                <li class='field border'>
                    <input id='date_time_to' maxlength='10' name='date-time-to' type='textfield' value=''>
                </li>
                <button id='submit_time'>Ok</button>
                </ul>
            </div>
        </div>
        <div id='eventTable'></div>
    `),

    onShow() {
        this.collection = new WiseMetering.Collection.Events;
        $(this.body.el).html(this.layout());
        this.initUi();
        this.bindEvents();
        this.initTable();
    },

    onClose: function() {
        this.unbindEvents();
        this.removeTable();
    },

    removeTable() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    reRenderTable() {
        this.removeTable();
        this.initTable();
    },

    initUi() {
        const bodyEl = $(this.body.el);
        this.ui = {
            table: bodyEl.find('#eventTable'),
            submit: bodyEl.find('#submit_time'),
            fromField: bodyEl.find('#date_time_from'),
            toField: bodyEl.find('#date_time_to')
        };

        this.ui.fromField.val(ParamsService.from);
        this.ui.toField.val(ParamsService.to);

        this.setupDatePicker(this.ui.fromField, this.updateFields.bind(this));

        this.setupDatePicker(this.ui.toField, this.updateFields.bind(this));

        this.updateFields();
        ParamsService.setParams({ from: this.ui.fromField.val(), to: this.ui.toField.val() });

    },

    setupDatePicker(field, callback) {
        field.datepicker({
            dateFormat: 'yy.mm.dd',
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            onSelect: callback
        });
    },

    updateFields() {
        this.ui.fromField.datepicker('option', 'maxDate', this.ui.toField.val());
        this.ui.toField.datepicker('option', 'minDate', this.ui.fromField.val());
    },

    bindEvents() {
        this.ui.submit.on('click', () => {
            ParamsService.setParams({ from: this.ui.fromField.val(), to: this.ui.toField.val() });
            this.reRenderTable();
        });
    },

    unbindEvents() {
        this.ui.submit.off('click');
    },

    async initTable() {
        this.ui.table.html(`<img src='/images/wisemetering/loader.svg' style='max-width: 100px; position: absolute; top: 30%; left: 45%;'>`);
        await this.fetchEvents();
        this.table = this.createTable();
        this.ui.table.html(this.table.el);
    },

    fetchEvents() {
        return this.collection.fetch({
            data: {
                from: this.ui.fromField.val(),
                to: this.ui.toField.val()
            }
        });
    },

    createTable() {
        return new WiseMetering.Views.Table({
            id: 'events',
            collection: this.collection,
            modelSerializer: model => {
                const building = model.building();
                return {
                    id: model.id,
                    name: model.get('name'),
                    indicator: model.indicator().get('name'),
                    building: building.get('name'),
                    value: formatValue(model.get('value'), model.indicator().get('unit')),
                    event_at: WiseMetering.utils.formatDate(model.get('start'), building.get('timezone'))
                };
            },
            columns: {
                name: i18next.t('common.name'),
                indicator: i18next.t('glossary.indicator'),
                building: i18next.t('glossary.building'),
                value: i18next.t('common.value'),
                event_at: i18next.t('events.event_at')
            },
            types: {
                created_at: 'date'
            },
            toolbar_actions: {
                select_all: {},
                show: {
                    label: 'Show',
                    view_call: model => new WiseMetering.Views.ShowEvent({ model })
                },
                open_indicator: {
                    icon: 'indicators',
                    label: 'Indicator',
                    view_call: model => {
                        const indicator = WiseMetering.indicators.get(model.get('indicator_id'));
                        Backbone.history.navigate(indicator.nodeUrl(), { trigger: true });
                    }
                },
                delete_all: {}
            }
        });
    }
});
