WiseMetering.Views.NewUtility = WiseMetering.Views.Modal.Save.extend({
    form_prefix: 'utility',
    template: 'templates/utilities/new',
    title: () => i18next.t('modal.title.utilities.new'),
    events: {
        'change @ui.utilityKind': 'onChangeUtilityKind'
    },
    ui: {
        extra: '#utility-extra',
        indicator: '#utility-indicator',
        utilityKind: '#utility-kind'
    },

    fillReactive: function() {
        const capacitiveIndicators = WiseMetering.indicators.where({ building_id: this.model.get('building_id'), kind_slug: 'capacitive_energy' });
        this.$('#utility-capacitive-energy').html(WiseMetering.utils.optionsFromModels(capacitiveIndicators, 'name', null, true));

        const inductiveIndicators = WiseMetering.indicators.where({ building_id: this.model.get('building_id'), kind_slug: 'inductive_energy' });
        this.$('#utility-inductive-energy').html(WiseMetering.utils.optionsFromModels(inductiveIndicators, 'name', null, true));
    },

    onChangeUtilityKind: function(event) {
        this.model.set('utility_kind_id', event.currentTarget.value);

        const
            utilityKind = this.model.utilityKind(),
            indicators = WiseMetering.indicators.where({ building_id: this.model.get('building_id'), kind_slug: utilityKind.indicatorKindSlug() });

        if (indicators.length) {
            this.ui.indicator.html(WiseMetering.utils.optionsFromModels(indicators, 'name', null, true));

            this.ui.utilityKind.attr('disabled', true);
            this.ui.indicator.attr('disabled', false);

            if (utilityKind.get('slug') === 'electricity') {
                this.$('label[for="utility-indicator"]').html(`${i18next.t('indicatorKinds.active_energy')}&nbsp;*`);
                this.renderReactive();
                this.fillReactive();
            }
        } else {
            this.ui.indicator.html(`<option value="">${i18next.t('indicators.no_indicators_found')}</option>`);
        }
    },

    onShow: function() {
        const utilityKindOptions = WiseMetering.utils.optionsFromModels(WiseMetering.utilityKinds.models, 'name');
        this.ui.utilityKind.append(utilityKindOptions);
    },

    renderReactive: function() {
        const template = `
            <dt>
                <label for='utility-capacitive-energy'>${i18next.t('indicatorKinds.capacitive_energy')}</label>
            </dt>
            <dd>
                <select class='text' id='utility-capacitive-energy' name='utility[capacitive_energy_id]'></select>
            </dd>
            <dt>
                <label for='utility-inductive-energy'>${i18next.t('indicatorKinds.inductive_energy')}</label>
            </dt>
            <dd>
                <select class='text' id='utility-inductive-energy' name='utility[inductive_energy_id]'></select>
            </dd>
        `;

        this.ui.extra.html(template);
    }
});
