WiseMetering.Views.NewBaselineAlarm = WiseMetering.Views.Modal.Custom.extend({
    template: 'templates/alarms/new_baseline',
    title: () => i18next.t('modal.title.alarms.new'),
    buttons: ['yes', 'cancel'],
    className: 'mbx-info',

    yes: function() {
        this.block();

        return this.model.save({}, {
            url: this.model.url() + '/generate_baseline_alarm',
            method: 'POST',
            success: () => {
                Promise.all([
                    WiseMetering.alarms.fetch(),
                    WiseMetering.alarmSchedules.fetch()
                ]).then(() => {
                    WiseMetering.layout.showTipper('success', 'Alarm created');
                    this.options.callback();
                    this.close();
                });
            },
            error: function(_, error) {
                WiseMetering.layout.showTipper('error', 'Failed to create alarm: ' + error.responseText);
                this.close();
            }.bind(this)
        });
    }
});
