WiseMetering.Collection.Objectives = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Objective,
    url: '/objectives',

    comparator: model => {
        const fields = [model.get('year')];
        const indicator = model.indicator();
        if (indicator) {
            fields.push(indicator.get('name'))
        }
        fields.push(model.get('unit'))
        return fields;
    },

    buildingLevelObjectives: function(utilityKindSlug = null, analysis_type = null, year = null) {
        let indicators = WiseMetering.indicators.buildingLevelIndicators(utilityKindSlug);
            objectives = WiseMetering.objectives.filter(objective => {
                let matchIndicator = indicators.pluck('id').includes(objective.get('indicator_id')),
                    matchYear = year ? objective.get('year') === year : true,
                    matchAnalysisType = analysis_type ? objective.get('analysis_type') === analysis_type : true;

                return matchIndicator && matchYear && matchAnalysisType;
            });

        return new WiseMetering.Collection.Objectives(objectives)
    },

    getUtilityFromSlug: function(utilityKindSlug) {
        return WiseMetering.utilityKinds.findWhere({ slug: utilityKindSlug });
    }
});
