module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.state') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( state )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.external_id') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( external_id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.organization') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( organization_name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( building_name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.integration_partner') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( integration_partner_name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.integration') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( integration_name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.first_sync') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( WiseMetering.utils.formatDate(first_sync) )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.last_sync') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( last_sync ? WiseMetering.utils.formatDate(last_sync) : i18next.t('ui.not_yet_synced') )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('integration_assets.orders_created_count') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( orders_created_count )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.settings') )) == null ? '' : __t) +
'</dt>\n    <div class="sliders-container"></div>\n</dl>\n';

}
return __p
}