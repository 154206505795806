import * as React from 'react';
import { Icon } from '@/components/wm';

const SidebarMenu = ({ items, currentView, handleViewChange }) => {
    return (
        <div className="tw-flex-auto tw-basis-1/4 tw-border-r tw-pr-6 tw-border-solid tw-border-border">
            {items.map((item) => (
                <a
                    className={`
                        tw-w-full
                        tw-p-3
                        tw-text-grey
                        tw-flex
                        tw-items-center
                        hover:tw-bg-grey-bg
                        hover:tw-text-grey-dark
                        [&>svg]:hover:tw-text-grey-dark
                        hover:tw-no-underline
                        ${currentView === item.key ? 'tw-bg-grey-bg tw-text-grey-dark [&>svg]:tw-text-grey-dark' : ''}
                    `}
                    key={item.key}
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        handleViewChange(item.key);
                    }}
                >
                    {item.icon && (
                        <Icon icon={item.icon} size="small" color="sideMenu" />
                    )}
                    <span className="tw-text-sm tw-font-medium tw-ml-1">{item.title}</span>
                </a>
            ))}
        </div>
    );
};

export { SidebarMenu };
