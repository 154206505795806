module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table class=\'interval\'>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">\n            ' +
((__t = ( i18next.t("common.interval") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("common.from") )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("common.to") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( interval.from )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class="to" name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( interval.to )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class=\'spacer\'></tr>\n    <tr>\n        <th class=\'head\' colspan=\'2\'>\n            Parte Fixa\n        </th>\n    <tr>\n        <th>\n            Potência <b>(kW)</b>\n        </th>\n        <th>\n            Custo <b>(' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/kW/mês)</b>\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][contracted_power]" type="text" value="' +
((__t = ( interval.values?.contracted_power )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][contracted_power_price]" type="text" value="' +
((__t = ( interval.values?.contracted_power_price )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class=\'spacer\'></tr>\n    <tr>\n        <th class=\'head\' colspan=\'2\'>\n            Parte Variável\n        </th>\n    </tr>\n    <tr>\n        <th>\n            Custo Consumo <b>(' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/' +
((__t = ( unit )) == null ? '' : __t) +
')</b>\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][consumption_price]" type="text" value="' +
((__t = ( interval.values?.consumption_price )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n</table>\n';

}
return __p
}