WiseMetering.Views.IndexTars = WiseMetering.Views.TableIndex.extend({
    icon: 'tariffs',
    name: () => i18next.t('glossary.tar'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'tars',
            collection: WiseMetering.tars,
            modelSerializer: function(model) {
                const
                    json = {},
                    latestTar = model.lastestTar();

                json.id = model.id;
                json.type = model.get('slug').toUpperCase();
                json.name = i18next.t(`glossary.${model.get('slug')}`).capitalize();
                json.country = "🇵🇹 Portugal";
                json.intervals = model.get('intervals').length;
                json.start = latestTar.start;
                json.finish = latestTar.finish;

                return json;
            },
            columns: {
                used: {
                    mRender: used => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors['green'],
                        title: i18next.t('common.in_use')
                    }),
                    sWidth: '20px'
                },
                type: i18next.t('common.type'),
                name: i18next.t('common.name'),
                start: i18next.t('common.start'),
                finish: i18next.t('common.finish'),
                country: i18next.t('common.country')
            },
            toolbar_actions: {
                show: {
                    view_call: (model) => {
                        new WiseMetering.Views.ShowTar({ model });
                    }
                },
            }
        });
    }
});
