module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <span id=\'spinner\' style=\'color:#224C4F\' hidden>\n            <i class=\'far fa-spinner fa-spin\'></i> i18next.t(\'integration_services.fetching_third_party_buildings\')\n        </span>\n        <dt>\n            <label for="integration_service_category">' +
((__t = ( i18next.t('glossary.buildings') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_service_category\' name=\'integration_service[building_id]\'>\n                ';
 buildings.forEach(function (building) { ;
__p += '\n                    <option value=\'' +
((__t = ( building.get("id") )) == null ? '' : __t) +
'\'>' +
((__t = ( building.get("name") )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <label for=\'integration_service_url\'>' +
((__t = ( i18next.t('glossary.base_url') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' name=\'integration_service[base_url]\' id=\'integration_service_url\'>\n        </dd>\n        <dt>\n            <label for=\'integration_service_token\'>' +
((__t = ( i18next.t('glossary.token') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' name=\'integration_service[token]\' id=\'integration_service_token\'>\n        </dd>\n        <dt>\n            <label for=\'integration_service_building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_service_building_id\' name=\'integration_service[external_info]\' disabled>\n                <option value=\'\'>' +
((__t = ( i18next.t('ui.please_validate') )) == null ? '' : __t) +
'</option>\n            </select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}