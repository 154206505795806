WiseMetering.Views.TariffsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/zones/tariffs',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click div#edit_tariffs': 'editTariffs',
        'click .zone_tariff_details': 'showTariff'
    },

    serializeData () {
        const tariffs = [];

        WiseMetering.utilityKinds.forEach(utility_kind => {
            const tariff = this.model.tariff(utility_kind.get('name'));

            if (tariff) {
                tariffs.push({
                    id: tariff.id,
                    label: utility_kind.get('name') + ' Tariff',
                    name: tariff.get('name')
                });
            }
        });

        return { tariffs };
    },

    editTariffs: function (event) {
        event.preventDefault();
        event.stopPropagation();
        new WiseMetering.Views.ZoneEditTariffs({
            title: i18next.t('modal.title.zones.edit_tariffs'),
            model: this.model
        });
    },

    showTariff: function (event) {
        const model = WiseMetering.tariffs.get(event.currentTarget.dataset.id);
        new WiseMetering.Views.ShowTariff({ model, mode: 'summary' });
    }
});
