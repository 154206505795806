WiseMetering.Views.ReportTabs = WiseMetering.Views.Content.extend({
    icon: 'reports',
    name: () => i18next.t('glossary.reports'),

    tabs: function() {
        return [
            {
                id: 'reports',
                name: i18next.t('reports.global_reports'),
                view: WiseMetering.Views.IndexReport,
                active: true
            },
            {
                id: 'scheduled-reports',
                name: i18next.t('reports.scheduled_reports'),
                view: WiseMetering.Views.ScheduledReportsIndex
            }
        ];
    }
});
