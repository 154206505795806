WiseMetering.Views.Ui.Widget.BarChart = WiseMetering.Views.Ui.Widget.Base.extend({
    color:  WiseMetering.Colors.green,
    data: this.data,
    normalization: false,
    templateID: 'templates/ui/widgets/bar_chart',
    useNormalization: this.useNormalization,

    build: function() {
        this.drawChart();
    },

    drawChart: function() {
        this.resetEventListeners();

        const
            originalData = this.data,
            values = [],
            xInfo = [],
            unit = this.unit;

        this.data.forEach(xEntry => {
            if (xEntry.name.length > 12 && this.data.length > 5){
                xInfo.push(xEntry.name.substring(0, 12) + '...')
            } else {
                xInfo.push(xEntry.name);
            }

            values.push(this.normalization ? xEntry.normalizedValue : xEntry.value);
        });

        const chartOptions = jQuery.extend(true, this.defaultChartOptions, {
            chart: {
                marginLeft: 60,
                height: `${this.widgetPreferences.height}px`
            },
            xAxis: {
                categories: xInfo,
                lineColor: this.color,
                labels: {
                    style: {
                        fontSize: '0.8em',
                        fontWeight: 'regular'
                    }
                }
            },
            yAxis: {
                labels: {
                    formatter: function() {
                        return formatValue(this.value, unit);
                    }
                }
            },
            plotOptions: {
                column: { groupPadding: 0 },
                series: {
                    events: {
                        click: function(event) {
                            const building = originalData[event.point.index];
                            Backbone.history.navigate(`/zones/${building.id}/dashboard`, { trigger: true });
                        }
                    },
                    stacking: 'normal'
                }
            },
            tooltip: {
                formatter: function() {
                    return `<span style="font-weight:bold; color: ${this.color}">${this.x}</span> ${formatValue(this.y, unit)}${this.normalization ? '/m²' : ''}`;
                }
            },
            series: [
                { type: 'column', data: values, colors: this.colors }
            ],
            exporting: {
                enabled: false,
                allowHTML: true,
                chartOptions: {
                    title: { text: this.title },
                    gridSize: { text: this.widgetPreferences.gridSize }
                }
            }
        });

        this.chart = new Highcharts.Chart(chartOptions);
    },

    updateOptions(options) {
        if (this.normalization) {
            this.$('#widget-normalization').addClass('basic-widget-underline')
            this.$('#widget-no-normalization').removeClass('basic-widget-underline')
        } else {
            this.$('#widget-normalization').removeClass('basic-widget-underline')
            this.$('#widget-no-normalization').addClass('basic-widget-underline')
        }

        this.$('.title-text').text(options.title);
    },

    resetEventListeners: function() {
        this.$('#widget-normalization').off('click');
        this.$('#widget-no-normalization').off('click');

        this.$('#widget-normalization').on('click', this.normalizeValues.bind(this));
        this.$('#widget-no-normalization').on('click', this.fullValues.bind(this));
    },

    fullValues: function() {
        this.normalization = false;
        let newData = this.data.slice(0, 100);

        if (newData.length >= 0) {
            this.updateOptions({ title: i18next.t('common.consumption').capitalize() });
            this.drawChart(newData);
        } else {
            WiseMetering.layout.showTipper('info', i18next.t('ui.no_data_available'));
        }

    },

    normalizeValues: function() {
        this.normalization = true;
        let newData = this.data.filter(siteInfo => siteInfo.normalization !== null);

        if (newData.length >= 0) {
            newData.forEach(siteInfo => siteInfo["normalizedValue"] = siteInfo.value / siteInfo.normalization);
            newData.sort((a, b) => b.normalizedValue - a.normalizedValue);

            this.updateOptions({ title: i18next.t('common.consumption').capitalize() });
            this.drawChart(newData);
        } else {
            WiseMetering.layout.showTipper('info', i18next.t('ui.no_data_available'));
        }
    }
});
