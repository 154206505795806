WiseMetering.Views.IndexGroup = WiseMetering.Views.TableIndex.extend({
    icon: 'groups',
    name: () => i18next.t('glossary.groups'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'groups',
            collection: WiseMetering.groups,
            modelSerializer: function(model) {
                const json = model.toJSON();
                json.buildings = model.buildings().length;
                json.users = model.users().length;
                if (json.buildings && json.users) {
                    json.usable = {
                        color: 'green',
                        title: i18next.t('ui.table.in_use')
                    };
                } else if (json.buildings || json.users) {
                    json.usable = {
                        color: 'orange',
                        title: i18next.t('ui.table.warning')
                    };
                } else {
                    json.usable = {
                        color: 'red',
                        title: i18next.t('ui.table.unused')
                    };
                }
                return json;
            },
            columns: {
                usable: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[color],
                        title: title
                    }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                description: i18next.t('common.description'),
                users: i18next.t('glossary.users'),
                buildings: i18next.t('glossary.buildings')
            },
            toolbar_actions: {
                new: {
                    permission: 'administrator',
                    view_call: () => new WiseMetering.Views.ManageGroup({
                        collection: WiseMetering.groups,
                        model: new WiseMetering.Model.Group
                    })
                },
                edit: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.ManageGroup({ model })
                },
                show: {
                    view_call: model => new WiseMetering.Views.ShowGroup({ model })
                },
                delete: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.GroupDestroy({ model })
                }
            }
        });
    }
});
