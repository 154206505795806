import { AllSubstringsIndexStrategy, Search } from 'js-search';

WiseMetering.Views.ForceCircuitSchedule = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/circuits/schedules/force',
    form_prefix: 'circuit',
    width: 1020,
    title: 'Force Schedule',
    ui: {
        results: '#circuit-search-results',
        schedule: '#schedule',
        search: '#circuit-search'
    },
    events: {
        'keyup #circuit-search': 'search'
    },
    defaultSearch: 'Type something on the search above for results',
    noResultsSearch: 'No circuits found',
    scheduleView: null,
    defaultScheduleValues: function() {
        const daily = new Array(96);
        for (let i = 0; i < daily.length; i++) {
            daily[i] = 'on';
        }

        return {
            monday: daily, tuesday: daily, wednesday: daily, thursday: daily,
            friday: daily, saturday: daily, sunday: daily, holiday: daily
        };
    }(),
    input_readers: [{
        type: 'checkbox', func: function(el) {
            if (el.prop('checked')) {
                return el.val();
            }
        }
    }],

    onRender: function() {
        this.initializeSearch();
        this.setScheduleView();
    },

    initializeSearch: function() {
        const allCircuits = WiseMetering.circuits.map(circuit => {
            return {
                id: circuit.id,
                name: circuit.fullPath(),
                searchable: circuit.searchString(),
                systemCategoryName: circuit.get('system_category_name')
            };
        });

        this.index = new Search('id');
        this.index.indexStrategy = new AllSubstringsIndexStrategy();
        this.index.addIndex('searchable');
        this.index.addDocuments(allCircuits);
    },

    save: function() {
        let data = this.getFormData();

        if (_.isArray(data.ids)) {
            data.ids = data.ids.filter(id => id);
        }

        if (_.isEmpty(data.ids)) {
            this.modal.close();
            return;
        }

        this.modal.block();
        $.ajax('/circuits/force_schedules', {
            data: JSON.stringify(data),
            contentType: 'application/json',
            type: 'POST',
            success: function() {
                WiseMetering.circuits.fetch().always(function() {
                    WiseMetering.layout.showTipper('success', `Successfully updated ${data.ids.length} circuit${data.ids.length > 1 ? 's' : ''}`);
                    this.modal.close();
                }.bind(this));
            }.bind(this),
            error: function() {
                WiseMetering.layout.showTipper('error', 'Failed to update circuit schedules');
                this.modal.close();
            }.bind(this)
        });
    },

    search: function(event) {
        const value = event.target.value.toLowerCase().trim();

        if (value.length === 0) {
            this.ui.results.html(this.defaultSearch);

            return;
        }

        const circuits = this.index.search(value);
        this.ui.results.html(JST['templates/circuits/schedules/circuit_list']({
            circuits: circuits.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }));

        let newSystemCategoryName = '';
        if (circuits.every(circuit => circuit.systemCategoryName === 'Lighting')) {
            newSystemCategoryName = 'Lighting';
        }

        if (this.scheduleView.options.systemCategoryName !== newSystemCategoryName) {
            this.setScheduleView(newSystemCategoryName);
        }
    },

    setScheduleView: function(systemCategoryName = '') {
        let periods = ['off', 'on'];
        if (systemCategoryName === 'Lighting') {
            periods = [...periods, 'sun'];
        }

        this.scheduleView = new WiseMetering.Views.CircuitSchedule({ editable: true, periods, values: this.defaultScheduleValues });
        this.ui.schedule.html(this.scheduleView.el);
    }
});
