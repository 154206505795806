WiseMetering.Views.Modal.Custom = Backbone.Marionette.ItemView.extend({
    initialize: function(attributes) {
        const that = this;
        $.extend(that, attributes);
        that.modal = new WiseMetering.Views.Modal.Base({
            buttons: that.buttons,
            callback: that.callback,
            closable: that.closable,
            collection: that.collection,
            form_prefix: that.form_prefix,
            group: that.group,
            indicator: that.indicator,
            indicator_id: that.indicator_id,
            ids: that.ids,
            model: that.model,
            ok: that.ok,
            reset: that.reset,
            save: that.save,
            showErrors: that.showErrors,
            title: that.title,
            ui: that.ui,
            user: that.user,
            validate: that.validate,
            width: that.width,
            yes: that.yes
        });
        WiseMetering.modalBox.show(that.modal);
        that.modal.body.show(that);
    }
});
