WiseMetering.Views.IndicatorsActive = WiseMetering.Views.TableIndex.extend({
    icon: 'indicators',
    name: () => i18next.t('glossary.indicators'),

    createTable: function() {
        this.collection = new WiseMetering.Collection.Indicators(
            WiseMetering.indicators.where({ used: true })
        );

        this.table = new WiseMetering.Views.Table({
            id: 'indicators',
            collection: this.collection,
            modelSerializer: model => {
                let color = 'orange', title = i18next.t('ui.table.unused');
                if (model.get('used')) {
                    color = 'green';
                    title = i18next.t('ui.table.in_use');
                } else if (model.hasFolder()) {
                    color = 'blue';
                    title = i18next.t('ui.table.used_in_folder');
                }
                const used = { color, title };

                let type = model.get('type');
                if (type === 'complex') {
                    type = `${type} (${model.get('operation_type')})`;
                }

                return {
                    building: model.building()?.get('name') || null,
                    id: model.id,
                    kind: model.kind().get('name'),
                    name: model.get('name'),
                    type,
                    updated_at: WiseMetering.utils.formatDate(model.get('updated_at')),
                    used
                };
            },
            columns: {
                used: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[color],
                        title: title.capitalize()
                    }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                type: i18next.t('common.type'),
                kind: i18next.t('glossary.indicator_kind'),
                building: i18next.t('glossary.building'),
                updated_at: i18next.t('glossary.last_change')
            },
            toolbar_actions: {
                refresh: {
                    disabled: false,
                    fetchCollection: WiseMetering.indicators
                },
                new: {
                    label: i18next.t('ui.actions.new'),
                    view_call: () => new WiseMetering.Views.NewIndicator({
                        collection: WiseMetering.indicators,
                        model: new WiseMetering.Model.Indicator
                    })
                },
                edit: {
                    label: i18next.t('ui.actions.edit'),
                    view_call: model => new WiseMetering.Views.EditIndicator({ model })
                },
                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => new WiseMetering.Views.IndicatorModalDetails({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                            afterDestroy: () => WiseMetering.indicators.fetch(),
                            model,
                            title: () => i18next.t('modal.title.indicators.destroy')
                        }
                    )
                }
            }
        });
        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.indicators);
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.indicators, 'remove add change', this.createTable.bind(this));
        this.createTable();
    }
});
