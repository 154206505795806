// Wise Indicator
Lmit.Wise.Components.Controller = function(controller, data, container, options) {
    this.options = options;
    this.data = data;
    this.uri = '/analytics/data.json';
    this.controller = controller;
    this.container = container;
    this.id = Lmit.Utils.generateCode();
    this.body = null;
    this.chart = null;
    this.legend = null;
    this.result = null;
    this.init();
};

Lmit.Wise.Components.Controller.prototype = {

    init: function() {
        this.element = $('<li>').addClass('wmui-chart-components');
        if (this.container instanceof Lmit.Section) {
            this.container.addItem(this);
        } else {
            this.container.append(this.element);
        }
        this.build();
    },

    build: function() {
        this.body = $('<div>').attr({ id: this.id });
        this.element.append(this.body);
    },

    addMsg: function(message) {
        this.body.append($('<div>').addClass('wmui-msg-box').addClass('regular').append(message));
    },

    removeMsg: function() {
        this.body.empty();
    },

    resetChart: function() {
        this.container.show();
        if (this.chart && this.chart.destroy && typeof (this.chart.destroy) === 'function') {
            try {
                this.chart.destroy();
            } catch (e) {
            }
        }
    },

    resetLegend: function() {
        if (this.legend) {
            this.legend.destroy();
            this.legend = null;
        }
    },

    drawLegend: function() {
        this.legend = new Lmit.Wise.Chart.Control.Legend(this, ['a', 'b', 'c', 'd'], this.element, {});
    },

    drawChart: function() {
        this.chart = new Lmit.Wise.Chart.Pie(this, this.element, this.settings, { id: this.id, style: 'blue' });
        this.chart.show();
    },

    redrawChart: function() {
        const { chartController } = this.controller;
        if (chartController.getCurrentInformationType() === 'realtime' && chartController.getCurrentVisualization() === 'plot') {
            this.resetChart();
            this.resetLegend();
            this.fetch();
        }
    },

    attrs: function(args) {
        if (args.period) {
            this.period = args.period;
        }
        if (args.resolution) {
            this.resolution = args.resolution;
        }
        if (args.analyze) {
            this.analyze = args.analyze;
        }
        this.fetch();
    },

    toggleCurve: function(item) {
        if (item.isActive) {
            item.isActive = false;
            this.controller.chartController.removeData(item.code);
        } else {
            item.isActive = true;
            this.controller.chartController.addData({
                id: item.id,
                url: '/analytics/data.json' || item.uri,
                unit: item.unit,
                kind: item.kind,
                name: item.name,
                rate: item.rate,
                color: item.color,
                operation: item.operation,
                code: item.code
            }, true);
        }
    },

    fetch: function() {
        this.removeMsg();

        const
            timezone = WiseMetering.indicators.get(this.controller.uid).timezone(),
            request = {
                components: true,
                from: moment.tz(ParamsService.from, 'YYYY.MM.DD', timezone).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                mode: 'realtime',
                rate: 0,
                to: moment.tz(ParamsService.to, 'YYYY.MM.DD', timezone).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            };

        $.ajax({
            contentType: 'application/json',
            data: request,
            dataType: 'json',
            type: 'GET',
            url: `/indicators/${this.controller.uid}/data`
        }).done(result => this.processDataAndPlot(result.components));
    },

    processDataAndPlot: function(results) {
        const colors = Lmit.Wise.Chart.Defaults.defaultPieChartColors;
        const components = results.map((result, index) => {
            const item = this.data.find(data => data.id === result.id);
            return {
                id: item.id,
                lid: item.id,
                code: item['wmui-tariff-list'],
                name: item.name,
                isActive: false,
                unit: item.unit,
                operation: item.operation,
                value: result.data,
                rate: item.rate,
                kind: item.kind,
                uri: item.uri,
                color: colors[index]
            };
        }).sort((a, b) => b.value - a.value);
        const total = components.reduce((sum, { value }) => sum + value, 0);
        const bugs = components.filter(({ value }) => value < 0);
        if (total > 0) {
            components.forEach(c => c.y = c.value);
            this.result = { total: total, components: components };
            if (bugs.length) {
                console.log(`Bug on indicator${bugs.length > 1 ? 's' : ''}: ${bugs.map(b => b.name).join(', ')}`);
                this.addMsg(i18next.t('ui.no_data_available_for_the_current_period'));
            } else {
                this.resetChart();
                this.drawChart();
                this.chart.addSeries(components);
            }
            this.resetLegend();
            this.drawLegend();
            this.legend.addSeries(components);
        } else {
            this.addMsg(i18next.t('ui.no_data_available_for_the_current_period'));
        }
    }

};
