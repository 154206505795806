module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'allocation_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'allocation_name\' name=\'allocation[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'allocation_from\'>' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'allocation_from\' maxlength=\'10\' name=\'allocation[from]\' value="' +
((__t = ( from )) == null ? '' : __t) +
'">\n        </dd>\n\n        <dt>\n            <label for=\'allocation_fraction\'>' +
((__t = ( i18next.t('glossary.fraction') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'allocation_fraction\' name=\'allocation[fraction]\' type=\'text\' value=\'' +
((__t = ( fraction )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'allocation_utility_kind\'>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'allocation_utility_kind\' class=\'utility_kinds text\'>\n                <option value=\'\' selected=\'selected\'>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 utilityKinds.forEach(function (utilityKind) { ;
__p += '\n                    <option name=\'allocation[utility_kind_name]\' ' +
((__t = ( utilityKind.get('name') === selectedUtilityKind ? 'selected ' : '' )) == null ? '' : __t) +
'value=\'' +
((__t = ( utilityKind.get('name') )) == null ? '' : __t) +
'\'>' +
((__t = ( utilityKind.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'allocation_indicator\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'allocation_indicator\' name=\'allocation[indicator_id]\' class=\'indicators text\'>\n                <option value=\'\' selected=\'selected\'>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 indicators.forEach(function (indicator) { ;
__p += '\n                    <option value=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\'>' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'allocation_tariff\'>' +
((__t = ( i18next.t('glossary.tariff') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'allocation_tariff\' name=\'allocation[tariff_id]\' class=\'tariffs text\'>\n                <option value=\'\' selected=\'selected\'>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 tariffs.forEach(function (tariff) { ;
__p += '\n                    <option value=\'' +
((__t = ( tariff.id )) == null ? '' : __t) +
'\'>' +
((__t = ( tariff.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}