WiseMetering.Model.Report = WiseMetering.Model.extend({
    type: 'report',
    urlRoot: '/reports',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    timezone: function() {
        const resource = this.building() ? this.building() : WiseMetering.currentOrganization;
        return resource.get('timezone');
    },

    reportingYears: function() {
        const
            yearsAvailable = moment().year() - this.referenceYear(),
            retentionYears = WiseMetering.currentOrganization.dataRetention(),
            availableReportingYears = retentionYears > yearsAvailable ? yearsAvailable : retentionYears;

        return Array.from(Array(availableReportingYears + 1).keys()).map( n => moment().year() - n);
    },

    referenceYear: function() {
        const
            resource = this.building() ? this.building() : WiseMetering.currentOrganization,
            date = moment.unix(resource.get('created_at')).tz(this.timezone());

        return date.year();
    }
});
