Lmit.Wise.Chart.Control.ChartType = function(controller, container, options) {
    this.options = options;
    this.controller = controller;
    this.container = container;
    this.view = null;
    this.chartTypes = ['line', 'bar', 'heatmap'];
    this.init();
};

Lmit.Wise.Chart.Control.ChartType.prototype = {

    init: function() {
        if (this.controller.chartType === null) {
            this.controller.chartType = 'line';
        }
        this.view = $('<div>').addClass('wmui-chart-control-resolution');
        this.container.append(this.view);
        this.build();
        this.updateView();
    },

    build: function() {
        let element = $('<ul>').addClass('ctrl');
        element.append($('<li>').addClass('label').text(i18next.t('glossary.chart_type') + ':'));
        this.chartTypes.forEach(type => {
            const iconName = { 'heatmap': 'heatmap', 'line': 'chartLine', 'bar': 'chartBar' }[type];
            const chartTypeElement = $(`<li title="${type.capitalize()}">`).addClass('icon mode').attr({ 'data-chart': type });
            chartTypeElement.append(WiseMetering.icon(iconName));
            element.append(chartTypeElement);
            this.bindItemEvent(chartTypeElement);
        });
        this.view.append(element);
    },

    bindItemEvent: function(item) {
        item.click(() => { this.setChartType($(item).attr('data-chart'), true); });
    },

    enable: function() {
        this.view.fadeIn(400);
    },

    idle: function() {
        this.view.fadeOut(400);
    },

    updateView: function() {
        this.view.children('ul').children('li').removeClass('current');
        this.view.children('ul').children(('li[data-chart=' + this.controller.chartType + ']')).addClass('current');
    },

    setChartType: function(type, fetch) {
        this.controller.onChartTypeUpdate(type);
        this.updateView();
    }
};
