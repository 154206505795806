module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class="stepped zero">\n        <dt>\n            <label for="invoice-cost">' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class="text" id="invoice-cost" name="invoice[cost]" value="' +
((__t = ( cost )) == null ? '' : __t) +
'">\n        </dd>\n\n        <dt>\n            <label for="invoice-consumption">' +
((__t = ( i18next.t('common.consumption') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class="text" id="invoice-consumption" name="invoice[consumption]" value="' +
((__t = ( consumption )) == null ? '' : __t) +
'">\n        </dd>\n\n        <dt>\n            <label for="invoice-reference">' +
((__t = ( i18next.t('common.reference') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <label class="switch">\n                <input style="height:20px" type="checkbox" id="invoice-reference" name="invoice[reference]" ' +
((__t = ( reference ? 'checked' : '' )) == null ? '' : __t) +
'>\n                <span class="slider round"></span>\n            </label>\n        </dd>\n\n        ';
 if (file) { ;
__p += '\n            <div class="file-link" id="file-link">\n                <span style="color:#8BC53F">' +
((__t = ( WiseMetering.icon('download') )) == null ? '' : __t) +
'</span>\n                <a class="link" href=' +
((__t = ( file_link )) == null ? '' : __t) +
' target="_blank">' +
((__t = ( i18next.t('ui.click_here_to_download_file') )) == null ? '' : __t) +
'</a>\n                <a href="#" id="remove-file" style="color:#224C4F"> (' +
((__t = ( i18next.t('common.remove') )) == null ? '' : __t) +
')</a>\n            </div>\n        ';
 } else { ;
__p += '\n            <dt>\n                <label for="upload-file">' +
((__t = ( i18next.t('ui.upload_file') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class="text" accept=".pdf, .jpg" id="upload-file" name="file" type="file"/>\n            </dd>\n        ';
 } ;
__p += '\n\n        <span id="spinner" style="display:none;color:#224C4F">\n            <i class="far fa-spinner fa-spin"></i>\n            ' +
((__t = ( i18next.t(`common.${file ? "removing" : "uploading"}`) )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t('common.file') )) == null ? '' : __t) +
'...\n        </span>\n    </dl>\n</form>\n';

}
return __p
}