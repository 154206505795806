WiseMetering.Analytics = {
    /* returns  current facility with the first two adjacent facilities on the left and right */
    benchmarkAllBuildings: function(period, useArea = false) {
        const
            data = [],
            func = period === 'mtd' ? 'currentMonth' : 'currentYear';

        let buildings = WiseMetering.zones.buildings();
        if (useArea) {
            buildings = buildings.filter(building => building.get('area'));
        }

        buildings.forEach(building => {
            let value = building[func]();

            if (value) {
                if (useArea) {
                    value /= building.get('area');
                }

                data.push({
                    id: building.id,
                    name: building.get('name'),
                    value
                });
            }
        });

        return _.sortBy(data, entry => entry.value).reverse();
    },

    /* returns  current facility with the first two adjacent facilities on the left and right */
    benchmarkCurrentFacilityBy: function(zone_id, kpi, period) {
        let values = [], sorted, position, i, value;

        const
            func = period === 'mtd' ? 'currentMonth' : 'currentYear',
            zone = WiseMetering.zones.get(zone_id);

        if (!zone || !zone[func]()) return;

        if (!_.isNull(kpi)) {
            var filtered = WiseMetering.zones.buildings().filter(function(building) {
                value = building[func]();
                return !_.isEmpty(WiseMetering.zone_attribute_values.where({
                    zone_id: building.id,
                    zone_attribute_id: kpi.id
                })) && value;
            });
            sorted = _.sortBy(filtered, function(building) {
                return building[func]() / _.first(WiseMetering.zone_attribute_values.where({
                    zone_id: building.id,
                    zone_attribute_id: kpi.id
                })).get('current_value');
            });
        } else {
            sorted = _.sortBy(_.filter(WiseMetering.zones.buildings().models, function(building) {
                return building[func]();
            }), function(building) {
                return building[func]();
            });
        }

        if (_.isNull(sorted) || _.isEmpty(sorted) || _.isUndefined(sorted)) return null;

        for (i = 0; i < _.size(sorted); i++){
            if (sorted[i].id === zone_id) {
                position = i + 1;
                break;
            }
        }

        for (i = 0; i < _.size(sorted); i++){
            if (_.contains([position - 2, position - 1, position, position + 1, position + 2], i + 1))
                kpi ? values.push([sorted[i].get('name'), sorted[i][func]() / _.first(WiseMetering.zone_attribute_values.where({
                        zone_id: sorted[i].id,
                        zone_attribute_id: kpi.id
                    })).get('current_value')]) :
                    values.push([sorted[i].get('name'), sorted[i][func]()]);
        }

        return { data: values, pos: position, tot: _.size(sorted) };
    },

    buildingsConsumption: function(period = 'ytd', building_ids = [], utilityKind) {
        const
            data = [],
            func = period === 'mtd' ? 'currentMonth' : 'currentYear';

        let buildings = [];

        if (building_ids.length > 0) {
            building_ids.forEach(building_id => {
                buildings.push(WiseMetering.zones.get(building_id));
            });
        } else {
            buildings = WiseMetering.zones.activeSites();
        }

        buildings.forEach(building => {
            let value = building[func](utilityKind);

            if (value){
                data.push({
                    id: building.id,
                    name: building.get('name'),
                    value,
                    normalization: building.get('area')
                });

            }
        });

        return _.sortBy(data, entry => entry.value).reverse();
    },

    comparisonMainDashboard: function(period = 'ytd', useArea = false, building_ids = []) {
        const func = period === 'mtd' ? 'currentMonth' : 'currentYear';

        let allData = [],
            buildings = [],
            cutoffLimit = 50; // can be part of the costumization in the future
            data = [];

        if (building_ids.length > 0) {
            building_ids.forEach(building_id => {
                buildings.push(WiseMetering.zones.get(building_id));
            });
        } else {
            buildings = WiseMetering.zones.activeSites();
        }

        if (useArea) {
            buildings = buildings.filter(building => building.get('area'));
        }

        buildings.forEach(building => {
            let value = building[func]();

            if (value){
                if (useArea) {
                    value /= building.get('area');
                }

                allData.push({
                    id: building.id,
                    name: building.get('name'),
                    value
                });
            }
        });

        allData.sort((a, b) => b.value - a.value);
        data = allData.slice(0, cutoffLimit);

        if (allData.length > data.length){
            const remainderData = allData.slice(cutoffLimit)

            data.push({
                id: null,
                name: i18next.t('glossary.others').capitalize() + '(' + remainderData.length +')',
                value: remainderData.reduce((acc, value) => acc + value.value, 0)
            });
        }

        return data;
    },

    currentAndHomologousMonth: function(zone_id, greatness) {
        const
            { month, year } = { month: moment().month() + 1, year: moment().year() },
            current = this.getData(zone_id, greatness, year, month),
            partialConsumption = this.getPartialConsumptionData(zone_id, greatness),
            homologous = partialConsumption?.homologous_month ?? null,
            last = partialConsumption?.last_month ?? null;

        return { current, homologous, last };
    },

    getPartialConsumptionData: function(zone_id, greatness){
        const
            zone = WiseMetering.zones.get(zone_id),
            data = zone.get('dashboard_data');

        return data?.[greatness]?.partial_consumption || null;
    },

    currentAndLastYear: function(zone_id, greatness) {
        const
            month = moment().month() + 1,
            year = moment().year(),
            partialConsumption = this.getPartialConsumptionData(zone_id, greatness);

        // In January, we just need to compare the current year with the homologous month
        if (month === 1) {
            return {
                current: this.getData(zone_id, greatness, year, 1),
                last: partialConsumption?.homologous_month ?? null,
            };
        }

        let current = 0, last = 0;

        for (let i = 1; i < month; i++) {
            current += this.getData(zone_id, greatness, year, i);
            last += this.getData(zone_id, greatness, year - 1, i);
        }

        current += this.getData(zone_id, greatness, year, month);
        last += partialConsumption?.homologous_month ?? null;

        return { current, last };
    },

    // circuit and zones consumption data
    distribution: function(rawData, type) {
        const total = rawData['total'];
        const collection = type === 'circuits' ? WiseMetering.circuits : WiseMetering.zones;

        // Filter out the 'total' key and map to name-value pairs
        let data = Object.entries(rawData)
            .filter(([key, _]) => key != 'total')
            .map(([key, value]) => {
                const model = collection.get(key);
                const name = model ? model.get('name') : 'Unknown';

                return { name: name, value: value };
            });

        // Sort the data from biggest to lowest value
        data = _.sortBy(data, 'value').reverse();

        // If the data is bigger than 4 we need to process further because the view only complies with a maximum of 4 values
        const MAX_CHILDS = 4;
        if (data.length > MAX_CHILDS) {
            // Maintain the first 3 and combine the rest into an "Others" category
            const others = data.slice(MAX_CHILDS);
            // And set the sum of the rest into a object with the name Others
            data = data.slice(0, MAX_CHILDS);
            data.push({
                name: `Combined ${type.capitalize()}`, value: _.inject(others, function(memo, child) {
                    return memo + child.value;
                }, 0)
            });
        }

        //Add the difference to total
        const dataTotal = data.map(obj => obj.value).reduce((acc, value) => acc + value, 0);

        data.push({ name: i18next.t(`glossary.others`).capitalize(), value: total - dataTotal });

        return data;
    },

    getAlarmsByUtilityKind: function(utilityKindSlug) {
        const
            allAlarms = WiseMetering.alarms.activeAlarms(),
            slug = utilityKindSlug === 'electricity' ? 'active_energy' : utilityKindSlug;

        let alarms = allAlarms.filter(function(alarm) {
            const indicator = WiseMetering.indicators.get(alarm.get("indicator_id"))

            return indicator.get("kind_slug") === slug
        })

        return alarms;
    },

    getBuildingsWithUtility: function(utilityKindSlug) {
        const buildings = WiseMetering.zones.activeSites();

        let buildingsWithUtility = buildings.filter(function(building){
            let utilityKinds = building.availableUtilityKinds();

            return utilityKinds.some(utilityKind => utilityKind.get('slug') === utilityKindSlug);
        });

        return new WiseMetering.Collection.Zones(buildingsWithUtility);
    },

    getCost: function(zone_id, greatness, year, month) {
        const zone = WiseMetering.zones.get(zone_id);
        if (!zone) {
            console.error(`No zone found with id ${zone_id}`);
            return null;
        }

        const data = zone.get('dashboard_data');
        if (!data || !data[greatness] || !data[greatness]['costs'] || !data[greatness]['costs'][year]) {
            return null;
        }

        if (month) {
            if (!data[greatness]['costs'][year][month - 1] || !data[greatness]['costs'][year][month - 1]['total']) {
                return null;
            }

            const total = data[greatness]['costs'][year][month - 1]['total'];
            if (!total || !total[1]) {
                return null;
            }

            return total[1];
        } else {
            return data[greatness]['costs'][year].reduce((acc, value) => {
                if (!value || !value['total'] || !value['total'][1]) {
                    return acc;
                }
                return acc + value['total'][1];
            }, 0);
        }
    },

    getCurrentAndHomologousConsumption: function(zone_id, utility_kind) {
        const
            zone = WiseMetering.zones.get(zone_id),
            homologousYearData = zone.ytd(utility_kind, (moment().year() - 1)),
            mtdInfo = WiseMetering.Analytics.currentAndHomologousMonth(zone.id, utility_kind),
            homologousYtd = [...homologousYearData.slice(0, moment().month()), mtdInfo.homologous || 0]

        return {
            current: zone.ytd(utility_kind, moment().year()).slice(0, moment().month() + 1),
            homologous: homologousYtd
        };
    },

    getData: function(id, greatness, year, month, type = 'consumption') {
        const
            zone = WiseMetering.zones.get(id),
            data = zone.get('dashboard_data');

        if (!data?.[greatness]?.[type]?.[year]) {
            return null;
        }

        if (month) {
            return data[greatness][type][year][month - 1];
        } else {
            return data[greatness][type][year].reduce((acc, value) => acc + value, 0);
        }
    },

    getLatestEvents: function(utilityKindSlug) {
        const slug = utilityKindSlug === 'electricity' ? 'active_energy' : utilityKindSlug;

        WiseMetering.dashboardEvents.sort();

        if (slug === 'all') {
            return WiseMetering.dashboardEvents.slice(0, 5);
        } else {
            return WiseMetering.dashboardEvents.filter(event => event.indicator().get('kind_slug') === slug).slice(0, 5);
        }
    },

    getIndicatorObjectiveAnalysis: function(indicator, unit = null) {
        const
            type = unit ? 'consumption' : 'cost',
            objective = indicator.objectives().findWhere({
                year: moment().year(),
                utility_kind_id: indicator.utilityKind().id,
                analysis_type: type
            });

        return this.getObjectiveAnalysis(objective);
    },

    getObjectiveAnalysis: function(objective) {
        return _.isUndefined(objective) ? null : {
            real: objective.getRealDataArray(),
            objective: objective.getTargetDataArray(),
            deviation: objective.get('deviation')
        };
    },

    getObjectiveAnalysisByUtility: function(utilityKindSlug, unit){
        let objectiveTotals = new Array(12).fill(0),
            realTotals = new Array(12).fill(0);

        let objectives = WiseMetering.objectives.buildingLevelObjectives(
            utilityKindSlug === 'all' ? null : utilityKindSlug,
            unit ? 'consumption' : 'cost',
            moment().year()
        );

        if (objectives.length === 0){
            return null;
        }

        objectives.forEach((objective) => {
            const
                realCurrent = objective.getRealDataArray(),
                objectiveCurrent = objective.getTargetDataArray();

            for (let i = 0; i < 12; i++) {
                objectiveTotals[i] += objectiveCurrent[i]

                if (realCurrent[i]) {
                    realTotals[i] += realCurrent[i];
                }
            };
        });

        return {
            objective: objectiveTotals,
            real: realTotals
        };
    },

    getObjectivesCompliance: function(year = moment().year()) {
        const allObjectives = WiseMetering.objectives.filter(objective => objective.get("year") === year),
              data = { total: 0, yes: 0, no: 0, percentage: 0 };

        allObjectives.forEach(objective => {
            const
                target = objective.getTargetDataArray(),
                real = objective.getRealDataArray();

            let consumptionTotal = 0,
                targetTotal = 0;

            real.filter(value => value !== null).forEach((monthlyConsumption, i) => {
                let monthyTarget = objective.isKwh() ? target[i] * 1000 : target[i];

                if (i === moment().month()) {
                    monthyTarget *= moment().date() / moment().daysInMonth();
                }

                consumptionTotal += monthlyConsumption;
                targetTotal += monthyTarget;
            });

            targetTotal - consumptionTotal >= 0 ? data['yes'] += 1 : data['no'] += 1;
            data['total'] += 1;
        })

        data['percentage'] = formatValue((data['yes'] * 100) / data['total'], 'N/A', 0);
        return data;
    },

    getOpportunities: function(utilityKindSlug, buildingId = null) {
        let utilityKind = this.getUtilityKind(utilityKindSlug),
            condition = buildingId ? { building_id: buildingId, utility_kind_id: utilityKind.id } : { utility_kind_id: utilityKind.id };
            opportunities = WiseMetering.opportunities.where(condition),
            value = opportunities.reduce((acc, opportunity) => acc + opportunity.get('cost'), 0);

        return {
            value: Lmit.Utils.formatNumber(value, WiseMetering.getCurrency()),
            counter: opportunities.length
        };
    },

    getOpportunitiesAllUtilities: function() {
        const opportunitiesByUtility = WiseMetering.opportunities.groupBy(opportunity => opportunity.get('utility_kind_id')),
              data = [];

        for (const key in opportunitiesByUtility) {
            const
                opportunities = opportunitiesByUtility[key],
                opportunitiesByState = _.groupBy(opportunities, opportunity => opportunity.get('state'));

            const opportunitiesData = Object.entries(opportunitiesByState).map(([state, opportunities]) => {
                const value = opportunities.reduce((acc, opportunity) => acc + opportunity.get('cost'), 0);

                return { color: WiseMetering.utils.getColorByState(state), name: i18next.t(`states.${state}`).capitalize(), value: value };
            });

            data.push({
                data: opportunitiesData,
                subtitle: i18next.t(`utility_kinds.${WiseMetering.utilityKinds.get(key).get('slug')}`).capitalize(),
                unit: WiseMetering.utilityKinds.get(key).get('unit')
            });
        }

        if (data.length <= 0) {
            data.push({
                data: [{ color: WiseMetering.Colors.lightGrey, name: i18next.t('ui.no_data').capitalize(), value: 100 }],
                subtitle: i18next.t('ui.no_data').capitalize(),
                unit: ''
            });
        }

        return data;
    },

    getOpportunitiesTotal: function() {
        let opportunities = WiseMetering.opportunities,
            total = opportunities.reduce((acc, opportunity) => acc + opportunity.get('cost'), 0);

        return total > 0 ? formatValue(total, WiseMetering.getCurrency()) : "0";
    },

    getOpportunitiesTotalByUtility: function(utilityKindSlug) {
        let opportunities = WiseMetering.opportunities.where({ utility_kind_id: this.getUtilityFromSlug(utilityKindSlug).id }),
            total = opportunities.reduce((acc, opportunity) => acc + opportunity.get('cost'), 0);

        return total > 0 ? formatValue(total, WiseMetering.getCurrency()) : "0";
    },

    getTotalExpenditure: function( year = moment().year() ) {
        const zones = WiseMetering.zones.activeSites();

        if (!zones) {
            console.error('No zone found');
            return "N/A";
        }

        const total = zones.reduce((acc, zone) => {
            const data = zone.get('dashboard_data');

            if (!data) {
                return acc;
            }

            Object.keys(data).forEach((key) => {
                if (!data[key] || !data[key]['cost'] || !data[key]['cost'][year]) {
                    return acc;
                }

                const value = data[key]['cost'][year].reduce((acc, value) => {
                    return acc + (value || 0);
                }, 0);

                acc += value;
            });

            return acc;
        }, 0);

        return total > 0 ? formatValue(total, WiseMetering.getCurrency(), 2, 9) : "--";
    },

    getTotalCostsPerSqM: function(year = moment().year(), utilityKindSlug = 'all') {
        const
            zones = utilityKindSlug === 'all' ? WiseMetering.zones.activeSites() : this.getBuildingsWithUtility(utilityKindSlug),
            buildings = zones.buildings();

        if (!buildings || buildings.length === 0) {
            console.error('No building found');
            return "N/A";
        }

        const buildingsWithData = buildings.filter(building => {
            const area = building.get('area');
            return building.get('dashboard_data') && area && area !== 0;
        });

        const data = buildingsWithData.map(building => {
            const
                buildingData = building.get('dashboard_data'),
                name = building.get('name'),
                initials = name.split(" ").map(word => word.charAt(0)).join("");

            let totalCost = 0;

            for (const key in buildingData) {
                if (buildingData.hasOwnProperty(key)) {
                    const costData = buildingData[key]?.cost?.[year];
                    if (costData) {
                        totalCost += costData.reduce((acc, value) => acc + (value || 0), 0);
                    }
                }
            }

            return {
                x: building.get('area'),
                y: Math.round(totalCost),
                z: building.get('area'),
                name: name,
                initial: initials
            };
        });

        return data;
    },

    getTotalUtilityExpenditure: function( utility = 'electricity') {
        const zones = WiseMetering.zones.activeSites();

        if (!zones) {
            console.error('No zones found');
            return null;
        }

        const total = zones.reduce((acc, zone) => {
            const
                values = zone.ytd(utility, moment().year(), 'cost'),
                finalValue = values.reduce((acc, value) => {
                    if (!value) {
                        return acc;
                    }
                    return acc + value;
                }, 0);

            return isNaN(finalValue) ? acc : acc + finalValue;
        }, 0);

        return total > 0 ? formatValue(total, WiseMetering.getCurrency(), 2, 9) : "--";
    },

    getTotalConsumption: function( utilityKind = 'electricity', unit = 'Wh') {
        const zones = WiseMetering.zones.activeSites();

        if (!zones) {
            console.error('No zones found');
            return null;
        }

        const total = zones.reduce((acc, zone) => {
            const values = zone.ytd(utilityKind, moment().year());

            const finalValue = values.reduce((acc, value) => {
                if (!value) {
                    return acc;
                }
                return acc + value;
            }, 0);

            return isNaN(finalValue) ? acc : acc + finalValue;
        }, 0);

        const decimals = (utilityKind === 'water' && total > 99999) ? 0 : 2

        return total > 0 ? formatValue(total, unit, decimals) : "--";
    },

    getUtilityFromSlug: function(utilityKindSlug) {
        return WiseMetering.utilityKinds.findWhere({ slug: utilityKindSlug });
    },

    getUtilityKind: function(utilityKindSlug) {
        return WiseMetering.utilityKinds.findWhere({ slug: utilityKindSlug });
    },

    getUtilityOpportunities: function(utilityKindSlug) {
        let opportunities = null;

        if (utilityKindSlug === 'all'){
            opportunities = WiseMetering.opportunities.models
        } else {
            const utilityKind = this.getUtilityFromSlug(utilityKindSlug),
                  condition = { utility_kind_id: utilityKind.id };

            opportunities = WiseMetering.opportunities.where(condition);
        }

        const opportunitiesByState = _.groupBy(opportunities, opportunity => opportunity.get('state')),
              opportunitiesData = Object.entries(opportunitiesByState).map(([state, opportunities]) => {
                const value = opportunities.reduce((acc, opportunity) => acc + opportunity.get('cost'), 0);
                const color = WiseMetering.utils.getColorByState(state);

                return { color: color, name: i18next.t(`states.${state}`).capitalize(), value: value };
              });

        return opportunitiesData;
    },

    getBuildingsCo2: function() {
        const buildings = WiseMetering.zones.activeSites();

        data = buildings.reduce((result, building) => {
            const buildingData = building.get('dashboard_data');

            if (buildingData.co2) {
                result.push({ name: building.get('name'), value: buildingData.co2 });
            }

            return result;
        }, [])

        return data;
    },

    totalCostByUtility: function(building_ids = []) {
        const year = moment().year();

        let buildings = [],
            data = [],
            utilitiesCost = {};

        if (building_ids.length > 0) {
            building_ids.forEach(building_id => {
                buildings.push(WiseMetering.zones.get(building_id));
            });
        } else {
            buildings = WiseMetering.zones.activeSites();
        }

        buildings.forEach((building) => {
            const buildingData = building.get('dashboard_data');

            Object.keys(buildingData).forEach((key) => {
                if (!buildingData[key] || !buildingData[key]['cost']|| !buildingData[key]['cost'][year]) {
                    return utilitiesCost;
                }

                const value = buildingData[key]['cost'][year].reduce((acc, value) => {
                    return acc + (value || 0);
                }, 0);

                utilitiesCost[key] ? utilitiesCost[key] += value : utilitiesCost[key] = value
            });
        });

        Object.keys(utilitiesCost).forEach((key) => {
            data.push({ name: i18next.t(`utility_kinds.${key}`).capitalize(), value: utilitiesCost[key]})
        })

        return data;
    }
};
