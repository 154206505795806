WiseMetering.Views.ProcessTemp = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'indicator',
    template: 'templates/indicators/process_temp',
    title: () => i18next.t('modal.title.indicators.process'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    save: function() {
        const data = this.formSerializer();

        //Block edition on modal
        this.modal.block();

        $.ajax({
            url: this.model.url() + '/copy_data_to_raw',
            type: 'POST',
            data: data,
            dataType: 'json',
            success: function() {
                WiseMetering.indicators.fetch();
                WiseMetering.layout.showTipper('success', 'Indicator processed sucessfully');
                Backbone.history.navigate(`/indicators/${data.raw_indicator_id}/plot`, { trigger: true });
                this.modal.close();
            }.bind(this),
            error: function(xhr) {
                let errorMessage = `Failed to process indicator: ${xhr.statusText}`;

                let errors = {};
                try {
                    errors = JSON.parse(xhr.responseText);
                    if (errors.error) {
                        errorMessage = errors.error;
                    }
                } catch (e) {
                }

                WiseMetering.layout.showTipper('error', errorMessage, 2000);
                this.modal.unblock();
            }.bind(this)
        });
    },

    filterIndicators: function() {
        return WiseMetering.indicators.filter(function(indicator) {
            const metals = indicator.get('metals');

            if (indicator.get('building_id') !== this.model.get('building_id')) {
                return;
            }
            if (indicator.get('kind_slug') !== this.model.get('kind_slug')) {
                return;
            }
            if (indicator.get('type') !== 'raw') {
                return;
            }

            return metals && metals.length <= 1;
        }.bind(this));
    },

    serializeData: function() {
        const filteredIndicators = this.filterIndicators();

        return {
            rawIndicators: new WiseMetering.Collection.Indicators(filteredIndicators),
            name: this.model.get('name'),
            id: this.model.id,
            type: this.model.get('kind_name')
        };
    }
});
