WiseMetering.Views.IndexActuator = WiseMetering.Views.TableIndex.extend({
    icon: 'actuators',
    name: () => i18next.t('glossary.actuators'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'actuators',
            collection: WiseMetering.actuators,
            modelSerializer: function(model) {
                const json = model.toJSON();
                json.kind = model.kind().get('name');
                json.circuit = model.circuit() ? model.circuit().fullPath() : null;
                return json;
            },
            columns: {
                name: i18next.t('common.name'),
                kind: i18next.t('glossary.indicator_kind'),
                circuit: i18next.t('glossary.connected_to'),
            },
            toolbar_actions: {
                show: {
                    view_call: model => {
                        const circuit = model.circuit();
                        if (circuit) {
                            new WiseMetering.Views.ShowCircuitSchedule({ model: circuit });
                        }
                    }
                }
            }
        });
    }
});
