module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'form_content\'>\n    <form>\n        <div style=\'padding-top: 10px;\'>\n            <dt>\n                <label for=\'notifications\'>' +
((__t = ( i18next.t('common.notifications') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <ul class=\'toolbar regular\' style=\'background:white;\'>\n                    <div class=\'wmui-btn\' id=\'add-notification\'>\n                        ' +
((__t = ( i18next.t('ui.buttons.add') )) == null ? '' : __t) +
'\n                    </div>\n                </ul>\n            </dd>\n            <dd id=\'notifications\'></dd>\n        </div>\n    </form>\n</div>\n';

}
return __p
}