WiseMetering.Views.ZoneAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/zones/accordion',

    onRender: function() {
        const collection = this.model.zones();

        this.table = new WiseMetering.Views.Table({
            id: 'zones',
            collection: collection,
            columns: {
                name: i18next.t('common.name')
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.ZoneAdd({
                        collection: WiseMetering.zones,
                        model: new WiseMetering.Model.Zone({ parent_id: this.model.id })
                    })
                },
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                },
                delete: {}
            }
        });
        this.$('#zones').html(this.table.el);

        this.listenTo(this.model, 'change:children:addZone', function(node) {
            collection.add(node);
        });
        this.listenTo(this.model, 'change:children:removeZone', function(node) {
            collection.remove(node);
        });
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    }
});
