WiseMetering.Views.IndicatorDashboard = Backbone.Marionette.ItemView.extend({
    template: "templates/ui/layout/empty",

    xhr: null,
    view: null,

    onShow() {
        this.xhr = $.ajax({
            url: `/indicators/${this.model.id}?view=presenter`,
            dataType: "json",
            error: () => Backbone.history.navigate('/'),
            success: data => {
                const panelElement = $("<div>").addClass("panel").addClass("full-height").attr("id", Lmit.Utils.generateCode());
                data.period = {
                    from: ParamsService.from,
                    to: ParamsService.to,
                };
                data.alias = ParamsService.rate;
                data.type = this.model.get('type');
                $(this.el).html(panelElement);
                this.view = new Lmit.Wise.Indicator(data, panelElement);
            }
        });
    },

    onClose: function () {
        if (this?.xhr?.abort) {
            this.xhr.abort();
        }
        if (this.view?.close) {
            this.view.close();
        }
    }

});
