WiseMetering.Views.IndexOrganizationNewSites = WiseMetering.Views.TableIndex.extend({
    icon: 'buildings',
    name: function() { return i18next.t('glossary.buildings') },

    createTable: function() {
        this.collection = WiseMetering.zones.newSites();

        this.table = new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: this.collection,
            columns: this.getColumns(),
            id: 'buildings',
            modelSerializer: function(model) {
                let json = {};

                const
                    sitePackage = WiseMetering.sitePackages.findWhere({building_id: model.id}),
                    pack = sitePackage ? WiseMetering.packages.get(sitePackage.get('package_id')) : null;

                json.id = model.id;
                json.name = model.get('name');
                json.packageName = pack ? pack.get('name') : '-';
                json.wiseboxes_default_limit = pack ? pack.get('wiseboxes') : '-';
                json.wiseboxes_extra_limit = pack ? sitePackage.get('wiseboxes_extra_limit') : '-';
                json.wiseboxes_used = pack ? sitePackage.getUsed('wiseboxes') : this.getEmpty();
                json.devices_default_limit = pack ? pack.get('devices') : '-';
                json.devices_extra_limit = pack ? sitePackage.get('devices_extra_limit') : '-';
                json.devices_used = pack ?  sitePackage.getUsed('devices') : this.getEmpty();
                json.output_default_limit = pack ? pack.defaultOutputs() : '-';
                json.output_used = pack ? sitePackage.getUsed('control_outputs') : this.getEmpty();
                json.current_sensor_default_limit = pack ? pack.defaultCurrentSensors() : '-';
                json.current_sensor_used = pack ? sitePackage.getUsed('current_sensors') : this.getEmpty();

                return json;
            }.bind(this),
            toolbar_actions: {
                show: {
                    view_call: model => new WiseMetering.Views.ZoneEdit({ model, title: `Edit ${model.get('name')}` })
                },
                new: {
                    permission: 'superuser',
                    view_call: () => {
                        const model = new WiseMetering.Model.Zone({ tag_ids: [] });
                        new WiseMetering.Views.NewBuilding({ collection: WiseMetering.zones, model, title: 'New Building' });
                    }
                },
                edit: {
                    view_call: model => new WiseMetering.Views.ZoneEdit({ model, title: `Edit ${model.get('name')}` })
                },
                manage_package: {
                    permission: 'superuser',
                    icon: 'package',
                    label: i18next.t('buildings.manage_package'),
                    view_call: building => new WiseMetering.Views.SitePackageEdit({
                        model: building,
                        title: 'Edit building package'
                    })
                },
                activate: {
                    permission: 'superuser',
                    icon: 'play',
                    label: i18next.t('buildings.activate_site'),
                    view_call: function(building) {
                        new WiseMetering.Views.ActivateBuilding({ collection: WiseMetering.zones.newSites(), model: building })
                    }.bind(this)
                },
                delete: {
                    permission: 'manager'
                }
            }
        });
        $(this.body.el).html(this.table.el);
    },

    getColumns: function() {
        if (WiseMetering.currentOrganization.showControlOutputs() ) {
            return Object.assign(this.getDefaultColumns(), this.getRetailColumns());
        } else {
            return this.getDefaultColumns();
        }
    },

    getDefaultColumns: function() {
        return {
            name: i18next.t('common.name'),
            packageName: {
                title: i18next.t('buildings.package_name'),
                mRender: function (packageName) {
                    return `<div style="text-transform: capitalize">${packageName}</div>`;
                }
            },
            wiseboxes_default_limit: {
                title: i18next.t('buildings.wise_boxes_limit'),
                className: 'align-right'
            },
            wiseboxes_extra_limit: {
                title: i18next.t('buildings.extra_wise_boxes_limit'),
                className: 'align-right'
            },
            wiseboxes_used: {
                title: i18next.t('buildings.used_wise_boxes'),
                className: 'align-right',
                mRender: function ({value, deviation, color}) {
                    return deviation === 100 ? value : this.usedEl(value, deviation, color);
                }.bind(this)
            },
            devices_default_limit: {
                title: i18next.t('buildings.devices_limit'),
                className: 'align-right'
            },
            devices_extra_limit: {
                title: i18next.t('buildings.extra_devices_limit'),
                className: 'align-right'
            },
            devices_used: {
                title: i18next.t('buildings.used_devices'),
                className: 'align-right',
                mRender: function ({value, deviation, color}) {
                    return deviation === 100 ? value : this.usedEl(value, deviation, color, 30);
                }.bind(this)
            }
        }
    },

    getRetailColumns: function() {
        return {
            output_default_limit: {
                title: i18next.t('buildings.control_outputs_limit'),
                className: 'align-right'
            },
            output_used: {
                title: i18next.t('buildings.used_control_outputs'),
                className: 'align-right',
                mRender: function ({value, deviation, color}) {
                    return deviation === 100 ? value : this.usedEl(value, deviation, color);
                }.bind(this)
            },
            current_sensor_default_limit: {
                title: i18next.t('buildings.current_sensors_limit'),
                className: 'align-right'
            },
            current_sensor_used: {
                title: i18next.t('buildings.used_current_sensors'),
                className: 'align-right',
                mRender: function ({value, deviation, color}) {
                    return deviation === 100 ? value : this.usedEl(value, deviation, color);
                }.bind(this)
            }
        }
    },

    getEmpty: function () {
        return {
            value: 0,
            deviation: 0.0,
            color: WiseMetering.Colors.lightGrey
        }
    },

    onClose: function() {
        this.stopListening(WiseMetering.zones);
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.zones, 'add change', this.createTable.bind(this));
        this.createTable();
    },

    usedEl: function(value, deviation, color, width = 15) {
        return `
            <b style="font-size:8px;color:${color}">(${Number(deviation).toFixed(1)}%)</b>
            <div style="display:inline-block;width:${width}px">${value}</div>
        `;
    },
});
