WiseMetering.Views.IndexZonesByBuilding = WiseMetering.Views.TableIndex.extend({
    icon: 'zones',
    name: () => i18next.t('glossary.zone'),

    createTable: function createTable() {
        const collection = this.model.zones();

        let table = new WiseMetering.Views.Table({
                id: 'zones',
                collection: collection,
                columns: {
                    name: i18next.t('common.name')
                },
                toolbar_actions: {
                    new: {
                        view_call: () => new WiseMetering.Views.ZoneAdd({
                            collection: WiseMetering.zones,
                            model: new WiseMetering.Model.Zone({
                                parent_id: this.model.type() === 'root-object' ? this.model.building().id : this.model.id
                            })
                        })
                    },
                    show: {
                        view_call: model => Backbone.history.navigate(model.nodeUrl(), {trigger: true})
                    },
                    delete: {
                        view_call: model => new WiseMetering.Views.Modal.Destroy({
                            model,
                            title: () => i18next.t('modal.title.zones.destroy')
                        })
                    }
                }
            });

        this.listenTo(this.model, 'change:children:addZone', function (node) {
            collection.add(node);
        });
        this.listenTo(this.model, 'change:children:removeZone', function (node) {
            collection.remove(node);
        });

        return table
    }
});
