module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="legend">\n    ';
 if (homologous.is_valid) { ;
__p += '\n        <div class="past-name title" style="background: ' +
((__t = ( colors[2] )) == null ? '' : __t) +
' ">\n            ' +
((__t = ( homologous.title )) == null ? '' : __t) +
' (' +
((__t = ( type )) == null ? '' : __t) +
')\n        </div>\n        <div class="past-value value">\n            ' +
((__t = ( homologous.formatted )) == null ? '' : __t) +
'\n        </div>\n    ';
 } ;
__p += '\n\n    <div class="current-name title" style="background-color:' +
((__t = ( colors[0] )) == null ? '' : __t) +
'">\n        ' +
((__t = ( current.title )) == null ? '' : __t) +
' (' +
((__t = ( type )) == null ? '' : __t) +
')\n    </div>\n    <div class="current-value value ' +
((__t = ( deviation ? 'comparison' : 'no-comparison' )) == null ? '' : __t) +
'">\n        ' +
((__t = ( current.formatted )) == null ? '' : __t) +
'\n    </div>\n    ';
 if (deviation) { ;
__p += '\n        <div class="percentage value" style="background-color:' +
((__t = ( current.value - homologous.value > 0 ? WiseMetering.SemaphorColors.red : WiseMetering.SemaphorColors.green )) == null ? '' : __t) +
'">\n            ' +
((__t = ( deviation )) == null ? '' : __t) +
'\n        </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}