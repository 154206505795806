module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'indicator_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'indicator_name\' name=\'indicator[name]\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for=\'indicator_description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text small\' id=\'indicator_description\' name=\'indicator[description]\'>' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n        </dd>\n        <div id=\'type-container\'></div>\n    </dl>\n</form>\n';

}
return __p
}