WiseMetering.Views.ZoneAutomationTab = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    template: 'templates/zones/automation',

    regions: {
        details: '#details-accordion'
    },

    onRender: function() {
        if (WiseMetering.currentUser.isSuperUser()) {
            this.details.show(new WiseMetering.Views.ZoneAutomationWiseBoxesAccordion({
                model: this.model
            }));
        }
    }
});
