module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'accordion-group\'>\n    <div class=\'accordion-heading\'>\n        <div class=\'accordion-toggle\'>\n            <div class=\'svg_icon\'>\n                ' +
((__t = ( WiseMetering.icon('allocations') )) == null ? '' : __t) +
'\n            </div>\n            <span>' +
((__t = ( i18next.t('allocations.active_allocations'))) == null ? '' : __t) +
' (' +
((__t = ( length )) == null ? '' : __t) +
')</span>\n        </div>\n    </div>\n    <div class=\'accordion-body accordion-body-fix\'>\n        <div class=\'accordion-inner\' id=\'active-allocations\'></div>\n    </div>\n</div>\n';

}
return __p
}