WiseMetering.Views.OrganizationTiersAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/organizations/tiers_accordion',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click .checkbox': 'onCheckboxClicked',
        'click @ui.changeTier': 'onChangeTierClicked'
    },

    ui: {
        changeTier: '#change-tier'
    },

    initialize: function() {
        this.selectedTier = this.model.tier();
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json.tierName = this.selectedTier.get('name');
        json.allowedTransitions = this.model.getAllowedTransitions();
        return json;
    },

    onCheckboxClicked: function(event) {
        const newTier = event.currentTarget.dataset.tier;
        this.updateTier(newTier);
    },

    onChangeTierClicked: function(event) {
        event.preventDefault();

        const newTier = event.currentTarget.dataset.tier;
        this.updateTier(newTier);
    },

    updateTier: function(tierName) {
        this.selectedTier = WiseMetering.tiers.findWhere({ name: tierName });
        this.model.save({ tier_id: this.selectedTier.id }, {
            url: `${this.model.urlRoot}/${this.model.id}/actions/change_tier`,
            patch: true,
            wait: true,
            success: function() {
                WiseMetering.layout.showTipper('success', i18next.t('tiers.changed_successfully', { tier: this.selectedTier.get('name') }));
                setTimeout(() => location.reload(), 1000);
            }.bind(this),
            error: function(_, xhr) {
                WiseMetering.layout.showTipper('error', i18next.t('tiers.contact_support'));
                this.selectedTier = this.model.tier();
                this.render();
            }.bind(this)
        });
    }
});
