module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span style="font-weight:bold; color: ' +
((__t = ( point.color.replace(opacity, 1) )) == null ? '' : __t) +
'">\n    ' +
((__t = ( point.name )) == null ? '' : __t) +
':\n</span>\n' +
((__t = ( fomattedValue )) == null ? '' : __t) +
' <b>(' +
((__t = ( value )) == null ? '' : __t) +
'%)</b>\n';
 if (notes) { ;
__p += '\n    <br>\n    <span style="font-size: 10px">' +
((__t = ( notes )) == null ? '' : __t) +
'</span>\n';
 } ;
__p += '\n';

}
return __p
}