WiseMetering.Views.IndicatorDetails = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/details',

    modelEvents: {
        'change': 'render'
    },

    serializeData: function() {
        let json = this.model.toJSON();

        json.building = this.model.building();
        json.creator = this.model.creator()?.get('name') || 'System';
        json.etl_name = this.model.etl()?.get('name');
        json.folders = this.model.folderNames(true);
        json.kind = this.model.kind().get('name');
        json.operation_name = this.model.operation_name(true);
        json.parent = this.model.parent();
        json.participations = this.model.participationsName(true);
        json.raw_operation_name = this.model.raw_operation_name(true);
        json.timezone = this.model.timezone();
        json.updated_at = moment.unix(json.updated_at).format('YYYY/MM/DD HH:mm');
        json.updater = this.model.updater()?.get('name') || 'System';

        return json;
    }
});
