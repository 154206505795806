Lmit.Wise.Chart.Pie = function(_controller, _container, _settings, _options){
    this.init(_controller, _container, _settings, _options);
};

Lmit.Wise.Chart.Pie.prototype = new Lmit.Wise.Chart.Generic();
Lmit.Wise.Chart.Pie.prototype.type_of = 'components';
Lmit.Wise.Chart.Pie.prototype.show = function(){ this.plotData(); };

Lmit.Wise.Chart.Pie.prototype.build = function(){ // build is overriden to avoid opacity 0
    // getting parent width avoids chart being misplaced when components section is hidden
    this.view = $('<div>').addClass('wmui-hc wmui-hc-' + this.type_of).attr({ id: this.id }).css({ width: $(this.container).parent().parent().width() });
    this.container.append(this.view);
};

Lmit.Wise.Chart.Pie.prototype.destroy = function(){
//    if (this.chart || this.view.find('.highcharts-container').length > 0){
    var thiz = this;
//        $(this.view).find('.highcharts-container').parent().animate({ opacity: 0 }, 200, function(){
    if (thiz.chart !== null && thiz.chart !== undefined && thiz.chart.destroy){ thiz.chart.destroy(); }
    thiz.view.remove();
//        }).delay(400);
//    }
};


Lmit.Wise.Chart.Pie.prototype.plotData = function(){
    this.chart = new Highcharts.Chart(this.chartSettings());
};

Lmit.Wise.Chart.Pie.prototype.addSeries = function(data){
    this.chart.addSeries({
        type: 'pie',
        name: 'Component',
        size: '85%',
        innerSize: '75%',
        data: data
    });
};

Lmit.Wise.Chart.Pie.prototype.defaultSettings = function(){
    var thiz = this;
    return {
        chart: {
            renderTo: thiz.id,
            margin: [0, 0, 0, 0],
            plotBackgroundColor: 'none',
            plotBorderWidth: 0,
            plotShadow: false,
            backgroundColor: null
        },
        exporting: {
            enabled: false
        },
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false,
            symbolWidth: 10,
            symbolHeight: 10,
            style: {
                left: 5,
                bottom: 10,
                right: 'auto',
                top: 'auto'
            },
            itemStyle:{
                fontSize: "10px",
                color: 'black',
                fontWeight: 'normal'
            },
            borderWidth: 0,
            labelFormatter: function() {
                return this.name + '<input type="checkbox" checked="">';
            },
            floating: false
        },
        tooltip: {
            formatter: function() {
                return '<b>'+ this.point.name +'</b><br>'+ Lmit.Utils.formatNumber(this.y, this.point.unit) + " (" + this.percentage.toFixed(2) + "%)";
            },
            borderRadius : 0,
            borderWidth: 1,
            borderColor: "#e3e3e3",
            style: {
                color: '#333333',
                fontSize: '11px',
                padding: '5px'
            },
            shadow: false
        },
        plotOptions: {
            pie: {
                borderRadius: 0,
                shadow: false,
                point: {
                    events: {
                        legendItemClick: function(e) {
                            // thiz.add({name:e.point.options.name , id:e.point.options.id, kind: "raw"})
                            thiz.controller.toggleCurve(this);
                            return false;
                        }
                    }
                },
                size: '100%',
                innerSize: '75%',
                animation: true,
                dataLabels: {enabled: false},
                showInLegend: true,
                events:{
                    click: function(e){
                        thiz.open({ name:e.point.options.name, id:e.point.options.id, kind:e.point.options.kind, uri:e.point.options.uri });
                    }
                },
                allowPointSelect: false,
                showCheckbox: true
            }
        },
        series: []

    };
};

Lmit.Wise.Chart.Pie.prototype.open = function(_args){
    this.controller.controller.open(_args);
};

Lmit.Wise.Chart.Pie.prototype.chartSettings = function(){
    var _settings = {
        chart: {
            height: 180,
            renderTo: this.id
        },
        events: {
            addSeries: function(e){
                $(this.container).parent().delay(400).animate({ opacity: 1 }, 400);
            }
        }
    };
    jQuery.extend(true, _settings, this.defaultSettings());
    return _settings;
};
