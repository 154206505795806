WiseMetering.Views.Modal.Save = Backbone.Marionette.ItemView.extend({
    buttons: ['save', 'cancel'],
    class: 'form_content',
    modal: null,

    formSerializer: function(excludeParams = []) {
        if (this.input_readers) {
            this.input_readers.forEach(ir => Backbone.Syphon.InputReaders.register(ir.type, ir.func));
        }
        return Backbone.Syphon.serialize(this, { exclude: excludeParams })[this.form_prefix];
    },

    initialize: function(attributes) {
        $.extend(this, attributes);
        this.modal = new WiseMetering.Views.Modal.Base({
            afterSave: this.afterSave,
            building_id: this.building_id,
            buttons: this.buttons,
            circuit_id: this.circuit_id,
            collection: this.collection,
            formSerializer: this.formSerializer,
            folder_id: this.folder_id,
            form_prefix: this.form_prefix,
            indicator_id: this.indicator_id,
            input_readers: this.input_readers,
            model: this.model,
            options: this.options,
            parent: this.parent,
            resize: this.resize,
            reset: this.reset,
            save: this.save.bind(this),
            title: this.title,
            to_edit: this.to_edit,
            validate: this.validate ? this.validate.bind(this) : null,
            width: this.width,
            zone_id: this.zone_id
        });
        WiseMetering.modalBox.show(this.modal);
        this.modal.body.show(this);
    },

    getFormData: function() {
        return this.formSerializer();
    },

    computeChangedAttributes: function(data) {
        return this.model.changedAttributes(data);
    },

    save: function() {
        //Serializes form
        let data = this.getFormData();
        //Detects if there is any change on parameters
        //If not, closes the modal
        let changed = this.computeChangedAttributes(data);
        if (!changed) {
            this.modal.close();
            return;
        }

        //Unbinds temporary model validation and removes error messages
        const complete = () => {
            this.$('label').removeClass('err');
            this.$('label').find('.err-help').remove();
            $(this.modal.errors.el).removeClass('active').empty();
        };

        //Block edition on modal
        this.modal.block();

        //Saves changed information on model
        this.submitData(changed)
        .done(data => {
            //Runs completion fn
            complete();

            //Adds returned information to collection
            if (this.collection) {
                this.collection.add(data);
            }

            //Shows success message and closes modal
            const done = () => {
                WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_saved'));
                this.modal.close();
            };

            //Checks if there's something to do before saving
            if (this.modal.afterSave && typeof (this.modal.afterSave) === 'function') {
                const afterSave = this.modal.afterSave();
                afterSave ? afterSave.done(done) : done();
            } else {
                done();
            }
        })
        .fail(xhr => {
            //Runs completion fn
            complete();

            // try to parse errors
            let errors = {};
            try {
                errors = JSON.parse(xhr.responseText);
            } catch (e) {
            }

            // show them
            _(errors.errors).each((message, param) => {
                let errorLabel = this.$(`label[for='${param}']`);

                if (errorLabel.length === 0) {
                    errorLabel = this.$(`label[for='${this.form_prefix}_${param}']`);
                }

                if (errorLabel.length === 0) {
                    errorLabel = this.$(`label[for='${this.form_prefix}_${param}_id']`);
                }

                if (errorLabel.length === 0) {
                    errorLabel = this.$(`label[for='${this.form_prefix}-${param}']`);
                }

                if (errorLabel.length === 0) {
                    errorLabel = this.$(`label[for='${this.form_prefix}-${param}-id']`);
                }

                if (errorLabel.length === 0) {
                    errors.error = message;
                }

                errorLabel.addClass('err');
                errorLabel.append(`<span class="err-help" id="question-${this.form_prefix}_${param}"><i class="${WiseMetering.icons.question_circle}" aria-hidden='true'></i></span>`);
                errorLabel.find(`#question-${this.form_prefix}_${param}`).mouseover(() => {
                    $.stt({ target: errorLabel, text: message });
                });
                errorLabel.find(`#question-${this.form_prefix}_${param}`).mouseout(() => {
                    $('div.stt').remove();
                });
            });
            if (errors.error) {
                $(this.modal.errors.el).addClass('active').html(errors.error);
            }
            this.modal.unblock();
        });
    },

    submitData: function(data) {
        return this.model.save(data, { wait: true, patch: true });
    }
});
