import moment from 'moment-timezone';

WiseMetering.Views.NewMarker = WiseMetering.Views.Modal.Save.extend({
    DAY_RATE: 24 * 3600,
    WEEK_RATE: 7 * 24 * 3600,
    MONTH_RATE: 30 * 24 * 3600,
    template: 'templates/markers/new',
    form_prefix: 'event',
    width: 800,
    className: 'test-name',
    title: () => i18next.t('modal.title.markers.new'),

    ui: {
        calculatedResults: '#calculated-results'
    },

    events: {
        'click input.has-length-checkbox': 'onHasLengthChecked',
        'click input.has-no-length-checkbox': 'onHasNoLengthChecked',
        'click input#one-day': 'onEventLengthChecked',
        'click input#one-week': 'onEventLengthChecked',
        'click input#one-month': 'onEventLengthChecked',
        'change select#event-reference-period': 'changeEventReferencePeriodStart',
        'change div#event-with-length select#event-has-measurable-impact': 'onMeasurableImpactChange'
    },

    afterSave: function() {
        WiseMetering.markers.fetch();
    },

    calculateImpact: function() {
        this.$('.fa-spinner').show();
        this.ui.calculatedResults.hide();
        this.removeTemporaryErrorNotifications();
        this.model.getCalculatedImpact(this.getFormData(), this.form_prefix);
    },

    changeEventReferencePeriodStart: function() {
        const timezone = this.timezone();

        const
            referenceDuration = $('select#event-reference-period').val(),
            eventAt = moment.tz($('input#event-at-date').val(), timezone),
            referencePeriodStartInput = $('input#event-custom-reference-period-start');

        if (referenceDuration) {
            referencePeriodStartInput.prop('disabled', true);
            this.updateCustomReferenceDate(this.getReferenceStartAtDate(eventAt, referenceDuration));
            return;
        }

        const eventDuration = $('input#event-duration').val();
        referencePeriodStartInput.prop('disabled', false);

        referencePeriodStartInput.datetimepicker('destroy');
        referencePeriodStartInput.datetimepicker({
            dateFormat: 'yy-mm-dd',
            changeHour: eventDuration === `${this.DAY_RATE}`,
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            timeFormat: eventDuration === `${this.DAY_RATE}` ? 'HH:00' : '00:00',
            minDate: moment.tz(timezone).add(-2, 'years').startOf('year').add(1, 'day').toDate(),
            maxDate: moment.tz(timezone).endOf('day').toDate(),
            onSelect: function(dateText) {
                this.updateCustomReferenceDate(dateText);
            }.bind(this)
        });
    },

    getEventDatetimePicker: function(options) {
        const eventAtDateEl = $('input#event-at-date');

        let predefined = {
            dateFormat: 'yy-mm-dd',
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            onSelect: function(dateText) {
                eventAtDateEl.val(dateText);
                this.changeEventReferencePeriodStart();
            }.bind(this)
        };

        eventAtDateEl.datetimepicker('destroy');
        eventAtDateEl.datetimepicker(Object.assign({}, predefined, options));
    },

    getFormData: function() {
        const data = this.formSerializer();

        return {
            ...data,
            indicator_id: this.options.indicator_id
        };
    },

    getReferenceStartAtDate: function(eventAt, duration) {
        if (duration === this.DAY_RATE || parseInt(duration) === this.DAY_RATE) {
            return eventAt.add(-1, 'day').format('YYYY-MM-DD HH:mm');
        } else if (duration === this.WEEK_RATE || parseInt(duration) === this.WEEK_RATE) {
            return eventAt.add(-1, 'week').format('YYYY-MM-DD HH:mm');
        } else if (duration === this.MONTH_RATE || parseInt(duration) === this.MONTH_RATE) {
            return eventAt.add(-1, 'month').format('YYYY-MM-DD HH:mm');
        }
    },

    onEventLengthChecked: function(event) {
        const timezone = this.timezone();

        $('input#event-duration').val(event.currentTarget.value);
        $('select#event-has-measurable-impact').prop('selectedIndex', 0);
        $('.impact-analysis-box').hide();
        $('.custom-reference-period').hide();
        $('div.reference-period').hide();
        $('div#event-date-and-time').show();
        $('div#event-with-length').show();

        let dateTimePickerOptions, dateValue;

        switch (event.currentTarget.value) {
            case this.DAY_RATE.toString():
                dateValue = moment.tz(timezone).format('YYYY-MM-DD HH:00');
                dateTimePickerOptions = {
                    changeMinute: false,
                    timeFormat: 'HH:00',
                    minDate: moment.tz(timezone).add(-2, 'years').startOf('year').add(1, 'day').toDate(),
                    maxDate: moment.tz(timezone).endOf('day').toDate()
                };
                break;
            case this.WEEK_RATE.toString():
                dateValue = moment.tz(timezone).format('YYYY-MM-DD 00:00');
                dateTimePickerOptions = {
                    changeMinute: false,
                    changeHour: false,
                    timeFormat: '00:00',
                    minDate: moment.tz(timezone).add(-2, 'years').startOf('year').add(1, 'week').toDate(),
                    maxDate: moment.tz(timezone).endOf('day').toDate()
                };
                break;
            case this.MONTH_RATE.toString():
                dateValue = moment.tz(timezone).format('YYYY-MM-DD 00:00');
                dateTimePickerOptions = {
                    changeMinute: false,
                    changeHour: false,
                    timeFormat: '00:00',
                    minDate: moment.tz(timezone).add(-2, 'years').startOf('year').add(1, 'month').toDate(),
                    maxDate: moment.tz(timezone).endOf('day').toDate()
                };
                break;
        }

        $('input#event-at-date').val(dateValue);

        this.getEventDatetimePicker(dateTimePickerOptions);
    },

    onHasLengthChecked: function() {
        $('div#event-date-and-time').hide();
        $('div#event-test-length-form').show();
    },

    onHasNoLengthChecked: function() {
        const timezone = this.timezone();

        $('dd#event-test-length-options > input').prop('checked', false);

        $('.impact-analysis-box').hide();
        $('div#test-length-form').hide();
        $('div#event-with-length').hide();
        $('div#event-test-length-form').hide();
        $('div#event-date-and-time').show();
        $('input#event-measurable-impact').val(0);
        $('input#event-at-date').datetimepicker('destroy');

        let dateTimePickerOptions = {
            stepMinute: 15,
            changeMinute: true,
            timeFormat: 'HH:mm',
            minDate: moment.tz(timezone).add(-2, 'years').startOf('year').toDate(),
            maxDate: moment.tz(timezone).endOf('day').toDate()
        };

        this.getEventDatetimePicker(dateTimePickerOptions);
    },

    onMeasurableImpactChange: function(e) {
        let eventHasMeasurableImpact = $(e.currentTarget).val();
        let referencePeriodSection = $(e.currentTarget).siblings('.reference-period');
        let referencePeriodSelect = $(e.currentTarget).siblings('.reference-period').children('select#event-reference-period');
        let referencePeriodStart = $('input#event-reference-period-start-value');
        let referencePeriodLength = $('input#event-reference-period-length');
        let customReferencePeriodSection = $(e.currentTarget).siblings('.custom-reference-period');

        if (eventHasMeasurableImpact === '1') {
            let options = [];
            let testLength = $('dd#event-test-length-options > input:checked').val();
            referencePeriodSelect.empty();
            referencePeriodLength.val(testLength);

            if (testLength === this.DAY_RATE.toString()) {
                options = options.concat([[i18next.t('markers.compare_with_yesterday'), 86400], [i18next.t('markers.compare_with_last_week'), 604800]]);
            } else if (testLength === this.WEEK_RATE.toString()) {
                options = options.concat([[i18next.t('markers.compare_with_last_week'), 604800]]);
            }
            options = options.concat([[i18next.t('markers.compare_with_last_month'), 2592000], [i18next.t('markers.compare_with_custom'), '']]);

            options.forEach(option => referencePeriodSelect.append(
                $('<option>').attr({ value: option[1] }).html(option[0])
            ));

            referencePeriodSection.css({ 'display': 'inline-block' });
            referencePeriodSelect.change();
            $('.custom-reference-period').show();
            $('.impact-analysis-box').show();
        } else {
            referencePeriodLength.removeAttr('value');
            referencePeriodStart.removeAttr('value');
            customReferencePeriodSection.hide();
            referencePeriodSection.hide();
            $('.impact-analysis-box').hide();
        }
    },

    onShow: function() {
        this.onHasNoLengthChecked();
    },

    removeTemporaryErrorNotifications: function() {
        this.$('label').removeClass('err');
        this.$('label').find('.err-help').remove().unbind('mouseover mouseout');
        $(this.modal.errors.el).removeClass('active').empty();
    },

    timezone: function() {
        const indicator = WiseMetering.indicators.get(this.options.indicator_id);
        const building = indicator.building();

        return building?.get('timezone') || 'UTC';
    },

    updateCustomReferenceDate: function(dateText) {
        $('input#event-custom-reference-period-start').val(dateText);
        $('input#event-reference-period-start-value').val(dateText);
        this.calculateImpact();
    }
});
