WiseMetering.Views.UtilitiesRootObject = WiseMetering.Views.Content.extend({
    initialize({ model, view }) {
        const hasPermissions = WiseMetering.currentOrganization.hasStandardPermissions();

        this.icon = 'utilities';
        this.tabs = [
            {
                active: view === 'details',
                href: `/${model.nodeUrl()}/details`,
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views[hasPermissions ? 'UtilitiesIndex' : 'NoAccess']
            }
        ];

        if (hasPermissions) {
            this.tabs.push({
                active: view === 'costs',
                id: 'costs',
                href: `/${model.nodeUrl()}/costs`,
                name: i18next.t('common.costs'),
                view: WiseMetering.Views.UtilitiesCosts
            })
        }
    }
});
