// Wise Indicator
Lmit.Wise.Chart.Control.Period = function(controller, container, options) {
    this.options = options;
    this.controller = controller;
    this.container = container;
    this.period = null;
    this.alias = options.alias || null;
    this.view = null;
    this.init();
};

Lmit.Wise.Chart.Control.Period.prototype = {
    init: function() {
        this.observers = [];
        if (this.options.observers) {
            this.options.observers.forEach(observer => this.register(observer));
        }
        this.view = $('<div>').addClass('wmui-chart-control-period');
        this.container.append(this.view);
        this.build();
    },

    register: function(item) {
        this.observers.push(item);
        return true;
    },

    notifyObservers: function() {
        this.observers.forEach(item => item.onPeriodChange(this.period.from, this.period.to));
    },

    onPeriodChange: function(from, to) {
        this.period = { from: from, to: to };
        this.clear();
    },

    build: function() {
        let element = $('<ul>').addClass('ctrl');
        element.append($('<li>').addClass('label').text(`${i18next.t('common.period')}:`));
        const periods = [
            { value: '1d', text: i18next.t('periods.1d') },
            { value: '1w', text: i18next.t('periods.1w') },
            { value: '1m', text: i18next.t('periods.1m') },
            { value: '3m', text: i18next.t('periods.3m') },
            { value: '6m', text: i18next.t('periods.6m') },
            { value: '1y', text: i18next.t('periods.1y') },
            { value: 'ytd', text: i18next.t('periods.ytd') }
        ];
        periods.forEach(period => {
            const periodCtrl = $('<li>').addClass('mode').attr({ 'data-period': period.value }).text(period.text);
            element.append(periodCtrl);
            this.bindItemEvent(periodCtrl);
        });
        this.view.append(element);
    },

    bindItemEvent: function(item) {
        const period = $(item).attr('data-period');
        item.click(() => this.setPeriod(period));
    },

    evalPeriod: function(alias, period) {
        const tz = this.controller.chart.timezone;

        if (period && period.from && period.to) {
            return {
                from: moment.tz(period.from, 'YYYY.MM.DD', tz).toDate(),
                to: moment.tz(period.to, 'YYYY.MM.DD', tz).toDate()
            };
        }

        const current = moment.tz(
            this.controller.getPeriodFromDateTimeController().to || new Date(), tz
        );

        const now = moment.tz(new Date(), tz);

        const start = current.clone().startOf('day');
        const end = current.clone().endOf('day');

        let from, to;

        switch (alias) {
            case '1h':
                from = end.clone().subtract(1, 'hour').clone().toDate();
                to = end.clone().toDate();
                break;
            case '1d':
                from = start.toDate();
                to = end.toDate();
                break;
            case '1w':
                from = start.subtract(7, 'days').toDate();
                to = end.toDate();
                break;
            case '1m':
                from = start.subtract(1, 'month').toDate();
                to = end.toDate();
                break;
            case '3m':
                from = start.subtract(3, 'months').toDate();
                to = end.toDate();
                break;
            case '6m':
                from = start.subtract(6, 'months').toDate();
                to = end.toDate();
                break;
            case '1y':
                from = start.subtract(1, 'year').toDate();
                to = end.toDate();
                break;
            case 'ytd':
                from = now.clone().startOf('year').toDate();
                to = now.clone().endOf('day').toDate();
                break;
            default:
                throw new Error('Invalid period selected');
        }

        return { from, to, alias };
    },

    updateView: function() {
        this.view.children('ul').children('li').removeClass('current');
        this.view.children('ul').children(('li[data-period=' + this.alias + ']')).addClass('current');
    },

    setPeriod(alias, period) {
        this.alias = alias;
        this.period = this.evalPeriod(alias, period);
        ParamsService.setParams({
            from: moment(this.period.from).format('YYYY.MM.DD'),
            to: moment(this.period.to).format('YYYY.MM.DD')
        });
        this.updateView();
        this.notifyObservers();
    },

    clear: function() {
        this.view.children('ul').children('li').removeClass('current');
    },

    idle: function() {
        this.view.hide();
    },

    enable: function() {
        this.view.show();
    }

};
