module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion-group\'>\n    <div class=\'accordion-heading\'>\n        <div class=\'accordion-toggle\'>\n            <div class="svg_icon">\n                ' +
((__t = ( WiseMetering.icon('details') )) == null ? '' : __t) +
'\n            </div>\n            ' +
((__t = ( i18next.t('common.details') )) == null ? '' : __t) +
'\n            ';
 if ( WiseMetering.currentUser.get('manager') ) { ;
__p += '\n                <div class=\'wmui-btn\' id=\'edit_details\' style=\'float:right\'>' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'</div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n\n    ';
 if(isBuilding) { ;
__p += '\n    <div class=\'accordion-body\' style=\'background:white;\'>\n        <div class=\'accordion-inner building-details\'>\n        ';
 } else { ;
__p += '\n        <div class=\'accordion-body collapse in\' id=\'collapse-details\' style=\'background:white;padding-bottom:10px;\'>\n            ';
 } ;
__p += '\n            <div class=\'per-box\' style=\'float: left;\'>\n                <dl>\n                    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
':</dt>\n                    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n\n                    ';
 if(description) { ;
__p += '\n                        <dt>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( description )) == null ? '' : __t) +
'</dd>\n                    ';
 } ;
__p += '\n\n                    ';
 if(category) { ;
__p += '\n                        <dt>' +
((__t = ( i18next.t('common.category') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( category )) == null ? '' : __t) +
'</dd>\n                    ';
 } ;
__p += '\n\n                    ';
 if(isBuilding) { ;
__p += '\n                        ';
 if(area) { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('common.area') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( area )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n\n                        ';
 if(capacity) { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('common.capacity') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( capacity )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n\n                        <dt>' +
((__t = ( i18next.t('common.tags') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( tag_names.join(', ') || '-' )) == null ? '' : __t) +
'</dd>\n\n                        <dt>' +
((__t = ( i18next.t('common.latitude') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( address.latitude )) == null ? '' : __t) +
'</dd>\n\n                        <dt>' +
((__t = ( i18next.t('common.longitude') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( address.longitude )) == null ? '' : __t) +
'</dd>\n\n                        <dt>' +
((__t = ( i18next.t('glossary.timezone') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( timezone )) == null ? '' : __t) +
'</dd>\n\n                        ';
 if(typeof electricity_reference !== 'undefined' ) { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('zones.electricity_reference_period') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( electricity_reference )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n\n                        ';
 if(typeof gas_energy_reference !== 'undefined') { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('zones.gas_energy_reference_period') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( gas_energy_reference )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n\n                        ';
 if(typeof hot_water_energy_reference !== 'undefined') { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('zones.hot_water_reference_period') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( hot_water_energy_reference )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n\n                        ';
 if(typeof cooled_water_energy_reference !== 'undefined') { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('zones.cooled_water_reference_period') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( cooled_water_energy_reference )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n\n                        ';
 if(typeof water_reference !== 'undefined') { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('zones.water_reference_period') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( water_reference )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n\n                        ';
 if(typeof gas_volume_reference !== 'undefined') { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('zones.gas_volume_reference_period') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( gas_volume_reference )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n\n                        ';
 if(typeof oil_reference !== 'undefined') { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('zones.oil_reference_period') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( oil_reference )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n                    ';
 } ;
__p += '\n                </dl>\n            </div>\n            ';
 if (isBuilding && image_url) { ;
__p += '\n            <div class=\'per-box\'>\n                <img class="preview-logo-image" src="' +
((__t = ( image_url )) == null ? '' : __t) +
'" alt="logo"/>\n            </div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n';

}
return __p
}