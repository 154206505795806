WiseMetering.Model.RootObject = WiseMetering.Model.extend({
    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    children: function() {
        let building = WiseMetering.zones.get(this.get('building_id'));
        return building.children(this.get('slug'));
    },

    circuits: function() {
        return this.building().circuits();
    },

    folders: function() {
        return this.building().folders();
    },

    inactiveTenants: function() {
        return this.building().inactiveTenants();
    },

    icon: function() {
        return this.get('slug');
    },

    isAvailable: function() {
        if (this.get('slug') === 'utilities') {
            return WiseMetering.currentOrganization.hasStandardPermissions();
        } else if (['tenants', 'seus'].includes(this.get('slug'))) {
            return WiseMetering.currentOrganization.hasAdvancedPermissions();
        } else {
            return true;
        }
    },

    nodeUrl: function(skipSearch) {
        return `/root_objects/${this.id}${skipSearch ? '' : location.search}`;
    },

    parent: function() {
        return this.building();
    },

    seus: function() {
        return this.building().seus();
    },

    slug: function() {
        return this.get('name').toLowerCase();
    },

    tenants: function() {
        return this.building().tenants();
    },

    type: function() {
        return 'root-object';
    },

    utilities: function() {
        return this.building().utilities();
    },

    zones: function() {
        return this.building().zones();
    }
});
