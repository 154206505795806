import es from './es.json';
import pt from './pt.json';

WiseMetering.Views.Table = Backbone.View.extend({
    className: 'wmui-table-view',
    customDeleteEvent: null,
    defaultIcons: {
        activate: 'activate',
        custom_delete: 'delete',
        custom_edit: 'edit',
        delete: 'delete',
        delete_all: 'delete',
        edit: 'edit',
        export: 'excel',
        generate: 'redo',
        manage: 'manage',
        new: 'add',
        open_indicator: 'indicators',
        refresh: 'refresh',
        test: 'test',
        zip: 'zip'
    },
    defaultPermissions: {
        activate: 'administrator',
        custom_delete: 'superuser',
        custom_edit: 'manager',
        delete: 'manager',
        delete_all: 'manager',
        edit: 'manager',
        generate: 'manager',
        manage: 'manager',
        new: 'manager',
        test: 'manager'
    },
    defaultText: function() {
        return {
            activate: i18next.t('ui.actions.activate'),
            custom_delete: i18next.t('ui.actions.delete'),
            custom_edit: i18next.t('ui.actions.edit'),
            delete: i18next.t('ui.actions.delete'),
            delete_all: i18next.t('ui.actions.delete'),
            edit: i18next.t('ui.actions.edit'),
            export: i18next.t('ui.actions.export'),
            generate: i18next.t('ui.actions.generate'),
            manage: i18next.t('ui.actions.manage'),
            new: i18next.t('ui.actions.new'),
            open_indicator: i18next.t('ui.actions.open_indicator'),
            refresh: i18next.t('ui.actions.refresh'),
            test: i18next.t('ui.actions.test'),
            zip: i18next.t('ui.actions.zip')
        };
    },
    disabled: [
        'activate',
        'custom_delete',
        'custom_edit',
        'delete',
        'delete_all',
        'edit',
        'export',
        'generate',
        'open_indicator',
        'test',
        'zip'
    ],
    eventActions: {
        activate: 'toolbarActivateAction',
        custom_delete: 'toolbarCustomDeleteAction',
        custom_edit: 'toolbarCustomEditAction',
        delete: 'toolbarDeleteAction',
        delete_all: 'toolbarDeleteAllAction',
        edit: 'toolbarEditAction',
        export: 'toolbarExportAction',
        generate: 'toolbarGenerateAction',
        manage: 'toolbarManageAction',
        new: 'toolbarNewAction',
        open_indicator: 'toolbarOpenIndicatorAction',
        refresh: 'toolbarRefreshAction',
        test: 'toolbarTestAction',
        zip: 'toolbarToZipAction'
    },
    oTable: null,
    tagName: 'div',

    events: function() {
        let events = {
            'keyup input[type="search"]': 'updateUrl'
        };

        events['click #' + this.options.id + ' tbody tr td input.table_row_checkbox'] = 'checkSelectRow';
        events['click #select_all_checkbox'] = 'toolbarSelectAllAction';
        events['click .dataTables_paginate > a'] = 'uncheckSelected';
        events['click #' + this.options.id + ' tbody tr td' + (this.options.disableSelect ? '' : ':not(:first-child)')] = 'toolbarShowAction';

        if (this.options.hasOwnProperty('toolbar_actions')) {
            _(this.options.toolbar_actions).each(function(event, action) {
                if (this.options.toolbar_actions.hasOwnProperty(action) && action !== 'select_all') {
                    events[`click div#ui-button-${action}:not(.disabled_icon)`] = this.eventActions[action] || function() {
                        const id = this.$('tr.checked:first').attr('id'), model = this.collection.get(id);
                        this.options.toolbar_actions[action].view_call(model);
                    }.bind(this);
                }
            }, this);
        }

        return events;
    },

    updateUrl: function(event) {
        if (event) {
            searchService.update(this.id, $(event.target).val());
            return;
        }

        const searchElement = this.$('input[type="search"]'), searchValue = searchService.fetch(this.id);

        searchElement.val(searchValue).trigger('keyup');

        searchService.update(this.id, searchValue);
    },

    initialize: function() {
        this.render();
        this.updateUrl();

        if (!this.options.hasOwnProperty('disableSelect')) {
            this.options.disableSelect = false;
        }
        if (!this.options.toolbar_actions) {
            this.options.toolbar_actions = {};
        }
        if (WiseMetering.currentUser.get('manager') && !this.options.hasOwnProperty('editable') && !this.options.hasOwnProperty('custom')) {
            this.delegateCallbacks();
        }
        if (WiseMetering.currentUser.get('manager') && this.options.hasOwnProperty('custom')) {
            this.delegateCustomCallbacks();
        }
    },

    renderToolbar: function() {
        const toolbar = this.options.toolbar_actions || {};
        let buttons = '<div class="dataTables_toolbar">';

        Object.keys(toolbar).forEach(action => {
            // Special case - doesn't appear in the toolbar
            if (['show', 'select_all'].includes(action)) {
                return;
            }

            const permission = toolbar[action].permission || this.defaultPermissions[action];

            if (!permission || permission && WiseMetering.currentUser.get(permission)) {
                buttons += this.renderButton(action);
            }
        });

        buttons += "</div>";

        this.$(`#${this.options.id}`).before(buttons);
    },

    renderButton: function(action) {
        return JST['templates/ui/table/icon']({
            action: action,
            disabled: !['manage', 'new', 'refresh'].includes(action),
            icon: this.options.toolbar_actions[action].icon || this.defaultIcons[action],
            label: this.options.toolbar_actions[action].label || this.defaultText()[action]
        });
    },

    renderDataTable: function() {
        let columns = [], aaData;

        if (this.options.disableSelect !== true) {
            let showSelectAll = Object.keys(this.options.toolbar_actions).includes('select_all');

            columns.push({
                mDataProp: null,
                orderable: false,
                sClass: 'control checkbox',
                sTitle: showSelectAll ? `<input type='checkbox' id='select_all_checkbox'>` : '',
                sDefaultContent: `<input type='checkbox' class='table_row_checkbox'>`,
                targets: 'no-sort'
            });
        }

        Object.keys(this.options.columns).forEach(key => {
            let obj;

            if (typeof this.options.columns[key] === 'object') {
                obj = this.options.columns[key];
            } else {
                obj = {
                    sTitle: this.options.columns[key]
                };
            }

            if (this.options.types && this.options.types[key]) {
                obj.type = this.options.types[key];
            }
            if ((this.options.hasOwnProperty('custom') && this.options.custom.checkbox) || !this.options.hasOwnProperty('custom')) {
                obj.mDataProp = key;
            }
            if (this.options.hasOwnProperty('custom')) {
                obj.sClass = key;
            }

            columns.push(obj);
        }, this);

        if (this.options.hasOwnProperty('custom')) {
            aaData = this.collection;
        } else if (this.options.modelSerializer) {
            aaData = this.collection.map(model => this.options.modelSerializer(model));
        } else {
            aaData = this.collection.toJSON();
        }

        // Default properties for every table
        let tableProperties = {
            bAutoWidth: false,
            bProcessing: true,
            aoColumns: columns,
            aaData: aaData,
            bJQueryUI: true,
            sPaginationType: 'full_numbers',
            bSort: this.options.sort === undefined ? true : this.options.sort,
            bPaginate: this.options.paginate === undefined ? true : this.options.paginate,
            bFilter: this.options.filter === undefined ? true : this.options.filter,
            bInfo: this.options.info === undefined ? true : this.options.info,
            bLengthChange: this.options.length === undefined ? true : this.options.length,
            bDeferRender: true,
            bSortClasses: false,
            order: this.getOrder(),
            createdRow: this.options.createdRow
        };

        if (this.options.disableSelect !== true) {
            tableProperties['aoColumns'][0]['sWidth'] = '20px';
        }

        if (!this.options.hasOwnProperty('custom')) {
            tableProperties.fnRowCallback = (row, data) => {
                $(row).attr('id', data.id);
            }; // this callback is used to add model id to the row class set.
        }

        if (this.options.aLengthMenu) {
            tableProperties['aLengthMenu'] = this.options.aLengthMenu;
            tableProperties['iDisplayLength'] = this.options.aLengthMenu[0];
        } else {
            tableProperties['aLengthMenu'] = [15, 50, 100];
            tableProperties['iDisplayLength'] = 15;
        }

        if (WiseMetering.userPreferences.language() === 'pt') {
            tableProperties['language'] = pt;
        } else if (WiseMetering.userPreferences.language() === 'es') {
            tableProperties['language'] = es;
        } else {
            // en it's the default
        }

        this.oTable = this.$('table#' + this.options.id).dataTable(tableProperties);
    },

    getOrder: function() {
        if (this.options.sort) {
            return [
                Object.keys(this.options.columns).indexOf(this.options.sort.column) + 1,
                this.options.sort.order
            ];
        } else {
            return [];
        }
    },

    delegateCustomCallbacks: function() {
        this.on('ignoreMetals', this.ignoreMetalsCallback, this);
        this.on('customEdit', this.customEditCallback, this);
    },

    delegateCallbacks: function() {
        this.listenTo(this.collection, 'add', this.addCallback);
        this.listenTo(this.collection, 'remove', this.deleteCallback);
        if (!this.options.accordion) {
            _.each(this.collection.models, function(model) {
                this.listenTo(model, 'change', this.editCallback);
            }, this);
        }
    },

    render: function() {
        this.$el.html(JST['templates/ui/table/show']({ id: this.options.id }));
        this.renderDataTable();
        this.renderToolbar();
    },

    checkSelectRow: function({ currentTarget }) {
        const deleteMultiple = this.options.multiple_selection === true ||
            this.options.toolbar_actions.hasOwnProperty('delete_all') ||
            this.options.toolbar_actions.hasOwnProperty('custom_delete');

        if (currentTarget.checked && !deleteMultiple) {
            const rows = $(this.fnGetSelected(this.oTable));
            rows.removeClass('checked');
            rows.find('input[type=checkbox]:checked').prop('checked', false);
        }
        $(currentTarget.parentNode.parentNode).toggleClass('checked');
        this.countSelectedRows();
    },

    uncheckAll: function() {
        this.$(`#${this.options.id} tbody tr`).each(function() {
            $(this).removeClass('checked');
            $(this).find('input:checked').prop('checked', false);
        });
    },

    uncheckSelected: function(e) {
        if (!$(e.currentTarget).hasClass('ui-state-disabled')) {
            const rows = $(this.fnGetSelected(this.oTable));
            rows.removeClass('checked');
            rows.children('.control.checkbox').children().prop('checked', false);
            this.countSelectedRows();
        }
    },

    countSelectedRows: function() {
        const rowCount = this.fnGetSelected(this.oTable).length;

        Object.keys(this.options.toolbar_actions).forEach(action => {
            if (['manage', 'new', 'refresh', 'select_all', 'show'].includes(action)) {
                // do nothing
            } else if (['custom_delete', 'delete_all', 'generate'].includes(action)) {
                this.$(`div#ui-button-${action}`)[rowCount > 0 ? 'removeClass' : 'addClass']('disabled_icon');
            } else if (['export', 'zip'].includes(action)) {
                this.$(`div#ui-button-${action}`)[rowCount > 1 ? 'removeClass' : 'addClass']('disabled_icon');
            } else {
                this.$(`div#ui-button-${action}`)[rowCount === 1 ? 'removeClass' : 'addClass']('disabled_icon');
            }
        });

        return rowCount;
    },

    toolbarSelectAllAction: function() {
        if (this.$('#select_all_checkbox').get(0).checked) {
            this.$('#' + this.options.id + ' tbody tr td input.table_row_checkbox').prop('checked', true);
            this.$('#' + this.options.id + ' tbody tr').addClass('checked');
        } else {
            this.$('#' + this.options.id + ' tbody tr td input.table_row_checkbox').prop('checked', false);
            this.$('#' + this.options.id + ' tbody tr').removeClass('checked');
        }
        this.countSelectedRows();
    },

    toolbarShowAction: function(e) {
        if (this.options.toolbar_actions && this.options.toolbar_actions.show && this.options.toolbar_actions.show.view_call) {
            this.options.toolbar_actions.show.view_call(this.collection.get($(e.currentTarget.parentNode).attr('id')));
        }
    },

    toolbarEditAction: function(e) {
        if (!$(e.currentTarget).hasClass('disabled_icon')) {
            const t = this.$('#' + this.options.id + ' tbody tr.checked')[0];
            $(t).removeClass('checked');
            $(t).children('.control.checkbox').children().prop('checked', false);
            this.countSelectedRows();
            this.options.toolbar_actions.edit.view_call(this.collection.get($(t).attr('id')));
        }
    },

    toolbarManageAction: function() {
        this.options.toolbar_actions.manage.view_call();
    },

    toolbarToZipAction: function() {
        const ids = _(this.fnGetSelected(this.oTable)).collect(function(row) {
            return $(row).attr('id');
        });
        window.open(this.collection.addToZipUrl(ids));
    },

    toolbarExportAction: function() {
        const ids = _(this.fnGetSelected(this.oTable)).collect(function(row) {
            return $(row).attr('id');
        });
        window.open(this.collection.exportDataUrl(ids));
    },

    toolbarNewAction: function() {
        this.options.toolbar_actions.new.view_call();
    },

    // Delete more than one model at the same time. For Backbone Models.
    toolbarDeleteAction: function(e) {
        if (!$(e.currentTarget).hasClass('disabled_icon')) {
            const id = this.$('#' + this.options.id + ' tbody tr.checked:first').attr('id'),
                model = this.collection.get(id);
            if (this.options.toolbar_actions.delete.view_call) {
                this.options.toolbar_actions.delete.view_call(model);
            } else {
                new WiseMetering.Views.Modal.Destroy({ model: model });
            }
        }
    },

    toolbarDeleteAllAction: function() {
        new WiseMetering.Views.DeleteAll({
            collection: this.collection,
            ids: _(this.fnGetSelected(this.oTable)).collect(function(row) {
                return $(row).attr('id');
            })
        });
    },

    toolbarGenerateAction: function() {
        new WiseMetering.Views.Generate({
            collection: this.collection,
            ids: _(this.fnGetSelected(this.oTable)).collect(row => $(row).attr('id'))
        });
    },

// Delete more than on model at the same time and used for custom data source (not backbone models)
    toolbarCustomDeleteAction: function() {
        const selected = this.fnGetSelected(this.oTable);
        if (selected.length > 0) {
            const timestamps = [];
            _.each(selected, function(selected) {
                timestamps.push($(selected).find('.timestamp').first().html());
            });
            this.options.toolbar_actions.custom_delete.view_call({
                target: this,
                timestamps
            });
        }
    },

    toolbarCustomEditAction: function() {
        const selected = this.fnGetSelected(this.oTable);
        if (selected.length === 1) {
            const $el = $(selected[0]);
            this.options.toolbar_actions.custom_edit.view_call({
                error: !!$el.find('.ignored').first().html(),
                timestamp: $el.find('.timestamp').first().html(),
                value: $el.find('.value').first().html(),
                target: this
            });
        }
    },

    toolbarOpenIndicatorAction: function(e) {
        if (!$(e.currentTarget).hasClass('disabled_icon')) {
            const id = this.$('#' + this.options.id + ' tbody tr.checked:first').attr('id');
            this.options.toolbar_actions.open_indicator.view_call(this.collection.get(id));
        }
    },

    toolbarRefreshAction: function(e) {
        if (this.options.toolbar_actions['refresh'].hasOwnProperty('fetchCollection')) {
            this.options.toolbar_actions['refresh'].fetchCollection.fetch();
        } else {
            this.collection.fetch();
        }
    },

    toolbarActivateAction: function(e) {
        if (!$(e.currentTarget).hasClass('disabled_icon')) {
            const id = this.$('tr.checked:first').attr('id'), model = this.collection.get(id);
            this.options.toolbar_actions.activate.view_call(model);
        }
    },

    toolbarTestAction: function(event) {
        if (!$(event.currentTarget).hasClass('disabled_icon')) {
            const id = this.$('tr.checked:first').attr('id'), model = this.collection.get(id);
            this.options.toolbar_actions.test.view_call(model);
        }
    },

    deleteCallback: function(model) {
        try {
            this.oTable.fnDeleteRow($('tr#' + model.id)[0]);
            this.countSelectedRows();
        } catch (error) {
            console.log(error);
        }
    },

    ignoreMetalsCallback: function() {
        _.each(this.fnGetSelected(this.oTable), function(selected) {
            $(selected).find('td.ignored').html('yes');
        }, this);
    },

    customEditCallback: function(record) {
        const position = this.oTable.fnGetPosition(this.fnGetSelected(this.oTable)[0]);
        this.oTable.fnUpdate(record.value, position, 3);
        this.oTable.fnUpdate(record.error ? 'yes' : null, position, 5);
    },

    editCallback: function(model) {
        // http://datatables.net/forums/discussion/comment/6946#Comment_6946
        try {
            this.oTable.fnUpdate(
                this.options.modelSerializer ? this.options.modelSerializer(model) : model.toJSON(),
                $(this.oTable.fnGetNodes()).filter('tr#' + model.id)[0]
            );
        } catch (error) {
            console.log(error);
        }
    },

    addCallback: function(model) {
        const data = this.options.modelSerializer ? this.options.modelSerializer(model) : model.toJSON();
        this.oTable.fnAddData(data);
        if (!this.options.accordion) {
            this.delegateNewCallback(model);
        }
    },

    delegateNewCallback: function(model) {
        this.listenTo(model, 'change', this.editCallback);
    },

    fnGetSelected: function(oTable) {
        return oTable.$('tr.checked');
    }
});
