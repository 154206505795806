// Wise Indicator
Lmit.Wise.Export = {};
Lmit.Wise.Export.Controller = function(controller, container, options) {
    this.components = options.components;
    this.container = container;
    this.controller = controller;
    this.element = null;
    this.form = null;
    this.indicator = options.indicator;
    this.url = options.uri;
    this.init();
};

Lmit.Wise.Export.Controller.prototype = {
    init: function() {
        this.element = $('<li>').addClass('wmui-export');
        if (this.container instanceof Lmit.Section) {
            this.container.addItem(this);
        } else {
            this.container.append(this.element);
        }
        this.build();
    },

    build: function() {
        this.form = $('<div>').attr({ id: this.id }).addClass('wmui-form');
        this.element.append(this.form);
        this.addForm();
    },

    onAnaliseChange: function(state) {
        // state === active means Analysis is active; state === disabled means Realtime is active;

        const
            $components = this.form.find('.export_components, select[name=export_components]'),
            $costs = this.form.find('.include_costs, select[name=include_costs]'),
            $extension = this.form.find('.export_extension, select[name=export_extension]'),
            $resolution = this.form.find('.export_resolution, select[name=export_resolution]');

        if (state === 'active') {
            $components.css('display', 'none');
            $costs.css('display', 'none');
            $resolution.css('display', 'none');
        } else {
            $components.css('display', 'initial');
            $costs.css('display', 'initial');
            $resolution.css('display', 'initial');
        }
    },

    onCompareChange: function(state, size) {
    },

    addForm: function() {
        let dl = $('<dl>');

        dl.append($('<dt>').append($(`<label class="export_extension" style="text-transform: capitalize;">`).text(i18next.t('common.format'))));
        let extensionSelect = $('<select>').attr({ name: 'export_extension' });
        let extensions = ['.xls', '.xlsx'];
        _.each(extensions, function(extension) {
            extensionSelect.append($('<option>').attr({ value: extension }).text(extension));
        });
        dl.append($('<dd>').append(extensionSelect));

        dl.append($('<dt>').append($(`<label class="export_resolution" style="text-transform: capitalize;">`).text(i18next.t('common.resolution'))));
        let resolutionSelect = $('<select>').attr({ name: 'export_resolution' });
        let resolutions = [
            { value: '15m', text: i18next.t('periods.15m') },
            { value: '1h', text: i18next.t('periods.1h') },
            { value: '1d', text: i18next.t('periods.1d') },
            { value: '1m', text: i18next.t('periods.1m') },
            { value: '1y', text: i18next.t('periods.1y') }
        ];
        _.each(resolutions, function(resolution) {
            resolutionSelect.append($('<option>').attr({ value: resolution.value }).text(resolution.text));
        });
        dl.append($('<dd>').append(resolutionSelect));

        dl.append($('<dt>').append($('<label style="text-transform: capitalize;">').text(i18next.t('glossary.unit'))));
        let unitSelect = $('<select style="text-transform: capitalize;">').attr({ name: 'export_ratio' });
        let units = [[i18next.t('common.none'), 1], ['Kilo', 1000], ['Mega', 1000000]];
        _.each(units, function(u) {
            unitSelect.append($('<option>').attr({ value: u[1] }).text(u[0]));
        });
        dl.append($('<dd>').append(unitSelect));

        if (this.components) {
            dl.append($('<dt>').append($(`<label class="export_components" style="text-transform: capitalize;">`).text(i18next.t('indicators.components'))));
            let componentSelect = $('<select>').attr({ name: 'export_components' });
            let components = [[i18next.t('common.no'), false], [i18next.t('common.yes'), true]];
            _.each(components, function(a) {
                componentSelect.append($('<option>').attr({ value: a[1] }).text(a[0]));
            });
            dl.append($('<dd>').append(componentSelect));
        }

        dl.append($('<dt>').append($(`<label class="include_costs" style="text-transform: capitalize;">`).text(i18next.t('indicators.export_section.include_costs?'))));
        const includeCosts = $('<select style="text-transform: capitalize;">').attr({ name: 'include_costs' });
        [{ text: i18next.t('common.no'), value: '' }, { text: i18next.t('common.yes'), value: true }].forEach(option => {
            includeCosts.append($('<option>').attr({ value: option.value }).text(option.text));
        });
        dl.append($('<dd>').append(includeCosts));

        this.form.append(dl);
        this.addToolbar();
    },

    addToolbar: function() {
        const
            that = this,
            btnPostDelegate = { onSelect: () => that.process() };

        new Lmit.UI.Toolbar('Post', [['wmui-btn', i18next.t('glossary.export'), {
            id: 'save',
            style: { 'float': 'right' }
        }, btnPostDelegate]], this.element, { 'style': 'form' });
    },

    getParams: function() {
        const timezone = this.indicator.timezone();

        let params = {};

        params.rate = $(this.form).find('select[name=export_resolution]').val();
        params.ratio = $(this.form).find('select[name=export_ratio]').val();
        params.extension = $(this.form).find('select[name=export_extension]').val();
        params.include_costs = $(this.form).find('select[name=include_costs]').val();
        params.from = moment.tz(ParamsService.from, 'YYYY.MM.DD', timezone).startOf('day').unix();
        params.to = moment.tz(ParamsService.to, 'YYYY.MM.DD', timezone).endOf('day').unix();
        params.mode = this.controller.chartController.getCurrentInformationType();

        if (params.mode === 'analysis') {
            params.grouped_by = this.controller.chartController.analyze[0];
            params.average = this.controller.chartController.analyze[1];
            params.components = false;
        } else {
            const export_select = $(this.form).find('select[name=export_components]');
            if (!$(export_select).attr('disabled')) {
                params.components = $(export_select).val();
            }
        }

        return params;
    },

    process: function() {
        Lmit.Utils.mbxElement().mbx({
            type: 'show',
            title: i18next.t('indicators.export_section.export_indicator'),
            width: 350,
            height: 216,
            auto_close: false
        });

        $.mbx.block(`<p class="small" style="font-size: 10px;">${i18next.t('indicators.export_section.please_wait')}</p>`);

        $.ajax({
            url: this.url,
            data: this.getParams(),
            dataType: 'binary',
            xhrFields: {
                responseType: 'blob'
            },
            error: this.doError.bind(this),
            success: this.doResult.bind(this)
        });
    },

    doResult: function(response, _status, xhr) {
        let filename = '';
        const disposition = xhr.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        const
            url = window.URL || window.webkitURL,
            blob = new Blob([response], { type: 'octet/stream' }),
            blobUrl = url.createObjectURL(blob);

        const displayName = _.last(filename.split('/')).substring(0, 22) + '...';

        let export_view = $('<div>').addClass('exported');
        let icon = $('<div>').addClass('icon xlsx');
        let btn = $('<div>').addClass('wmui-btn-download').append($('<div>').addClass('wmui-btn').text(displayName));
        let desc = $('<p>').addClass('center desc').text(`${i18next.t('indicators.export_section.file_size')}: ${Math.round(blob.size / 1024 * 100) / 100}Kb`);

        export_view.append(icon).append(btn).append(desc);

        $.mbx.clear();
        $.mbx.append(export_view);

        const getFile = function() {
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = filename;
            link.click();

            WiseMetering.layout.showTipper('info', i18next.t('ui.file_successfully_downloaded'));

            // cleanup and close the box
            url.revokeObjectURL(blobUrl);
            $.mbx.close();
        };

        icon.click(function() {
            btn.click();
        });

        btn.click(getFile);
    },

    doError: function(xhr) {
        let export_view = $('<div>').addClass('exported');
        let icon = $('<div>').addClass('icon error');
        let desc = $('<p>').addClass('center desc error').text(i18next.t('indicators.export_section.export_error'));
        export_view.append(icon).append(desc);
        $.mbx.clear();
        $.mbx.append(export_view);
    }
};
