WiseMetering.Model.Utility = WiseMetering.Model.extend({
    urlRoot: '/utilities',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    capacitiveEnergy: function() {
        if (this.get('capacitive_energy_id')) {
            return WiseMetering.indicators.get(this.get('capacitive_energy_id'));
        }

        return null;
    },

    children: function() {
        return this.contracts();
    },

    contracts: function() {
        const contracts = WiseMetering.contracts.where({ utility_id: this.get('id') });
        return new WiseMetering.Collection.Contracts(contracts);
    },

    finish: function() {
        return this.contracts().last().get('finish');
    },

    icon: function() {
        const utilityKind = this.utilityKind();
        return WiseMetering.icons.getIconFromUtilityKind(utilityKind.get('slug'))
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    indicatorsByUtility: function(utilityKindName) {
        if (utilityKindName === 'Electricity') {
            utilityKindName = 'active-energy';
        }

        return WiseMetering.indicators.where({ building_id: this.get('building_id'), kind_slug: utilityKindName });
    },

    inductiveEnergy: function() {
        if (this.get('inductive_energy_id')) {
            return WiseMetering.indicators.get(this.get('inductive_energy_id'));
        }

        return null;
    },

    nextAvailableDate: function() {
        const lastContract = this.contracts().last();
        if (lastContract) {
            return moment(lastContract.get('finish'), 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
        } else {
            return '2020-01-01';
        }
    },

    nodeUrl: function(skipSearch) {
        return `/utilities/${this.get('id')}/details${skipSearch ? '' : location.search}`;
    },

    parent: function() {
        return WiseMetering.rootObjects.findWhere({ building_id: this.get('building_id'), slug: 'utilities' });
    },

    start: function() {
        return this.contracts().first().get('start');
    },

    type: function() {
        return 'utility';
    },

    unit: function() {
        return this.utilityKind().get('unit');
    },

    utilityKind: function() {
        return WiseMetering.utilityKinds.get(this.get('utility_kind_id'));
    }
});
