module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (isNew) { ;
__p += '\n    <dt>\n        <label for="indicator_etl_id">ETL</label>\n    </dt>\n    <dd>\n        <select class="text" id="indicator_etl_id" name="indicator[etl_id]">\n            ';
 etls.each(function(etl) { ;
__p += '\n                <option value="' +
((__t = ( etl.id )) == null ? '' : __t) +
'">' +
((__t = ( etl.get('name') )) == null ? '' : __t) +
'</option>\n            ';
 }); ;
__p += '\n        </select>\n    </dd>\n';
 } ;
__p += '\n\n<dt>\n    <label for="indicator_etl_reference">' +
((__t = ( i18next.t('indicators.etl_reference') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input id=\'indicator_etl_reference\' class="text" name="indicator[etl_reference]" placeholder="Insert indicator id on bms" value=\'' +
((__t = ( etl_reference )) == null ? '' : __t) +
'\'/>\n</dd>\n<dt>\n    <label for="indicator_ratio">' +
((__t = ( i18next.t('glossary.ratio') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input id=\'indicator_ratio\' class="text" name="indicator[ratio]" placeholder="Insert indicator ratio" value=\'' +
((__t = ( ratio )) == null ? '' : __t) +
'\'/>\n</dd>\n\n';
 if (!isNew) { ;
__p += '\n    <dt>\n        <input class=\'vertical-middle\' ' +
((__t = ( stopped ? 'checked' : '' )) == null ? '' : __t) +
' id=\'indicator_stopped\' name=\'indicator[stopped]\' type=\'checkbox\'>\n        <label for=\'indicator_stopped\'>' +
((__t = ( i18next.t('common.stopped') )) == null ? '' : __t) +
'?</label>\n    </dt>\n';
 } ;
__p += '\n';

}
return __p
}