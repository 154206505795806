WiseMetering.Views.IndexCompany = WiseMetering.Views.TableIndex.extend({
    icon: 'companies',
    name: () => i18next.t('glossary.companies'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            collection: WiseMetering.companies,
            columns: {
                name: i18next.t('common.name'),
                address: i18next.t('common.address'),
                postal_code: i18next.t('common.postal_code'),
                city: i18next.t('common.city'),
                country: i18next.t('common.country'),
                vat: i18next.t('common.vat_number')
            },
            id: 'companies',
            toolbar_actions: {
                show: {
                    view_call: model => {
                        model.fetch({
                            success: function() {
                                new WiseMetering.Views.ShowCompany({ model });
                            }
                        });
                    }
                },
                new: {
                    view_call: () => new WiseMetering.Views.AddCompany({
                        collection: WiseMetering.companies,
                        model: new WiseMetering.Model.Company({
                            organization_id: WiseMetering.currentOrganization.id
                        })
                    })
                },
                edit: {
                    view_call: model => {
                        model.fetch({
                            success: model => new WiseMetering.Views.EditCompany({ model: model })
                        });
                    }
                },
                delete: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.DestroyCompany({ model })
                }
            }
        });
    }
});
