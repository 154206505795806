WiseMetering.Model.ScheduledReport = WiseMetering.Model.extend({
    urlRoot: '/scheduled_reports',

    notifications: function() {
        return _.select(this.get('user_ids'), function(user_id) {
            return WiseMetering.users.get(user_id);
        }).map(function(user_id) {
            return WiseMetering.users.get(user_id).get('name');
        }).join(', ');
    }
});
