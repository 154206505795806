WiseMetering.Views.Ui.Widget.GlobalObjectives = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/basic_chart',

    initialize: function(params) {
        params.unit = params.unit ? params.unit : WiseMetering.getCurrency();
        WiseMetering.Views.Ui.Widget.Base.prototype.initialize.call(this, params);
    },

    build: function() {
        this.drawChart();
    },

    drawChart: function() {
        const
            { colors, unit } = this,
            months = i18next.t('highcharts.shortMonths', { returnObjects: true }),
            now = moment(),
            currentYear = now.year();

        let { objective, real } = this.data;

        if (unit.toLowerCase() === 'wh') {
            objective = objective.map(n => n * 1000);
        }

        for (let i = real.length; i < 12; i++) {
            real.push(null);
        }

        for (let i = objective.length; i < 12; i++) {
            objective.push(null);
        }

        this.chart = new Highcharts.Chart(jQuery.extend(true, {}, this.defaultChartOptions, {
            chart: {
                height: `${this.widgetPreferences.height}px`
            },
            xAxis: {
                categories: i18next.t('highcharts.shortMonths', { returnObjects: true }),
                lineColor: '#797979'
            },
            yAxis: {
                labels: {
                    formatter: function () {
                        return formatValue(this.value, unit, 0, 9)
                    }
                },
                title: {
                    enabled: false
                }
            },
            exporting: {
                enabled: false,
            },
            tooltip: {
                outside: true,
                formatter: function() {
                    const month = months.indexOf(this.x);
                    let realValue = real[month] || 0,
                        objectiveValue = objective[month] || 0,
                        difference = realValue - objectiveValue;

                    return JST['templates/ui/widgets/objective_tooltip']({
                        colors,
                        month: this.x,
                        objectiveValue: formatValue(objectiveValue, unit),
                        realValue: formatValue(realValue, unit),
                        positive: difference > 0,
                        percentage: formatValue(objectiveValue ? difference / objectiveValue * 100 : 0, '%'),
                        unit: unit
                    });
                }
            },
            series: [
                {
                    name: currentYear,
                    type: 'column',
                    data: real,
                    color: this.colors[0]
                },
                {
                    name: i18next.t('glossary.objective'),
                    type: 'spline',
                    data: objective,
                    color: this.colors[1],
                    linkedTo: ':previous'
                }
            ]
        }));
    }
});
