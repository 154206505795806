require('./app');
require('./dateprototype');
require('./resources');

var req;

req = require.context('./ui', false);
req.keys().forEach(req);
req = require.context('./lmit', false);
req.keys().forEach(req);
req = require.context('./wise/generic', false);
req.keys().forEach(req);
req = require.context('./wise', false);
req.keys().forEach(req);
req = require.context('./wise/analytics', false);
req.keys().forEach(req);
