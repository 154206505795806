module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="navbar-inner dashboard-inner">\n    <div class="global-dashboard-title">\n        ' +
((__t = ( WiseMetering.icon('home') )) == null ? '' : __t) +
'\n        Home\n    </div>\n    <ul class="nav widget-options dashboard-options">\n        <li>\n            <ul class="ctrl" style="line-height:25px;">\n                <li class="label">' +
((__t = ( i18next.t('common.period').capitalize() )) == null ? '' : __t) +
':</li>\n                <li class="underlined-text not-selectable" disabled>\n                    YTD\n                </li>\n                <li class="label">' +
((__t = ( i18next.t('glossary.utility') )) == null ? '' : __t) +
':</li>\n                ';
 utilities.forEach(function (key) { ;
__p += '\n                    <li value="' +
((__t = ( key )) == null ? '' : __t) +
'" class="utility mode ';
 if (selectedUtilityKind.slug === key) { ;
__p += ' underlined-text ';
 };
__p += '" >\n                        ' +
((__t = ( i18next.t(`utility_kinds.${key}`).capitalize() )) == null ? '' : __t) +
'\n                    </li>\n                ';
 }) ;
__p += '\n                <li class="mode" id="edit-button">\n                    ' +
((__t = ( WiseMetering.icon('edit') )) == null ? '' : __t) +
'\n                </li>\n            </ul>\n        </li>\n    </ul>\n</div>\n\n<div class="navbar-inner dashboard-inner-edit hidden-transition" id="edit-header">\n    <div class="global-dashboard-edit-title">\n        ' +
((__t = ( WiseMetering.icon('edit') )) == null ? '' : __t) +
'\n        Edit mode\n    </div>\n    <ul class="nav widget-options dashboard-options">\n        <li>\n            <ul class="ctrl" style="line-height:25px;">\n                <li class="mode" id="save-button">\n                    Save\n                </li>\n                <li class="mode" id="cancel-button">\n                    Cancel\n                </li>\n            </ul>\n        </li>\n    </ul>\n</div>\n';

}
return __p
}