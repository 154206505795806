// Lmit.UI.Tipper.confirm("Please Confirm This?", e,{pos:"nw", delegate:{onOk:function(e){ alert("done");}}});
// Lmit.UI.Tipper.prompt("Name View", e, {pos:"ne", okText:"Save",delegate:{onOk:function(value,e){ alert(value);}}});
// Lmit.UI.Tipper.signal("Do you really Want to Delete That?", e, {delegate:{callBack:function(e){ alert("done");}}});
// Lmit.UI.Tipper.alert("This is an Alert!!" , e, {pos:"se", delegate:{onOk:function(val,e,obj){ obj.destroy();}}});
// Lmit.UI.Tipper.publish(_html_content_here_, e, {delegate:{onOk:function(val, e, obj){ obj.destroy();}}});

Lmit.UI.Tipper = function(options) {
    options = options || {};
    this.options = options;
    this.container = options._container || $("body");
    this.element = null;
    this.body = null;
    this.w = options.w || "auto";
    this.h = options.h || "auto";
    this.x = options.x || null;
    this.y = options.y || null;
    this.timeout = options.timeout || 5000;
    this.pos = this.options.pos || "n";
    this.arrowOffset = 20;
    this.kind = options.kind;
    this.delegate = options.delegate || {};
    this.init();
};
Lmit.UI.Tipper.prompt = function(text, event, options) {
    new Lmit.UI.Tipper($.extend(options, {
        okText: options.okText,
        text: text,
        kind: "prompt",
        event: event
    }));
};

Lmit.UI.Tipper.removeAll = function() {
    $("div.wmui-tipper").remove();
};
Lmit.UI.Tipper.confirm = function(text, event, options) {
    new Lmit.UI.Tipper($.extend(options, { text: text, kind: "confirm", event: event }));
};

Lmit.UI.Tipper.alert = function(text, event, options) {
    new Lmit.UI.Tipper($.extend(options, { text: text, kind: "alert", event: event }));
};
Lmit.UI.Tipper.signal = function(text, event, options) {
    new Lmit.UI.Tipper($.extend(options, { text: text, kind: "signal", event: event }));
};

Lmit.UI.Tipper.publish = function(text, event, options) {
    new Lmit.UI.Tipper($.extend(options, { text: text, kind: "publish", event: event }));
};
Lmit.UI.Tipper.wait = function(text, event, options) {
    new Lmit.UI.Tipper($.extend(options, { text: text, kind: "wait", event: event }));
};

Lmit.UI.Tipper.prototype = {
    init: function() {
        this.destroy();
        this.build();
    },

    build: function() {

        this.element = $("<div>").addClass("wmui-tipper").css({ display: 'none' });
        if (this.options.style) {
            this.element.css(this.options.style);
        }
        if (this.options.klass) {
            this.element.addClass(this.options.klass);
        }

        const temp = this.template(this.kind, this.options);
        this.element.append(temp);
        this.container.append(this.element);
        this.setPosition();
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        if (this.kind === "signal") {
            this.timeOut = setTimeout(function() {
                this.element.remove();
            }.bind(this), this.timeout);
        } else {
            this.setArrow();
        }
        this.element.show();
        this.element.addClass("an an-" + this.pos);
    },

    template: function(kind, options) {
        const temp = $("<div>").addClass("wmui-tipper-body");
        switch (kind) {
            case "prompt":
                temp.addClass("wmui-tipper-prompt");
                temp.append($("<label>").text(options.text));
                temp.append($("<input>").attr({ 'type': 'text' }));
                temp.append(this.buttonsFor(kind, options));
                break;
            case "publish":
                temp.addClass("wmui-tipper-publish");
                temp.html(options.text);
                temp.append(this.buttonsFor("prompt", options));
                break;
            case "alert":
                temp.addClass("wmui-tipper-alert");
                temp.append($("<p>").text(options.text));
                temp.append(this.buttonsFor(kind, options));
                break;
            case "signal":
                temp.addClass("wmui-tipper-signal");
                temp.append($("<p>").addClass("imperative").text(options.text));
                break;
            case "confirm":
                temp.addClass("wmui-tipper-confirm");
                temp.append($("<p>").text(options.text));
                temp.append(this.buttonsFor(kind, options));
                break;
            case "wait":
                // temp.addClass("wmui-tipper-wait");
                // if(_options.text){temp.append($("<p>").text(_options.text));}
                // temp.append($("<div>").addClass("wmui-tipper-wait-icon"));
                // var animCircle = $("<div>").addClass("wmui-tipper-anim").css({height:"auto"});
                // temp.append(animCircle);
                // this.svgLoader(animCircle.get(0));
                break;
        }

        return temp;
    },

    buttonsFor: function(kind, options) {
        options = options || {};
        var delegate = options.delegate;
        var _this_ = this;
        var btns = $("<div>").addClass("wmui-tipper-btns");
        var okBtn = $("<div>").addClass("wmui-btn").text((options.okText) ? options.okText : "Ok");
        okBtn.click(function(e) {
            if (delegate && delegate.onOk) {
                delegate.onOk((kind === "prompt") ? _this_.element.find("input[type=text]").val() : true, e, _this_);
            }
        });
        btns.append(okBtn);
        switch (kind) {
            case "confirm":
            case "prompt":
                var cancelBtn = $("<div>").addClass("wmui-btn").text(i18next.t('ui.buttons.cancel'));
                cancelBtn.click(function(e) {
                    if (delegate && delegate.onCancel) {
                        delegate.onCancel(this);
                    }
                    _this_.destroy();
                });
                btns.append(cancelBtn);
                break;
        }
        return btns;
    },

    evalPosition: function(target) {
        const position = target.offset();
        if (this.pos.match(/w/)) {
            this.x = (position.left + (target.width() / 2)) - this.arrowOffset;
        } else if (this.pos.match(/e/)) {
            this.x = position.left + (target.width() / 2) - (this.element.width()) + this.arrowOffset;
        } else {
            this.x = position.left + (target.width() / 2) - (this.element.width() / 2);
        }
        if (this.pos.match(/s/)) {
            this.y = (position.top - this.element.height());
        } else {
            this.y = position.top + target.height();
        }
    },

    setArrow: function() {
        var pos = this.pos;
        var arrow = $("<div>").addClass("wmui-tipper-arrow " + (pos));
        if (pos.match(/s/)) {
            this.element.append(arrow);
        } else {
            this.element.prepend(arrow);
        }
        var left;
        if (pos.match(/w/)) {
            left = 10;
        } else if (pos.match(/e/)) {
            left = (this.element.width()) - 30;
        } else {
            left = (this.element.width() / 2) - 10;
        }
        arrow.css({ left: left });
    },

    addInputMessage: function(args) {
        this.element.find("div.wmui-tipper-body input[type=text]").attr({
            placeholder: args.text || i18next.t('ui.please_type_something')
        });
    },

    setPosition: function() {
        if (this.options.event) {
            this.evalPosition($(this.options.event.target || this.options.event.srcElement));
        } else {
            this.x = ($("body").width() / 2) - (this.element.width() / 2);
            this.y = -10;
        }
        this.element.css({
            top: this.y,
            left: this.x,
            width: this.w,
            height: this.h
        });
    },

    destroy: function() {
        $(this.element).remove();
    }

};
