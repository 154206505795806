WiseMetering.Views.EditSpecialFeaturesOrganization = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/organizations/edit_special_features',
    className: 'mbx-info text-left',
    form_prefix: 'organization',
    title: () => i18next.t('modal.title.organizations.edit'),

    serializeData: function() {
        const json = this.model.toJSON();
        json.hasSpecialFeatures = this.model.hasSpecialFeatures();
        return json;
    }
});
