WiseMetering.Views.NewContract = WiseMetering.Views.Modal.Save.extend({
    form_prefix: 'contract',
    template: 'templates/contracts/new',
    title: () => i18next.t('modal.title.contracts.new'),
    ui: {
        extra: '#contract-extra',
        finish: '#contract-finish',
        start: '#contract-start',
        tariff: '#contract-tariff'
    },
    events: {
        'change @ui.start': 'onChangeStart'
    },

    getFormData: function() {
        const data = this.formSerializer();
        if (data.extra) {
            Object.entries(data.extra).map(([key, value]) => {
                data.extra[key] = value * 1000;
            });
        }
        return data;
    },

    onChangeStart: function() {
        this.ui.start.attr('disabled', true);
        this.ui.finish.attr('disabled', false).attr('min', this.ui.start.val());
    },

    onRender: function() {
        const countryCode = this.options.utility.building().get('address').country_code;
        const utilityKindSlug = this.options.utility.utilityKind().get('slug');

        this.renderTariffs(countryCode, utilityKindSlug);
        this.setupDates();
        if (utilityKindSlug === 'electricity') {
            this.renderExtra(countryCode, utilityKindSlug);
        }
    },

    renderExtra: function(countryCode) {
        let template = '';

        if (countryCode === 'PT') {
            template = `
                <dt>
                    <label for='contract-extra-contracted-power'>${i18next.t('tariffs.required_power')} (kW)&nbsp;*</label>
                </dt>
                <dd>
                    <input class='text' id='contract-extra-contracted-power' name='contract[extra][contracted_power]' type='number'>
                </dd>
            `;
        } else if (countryCode === 'ES') {
            [1, 2, 3, 4, 5, 6].forEach(index => {
                template += `
                    <dt>
                        <label for='contract-extra-contracted-power-p${index}'>${i18next.t('tariffs.required_power')} P${index} (kW)&nbsp;*</label>
                    </dt>
                    <dd>
                        <input class='text' id='contract-extra-contracted-power-p${index}' name='contract[extra][contracted_power_p${index}]' type='number'>
                    </dd>
                `;
            });
        }

        this.ui.extra.html(template);
    },

    renderTariffs: function(countryCode, utilityKindSlug) {
        const tariffs = WiseMetering.tariffs.where({ country_code: countryCode, utility_kind_slug: utilityKindSlug });
        this.ui.tariff.html(WiseMetering.utils.optionsFromModels(tariffs));
    },

    setupDates: function() {
        const minDate = this.options.utility.nextAvailableDate();

        if (this.options.utility.contracts().length === 0) {
            this.ui.start.attr('min', minDate);
            this.ui.finish.attr('disabled', true);
        } else {
            this.ui.start.attr('disabled', true).val(minDate);
            this.ui.finish.attr('min', minDate);
        }
    }
});
