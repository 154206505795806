module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'modal_blocker\'>\n    <div class=\'modal_blocker_container\'>\n        ' +
((__t = ( i18next.t('modal.please_wait') )) == null ? '' : __t) +
'\n    </div>\n</div>\n';

}
return __p
}