WiseMetering.Views.LocationEdit = WiseMetering.Views.Modal.Save.extend({

    template: 'templates/zones/address',
    className: 'mbx-info text-left',
    form_prefix: 'address',
    title: () => i18next.t('modal.title.address.edit'),
    input_readers: [{
        type: 'default',
        func: function(el) {
            return el.val() === '' ? null : el.val();
        }
    }],
    width: '75%',
    map: null,
    marker: null,

    modelEvents: {
        "change": "render"
    },

    events: {
        "keyup #address": "search"
    },

    onShow: function() {
        this.mapOptions = {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: { mapTypeIds: [] }
        };
        this.mapOptions.center = new google.maps.LatLng(
            this.model.get("latitude"),
            this.model.get("longitude")
        );
        this.codeAddress();
    },

    createMap: function(center) {
        var that = this;
        that.map = new google.maps.Map(document.getElementById("map_canvas"), that.mapOptions);
        google.maps.event.addListener(that.map, 'zoom_changed', function() {
            that.mapOptions.zoom = that.map.getZoom();
        });
        that.map.setCenter(center);
        that.marker = new google.maps.Marker({
            icon: WiseMetering.mapIcons.building.default,
            map: that.map,
            position: center,
            draggable: true
        });
        that.setLatLng(that.marker.getPosition());
        google.maps.event.addListener(that.marker, 'dragend', function() {
            center = that.marker.getPosition();
            that.map.setCenter(center);
            that.setLatLng(center);
        });
    },

    setLatLng: function(position) {
        this.$("input#zone_latitude").val(position.lat());
        this.$("input#zone_longitude").val(position.lng());
    },

    codeAddress: function() {
        var that = this;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': document.getElementById("address").value }, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var position = results[0].geometry.location;
                that.createMap(position);
            } else {
                alert("Geocode was not successful for the following reason: " + status);
            }
        });
    },

    search: function(event) {
        if ((event.keyCode || event.which) === 13) {
            event.stopPropagation();
            event.preventDefault();
            this.codeAddress();
        }
    },

    afterSave: function() {
        return this.options.building.fetch();
    }
});
