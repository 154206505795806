module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>Id</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( description || '-' )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.buildings') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( buildings || i18next.t('groups.no_buildings') )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.users') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( users || i18next.t('groups.no_users') )) == null ? '' : __t) +
'</dd>\n</dl>\n';

}
return __p
}