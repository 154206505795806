WiseMetering.Views.Folder = WiseMetering.Views.Content.extend({
    icon: 'folders',
    tabs: function() {
        return [
            {
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.FolderDetailsTab
            }
        ];
    }
});
