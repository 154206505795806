WiseMetering.Collection.IntegrationAssets = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.IntegrationAsset,
    url: '/integration_assets',

    bulkCreate: function(assets) {
        return $.ajax({
            url: '/integration_assets/bulk_create',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ assets: assets }),
            success: function(data) {
                WiseMetering.integrationAssets.add(data);
            }
        });
    }
});

