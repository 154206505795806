WiseMetering.Views.Card = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/card/item',

    ui: {
        activate: '.activate',
        manage: '.manage'
    },

    events: {
        'click @ui.activate': 'onActivateClicked',
        'click @ui.manage': 'onManageClicked'
    },

    initialize: function() {
        this.createAttributesList();
    },

    createAttributesList: function() {
        const attributes = this.options.data.attributes || [];

        this.attributes = attributes.map(attribute => ({
            label: attribute.label,
            value: this.model.getJsonFormatted()[attribute.key]
        }));
    },

    serializeData: function() {
        return {
            attributes: this.attributes,
            button: this.options.data.button,
            description: this.options.data.description,
            emphasis: this.options.data.emphasis,
            homepage: this.options.data.homepage,
            imgSrc: this.options.data.image,
            linkedin: this.options.data.linkedin,
            state: this.options.data.state,
            title: this.options.data.title,
            website: this.options.data.website
        };
    },

    onActivateClicked: function(event) {
        event.preventDefault();
    },

    onManageClicked: function(event) {
        event.preventDefault();

        const url = this.options.data.button.href;
        Backbone.history.navigate(url, { trigger: true });
    },
});
