Lmit.Wise.Chart.Control.PlotBands = function(controller, container, indicator_id) {
    this.active = false;
    this.controller = controller;
    this.container = container;
    this.eventCtrl = null;
    this.indicator = WiseMetering.indicators.get(indicator_id);
    this.plotBands = [];
    this.init();
};

Lmit.Wise.Chart.Control.PlotBands.prototype = {

    init: function() {
        var that = this;

        // Build view (how ugly is this) ?
        var view = $('<div>').addClass('wmui-chart-control-plot-bands');
        that.container.append(view);
        var control = $('<ul>').addClass('ctrl');
        that.eventCtrl = $('<li>').addClass('mode').text('Tariff periods');
        control.append(that.eventCtrl);
        view.append(control);

        // Bind click event
        that.eventCtrl.click(function() {
            that.active = !that.active;
            that.eventCtrl.toggleClass('current');
            that.active ? that.addPlotBands() : that.removePlotBands();
        });
    },

    addPlotBands: function() {
        var that = this;
        that.removePlotBands(); // Always remove plotBands first, just in case
        that.fetchData();
        _.each(that.plotBands, function(plotBand) {
            that.controller.chart.chart.xAxis[0].addPlotBand(plotBand);
        });
    },

    fetchData: function() {
        var that = this;
        var current = moment($('.wmui-chart-control-date_time #date-time-from').val(), 'YYYY.MM.DD').unix();
        var to = moment($('.wmui-chart-control-date_time #date-time-to').val(), 'YYYY.MM.DD').unix();
        var intervals = [];

        try {
            intervals = that.indicator.zone().electricity_tariff().timetable().get('value');
        } catch (e) {
            console.log('Unable to find timetable');
        }

        if (_(intervals).isEmpty()) {
            that.showError();
        } else {
            var previous = null;
            var band = [];
            while (current < to) {
                var interval = _(intervals).find(function(interval) {
                    return interval.to ? (from >= interval.from && from < interval.to) : (from >= interval.from);
                });
                if (interval) {
                    current += 900 * 1000;
                } else {
                    that.showError();
                    return -1;
                }
            }
        }
    },


    removePlotBands: function() {
        var that = this;
        _.each(that.plotBands, function(plotBand) {
            that.controller.chart.chart.xAxis[0].removePlotBand(plotBand.id);
        });
    },

    showError: function() {
        var that = this;
        that.eventCtrl.removeClass('current');
        WiseMetering.layout.showTipper('error', 'Unable to show data, please report this issue to your administrator.');
        that.eventCtrl.unbind('click');
    }

};
