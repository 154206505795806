module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>' +
((__t = ( i18next.t('common.workflow') )) == null ? '' : __t) +
'</dt>\n    <dd class=\'workflow\' id=\'workflow\'></dd>\n\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('glossary.title') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( title )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( description )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('common.state') )) == null ? '' : __t) +
'</dt>\n    <dd><i class=\'' +
((__t = ( WiseMetering.icons.circle )) == null ? '' : __t) +
'\' style=\'color: ' +
((__t = ( color )) == null ? '' : __t) +
'\'></i> ' +
((__t = ( state.capitalize() )) == null ? '' : __t) +
'</dd>\n\n    ';
 if (completed_at) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('opportunities.completed_at') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( completed_at )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n\n    <dt>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( utilityKindName )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</dt>\n    <dd>\n        ';
 if (indicator) { ;
__p += '\n            <a id=\'close\' href="' +
((__t = ( indicator.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</a>\n        ';
 } else { ;
__p += '\n            -\n        ';
 } ;
__p += '\n    </dd>\n\n    <dt>' +
((__t = ( i18next.t('common.priority') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( priority )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('opportunities.consumption_savings') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( consumption )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('opportunities.cost_savings') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( cost )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('common.capex') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( capex )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('opportunities.payback_period') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( payback )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('glossary.created_by') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( creatorName )) == null ? '' : __t) +
', ' +
((__t = ( created_at )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( updaterName )) == null ? '' : __t) +
', ' +
((__t = ( updated_at )) == null ? '' : __t) +
'</dd>\n\n    ';
 if (file) { ;
__p += '\n        <dt style="margin-top:15px"></dt>\n        <dd class=\'file-link\' id=\'file-link\'>\n            <span style=\'color:#8BC53F\'>' +
((__t = ( WiseMetering.icon('download') )) == null ? '' : __t) +
'</span> <a class=\'link\' href=' +
((__t = ( file_link )) == null ? '' : __t) +
' target=\'_blank\'>' +
((__t = ( i18next.t('ui.click_here_to_download_file') )) == null ? '' : __t) +
'</a>\n            <a href=\'#\' id=\'remove-file\' style=\'color:#224C4F\'> (' +
((__t = ( i18next.t('common.remove') )) == null ? '' : __t) +
') </a>\n        </dd>\n    ';
 } ;
__p += '\n    <span id=\'spinner\' style=\'display:none;color:#224C4F\'>\n        <i class=\'far fa-spinner fa-spin\'></i> ' +
((__t = ( i18next.t(`ui.${file ? 'removing' : 'uploading'}_file`) )) == null ? '' : __t) +
'\n    </span>\n</dl>\n';

}
return __p
}