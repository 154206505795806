WiseMetering.Views.NewAlarm = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/alarms/new',
    form_prefix: 'alarm',
    width: 1150,
    ui: {
        notifications: '#notifications',
        schedule: '#alarm-schedule'
    },
    events: {
        'change select#alarm-template': 'changeGrid',
        'click #add-notification': 'onAddNotification',
        'click #remove-notification': 'onRemoveNotification'
    },
    title: () => i18next.t('modal.title.alarms.new'),

    afterSave: function() {
        this?.options?.callback();
    },

    changeGrid: function(e) {
        this.alarm_schedule = WiseMetering.alarmSchedules.get(e.currentTarget.value);
        const alarmSchedule = this.alarm_schedule;

        if (alarmSchedule) {
            const values = {};
            alarmSchedule.periods().forEach(period => values[period] = [0, 0]);

            let unit = this.indicator.get('unit');
            if (['W', 'Wh', 'VArh'].includes(unit)) {
                unit = `k${unit}`;
            }

            const scheduleView = new WiseMetering.Views.AlarmSchedule({
                editValues: true,
                periods: alarmSchedule.periods(),
                schedule: alarmSchedule.schedule(),
                unit,
                values
            });
            this.ui.schedule.html(scheduleView.el);
            this.$('a#outbetween').tooltip();
        }
    },

    serializeData: function() {
        let alarmTypes = WiseMetering.alarmTypes;

        if (!this.indicator.cumulative()) {
            alarmTypes = alarmTypes.filter(type => type !== 'cumulative');
        }

        return {
            alarmTypes,
            indicatorName: this.indicator.get('name')
        };
    },

    getFormData: function() {
        const data = this.formSerializer();

        data.indicator_id = this.indicator.id;
        data.organization_id = WiseMetering.currentOrganization.id;

        if (data.values) {
            let ratio = 1;
            if (['W', 'Wh', 'VArh'].includes(this.indicator.get('unit'))) {
                ratio = 1000;
            }

            for (let period in data.values) {
                ['min', 'max'].forEach(entry => data.values[period][entry] = Number(data.values[period][entry]) * ratio);
            }
        }

        if (data.average) {
            data.average = data.average === '1';
        }

        return data;
    },

    onAddNotification: function() {
        this.addNotification();
    },

    addNotification: function() {
        this.ui.notifications.append(JST['templates/alarms/notification']({ userId: null }));
    },

    onRemoveNotification: function(ev) {
        ev.preventDefault();
        $(ev.currentTarget).parent().remove();
    }
});
