import queryString from 'query-string';

(function() { // http://ricostacruz.com/backbone-patterns/#file_naming - The setup/glue code file
    WiseMetering.actuators = new WiseMetering.Collection.Actuators;
    WiseMetering.actuatorKinds = new WiseMetering.Collection.ActuatorKinds;
    WiseMetering.alarms = new WiseMetering.Collection.Alarms;
    WiseMetering.alarmSchedules = new WiseMetering.Collection.AlarmSchedules;
    WiseMetering.allocations = new WiseMetering.Collection.Allocations;
    WiseMetering.analyzeViews = new WiseMetering.Collection.AnalyzeViews;
    WiseMetering.apiRequests = new WiseMetering.Collection.ApiRequests;
    WiseMetering.buildingTags = new WiseMetering.Collection.BuildingTags;
    WiseMetering.sitePackages = new WiseMetering.Collection.SitePackages;
    WiseMetering.circuitTags = new WiseMetering.Collection.CircuitTags;
    WiseMetering.circuits = new WiseMetering.Collection.Circuits;
    WiseMetering.companies = new WiseMetering.Collection.Companies;
    WiseMetering.contracts = new WiseMetering.Collection.Contracts;
    WiseMetering.countries = new WiseMetering.Collection;
    WiseMetering.currencies = [];
    WiseMetering.dashboardEvents = new WiseMetering.Collection.Events;
    WiseMetering.devices = new WiseMetering.Collection.Devices;
    WiseMetering.utilities = new WiseMetering.Collection.Utilities;
    WiseMetering.etlDrivers = [];
    WiseMetering.etlRates = [];
    WiseMetering.etls = new WiseMetering.Collection.Etls;
    WiseMetering.externalAssets = new WiseMetering.Collection.ExternalAssets;
    WiseMetering.folders = new WiseMetering.Collection.Folders;
    WiseMetering.globalReportTypes = new WiseMetering.Collection.GlobalReportTypes;
    WiseMetering.groups = new WiseMetering.Collection.Groups;
    WiseMetering.holidays = new WiseMetering.Collection.Holidays;
    WiseMetering.indicators = new WiseMetering.Collection.Indicators;
    WiseMetering.indicatorKinds = new WiseMetering.Collection;
    WiseMetering.integrationAssets = new WiseMetering.Collection.IntegrationAssets;
    WiseMetering.integrationPartners = new WiseMetering.Collection.IntegrationPartners;
    WiseMetering.integrationServices = new WiseMetering.Collection.IntegrationServices;
    WiseMetering.invoices = new WiseMetering.Collection.Invoices;
    WiseMetering.licenses = new WiseMetering.Collection.Licenses;
    WiseMetering.markers = new WiseMetering.Collection.Markers;
    WiseMetering.markerTypes = new WiseMetering.Collection;
    WiseMetering.messages = new WiseMetering.Collection.Messages;
    WiseMetering.metadataTypes = new WiseMetering.Collection;
    WiseMetering.objectives = new WiseMetering.Collection.Objectives;
    WiseMetering.opportunities = new WiseMetering.Collection.Opportunities;
    WiseMetering.organizationLogs = new WiseMetering.Collection.OrganizationLogs;
    WiseMetering.reports = new WiseMetering.Collection.Reports;
    WiseMetering.rootObjects = new WiseMetering.Collection.RootObjects;
    WiseMetering.seus = new WiseMetering.Collection.Seus;
    WiseMetering.scheduledReports = new WiseMetering.Collection.ScheduledReports;
    WiseMetering.systemCategories = new WiseMetering.Collection;
    WiseMetering.tariffs = new WiseMetering.Collection.Tariffs;
    WiseMetering.tariffTypes = new WiseMetering.Collection.TariffTypes;
    WiseMetering.tars = new WiseMetering.Collection.Tars;
    WiseMetering.temperatureColors = [];
    WiseMetering.tenants = new WiseMetering.Collection.Tenants;
    WiseMetering.tenantInvoices = new WiseMetering.Collection.TenantInvoices;
    WiseMetering.timezones = {};
    WiseMetering.units = new WiseMetering.Collection;
    WiseMetering.users = new WiseMetering.Collection.Users;
    WiseMetering.utilities = new WiseMetering.Collection.Utilities;
    WiseMetering.utilityKinds = new WiseMetering.Collection.UtilityKinds;
    WiseMetering.zone_attribute_values = new WiseMetering.Collection.ZoneAttributeValues;
    WiseMetering.zone_attributes = new WiseMetering.Collection.ZoneAttributes;
    WiseMetering.zoneCategories = new WiseMetering.Collection;
    WiseMetering.zoneReportTypes = new WiseMetering.Collection.ZoneReportTypes;
    WiseMetering.zones = new WiseMetering.Collection.Zones;

    // Global application events
    // Actuators
    WiseMetering.actuators.on('add', actuator => {
        actuator.bindChange();
        if (actuator.circuit()) {
            actuator.circuit().trigger('change:addActuator', actuator);
        }
    });

    WiseMetering.actuators.on('remove', actuator => {
        if (actuator.circuit()) {
            actuator.circuit().trigger('change:removeActuator', actuator);
        }
    });

    // Allocations
    WiseMetering.allocations.on('add', allocation => {
        if (allocation.parent()) {
            allocation.parent().trigger('change:children:addAllocation', allocation);
        }
    });

    WiseMetering.allocations.on('remove', allocation => {
        if (allocation.parent()) {
            allocation.parent().trigger('change:children:removeAllocation', allocation);
        }
    });

    // Zones
    WiseMetering.zones.on('add', zone => {
        if (zone.parent()) {
            zone.parent().trigger('change:children:addZone', zone);
        }
    });
    WiseMetering.zones.on('remove', zone => {
        if (zone.parent()) {
            zone.parent().trigger('change:children:removeZone', zone);
        }
    });

    // Zone Attribute Values
    WiseMetering.zone_attribute_values.on('add', zone_attribute_value => {
        zone_attribute_value.bindChange();
        if (zone_attribute_value.zone()) {
            zone_attribute_value.zone().trigger('change:addAttributeValue');
        }
    });
    WiseMetering.zone_attribute_values.on('remove', zone_attribute_value => {
        if (zone_attribute_value.zone()) {
            zone_attribute_value.zone().trigger('change:removeAttributeValue');
        }
    });

    // Folders
    WiseMetering.folders.on('add', folder => {
        if (folder.parent()) {
            folder.parent().trigger('change:children:addFolder', folder);
        }
    });
    WiseMetering.folders.on('remove', folder => {
        if (folder.parent()) {
            folder.parent().trigger('change:children:removeFolder', folder);
        }
    });

    // Circuits
    WiseMetering.circuits.on('add', circuit => {
        if (circuit.parent()) {
            circuit.parent().trigger('change:children:addCircuit', circuit);
        }
    });
    WiseMetering.circuits.on('remove', circuit => {
        if (circuit.parent()) {
            circuit.parent().trigger('change:children:removeCircuit', circuit);
        }
    });

    // Contracts
    WiseMetering.contracts.on('add', contract => {
        if (contract.parent()) {
            contract.parent().trigger('change:children:addContract', contract);
        }
    });
    WiseMetering.contracts.on('remove', contract => {
        if (contract.parent()) {
            contract.parent().trigger('change:children:removeContract', contract);
        }
    });

    // Utilities
    WiseMetering.utilities.on('add', utility => {
        if (utility.parent()) {
            utility.parent().trigger('change:children:addUtility', utility);
        }
    });
    WiseMetering.utilities.on('remove', utility => {
        if (utility.parent()) {
            utility.parent().trigger('change:children:removeUtility', utility);
        }
    });

    // Indicators
    WiseMetering.indicators.on('add', indicator => {
        indicator.bindChange();
        if (indicator.parent()) {
            indicator.parent().trigger('change:children:addIndicator', indicator);
        }
    });
    WiseMetering.indicators.on('remove', indicator => {
        if (indicator.parent()) {
            indicator.parent().trigger('change:children:removeIndicator', indicator);
        }
    });

    // Seus
    WiseMetering.seus.on('add', seu => {
        if (seu.parent()) {
            seu.parent().trigger('change:children:addSeu', seu);
        }
    });
    WiseMetering.seus.on('remove', seu => {
        if (seu.parent()) {
            seu.parent().trigger('change:children:removeSeu', seu);
        }
    });

    // Tenants
    WiseMetering.tenants.on('add', tenant => {
        if (tenant.parent()) {
            tenant.parent().trigger('change:children:addTenant', tenant);
        }
    });
    WiseMetering.tenants.on('remove', tenant => {
        if (tenant.parent()) {
            tenant.parent().trigger('change:children:removeTenant', tenant);
        }
    });

    // Start the application, after everything is defined
    WiseMetering.start();

    const goToLogin = () => {
        if (Backbone.history.location.pathname !== '/login') {
            WiseMetering.targetUrl = Backbone.history.location.pathname;
        }
        Backbone.history.navigate('/login');
        Backbone.history.loadUrl(Backbone.history.fragment);
    };

    WiseMetering.startApp = () => {
        $.when($.get('/users/me'))
        .done(loginData => {
            const preferences = loginData.preferences;
            delete loginData.preferences;

            WiseMetering.currentUser = new WiseMetering.Model.User(loginData);
            WiseMetering.userPreferences = new WiseMetering.Model.UserPreference(preferences);

            WiseMetering.loadLang(WiseMetering.userPreferences.language());

            WiseMetering.setOrganization();
            WiseMetering.currentOrganization.fetch({ async: false });

            Backbone.history.stop();
            Backbone.history.start({ pushState: true, root: `/${WiseMetering.currentOrganization.get('subdomain')}/`, silent: true });

            document.title = 'WiseMetering ' + WiseMetering.currentOrganization.get('name');
            WiseMetering.layout = new WiseMetering.Views.Layouts.Root({ model: WiseMetering.currentUser });
            WiseMetering.mainRegion.show(WiseMetering.layout);

            new WiseMetering.Views.Loading(); // Place the loader

            (async () => {
                // We only need the first position of the array
                let [bootstrapData] = await Promise.all([
                    $.get(`/bootstrap?organization_id=${WiseMetering.currentOrganization.id}`),
                    WiseMetering.circuits.fetch(),
                    WiseMetering.contracts.fetch(),
                    WiseMetering.folders.fetch(),
                    WiseMetering.indicators.fetch(),
                    WiseMetering.seus.fetch(),
                    WiseMetering.utilities.fetch(),
                    WiseMetering.zones.fetch()
                ]);

                WiseMetering.rootObjects = new WiseMetering.Collection.RootObjects().build();
                WiseMetering.currentLocale = WiseMetering.locales[WiseMetering.currentOrganization.get('country_code')] || WiseMetering.locales['PT'];
                WiseMetering.number_formatter = new NumberFormatter(WiseMetering.currentLocale);
                WiseMetering.actuatorKinds = new WiseMetering.Collection.ActuatorKinds(bootstrapData.actuator_kinds);
                WiseMetering.alarmTypes = bootstrapData.alarm_types;
                WiseMetering.countries = new WiseMetering.Collection(bootstrapData.countries);
                WiseMetering.currencies = bootstrapData.currencies;
                WiseMetering.etlDrivers = bootstrapData.etl_drivers;
                WiseMetering.etlRates = bootstrapData.etl_rates;
                WiseMetering.indicatorKinds = new WiseMetering.Collection(bootstrapData.indicator_kinds);
                WiseMetering.markerTypes = new WiseMetering.Collection(bootstrapData.marker_types);
                WiseMetering.metadataTypes = bootstrapData.metadata_types;
                WiseMetering.packages = new WiseMetering.Collection.Packages(bootstrapData.packages);
                WiseMetering.systemCategories = new WiseMetering.Collection(bootstrapData.system_categories);
                WiseMetering.tariffTypes = new WiseMetering.Collection.TariffTypes(bootstrapData.tariff_types);
                WiseMetering.temperatureColors = bootstrapData.temperature_colors;
                WiseMetering.timezones = bootstrapData.timezones;
                WiseMetering.tiers = new WiseMetering.Collection.Tiers(bootstrapData.tiers);
                WiseMetering.units = new WiseMetering.Collection(bootstrapData.units);
                WiseMetering.utilityKinds = new WiseMetering.Collection.UtilityKinds(bootstrapData.utility_kinds);
                WiseMetering.zoneCategories = new WiseMetering.Collection(bootstrapData.zone_categories);
                WiseMetering.zoneReportTypes = new WiseMetering.Collection.ZoneReportTypes(bootstrapData.zone_report_types);
                WiseMetering.globalReportTypes = new WiseMetering.Collection.GlobalReportTypes(bootstrapData.global_report_types);
                WiseMetering.tenantIntervals = bootstrapData.tenant_intervals;
                WiseMetering.rootObjects = new WiseMetering.Collection.RootObjects().build();
                WiseMetering.organizationTypes = [
                    { name: 'Building', slug: 'building' },
                    { name: 'Industry', slug: 'industry' },
                    { name: 'Retail', slug: 'retail' }
                ];
                WiseMetering.workflowActions = {
                    pending: 'reset',
                    ongoing: 'start',
                    completed: 'complete',
                    active: 'activate',
                    discarded: 'discard'
                };

                // using Backbone.Collection to avoid ordering the list
                // with the default Backbone.Collection items are rendered
                WiseMetering.layout.tree.show(new WiseMetering.Views.Buildings({
                    collection: new Backbone.Collection([
                        ...WiseMetering.folders.roots().models,
                        ...WiseMetering.zones.activeSites().models
                    ])
                }));

                const collections = [
                    WiseMetering.actuators,
                    WiseMetering.analyzeViews,
                    WiseMetering.apiRequests,
                    WiseMetering.buildingTags,
                    WiseMetering.sitePackages,
                    WiseMetering.circuitTags,
                    WiseMetering.companies,
                    WiseMetering.users,
                    WiseMetering.zone_attribute_values,
                    WiseMetering.zone_attributes,
                    WiseMetering.alarms,
                    WiseMetering.alarmSchedules,
                    WiseMetering.allocations,
                    WiseMetering.devices,
                    WiseMetering.etls,
                    WiseMetering.groups,
                    WiseMetering.holidays,
                    WiseMetering.invoices,
                    WiseMetering.integrationPartners,
                    WiseMetering.integrationServices,
                    WiseMetering.markers,
                    WiseMetering.objectives,
                    WiseMetering.opportunities,
                    WiseMetering.organizationLogs,
                    WiseMetering.reports,
                    WiseMetering.scheduledReports,
                    WiseMetering.tariffs,
                    WiseMetering.tars,
                    WiseMetering.tenants,
                    WiseMetering.tenantInvoices
                ];

                if (WiseMetering.currentUser.get('manager')) {
                    collections.push(WiseMetering.integrationAssets);
                }

                await Promise.all(collections.map(collection => collection.fetch()));
                await WiseMetering.dashboardEvents.fetch({ data: { limit: 25 } });

                WiseMetering.reports.fetch(); // Fetch reports after the other collections and don't wait for it

                // Load params that are relevant
                const params = queryString.parse(location.search);
                if (params.from) {
                    ParamsService.setParams({ from: params.from });
                }
                if (params.rate) {
                    ParamsService.setParams({ rate: params.rate });
                }
                if (params.to) {
                    ParamsService.setParams({ to: params.to });
                }
                MapService.init();

                // Load APP
                WiseMetering.modalBox.close();

                Backbone.history.loadUrl(Backbone.history.fragment);

                // On ESC, either close the modal box or the main view
                $('body').keyup(WiseMetering.onCancel);

                if (!Backbone.history.fragment && WiseMetering.zones.buildings().length === 1) {
                    Backbone.history.navigate(WiseMetering.zones.buildings().first().nodeUrl(), { trigger: true });
                }

                WiseMetering.messages.fetch();
                setInterval(() => WiseMetering.messages.fetch(), 60000);

                // Bind stuff
                WiseMetering.actuators.each(v => v.bindChange());
                WiseMetering.indicators.each(v => v.bindChange());
                WiseMetering.zone_attribute_values.each(v => v.bindChange());
            })();
        })
        .fail(goToLogin);
    };

    if (window.location.pathname === '/login') {
        goToLogin();
    } else if ( window.location.pathname.startsWith('/reset_password') ) {
        const id = window.location.pathname.split('/').pop() || 'code';
        Backbone.history.navigate(`/reset_password/${id}`);
        Backbone.history.loadUrl(Backbone.history.fragment);
    } else if (window.location.pathname === '/old_login') {
        Backbone.history.navigate('/old_login');
        Backbone.history.loadUrl(Backbone.history.fragment);
    } else {
        WiseMetering.startApp();
    }
})();
