WiseMetering.Views.CircuitDetailsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/circuits/details',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click #edit_attributes': 'editAttributes'
    },

    serializeData: function() {
        const json = this.model.toJSON(), actuator = this.model.actuator();
        json.actuatorName = actuator ? actuator.get('name') : null;
        json.circuitTagName = this.model.tag().get('name');
        return json;
    },

    editAttributes: function(event) {
        event.stopPropagation();
        new WiseMetering.Views.CircuitEdit({ model: this.model });
    }
});
