WiseMetering.Views.IndexDevice = WiseMetering.Views.TableIndex.extend({
    icon: 'devices',
    name: () => i18next.t('glossary.devices'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            collection: WiseMetering.devices,
            columns: {
                name: i18next.t('common.name'),
                zone: i18next.t('glossary.zone'),
                equipment_name: i18next.t('glossary.equipment')
            },
            id: 'devices',
            modelSerializer: function(model) {
                return {
                    name: model.get('name'),
                    zone: model.get('installation_zone_full_path'),
                    equipment_name: model.get('equipment_name'),
                    firmware_version: model.get('firmware_version')
                };
            },
            disableSelect: true
        });
    }
});
