WiseMetering.Views.EditAlarmSchedule = WiseMetering.Views.NewAlarmSchedule.extend({
    width: 1020,

    onRender: function() {
        const $schedule = new WiseMetering.Views.AlarmSchedule({
            editable: true,
            periods: this.model.periods(),
            schedule: this.model.schedule()
        });
        this.ui.schedule.append($schedule.el);
    },

    save: function() {
        WiseMetering.Views.Modal.Save.prototype.save.call(this);
    },

    title: function() {
        return i18next.t('modal.title.alarm_schedules.edit');
    }
});
