module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <input name=\'user[organization_id]\' type=\'hidden\' value=\'' +
((__t = ( WiseMetering.currentOrganization.id )) == null ? '' : __t) +
'\'>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'user_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'user_name\' name=\'user[name]\' type=\'text\'>\n        </dd>\n        <dt>\n            <label for=\'user_email\'>' +
((__t = ( i18next.t('common.email') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'user_email\' name=\'user[email]\' type=\'text\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}