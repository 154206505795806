WiseMetering.Collection.Tariffs = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Tariff,
    url: '/tariffs',

    byUtilityKind(utility_kind_name) {
        return this.where({ utility_kind_name });
    },

    byUtilityKindTimezone(utility_kind_name, timezone) {
        return this.where({ utility_kind_name, timezone });
    }
});
