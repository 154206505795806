// Wise Indicator
Lmit.Wise.Chart.Control.InformationType = function(_controller_, _container_, _options_) {
    this.options = _options_;
    this.controller = _controller_;
    this.container =  _container_;
    // view elements
    this.view= null;
    this.code = Lmit.Utils.generateCode();
    //
    this.uri = this.options.uri;
    this.selectedEvent = null;

    // available information types. code must be unique
    this.informationTypes = [
        { code: 'realtime', name: i18next.t('indicators.realtime') },
        { code: 'analysis', name: i18next.t('glossary.analysis') }
    ];
    // current information type
    this.currentInformationType = null;
    // initialize controller
    this.init();
};

Lmit.Wise.Chart.Control.InformationType.prototype = {

    init: function(){
        this.view = $('<div>').addClass('wmui-chart-control-information-type');
        this.container.append(this.view);
        this.build();
    },

    setInformationType: function(informationTypeCode){
        $(this.view).find("li[data-type='"+this.currentInformationType+"']").removeClass('current');
        this.currentInformationType = informationTypeCode;
        $(this.view).find("li[data-type='"+informationTypeCode+"']").addClass('current');
    },

    idle: function(){
        this.view.fadeOut(400);
    },

    enable: function(){
        this.view.fadeIn(400);
    },

    build: function(){
        var thiz = this;
        var controlPlaceholder = $('<ul>').addClass("ctrl");
        var defaultInformationType = this.options.defaultInformationType || 'realtime'; // sets realtime as default information type if it's not overriden
        _.each(this.informationTypes, function(item,i){
            var informationType = $('<li>').addClass("mode").attr({ "data-type": item.code }).text(item.name);
            if (item.code == defaultInformationType){
                informationType.addClass('current');
                thiz.currentInformationType = item.code;
            }
            controlPlaceholder.append(informationType);
            thiz.bindItemEvent(informationType);
        });
        this.view.append(controlPlaceholder);
    },

    bindItemEvent: function (item){
        var thiz = this;
        item.click(function (e){
            if (!$(this).hasClass('locked')){
                var selectedInformationType = $(this).attr('data-type');
                if (thiz.currentInformationType === null || thiz.currentInformationType != selectedInformationType){
                    thiz.setInformationType(selectedInformationType);
                    switch(selectedInformationType){
                        case 'realtime':
                            thiz.controller.indicatorController.analyzeController.container.close();
                            thiz.controller.indicatorController.alarmsController.container.open();
                            thiz.controller.indicatorController.costsController.container.open();
                            thiz.controller.indicatorController.exportController.onAnaliseChange('disabled');
                            thiz.controller.enable('plot', true, {});
                            break;
                        case 'analysis':
                            var s = thiz.controller.indicatorController.analyzeController.toolbar.elements[0];
                            if (s.select.find('option[data-type="usr"]').length > 0){ s.select.val(s.select.find('option[data-type="usr"]:first').val()).change(); }
                            thiz.controller.indicatorController.analyzeController.container.open({ showContent: true });
                            thiz.controller.indicatorController.alarmsController.container.close();
                            if(thiz.controller.indicatorController.costsController) thiz.controller.indicatorController.costsController.container.close();
                            thiz.controller.indicatorController.exportController.onAnaliseChange('active');
                            break;
                    }
                }
            }
        });
    },

    show: function(){
        this.fetch();
    },

    hide: function(){
        this.removePlot();
        if(this.controller.plotBands.length>0){
            this.controller.chart.removePlotBand("_event_period_");
            $.mbx.close();
        }
    },

    disableInformationType: function(informationType){
        this.view.find('ul.ctrl > li[data-type="' + informationType + '"]').addClass('locked');
    },

    enableInformationType: function(informationType){
        this.view.find('ul.ctrl > li[data-type="' + informationType + '"]').removeClass('locked');
    }

};
