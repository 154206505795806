module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'result\' data-href=\'' +
((__t = ( href )) == null ? '' : __t) +
'\'>\n    <div class=\'building-name\'>' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n    <div class=\'building-address\'>' +
((__t = ( address )) == null ? '' : __t) +
'</div>\n    <div class=\'building-tags\'>' +
((__t = ( WiseMetering.icon('tags', 'fa-sm') )) == null ? '' : __t) +
'&nbsp;' +
((__t = ( tags || i18next.t('ui.map_search.no_tags_available') )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
}