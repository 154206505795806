WiseMetering.Views.CircuitAutomation = Backbone.Marionette.ItemView.extend({
    events: {
        'click #edit-schedule': 'editSchedule',
        'click #manage-conditional': 'manageConditional'
    },
    modelEvents: {
        'change': 'render'
    },
    template: 'templates/circuits/automation',
    ui: {
        manageConditional: '#manage-conditional',
        schedule: '#circuit-schedule'
    },

    manageConditional: function() {
        $.get(`${WiseMetering.apiUrl}/devices/${this.model.get('wise_box_id')}/inputs`).then(function(availableInputs) {
            new WiseMetering.Views.ManageCircuitConditional({ availableInputs, model: this.model });
        }.bind(this));
    },

    editSchedule: function(event) {
        event.preventDefault();
        new WiseMetering.Views.EditCircuitSchedule({ model: this.model });
    },

    onRender: function() {
        const view = new WiseMetering.Views.CircuitScheduleHistory({ model: this.model });
        view.render();
        this.ui.schedule.html(view.el);
    }
});
