Lmit.Wise.Alarms.Controller = function(controller, container, options) {
    this.options = options;
    this.controller = controller;
    this.container = container;
    this.element = null;
    this.body = null;
    this.toolbar = null;
    this.unit = this.options.unit || '';
    this.users = null;
    this.init();
};

Lmit.Wise.Alarms.Controller.prototype = {
    init: function() {
        this.element = $('<li>').addClass('wmui-chart-triggers');
        if (this.options.id) {
            this.element.attr({ id: this.options.id });
        }
        if (this.container instanceof Lmit.Section) {
            this.container.addItem(this);
        } else {
            this.container.append(this.element);
        }
        this.build();
    },

    build: function() {
        this.buildToolbar();
        this.body = $('<div>');
        this.element.append(this.body);
        this.buildTriggerList();
    },

    buildTriggerList: function() {
        this.triggerList = $('<dl>').addClass(this.options.style).css('margin-right', '0');
        this.body.html(this.triggerList);

        const alarms = WiseMetering.alarms.where({ indicator_id: this.options.indicatorUUID });

        if (alarms.length === 0) {
            this.triggerList.find('div.wmui-section-loader').text(i18next.t('alarms.no_alarms'));
            return;
        }

        alarms.forEach(function(alarm) {
            let newTriggerRow = $('<div>').addClass('offset')
            .css({ 'margin-right': '0', 'width': '100%', 'display': 'flex', 'padding': '5px 0' })
            .append($('<span>').css({ 'margin-right': '2px', 'font-size': '9px' }).html(alarm.get('name')).attr('title', alarm.get('description')));

            newTriggerRow.children().click(function() {
                new WiseMetering.Views.ShowAlarm({ model: WiseMetering.alarms.get(alarm.id) });
            });

            newTriggerRow.children().hover(function(event) {
                $(event.currentTarget).css('text-decoration', 'underline');
            }, function(event) {
                $(event.currentTarget).css('text-decoration', 'none');
            });

            this.triggerList.append(newTriggerRow);

            const enableDisableButton = $('<div>')
            .addClass('wmui-btn disable wmui-symbol')
            .addClass(alarm.get('active') ? 'deactivate' : 'activate')
            .attr('style', 'margin-left: auto !important')
            .text(i18next.t(`common.${alarm.get('active') ? 'disable' : 'enable'}`));

            enableDisableButton.data('active', alarm.get('active'));
            enableDisableButton.click(function() {
                const active = !$(this).data('active');
                alarm.save({ active }, {
                    error: function() {
                        alert(i18next.t('alarms.error_updating'));
                    }, patch: true, success: function(alarm) {
                        enableDisableButton.data('active', alarm.get('active'));
                        if (alarm.get('active')) {
                            enableDisableButton.removeClass('activate').addClass('deactivate');
                        } else {
                            enableDisableButton.removeClass('deactivate').addClass('activate');
                        }
                        enableDisableButton.html(i18next.t(`common.${alarm.get('active') ? 'disable' : 'enable'}`));
                    }, wait: true
                });
            });
            newTriggerRow.append(enableDisableButton);

            const removeButton = $('<div>').addClass('wmui-btn rm wmui-symbol strong').text('✖').css('float', 'right');
            removeButton.click(function() {
                alarm.destroy({
                    error: function() {
                        alert(i18next.t('alarms.error_removing'));
                    },
                    success: function() {
                        WiseMetering.alarmSchedules.fetch({
                            success: function() {
                                $(this).parent().remove();
                            }.bind(this)
                        });
                    }.bind(this),
                    wait: true
                });
            });
            newTriggerRow.append(removeButton);
        }.bind(this));
    },

    buildToolbar: function() {
        let buttonAddTrigger = {
            onSelect: function() {
                new WiseMetering.Views.NewAlarm({
                    callback: this.buildTriggerList.bind(this),
                    collection: WiseMetering.alarms,
                    indicator: this.controller.indicator,
                    model: new WiseMetering.Model.Alarm
                });
            }.bind(this)
        };

        this.toolbar = new Lmit.UI.Toolbar(
            'Alarms Toolbar',
            [
                ['wmui-label', i18next.t('alarms.alarm_list'), { style: { 'float': 'left', 'text-transform': 'capitalize' } }],
                ['wmui-btn', i18next.t('modal.title.alarms.new'), { style: { 'float': 'right' } }, buttonAddTrigger]
            ],
            this.element,
            { 'style': 'regular' }
        );
    }
};
