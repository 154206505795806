WiseMetering.Views.CircuitAdd = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/circuits/add',
    form_prefix: 'circuit',
    title: () => i18next.t('modal.title.circuits.new'),
    className: 'mbx-info text-left',

    onRender: function() {
        this.$('select#circuit-tag').append(WiseMetering.utils.optionsFromModels(WiseMetering.circuitTags.models));
    }
});
