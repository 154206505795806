module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped\'>\n        <div class=\'schedule-left\'>\n            <dt>\n                <label>Circuit</label>\n            </dt>\n            <dd>' +
((__t = ( fullPath )) == null ? '' : __t) +
'</dd>\n            <div id=\'circuit-schedule-extra\'></div>\n        </div>\n        <div class=\'schedule-right\'>\n            <dt>\n                <label>Schedule</label>\n            </dt>\n            <div id=\'schedule\'></div>\n        </div>\n    </dl>\n</form>\n';

}
return __p
}