module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('common.attributes') )) == null ? '' : __t) +
'\n                <div class=\'wmui-btn\' id=\'edit_attributes\' style=\'float: right;\'>' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                <div class=\'per-box\'>\n                    <dl>\n                        <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('common.start') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( start )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('common.finish') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( finish )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('glossary.tariff') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( `<a id='${tariff.id}' href='/tariffs'>${tariff.get('name')}</a>` )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( lastChange )) == null ? '' : __t) +
'</dd>\n                        ';
 if (contractedPower) { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('glossary.contracted_power') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( contractedPower )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n                    </dl>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}