WiseMetering.Model.Tar = WiseMetering.Model.extend({
    urlRoot: '/tars',

    initialize: function() {
        if (!this.has('id')) {
          this.set('id', this.cid);
        }
    },

    lastestTar: function() {
        return this.get('intervals').reduce((latest, current) => {
            const latestDate = new Date(latest.start);
            const currentDate = new Date(current.start);
            return currentDate > latestDate ? current : latest;
        });
    }
});
