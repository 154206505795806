WiseMetering.Views.TenantDetailsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/tenants/details',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click #edit_attributes': 'editAttributes'
    },

    editAttributes: function(event) {
        event.stopPropagation();
        new WiseMetering.Views.EditTenant({ model: this.model });
    }
});
