module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="costs-table" style="margin: 10px;">\n    <table class="dataTable">\n        <thead role="row">\n        <tr>\n            <th class="ui-state-default sorting_disabled">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.day') )) == null ? '' : __t) +
'\n                </div>\n            </th>\n            <th class="ui-state-default sorting_disabled">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.time') )) == null ? '' : __t) +
'\n                </div>\n            </th>\n            <th class="ui-state-default sorting_disabled align-right">\n                <div class="DataTables_sort_wrapper">\n                    ' +
((__t = ( i18next.t('common.value') )) == null ? '' : __t) +
' (€/kWh)\n                </div>\n            </th>\n        </tr>\n        </thead>\n        <tbody></tbody>\n    </table>\n</div>\n';

}
return __p
}