module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="mbx-info text-left">\n    <dl class="stepped zero">\n        <dt>\n            <label for="contract-name">' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'&nbsp;*</label>\n        </dt>\n        <dd>\n            <input class="text" id="contract-name" name="contract[name]" type="text" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </dd>\n        <dt>\n            <label for="contract-finish">' +
((__t = ( i18next.t('common.finish') )) == null ? '' : __t) +
'&nbsp;*</label>\n        </dt>\n        <dd>\n            <input class="text" disabled id="contract-finish" min="' +
((__t = ( start )) == null ? '' : __t) +
'" name="contract[finish]" required type="date" value="' +
((__t = ( finish )) == null ? '' : __t) +
'">\n        </dd>\n    </dl>\n</div>\n';

}
return __p
}