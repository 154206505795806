Lmit.UI.Icon = function(container, options) {
    this.options = options;
    this.container = container;
    this.element = null;
    this.render = null;
    this.path = null;
    this.index = options.index || null;
    this.fillColor = null;
    this.delegate = options.delegate || null;
    this.name = null;
    this.init();
};

Lmit.UI.Icon.prototype = {
    icons: {
        in_arrow_east: 'M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8s8-3.582,8-8S12.418,0,8,0z M6.715,13.353l-1.946-1.948L8.174,8L4.769,4.594l1.946-1.946L12.067,8L6.715,13.353z',
        in_arrow_west: 'M8,16c4.418,0,8-3.582,8-8s-3.582-8-8-8S0,3.582,0,8S3.582,16,8,16z M9.285,2.647l1.946,1.948L7.826,8l3.405,3.406l-1.946,1.946L3.933,8L9.285,2.647z',
        jump: 'M12.981,9.073V6.817l-12.106,6.99l12.106,6.99v-2.422c3.285-0.002,9.052,0.28,9.052,2.269c0,2.78-6.023,4.263-6.023,4.263v2.132c0,0,13.53,0.463,13.53-9.823C29.54,9.134,17.952,8.831,12.981,9.073z',
        search: 'M29.772,26.433l-7.126-7.126c0.96-1.583,1.523-3.435,1.524-5.421C24.169,8.093,19.478,3.401,13.688,3.399C7.897,3.401,3.204,8.093,3.204,13.885c0,5.789,4.693,10.481,10.484,10.481c1.987,0,3.839-0.563,5.422-1.523l7.128,7.127L29.772,26.433zM7.203,13.885c0.006-3.582,2.903-6.478,6.484-6.486c3.579,0.008,6.478,2.904,6.484,6.486c-0.007,3.58-2.905,6.476-6.484,6.484C10.106,20.361,7.209,17.465,7.203,13.885z',
        view_off: 'M16,8.286C8.454,8.286,2.5,16,2.5,16s5.954,7.715,13.5,7.715c5.771,0,13.5-7.715,13.5-7.715S21.771,8.286,16,8.286z',
        view_on: 'M16,8.286C8.454,8.286,2.5,16,2.5,16s5.954,7.715,13.5,7.715c5.771,0,13.5-7.715,13.5-7.715S21.771,8.286,16,8.286zM16,20.807c-2.649,0-4.807-2.157-4.807-4.807s2.158-4.807,4.807-4.807s4.807,2.158,4.807,4.807S18.649,20.807,16,20.807zM16,13.194c-1.549,0-2.806,1.256-2.806,2.806c0,1.55,1.256,2.806,2.806,2.806c1.55,0,2.806-1.256,2.806-2.806C18.806,14.451,17.55,13.194,16,13.194z'
    },

    init: function() {
        if (this.options.divClass === undefined) {
            this.element = $('<div>').addClass('wmui-icon');
            if (this.options.style) {
                this.element.css(this.options.style);
            }
            if (this.options.klass) {
                this.element.addClass(this.options.klass);
            }
            this.container.append(this.element);
            this.setPath(this.options.icon);
        } else if (this.options.divClass === false) {
            this.element = this.container;
            this.setPath(this.options.icon);
        } else {
            this.element = $('<div>').addClass(this.options.divClass);
            if (this.options.style) {
                this.element.css(this.options.style);
            }
            if (this.options.klass) {
                this.element.addClass(this.options.klass);
            }
            this.container.append(this.element);
            this.setPath(this.options.icon);
        }
        this.bindEvents();
    },

    setPath: function(name) {
        this.name = name;
        this.element.empty();
        this.render = new Highcharts.Renderer(this.element.get(0), this.options.w || 36, this.options.w || 36);
        this.path = this.render.path(this.icons[this.name]);
        this.attr(this.options);
        this.path.add();
    },

    attr: function(args) {
        if (args && args !== this.options) {
            $.extend(this.options, args);
        }
        if (this.options.icon) {
            this.options.path = this.icons[this.options.icon];
            this.state = this.options.icon;
        }
        if (this.options.path) {
            this.path.attr({ 'd': this.options.path });
        }
        if (this.options.fillColor) {
            this.path.attr({ 'fill': args.fillColor });
        }
        if (this.options.strokeColor) {
            this.path.attr({ 'stroke': args.strokeColor });
            this.path.attr({ 'stroke-width': args.strokeWidth });
        } else {
            this.path.attr({ 'stroke-width': 0 });
        }

        const transform = [];
        if (this.options.scale) {
            transform.push('scale(' + this.options.scale + ',' + this.options.scale + ')');
        }
        if (this.options.offset) {
            transform.push('translate(' + this.options.offset[0] + ',' + this.options.offset[1] + ')');
        }
        if (this.options.rotate) {
            transform.push('rotate(' + this.options.rotate + ')');
        }
        this.path.attr({ transform: transform.join(' ') });

        this.state = this.options.icon;
        if (this.options.style) {
            $(this.render.box).css(this.options.style);
        }
    },

    bindEvents: function() {
        const self = this;
        if (self.delegate) {
            if (self.delegate.onSelect) {
                self.element.click(function(e) {
                    self.delegate.onSelect(self, e);
                });
            }
            if (self.delegate.onMouseOver) {
                self.element.mouseover(function(e) {
                    self.delegate.onMouseOver(self, e);
                });
            }
            if (self.delegate.onMouseOut) {
                self.element.mouseout(function(e) {
                    self.delegate.onMouseOut(self, e);
                });
            }
        }
    }
};
