WiseMetering.Views.UtilityCosts = Backbone.Marionette.Layout.extend({
    template: 'templates/utilities/costs',
    regions: {
        container: '#utility-costs-container'
    },

    onShow: function() {
        const costView = new WiseMetering.Views.Costs({
            maxDate: this.model.finish(),
            minDate: this.model.start(),
            model: this.model
        });

        this.container.show(costView);
    }
});
