WiseMetering.Views.IndexHoliday = WiseMetering.Views.TableIndex.extend({
    icon: 'holidays',
    name: () => i18next.t('glossary.holidays'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'holidays',
            collection: WiseMetering.holidays,
            columns: {
                date: i18next.t('common.date'),
                name: i18next.t('common.name'),
                city: i18next.t('common.city')
            },
            sort: {
                column: 'date',
                order: 'desc'
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.NewHoliday({
                        collection: WiseMetering.holidays,
                        model: new WiseMetering.Model.Holiday
                    })
                },
                show: {
                    view_call: model => new WiseMetering.Views.EditHoliday({ model })
                },
                delete: {
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.holidays.destroy')
                    })
                }
            }
        });
    }
});
