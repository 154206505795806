WiseMetering.Views.DisableUser = WiseMetering.Views.Modal.Custom.extend({
    buttons: ['yes', 'no'],
    className: 'mbx-info',
    template: 'templates/users/disable',
    width: 650,

    title: function() {
        return `${i18next.t('common.disable').capitalize()} ${this.model.get('name')}`;
    },

    yes: function() {
        this.block();
        this.model.disable(
            function() {
                WiseMetering.layout.showTipper('success', i18next.t('users.disable_user', { name: this.model.get('name') }));
                this.close();
            }.bind(this),
            function() {
                WiseMetering.layout.showTipper('error', i18next.t('users.error_message'));
                this.close();
            }.bind(this)
        ).then(data => {
            this.options.callback();
            this.model.set(data);
        });
    }
});
