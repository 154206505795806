module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dd class="asset-settings">\n    <span class="setting-name">' +
((__t = ( name )) == null ? '' : __t) +
'</span>\n    <label class=\'switch\'>\n        <input ' +
((__t = ( readOnly ? 'disabled' : null )) == null ? '' : __t) +
' style=\'height:20px\' type=\'checkbox\' id=\'settings-key\' name=\'settings[' +
((__t = (key)) == null ? '' : __t) +
']\' ' +
((__t = ( value ? 'checked' : null )) == null ? '' : __t) +
'>\n        <span class=\'slider round\'></span>\n    </label>\n</dd>\n';

}
return __p
}