WiseMetering.Views.TmpIndicator = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/types/tmp',

    serializeData: function() {
        return {
            ratio: this.model.get('ratio')
        };
    }
});

