WiseMetering.Views.BaseSchedule = Backbone.View.extend({
    tagName: 'div',
    className: 'wmui-schedule-view',
    id: 'schedule_grid',
    editable: false,
    addValues: false,
    editValues: false,
    periods: [],
    startPosition: {},
    globalSelectedColor: null,
    mouseDown: false,

    events: function() {
        return this.editable ? {
            'click div.color-legend': 'pickColorFromLegend',
            'mousedown tbody > tr > td.input': 'onMouseDown',
            'mouseenter tbody > tr > td.input': 'onMouseEnter',
            'mouseleave tbody > tr > td.input': 'onMouseLeave',
            'mouseup tbody > tr > td.input': 'onMouseUp',
            'periodchange div#dynamic-periods': 'setColors'
        } : {
            'mouseenter tbody > tr > td.input': 'onMouseEnter', 'mouseleave tbody > tr > td.input': 'onMouseLeave'
        };
    },

    initialize: function() {
        this.ruleValues = [];
        this.ruleColors = [];

        if (this.options.addValues !== undefined) this.addValues = this.options.addValues;
        if (this.options.colors !== undefined) this.colors = this.options.colors;
        if (this.options.editable !== undefined) this.editable = this.options.editable;
        if (this.options.editValues !== undefined) this.editValues = this.options.editValues;
        if (this.options.periods !== undefined) this.periods = this.options.periods;
        if (this.options.unit !== undefined) this.unit = this.options.unit;
        if (this.options.values !== undefined) this.values = this.options.values;

        this.render();
        this.setColors();
    },

    render: function() {
        this.$el.html(JST[this.template]({
            addValues: this.addValues,
            colors: this.colors,
            editable: this.editable,
            editValues: this.editValues,
            periods: this.periods,
            schedule: this.options.schedule,
            kind: this.options.kind,
            unit: this.options.unit,
            values: this.options.values
        }));
    },

    setColors: function() {
        this.ruleValues = [];
        this.ruleColors = [];

        _.each(this.$('div.color-legend'), rule => {
            this.ruleValues.push($(rule).attr('name'));
            this.ruleColors.push($(rule).children('span.color-box').css('background-color'));
        });
    },

    onMouseUp: function() {
        this.startPosition = {};
        this.mouseDown = false;
    },

    onMouseLeave: function() {
        $('#current_position').hide();
    },

    onMouseDown: function(event) {
        const newValue = this.ruleValues[_.indexOf(this.ruleColors, this.globalSelectedColor)];
        if (newValue) {
            this.startPosition = {
                x: parseInt($(event.currentTarget).attr('data-x')), y: parseInt($(event.currentTarget).attr('data-y'))
            };
            this.mouseDown = true;
            $(event.currentTarget).css('background-color', this.globalSelectedColor);
            $(event.currentTarget).children('input').val(newValue);
        }
    },

    onMouseEnter: function(event) {
        if (this.mouseDown) {
            const newValue = this.ruleValues[_.indexOf(this.ruleColors, this.globalSelectedColor)];
            const startX = this.startPosition.x;
            const startY = this.startPosition.y;
            const currentX = parseInt($(event.currentTarget).attr('data-x'));
            const currentY = parseInt($(event.currentTarget).attr('data-y'));
            _.each(this.$('tbody > tr > td.input'), element => {
                let elementX = parseInt($(element).attr('data-x'));
                let elementY = parseInt($(element).attr('data-y'));
                if (elementX >= startX && elementX <= currentX && elementY >= startY && elementY <= currentY) {
                    $(element).css('background-color', this.globalSelectedColor);
                    $(element).children('input').val(newValue);
                }
            });
        }
        const current_color = $(event.currentTarget).css('background-color');
        const current_index = _.indexOf(this.ruleColors, current_color);
        const current_time = this.nameFromCoordinates($(event.currentTarget).attr('data-x'), $(event.currentTarget).attr('data-y'));
        const current_name = current_index !== -1 ? this.ruleValues[current_index] : i18next.t('common.none');
        $('#current_position').show().html(`<b>${i18next.t('ui.schedule.current_position')}:</b><br><b>${i18next.t('common.state')}:&nbsp;</b>${current_name}<br><b>${i18next.t('common.time')}:&nbsp;</b>${current_time}`);
    },

    nameFromCoordinates: function(x, y) {
        const
            weekday = WiseMetering.weekdays[Math.floor(x / 4)],
            hour = WiseMetering.utils.pad2(y),
            minute = WiseMetering.utils.pad2((x % 4) * 15);

        return `${i18next.t(`weekdays.${weekday}`)} @ ${hour}:${minute}`;
    },

    selectColor: function(element) {
        const $element = $(element);

        $element.children('span.color-text').css('font-weight', 'bold');
        $element.children('span.color-box').css('border-color', 'rgb(200, 200, 200)');
        $element.addClass('selected');
    },

    deselectColor: function(element) {
        const $element = $(element);

        $element.children('span.color-text').css('font-weight', 'normal');
        $element.children('span.color-box').css('border-color', 'rgb(255, 255, 255)');
        $element.removeClass('selected');
    },

    pickColorFromLegend: function(event) {
        if (!$(this).hasClass('selected')) {
            this.selectColor(event.currentTarget);
            _.each($(event.currentTarget).siblings(), element => {
                this.deselectColor(element);
            });
            this.globalSelectedColor = $(event.currentTarget).children('span.color-box').css('background-color');
        }
    }
});
