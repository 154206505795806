WiseMetering.Views.NewShopperTrackEtl = Backbone.Marionette.ItemView.extend({
    template: 'templates/etls/types/shopper_track',

    filterIndicators: function() {
        // to be changed based on real requirements
        return WiseMetering.indicators.filter(function(indicator) {
            if (!indicator.get('building_id')) {
                return false;
            }

            const
                timezone = indicator.building()?.get('timezone');

            return indicator.utilityKind() && timezone === 'Europe/Lisbon';
        });
    },

    serializeData: function() {
        const indicators = this.filterIndicators();

        return {
            indicators: _.groupBy(indicators, indicator => indicator.get('building_name')),
            rate: WiseMetering.etlRates[5],
            start_at: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            mode: 'local'
        };
    }
});
