module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'form_content\'>\n    <dl class=\'stepped\'>\n        ' +
((__t = ( body )) == null ? '' : __t) +
'\n    </dl>\n</div>\n';

}
return __p
}