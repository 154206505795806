module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.state') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( state )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.category') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( category )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.organization') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( organization_name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( building_name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.integration_partner') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( integration_partner_name )) == null ? '' : __t) +
'</dd>\n    ';
 if (WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.base_url') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( base_url )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n    <dt>' +
((__t = ( i18next.t('glossary.external_id') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( external_building_ref )) == null ? '' : __t) +
'</dd>\n</dl>\n';

}
return __p
}