WiseMetering.Views.Ui.Widget.NoData = Backbone.View.extend({
    templateID: 'templates/ui/widgets/no_data',
    editMode: false,
    message: '',

    initialize: function(params) {
        $.extend(this, params);
        this.$el.on(`click`, `#options-${this.options.widgetPreferences.id}`, this.widgetOptions.bind(this));
    },

    build: function(){
    },

    render: function() {
        this.$el = $(JST[this.templateID]({
            message: this.options.message,
            organizationId: WiseMetering.currentOrganization.get('id'),
            title: this.options.title,
            widgetPreferences: this.options.widgetPreferences
        }));

        this.editMode ? $(`#wrapper-${this.options.widgetPreferences.id}`).replaceWith(this.$el) : $(this.options.el).append(this.$el);
        this.editMode = false;
    },

    cancelEdit: function() {
        this.editMode = true;

        this.render();
    },

    close: function() {
        this.$el.remove();
    },

    updatePreferences: function(newPreferences) {
        this.editMode = true;
        this.widgetPreferences = newPreferences;

        this.render();
    },

    widgetOptions: function() {
        this.optionsView = new WiseMetering.Views.Ui.WidgetOptions({
            el: `#region-${this.widgetPreferences.id}`,
            preferences: this.widgetPreferences,
            title: this.options.title,
        });

        this.listenTo(this.optionsView, 'save:preferences', this.updatePreferences);
        this.listenTo(this.optionsView, 'cancel:preferences', this.cancelEdit);
        this.optionsView.render();
    }
});
