WiseMetering.Views.ShowMarker = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/markers/show',
    className: 'mbx-info text-left',
    width: 500,

    buttons: function() {
        var buttons = ['close'];
        if (WiseMetering.currentUser.get('manager')) buttons.push('edit');
        return buttons;
    },

    edit: function() {
        this.block();
        _.defer(function() {
            new WiseMetering.Views.EditMarker({ model: this.model });
        }.bind(this));
    },

    serializeData: function() {
        const
            model = this.model,
            building = model.building(),
            creator = model.creator(),
            description = model.get('description') || 'No description',
            updater = model.updater(),
            indicator = model.indicator(),
            json = model.toJSON();

        json.building = building ? building.get('name') : null;
        json.creator = creator ? creator.get('name') : 'System';
        json.created_at = WiseMetering.utils.formatDate(model.get('created_at'), building.get('timezone'), 'YYYY/MM/DD HH:mm');
        json.from = WiseMetering.utils.formatDate(model.get('marker_at'), building.get('timezone'), 'YYYY/MM/DD HH:mm');
        json.to = model.get('end_date') ? WiseMetering.utils.formatDate(model.get('end_date'), building.get('timezone'), 'YYYY/MM/DD HH:mm') : null;
        json.description = description.replace(/\n/g, '<br/>');
        json.indicator = indicator;
        json.reference_period_start = model.get('reference_period_start') ? WiseMetering.utils.formatDate(model.get('reference_period_start'), building.get('timezone'), 'YYYY/MM/DD HH:mm') : null;
        json.state = i18next.t(`states.${model.get('state')}`);
        json.stateColor = this.model.states[this.model.get('state')].color;
        json.updater = updater ? updater.get('name') : 'System';
        json.updated_at = WiseMetering.utils.formatDate(model.get('updated_at'), building.get('timezone'), 'YYYY/MM/DD HH:mm');

        let results = model.results();
        if (results) {
            results = results.replace('\n', '<br/>');
        }
        json.results = results;
        return json;
    },

    title: function() {
        return i18next.t('modal.title.markers.show');
    }
});
