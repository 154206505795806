module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('events.triggered_by') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( trigger )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.created_by') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( creator )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( description )) == null ? '' : __t) +
'</dd>\n    ';
 if(indicator){ ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( indicator )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n</dl>\n';

}
return __p
}