Lmit.UI.View = function( _controller, _options_) {
    this.options= _options_ || {};
    this.controller = _controller;
    this.element = null;
    this.init();
};

Lmit.UI.View.prototype = {
    init:function(){
        this.build();
    },

    build:function(){
        this.element = $("<div>").addClass("wmui-view")
        if (this.options.id) this.element.attr({id:this.options.id});
        if (this.options.klass) this.element.addClass(this.options.klass);
        if (this.container) this.container.append(this.element);
    },

    hide: function(){
        this.element.hide()
    },

    show: function(){
        this.element.show()
    }

};
    
