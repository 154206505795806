Lmit.Wise.Chart.Control.Resume = function(_controller_, _container_, _options_) {
    this.options = _options_;
    this.controller = _controller_;
    this.container = _container_;
    this.format = _options_.format || null;
    // view elements
    this.view = null;
    this.list = null;
    this.box = null;
    this.vhandler = null;
    this.iconCtrlHandler = null;
    this.componentLoadingTemplate = _.template(`
    <li data-id="<%=id%>" data-index="<%=index%>">
    <img class="componentLoading" src="/images/wisemetering/loader.svg" style="max-height:50px; margin-top:-10px; padding:0 10px;">
    </li>
    `);
    //defaults
    this.init();
};

Lmit.Wise.Chart.Control.Resume.prototype = {

    init: function() {
        this.view = $('<div>').addClass('wmui-chart-control-resume');
        this.box = $('<div>').addClass('wmui-resume-box');
        this.list = $('<ul>').addClass('board collapsed');
        this.view.append(this.box);
        this.box.append(this.list);
        if (this.container) {
            this.container.append(this.view);
        }
        this.addNavigation();
        this.addBehaviour();
        this.addHandler();
    },

    addHandler: function() {
        var _this_ = this;

        this.vhandler = $('<div>').addClass('btn-ctrl').text(i18next.t('common.more'));
        this.view.append(this.vhandler);

        this.vhandler.click(function(e) {
            if (!_this_.list.hasClass('collapsed')) {
                _this_.list.addClass('collapsed');
                _this_.list.animate({ height: '40px' }, 200);
                _this_.vhandler.text(i18next.t('common.more'));
            } else {
                _this_.list.removeClass('collapsed');
                _this_.list.animate({ height: '90px' }, 200);
                _this_.vhandler.text(i18next.t('common.less'));
            }
        });

    },

    addBehaviour: function() {
        var _target_width = this.targetWidth();
        var _container_width = this.view.width();
        var _margin_left = Math.abs(Number(this.list.css('marginLeft').split('px')[0])) || 0;
        var _right_hidden = Math.abs((_margin_left + _container_width) - _target_width);
        this.view.mouseover(function(e) {
            if (_target_width > _container_width) {
                $(this).addClass('show-ctrl');
            } else {
            }
        });
    },

    addNavigation: function() {
        var _this_ = this;
        var delegate = {
            onSelect: function(src, e) {
                var _move;
                switch (src.options.icon) {
                    case 'in_arrow_west':
                        _move = _this_.pushLeft();
                        if (_move !== null) {
                            _this_.list.animate({ marginLeft: _move + 'px' });
                        }
                        break;
                    case 'in_arrow_east':
                        _move = _this_.pushRight();
                        if (_move !== null) {
                            _this_.list.animate({ marginLeft: _move + 'px' });
                        }
                        break;
                }
            }
        };

        var delegate_handler = {
            onSelect: function(src, e) {
            }
        };

        var mv_right = new Lmit.UI.Icon(this.box, { icon: 'in_arrow_east', delegate: delegate, klass: 'wmui-btn-hdl in_arrow_east right', fillColor: '#e3e3e3', w: 16, h: 16, scale: 1, offset: [0, 0] });
        var mv_left = new Lmit.UI.Icon(this.box, { icon: 'in_arrow_west', delegate: delegate, klass: 'wmui-btn-hdl in_arrow_west left', fillColor: '#e3e3e3', w: 16, h: 16, scale: 1, offset: [0, 0] });

    },

    pushRight: function() {
        var _target_width = this.targetWidth();
        var _container_width = this.view.width();
        var _total_sections = this.list.children().length;
        var _step = _target_width / _total_sections;
        var _margin_left = Math.abs(Number(this.list.css('marginLeft').split('px')[0])) || 0;
        var _right_hidden = Math.abs((_margin_left + _container_width) - _target_width);

        var _moveTo = 0;

        if (_target_width > _container_width) {
            if ((_right_hidden < _container_width)) {
                _moveTo = -(Math.abs(_margin_left) + _right_hidden);
            } else {
                _moveTo = -(Math.abs(_margin_left) + _container_width);
            }
        }
        return _moveTo;
    },

    pushLeft: function() {
        var _target_width = this.targetWidth();
        var _container_width = this.view.width();
        // var _max_steps = Pa_container_width/_steps;
        var _total_sections = this.list.children().length;
        var _step = _target_width / _total_sections;
        var _margin_left = Math.abs(Number(this.list.css('marginLeft').split('px')[0])) || 0;
        var _visible = Math.abs(_margin_left) - _target_width;

        var _moveTo = 0;
        if (_margin_left > 0) {
            if (_margin_left > _container_width) {
                _moveTo = -(_margin_left) + _container_width;
            } else {
                _moveTo = 0;
            }
        }

        return _moveTo;

    },

    targetWidth: function() {
        var _this_ = this;
        var _width = 0;
        _.each(this.list.children(), function(_sectionElm) {
            _width += $(_sectionElm).width() + 18;
        });
        return _width;
    },

    add: function(args) {
        args.forEach(data => {
            if (data.value === null || data.value === undefined) return;
            const board = this.build(data);
            this.list.find(`[data-id=${data.code || data.id}]`).css({ color: data.color }).html(board);
        });
    },

    generateLoading(id) {
        const getLastIndex = () => {
            return this.list.find('li[data-index]').toArray()
            .map(i => parseInt(i.dataset.index))
            .sort((a, b) => b - a)
            .at(0);
        };

        const index = getLastIndex() + 1 || 0;
        const tmpl = this.componentLoadingTemplate({ id, index });
        this.list.prepend(tmpl);
    },

    build: function(args) {
        const list = $('<ul>');
        [
            { key: 'sum', text: i18next.t('common.total') },
            { key: 'avg', text: i18next.t('common.average') },
            { key: 'max', text: i18next.t('common.max') },
            { key: 'min', text: i18next.t('common.min') },
            { key: 'value', text: i18next.t('common.last') }
        ].forEach(item => {
            const value = args[item.key];
            if (value) {
                list.append($('<li>').addClass('reg small').html(`<span class='uppercase'>${item.text}</span><span class='strong'>${formatValue(value, args.unit)}</span>`));
            }
        });

        return list;
    },

    removeById: function(_id) {
        _.each(this.list.children(), function(board_item) {
            if ($(board_item).attr('data-id') == _id) {
                $(board_item).remove();
            }
        });
    },

    reset: function() {
        _.each(this.list.children('li'), function(board_item) {
            if (!$(board_item).hasClass('wmui-control-handler')) {
                $(board_item).remove();
            }
        });
    },

    idle: function() {
        this.view.hide();
    },

    enable: function() {
        this.view.show();
    },

    hideBoard: function(_id) {
        _.each(this.list.children(), function(board_item) {
            if ($(board_item).attr('data-id') == _id) {
                $(board_item).hide('fast');
            }
        });
    },

    showBoard: function(_id) {
        _.each(this.list.children(), function(board_item) {
            if ($(board_item).attr('data-id') == _id) {
                $(board_item).show('fast');
            }
        });
    },

    toggleDetails: function() {
        if (this.list.hasClass('expanded')) {
            this.iconCtrlHandler.attr({ rotate: '270 9 9' });
        } else {
            this.iconCtrlHandler.attr({ rotate: '90 9 9' });
        }
        this.list.toggleClass('expanded');
    },

    highlight: function(_args) {
        this.list.children('li[data-id=' + _args[4] + ']').addClass('wmui-board-active');
    },

    removeHighlight: function(_args) {
        this.list.children('li').removeClass('wmui-board-active');
    }
};
