Lmit.UI.SelectBox = function(_name_, _container_, _options_,_actions_) {
	this.name = _name_;
	this.id = _options_.id || null;
	this.options= _options_;	
	this.data = this.options.data || [];
	this.actions= _actions_;
	this.container = _container_ ;
  this.select = null;
	this.element = null;
	this.init();
};	


Lmit.UI.SelectBox.prototype = {
	init:function(){
		this.build();
	},
	
	build:function(){
	    var _this_= this;
      this.element = $("<li>").addClass("wmui-select-box")
      this.select = $("<select>").attr({name:this.name})
      if (this.options.disabled==true) this.disable()

      this.element.append(this.select)
    
    	if (this.options.id) this.element.attr({id:this.options.id});
      _.each(this.data, function(d){
        _this_.addOption(d);        
      })      
      
      this.container.append(this.element);
    	this.bindEvents();
	},

	addOption: function(item){

    var itemElement = $("<option>").attr({value:item[1]}).text(item[0])     

    if (item[2]) itemElement.attr({"data-id":item[2]})
    if (item[3]) itemElement.attr({"data-type":item[3]})
    if (item[4]) itemElement.attr({"data-text":item[4]})
    
    this.select.append(itemElement);    
	},
	
	selectOptionById: function(value){	  
    var _this_=this
    _.each(this.select.children(), function(item,i){
      if($(item).attr("data-id")==value) $(item).attr('selected', 'selected')             
      _this_.select.change()
    })
	},
	
	removeOptionByAttrType: function(value){
    _.each(this.select.children(), function(item,i){
      if($(item).attr("data-type")==value) $(item).remove()               
    })    
	},
		
	bindEvents: function() {
	  var _this_ = this;
	  $(this.select).change(function(e){        
      _this_.actions.onChange(this.value,e)
	  })
  },
	
	disable:function(){
	   this.select.removeClass("enabled")
     this.select.attr("disabled", true);
	},
	
	enable:function(){
	   this.select.addClass("enabled")
     this.select.attr("disabled", false);
	},
	
	empty: function(){
    _.each(this.select.children(), function(item,i){ $(item).remove() })
	}
		
};
    