module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'mbx-info\'>\n    ' +
((__t = ( i18next.t('ui.confirm_remove', { name }) )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}