Lmit.Utils = {
    normalizeDateTime: function(_date) {
        return new Date(parseInt(_date.getTime() / 3600000) * 3600000);
    },

    generateGuid: function() {
        var result, i, j;
        result = '';
        for (j = 0; j < 32; j++) {
            if (j === 8 || j === 12 || j === 16 || j === 20) {
                result = result + '-';
            }
            i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return result;
    },

    generateCode: function() {
        var result, i, j;
        result = '';
        for (j = 0; j < 6; j++) {
            i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return result;
    },

    formatNumber: function(value, unit, decimalPlaces = 2) {
        // TODO: remove this formatNumber and always use the global formatValue
        if (unit === 's') {
            return window.formatValue(Number(value), unit, decimalPlaces);
        }

        if (unit === 'm3') {
            value = Intl.NumberFormat(WiseMetering.currentLocale.name, { maximumFractionDigits: decimalPlaces }).format(value);
            return `${value} ${unit}`;
        }

        if (unit === '€') {
            value = Intl.NumberFormat(WiseMetering.currentLocale.name, { style: 'currency', currency: 'EUR' }).format(value);

            return `${value}`;
        }

        if (unit === '£') {
            value = Intl.NumberFormat(WiseMetering.currentLocale.name, { style: 'currency', currency: 'GBP' }).format(value);

            return `${value}`;
        }

        value = Number(value);
        let power = '';
        if (unit === 'N/A') {
            unit = '';
        }

        if (Math.abs(value) >= 1000000) {
            value = value / 1000000;
            power = 'M';
        } else if (Math.abs(value) >= 1000) {
            value = value / 1000;
            power = 'k';
        }

        value = Intl.NumberFormat(WiseMetering.currentLocale.name, { maximumFractionDigits: decimalPlaces }).format(value);
        return `${value} ${power}${unit}`;
    },

    isNumber: function(value) {
        return typeof value === 'number' && !Number.isNaN(value);
    },

    mbxElement: function() {
        $.mbx.remove();
        $('body').append('<div id=\'_____mbx_tgt_cn_____\'>');
        return $('div#_____mbx_tgt_cn_____');
    },

    //Reflow Highcart charts
    resizeCharts(charts) {
        charts = charts || Highcharts.charts;
        reflowHandler(reflowing)(identity)(charts); //Reflow active charts
    },

    resizeWidgets() {
        const widgetEl = $('#widgets');
        if (!widgetEl.length) return;

        const widgetCount = widgetEl.find('.widget:visible').length;

        //Calculate columns : ParentContainer / Width of first widget
        const cols = Math.round(widgetEl.width() / widgetEl.find('.widget').first().outerWidth());

        //Calculate elements left to fill line
        const leftEls = cols - (widgetCount % cols);

        document.querySelector('#widgets .ghost-item').style.flexBasis = `${leftEls * 100 / cols}%`;
    },

    triggerResize() {
        this.resizeWidgets();
        this.resizeCharts();
    },

    getMessageFromException(message) {
        return message.responseJSON.error.replace(/([^\s ]+)/, '').slice(1, -1);
    }

};

const identity = charts => charts.filter(_.identity);    //Remove empty chart entries
const reflowing = charts => charts.forEach(c => {
    try {
        c.reflow();
    } catch (e) {
    }
}); //Loop and reflow each chart
const reflowHandler = reflowing => identity => charts => reflowing(identity(charts));   //Curring chart reflowing

Date.prototype.Quarter = function() {
    var _m = this.getMonth();
    return parseInt(((_m * 4)) / 12, 10) + 1;
};

Date.prototype.Semester = function() {
    var _m = this.getMonth();
    return parseInt(((_m * 2)) / 12, 10) + 1;
};
