module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dt>\n    <label for=\'schedule-versions\'>\n        ' +
((__t = ( i18next.t('common.versions') )) == null ? '' : __t) +
':\n    </label>\n</dt>\n<dd>\n    <select id=\'schedule-versions\' disabled>\n        <option value=\'\'>Loading versions, please wait...</option>\n    </select>\n</dd>\n<dt>\n    <label>\n        ' +
((__t = ( i18next.t('glossary.schedule') )) == null ? '' : __t) +
'\n    </label>\n</dt>\n<div id=\'schedule\'></div>\n';

}
return __p
}