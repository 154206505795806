WiseMetering.Views.NewDatelkaEtl = Backbone.Marionette.ItemView.extend({
    template: 'templates/etls/types/datelka',

    filterIndicators: function() {
        return WiseMetering.indicators.filter(function(indicator) {
            if (!indicator.get('building_id')) {
                return false;
            }

            return indicator.utilityKindSlug();
        });
    },

    serializeData: function() {
        const indicators = this.filterIndicators();

        return {
            indicators: _.groupBy(indicators, indicator => indicator.get('building_name')),
            rate: WiseMetering.etlRates[0],
            start_at: moment().format('YYYY-MM-DD'),
            username: 'datelka',
            mode: 'local'
        };
    }
});
