module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n  <dt>ID</dt>\n  <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n\n  <dt>Name</dt>\n  <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n\n  <dt>Start Date</dt>\n  <dd>' +
((__t = ( from )) == null ? '' : __t) +
'</dd>\n\n  ';
 if (to) { ;
__p += '\n    <dt>End Date</dt>\n    <dd>' +
((__t = ( to )) == null ? '' : __t) +
'</dd>\n  ';
 } ;
__p += '\n\n  <dt>Fraction</dt>\n  <dd>' +
((__t = ( fraction )) == null ? '' : __t) +
'</dd>\n\n  <dt>Indicator</dt>\n  <dd>\n    <a id=\'indicator\' href="#">' +
((__t = ( indicatorName )) == null ? '' : __t) +
'</a>\n  </dd>\n\n  <dt>Tariff</dt>\n  <dd>\n    <a id=\'tariff\' href="#">' +
((__t = ( tariffName )) == null ? '' : __t) +
'</a>\n  </dd>\n\n  <dt>Created by</dt>\n  <dd>' +
((__t = ( creator)) == null ? '' : __t) +
', ' +
((__t = ( createdAt)) == null ? '' : __t) +
'</dd>\n\n  <dt>Last Updated by</dt>\n  <dd>' +
((__t = ( updater)) == null ? '' : __t) +
', ' +
((__t = ( updatedAt)) == null ? '' : __t) +
'</dd>\n</dl>\n';

}
return __p
}