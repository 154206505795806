WiseMetering.Views.Omie = WiseMetering.Views.Content.extend({
    omieData: null,
    template: 'templates/tariffs/omie',
    viewType: 'Chart',

    period: {
        from: null,
        to: null
    },

    ui: {
        chartView: '#chart-view',
        dataTable: '.dataTable tbody',
        fromField: '.date_time_from',
        loader: '.costs-loader',
        omieKindField: '#select-omie-kind',
        omieKindSection: '#select-omie-kind-section',
        tableView: '#table-view',
        timeSubmit: '.submit-time',
        toField: '.date_time_to'
    },

    events: {
        'click .submit-time': 'fetchOmieData',
        'change @ui.omieKindField': 'fetchOmieData',
        'click @ui.chartView': 'switchView',
        'click @ui.tableView': 'switchView'
    },

    regions: {
        contentArea: '#content-area'
    },

    fetchData: function(data, success, error, actionUrl) {
        const
            contentType = 'application/json',
            dataType = 'json',
            type = 'GET',
            url = WiseMetering.apiUrl + actionUrl;

        return $.ajax({ contentType, data, dataType, error, success, type, url });
    },

    fetchOmieData: function() {
        const
            params = {
                from: this.ui.fromField.val(),
                to: this.ui.toField.val(),
            },
            url = `/integration_data/omie/${this.ui.omieKindField.val()}/data`;

        WiseMetering.utils.addLoader(this.ui.loader, 0, 0, 38);

        this.fetchData(params, omie => {
            this.omieData = omie;
            this[`render${this.viewType}`](this.omieData);
        }, errors => this.renderError(errors), url);
    },

    fetchOmieList: function(){
        const
            params = {
                from: this.period.from,
                to: this.period.to
            },
            url = '/integration_data/omie';

        this.fetchData(params, omieList => this.populateTariffs(omieList), errors => this.renderError(errors), url);
    },

    onShow: function() {
        this.period.from = moment.tz().subtract(7, 'days').format('YYYY-MM-DD');
        this.period.to = moment.tz().format('YYYY-MM-DD');

        this.setupFromField();
        this.setupToField();
        this.updateFields();

        this.fetchOmieList();
    },

    populateTariffs: function(omieList) {
        const options = omieList.map(omie => ({ id: omie['id'], name: omie['identifier'] }));

        this.ui.omieKindField.html(WiseMetering.utils.optionsFromArray(options));
        this.fetchOmieData();
    },

    renderError: function(errors) {
        this.contentArea.reset();

        this.ui.loader.empty();

        $('#content-area').html(`<div style="color: #E94547; padding-left: 20px;">Error fetching data</div>`);
    },

    renderChart: function(data) {
        const
            el = '#content-area',
            title = 'OMIE',
            unit = WiseMetering.getCurrency();

        if (this.widget) {
            this.widget.close();
        }

        this.widget = new WiseMetering.Views.Ui.Widget.Omie({ el, data, title, unit});

        this.ui.loader.empty();
        this.renderWidget();
    },

    renderTable: function(data) {
        const tableView = new WiseMetering.Views.OmieTable(data);

        this.ui.loader.empty();
        this.contentArea.show(tableView);
    },

    renderWidget: function() {
        this.contentArea.reset();
        this.widget.render(false);
        this.$('#content-area').append('<div class="ghost-item">');
        Lmit.Utils.triggerResize();
        this.widget.drawChart();
    },

    setupDatepicker: function(field, callback) {
        const maxDate = new Date().toISOString().split('T')[0];

        field.datepicker({
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            minDate: "2022-01-01 00:00:00",
            maxDate: maxDate,
            onSelect: callback
        });
    },

    setupFromField: function() {
        this.ui.fromField.val(this.period.from);

        this.setupDatepicker(this.ui.fromField, dateText => {
            this.period.from = dateText;
            this.updateFields();
        });
    },

    setupToField: function() {
        this.ui.toField.val(this.period.to);

        this.setupDatepicker(this.ui.toField, dateText => {
            this.period.to = dateText;
            this.updateFields();
        });
    },

    switchView: function(event){
        const clickedElement = $(event.currentTarget);

        $('.current').removeClass('current');
        clickedElement.addClass('current');

        this.viewType = clickedElement.attr('data');
        this[`render${this.viewType}`](this.omieData);
    },

    updateFields: function() {
        this.ui.fromField.datepicker('option', 'maxDate', this.ui.toField.val());
        this.ui.toField.datepicker('option', 'minDate', this.ui.fromField.val());
    }
});
