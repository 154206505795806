WiseMetering.Views.RemoveIndicatorMetal = WiseMetering.Views.Modal.Destroy.extend({
    title: () => i18next.t('modal.title.indicators.destroy'),

    yes: function() {
        this.block();
        this.model.deleteMetals(this.options.timestamps)
        .done(() => this.options.target.trigger('ignoreMetals'))
        .fail(() => WiseMetering.layout.showTipper('error', 'Unable to delete data, please check with a developer'))
        .always(() => this.close());
    }
});
