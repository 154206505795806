WiseMetering.Views.Ui.Widget.Objective = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/objective',

    initialize: function(params) {
        params.unit = params.unit ? params.unit : WiseMetering.getCurrency();
        WiseMetering.Views.Ui.Widget.Base.prototype.initialize.call(this, params);
    },

    drawChart: function() {
        const
            { colors, unit } = this,
            period = this.period,
            months = i18next.t('highcharts.shortMonths', { returnObjects: true }),
            now = moment(),
            currentYear = now.year(),
            currentMonth = now.month(),
            currentDay = now.date(),
            daysInMonth = now.daysInMonth();

        let { objective, real, deviation } = this.data;

        if (unit.toLowerCase() === 'wh') {
            objective = objective.map(n => n * 1000);
        }

        for (let i = real.length; i < 12; i++) real.push(null);
        for (let i = objective.length; i < 12; i++) objective.push(null);

        this.chart = new Highcharts.Chart(jQuery.extend(true, {}, this.defaultChartOptions, {
            xAxis: {
                categories: i18next.t('highcharts.shortMonths', { returnObjects: true }),
                lineColor: '#797979'
            },
            yAxis: {
                labels: {
                    formatter: point => formatValue(point.value, unit)
                },
                title: {
                    enabled: false
                }
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                useHTML: true,
                labelFormatter: function() {
                    this.legendItem.symbol.hide();

                    const
                        initialMonth = period === 'ytd' ? 0 : currentMonth,
                        currentValue = real.slice(initialMonth, currentMonth + 1).reduce((acc, num) => acc + num, 0),
                        currentGoal = objective.slice(0, currentMonth + 1).reduce((acc, amount, month) => {
                            if (amount && month === currentMonth) {
                                amount = amount * currentDay / daysInMonth;
                            } else if (period === 'mtd') {
                                amount = 0;
                            }
                            return acc + amount;
                        }, 0);

                    return JST['templates/ui/widgets/objective_label']({
                        colors, formatValue, currentGoal, currentValue, deviation, unit, currentYear, period
                    });
                }
            },
            tooltip: {
                formatter: function() {
                    const month = months.indexOf(this.x);
                    let realValue = real[month] || 0,
                        objectiveValue = objective[month] || 0,
                        difference = realValue - objectiveValue;

                    return JST['templates/ui/widgets/objective_tooltip']({
                        colors,
                        month: this.x,
                        objectiveValue: formatValue(objectiveValue, unit),
                        realValue: formatValue(realValue, unit),
                        positive: difference > 0,
                        percentage: formatValue(objectiveValue ? difference / objectiveValue * 100 : 0, '%'),
                        unit: unit
                    });
                }
            },
            series: [
                {
                    name: currentYear,
                    type: 'column',
                    data: real,
                    color: this.colors[0]
                },
                {
                    name: i18next.t('glossary.objective'),
                    type: 'spline',
                    data: objective,
                    color: this.colors[1],
                    linkedTo: ':previous'
                }
            ]
        }));
    }
});
