module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = ( i18next.t('users.request_reset_password', { name }) )) == null ? '' : __t) +
'\n<br/>\n' +
((__t = ( i18next.t('users.email_sent_with_password', { email }) )) == null ? '' : __t) +
'\n<br/>\n' +
((__t = ( i18next.t('users.click_yes_to_confirm') )) == null ? '' : __t) +
'\n';

}
return __p
}