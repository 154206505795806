WiseMetering.Views.ZoneDashboard = Backbone.Marionette.ItemView.extend({
    className: 'dashboard full-height',
    events: {
        'click .sort-button': 'sortWidgets',
        'click .rebuild-button': 'rebuildDashboard',
        'click .period': 'onChangePeriod',
        'click .utility_kind': 'onChangeUtilityKind'
    },
    info: [],
    period: null,
    template: 'templates/zones/dashboard',
    selectedUtilityKinds: null,
    utility_kinds: [
        { name: 'electricity', unit: 'Wh', show: true },
        { name: 'water', unit: 'm³', show: true },
        { name: 'oil', unit: 'm³', show: true },
        { name: 'gas_energy', unit: 'Wh', show: true },
        { name: 'gas_volume', unit: 'm³', show: false },
        { name: 'cooled_water_energy', unit: 'Wh', show: false },
        { name: 'hot_water_energy', unit: 'Wh', show: false }
    ],
    widgets: {},

    onBeforeRender: function() {
        this.dashboardData = this.model.get('dashboard_data');
        this.period = this.period || WiseMetering.userPreferences.buildingWidgets().period;
        this.buildWidgets();
    },

    onChangePeriod: function(event) {
        this.period = event.currentTarget.getAttribute('value');

        const model = WiseMetering.userPreferences;
        const preferences = model.buildingWidgets();
        preferences.period = this.period;

        model
        .save({ building_widgets: preferences }, { patch: true })
        .done(() => WiseMetering.layout.showTipper('success', 'Successfully saved preferences', 250))
        .fail(() => WiseMetering.layout.showTipper('error', 'Error saving preferences', 250));

        this.closeWidgets();
        this.buildWidgets();
        this.render();
        this.renderWidgets();
        this.bindWidgetResize();
    },

    onChangeUtilityKind: function(event) {
        const selectedUtilityKind = event.currentTarget.getAttribute('value');

        if (selectedUtilityKind === 'all') {
            this.selectedUtilityKinds = this.utility_kinds;
        } else {
            this.selectedUtilityKinds = this.utility_kinds.filter(utility_kind => {
                return WiseMetering.utilityKinds.findWhere({ slug: utility_kind.name }).get('name') === selectedUtilityKind;
            });
        }

        this.closeWidgets();
        this.buildWidgets();
        this.render();
        this.renderWidgets();
        this.bindWidgetResize();
    },

    onClose: function() {
        this.closeWidgets();
        this.unbindWidgetResize();
    },

    onShow: function() {
        this.renderWidgets();
        this.bindWidgetResize();
    },

    bindWidgetResize: function() {
        this.unbindWidgetResize();

        //Add ghost item to adjust widget sizes
        this.$('#widgets .ghost-item').remove();
        this.$('#widgets').append('<div class="ghost-item">');

        //Trigger chart/widget resize
        Lmit.Utils.triggerResize();

        //Listen to window resize
        $(window).on('resize.widgets', Lmit.Utils.resizeWidgets());
    },

    buildWidgets: function() {
        this.widgets = {};

        this.buildCo2Widget();
        this.selectedUtilityKinds = this.selectedUtilityKinds || this.utility_kinds;
        this.selectedUtilityKinds.forEach(function(utility_kind) {
            this.buildTrendChart(utility_kind);

            if (this.selectedUtilityKinds.length > 1 || utility_kind.name === 'electricity') {
                this.buildElectricityWidgets();
            }

            this.buildCostChart(utility_kind);
            this.buildObjectiveChart(utility_kind);
            this.buildOpportunityWidget(utility_kind);
        }.bind(this));
    },

    buildCo2Widget: function() {
        const zoneData = this.model.get('dashboard_data');

        if (!zoneData.co2) return;

        //Build co2 type widget
        const
            title = WiseMetering.AvailableWidgets['co2'],
            data = { value: window.formatValue(zoneData.co2, 'g') + ' CO2' },
            name = 'teste';

        this.widgets['co2'] = this.buildWidget('Co2Displayer', data, title, null, null, name);
    },

    buildElectricityWidgets: function() {
        const zoneData = this.model.get('dashboard_data');

        if (zoneData.electricity){
            this.buildDistributionChart('zones');
            this.buildDistributionChart('circuits');

            if (this.model.isBuilding() && !this.model.isSingleBuilding()) {
                this.buildBenchmarkChart('benchmark');
            }
            if (this.model.isBuilding()) {
                this.buildPowerChart();
            }
        }
    },

    buildWidget: function(func, data, title, unit, type, name) {
        const el = '#widgets',
              period = this.period;

        return new WiseMetering.Views.Ui.Widget[func]({ el, data, title, unit, type, period, name });
    },

    //Build benchmark type chart
    buildBenchmarkChart(name) {
        let defaultKpi = WiseMetering.zone_attributes.findWhere({ default: true }) || null;
        const hasZoneAttributes = WiseMetering.zone_attribute_values.findWhere({
            zone_id: this.model.id,
            zone_attribute_id: defaultKpi
        });

        if (defaultKpi && !hasZoneAttributes) {
            defaultKpi = null;
        }

        const data = WiseMetering.Analytics.benchmarkCurrentFacilityBy(this.model.id, defaultKpi, this.period);

        if (data !== null) {
            this.widgets[name] = new WiseMetering.Views.Ui.Widget.Benchmark({ el: '#widgets', data, self: this.model.get('name'), kpi: defaultKpi });
        }
    },

    // Builds Cost Breakdown - UtilityKind
    // Builds Cost per Period - UtilityKind
    buildCostChart(utility_kind) {
        ['type', 'period'].forEach(function(type) {
            const
                name = `${utility_kind.name}_cost_${type}`,
                title = WiseMetering.AvailableWidgets[name],
                dataType = type === 'type' ? 'partial_costs' : 'partial_active_costs'
                rawData = this.dashboardData?.[utility_kind.name]?.[dataType]?.[this.period];

            if (!rawData) return;

            delete rawData.total;
            delete rawData.total_without_vat;

            let data = Object.entries(rawData)
                .map(([key, value]) => {
                    const name = i18next.t(`widgets.${dataType}.${key}`);

                    return { name: name, value: value };
                });

            data = _.sortBy(data, 'value').reverse();

            if (_.isEmpty(data) || data.length <= 1) return;

            this.widgets[name] = this.buildWidget('Cost', data, title, null, type);
        }.bind(this));
    },

    // Builds Consumption Breakdown by System - Electricity
    // Builds Consumption Breakdown by Zone - Electricity
    buildDistributionChart(type) {
        const
            name = `electricity_${type}`,
            rawData = this.dashboardData?.['electricity']?.[type + '_consumption']?.[this.period],
            title = WiseMetering.AvailableWidgets[name];

        if (!rawData) return;

        const data = WiseMetering.Analytics.distribution(rawData, type);

        if (_.isEmpty(data) || data.length <= 1) return;

        this.widgets[name] = this.buildWidget('Distribution', data, title, 'Wh', type);
    },

    // Build objective type chart
    buildObjectiveChart(utility_kind) {
        if (!utility_kind.show) return;

        ['consumption', 'cost'].forEach(function(type) {
            const kind_slug = utility_kind.name === 'electricity' ? 'active_energy' : utility_kind.name,
                  indicator = WiseMetering.indicators.findWhere({parent_id: this.model.id, kind_slug: kind_slug});

            if (_.isEmpty(indicator)) return;

            const
                name = `${utility_kind.name}_objective_${type}`,
                unit = type === 'cost' ? null : utility_kind.unit,
                title = WiseMetering.AvailableWidgets[name],
                data = WiseMetering.Analytics.getIndicatorObjectiveAnalysis(indicator, unit);

            if (_.isEmpty(data)) return;

            this.widgets[`${utility_kind.name}_objective_${type}`] = this.buildWidget('Objective', data, title, unit);
        }.bind(this));
    },

    //Build opportunities type widget
    buildOpportunityWidget(utility_kind) {
        const
            title = WiseMetering.AvailableWidgets[`${utility_kind.name}_opportunity`],
            data = WiseMetering.Analytics.getOpportunities(utility_kind.name, this.model.id),
            name = i18next.t('widgets.saving_opportunities');

        this.widgets[`${utility_kind.name}_opportunity`] = this.buildWidget('ValueDisplayer', data, title, null, null, name);
    },

    // Builds Power Analysis chart
    buildPowerChart: function() {
        const
            buildingPowerData = this.dashboardData.electricity,
            year =  moment().year(),
            data = {
                contracted_power: buildingPowerData?.['contracted_power']?.[year],
                max_measured_power: buildingPowerData?.['max_measured_power']?.[year]
            },
            title = WiseMetering.AvailableWidgets['requested_power'];

        if (data.max_measured_power && data.contracted_power) {
            this.widgets['requested_power'] = this.buildWidget('Power', data, title, 'W');
        }
    },

    //Build new trend type chart
    buildTrendChart(utility_kind) {
        const
            name = `${utility_kind.name}_trend`,
            title = WiseMetering.AvailableWidgets[name],
            data = WiseMetering.Analytics.getCurrentAndHomologousConsumption(this.model.id, utility_kind.name);

        if (data.current.length > 0) {
            this.widgets[name] = this.buildWidget('Trend', data, title, utility_kind.unit);
        }
    },

    closeWidgets: function() {
        Object.values(this.widgets).forEach(w => w.close());
    },

    rebuildDashboard: function() {
        WiseMetering.layout.showTipper(
            'info',
            i18next.t('dashboard.rebuild_started', { name: this.model.get('name') })
        );

        this.model.rebuildDashboard()
        .done(function() {
            WiseMetering.layout.showTipper(
                'success',
                i18next.t('dashboard.rebuild_success')
            );
        })
        .fail(function() {
            WiseMetering.layout.showTipper(
                'error',
                i18next.t('dashboard.rebuild_error')
            );
        });
    },

    renderWidgets: function() {
        const { widgets, active } = WiseMetering.userPreferences.buildingWidgets();

        widgets.forEach(widget => {
            const tile = this.widgets[widget];
            if (tile && active.includes(widget)) {
                if (tile.chartDisplayer) {
                    tile.render(false);
                    tile.drawChart();
                } else {
                    tile.render();
                }
            }
        });
    },

    serializeData: function() {
        this.info = [];

        if (!this.model.activeEnergy()) {
            this.info.push(i18next.t('dashboard.no_active_energy', { type: this.model.type() }));
        }

        if (!this.model.currentMonth()) {
            this.info.push(i18next.t('dashboard.no_data_current_month'));
        }

        if (this.model.isBuilding() && !this.model.get('tariff_id')) {
            this.info.push(i18next.t('dashboard.no_tariff_assigned', { type: this.model.type() }));
        }

        if (this.model.isBuilding() && !this.model.requestedPower() && WiseMetering.currentOrganization.get('country_code') !== 'GB') {
            this.info.push(i18next.t('dashboard.no_requested_power'));
        }

        return {
            utilityKinds: this.model.availableUtilityKinds(),
            info: this.info,
            isBuilding: this.model.isBuilding(),
            period: this.period,
            selectedUtilityKind: this.selectedUtilityKinds.length === 1 ? this.selectedUtilityKinds.at(0) : 'all',
            widgets: this.widgets
        };
    },

    sortWidgets: function(event) {
        event.preventDefault();
        new WiseMetering.Views.SortWidgets({ model: WiseMetering.userPreferences });
    },

    unbindWidgetResize: function() {
        $(window).off('resize.widgets');
    }
});
