import { Box, ScopedCssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import Content from './Content';

const theme = createTheme();

const Admin = () => {
    return (
        <ThemeProvider theme={theme}>
            <ScopedCssBaseline/>
            <Box sx={{ height: 'calc(100vh - 50px)', width: '100vw' }}>
                <Content/>
            </Box>
        </ThemeProvider>
    );
};

export default Admin;
