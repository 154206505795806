module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'form_content\'>\n    <div class=\'explain\' style=\'border:none;\'></div>\n    <form>\n        <dl class=\'stepped\'>\n            <dt><label for=\'metal_timestamp\'>' +
((__t = ( i18next.t('indicators.timestamp') )) == null ? '' : __t) +
'</label></dt>\n            <dd><input disabled id=\'metal_timestamp\' maxlength=\'10\' type=\'text\' value=\'' +
((__t = ( timestamp )) == null ? '' : __t) +
'\'></dd>\n            <dt><label for=\'metal_value\'>' +
((__t = ( i18next.t('indicators.insert_value', { unit }) )) == null ? '' : __t) +
'</label></dt>\n            <dd><input id=\'metal_value\' type=\'text\' value=\'' +
((__t = ( value )) == null ? '' : __t) +
'\'></dd>\n            <dt><label for=\'metal_error\'>' +
((__t = ( i18next.t('common.error') )) == null ? '' : __t) +
'?</label></dt>\n            <dd><input ' +
((__t = ( error ? 'checked' : null )) == null ? '' : __t) +
' id=\'metal_error\' type=\'checkbox\'></dd>\n        </dl>\n    </form>\n</div>\n';

}
return __p
}