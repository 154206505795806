WiseMetering.Views.IndexEtl = WiseMetering.Views.TableIndex.extend({
    icon: 'etls',
    name: () => i18next.t('glossary.etls'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'etls',
            collection: WiseMetering.etls,

            modelSerializer: function(model) {
                const json = model.toJSON();
                const creator = model.creator();

                json.creator = creator?.get('name') || 'System';
                json.indicators = model.indicators().length;
                json.last_run = model.get('last_run') ? WiseMetering.utils.formatDate(model.get('last_run')) : i18next.t('etls.never_executed');
                json.updated = WiseMetering.utils.formatDate(model.get('updated_at'));
                json.start_on = WiseMetering.utils.formatDate(model.get('start_at'));
                json.rate = model.getHumanEtlRate(json.rate);

                return json;
            },
            columns: {
                active: {
                    mRender: active =>
                        JST['templates/ui/components/circle']({
                            color: WiseMetering.Colors[active ? 'green' : 'red'],
                            title: i18next.t(`glossary.${active ? 'active' : 'inactive'}`)
                        }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                etl_driver: i18next.t('glossary.driver'),
                indicators: i18next.t('glossary.indicators'),
                mode: i18next.t('common.mode'),
                rate: i18next.t('glossary.rate'),
                start_on: i18next.t('common.from'),
                creator: i18next.t('glossary.created_by'),
                updated: i18next.t('glossary.last_change'),
                last_run: i18next.t('glossary.last_run')

            },
            toolbar_actions: {
                new: {
                    view_call: () =>
                        new WiseMetering.Views.NewEtl({
                            collection: WiseMetering.etls,
                            model: new WiseMetering.Model.Etl
                        })
                },
                edit: {
                    view_call: model => {
                        switch (model.get('etl_driver')) {
                            case 'eredes':
                                return new WiseMetering.Views.ERedesEtlEdit({ model });
                            default:
                                return new WiseMetering.Views.EditEtl({ model });
                        }
                    }
                },
                activate: {
                    view_call: model => {
                        if (model.get('active')) {
                            WiseMetering.layout.showTipper('info', 'Etl is already active');
                        } else {
                            new WiseMetering.Views.ActivateEtl({ model, callback: () => this.table.uncheckAll() });
                        }
                    },
                    permission: 'superuser'
                },
                disable: {
                    view_call: model => {
                        if (model.get('active')) {
                            new WiseMetering.Views.DisableEtl({ model, callback: () => this.table.uncheckAll() });
                        } else {
                            WiseMetering.layout.showTipper('info', 'Etl is already disabled');
                        }
                    },
                    icon: 'disable',
                    permission: 'superuser'
                },
                delete: {
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.etls.destroy')
                    })
                },
                show: {
                    view_call: model => new WiseMetering.Views.ShowEtl({ model })
                }
            }
        });
    }
});
