WiseMetering.Views.Utility = WiseMetering.Views.Content.extend({
    initialize({ model, view }) {
        this.icon = model.icon();
        this.tabs = [
            {
                active: view === 'details',
                href: `/utilities/${model.id}/details`,
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.UtilityDetailsTab
            },
            {
                active: view === 'costs',
                id: 'costs',
                href: `/utilities/${model.id}/costs`,
                name: i18next.t('common.costs'),
                view: WiseMetering.Views.UtilityCosts
            }
        ];

        if (WiseMetering.currentUser.get('manager')) {
            this.tabs.push({
                active: view === 'invoices',
                href: `/utilities/${model.id}/invoices`,
                id: 'invoices',
                name: i18next.t('glossary.invoices'),
                view: WiseMetering.Views.IndexInvoice
            });
        }
    }
});
