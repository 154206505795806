module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'basic-widget table-widget grid-' +
((__t = ( widgetPreferences.gridSize )) == null ? '' : __t) +
' hidden-' +
((__t = ( widgetPreferences.hidden )) == null ? '' : __t) +
'\' data-id="' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'" id="wrapper-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'">\n    <div class=\'options-region\' id=\'region-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'\'></div>\n    ';
 if(widgetPreferences.hidden){ ;
__p += '\n        <div class="widget-hidden">\n            <div class="widget-hidden-icon">\n                ' +
((__t = ( WiseMetering.icon('hidden') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    ';
 } ;
__p += '\n    <div class=\'title\'>\n      ' +
((__t = ( title )) == null ? '' : __t) +
'\n      <span class=\'basic-widget-options\' id=\'options-' +
((__t = ( widgetPreferences.id )) == null ? '' : __t) +
'\'>' +
((__t = ( WiseMetering.icon('edit') )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class=\'empty-content\'>\n        <div class=\'empty-symbol\'>\n            ' +
((__t = ( WiseMetering.icon('circle_exclamation') )) == null ? '' : __t) +
'\n        </div>\n        ' +
((__t = ( i18next.t('ui.no_data_available').capitalize() )) == null ? '' : __t) +
'\n        <div class="empty-message">\n            <p>' +
((__t = ( message )) == null ? '' : __t) +
'</p>\n        </div>\n    </div>\n</div>\n\n';

}
return __p
}