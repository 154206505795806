module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n\n    ';
 if (address) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('common.address') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( address )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n\n    ';
 if (postal_code) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('common.postal_code') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( postal_code )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n\n    ';
 if (city) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('common.city') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( city )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n\n    ';
 if (country) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('common.country') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( country )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n\n    ';
 if (vat) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('common.vat_number') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( vat )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n\n    ';
 if (creator) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.created_by') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( creator )) == null ? '' : __t) +
', ' +
((__t = ( createdAt )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n\n    ';
 if (updater) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( updater )) == null ? '' : __t) +
', ' +
((__t = ( updatedAt )) == null ? '' : __t) +
'</dd>\n    ';
 } ;
__p += '\n</dl>\n';

}
return __p
}