module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.email') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( email )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.administrator') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( i18next.t(`common.${administrator ? 'yes' : 'no'}`) )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.manager') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( i18next.t(`common.${manager ? 'yes' : 'no'}`) )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.groups') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( groups || i18next.t('users.no_groups') )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.buildings') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( buildings || i18next.t('users.no_buildings') )) == null ? '' : __t) +
'</dd>\n</dl>\n';

}
return __p
}