WiseMetering.Views.ZoneSummaryInfo = WiseMetering.Views.CardsView.extend({
    cards: function() {
        this.sitePackage = this.model.getSitePackage();
        this.pack = WiseMetering.packages.get(this.sitePackage.get('package_id'))
        const widgets = [];
        widgets.push(this.package());
        widgets.push(this.usedWiseBoxes());
        widgets.push(this.usedDevices());
        if (WiseMetering.currentOrganization.showControlOutputs()) {
            widgets.push(this.usedOutputs());
        }
        if (WiseMetering.currentOrganization.isRetail()) {
            widgets.push(this.usedCurrentSensors());
        }
        widgets.push(this.indicators());

        Object.keys(this.model.get('reference_values')).forEach( utility => {
            const data = this.model.get('reference_values')[utility];
            widgets.push(this.referenceValues(utility, data));
        })
        return widgets;
    },

    deviation: function(value) {
        return value > 100 ? '>100%' : `${value.toFixed(2)}%`;
    },

    indicators: function() {
        const total = WiseMetering.indicators.where({building_id: this.model.id});

        return {
            label: i18next.t('glossary.indicators'),
            value: total.length
        }
    },

    package: function() {
        return {
            label: i18next.t('site_packages.building_package'),
            value: this.pack.get('name')
        }
    },

    referenceValues: function(utility, data) {
        const
            utilityKind = WiseMetering.utilityKinds.get(utility),
            value = Lmit.Utils.formatNumber(data.consumption, utilityKind.unit()),
            cost = Lmit.Utils.formatNumber(data.cost, WiseMetering.getCurrency());

        return {
            label: i18next.t(`utility_kinds.${utilityKind.get('slug')}`),
            subLabel: { value: i18next.t('common.reference_consumption') },
            value: value,
            bottomLabel: `${i18next.t('common.cost')}: ${cost}`
        }
    },

    usedDevices: function() {
        const used = this.sitePackage.getUsed('devices'),
              total = this.pack.get('devices') + this.sitePackage.get('devices_extra_limit');

        return {
            label: i18next.t('glossary.devices'),
            subLabel: { value: i18next.t('common.used_plural') },
            progressBar: {
                value: used.deviation.toFixed(2),
                partial: used.value
            },
            value: used.value,
            bottomLabel: `${i18next.t('common.total').capitalize()}: ${total}`
        }
    },

    usedWiseBoxes: function() {
        const used = this.sitePackage.getUsed('wiseboxes'),
              total = this.pack.get('wiseboxes') + this.sitePackage.get('wiseboxes_extra_limit');

        return {
            label: i18next.t('glossary.wise_boxes'),
            subLabel: { value: i18next.t('common.used_plural') },
            progressBar: {
                value: used.deviation.toFixed(2),
                partial: used.value
            },
            value: used.value,
            bottomLabel: `${i18next.t('common.total').capitalize()}: ${total}`
        }
    },

    usedOutputs: function() {
        const used = this.sitePackage.getUsed('control_outputs'),
              total = this.pack.defaultOutputs();

        return {
            label: i18next.t('glossary.control_outputs'),
            subLabel: { value: i18next.t('common.used_plural') },
            progressBar: {
                value: used.deviation.toFixed(2),
                partial: used.value
            },
            value: used.value,
            bottomLabel: `${i18next.t('common.total').capitalize()}: ${total}`
        }
    },

    usedCurrentSensors: function() {
        const used = this.sitePackage.getUsed('current_sensors'),
              total = this.pack.defaultCurrentSensors();

        return {
            label: i18next.t('glossary.current_sensors'),
            subLabel: { value: i18next.t('common.used_plural') },
            progressBar: {
                value: used.deviation.toFixed(2),
                partial: used.value
            },
            value: used.value,
            bottomLabel: `${i18next.t('common.total').capitalize()}: ${total}`
        }
    }
});
