WiseMetering.Views.Zone = WiseMetering.Views.Content.extend({
    initialize({ model, view }) {
        this.icon = model.icon();
        this.tabs = [];

        this.tabs.push({
            active: view === 'details',
            href: `/${this.objectName()}/${model.id}/details`,
            id: 'details',
            name: i18next.t('common.details'),
            view: WiseMetering.Views.ZoneDetailsTab
        });

        if (model.isBuilding() && model.get('state') === 'active') {
            this.tabs.push({
                active: view === 'dashboard',
                href: `/zones/${model.id}/dashboard`,
                id: 'dashboard',
                name: i18next.t('common.dashboard'),
                view: WiseMetering.Views.ZoneDashboard
            });
        }

        if (model.type() === 'building') {
            this.tabs.push({
                active: view === 'opportunities',
                href: `/${this.objectName()}/${model.id}/opportunities`,
                id: 'opportunities',
                name: i18next.t('glossary.opportunities'),
                view: WiseMetering.Views.IndexOpportunity
            });
        }

        if (model.type() === 'building') {
            let objectivesView = WiseMetering.currentOrganization.is('essentials') ? 'NoAccess' : 'Objectives';
            this.tabs.push({
                active: view === 'objectives',
                href: `/${this.objectName()}/${model.id}/objectives`,
                id: 'objectives',
                name: i18next.t('glossary.objectives'),
                view: WiseMetering.Views[objectivesView]
            });
        }

        if (model.isBuilding()) {
            this.tabs.push({
                active: view === 'reports',
                href: `/${this.objectName()}/${model.id}/reports`,
                id: 'reports',
                name: i18next.t('glossary.reports'),
                view: WiseMetering.Views.IndexReport
            });
        }

        if (model.isBuilding()) {
            this.tabs.push({
                active: view === 'indicators',
                href: `/${this.objectName()}/${model.id}/indicators`,
                id: 'indicators',
                name: i18next.t('glossary.indicators'),
                view: WiseMetering.Views.IndexIndicatorByBuilding
            });
        }

        if (model.isBuilding() && model.isActivated()) {
            this.tabs.push({
                active: view === 'scheduled_reports',
                href: `/${this.objectName()}/${model.id}/scheduled_reports`,
                id: 'scheduled-reports',
                name: i18next.t('glossary.scheduled_reports'),
                view: WiseMetering.Views.ScheduledReportsIndex,
                dropdownTab: true
            });
        }

        if (model.type() === 'building' && WiseMetering.currentUser.isSuperUser()) {
            this.tabs.push({
                active: view === 'permissions',
                href: `/${this.objectName()}/${model.id}/permissions`,
                id: 'permissions',
                name: i18next.t('common.permissions'),
                view: WiseMetering.Views.ZonePermissions,
                dropdownTab: true
            });
        }
    },

    objectName: function() {
        return this.model.get('state') !== 'active' && this.model.isBuilding() ? 'buildings' : 'zones';
    }
});
