WiseMetering.Model.Company = WiseMetering.Model.extend({
    urlRoot: '/companies',

    getDate: function (dateKey) {
      if (typeof this.get(dateKey) === 'number') {
        return WiseMetering.utils.formatDate(this.get(dateKey), this.timezone(), 'YYYY-MM-DD');
      }

      return this.get(dateKey) || '';
    },

    getDateTime: function (dateKey) {
      if (typeof this.get(dateKey) === 'number') {
        return WiseMetering.utils.formatDate(this.get(dateKey), this.timezone(), 'YYYY-MM-DD HH:mm');
      }

      return this.get(dateKey) || '';
    },

    icon: function() {
        return 'companies';
    },

    timezone: function () {
        return WiseMetering.currentOrganization.get('timezone');
    }
});
