WiseMetering.Collection.Devices = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Device,
    url: '/devices',

    wiseBoxes: function(building_id) {
        let conditions = { wise_box: true };
        if (building_id) {
            conditions.building_id = building_id;
        }

        return new WiseMetering.Collection.Devices(this.where(conditions));
    }
});
