module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span>\n    <i class="' +
((__t = (WiseMetering.icons.exclamationCircle)) == null ? '' : __t) +
'" aria-hidden=\'true\'></i>\n    ' +
((__t = ( i18next.t('tiers.no_access') )) == null ? '' : __t) +
'\n</span>\n';

}
return __p
}