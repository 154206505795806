WiseMetering.Views.IndexFoldersByBuilding = WiseMetering.Views.TableIndex.extend({
    icon: 'folders',
    name: () => i18next.t('glossary.folders'),

    createTable: function createTable() {
        const collection = this.model.folders(),
            type = this.model.type() === 'folder' ? 'folder_id' : 'zone_id';

        let table = new WiseMetering.Views.Table({
            id: 'folders',
            collection: collection,
            columns: {
                'name': i18next.t('common.name')
            },
            toolbar_actions: {
                new: {
                    view_call: () => {
                        new WiseMetering.Views.FolderManage({
                            collection: WiseMetering.folders,
                            model: new WiseMetering.Model.Folder({
                                [type]: this.model.type() === 'root-object' ? this.model.building().id : this.model.id
                            })
                        });
                    }
                },
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.folders.destroy')
                    })}
            }
        });

        this.listenTo(this.model, 'change:children:addFolder', function(node) {
            collection.add(node);
        });

        this.listenTo(this.model, 'change:children:removeFolder', function(node) {
            collection.remove(node);
        });

        return table;
    }
});
