module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'form_content\'>\n    <div class=\'err\'></div>\n    <form>\n        <dl class=\'stepped\'>\n            <b>\n                ' +
((__t = ( i18next.t('indicators.attention') )) == null ? '' : __t) +
'\n                <br>\n                ' +
((__t = ( i18next.t('indicators.enter_values_or_upload') )) == null ? '' : __t) +
'\n            </b>\n            <br>\n            <br>\n            <dt>\n                <label for=\'metal_timestamp\'>' +
((__t = ( i18next.t('indicators.timestamp') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input id=\'metal_timestamp\' maxlength=\'10\' type=\'text\'>\n            </dd>\n            <dt>\n                <label for=\'metal_value\'>' +
((__t = ( i18next.t('indicators.insert_value', { unit }) )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input id=\'metal_value\' type=\'text\'>\n            </dd>\n            <dt>\n                <label for=\'\'>' +
((__t = ( i18next.t('common.upload') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input id=\'metal_xls\' name=\'metal_file\' type=\'file\' accept=\'.xls\'>\n                <a href=\'' +
((__t = ( WiseMetering.apiUrl )) == null ? '' : __t) +
'/static/example.xls\'>' +
((__t = ( i18next.t('indicators.download_example_file') )) == null ? '' : __t) +
'</a>\n                <br/>\n                <div style=\'background: #ddd; margin-top: 5px; padding: 10px;\'>\n                    ' +
((__t = ( i18next.t('indicators.note', { timezone }) )) == null ? '' : __t) +
'\n                    <br>\n                    ' +
((__t = ( i18next.t('indicators.example_note') )) == null ? '' : __t) +
'\n                </div>\n            </dd>\n        </dl>\n    </form>\n</div>\n';

}
return __p
}