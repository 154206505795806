WiseMetering.Views.Ui.Widget.Cost = WiseMetering.Views.Ui.Widget.Base.extend({
    colors: WiseMetering.WidgetColors.MultiColor,
    templateID: 'templates/ui/widgets/cost',
    tooltip_template: _.template('<span style="font-weight:bold; color: <%= color %>"><%= name %></span> <%= value %> <b>(<%= percentage %>%)</b>'),
    legend_template: _.template('<strong><%= name %></strong><br/><%= value %>'),

    initialize: function(params) {
        WiseMetering.Views.Ui.Widget.Base.prototype.initialize.call(this, params);
    },

    drawChart: function() {
        var that = this;

        var data = _.map(that.data, function(cost, index) {
            return {
                name: cost.name,
                y: cost.value,
                color: that.colors[index]
            };
        });

        let
            total = data.reduce((acc, value) => acc + value.y, 0),
            currency = WiseMetering.currentOrganization.get('currency'),
            formattedValue = total ? `${currency} ${formatValue(total, this.options.unit)}` : '';

        that.chart = new Highcharts.Chart(jQuery.extend(true, that.defaultChartOptions, {
            exporting: {
                allowHTML: true,
                chartOptions: {
                    title: { text: that.title },
                    legend: { enabled: false }
                }
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        connectorWidth: 0,
                        formatter() {
                            if (this.percentage <= 0) return;
                            const position = this.point.dataLabel?.dataLabelPosition?.alignment || 'left';
                            return `
                                <div style="text-align: ${position}">
                                    ${position === 'left' ? `<span style="display: inline-block; width: 6px; height: 6px; margin: 5px; background: ${this.point.color};"></span>` : ``}
                                    <div style="display: inline-block;">
                                        <span>${this.point.name}</span><br>
                                        <span>${formatValue(this.y, WiseMetering.currentOrganization.get('currency'))} (${formatValue(this.percentage)}%)</span>
                                    </div>
                                    ${position === 'right' ? `<span style="display: inline-block; width: 6px; height: 6px; margin: 5px; background: ${this.point.color};"></span>` : ``}
                                </div>
                            `;
                        }
                    },
                    innerSize: '75%',
                    size: '75%'
                }
            },
            series: [{ type: 'pie', name: null, data: data }],
            title: {
                text: formattedValue,
                align: 'center',
                verticalAlign: 'middle',
                y: 15,
                style: {
                    fontWeight: 'bold'
                }
            },
            tooltip: { formatter: that.tooltip_formatter(that) }
        }));
    },

    tooltip_formatter: function(ctx) {
        var that = ctx;
        return function() {
            return that.tooltip_template({
                color: this.point.color.replace(that.opacity, 1),
                name: this.point.name,
                value: formatValue(this.y, WiseMetering.getCurrency()),
                percentage: this.percentage.toFixed(2)
            });
        };
    }
});
