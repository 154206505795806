WiseMetering.Views.RootObject = WiseMetering.Views.Content.extend({
    initialize: function() {
        this.icon = this.model.icon();
        this.tabs = [
            {
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views[this.noAccessView() || this.mainView()]
            }
        ];

        if (!this.noAccessView() && this.model.get('slug') === 'tenants') {
            this.tabs.push({
                id: 'invoices',
                name: i18next.t('glossary.proforma_invoices'),
                view: WiseMetering.Views.IndexTenantInvoices
            });
            this.tabs.push({
                id: 'history',
                name: i18next.t('common.history'),
                view: WiseMetering.Views.IndexInactiveTenantsByBuilding
            });
        }
    },

    mainView: function() {
        return `Index${this.model.get('slug').capitalize()}ByBuilding`;
    },

    noAccessView: function() {
        const hasAdvancedPermissions = WiseMetering.currentOrganization.hasAdvancedPermissions();
        if (!hasAdvancedPermissions && ['tenants', 'seus'].includes(this.model.get('slug'))) {
            return 'NoAccess';
        }
    }
});
