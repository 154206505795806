WiseMetering.Model.Package = WiseMetering.Model.extend({
    defaultOutputs: function() {
        return this.defaultMeasurements();
    },

    defaultCurrentSensors: function() {
        return this.defaultMeasurements();
    },

    defaultMeasurements: function() {
        return (this.get('type') === 'retail' && this.get('name') === 'basic') ? 8 : 16;
    }
});
