WiseMetering.Views.Ui.Widget.Power = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/power',

    initialize: function(params) {
        WiseMetering.Views.Ui.Widget.Base.prototype.initialize.call(this, params);
    },

    drawChart: function() {
        const
            colors = [WiseMetering.Colors.red, WiseMetering.Colors.grey],
            { unit } = this,
            months = i18next.t('highcharts.shortMonths', { returnObjects: true }),
            now = moment(),
            currentYear = now.year();

        const requested = [], real = [];

        for (let i = 0; i < 12; i++) {
            requested.push(this.data.contracted_power?.[i]);
            real.push(this.data.max_measured_power?.[i]);
        }

        this.chart = new Highcharts.Chart(jQuery.extend(true, {}, this.defaultChartOptions, {
            xAxis: {
                categories: i18next.t('highcharts.shortMonths', { returnObjects: true }),
                lineColor: '#797979'
            },
            yAxis: {
                labels: {
                    formatter: point => formatValue(point.value, unit)
                }
            },
            tooltip: {
                formatter: function() {
                    const month = months.indexOf(this.x);

                    let realValue, requestedValue, difference, year;

                    realValue = real[month];
                    requestedValue = requested[month];
                    year = currentYear;

                    difference = realValue - requestedValue;

                    let percentage = null;
                    if (requestedValue && realValue) {
                        percentage = formatValue(requestedValue ? difference / requestedValue * 100 : 0, '%');
                    }

                    return JST['templates/ui/widgets/power_tooltip']({
                        colors,
                        month: this.x,
                        year,
                        requestedValue: formatValue(requestedValue, unit),
                        realValue: formatValue(realValue, unit),
                        positive: difference > 0,
                        percentage: percentage
                    });
                }
            },
            series: [
                {
                    name: currentYear,
                    type: 'column',
                    data: real,
                    color: colors[1],
                    linkedTo: ':previous'
                },
                {
                    name: i18next.t('glossary.objective'),
                    type: 'spline',
                    data: requested,
                    color: colors[0],
                    linkedTo: ':previous'
                }
            ],
            exporting: {
                allowHTML: true,
                chartOptions: {
                    title: {
                        text: this.title
                    }
                }
            }
        }));
    }
});
