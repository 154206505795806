WiseMetering.Views.Ui.Widget.Base = Backbone.View.extend({
    chart: null,
    chartDisplayer: true,
    colors: WiseMetering.WidgetColors.Default,
    data: null,
    defaultChartOptions: null,
    draw: true,
    el: null,
    editMode: false,
    highlightArea: null,
    id: null,
    legendData: null,
    opacity: 0.7,
    templateID: null,
    type: null,
    useNormalization: false,
    value: null,
    widgetPreferences: null,

    initialize: function(params) {
        this.data = params.data;
        $.extend(this, params);
        this.id = generateUUID();
        this.setDefaultChartOptions();

        if (this.widgetPreferences) {
            this.$el.on(`click`, `#options-${this.widgetPreferences.id}`, this.widgetOptions.bind(this));
        }
    },

    render: function(drawChart = true) {
        this.$el = $(JST[this.templateID]({
            colors: this.colors,
            data: this.data,
            global: this.options.global,
            highlightArea: this.options.highlightArea,
            widgetPreferences: this.widgetPreferences,
            id: this.id,
            name: this.options.name,
            subtitle: this.options.subtitle,
            title: this.options.title,
            useNormalization: this.options.useNormalization,
            value: this.value,
        }));

        this.editMode ? $(`#wrapper-${this.options.widgetPreferences.id}`).replaceWith(this.$el) : $(this.el).append(this.$el);
        this.editMode = false;

        if (this.chartDisplayer) {
            if (drawChart) {
                this.drawChart();
            }
            this.drawLegend();
            this.showLegend();
        }
    },

    addLoader: function() {
        this.$('.content').addClass('loader').html('Loading data...');
    },

    cancelEdit: function() {
        this.editMode = true;

        this.render();
    },

    close: function() {
        if (this.chart && this.chart.destroy && typeof (this.chart.destroy) === 'function') {
            this.chart.destroy();
        }

        if (this.widgetPreferences) {
            this.$el.on(`click`, `#options-${this.widgetPreferences.id}`, this.widgetOptions.bind(this));
        }
        this.$el.remove();
    },

    drawChart: function() {
    },

    drawLegend: function() {
    },

    fadeOut() {
        this.$el.fadeOut();
    },

    fadeIn() {
        this.$el.fadeIn();
    },

    hideChart() {
        this.$el.hide();
    },

    redraw: function() {
        if (this.draw && this.response) {
            this.$('#' + this.id).empty();
            $.extend(true, this.defaultChartOptions, { plotOptions: { series: { animation: false } } });
            this.drawChart();
            this.showLegend();
            this.draw = false;
        }
    },

    setDefaultChartOptions: function() {
        this.defaultChartOptions = {
            colors: this.colors,
            chart: {
                renderTo: this.id,
                backgroundColor: false,
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false
            },
            title: { text: null },
            subtitle: { text: null },
            credits: { enabled: false },
            legend: { enabled: false },
            tooltip: { enabled: true },
            xAxis: {
                title: { text: null },
                tickWidth: 0,
                labels: {
                    enabled: true,
                    color: '#666666',
                    style: { fontWeight: 'bold', fontSize: '9px' }
                }
            },
            yAxis: {
                title: { text: null },
                gridLineColor: '#CCCCCC',
                labels: {
                    y: 2,
                    x: -3,
                    style: { fontSize: '8px' }
                },
                min: 0
            },
            plotOptions: {
                area: {
                    lineWidth: 0,
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: true,
                                lineWidth: 0,
                                radius: 3
                            }
                        }
                    },
                    shadow: false,
                    stacking: 'normal',
                    fillOpacity: this.opacity
                },
                spline: {
                    lineWidth: 2,
                    marker: {
                        enabled: true,
                        radius: 3
                    },
                    dataLabels: {
                        cursor: 'pointer'
                    }
                },
                column: {
                    dataLabels: {
                        cursor: 'pointer',
                        enabled: false,
                        color: '#666666',
                        style: { fontSize: '8px' },
                        formatter: function() {
                            return formatValue(this.y, WiseMetering.getCurrency());
                        }
                    },
                    pointPadding: 0.05,
                    groupPadding: 0.05,
                    borderRadius: 0,
                    borderWidth: 0,
                    shadow: 0
                },
                pie: {
                    borderRadius: 0,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        cursor: 'pointer',
                        style: { fontWeight: 'bold', fontSize: '9px' },
                        color: '#666666',
                        distance: 10,
                        softConnector: false
                    },
                    shadow: false,
                    showInLegend: true
                }
            },
            exporting: {
                enabled: true,
                buttons: {
                    printButton: { enabled: false }
                },
                chartOptions: {
                    chart: {
                        backgroundColor: '#FFFFFF'
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle',
                        borderWidth: 0,
                        itemMarginBottom: 10,
                        itemStyle: { fontSize: '9px' }
                    }, // !legend
                    plotOptions: {
                        pie: {
                            showInLegend: true,
                            softConnector: false
                        } // !pie
                    } // !plotOptions
                } // !chartOptions
            } // !exporting
        };
    },

    showChart() {
        this.$el.show();
    },

    showLegend: function() {
        const
            content = this.$('.content'),
            legend = this.$('.legend');

        legend.css('top', (content.height() - legend.height()) / 2);
        legend.show();
    },

    updatePreferences: function(newPreferences) {
        this.editMode = true;
        this.widgetPreferences = newPreferences;

        this.render();
    },

    widgetOptions: function() {
        this.optionsView = new WiseMetering.Views.Ui.WidgetOptions({
            el: `#region-${this.widgetPreferences.id}`,
            preferences: this.widgetPreferences,
            title: this.options.title,
        });

        this.listenTo(this.optionsView, 'save:preferences', this.updatePreferences);
        this.listenTo(this.optionsView, 'cancel:preferences', this.cancelEdit);
        this.optionsView.render();
    }
});
