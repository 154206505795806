WiseMetering.Views.IndexAllocationsHistory = WiseMetering.Views.TableIndex.extend({
    icon: 'allocations',
    name: () => i18next.t('common.history'),

    createTable: function createTable() {
        this.collection = WiseMetering.allocations.inactiveAllocations(this.model.id);

        return new WiseMetering.Views.Table({
            id: 'allocations',
            collection: this.collection,
            modelSerializer: function(model) {
                const json = model.toJSON(),
                    timezone = model.timezone();

                json.from = WiseMetering.utils.formatDate(json.from, timezone, 'YYYY/MM/DD');
                json.to = WiseMetering.utils.formatDate(json.to, timezone, 'YYYY/MM/DD');
                return json;
            },
            columns: {
                name: i18next.t('common.name'),
                indicator_id: {
                    title: i18next.t('glossary.indicator'),
                    mRender: function(indicator_id) {
                        const indicator = WiseMetering.indicators.get(indicator_id);
                        return indicator.get('name');
                    }
                },
                tariff_id: {
                    title: i18next.t('glossary.tariff'),
                    mRender: function(tariff_id) {
                        const tariff = WiseMetering.tariffs.get(tariff_id);
                        return tariff.get('name');
                    }
                },
                fraction: i18next.t('glossary.fraction'),
                from: i18next.t('common.from'),
                to: i18next.t('common.to')
            },
            sort: {
                column: 'name',
                order: 'asc'
            },
            toolbar_actions: {
                edit: {
                    view_call: model => {
                        model.fetch({
                            success: model => new WiseMetering.Views.EditAllocation({
                                active: false,
                                collection: this.collection,
                                indicators: new WiseMetering.Collection.Indicators,
                                model: model,
                                oppositeCollection: this.options.oppositeCollection,
                                tariffs: new WiseMetering.Collection.Tariffs,
                                tenant: this.model
                            })
                        });
                    }
                },
                show: {
                    view_call: model => {
                        model.fetch({
                            success: function() {
                                new WiseMetering.Views.ShowAllocation({ model });
                            }
                        });
                    }
                }
            }
        });
    }
});
