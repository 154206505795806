WiseMetering.Views.IndexReading = WiseMetering.Views.TableIndex.extend({
    icon: 'readings',
    name: () => i18next.t('glossary.readings'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'readings',
            collection: WiseMetering.indicators.raw(),
            modelSerializer: function(model) {
                const json = model.toJSON();
                json.building = model.building().get('name');
                return json;
            },
            columns: {
                name: i18next.t('common.name'),
                building: i18next.t('glossary.building'),
                device_name: i18next.t('glossary.device')
            },
            toolbar_actions: {
                show: {
                    view_call: model => {
                        const indicator = WiseMetering.indicators.get(model.id);
                        Backbone.history.navigate(indicator.nodeUrl(), { trigger: true });
                    }
                }
            },
            disableSelect: true
        });
    }
});
