WiseMetering.Views.EditZoneAttribute = WiseMetering.Views.Modal.Save.extend({
    template: "templates/zone_attributes/edit",
    className: "mbx-info text-left",
    form_prefix: "zone_attribute",
    title: "Edit Zone Attribute",

    ui: {
        enumerator_setup: ".enumerator_setup",
        new_enumerator_member: ".new_member",
        enumerator_list: ".enumerator_list"
    },

    events: {
        "click a.new_enumerator_member": "newEnumeratorMemberShow",
        "keyup .new_member": "addMember",
        "click a.mbx_btn_close": "removeMember"
    },

    serializeData: function() {
        var json = this.model.toJSON();
        // Because default is a reserved word, it won't work otherwise
        json.isDefault = json.default;
        return json;
    },

    onRender: function() {
        this.ui.enumerator_list.sortable();
    },

    addMember: function(event) {
        if ((event.keyCode || event.which) === 13) {
            var value = $(event.currentTarget).val();
            // TODO: Refactor this piece of shit
            var enumeratorMember = "";
            enumeratorMember += '<li class="member" style="float:none;padding:2px 2px 2px 5px;background:rgba(225,230,236,0.5);margin-bottom:1px;border:1px solid #ccc;">';
            enumeratorMember += '<span style="z-index:1;">' + value + '</span>';
            enumeratorMember += '<a style="position:relative;top:2px;right:2px;" href="#" class="mbx_btn_close"></a>';
            enumeratorMember += '<input type="hidden" name="zone_attribute[enumerator][]" value="' + value + '" />';
            enumeratorMember += '</li>';
            this.ui.enumerator_list.append(enumeratorMember);
            this.ui.new_enumerator_member.val(null);
            this.ui.new_enumerator_member.hide();
        }
    },

    removeMember: function(event) {
        $(event.currentTarget).parent("li:first").remove();
    },

    newEnumeratorMemberShow: function() {
        this.ui.new_enumerator_member.show();
        this.ui.new_enumerator_member.focus();
        this.modal.redraw();
        return false;
    }

});
