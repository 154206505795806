module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form_content">\n    <form style="display:flex;">\n        <dl class=\'stepped zero\' style="width:50%;">\n            <div>\n                <dt>\n                    <label for="event_name">' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <input class="text" id="event_name" name="event[name]" size="30" type="text" style="width:300px"/>\n                </dd>\n\n                <dt>\n                    <label for="event-description">' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <textarea class="text" id="event-description" name="event[description]" cols="40" rows="20" style="width:300px"></textarea>\n                </dd>\n\n                <dt class="help" id="what_happened">\n                    <label for="event-event-type-id">' +
((__t = ( i18next.t('markers.what_happened') )) == null ? '' : __t) +
'?</label>\n                </dt>\n                <dd class="marker_type">\n                    <select class="text" id="event-event-type-id" name="event[marker_type_id]">\n                        ';
 WiseMetering.markerTypes.forEach(function (markerType){ ;
__p += '\n                            <option value="' +
((__t = ( markerType.id )) == null ? '' : __t) +
'">' +
((__t = ( markerType.get('name') )) == null ? '' : __t) +
'</option>\n                        ';
 }) ;
__p += '\n                    </select>\n                </dd>\n\n                <div id="event-has-length-form">\n                    <dt>\n                        <label for="event-has-length">' +
((__t = ( i18next.t('markers.marker_length') )) == null ? '' : __t) +
'</label>\n                    </dt>\n                    <dd id="event-has-length">\n                        <input type="radio" class="has-no-length-checkbox" checked\n                               name="event-has-length" value="0" style="vertical-align:initial">\n                        <span style="margin-right:5px">' +
((__t = ( i18next.t('common.no') )) == null ? '' : __t) +
'</span>\n                        </input>\n                        <input type="radio" class="has-length-checkbox"\n                               name="event-has-length" value="1" style="vertical-align:initial">\n                        <span style="margin-right:5px">' +
((__t = ( i18next.t('common.yes') )) == null ? '' : __t) +
'</span>\n                        </input>\n                    </dd>\n                </div>\n\n                <div id="event-test-length-form" style="display:none">\n                    <dt>\n                        <label for="event-test-length-options">' +
((__t = ( i18next.t('markers.marker_period') )) == null ? '' : __t) +
'</label>\n                    </dt>\n                    <dd id="event-test-length-options">\n                        <input id="one-day" type="radio" name="event-test-length" value="86400" style="vertical-align:initial">\n                        <span style="margin-right:5px">1 ' +
((__t = ( i18next.t('common.day') )) == null ? '' : __t) +
'</span>\n                        </input>\n                        <input id="one-week" type="radio" name="event-test-length" value="604800" style="vertical-align:initial">\n                        <span style="margin-right:5px">1 ' +
((__t = ( i18next.t('common.week') )) == null ? '' : __t) +
'</span>\n                        </input>\n                        <input id="one-month" type="radio" name="event-test-length" value="2592000" style="vertical-align:initial">\n                        <span style="margin-right:5px">1 ' +
((__t = ( i18next.t('common.month') )) == null ? '' : __t) +
'</span>\n                        </input>\n                    </dd>\n                </div>\n\n                <div id="event-date-and-time">\n                    <dt class="help" id="when_did_it_happen">\n                        <label for="event-marker_at">' +
((__t = ( i18next.t('markers.when_did_it_happened') )) == null ? '' : __t) +
'?</label>\n                    </dt>\n                    <dd>\n                        <ul class="ctrl inline dp" style="padding:0">\n                            <li class="field border">\n                                <input class="text" id="event-at-date" maxlength="10" name="event[marker_at]" readonly type="text" value="' +
((__t = ( moment().format('YYYY-MM-DD HH:00') )) == null ? '' : __t) +
'">\n                            </li>\n                        </ul>\n                    </dd>\n                </div>\n\n                <div id="event-with-length" style="display:none">\n                    <dt class="help">\n                        <label for="event-reference_period_start">' +
((__t = ( i18next.t('markers.measure_test_impact') )) == null ? '' : __t) +
'?</label>\n                    </dt>\n                    <dd>\n                        <div style="display:flex">\n                            <select class="text" style="width:20% !important" id="event-has-measurable-impact">\n                                <option value="0">' +
((__t = ( i18next.t('common.no') )) == null ? '' : __t) +
'</option>\n                                <option value="1">' +
((__t = ( i18next.t('common.yes') )) == null ? '' : __t) +
'</option>\n                            </select>\n                            <div class="reference-period" style="display:none;margin-left:5px;width: -webkit-fill-available">\n                                <select class="text" id="event-reference-period"></select>\n                            </div>\n                        </div>\n                        <div class="custom-reference-period" style="display:none;margin-top: 5px;">\n                            <ul class="ctrl inline dp" style="padding:0;">\n                                <li class="field border" style="width:100%">\n                                    <input class="text" id="event-custom-reference-period-start" maxlength="10" readonly type="text" value="' +
((__t = ( moment().format('YYYY-MM-DD') )) == null ? '' : __t) +
'">\n                                </li>\n                            </ul>\n                        </div>\n                    </dd>\n                </div>\n\n                <input id="event-duration" name="event[duration]" type="hidden" value=""/>\n                <input id="event-reference-period-start-value" name="event[reference_period_start]" type="hidden"/>\n            </div>\n\n            <div class=\'impact-analysis-box\' style="display:none;">\n                <dt>\n                    <label>' +
((__t = ( i18next.t('markers.impact_measure') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd class="results">\n                    <div>\n                        <i class="fas fa-spinner fa-spin" style="display:none"></i>\n                        <span id="calculated-results"></span>\n                    </div>\n                </dd>\n            </div>\n        </dl>\n\n        <div style=\'margin-left:10px;width:50%;\'>\n            <div class=\'event-description\'>\n                <dt>\n                    <label for=\'description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    ';
 const description = i18next.t('markers.description', { returnObjects: true }) ;
__p += '\n                    <div id="event-description">\n                        ' +
((__t = ( description[0] )) == null ? '' : __t) +
'\n                        <ul style=\'list-style-type:disc;margin-left:25px\'>\n                            <li>' +
((__t = ( description[1] )) == null ? '' : __t) +
'</li>\n                            <li>' +
((__t = ( description[2] )) == null ? '' : __t) +
'</li>\n                            <li>' +
((__t = ( description[3] )) == null ? '' : __t) +
'</li>\n                        </ul>\n                        ' +
((__t = ( description[4] )) == null ? '' : __t) +
'\n                    </div>\n                </dd>\n            </div>\n        </div>\n    </form>\n</div>\n';

}
return __p
}