module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="building-info-window" onclick="goToBuilding(\'' +
((__t = ( id )) == null ? '' : __t) +
'\')">\n    <div class="popup-title">\n        ' +
((__t = ( name )) == null ? '' : __t) +
'\n        <div class="street">' +
((__t = ( street )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="content-container">\n        ';
 if (imageUrl) { ;
__p += '\n            <div class="image">\n                <img src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'"/>\n            </div>\n        ';
 } ;
__p += '\n        ';
 if (data.length) { ;
__p += '\n            <div class="popup-content" style="max-height: ' +
((__t = ( contentHeight )) == null ? '' : __t) +
'">\n                <div class="summary">\n                    ';
 data.forEach(function(utility_kind) { ;
__p += '\n                        <div class="line">\n                            <strong>' +
((__t = ( utility_kind.name )) == null ? '' : __t) +
'</strong>\n                            <span style="color:' +
((__t = ( utility_kind.color )) == null ? '' : __t) +
'">' +
((__t = ( utility_kind.signal )) == null ? '' : __t) +
'' +
((__t = ( utility_kind.variance )) == null ? '' : __t) +
'</span>\n                        </div>\n                        <div class="line">\n                            <span>' +
((__t = ( period.capitalize() )) == null ? '' : __t) +
'</span>\n                            <span>' +
((__t = ( utility_kind.current )) == null ? '' : __t) +
'</span>\n                        </div>\n                        <div class="line last-year">\n                            <span>' +
((__t = ( i18next.t('common.last').capitalize() )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t(`common.${period === 'ytd' ? 'year' : 'month'}`) )) == null ? '' : __t) +
'</span>\n                            <span>' +
((__t = ( utility_kind.last )) == null ? '' : __t) +
'</span>\n                        </div>\n                    ';
 }); ;
__p += '\n                </div>\n            </div>\n        ';
 } else { ;
__p += '\n            <div class="no-content">\n                ' +
((__t = ( i18next.t('ui.no_data_available') )) == null ? '' : __t) +
'\n            </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';

}
return __p
}