import { useEffect, useState } from 'react';
import { LoginCard } from '@/components/layout/login-card';
import { AlertBox, PasswordRequirements, Form, FormLabel, Button, PasswordInput, StandaloneLink, Icon } from '@/components/wm';
import { apiRequest } from '@/utils/request';
import * as React from 'react';

export function ResetPassword({ onAlert, code, backToLogin }) {
    const
        [loading, setLoading] = useState(false),
        [password, setPassword] = useState(''),
        [passwordConfirmation, setPasswordConfirmation] = useState(''),
        [canSubmit, setCanSubmit] = useState(false);

    const handlePasswordChange = (newPwd, confirmPwd) => {
        setPassword(newPwd);
        setPasswordConfirmation(confirmPwd);
    };

    const handleRequirementsMet = (isMet) => {
        setCanSubmit(isMet);
    };

    const onHandleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            password,
            password_confirmation: passwordConfirmation
        };

        try {
            const response = await apiRequest(`/reset_password/${code}`, 'PUT', data);

            if (response.status === 200) {
                onAlert('Successfully changed password', 'success', 'Success');
                backToLogin();
            }
        } catch(err) {
            const errorMessage = err.response?.data?.error?.capitalize() || 'An error occurred';

            onAlert(errorMessage, 'destructive', 'Failed')
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
            <div className="tw-grid tw-grid-cols-1">
                <LoginCard>
                    <Form>
                        <FormLabel htmlFor="email">insert a new password</FormLabel>
                        <PasswordInput
                            id="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormLabel htmlFor="email">confirm the new password</FormLabel>
                        <PasswordInput
                            id="password_confirmation"
                            required
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                        />
                        <Button type="submit" loading={loading} className="tw-w-full" variant="secondary" onClick={onHandleSubmit} disabled={!canSubmit}>
                            Submit
                        </Button>
                    </Form>
                </LoginCard>
                <PasswordRequirements
                    newPassword={password}
                    confirmPassword={passwordConfirmation}
                    onPasswordChange={handlePasswordChange}
                    onRequirementsMet={handleRequirementsMet}
                />
                <StandaloneLink onAction={backToLogin} icon="arrowLeftCircle" text="Back to Login" />
            </div>
    );
}
