WiseMetering.Views.Map = Backbone.Marionette.Layout.extend({
    activeMarker: null,
    bounds: null,
    map: null,
    mapType: 'ROADMAP',
    markers: [],
    maxZoom: 16,
    minZoom: 2,
    template: 'templates/ui/map/index',
    regions: {
        map: '#map-placeholder',
        search: '#map-search'
    },

    closeMarker: function(marker) {
        const icon = WiseMetering.mapIcons.building.default;
        this.enablePanningAndScrolling();
        marker.setIcon(icon);
        marker.infowindow.close();
        marker.building.trigger('node:close');
        this.activeMarker = null;
    },

    createControl: function() {
        const element = document.createElement('div');
        element.index = 1;
        element.innerHTML = JST['templates/ui/components/map_center']();
        return element;
    },

    disablePanningAndScrolling: function() {
        $(this.control).hide();
        this.map.setOptions({
            zoomControl: false,
            gestureHandling: 'none'
        });
    },

    drawMarker: function(building) {
        const lat = building.get('address').latitude, lng = building.get('address').longitude;
        const icon = WiseMetering.mapIcons.building.default;
        if (!lat || !lng) {
            return;
        }

        let marker = new google.maps.Marker({
            icon: icon,
            map: this.map,
            position: new google.maps.LatLng(lat, lng)
        });

        marker.building = building;

        $('body').keyup(({ keyCode }) => {
            if (keyCode === 27) {
                if (this.activeMarker === marker) {
                    this.closeMarker(marker);
                }
            }
        });

        const view = new WiseMetering.Views.MapBuildingPopup({ model: building, contentHeight: '144px' });
        view.render();
        marker.infowindow = new google.maps.InfoWindow({
            content: view.$el.html()
        });

        google.maps.event.addListener(marker, 'click', () => {
            if (this.activeMarker === marker) {
                return;
            }

            if (this.activeMarker) {
                this.closeMarker(this.activeMarker);
            }

            this.map.panTo({ lat, lng });
            this.disablePanningAndScrolling();

            marker.infowindow.open(this.map, marker);
            marker.setIcon(WiseMetering.mapIcons.building.hover);
            this.activeMarker = marker;
        });

        google.maps.event.addListener(marker, 'mouseover', () => {
            if (this.activeMarker === marker) {
                return;
            }
            marker.setIcon(WiseMetering.mapIcons.building.hover);
        });

        google.maps.event.addListener(marker, 'mouseout', () => {
            if (this.activeMarker === marker) {
                return;
            }
            marker.setIcon(icon);
        });

        google.maps.event.addListener(marker.infowindow, 'closeclick', () => {
            building.trigger('node:close');
            if (this.activeMarker === marker) {
                this.closeMarker(marker);
            }
        });

        this.bounds.extend(marker.position);
        this.markers.push(marker);
    },

    enablePanningAndScrolling: function() {
        $(this.control).show();
        this.map.setOptions({
            zoomControl: true,
            gestureHandling: 'greedy'
        });
    },

    fitBounds: function() {
        const markers = this.markers.filter(marker => marker.visible);
        if (markers.length === 0) {
            return;
        }

        let bounds = new google.maps.LatLngBounds();
        markers.forEach(marker => bounds.extend(marker.position));
        this.map.fitBounds(bounds);
    },

    onShow: function() {
        window.goToBuilding = id => {
            const model = WiseMetering.zones.get(id);
            Backbone.history.navigate(model.nodeUrl(true), { trigger: true });
        };

        let { lat, lng, zoom } = MapService;

        if (zoom < this.minZoom || zoom > this.maxZoom) {
            zoom = this.maxZoom;
            MapService.set({ zoom });
        }

        this.map = new google.maps.Map(document.getElementById('map-placeholder'), {
            center: new google.maps.LatLng(lat, lng),
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId[this.mapType],
            maxZoom: this.maxZoom,
            minZoom: this.minZoom,
            rotateControl: false,
            scaleControl: false,
            streetViewControl: false,
            styles: WiseMetering.MapStyles,
            zoom: zoom,
            zoomControl: true
        });
        this.bounds = new google.maps.LatLngBounds();

        google.maps.event.addListener(this.map, 'idle', this.updateUrl.bind(this));

        // I can't figure out how to make it work without this line here.
        this.clearMarkers();
        this.collection.each(this.drawMarker.bind(this));

        if (!zoom || !lat || !lng) {
            this.fitBounds();
        }

        this.control = this.createControl();
        this.control.addEventListener('click', function() {
            if (!this.activeMarker) {
                this.fitBounds();
            }
        }.bind(this));
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.control);

        this.listenTo(window.MapService.events, 'map:search:end', this.filterMarkers);

        this.search.show(new WiseMetering.Views.MapSearch({ collection: this.collection }));
    },

    filterMarkers: function(buildingIds) {

        if (this.activeMarker) {
            this.closeMarker(this.activeMarker);
        }
        this.markers.forEach(marker => {
            marker.setVisible(buildingIds.includes(marker.building.id));
        });
    },

    updateUrl: function() {
        const center = this.map.getCenter();
        MapService.set({
            lat: center.lat(),
            lng: center.lng(),
            zoom: this.map.getZoom()
        });
    },

    clearMarkers: function() {
        this.markers.forEach(marker => marker.setMap(null));
        this.markers = [];
    },

    close: function() {
        this.clearMarkers();
        this.activeMarker = null;
        this.bounds = null;
        this.map = null;
        this.stopListening(window.MapService.events);
    }
});
