WiseMetering.Views.EditIntegrationAsset = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration_asset',
    template: 'templates/integration_assets/edit',
    title: () => i18next.t('modal.title.integration_assets.edit'),

    getFormData: function() {
        const data = this.formSerializer(),
              checked = $('input[name=asset-state]').prop('checked'),
              assetSettings = this.model.get('settings'),
              settingsParams = {};

        checked ? data.state = 'active' : data.state = 'disabled';

        assetSettings.forEach(function (setting) {
            const checkbox = $('input[name="settings[' + setting.key + ']"]');
            settingsParams[setting.key] = checkbox.prop('checked');
        });

        data.settings = settingsParams;

        return data;
    },

    onRender: function() {
        const $container = this.$('.sliders-container');

        this.model.get('settings').forEach(function(setting) {
            const settingView = new WiseMetering.Views.IntegrationAssetSetting({ model: new Backbone.Model(setting) });

            settingView.render();
            $container.append(settingView.el);
        }, this);
    },
});
