module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'organization_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'organization_name\' name=\'organization[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for="organization_subdomain">' +
((__t = ( i18next.t('glossary.subdomain') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class="text" id="organization_subdomain" name="organization[subdomain]" type="text" value="' +
((__t = ( subdomain )) == null ? '' : __t) +
'"/>\n        </dd>\n        <dt>\n            <label for=\'organization_country_code\'>' +
((__t = ( i18next.t('common.country') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'organization_country_code\' name=\'organization[country_code]\'>\n                ';
 WiseMetering.countries.each(function(country){ ;
__p += '\n                    <option ' +
((__t = ( country.get("code") === country_code ? "selected" : '' )) == null ? '' : __t) +
' value=\'' +
((__t = ( country.get("code") )) == null ? '' : __t) +
'\'>' +
((__t = ( country.get("name") )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <label for=\'organization_timezone\'>' +
((__t = ( i18next.t('glossary.timezone') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'organization_timezone\' name=\'organization[timezone]\'>\n                ';
 timezones.forEach(function(tz) { ;
__p += '\n                    <option ' +
((__t = ( tz === timezone ? "selected='selected' " : '' )) == null ? '' : __t) +
'value=\'' +
((__t = ( tz )) == null ? '' : __t) +
'\'>' +
((__t = ( tz )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <label for=\'organization_type\'>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'organization_type\' name=\'organization[type]\'>\n                <option disabled selected=\'selected\'>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 _.each(WiseMetering.organizationTypes, function(type_option){ ;
__p += '\n                    <option value=\'' +
((__t = ( type_option.slug )) == null ? '' : __t) +
'\'>' +
((__t = ( type_option.name )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <label for=\'organization_tier\'>' +
((__t = ( i18next.t('glossary.tier') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <div class="btn-group btn-group-organization" role="group" aria-label="...">\n                ';
 _.each(['essentials', 'standard', 'advanced', 'premium'], function(tier){ ;
__p += '\n                    <button type="button" class="btn btn-' +
((__t = ( tier )) == null ? '' : __t) +
'">\n                        ' +
((__t = ( tier )) == null ? '' : __t) +
'\n                    </button>\n                ';
 }); ;
__p += '\n            </div>\n        </dd>\n        <dt>\n            <label class=\'decapitalize\' for="organization-icon">' +
((__t = ( i18next.t('organizations.icon_label') )) == null ? '' : __t) +
' *</label>\n        </dt>\n        <dd>\n            <input accept="image/png" class=\'input-icon text\' type=\'file\'>\n        </dd>\n\n        <div class="preview-icon-wrapper">\n            <dt>\n                <label class=\'decapitalize\'>' +
((__t = ( i18next.t('organizations.icon_preview') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd class="preview-icon">\n                <img class="preview-icon-image" src="#" alt="icon"/>\n            </dd>\n        </div>\n\n        <dt>\n            <label class=\'decapitalize\' for="organization-logo">' +
((__t = ( i18next.t('organizations.logo_label') )) == null ? '' : __t) +
' *</label>\n        </dt>\n        <dd>\n            <input accept="image/png" class=\'input-logo text\' type=\'file\'>\n        </dd>\n\n        <div class="preview-logo-wrapper">\n            <dt>\n                <label class=\'decapitalize\'>' +
((__t = ( i18next.t('organizations.logo_preview') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd class="preview-logo">\n                <img class="preview-logo-image" src="#" alt="logo"/>\n            </dd>\n        </div>\n        <dt>\n            <label for=\'organization_currency\'>' +
((__t = ( i18next.t('common.currency') )) == null ? '' : __t) +
'</label>\n        </dt>\n\n        <dd>\n            <select class=\'text\' id=\'organization_currency\' name=\'organization[currency]\'>\n                ';
 _.each(WiseMetering.currencies, function(currency){ ;
__p += '\n                    <option value=\'' +
((__t = ( currency )) == null ? '' : __t) +
'\'>' +
((__t = ( currency )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}