module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        ' +
((__t = ( i18next.t('buildings.disable_confirmation') )) == null ? '' : __t) +
'\n    </dl>\n</form>\n';

}
return __p
}