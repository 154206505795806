module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<input class=\'text\' hidden id="etl_dsn" type="text" name=\'etl[dsn]\' value="lora">\n<input class=\'text\' hidden id="etl_mode" type="text" name=\'etl[mode]\' value="' +
((__t = ( mode )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id="etl_rate" type="number" name=\'etl[rate]\' value="' +
((__t = ( rate )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id="etl_secret" type="text" name=\'etl[secret]\' value="lora">\n<input class=\'text\' hidden id="etl_start_at" type="text" name=\'etl[start_at]\' value="' +
((__t = ( start_at )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id="etl_username" type="text" name=\'etl[username]\' value="lora">\n';

}
return __p
}