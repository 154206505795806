Lmit.UI.EventInspector = function(_title_, _data_, _controller, _container_, _options_) {
    this.title = _title_ || null;
    this.id = _options_.id || null;
    this.data = _data_;
    this.options = _options_;
    this.controller = _controller;
    this.container = _container_;
    this.list = null;
    this.form = null;
    this.toolbar = null;
    this.element = null;
    this.eventPoint = _options_.eventPoint || null;
    this.init();
};

Lmit.UI.EventInspector.prototype = {
    init() {
        this.element = $('<div>').addClass('wmui-event-inspector');
        if (this.options.id) this.element.attr({ id: this.options.id });
        (this.container instanceof Lmit.Section) ? this.container.addItem(this) : this.container.append(this.element);
        this.buildToolbar();
        this.build();
    },

    destroy() {
        // remove plot band
        const plotID = this.id;
        this.controller.chartController.chart.removePlotBand(`_event_period_${plotID}`);
        this.controller.chartController.chart.removePlotBand(`event_reference_period_${plotID}`);
        // slideUp body and then section
        this.container.sectionBodyElement.slideUp(400, () => {
            this.list.remove();
            this.toolbar.remove();
            this.element.remove();
            this.container.sectionElement.slideUp(400, () => {
                this.container.destroy();
            });
        });
    },

    buildToolbar() {
        const closeEventInspector = {
            onSelect: (value, e) => {
                // remove event from indicator
                try {
                    this.controller.removeEvent(this.id);
                } catch (err) {
                }
                e.preventDefault();
            }
        };
        this.toolbar = new Lmit.UI.Toolbar('Event Inspector Toolbar', [['wmui-btn', i18next.t('indicators.close_marker'), { style: { 'float': 'right' } }, closeEventInspector]], this.element, { 'style': 'regular' });
    },

    build() {
        if (this.list) {
            this.list.remove();
        }
        this.list = $('<div>').addClass('wmui-list');
        if (this.title) {
            this.head = $('<div>').addClass('wmui-list-title').append($('<h3>').text(this.title));
            this.list.append(this.head);
        }
        let list = $('<dl>').addClass('stepped');

        for (const item in this.data) {
            const value = this.data[item];
            if (!value) {
                continue;
            }

            const dt = $('<dt>');
            dt.append($('<span>').text(item));
            list.append(dt);

            const dd = $('<dd>');
            if (item === 'Marker Results') {
                value.split('\n').forEach(text => dd.append($('<span>').text(text).css({ 'display': 'block' })));
            } else {
                dd.append($('<span>').text(value)).append('<br>');
            }
            list.append(dd);
        }

        this.list.append(list);
        this.element.append(this.list);

        // avoids losing current selected event point when, for example, the chart is zoomed
        let eventPoint = this.eventPoint;

        let x;
        let d;

        // zoom to period
        switch (eventPoint.typeCategory) {
            case 'test':
                if (eventPoint.referencePeriodStart) {
                    x = (this.eventPoint.options.d) ? new Date(this.eventPoint.referencePeriodStart) : moment(new Date(this.eventPoint.referencePeriodStart)).add({ hours: -12 });
                    d = (this.eventPoint.options.d) ? new Date(this.eventPoint.options.d) : moment(new Date(this.eventPoint.referencePeriodStart)).add({ hours: 12 });
                } else {
                    x = (this.eventPoint.options.d) ? new Date(this.eventPoint.options.x) : moment(new Date(this.eventPoint.options.x)).add({ hours: -12 });
                    d = (this.eventPoint.options.d) ? new Date(this.eventPoint.options.d) : moment(new Date(this.eventPoint.options.x)).add({ hours: 12 });
                }
                break;
            default:
                x = (this.eventPoint.options.d) ? new Date(this.eventPoint.options.x) : moment(new Date(this.eventPoint.options.x)).add({ hours: -12 });
                d = (this.eventPoint.options.d) ? new Date(this.eventPoint.options.d) : moment(new Date(this.eventPoint.options.x)).add({ hours: 12 });
                break;
        }

        this.controller.chartController.eventController.selectedEvent = jQuery.extend(true, {}, this.eventPoint);

        const tpPlotBand = {
            id: `_event_period_${eventPoint.options.id}`,
            from: eventPoint.x,
            to: eventPoint.d,
            color: '#f3f3f3',
            _ref: eventPoint.options.id
        };
        this.controller.chartController.chart.addPlotBand(tpPlotBand);

        if (eventPoint.referencePeriodStart) {
            // create plot bands for reference and test periods
            const rpPlotBand = {
                id: `event_reference_period_${eventPoint.options.id}`,
                from: eventPoint.referencePeriodStart,
                to: eventPoint.referencePeriodEnd,
                color: '#f3f3f3'
            };
            this.controller.chartController.chart.addPlotBand(rpPlotBand);
        }
    },

    remove() {
        this.element.remove();
    }
};
