module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'high\' style=\'float:left;padding:1em 0 0 .5em;\'>\n    <img src=\'/images/ui/password.png\'>\n</div>\n<div class=\'form_content\' style=\'float:left;margin:1em;padding-left:1em;border-left:1px solid #e3e3e3;width:380px\'>\n    <div class=\'explain\' style=\'border:none; margin-bottom:0\'>\n        <div class=\'info notice\'>\n            <div class=\'strong\'>\n                Please enter your Registered Email.\n            </div>\n            We will send you an email with a link to reset your password.\n        </div>\n    </div>\n    <dl class=\'stepped\'>\n        <dt>\n            <label for=\'email\'>\n                Registered Email\n            </label>\n        </dt>\n        <dd>\n            <input class=\'title\' id="registered-email" name=\'email\' type=\'text\' value=\'' +
((__t = ( email )) == null ? '' : __t) +
'\'>\n        </dd>\n        <input id=\'submit\' style=\'display:none;\' type=\'submit\'>\n    </dl>\n</div>\n';

}
return __p
}