WiseMetering.Views.IndexInactiveTenantsByBuilding = WiseMetering.Views.TableIndex.extend({
    icon: 'tenants',
    name: () => i18next.t('glossary.tenants'),

    createTable: function createTable() {
        let collection = this.model.inactiveTenants(),
            table = new WiseMetering.Views.Table({
                id: 'tenants',
                collection: collection,
                columns: {
                    name: i18next.t('common.name'),
                    company_id: {
                        title: i18next.t('glossary.company'),
                        mRender: function(company_id) {
                            const company = WiseMetering.companies.get(company_id);
                            return company.get('name');
                        }
                    },
                    area: {
                        title: i18next.t('common.area'),
                        className: 'align-right'
                    }
                },
                sort: {
                    column: 'from',
                    order: 'desc'
                },
                toolbar_actions: {
                    new: {
                        view_call: () => {
                            new WiseMetering.Views.AddTenant({
                                collection: WiseMetering.tenants,
                                model: new WiseMetering.Model.Tenant({
                                    building_id: this.model.building().id,
                                    organization_id: WiseMetering.currentOrganization.id
                                })
                            });
                        }
                    },
                    edit: {
                        view_call: model => {
                            model.fetch({
                                success: model => new WiseMetering.Views.EditTenant({ model: model })
                            });
                        }
                    },
                    delete: {
                        view_call: model => new WiseMetering.Views.DestroyTenant({ model })
                    },
                    show: {
                        view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                    }
                }
            });

        this.listenTo(this.model, 'change:children:addTenant', function(node) {
            collection.add(node);
        });

        this.listenTo(this.model, 'change:children:removeTenant', function(node) {
            collection.remove(node);
        });

        return table;
    }
});
