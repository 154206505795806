WiseMetering.Views.Modal.Base = Backbone.Marionette.Layout.extend({
    template: 'templates/ui/modal/box',

    attributes: function() {
        return {
            id: 'lmit_mbx',
            class: 'mbx'
        };
    },

    class: 'mbx',

    // Defaults
    closable: false,
    draggable: true,
    backdrop: true,
    width: 466,
    buttons: [],
    title: null,
    resize: true,
    $backdrop: $('#modal-backdrop'),
    $body: $('body'),

    regions: {
        header: '#modal-header',
        errors: '#modal-errors',
        body: '#modal-body',
        footer: '#modal-footer'
    },

    events: {
        'click #activate': 'activate',
        'click #cancel': 'cancel',
        'click #close': 'close',
        'click #discard': 'discard',
        'click #edit': 'edit',
        'click #no': 'close',
        'click #ok': 'ok',
        'click #open': 'open',
        'click #reset': 'reset',
        'click #save': 'save',
        'click #validate': 'validate',
        'click #yes': 'yes'
    },

    serializeData: function() {
        return {
            closable: this.closable,
            backdrop: this.backdrop,
            buttons: typeof (this.buttons) === 'function' ? this.buttons() : this.buttons,
            title: typeof (this.title) === 'function' ? this.title() : this.title
        };
    },

    initialize: function(attributes) {
        $.extend(this, attributes);
        this.body.on('show', this.redraw.bind(this, true));

        //Preventing form submission when pressing Enter
        this.$el.on('keydown', event => !(event.keyCode === 13 && event.target.tagName !== 'TEXTAREA'));

        $(window).on('resize.modal', this.ajustMaxSizes.bind(this));
    },

    onRender: function() {
        var that = this;
        if (that.draggable) that.$el.draggable({ handle: '#modal-header' });
        if (that.backdrop) that.$backdrop.show();
        that.$el.css({
            position: 'fixed',
            width: that.width,
            height: 'auto',
            display: 'block',
            zIndex: 9000
        });
        this.ajustMaxSizes();
    },

    cancel() {
        $('.template_defined div').trigger('click');
        this.onClose();
    },

    onClose: function() {
        $(window).off('resize.modal');

        this.$body.unbind('keyup');
        this.$body.keyup(WiseMetering.onCancel);

        this.$backdrop.hide();
        $('div.stt').remove();

        // I don't understand why this is needed... And I think I'm doing something wrong
        WiseMetering.modalBox.$el.empty();
        WiseMetering.modalBox.currentView = undefined;
    },

    redraw: function(force) {
        const width = this.$el.width();
        const height = this.$el.height();

        if (this.width !== width || force) {
            this.width = width;
            this.$el.css({ left: $(window).width() / 2 - width / 2 });
        }

        if (this.height !== height || force) {
            this.height = height;
            this.$el.css({ top: $(window).height() / 2 - height / 2 });
        }
    },

    ajustMaxSizes: function() {
        this.$el.css({ 'max-width': $(window).width() - 100 })
        .find('.mbx_cont').css({ 'max-height': $(window).height() - 200 });
    },

    ok: function() {
        this.close();
    },

    edit: function() {
    },

    reset: function() {
    },

    save: function() {
    },

    activate: function() {
    },

    discard: function() {
    },

    showActivationButton() {
    },

    showDiscardButton() {
    },

    validate: function() {
    },

    yes: function() {
    },

    block: function() {
        this.$body.unbind('keyup');
        this.$el.append(JST['templates/ui/modal/block']());
    },

    unblock: function() {
        this.$body.keyup(WiseMetering.onCancel);
        this.$('.modal_blocker').remove();
    }

});
