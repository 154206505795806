WiseMetering.Views.ContractsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/contracts/accordion',

    onRender: function() {
        this.listenTo(WiseMetering.contracts, 'add change remove', this.createTable.bind(this));
        this.createTable();
    },

    createTable: function() {
        const collection = this.model.contracts();
        collection.comparator = function(model) {
            return -parseInt(model.get('start').replace('-', ''));
        };
        collection.sort();

        const columns = {
            name: i18next.t('common.name'),
            tariff: i18next.t('glossary.tariff'),
            start: i18next.t('common.start'),
            finish: i18next.t('common.finish')
        };

        if (this.model.utilityKind().get('slug') === 'electricity') {
            columns['contractedPower'] = i18next.t('glossary.contracted_power');
        }

        this.table = new WiseMetering.Views.Table({
            accordion: true,
            collection,
            modelSerializer: this.modelSerializer,
            columns,
            id: 'contracts',
            toolbar_actions: {
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                },
                new: {
                    view_call: () => {
                        new WiseMetering.Views.NewContract({
                            collection: WiseMetering.contracts,
                            model: new WiseMetering.Model.Contract({ utility_id: this.model.id }),
                            utility: this.model
                        });
                    }
                },
                edit: {
                    view_call: model => new WiseMetering.Views.EditContract({ model })
                },
                delete: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.Modal.Destroy({ model })
                }
            }
        });

        this.$('#contracts-table').html(this.table.el);
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    modelSerializer: function(model) {
        return {
            ...model.toJSON(),
            tariff: model.tariff().get('name'),
            contractedPower: model.formatContractedPower()
        };
    }
});
