module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (!configured) { ;
__p += '\n    <div id=\'configuration-status\'></div>\n';
 } ;
__p += '\n<div id=\'summary-info\'></div>\n<div id=\'details-accordion\'></div>\n<div id=\'location-accordion\'></div>\n<div id=\'attributes-accordion\'></div>\n<div id=\'indicators-accordion\'></div>\n<div id=\'zones-accordion\'></div>\n<div id=\'folders-accordion\'></div>\n<div id=\'circuits-accordion\'></div>\n';
 if (configured) { ;
__p += '\n    <div id=\'configuration-status\'></div>\n';
 } ;
__p += '\n<div id=\'system-info-accordion\'></div>\n';

}
return __p
}