module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('common.attributes') )) == null ? '' : __t) +
'\n                ';
 if(WiseMetering.currentUser.get('manager')){ ;
__p += '\n                    <div class=\'wmui-btn\' id=\'edit_attributes\' style=\'float: right;\'>' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'</div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\' style="display:flex; justify-content: space-between;">\n                <div class=\'per-box\'>\n                    <dl>\n                        <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('glossary.indicators') )) == null ? '' : __t) +
':</dt>\n                        <dd>\n                            <a href="' +
((__t = ( indicator.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</a>\n                        </dd>\n                        ';
 if (variable) { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('seus.independent_variable') )) == null ? '' : __t) +
':</dt>\n                            <dd>\n                                <a href="' +
((__t = ( variable.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( variable.get('name') )) == null ? '' : __t) +
'</a>\n                            </dd>\n                        ';
 } ;
__p += '\n                    </dl>\n                </div>\n                ';
 if (relevance) { ;
__p += '\n                    <div class=\'per-box\'>\n                        <dl>\n                            <div align=\'right\' style=\'color:' +
((__t = ( WiseMetering.baseColors.green )) == null ? '' : __t) +
';font-size:14px\'><b>' +
((__t = ( relevance )) == null ? '' : __t) +
'</b></div>\n                            <div style=\'color:' +
((__t = ( WiseMetering.baseColors.dark_green )) == null ? '' : __t) +
';font-size:0.9167em\' onMouseOver>\n                                ' +
((__t = ( i18next.t('common.relevance') )) == null ? '' : __t) +
'\n                                <span data-placement=\'bottom\' data-toggle=\'tooltip\' title="' +
((__t = ( i18next.t('seus.when_compared_to_total_consumption') )) == null ? '' : __t) +
'">\n                                <i class="' +
((__t = (WiseMetering.icons.question_circle)) == null ? '' : __t) +
'" aria-hidden=\'true\'></i>\n                            </span>\n                            </div>\n                        </dl>\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}