import hexToRgb from '@/utils/hexToRgb';

Lmit.Wise.Chart.Control.Errors = class {
    constructor(controller, container, indicator_id) {
        this.active = false;
        this.controller = controller;
        this.container = container;
        this.indicator = WiseMetering.indicators.get(indicator_id);
        this.plotBands = [];
        this.view = null;
        this.init();
    }

    init() {
        this.view = $('<div>').addClass('wmui-chart-control-errors');
        let control = $('<ul>').addClass('ctrl');
        control.append($('<li>').addClass('label').text(i18next.t('glossary.administration') + ':'));

        let eventCtrl = $(`<li title="${i18next.t('indicators.show_errors').capitalize()}">`).addClass('icon mode').append(WiseMetering.icon('errors'));

        this.container.append(this.view);
        control.append(eventCtrl);
        this.view.append(control);

        // Bind click event
        eventCtrl.click(() => {
            if (this.plotBands.length) {
                eventCtrl.toggleClass('current');
                this.active = eventCtrl.hasClass('current');
                this.active ? this.addPlotBands() : this.removePlotBands();
            } else {
                this.active = false;
                eventCtrl.removeClass('current').addClass('disabled').unbind('click');
                WiseMetering.layout.showTipper('info', i18next.t('indicators.no_errors_available'));
            }
        });

        // Pre-fetch data
        this.fetchData();

        const type = this.indicator.get('type');
        // Only root can rebuild all. Managers can also rebuild mirrors and indicators
        if (WiseMetering.currentUser.isSuperUser() || (WiseMetering.currentUser.get('manager') && (type === 'import'))) {
            // Add rebuild
            const title = i18next.t('common.rebuild').capitalize();
            const rebuildCtrl = $(`<li title="${title}">`).addClass('icon mode').append(WiseMetering.icon('rebuild'));
            control.append(rebuildCtrl);

            // Bind click event
            rebuildCtrl.click(() => {
                this.indicator.rebuild().then(
                    () => WiseMetering.layout.showTipper('info', i18next.t('indicators.rebuild_done')),
                    () => WiseMetering.layout.showTipper('error', i18next.t('indicators.rebuild_failed'))
                );
            });
        }

        if (WiseMetering.currentUser.isSuperUser() && this.indicator.get('type') != 'tmp') {
            let addAlarm = $(`<li title="${i18next.t('alarms.new_baseline_alarm')}">`).addClass('icon mode').append(WiseMetering.icon('alarms'));
            control.append(addAlarm);
            const callback = this.controller.indicatorController.alarmsController.__proto__.buildTriggerList.bind(this.controller.indicatorController.alarmsController);
            addAlarm.click(() => {
                new WiseMetering.Views.NewBaselineAlarm({ callback, model: this.indicator });
            });
        }
    }

    addPlotBands() {
        //this.removePlotBands();
        this.plotBands.forEach(band => this.controller.chart.chart.xAxis[0].addPlotBand(band));
    }

    enable() {
        this.view.fadeIn(400);
    }

    fetchData() {
        this.indicator.getErrorIntervals(data => {
            this.plotBands = data.map((interval, index) => {
                return {
                    from: interval[0] * 1000,
                    to: interval[1] * 1000,
                    color: hexToRgb('#e94547', 0.3),
                    id: 'error-plot-band-' + index
                };
            });
        });
    }

    idle() {
        this.view.fadeOut(400);
    }

    removePlotBands() {
        this.plotBands.forEach(band => this.controller.chart.chart.xAxis[0].removePlotBand(band.id));
    }
};
