WiseMetering.Views.Ui.Widget.BubbleChart = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/basic_chart',

    build: function() {
        this.drawChart();
    },

    drawChart: function() {
        const axis = this.axis,
              maxX = this.getHighestInAxis('x', this.data),
              maxY = this.getHighestInAxis('y', this.data),
              data = _.map(this.data, function(child, index) {

                return {
                    x: child.x,
                    y: child.y,
                    z: child.z,
                    name: child.name,
                    initial: child.initial,
                    color: this.generateColor(maxX, maxY, child.x, child.y),
                };
            }.bind(this));

        this.chart = new Highcharts.Chart(jQuery.extend(true, this.defaultChartOptions, {
            chart: {
                type: 'bubble',
                zoomType: 'xy',
                height: `${this.widgetPreferences.height}px`
            },
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false,
            },
            xAxis: {
                gridLineWidth: 1,
                labels: {
                    format: `{value} ${axis['x']['labelUnit']}`
                }
            },
            yAxis: {
                startOnTick: false,
                endOnTick: false,
                labels: {
                    format: `{value} ${axis['y']['labelUnit']}`
                },
                maxPadding: 0.2
            },
            tooltip: {
                useHTML: true,
                style: {
                    fontSize: '12px'
                },
                formatter: function() {
                    return `
                        <span style="font-weight:bold; color: ${this.point.color}">${this.point.name}</span><br>
                        <span><b>${axis['x']['title']}: </b>${formatValue(this.point.x, axis['x']['labelUnit'])}</span><br>
                        <span><b>${axis['y']['title']}: </b>${formatValue(this.point.y, axis['y']['labelUnit'])}</span>
                    `;
                },
                followPointer: true
            },
            plotOptions: {
                bubble: {
                    opacity: 1,
                    marker: {
                        lineWidth: 0
                    }
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.initial}',
                        style: {
                            textOutline: 'none',
                            fontSize: 10,
                            color: '#fff'
                        }
                    },
                }
            },
            series: [{
                data: data,
                marker: {
                    fillOpacity: 0.9
                }
            }],
        }));
        window.debuggChart = this.chart;
    },

    generateColor: function(maxX, maxY, x, y){
        const slope = maxY / maxX,
              hypotenuseY = 0 + slope * x;

        if (y > hypotenuseY + maxY * 0.2) {
            return WiseMetering.SemaphorColors.red;
        } else if (y < hypotenuseY - maxY * 0.1) {
            return WiseMetering.SemaphorColors.green;
        } else {
            return WiseMetering.SemaphorColors.orange;
        }
    },

    getHighestInAxis: function(axis, data) {
        let highestValue = 0;

        data.forEach(obj => {
            if (obj[axis] > highestValue) {
                highestValue = obj[axis];
            }
        });

        return highestValue;
    }
});
