WiseMetering.Views.ShowApiKey = WiseMetering.Views.Modal.Show.extend({
    buttons: ['close', 'edit'],
    className: 'mbx-info text-left',
    template: 'templates/api_keys/show',
    title: () => i18next.t('modal.title.api_keys.show'),

    edit: function() {
        this.block();
        _.defer(function() {
            new WiseMetering.Views.EditApiKey({ model: this.model });
        }.bind(this));
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json.buildings = this.model.buildings().pluck('name').join(', ');
        json.groups = this.model.groups().pluck('name').join(', ');
        json.rateFormatted = WiseMetering.RATES[this.model.get('rate')];
        return json;
    }
});
