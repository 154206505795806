WiseMetering.Views.Modal.Show = Backbone.Marionette.ItemView.extend({
    buttons: ['close'],

    initialize: function(attributes) {
        const that = this;
        $.extend(that, attributes);
        this.modal = new WiseMetering.Views.Modal.Base({
            buttons: that.buttons,
            closable: that.closable,
            draggable: that.draggable,
            edit: that.edit,
            activate: that.activate,
            discard: that.discard,
            mode: that.mode,
            model: that.model,
            ok: that.ok,
            open: that.open,
            reset: that.reset,
            showActivationButton: that.showActivationButton,
            showDiscardButton: that.showDiscardButton,
            title: that.title,
            width: that.width,
            yes: that.yes
        });
        WiseMetering.modalBox.show(this.modal);
        this.modal.body.show(that);
    }
});
