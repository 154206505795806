WiseMetering.Views.IndexApiRequests = WiseMetering.Views.TableIndex.extend({
    createTable: function() {
        return new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: WiseMetering.apiRequests,
            columns: {
                integrator: i18next.t('glossary.integration'),
                object_class: i18next.t('common.class'),
                action: i18next.t('common.action'),
                day: i18next.t('common.day'),
                counter: i18next.t('common.counter')
            },
            id: 'api-requests',
            modelSerializer: function(model) {
                const json = model.toJSON();
                json.day = WiseMetering.utils.formatDate(json.day, WiseMetering.currentOrganization.get('timezone'), 'YYYY/MM/DD');
                return json;
            }.bind(this),
            toolbar_actions: {
                refresh: {
                    disabled: false
                }
            }
        });
    }
});
