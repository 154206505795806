WiseMetering.Views.ContractDetails = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    template: 'templates/contracts/details',

    regions: {
        details: '#details-accordion'
    },

    onRender: function() {
        this.details.show(new WiseMetering.Views.ContractDetailsAccordion({ model: this.model }));
    }
});
