Lmit.UI.Toolbox = function(_text, _container, _options) {
    this.text = _text;
    this.options= _options;
    this.container = _container;
    this.element = null;
    this.list=null;
    this.init();
};


Lmit.UI.Toolbox.prototype = {
    init:function(){
        this.build();
    },

    build:function(){
        var _this_ = this;
        this.element = $("<div>").addClass("wmui-toolbox");
        if (this.options.id){ this.element.attr({ id:this.options.id }); }
        if (this.options.klass){ this.element.addClass(this.options.klass); }
        this.container.append(this.element);
        this.list = $("<ul>").addClass("wmui-toolbox-menu");
        this.element.append(this.list);
        _.each(this.options.data, function(_args){
            _this_.addOption(_args);
        });
    },

    addOption: function(_args){
        var _this_ = this;
        var item = $("<li>").addClass("wmui-toolbox-btn");
        if (_args.name){ item.attr({ name: _args.name }); }
        if (_args.title){ item.attr({ title: _args.title }); }
        _this_.list.append(item);
        new Lmit.UI.Icon(item, _args);
        if(_args.label){
            item.append($("<label>").text(_args.label));
        }
    }
};
