module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'color-legend\'>\n    <span class=\'color-box\' style=\'background-color: ' +
((__t = ( WiseMetering.Colors.AlarmTemplates[index] )) == null ? '' : __t) +
'\'></span>\n    <input id=\'period-name\' maxlength=\'12\' placeholder=\'' +
((__t = ( i18next.t('ui.schedule.legend_tooltip') )) == null ? '' : __t) +
'\'>\n</div>\n';

}
return __p
}