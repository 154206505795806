WiseMetering.Model.ActuatorKind = WiseMetering.Model.extend({
    circuit_tags: function() {
        var id = this.id;
        // Find all actuators of this type
        var actuators = WiseMetering.actuators.where({ actuator_kind_id: id });
        // Then, find all circuits that have this actuators
        var circuits = _.collect(actuators, function(actuator) { return WiseMetering.circuits.get(actuator.get('circuit_id')); });
        circuits = _.compact(circuits);
        // Then, for each circuit, find the tag
        var circuit_tags = _.collect(circuits, function(circuit) { return WiseMetering.circuitTags.get(circuit.get('tag_id')); });
        // Return a collection with all the tags that have this actuator kind connected
        return new WiseMetering.Collection(_.unique(circuit_tags));
    }
});
