WiseMetering.Views.EditCircuitSchedule = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/circuits/schedules/edit',
    form_prefix: 'circuit',
    width: 1020,
    title: 'Edit Schedule',
    ui: {
        extra: '#circuit-schedule-extra',
        schedule: '#schedule'
    },

    getFormData: function() {
        let data = this.formSerializer();

        data.schedule.extra = data.schedule.extra || {};

        Object.entries(data.schedule.extra).forEach(([key, value]) => {
            data.schedule.extra[key] = parseInt(value);
        });

        return data;
    },

    onRender: function() {
        this.setExtra();

        let periods = ['off', 'on'];
        if (this.model.get('system_category_name') === 'Lighting') {
            periods = [...periods, 'sun'];
        }

        const view = new WiseMetering.Views.CircuitSchedule({
            editable: true,
            periods,
            values: this.model.get('schedule')
        });

        this.ui.schedule.append(view.el);
    },

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            fullPath: this.model.fullPath()
        };
    },

    setExtra: function() {
        if (this.model.get('system_category_name') === 'Lighting') {
            const extra = this.model.get('schedule').extra;
            this.ui.extra.html(JST['templates/circuits/schedules/extra']());
            this.$('#circuit-schedule-extra-delay').val(extra.delay);
            this.$('#circuit-schedule-extra-advance').val(extra.advance);
        }
    }
});
