WiseMetering.Model.Organization = WiseMetering.Model.extend({
    urlRoot: '/organizations',

    calculateRequests: function(type) {
        let total = 0;
        WiseMetering.apiRequests.forEach(function(request) {
            let startOf = moment.tz(this.get('timezone')).startOf(type).unix();
            if (request.get('day') >= startOf) {
                total = total + request.get('counter');
            }
        }.bind(this));
        return total;
    },

    dataRetention: function() {
        const tier = WiseMetering.tiers.get(this.get('tier_id'));
        return tier.get('data_retention');
    },

    defaults: function() {
        return {
            automation: false,
            country_code: 'PT',
            currency: '€',
            name: '',
            sets: false,
            subdomain: '',
            timezone: 'Europe/Lisbon'
        };
    },

    getAllowedTransitions: function() {
        return {
            essentials: [].includes(this.tier().get('name')),
            standard: ['essentials'].includes(this.tier().get('name')),
            advanced: ['essentials', 'standard'].includes(this.tier().get('name')),
            premium: ['essentials', 'standard', 'advanced'].includes(this.tier().get('name'))
        };
    },

    hasStandardPermissions: function() {
        return this.get('is_standard') || this.get('is_advanced') || this.get('is_premium');
    },

    hasAdvancedPermissions: function() {
        return this.get('is_advanced') || this.get('is_premium');
    },

    hasSpecialFeatures: function() {
        return this.get('type') !== 'retail';
    },

    icon: function () {
        return 'organization';
    },

    is: function(tierName) {
        if (tierName instanceof Array) {
            return tierName.includes(this.tier().get('name'));
        } else {
            return this.get('tier_id') === WiseMetering.tiers.findWhere({name: tierName}).id;
        }
    },

    isRetail: function() {
        return this.get('type') === 'retail';
    },

    showIntegrations: function() {
        return WiseMetering.currentOrganization.hasAdvancedPermissions() || WiseMetering.currentOrganization.get('has_integrations');
    },

    subdomain: function() {
        return '/' + this.get('subdomain');
    },

    tier: function () {
        return  WiseMetering.tiers.get(this.get('tier_id'));
    },

    showControlOutputs: function() {
        return this.get('automation') || this.get('type') === 'retail';
    },
});
