WiseMetering.Views.OpportunityModalDetails = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/opportunities/details',
    className: 'mbx-info text-left',
    form_prefix: 'opportunity',
    width: 600,
    title: () => i18next.t('modal.title.opportunities.show'),

    ui: {
        fileLink: '#file-link',
        removeFile: '#remove-file',
        spinner: '#spinner'
    },

    events: {
        'click #edit': 'edit',
        'click #pending': 'onWorkflowClicked',
        'click #ongoing': 'onWorkflowClicked',
        'click #completed': 'onWorkflowClicked',
        'click #discarded': 'onWorkflowClicked',
        'click #remove-file': 'onRemoveFile'
    },

    buttons: function() {
        const buttons = ['close'];
        if (WiseMetering.currentUser.get('administrator')) {
            buttons.push('edit');
        }
        return buttons;
    },

    edit: function() {
        new WiseMetering.Views.EditOpportunity({ model: this.model });
    },

    onWorkflowClicked: function(event) {
        event.preventDefault();

        const state = event.currentTarget.value;
        this.model.changeState(state).done(() => {
            this.render();
        });
    },

    onRemoveFile() {
        this.ui.fileLink.hide();
        this.ui.removeFile.hide();
        this.ui.spinner.show();
        $('.toolbar_button').attr('disabled', 'disabled');
        WiseMetering.layout.showTipper('info', i18next.t('ui.removing_file'), false);
        this.model.removeFile().done(() => {
            WiseMetering.layout.showTipper('success', i18next.t('ui.file_removed_successfully'));
            $('.toolbar_button').removeAttr('disabled', 'disabled');
            this.ui.fileLink.hide();
            this.ui.spinner.hide();
        });
    },

    onRender: function() {
        const html = JST['templates/ui/modal/workflow']({
            buttons: {
                pending: true,
                ongoing: true,
                completed: true,
                discarded: true
            },
            selected: this.model.get('state')
        });
        this.$('#workflow').append(html);
    },

    serializeData: function() {
        const
            json = this.model.toJSON(),
            timezone = this.model.timezone(),
            utilityKind = this.model.utilityKind(),
            creator = WiseMetering.users.get(json.created_by),
            updater = WiseMetering.users.get(json.updated_by);

        json.capex = Lmit.Utils.formatNumber(json.capex, WiseMetering.getCurrency(), 0);
        json.color = this.model.states[this.model.get('state')].color;
        json.completed_at = this._formatDate(json.completed_at, timezone);
        json.consumption = Lmit.Utils.formatNumber(json.consumption, utilityKind.unit(), 0);
        json.cost = Lmit.Utils.formatNumber(json.cost, WiseMetering.getCurrency(), 0);
        json.created_at = this._formatDate(json.created_at, timezone);
        json.creatorName = creator ? creator.get('name') : 'System';
        json.description = !json.description ? '-' : json.description;
        json.file_link = this.model.fileLink();
        json.indicator = this.model.indicator();
        json.payback = this.model.payback();
        json.updaterName = updater ? updater.get('name') : 'System';
        json.updated_at = this._formatDate(json.updated_at, timezone);
        json.utilityKindName = i18next.t(`utility_kinds.${utilityKind.get('slug')}`).capitalize();

        return json;
    },

    _formatDate: function(date, timezone) {
        if (!date) {
            return null;
        }
        return WiseMetering.utils.formatDate(date, timezone, 'YYYY/MM/DD HH:mm');
    }
});
