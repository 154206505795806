// String
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.splitCapitalize = function(divider, joiner = ' ') {
    const array = this.split(divider).map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return array.join(joiner);
};
