module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class=\'interval\'>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">\n            ' +
((__t = ( i18next.t("common.interval") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("common.from") )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("common.to") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( interval.from )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class="to" name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( interval.to )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n\n    <tr class=\'spacer\'></tr>\n    <tr>\n        <th class=\'head\' colspan=\'2\'>\n            Gás\n        </th>\n    <tr>\n        <th>\n            Componente Fixa <b>(' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/dia)</b>\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][fixed]" type="text" value="' +
((__t = ( interval.values?.fixed )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n\n    <tr class=\'spacer\'></tr>\n    <th class=\'head capitalize\' colspan=\'2\'>' +
((__t = ( i18next.t('glossary.ranges') )) == null ? '' : __t) +
'</th>\n    ';
 ranges.forEach(function(range, rangeIndex) { ;
__p += '\n        <tr class="range_' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'">\n            <th>\n                From\n            </th>\n            <td>\n                <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][rages][' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( range.from )) == null ? '' : __t) +
'">\n            </td>\n        </tr>\n        ';
 if(range.to) { ;
__p += '\n            <tr class="range_' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'">\n                <th>\n                    To\n                </th>\n                <td>\n                    <input class="range_end" ' +
((__t = ( range.disabled ? 'disabled' : '' )) == null ? '' : __t) +
' name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][rages][' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( range.to )) == null ? '' : __t) +
'">\n                </td>\n            </tr>\n        ';
 } ;
__p += '\n        <tr class="range_' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'">\n            <th>\n                Valor\n            </th>\n            <td>\n                <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][rages][' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'][value]" type="text" value="' +
((__t = ( range.value )) == null ? '' : __t) +
'">\n            </td>\n        </tr>\n\n        <tr class=\'spacer\'></tr>\n    ';
 }); ;
__p += '\n\n    <tr class=\'spacer last\'></tr>\n    <th class=\'top delRange\' colspan=\'2\'>Remover Escalão</th>\n</table>\n';

}
return __p
}