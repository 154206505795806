module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'offset\' style=\'margin-bottom: 5px;\'>\n    <select name=\'alarm[integration_assets][]\' class=\'text\' style=\'display:inline-block; width: calc(100% - 31px) !important;\' ' +
((__t = ( assetId ? 'disabled' : '' )) == null ? '' : __t) +
'>\n        <option value=\'\' disabled selected>- Select asset -</option>\n        ';
 WiseMetering.integrationAssets.each(function(asset) { ;
__p += '\n            ';
 if (asset.get('indicator_id') === indicatorId) { ;
__p += '\n                <option ' +
((__t = ( (asset.id === assetId) ? 'selected ' : '' )) == null ? '' : __t) +
'value=\'' +
((__t = ( asset.id )) == null ? '' : __t) +
'\'>\n                    ' +
((__t = ( asset.get('name') )) == null ? '' : __t) +
'\n                </option>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n    </select>\n    <div class=\'wmui-btn rm wmui-symbol strong\' id=\'remove-asset\'>✖</div>\n</div>\n';

}
return __p
}