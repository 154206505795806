WiseMetering.Views.NewScheduledReport = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',

    events: {
        'change #report-type': 'onTypeChange',
        'change #report-comparison-section': 'showFrequency',
        'change #report-file-section': 'showFrequency',
        'change #report-folder-section': 'showFrequency',
        'change #report-details-section': 'showFrequency',
        'click #add-notification': 'onAddNotification',
        'click #remove-notification': 'onRemoveNotification'
    },

    form_prefix: 'report',

    frequencies: [
        { title: 'Daily', value: 'day' },
        { title: 'Weekly', value: 'week' },
        { title: 'Monthly', value: 'month' },
        { title: 'Quarterly', value: 'quarter' }
    ],

    input_readers: [
        { func: $element => $element.val() === '' ? null : $element.val(), type: 'hidden' },
        { func: $element => $element.val() === '' ? null : $element.val(), type: 'select' }
    ],

    template: 'templates/scheduled_reports/new',

    title: null,

    width: 500,

    eventsReportSettings: function(type) {
        if (['ZoneEventsReport', 'GlobalEventsReport'].includes(type)) {
            this.showFrequency();
        }
    },

    schemaReportSettings: function(type) {
        if (['ZoneSchemaReport'].includes(type)) {
            this.showFrequency();
        }
    },

    zoneConsumptionSettings: function(type) {
        if (type === 'ZoneConsumptionReport') {
            this.showFrequency();
            ['week', 'month', 'quarter'].forEach((item, i) => {
                $('#report-frequency option[value="' + item + '"]').attr('disabled', true);
            });
        }
    },

    folderReportSettings: function(type) {
        const folderElement = this.$('#report-folder-section');
        ['ZoneFolderReport'].includes(type) ? folderElement.show() : folderElement.hide();
    },

    getFormData: function() {
        let data = this.formSerializer();
        if (data.folder_id) {
            // do nothing - folders don't need to send neither building_id or organization_id
        } else if (this.options.buildingId) {
            delete data.folder_id;
            data.building_id = this.options.buildingId;
        } else {
            delete data.folder_id;
            data.organization_id = WiseMetering.currentOrganization.id;
        }
        return data;
    },

    globalDetailedReportSettings: function(type) {
        if (type === 'GlobalDetailedReport') {
            this.showFrequency();
        }
    },

    globalReports: function() {
        let reports = [], utilityKindReports = [], managementReports = [], otherReports = [], customReports = [];

        utilityKindReports.push({ title: 'All UtilityKinds', value: 'GlobalUtilityReport', active: true });
        utilityKindReports.push({ title: 'UtilityKind', value: 'GlobalUtilityReport', active: true });
        reports.push(['Utility', utilityKindReports]);

        managementReports.push({ title: 'Detailed', value: 'GlobalDetailedReport', active: this.hasAccess() });
        reports.push(['Management', managementReports]);

        otherReports.push({ title: 'Events', value: 'GlobalEventsReport', active: true });
        reports.push(['Other', otherReports]);

        if (WiseMetering.currentOrganization.get('subdomain') === 'vodafoneiptv') {
            customReports.push({ title: 'Room Analysis', value: 'GlobalVodafoneReport' });
            reports.push(['Custom', customReports]);
        }
        if (WiseMetering.currentOrganization.get('subdomain') === 'tp' && WiseMetering.currentUser.isSuperUser()) {
            customReports.push({ title: 'TagusPark Custom', value: 'GlobalTagusParkReport' });
            reports.push(['Custom', customReports]);
        }

        return reports;
    },

    globalTagusPark: function(type) {
        if (type === 'GlobalTagusParkReport') {
            this.showFrequency();
        }
    },

    globalVodafoneSettings: function(type) {
        const fileElement = this.$('#report-file-section');
        type === 'GlobalVodafoneReport' ? fileElement.show() : fileElement.hide();
    },

    initializeForm: function() {
        this.$('#report-frequency-section').hide();
        this.$('#report-frequency').val('');
        this.$('#report-frequency option').prop('disabled', false);
        this.$('#report-utility-kind').val('');
        this.$('#report-comparison').val('');
        this.$('#report-file').val('');
        this.$('#report-folder').val('');
        this.$('#report-details').val('');
    },

    isCustomisedReport: function(type) {
        const customisations = ['GlobalTagusParkReport', 'GlobalVodafoneReport'];

        return customisations.includes(type);
    },

    onAddNotification: function() {
        this.$('#notification-list').append(JST['templates/reports/notification']({ userId: null }));
    },

    onRemoveNotification: function(event) {
        $(event.currentTarget).parent().remove();
    },

    onShow: function() {
        this.$('#report-description-section').hide();
        this.initializeForm();

        let language = WiseMetering.currentOrganization.get('language').toLowerCase();
        this.$('#report-language > option[value=\'' + language + '\']').prop('selected', true);
    },

    onTypeChange: function() {
        const type = this.$('#report-type').val();

        this.initializeForm();
        this.setReportDescription(type);
        this.utilityKindReportSettings(type);
        this.folderReportSettings(type);
        this.eventsReportSettings(type);
        this.schemaReportSettings(type);
        this.zoneConsumptionSettings(type);
        this.zoneDetailedReportSettings(type);
        this.globalDetailedReportSettings(type);

        // Custom Reports
        this.globalVodafoneSettings(type);
        this.globalTagusPark(type);
    },

    serializeData: function() {
        const now = moment();
        let folders = [];
        if (this.options.buildingId) {
            folders = WiseMetering.folders.where({ building_id: this.options.buildingId }).map(folder => {
                const id = folder.id;
                let depth = 0, name = folder.get('name'), parent = folder.parent();

                while (parent && parent.type() !== 'building') {
                    name = `${parent.get('name')} > ${name}`;
                    parent = parent.parent();
                    depth += 1;
                }
                return { depth, id, name };
            }).sort((a, b) => {
                // make the ones with depth 0 appear first
                if (a.depth === 0 || b.depth === 0) {
                    if (a.depth === b.depth) {
                        return a.name > b.name ? 1 : -1;
                    }
                    return a.depth > b.depth ? 1 : -1;
                } else {
                    return a.name > b.name ? 1 : -1;
                }
            });
        }

        let reports = this.options.buildingId ? this.zoneReports() : this.globalReports();

        return Object.assign({}, this.model.toJSON(), {
            building_id: this.options.buildingId,
            folders,
            reports,
            utility_kinds: WiseMetering.utilityKinds.map(x => ({ slug: x.get('slug'), name: x.get('name') })),
            frequencies: this.frequencies,
            month: now.month() + 1,
            quarter: now.quarter(),
            year: now.year(),
            years: [now.year(), now.year() - 1, now.year() - 2]
        });
    },

    showFrequency: function() {
        this.$('#report-frequency-section').show();
    },

    utilityKindReportSettings: function(type) {
        const
            option = this.$('#report-type option:selected').text(),
            comparisonElement = this.$('#report-comparison-section'),
            utilityKindElement = this.$('#report-utility-kind-section'),
            isSingleUtilityKind = option === 'UtilityKind';

        ['ZoneUtilityReport', 'GlobalUtilityReport'].includes(type) ? comparisonElement.show() : comparisonElement.hide();
        isSingleUtilityKind ? utilityKindElement.show() : utilityKindElement.hide();
    },

    zoneDetailedReportSettings: function(type) {
        const detailsElement = this.$('#report-details-section');
        type === 'ZoneDetailedReport' ? detailsElement.show() : detailsElement.hide();
    },

    zoneReports: function() {
        return [
            { title: i18next.t('reports.all_utility_kinds'), value: 'ZoneUtilityReport', active: true },
            { title: i18next.t('reports.consumption_analysis'), value: 'ZoneConsumptionReport', active: this.hasAccess() },
            { title: i18next.t('reports.detailed'), value: 'ZoneDetailedReport', active: this.hasAccess() },
            { title: i18next.t('glossary.events'), value: 'ZoneEventsReport', active: true },
            { title: i18next.t('glossary.folder'), value: 'ZoneFolderReport', active: this.hasAccess() },
            { title: i18next.t('reports.schema'), value: 'ZoneSchemaReport', active: this.hasAccess() }
        ];
    },

    setReportDescription: function(type) {
        let reportDescription = i18next.t('reports.common_report');
        const defaultDescription = i18next.t('reports.default_description');

        if (type === 'ZoneUtilityReport' || type === 'ZoneConsumptionReport' || type === 'GlobalUtilityReport') {
            reportDescription = defaultDescription;
        } else if (type === 'ZoneEventsReport' || type === 'GlobalEventsReport') {
            reportDescription = i18next.t('reports.events_report');
        } else if (type === 'ZoneFolderReport') {
            reportDescription = i18next.t('reports.folder_report');
        } else if (type === 'ZoneSchemaReport') {
            reportDescription = i18next.t('reports.schema_report');
        } else if (type === 'GlobalDetailedReport') {
            reportDescription = i18next.t('reports.global_detailed_report');
        } else if (type === 'ZoneDetailedReport') {
            reportDescription = i18next.t('reports.zone_detailed_report');
        } else if (this.isCustomisedReport(type)) {
            reportDescription = i18next.t('reports.customized_report');
        }

        this.$('#report-description-section').show();
        this.$('#report-description-section').html(reportDescription);
    },

    hasAccess: function() {
        return WiseMetering.currentOrganization.hasStandardPermissions();
    }
});
