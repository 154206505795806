WiseMetering.Views.ShowUser = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/users/show',
    className: 'mbx-info text-left',
    form_prefix: 'user',
    width: 500,
    title: () => i18next.t('modal.title.users.show'),

    buttons: function() {
        const buttons = ['close'];
        if (WiseMetering.currentUser.get('administrator')) {
            buttons.push('edit');
        }
        return buttons;
    },

    edit: function() {
        const that = this;
        that.block();
        _.defer(function() {
            new WiseMetering.Views.EditUser({ model: that.model });
        });
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json.buildings = this.model.buildings().pluck('name').join(', ');
        json.groups = this.model.groups().pluck('name').join(', ');
        return json;
    }
});
