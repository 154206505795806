WiseMetering.Views.IndexAlarm = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/layout/empty',

    onShow: function() {
        let columns;
        columns = {
            active: {
                mRender: active => JST['templates/ui/components/circle']({
                    color: WiseMetering.Colors[active ? 'green' : 'red'],
                    title: i18next.t(`glossary.${active ? 'active' : 'disabled'}`)
                }),
                sWidth: '20px'
            },
            name: i18next.t('glossary.title'),
            type: i18next.t('common.type'),
            schedule: i18next.t('ui.table.schedule_name'),
            indicator: i18next.t('glossary.indicator'),
            notifications: i18next.t('glossary.notifications')
        };

        if (WiseMetering.currentUser.isSuperUser()) {
            columns.last_checked_humanized = i18next.t('ui.table.last_checked');
        }

        this.table = new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: WiseMetering.alarms,
            columns: columns,
            id: 'alarms',
            modelSerializer: function(model) {
                return {
                    ...model.toJSON(),
                    indicator: model.indicator() ? model.indicator().get('name') : null,
                    last_checked_humanized: model.last_checked_humanized(),
                    notifications: model.notifications().sort().join(', '),
                    schedule: WiseMetering.alarmSchedules.get(model.get('schedule_id')).get('name')
                };
            },
            toolbar_actions: {
                show: {
                    label: 'Show',
                    view_call: model => new WiseMetering.Views.ShowAlarm({ model })
                },
                open_indicator: {
                    icon: 'indicators',
                    label: 'Indicator',
                    view_call: model => {
                        const href = WiseMetering.indicators.get(model.get('indicator_id')).nodeUrl();
                        Backbone.history.navigate(href, { trigger: true });
                    }
                },
                edit: {
                    view_call: model => new WiseMetering.Views.EditAlarm({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.alarms.destroy')
                    })
                }
            }
        });

        $(this.el).html(this.table.el);
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    }
});
