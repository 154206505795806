(function ($) {

    $.err= function (options) {
        return $.mbx.def.init($('body'), options);
    };

    $.err.clear= function () {
        $.err.def.clear(true);
    };

    $.fn.err= function(options) {
        return $.err.def.init(this, options);
    };

    $.err.def= {
        init: function (element, options) {
            this.clear();
            this.element = element;
            this.label_prefix = options.label_prefix || '';
            this.errors = options.errors || {};
            this.title = options.title || i18next.t('ui.please_try_again');
            this.buildAndAppendErrorTitle();
            this.buildAndMarkErrors();
            this.show();
            return this;
        },
        buildAndAppendErrorTitle: function(){
            var text = this.title;
            try{
                text = this.errors.error;
            } catch(e){}
            this.element.html($('<div>').addClass('error crn_all').html(text));
        },
        buildAndMarkErrors:function(){
            if (this.errors.adhoc !== null && _.size(this.errors) > 0){
                for (var i in this.errors.adhoc) {
                    this.addErrorAt(this.label_prefix + '_' + this.errors.adhoc[i][0], this.errors.adhoc[i][1]);
                }
            }
        },
        addErrorAt:function(name, error){
            target = $('label[for=' + name + ']');
            target.addClass("err").attr({'data-error':('stt_err:' + error)}).css({ color: '#cc0000' });
            target.mouseover(function(){ $.stt({target:$(this), style:"info", text:this.getAttribute("data-error"), direction:"down"}); });
            target.mouseout(function(){ $("div.stt").remove(); });
        },
        clear:function(){
            $('div.error').remove();
            $('label.err'+(this.label_prefix ? '[for^="'+this.label_prefix+'"]' : '')).removeClass('err').removeAttr('data-error').removeAttr('style');
        },
        show:function(){
            this.element.show();
            $.mbx.resize();
        }
    };
})(jQuery);


