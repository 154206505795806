WiseMetering.Views.Ui.Widget.Benchmark = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/benchmark',
    self_value: null,
    kpi_unit: '',
    colors: WiseMetering.WidgetColors.Benchmarking,

    initialize: function(params) {
        params.title = i18next.t('glossary.benchmark');
        WiseMetering.Views.Ui.Widget.Base.prototype.initialize.call(this, params);
    },

    drawChart: function() {
        const that = this;

        if (this.kpi) {
            const initials = this.kpi.unit().get('symbol');
            this.kpi_unit = `/${initials}`; // replace(/[^A-Z]/g, '');
            this.$('#title').html(`${i18next.t('widgets.benchmark_per', { name: this.kpi.get('name') })} (${initials})`);
        }

        let self = [];
        let others = [];
        let categories = [];

        this.data.data.forEach(b => {
            const first = b.at(0);
            const last = b.at(-1);
            if (first === this.self) {
                this.self_value = last;
            }
            categories.push(first);
            self.push(first === this.self ? last : null);
            others.push(first === this.self ? null : last);
        });

        that.chart = new Highcharts.Chart(jQuery.extend(true, {}, that.defaultChartOptions, {
            chart: { marginLeft: 60 },
            xAxis: {
                categories: categories,
                lineColor: '#797979'
            },
            yAxis: {
                labels: {
                    formatter: function() {
                        return that.kpi ? formatValue(this.value, 'Wh') + that.kpi_unit : formatValue(this.value, 'Wh');
                    }
                }
            },
            plotOptions: {
                column: { groupPadding: 0 },
                series: { stacking: 'normal' }
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                useHTML: true,
                labelFormatter: function() {
                    this.legendItem.symbol.hide();

                    const consumption = that.data.data.find(d => d[0] === that.self)[1];
                    return `
                        <div class="legend">
                            <div class="title" style="background:${that.colors[1]};">
                                ${i18next.t('common.position')}
                            </div>
                            <div class="position value no-comparison" style="line-height: 21px; margin-bottom: 10px;">
                                ${i18next.t('widgets.out_of', { position: that.data.pos, total: that.data.tot })}
                            </div>
                            <div class="title" style="background-color:${that.colors[1]}">
                                ${i18next.t('common.consumption')}
                            </div>
                            <div class="consumption value no-comparison">
                                ${that.kpi ? formatValue(consumption, 'Wh') + that.kpi_unit : formatValue(consumption, 'Wh')}
                            </div>
                        </div>
                    `;
                }
            },
            tooltip: {
                formatter: function() {
                    var self = that.self_value;
                    return (this.y !== self) ? '<span style="font-weight:bold; color:' + that.colors[0] + '">' + this.x + '</span> ' + formatValue(this.y, 'Wh') + that.kpi_unit + ' <span style="font-weight:bold; color:' + (this.y - self > 0 ? WiseMetering.SemaphorColors.red : WiseMetering.SemaphorColors.green) + '">(' + (this.y - self > 0 ? '+' : '') + formatValue((this.y - self) / self * 100, '%') + ')</span>' : '<span style="font-weight:bold; color:' + that.colors[1] + '">' + this.x + '</span> ' + formatValue(this.y, 'Wh');
                }
            },
            series: [
                { type: 'column', data: others, color: that.colors[0] },
                { type: 'column', data: self, color: that.colors[1], linkedTo: ':previous' }
            ],
            exporting: {
                allowHTML: true,
                chartOptions: {
                    title: { text: that.title }
                }
            }
        }));
    }
});
