WiseMetering.Views.UtilitiesCosts = Backbone.Marionette.Layout.extend({
    template: 'templates/utilities/costs',
    regions: {
        container: '#utility-costs-container'
    },

    onShow: function() {
        const utilities = WiseMetering.utilities.where({ building_id: this.model.building().id, utility_kind_id: WiseMetering.utilityKinds.findWhere({ slug: 'electricity' }).id });

        const minDate = utilities.map(utility => utility.start()).reduce((min, c) => c < min ? c : min);
        const maxDate = utilities.map(utility => utility.finish()).reduce((max, c) => c > max ? c : max);

        const costView = new WiseMetering.Views.Costs({
            maxDate,
            minDate,
            model: this.model.building(),
            withUtilityKind: true
        });

        this.container.show(costView);
    }
});
