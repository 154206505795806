WiseMetering.Views.ShowAlarmSchedule = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/alarm_schedules/show',
    width: 1020,
    ui: {
        schedule: '#schedule'
    },
    title: () => i18next.t('modal.title.alarm_schedules.show'),

    buttons: function() {
        return ['edit', 'close'];
    },

    edit: function() {
        this.block();
        _.defer(function() {
            new WiseMetering.Views.EditAlarmSchedule({ model: this.model });
        }.bind(this));
    },

    onRender: function() {
        const schedule = new WiseMetering.Views.AlarmSchedule({
            periods: this.model.periods(),
            schedule: this.model.schedule()
        });

        this.ui.schedule.append(schedule.el);
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json['alarms'] = this.model.alarms();
        return json;
    }
});
