WiseMetering.Views.IndexOpportunity = WiseMetering.Views.TableIndex.extend({
    icon: 'opportunities',
    name: () => i18next.t('glossary.opportunities'),

    createTable: function createTable() {
        const collection = WiseMetering.opportunities.findByBuildingId(this.model.id);

        this.listenTo(collection, 'add', function(opportunity) {
            WiseMetering.opportunities.add(opportunity);
        });

        return new WiseMetering.Views.Table({
            id: 'opportunity',
            collection: collection,

            modelSerializer: model => {
                const
                    json = model.toJSON(),
                    timezone = WiseMetering.zones.get(json.building_id).get('timezone'),
                    utilityKind = WiseMetering.utilityKinds.get(json.utility_kind_id),
                    creator = WiseMetering.users.get(json.created_by);

                json.utilityKindName = i18next.t(`utility_kinds.${utilityKind.get('slug')}`).capitalize();
                json.capex = Lmit.Utils.formatNumber(json.capex, WiseMetering.getCurrency(), 0);
                json.created = WiseMetering.utils.formatDate(json.created_at, timezone, 'YYYY/MM/DD HH:mm');
                json.consumption = Lmit.Utils.formatNumber(json.consumption, utilityKind.unit(), 2);
                json.cost = Lmit.Utils.formatNumber(json.cost, WiseMetering.getCurrency(), 0);
                json.creatorName = creator ? creator.get('name') : 'System';
                json.file = json.file && model.fileLink();
                json.status = model.states[model.get('state')];
                json.payback = model.payback();
                return json;
            },
            columns: {
                status: {
                    title: i18next.t('common.state'),
                    className: 'center',
                    width: '5%',
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({ color, title })
                },
                file: {
                    title: i18next.t('common.file'),
                    className: 'table-icon center file',
                    width: '5%',
                    mRender: function(file) {
                        if (!file) {
                            return '';
                        }
                        return `<a class='link' href='${file}' target='_blank'>${WiseMetering.icon('file')}</a>`;
                    }
                },
                title: {
                    title: i18next.t('glossary.title'),
                    width: '20%'
                },
                priority: {
                    title: i18next.t('common.priority'),
                    className: 'center'
                },
                utilityKindName: {
                    title: i18next.t('glossary.utility_kind'),
                    className: 'center',
                    width: '10%'
                },
                consumption: {
                    title: i18next.t('opportunities.consumption_savings'),
                    className: 'align-right',
                    width: '10%'
                },
                cost: {
                    title: i18next.t('opportunities.cost_savings'),
                    className: 'align-right',
                    width: '10%'
                },
                capex: {
                    title: i18next.t('common.capex'),
                    className: 'align-right',
                    width: '10%'
                },
                payback: {
                    title: i18next.t('common.payback'),
                    className: 'align-right',
                    width: '10%'
                }
            },
            sort: {
                column: 'created',
                order: 'desc'
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.AddOpportunity({
                        collection: collection,
                        indicators: new WiseMetering.Collection.Indicators,
                        model: new WiseMetering.Model.Opportunity({ building_id: this.model.id }),
                        type: 'new'
                    })
                },
                edit: {
                    view_call: model => new WiseMetering.Views.EditOpportunity({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.DestroyOpportunity({ model })
                },
                show: {
                    view_call: model => new WiseMetering.Views.OpportunityModalDetails({ model })
                }
            }
        });
    }
});
