module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dt>\n    <label for="operation-type">' +
((__t = ( i18next.t('indicators.operation_type') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'operation-type\'>\n        <option disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n        <option value=\'clone\'>' +
((__t = ( i18next.t('indicators.clone') )) == null ? '' : __t) +
'</option>\n        <option value=\'aggregation\'>' +
((__t = ( i18next.t('indicators.aggregation') )) == null ? '' : __t) +
'</option>\n        <option value=\'advanced\'>' +
((__t = ( i18next.t('indicators.advanced') )) == null ? '' : __t) +
'</option>\n    </select>\n</dd>\n<div id=\'operation-container\'></div>\n';

}
return __p
}