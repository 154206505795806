WiseMetering.Model.AlarmSchedule = WiseMetering.Model.extend({
    urlRoot: '/alarm_schedules',

    alarms: function() {
        return WiseMetering.alarms.where({ schedule_id: this.get('id') });
    },

    periods: function() {
        return this.get('periods').filter(period => period !== 'off');
    },

    schedule: function() {
        return WiseMetering.weekdays.reduce((schedule, weekday) => ({ ...schedule, [weekday]: this.get(weekday) }), {});
    }
});
