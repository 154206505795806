import * as React from 'react';
import { Icon } from '@/components/wm/';

const StandaloneLink = ({ onAction, loading = false, icon, text, href }) => {
    const handleClick = (e) => {
        if (onAction && !loading) {
            e.preventDefault();
            onAction();
        } else if (loading) {
            e.preventDefault();
        }
    };

    return (
        <a
            href={href}
            onClick={handleClick}
            aria-disabled={loading}
            className={
                `
                tw-text-primary hover:tw-underline tw-cursor-pointer tw-font-bold tw-text-md !tw-mt-4 tw-inline-flex tw-items-center
                ${loading ? 'tw-text-gray-500 tw-cursor-not-allowed hover:tw-text-gray-500' : ''}
                `
            }
            target="_blank"
        >
            {icon && <Icon icon={icon}/>}
            { text }
        </a>
      );
};

StandaloneLink.displayName = 'StandaloneLink';

export { StandaloneLink }
