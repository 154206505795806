WiseMetering.Model.Tariff = WiseMetering.Model.extend({
    urlRoot: '/tariffs',

    buildings: function() {
        const building_ids = this.contracts().pluck('building_id'),
              zones = building_ids.map(building_id => WiseMetering.zones.get(building_id));

        return new WiseMetering.Collection.Zones(zones);
    },

    contracts: function() {
        let contracts = WiseMetering.contracts.filter(contract => contract.get('tariff_id') === this.id);

        return new WiseMetering.Collection.Contracts(contracts);
    },

    nodeUrl: function() {
        return `/${WiseMetering.currentOrganization.get('subdomain')}/tariffs?search=${encodeURIComponent(this.name())}`;
    },

    tariffType: function() {
        return WiseMetering.tariffTypes.get(this.get('tariff_type_id'));
    },

    template: function() {
        return this.tariffType().template();
    },

    tenants: function() {
        const tenants = new Set();

        WiseMetering.allocations.forEach(allocation => {
            if (allocation.get('tariff_id') === this.id) {
                tenants.add(WiseMetering.tenants.get(allocation.get('tenant_id')));
            }
        });

        return new WiseMetering.Collection.Tenants([...tenants]);
    },

    utilityKind: function() {
        return WiseMetering.utilityKinds.get(this.get('utility_kind_id'));
    },

    costs(opts, handler) {
        const default_params = {
            from: Math.floor(+moment().startOf('year') / 1000),
            to: Math.floor(+moment().startOf('day') / 1000)
        };

        return $.ajax({
            url: this.url() + '/costs',
            type: 'GET',
            data: Object.assign({}, default_params, opts),
            success: handler
        });
    }
});
