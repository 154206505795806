WiseMetering.Views.InvoicingDetailsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/tenants/invoicing',

    ui: {
        invoiceIsDetailedSwitcher: '#invoice-is-detailed-switcher',
        invoiceIsVatSwitcher: '#invoice-is-vat-switcher',
        invoiceModeSwitcher: '#invoice-mode-switcher'
    },

    events: {
      'change input#invoice-is-detailed-switcher': 'onChangeIsDetailed',
      'change input#invoice-is-vat-switcher': 'onChangeIsVat',
      'change input#invoice-mode-switcher': 'onChangeMode'
    },

    modelEvents: {
        'change': 'render'
    },

    serializeData: function () {
        const json = this.model.toJSON();
        json.isPerArea = this.model.get('mode') === 'area';
        return json
     },

    onChangeIsDetailed: function (event) {
        event.preventDefault();
        this.model.save({is_detailed: this.ui.invoiceIsDetailedSwitcher.is(':checked')}, {
            patch: true,
            error: function () {
                this.ui.invoiceIsDetailedSwitcher.prop("checked", false);
            }.bind(this)
        })
    },

    onChangeIsVat: function (event) {
        event.preventDefault();
        this.model.save({is_vat: this.ui.invoiceIsVatSwitcher.is(':checked')}, {
            patch: true,
            error: function () {
                this.ui.invoiceIsVatSwitcher.prop("checked", false);
            }.bind(this)
        })
    },

    onChangeMode: function (event) {
        event.preventDefault();
        const isPerArea = this.ui.invoiceModeSwitcher.is(':checked');
        this.model.save({mode: isPerArea ? 'area' : 'consumption'}, {
            patch: true,
            wait: true,
            error: function () {
                this.ui.invoiceModeSwitcher.prop("checked", false);
                WiseMetering.layout.showTipper('error', i18next.t('tenants.invalid_area_error'));
            }.bind(this)
        })
    }
});
