import * as React from 'react';
import { useEffect, useState } from 'react';
import { AlertBox, Button, Icon } from '@/components/wm';

export function PasswordRequirements({ confirmPassword, newPassword, onPasswordChange, onRequirementsMet }) {
    const [passwordRequirements, setPasswordRequirements] = useState({});

    const allRequirementsMet = Object.values(passwordRequirements).every(req => req.met);

    useEffect(() => {
        const requirements = WiseMetering.utils.validatePassword(newPassword, confirmPassword);
        setPasswordRequirements(requirements);
        onRequirementsMet(allRequirementsMet); // Notify parent when requirements are met or not.
    }, [newPassword, confirmPassword, allRequirementsMet, onRequirementsMet]);

    const handleGeneratePassword = () => {
        const generatedPassword = WiseMetering.utils.generatePassword();
        onPasswordChange(generatedPassword, confirmPassword);
    };

    return (
        <AlertBox active={true} variant="info" title={ (i18next.t('ui.passwords.requirements.title') || "Password requirements") }>
            <div>
                <ul className="tw-space-y-2 tw-list-none tw-p-0 tw-m-0 tw-mt-2">
                    {Object.entries(passwordRequirements || {}).map(([key, { met, message }]) => (
                        <li key={key} className="tw-flex tw-items-center tw-w-full tw-text-xs">
                            {met ? (
                                <Icon icon="circleCheck" size="small" color="success" />
                            ) : (
                                <Icon icon="exclamationCircle" size="small" color="destructive" />
                            )}
                            <span className="tw-ml-2">{message}</span>
                        </li>
                    ))}
                </ul>
                <Button
                    className="tw-w-full tw-mt-3"
                    variant="info"
                    onClick={handleGeneratePassword}
                >
                    {(i18next.t('ui.passwords.generate') || "Generate Password")}
                </Button>
            </div>
        </AlertBox>
    );
}
