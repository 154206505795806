WiseMetering.Model.CircuitTag = WiseMetering.Model.extend({
    urlRoot: '/circuit_tags',

    buildings: function() {
        const buildings = _.collect(WiseMetering.circuits.where({ tag_id: this.id }), function(circuit) {
            return WiseMetering.zones.get(circuit.get('building_id'));
        });

        return new WiseMetering.Collection(_.uniq(buildings));
    }
});
