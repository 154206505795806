WiseMetering.Model.UtilityKind = WiseMetering.Model.extend({
    shortName: function() {
        return this.get('name').toLowerCase().replace(/ /g, '_');
    },

    unit: function() {
        return this.get('unit') === 'kWh' ? 'Wh' : this.get('unit');
    },

    indicatorKindSlug: function() {
        let slug = this.get('slug');
        if (slug === 'electricity') {
            slug = 'active_energy';
        }
        return slug;
    }
});
