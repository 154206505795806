WiseMetering.Views.AggregationOperation = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/operations/aggregation',

    ui: {
        operation: '#indicator-operation',
        search: 'input#indicator-search'
    },

    events: {
        'keyup input#indicator-search': 'search',
        'click #indicators-list': 'updateOperation',
        'click #set-advanced': 'setAdvanced'
    },

    setAdvanced: function() {
        const view = new WiseMetering.Views.AdvancedOperation({ modal: this.options.modal, model: this.model });
        $('#type-container').html(view.render().el);
    },

    onRender: function() {
        this.search();
    },

    search: function(event) {
        if (event && (event.keyCode || event.which) !== 13) {
            return;
        }

        const
            buildingId = this.model.get('building_id'),
            kindId = this.model.get('kind_id'),
            usedIndicatorIds = this.model.get('operation')?.match(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g) || [];

        let indicators = WiseMetering.indicators.map(indicator => indicator.attributes).filter(indicator => usedIndicatorIds.includes(indicator.id) || indicator.kind_id === kindId);
        indicators.sort((a, b) => {
            if (usedIndicatorIds.includes(a.id) === usedIndicatorIds.includes(b.id)) {
                return a.name > b.name;
            }

            return usedIndicatorIds.includes(b.id) ? 1 : -1;
        });

        if (buildingId !== null) {
            indicators = indicators.filter(indicator => indicator.building_id === buildingId);
        }

        // Name filter
        let name = this.ui.search.val();
        // Inspired by http://stackoverflow.com/a/9310752/1787187
        if (name) {
            name = new RegExp(name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
            indicators = indicators.filter(indicator => usedIndicatorIds.includes(indicator.id) || indicator.name.match(name));
        }

        let options = '';
        let indicatorsList = this.$('#indicators-list');

        if (indicators.length) {
            indicators.forEach(indicator => {
                let checked = usedIndicatorIds.includes(indicator.id);

                options += `<input class="vertical-middle" ${checked ? 'checked' : ''} id="aggregation-indicator-${indicator.id}" name="indicators[]" type="checkbox" value="${indicator.id}">`;
                options += `&nbsp;<label for='aggregation-indicator-${indicator.id}'>${indicator.name}</label><br/>`;
            });
        } else {
            options += 'No indicators found';
        }

        indicatorsList.html(options);
        this.options.modal.redraw();

        this.updateOperation();
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.isNew = this.model.isNew();
        return json;
    },

    updateOperation: function() {
        let operation = '';

        this.$('input[name="indicators[]"]:checked').each(function() {
            if (operation) {
                operation += '+';
            }
            operation += $(this).val();
        });

        this.ui.operation.val(operation);
    }
});
