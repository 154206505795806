WiseMetering.Views.ZoneDetailsAccordion = Backbone.Marionette.ItemView.extend({
    events: {
        'click div#edit_details': 'editDetails'
    },
    modelEvents: {
        'change': 'render'
    },
    template: 'templates/zones/details',

    serializeData: function() {
        const timezone = this.model.get('timezone');
        let model = {
            address: this.model.get('address'),
            area: formatValue(this.model.get('area'), 'm²'),
            capacity: this.model.get('capacity') ? formatValue(this.model.get('capacity')) + ' persons' : null,
            category: this.model.category().get('name'),
            description: this.model.get('description'),
            image_url: this.model.get('image_url'),
            isBuilding: this.model.isBuilding(),
            key: 'AIzaSyBN4-S671Jhd7sykFpWC9xmSdr0brFE-c4',
            name: this.model.get('name'),
            tag_names: this.model.get('tag_names'),
            tariff: this.model.electricity_tariff() ? this.model.electricity_tariff().get('name') : null,
            timezone: timezone
        };

        return model;
    },

    editDetails: function() {
        new WiseMetering.Views.ZoneEdit({ title: 'Edit ' + this.model.get('name'), model: this.model });
    }
});
