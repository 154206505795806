WiseMetering.Views.EditOpportunity = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/opportunities/edit',
    title: () => i18next.t('modal.title.opportunities.edit'),
    form_prefix: 'opportunity',
    className: "mbx-info text-left",

    ui: {
        completedAt: 'input#completed-at',
        fileLink: '#file-link',
        removeFile: '#remove-file',
        spinner: '#spinner',
        uploadFile: '#upload-file'
    },

    events: {
        'change #upload-file': 'onUploadFile',
        'click #remove-file': 'onRemoveFile'
    },

    modelEvents: {
        'change': 'render'
    },

    serializeData: function() {
        const json = this.model.toJSON();

        json.availableUtilityKinds = this.model.building().availableUtilityKinds();
        json.completed_at = json.completed_at ? WiseMetering.utils.formatDate(json.completed_at, this.model.timezone(), 'YYYY/MM/DD HH:mm') : null;
        if ( WiseMetering.utilityKinds.get(json.utility_kind_id).unit() === 'Wh' ) {
            json.consumption = json.consumption/1000
        }
        json.file_link = this.model.fileLink();
        json.form_prefix = this.form_prefix;
        json.indicators = this.model.indicators();
        json.payback = this.model.payback();
        return json;
    },

    getFormData: function() {
        let data = this.formSerializer();
        if (WiseMetering.utilityKinds.get(data.utility_kind_id).get('unit') === 'kWh') {
            data.consumption = data.consumption * 1000;
        }
        return data;
    },

    onRemoveFile: function() {
        this.ui.fileLink.hide();
        this.ui.removeFile.hide();
        this.ui.spinner.show();
        $('.toolbar_button').attr('disabled', 'disabled')
        WiseMetering.layout.showTipper('info', i18next.t('ui.removing_file'), false);

        this.model.removeFile().done(function() {
            WiseMetering.layout.showTipper('success', i18next.t('ui.file_removed_successfully'));
            $('.toolbar_button').removeAttr('disabled', 'disabled')
        }.bind(this));
    },

    onShow: function() {
        this.ui.completedAt.datetimepicker('destroy');
        this.ui.completedAt.datetimepicker({
            changeMinute: false,
            changeMonth: true,
            changeYear: true,
            dateFormat: 'yy-mm-dd',
            maxDate: moment().endOf('day').toDate(),
            minDate: moment().add(-2, 'years').startOf('year').toDate(),
            showOtherMonths: true,
            timeFormat: 'HH:00',
            onSelect: function(dateText) {
                this.ui.completedAt.val(dateText);
            }.bind(this)
        });
    },

    onUploadFile: function() {
        const file = this.ui.uploadFile.prop('files')[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            WiseMetering.layout.showTipper('info', i18next.t('ui.uploading_file'), false);
            this.ui.spinner.show();
            this.ui.uploadFile.hide();
            $('.toolbar_button').attr('disabled', 'disabled');

            this.model.uploadFile(formData).done(() => {
                WiseMetering.layout.showTipper('success', i18next.t('ui.file_uploaded_successfully'));
                $('.toolbar_button').removeAttr('disabled', 'disabled');
            }).fail((response) => {
                let message = JSON.parse(response.responseText)?.errors?.file || i18next.t('ui.file_upload_error');
                WiseMetering.layout.showTipper('error', message, 5000);
            });
        }
    }
});
