import React from 'react';

import MessageDetails from './MessageDetails';
import MessageList from './MessageList';

export default class extends React.Component {
    constructor(args) {
        super(args);
        this.state = {
            selectedMessageId: null,
            currentSection: 'inbox'
        };
    }

    componentDidMount() {
        this.props.messages.on('add change remove', this.handleChange.bind(this));
    };

    componentWillUnmount() {
        this.props.messages.off('add change remove', this.handleChange.bind(this));
    };

    handleChange() {
        this.forceUpdate();
    }

    openMessage(id) {
        const message = this.props.messages.get(id);
        if (!message.get('read')) {
            message.save({ read: true }, { patch: true });
        }
        this.setState({ selectedMessageId: message.id });
    };

    deleteMessage(id) {
        let message = this.props.messages.get(id);
        message.destroy();

        const nextMessage = this.props.messages.first();

        if (nextMessage) {
            this.openMessage(nextMessage.id);
        } else {
            this.setState({ selectedMessageId: null });
        }
    };

    render() {
        const currentMessage = this.props.messages.find(message => message.id === this.state.selectedMessageId);
        return (
            <div id="messages">
                <MessageList
                    messages={this.props.messages}
                    onMessageSelected={id => this.openMessage(id)}
                    selectedMessageId={this.state.selectedMessageId}
                    currentSection={this.state.currentSection}
                />
                <MessageDetails
                    message={currentMessage}
                    onDelete={id => this.deleteMessage(id)}
                />
            </div>
        );
    };
}
