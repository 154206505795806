module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'etl_dsn\'>' +
((__t = ( i18next.t('etls.cpe') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input disabled class=\'text\' id=\'etl_dsn\' value=\'' +
((__t = ( dsn )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for=\'etl_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'etl_name\' name=\'etl[name]\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt style=\'margin-bottom: 0.5em;\'>\n            <label for=\'etl_consent\'>' +
((__t = ( i18next.t('etls.consent') )) == null ? '' : __t) +
'?</label>\n            <input ' +
((__t = ( consent ? 'checked disabled' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'etl_consent\' name=\'etl[consent]\' type=\'checkbox\' >\n        </dt>\n    </dl>\n</form>\n';

}
return __p
}