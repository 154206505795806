module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div style=\'margin-top: 5px;\'>\n    <input class=\'zone_attribute_value_from\' maxlength=\'10\' name=\'zone_attribute_value[values][' +
((__t = ( index )) == null ? '' : __t) +
'][from]\' placeholder=' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
' type=\'text\' value="' +
((__t = ( from )) == null ? '' : __t) +
'">\n    <input class=\'zone_attribute_value_to\' maxlength=\'10\' name=\'zone_attribute_value[values][' +
((__t = ( index )) == null ? '' : __t) +
'][to]\' placeholder=' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
' type=\'text\' value="' +
((__t = ( to )) == null ? '' : __t) +
'">\n    <input class=\'zone_attribute_value\' name=\'zone_attribute_value[values][' +
((__t = ( index )) == null ? '' : __t) +
'][value]\' placeholder=' +
((__t = ( i18next.t('common.value') )) == null ? '' : __t) +
' type=\'text\' value=\'' +
((__t = ( value )) == null ? '' : __t) +
'\'> ' +
((__t = ( symbol )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}