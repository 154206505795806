WiseMetering.Views.NewERedesEtl = Backbone.Marionette.ItemView.extend({
    template: 'templates/etls/types/e_redes',

    serializeData: function() {
        return {
            buildings: WiseMetering.zones.buildings(),
            rate: WiseMetering.etlRates[0],
            start_at: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            username: 'eredes',
            secret: 'eredes',
            mode: 'local'
        }
    }
});
