module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class="stepped zero">\n        <input id=\'zone_latitude\' name=\'address[latitude]\' type=\'hidden\' value=\'' +
((__t = ( latitude )) == null ? '' : __t) +
'\'>\n        <input id=\'zone_longitude\' name=\'address[longitude]\' type=\'hidden\' value=\'' +
((__t = ( longitude )) == null ? '' : __t) +
'\'>\n        <dt>\n            <label for=\'address\'>Address</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'address\' placeholder=\'Please insert an address and press enter to search\' style=\'width: 536px;\' value="' +
((__t = ( full_address )) == null ? '' : __t) +
'">\n        </dd>\n        <dt>\n            <label>Map</label>\n        </dt>\n        <dd>\n            <div id=\'map_canvas\' style=\'height:400px;\'></div>\n        </dd>\n        <dd class=\'last\'>\n            Please drag the marker into the exact position.\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}