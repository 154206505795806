WiseMetering.Views.EditEtl = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'etl',
    template: 'templates/etls/edit',
    title: () => i18next.t('modal.title.etls.edit'),
    input_readers: [{
        type: 'default', func: function(el) {
            return el.val() === '' ? null : el.val();
        }
    }],

    getFormData: function() {
        let data = this.formSerializer();
        if (!data.secret) {
            delete data.secret;
        }

        return data;
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.etl_rates = WiseMetering.etlRates.reduce((a, v) => ({ ...a, [v]: this.model.getHumanEtlRate(v) }), {});
        return json;
    }
});
