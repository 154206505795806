module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion-group\'>\n    <div class=\'accordion-heading\'>\n        <div class=\'accordion-toggle\'>\n            <div class=\'svg_icon\'>\n                ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n            </div>\n            ' +
((__t = ( i18next.t('indicators.indicator_attributes') )) == null ? '' : __t) +
'\n            ';
 if(WiseMetering.currentUser.get('manager')){ ;
__p += '\n                <div class=\'wmui-btn\' id=\'edit_attributes\' style=\'float: right;\'>' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'</div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n    <div class=\'accordion-body\'>\n        <div class=\'accordion-inner\'>\n            <div class=\'mbx-info text-left\' id=\'indicator-details-content\' style="border: none; padding: 0;">\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}