module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <input name=\'circuit_tag[organization_id]\' type=\'hidden\' value=\'' +
((__t = ( WiseMetering.currentOrganization.id )) == null ? '' : __t) +
'\'>\n    <dl class=\'stepped zero\'>\n    <dt>\n      <label for=\'circuit_tag_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n    </dt>\n    <dd>\n      <input class=\'text\' id=\'circuit_tag_name\' name=\'circuit_tag[name]\' type=\'text\'>\n    </dd>\n    <dt>\n      <label for=\'circuit_tag_system_category_id\'>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</label>\n    </dt>\n    <dd>\n      <select class=\'text\' id=\'circuit_tag_system_category_id\' name=\'circuit_tag[system_category_id]\'></select>\n    </dd>\n  </dl>\n</form>\n';

}
return __p
}