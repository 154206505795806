// Wise Indicator
Lmit.Wise.Chart.Compare = function(_controller_, _container_, _options_) {
    this.options = _options_;
    this.controller = _controller_;
    this.container = _container_;
    // view elements
    this.view = null;
    this.url = null;
    this.collection = null;
    this.itemsView = null;
    this.MAX_RESULTS = 10;
    this.searchPanel = null;
    this.searchResults = null;
    this.searchField = null;
    this.items = null;
    this.observers = null;
    //defaults
    this.maxFields = 6;
    this.init();
};

Lmit.Wise.Chart.Compare.prototype = {
    init: function() {
        this.view = $('<div>').addClass('wmui-chart-compare');
        this.observers = [];
        this.items = [];
        this.container.append(this.view);
        this.build();
        this.buildSearchPanel();
        this.bindEvents();
    },

    register: function(item) {
        this.observers.push(item);
        return true;
    },

    notifyObservers: function(state) {
        var _this_ = this;
        _.each(_this_.observers, function(item) {
            item.onCompareChange(state, _this_.items.length);
        });
    },

    build: function() {
        const
            element = $('<ul>').addClass('ctrl'),
            comparison = $('<li>').addClass('label').addClass('comparison').text(`${i18next.t('common.comparison')}:`);

        element.append(comparison);

        this.searchField = $('<input>').attr({
            placeholder: i18next.t('indicators.search_placeholder'),
            type: 'textfield',
            value: '',
            maxlength: 255,
            name: 'chart-compare'
        });

        const search = $('<li style="margin:2px !important">').addClass('regular border').append(this.searchField);
        element.append(search);

        this.itemsView = $('<ul>').addClass('items');
        element.append($('<li>').append(this.itemsView));
        this.view.append(element);
    },

    attrs: function(args) {
        if (args.period) {
            this.period = args.period;
        }
    },

    addItem: function(info, delegate, fetch, allowNav) {
        if (info.building_name) {
            info.name = info.name + ' [' + info.building_name + ']';
        }

        info.code = Lmit.Utils.generateCode();
        let item = $('<li>').attr({ 'data-code': info.code });

        if (info.color) {
            item.css({ 'background-color': new Lmit.Color(info.color).rbgWithAlpha(0.05) });
        }
        item.append($('<input>').attr({
            type: 'checkbox',
            name: 'overlay_indicator',
            value: 'true',
            id: 'RF' + info.code
        }));
        item.append($('<label>').attr({ 'for': 'RF' + info.code }).text(info.name));

        item.append('<div class="wmui-icon rm"><i class="fa fa-times" aria-hidden="true"></i></div>');
        this.items.push(info);
        if (info?.kind !== 'cost') {
            this.itemsView.append(item);
        }
        this.bindItemEvent(item, info, delegate, fetch, allowNav);

        this.notifyObservers('added');
        return info.code;
    },

    bindItemEvent: function(item, args, delegate, fetch, allowNav) {
        let that = this;
        item.children('input[type=checkbox]').change(function() {
            if (this.checked) {
                that.yieldItemData(args);
                if (delegate?.onCheck) {
                    delegate.onCheck(item);
                }
            } else {
                that.releaseItemData(args.code);
                if (delegate?.onUncheck) {
                    delegate.onUncheck(item);
                }
            }
        });
        if (fetch) {
            item.children('input[type=checkbox]').attr({ checked: 'checked' });
            this.yieldItemData(args);
        }
        if (allowNav) {
            item.children('label').dblclick(function(e) {
                that.controller.indicatorController.open({ id: args.id, name: args.name, kind: args.kind });
                if (delegate && delegate.onOpen) {
                    delegate.onOpen(item, e);
                }
            });
        }
        item.children('div.rm').click(function(e) {
            $(this).parent('li').remove();
            that.releaseItemData(args.code);
            that.removeItem(args);
            if (delegate && delegate.onRemove) {
                delegate.onRemove(item);
            }
            e.preventDefault();
        });
    },

    removeItemByCode: function(code) {
        let item = $(this.itemsView).find('li[data-code=' + code + ']');
        this.releaseItemData(code);
        item.remove();
    },

    yieldItemData: function(args) {
        const params = {
            id: args.id,
            unit: args.unit,
            kind: args.kind,
            tariff_id: args.tariff || null,
            name: args.name,
            rate: args.rate,
            operation: args.operation,
            code: args.code,
            color: args.color,
            url: args.url,
            attributes: args.attributes
        };
        this.controller.addData(params, true);
    },

    removeItem: function(_args) {
        var _items = [];
        _.each(this.items, function(a) {
            if (a !== _args) {
                _items.push(a);
            }
        });
        this.items = _items;
        this.notifyObservers('removed');
    },

    releaseItemData: function(_code) {
        this.controller.removeData(_code);
    },

    buildSearchPanel: function() {
        this.searchPanel = $('<div>').addClass('wmui-search-result-list').hide();
        this.searchResults = $('<ul>').addClass('wmui-search-items');
        this.view.append(this.searchPanel.append(this.searchResults));
    },

    bindEvents: function() {
        const that = this;
        this.searchField.keyup(function(e) {
            if (this.value === '' || this.value.split('')[0] === '') {
                that.searchPanel.hide();
                that.searchResults.empty();
            } else if (this.value.length > 3) {
                that.searchPanel.hide();
                that.search(this.value);
            }
            e.stopPropagation();
            e.preventDefault();
        });
        this.searchField.parent('li').next('li.search_btn').click(function(e) {
            that.search(that.searchField.value);
            e.stopPropagation();
            e.preventDefault();
        });
    },

    search: function(value) {
        let ids = _.pluck(this.items, 'id');
        ids.push(this.options.id);

        // Solution inspired by http://stackoverflow.com/a/6969486/1787187
        const searchValue = new RegExp(value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');

        let results = [];
        for (let i = 0; i < WiseMetering.indicators.length; i++) {
            const indicator = WiseMetering.indicators.models[i];

            if (indicator.get('name').match(searchValue) && !ids.includes(indicator.id)) {
                const building = indicator.building();
                results.push({
                    name: [indicator.get('name'), building ? building.get('name') : ''],
                    uri: `/indicators/${indicator.id}?view=presenter`
                });
            }

            if (results.length >= this.MAX_RESULTS) {
                break;
            }
        }

        this.addSearchResults(results);
    },

    addSearchResults: function(results) {
        var _this_ = this;
        _this_.searchResults.empty();
        _.each(results, function(item, i) {
            var element = $('<li>');
            element.append('<div class="icon"><i class="fa fa-times" aria-hidden="true"></i></div>');
            element.append($('<div class="item-info">'));
            element.find('.item-info').append($('<div class="item-label">'));
            element.find('.item-label').append($('<div>').text(item.name[0]));
            element.find('.item-label').append($('<div>').text(item.name[1]));

            if (_this_.itemExists(item.id)) {
                element.addClass('inactive');
            } else {
                var btn = $('<div>').addClass('wmui-btn activate rgt').text(i18next.t('ui.buttons.add'));
                btn.click(function(e) {
                    $.getJSON(item.uri, {}, function(data) {
                        _this_.addItem(data, {}, false, true);
                        _this_.hideSearchPanel();
                        _this_.searchResults.empty();
                    });
                });
                element.find('.item-info').append(btn);
            }
            _this_.searchResults.append(element);
        });

        if (results.length > 0) {
            var _item_ = $('<li>').addClass('bottom_bar');
            _item_.append(`Max of ${this.MAX_RESULTS} results shown`);
            var _label_ = $('<label>').addClass('btn_close').css({ 'float': 'right' }).text('Close');
            _label_.click(function(e) {
                _this_.hideSearchPanel();
                e.preventDefault();
                e.stopPropagation();
            });
            _item_.append(_label_);
            _this_.searchResults.append(_item_);
            _this_.showSearchPanel();
        }

    },

    itemExists: function(_id) {
        if (this.options.id == _id) {
            return true;
        }
        var _exists = false;

        _.each(this.items, function(item) {
            if (item.id == _id) {
                _exists = true;
            }
        });
        return _exists;
    },

    hideSearchPanel: function() {
        $('body').unbind('click');
        this.searchPanel.hide();
    },

    showSearchPanel: function() {
        var _this_ = this;
        _this_.searchPanel.show();
        $('body').click(function(e) {
            _this_.searchPanel.hide();
            $('body').unbind('click');
        });
    }
};
