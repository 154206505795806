import React from 'react';
import { Checkbox } from '@/components/wm';

export function CheckboxGroup({
    className = '',
    options = [],
    onChange = () => {},
    values = {}
}) {
    const handleCheckboxChange = (key) => {
        onChange({
            ...values,
            [key]: !values[key],
        });
    };

    return (
        <div className={`tw-flex tw-flex-col tw-gap-2 tw-py-2 tw-px-3 tw-border tw-border-solid tw-rounded-md tw-border-input ${className}`}>
            {options.map(({ id, key, label }) => (
                <Checkbox
                    key={id || key}
                    id={id || key}
                    checked={values[key]}
                    label={label}
                    onCheckedChange={() => handleCheckboxChange(key)}
                />
            ))}
        </div>
    );
}
