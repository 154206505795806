WiseMetering.Model.IntegrationAsset = WiseMetering.Model.extend({
    urlRoot: '/integration_assets',

    integrationService: function() {
        return WiseMetering.integrationServices.get(this.get("integration_id"));
    },

    integrationPartner: function() {
        return WiseMetering.integrationPartners.get(this.integrationService().get("partner_id"));
    },

    building: function() {
        return WiseMetering.zones.get(this.integrationService().get("building_id"));
    }
});
