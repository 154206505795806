Lmit.Wise.Chart.Control.costs = function(controller, container, options) {
    this.controller = controller;
    this.container = container;
    this.settings = options.settings;
    this.view = {};
    this.indicator = WiseMetering.indicators.get(options.id);
    this.init();
};

Lmit.Wise.Chart.Control.costs.prototype = {
    init: function() {
        if (WiseMetering.currentUser.get('manager') && this.indicator?.hasUtility()) {
            this.view = $('<div>').addClass('wmui-chart-control-costs');
            this.container.append(this.view);
            this.build();
        }
    },

    build: function() {
        let element = $('<ul>').addClass('ctrl');
        element.append($('<li>').addClass('label').text(`${i18next.t('common.costs')}:`));
        const option = $('<li>').addClass('mode').append(WiseMetering.icon('euro'));
        element.append(option);
        this.bindItemEvent(option);
        this.view.append(element);
    },

    bindItemEvent: function(item) {
        item.click(() => {
            if ($(item).hasClass('current')) {
                $(item).removeClass('current');
                this.controller.compareController.removeItemByCode(this.code);
                return;
            }

            $(item).addClass('current');

            const params = {
                name: `${this.indicator.get('name')} [${i18next.t('common.costs')}]`,
                unit: WiseMetering.getCurrency(),
                operation: this.indicator.id,
                kind: 'cost',
                id: this.indicator.id
            };

            this.code = this.controller.compareController.addItem(params, {}, true, true);
        });
    },

    enable: function() {
        if (WiseMetering.currentUser.get('manager')) {
            this.view.fadeIn(400);
        }
    },

    idle: function() {
        this.view.fadeOut(400);
    }
};
