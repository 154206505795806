WiseMetering.Model.Objective = WiseMetering.Model.extend({
    urlRoot: '/objectives',

    fetchRealData: function() {
        const
            indicator = WiseMetering.indicators.get(this.get('indicator_id')),
            params = {
                from: `${this.get('year')-1}-01-01`,
                to: `${this.get('year')-1}-12-31`,
                rate: 2592000,
            };

            if (this.get('analysis_type') === 'consumption') {
                params.from += ' 00:00:00';
                params.to += ' 23:59:59';
                return indicator.getRealtimeData(params);
            } else {
                return indicator.fetchIndicatorData('/costs',params);
            }
    },

    getTargetDataArray: function() {
        return Object.keys(WiseMetering.MonthsEN).map(months => this.get(months.toLowerCase()) || 0)
    },

    getRealDataArray: function() {
        return this.get('real');
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },


    isConsumption: function() {
        return this.get('analysis_type') === 'consumption';
    },

    isKwh: function() {
        return this.get('unit') === 'kWh';
    },

    zone: function () {
        return WiseMetering.zones.get(this.get('zone_id'));
    }
});
