WiseMetering.Views.AddSeu = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/seus/add',
    title: () => i18next.t('modal.title.seus.new'),
    form_prefix: 'seu',
    className: 'mbx-info text-left',

    ui: {
        indicators: '#seu-indicator',
        indicatorsSearch: 'input#indicator-search',
        name: 'input#seu-name',
        variables: '#seu-variable',
        variablesSearch: 'input#variable-search'
    },

    events: {
        'change #seu-indicator': 'onChangeIndicator',
        'change #seu-utility-kind': 'onChangeUtilityKind',
        'change #seu-variable': 'onChangeVariable',
        'input #seu-name': 'onChangeName',
        'keyup input#indicator-search': 'onIndicatorSearch',
        'keyup input#variable-search': 'onVariableSearch'
    },

    availableUtilityKinds: function() {
        const building = this.model.building();
        return building.availableUtilityKinds();
    },

    onChangeIndicator: function() {
        this.selectedIndicator = this.options.indicators.get(this.$('.indicators option:selected').val());
        this.render();
    },

    onChangeName: function() {
        this.selectedName = this.ui.name.val();
    },

    onChangeUtilityKind: function() {
        let slug = this.$('.utility_kinds option:selected').val();
        this.selectedUtilityKind = WiseMetering.utilityKinds.findWhere({ slug });
        this.updateIndicators();
        this.render();
    },

    onChangeVariable: function() {
        this.selectedVariable = this.options.variables.get(this.$('.variables option:selected').val());
    },

    onIndicatorSearch: function(event) {
        if (event && (event.keyCode || event.which) !== 13) return;

        let indicators = this.options.indicators.map(indicator => indicator.attributes);

        let options = this.search(indicators, this.ui.indicatorsSearch.val());
        this.ui.indicators.html(options);
    },

    onVariableSearch: function(event) {
        if (event && (event.keyCode || event.which) !== 13) return;

        let variables = this.options.variables.map(indicator => indicator.attributes);
        let options = this.search(variables, this.ui.variablesSearch.val(), true);
        this.ui.variables.html(options);
    },

    onRender: function() {
        if (this.selectedIndicator) {
            this.ui.indicators.html(WiseMetering.utils.optionsFromArray([this.selectedIndicator.attributes]));
        }
    },

    search: function(collection, name, isVariable = false) {
        let indicators = collection;
        if (name) {
            name = new RegExp(name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
            indicators = collection.filter(indicator => indicator.name.match(name));
        }

        let options;

        if (indicators.length === 0) {
            options = `<option disabled selected>${i18next.t('seus.no_indicators_found')}</option>`;
        } else {
            if (isVariable) {
                options = `<option value='' selected>${i18next.t('seus.no_variable')}</option>`;
            } else {
                options = `<option disabled selected>${i18next.t('seus.choose_filtered_option')}</option>`;
            }
            options += WiseMetering.utils.optionsFromArray(indicators);
        }

        return options;
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.form_prefix = this.form_prefix;
        json.indicators = this.options.indicators;
        json.selectedIndicator = this.selectedIndicator || {};
        json.selectedName = this.selectedName;
        json.selectedUtilityKindSlug = this.selectedUtilityKind ? this.selectedUtilityKind.get('slug') : null;
        json.selectedVariable = this.selectedVariable || {};
        json.utility_kinds = this.availableUtilityKinds();
        json.variables = this.options.variables.groupBy('kind_slug');
        return json;
    },

    updateIndicators: function() {
        const utilityKind = new WiseMetering.Model.UtilityKind;
        let indicators = WiseMetering.indicators.where({
            building_id: this.model.get('building_id'),
            kind_slug: this.selectedUtilityKind.indicatorKindSlug()
        });
        this.options.indicators.reset();
        this.options.indicators.add(indicators);
    }
});
