WiseMetering.Views.ZoneLocationAccordion = Backbone.Marionette.ItemView.extend({

    template: 'templates/zones/location',

    modelEvents: {
        change: 'createMap'
    },

    events: {
        'click div#edit_location': 'editLocation',
        'click .edit-panorama': 'editPanorama'
    },

    serializeData: function() {
        let canEdit = WiseMetering.currentUser.get('manager') && !this.model.isActivated();
        if (WiseMetering.currentUser.get('superuser')) {
            canEdit = true;
        }

        return { canEdit };
    },

    editLocation: function(event) {
        event.preventDefault();
        event.stopPropagation();
        const address = new WiseMetering.Model.Address(this.model.get('address'));
        new WiseMetering.Views.LocationEdit({ title: 'Edit ' + this.model.get('name'), model: address, building: this.model });
    },

    editPanorama: function() {
        const address = new WiseMetering.Model.Address(this.model.get('address'));
        new WiseMetering.Views.EditPanorama({ model: address, building: this.model });
    },

    onShow: function() {
        setTimeout(this.createMap.bind(this), 0);
    },

    createMap: function() {
        const center = new google.maps.LatLng(
            this.model.get('address').latitude,
            this.model.get('address').longitude
        );

        const map = new google.maps.Map(document.getElementById('building-map'), {
            center: center,
            draggable: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId['ROADMAP'],
            maxZoom: 12,
            minZoom: 12,
            rotateControl: false,
            scaleControl: false,
            streetViewControl: false,
            styles: WiseMetering.MapStyles,
            zoom: 12,
            zoomControl: false
        });

        new google.maps.Marker({
            icon: WiseMetering.mapIcons.building.default,
            map: map,
            position: center
        });
    }

});
