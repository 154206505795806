// Wise Indicator
Lmit.Wise.Analyze.Controller = function(controller, container) {
    this.controller = controller;
    this.container = container;
    // view elements
    this.element = null;
    this.body = null;
    this.toolbar = null;
    this.observers = null;
    this.data = [
        ['15_minutes', i18next.t('indicators.analysis_section.15_minutes'), 1],
        ['hour', i18next.t('indicators.analysis_section.hour'), 1],
        ['day_of_week', i18next.t('indicators.analysis_section.day_of_week'), 2],
        ['day_type', i18next.t('indicators.analysis_section.day_type'), 2],
        ['day_of_month', i18next.t('indicators.analysis_section.day_of_month'), 1],
        ['month', i18next.t('indicators.analysis_section.month'), 3],
        ['year', i18next.t('indicators.analysis_section.year'), 4]
    ];
    this.maxGroupIndex = 4;
    this.defaultMessage = i18next.t('indicators.analysis_section.info');
    // defaults
    this.init();
};

Lmit.Wise.Analyze.Controller.prototype = {

    init: function() {
        this.observers = [];
        this.element = $('<li>').addClass('wmui-chart-analyze');
        if (this.container instanceof Lmit.Section) {
            this.container.addItem(this);
        } else {
            this.container.append(this.element);
        }
        this.build();
        this.showForm();
        this.container.sectionElement.hide();
    },

    register: function(item) {
        this.observers.push(item);
        return true;
    },

    notifyObservers: function(state) {
        var _this_ = this;
        _.each(_this_.observers, function(item) {
            item.onAnaliseChange(state);
        });
    },

    build: function() {
        this.buildToolbar();
        this.body = $('<div>');
        this.element.append(this.body);
        this.buildHelpBox(); // this should be a uiHelpBox
    },

    buildHelpBox: function(msg) {
        if (this.helpBox) {
            this.helpBox.remove();
        }
        this.helpBox = $('<div>').addClass('wmui-msg-box').css({ 'text-align': 'left' });
        this.helpBox.append(msg || this.defaultMessage);
        this.body.append(this.helpBox);
    },

    buildForm: function() {
        this.formView = $('<div>').addClass('wmui-form');
        var fv = $('<dl>').addClass('stepped ');
        this.optionsTarget = $('<dl>').addClass('stepped');
        fv.append(this.optionsTarget);
        //Add the first option select
        this.addOptionSelectTo(this.optionsTarget);
        this.addButtons({}, fv);
        this.formView.append(fv);
        this.body.append(this.formView);
        this.formView.hide();
    },

    showForm: function() {
        this.optionsNum = 0;
        this.hideForm();
        this.buildForm();
        this.formView.show();
        this.toolbar.element.find(`li.wmui-btn:contains("x")`).hide();
    },

    hideForm: function() {
        if (this.formView) {
            this.formView.remove();
        }
        this.formView = null;
        this.toolbar.element.find(`li.wmui-btn:contains("x")`).show();
    },

    addOptionSelectTo: function(_target, _index) {

        this.optionsNum++;
        _index = _index || 0;
        var _data = this.getValidOptions(_index);
        var _text = [i18next.t('indicators.analysis_section.group_data_by'), i18next.t('indicators.analysis_section.separate_data_by')];
        if (_data.length > 0) {

            _target.append($('<dt>').text(this.optionsNum + '-' + _text[this.optionsNum - 1]));
            var optionSelect = $('<dd>');
            var selectBox = $('<select>').addClass('analyze-opt').attr({ name: 'analyze[group][' + this.optionsNum + ']' });

            _.each(_data, function(item) {
                selectBox.append($('<option>').attr({ 'data-index': item[2], value: item[0] }).text(item[1]));
            });
            var _mode;
            if (this.optionsNum == 1) {

                _mode = $('<select>').addClass('analyze-mode').attr({ name: 'analyze[mode]' });

                _.each([[i18next.t('common.average'), 'true'], [i18next.t('common.sum'), 'false']], function(item, i) {
                    _mode.append($('<option>').attr({ 'data-index': i, value: item[1] }).text(item[0]));
                });
            }

            optionSelect.append(selectBox);
            optionSelect.append(_mode);

            this.bindEvent(selectBox, 'select');

            if (this.optionsNum < 2 && _index < 2 && _data.length > 1) {
                var plus_btn = $('<div>').addClass('wmui-btn add wmui-symbol strong').text(i18next.t('common.more'));
                optionSelect.append(plus_btn);
                this.bindEvent(plus_btn, 'add_option');
            }
            if (this.optionsNum > 1) {
                var remove_btn = $('<div>').addClass('wmui-btn rm wmui-symbol strong').text('✖');
                this.bindEvent(remove_btn, 'remove_option');
                optionSelect.append(remove_btn);
            }
            _target.append(optionSelect);
            return true;
        }
        return false;
    },

    getValidOptions: function(_index_) {
        return _.select(this.data, function(item) {
            return item[2] > _index_;
        });
    },

    bindEvent: function(_element, _kind) {
        var _this_ = this;
        switch (_kind) {
            case 'select':
                _element.change(function(e) {
                    var group_index = $(this).find(':selected').attr('data-index');
                    if (_this_.maxGroupIndex === group_index) {
                        $(this).next('div.wmui-btn.add').hide();
                    } else {
                        $(this).next('div.wmui-btn.add').show();
                    }
                });
                break;
            case 'remove_option':
                _element.click(function(e) {
                    $(this).parent('dd').prev().prev().children('div.wmui-btn').show();
                    $(this).parent('dd').prev().prev().children('select').attr('disabled', false);

                    $(this).parent('dd').prev('dt').remove();
                    $(this).parent('dd').remove();
                    _this_.optionsNum--;
                });
                break;
            case 'add_option':
                _element.click(function(e) {
                    var group_index = $(this).parent('dd').children('select').find(':selected').attr('data-index');

                    if (_this_.addOptionSelectTo(_this_.optionsTarget, group_index)) {
                        $(this).parent('dd').children('div.wmui-btn').hide();
                        $(this).parent('dd').children('select').attr('disabled', true);
                    }
                });
                break;
        }
    },

    serialize: function(value, e, tipper) {
        if (value) {
            Lmit.UI.Tipper.removeAll();
        } else {
            tipper.addInputMessage({ text: i18next.t('indicators.analysis_section.name_is_required') });
            return false;
        }

        const params = { name: value, value: this.serializeForm().join(',') };

        const model = new WiseMetering.Model.AnalyzeView();

        model.save(params, {
            success: function() {
                WiseMetering.analyzeViews.add(model);
                this.hideForm();
                this.getSerializedOptions(function() {
                    Lmit.UI.Tipper.signal(
                        i18next.t('indicators.analysis_section.added'),
                        { target: this.toolbar.elements[0].select },
                        { timeout: 1000, pos: 's' }
                    );
                    this.setCurrentAnalyzeOption(model.id);
                }.bind(this));
            }.bind(this)
        });
    },

    getSerializedOptions: function(callback) {
        const data = WiseMetering.analyzeViews.map(function(analyzeView) {
            return {
                id: analyzeView.id,
                name: analyzeView.get('name'),
                value: analyzeView.get('value')
            };
        });
        this.addSerializedOptions(data);
        if (typeof callback === 'function') {
            callback();
        }
    },

    addSerializedOptions: function(results) {
        const select = this.toolbar.elements[0];
        select.removeOptionByAttrType('usr');
        _.each(results, function(item) {
            select.addOption([item.name, item.value, item.id, 'usr', item.description]);
        });
    },

    setCurrentAnalyzeOption: function(id) {
        var s = this.toolbar.elements[0];
        s.select.val(s.select.find('option[data-id="' + id + '"]:first').val()).change();
    },

    addButtons: function(_params, _target) {
        var _this_ = this;
        var btnSaveDelegate = {
            onSelect: function() {
                Lmit.UI.Tipper.prompt(
                    `${i18next.t('common.name')}:`,
                    arguments[1],
                    {
                        pos: 'se',
                        okText: i18next.t('ui.buttons.save'),
                        delegate: {
                            onOk: function(value, e, _tipper) {
                                _this_.serialize(value, e, _tipper);
                            }
                        }
                    }
                );
            }
        };

        var btnApplyDelegate = {
            onSelect: function() {
                _this_.submitAnalyze();
            }
        };

        new Lmit.UI.Toolbar(
            '',
            [
                ['wmui-btn', i18next.t('ui.buttons.apply'), { style: { 'float': 'left' } }, btnApplyDelegate],
                ['wmui-btn', i18next.t('ui.buttons.save'), { style: { 'float': 'right' } }, btnSaveDelegate]
            ],
            _target,
            { 'style': 'form' });
    },

    submitAnalyze: function(value) {
        const
            params = value?.split(',') || this.serializeForm(),
            mode = params.pop(),
            p = _.map(params, function(k) {
                return k;
            }),
            modes = [
                i18next.t('indicators.analysis_section.group_data_by'),
                i18next.t('indicators.analysis_section.separate_data_by')
            ];

        this.controller.chartController.enable('plot', true, [p.reverse(), mode, this.controller.holidays]);

        const text = _.map(params, function(value, index) {
            return `${modes[index]} ${i18next.t(`indicators.analysis_section.${value}`)}<br>`;
        });

        this.buildHelpBox(`<div class="t-left"><h5 class="bottom">${text.join('')}</h5><div>`);
    },

    serializeForm: function() {
        var params = [];
        var selectedOptions = this.body.find('select.analyze-opt');
        selectedOptions = selectedOptions.find(':selected');
        _.each(selectedOptions, function(opt, i) {
            params.push($(opt).attr('value'));
        });
        params.push(this.body.find('select.analyze-mode').val());
        return params;
    },

    buildToolbar: function() {
        var thiz = this;

        var selectActions = {
            onChange: function(value, e) {
                if (value == '1') {
                    thiz.showForm();
                    var srcEl = e.srcElement ? e.srcElement : e.target;
                    thiz.helpBox.remove();
                } else if (value !== '1') {
                    thiz.hideForm();
                    thiz.submitAnalyze(value);
                }
            }
        };

        var removeActions = {
            onSelect: function(value, e) {
                var srcEl = e.srcElement ? e.srcElement : e.target;
                var selectBox = $(srcEl).parent().prev('li').children('select');
                var _id = selectBox.find(':selected').attr('data-id');
                thiz.removeAnalysisOption(_id, function() {
                    Lmit.UI.Tipper.signal(i18next.t('indicators.analysis_section.removed'), e, { timeout: 1000, pos: 's' });
                    // select first data analysis if available
                    selectBox.val(selectBox.find('option[data-id]:first').val());
                    // force change
                    selectBox.change();
                });
            }
        };

        var selectBoxOptions = { data: [[`- ${i18next.t('indicators.analysis_section.new')} -`, 1]] };

        this.toolbar = new Lmit.UI.Toolbar('Info Toolbar', [
                ['wmui-select-box', 'analyze_selection', selectBoxOptions, selectActions],
                ['wmui-btn', 'x', { style: {}, disable: false }, removeActions]
            ],
            this.element, { 'style': 'regular' });
        thiz.getSerializedOptions();

    },

    removeAnalysisOption: function(id, callback) {
        WiseMetering.analyzeViews.get(id).destroy({
            wait: true,
            success: function() {
                this.getSerializedOptions(callback);
            }.bind(this)
        });
    }
};
