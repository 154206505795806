WiseMetering.Views.ShowTariff = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    form_prefix: 'tariff',
    template: 'templates/tariffs/show',
    ui: {
        interval: '#tariff-interval',
        values: '#tariff-values'
    },
    events: {
        'change #tariff-interval': 'changeInterval'
    },
    title: () => i18next.t('modal.title.tariffs.show'),

    buttons: function() {
        const buttons = ['close'];
        if (WiseMetering.currentUser.get('manager') && this.options.mode !== 'summary') {
            buttons.push('edit');
        }
        return buttons;
    },

    edit: function() {
        const { model } = this;
        this.block();
        _.defer(function() {
            new WiseMetering.Views.SaveTariff({ model });
        });
    },

    serializeData: function() {
        const buildings = this.model.buildings(),
              contracts = this.model.contracts();

        return {
            buildings: buildings.length > 0 ? buildings.pluck('name').sort().join(', ') : null,
            contracts: contracts.length > 0 ? contracts.pluck('name').sort().join(', ') : null,
            name: this.model.get('name'),
            showHeader: this.options.mode !== 'summary',
            tariffType: this.model.tariffType().get('name'),
            tenants: this.model.tenants().pluck('name').sort().join(', '),
            timezone: this.model.get('timezone'),
            utility_kind: this.model.utilityKind().get('name')
        };
    },

    onRender: function() {
        const id = _.last(this.model.get('intervals')).id;

        this.renderSelect(id);
        this.renderValues(id);
    },

    changeInterval: function() {
        const id = this.ui.interval.val();
        this.renderValues(id);
    },

    renderValues: function(id) {
        const
            index = null,
            interval = this.model.get('intervals').find(interval => interval.id === id),
            template = this.model.template(),
            unit = this.model.utilityKind().get('unit'),
            ranges = interval.values.ranges || [];

        const html = JST[`templates/tariffs/${template}`]({ index, interval, ranges, unit });
        this.ui.values.html(html);

        this.$('.delRange').remove();
        this.$('input').attr('disabled', 'disabled');
    },

    renderSelect: function(id) {
        const options = this.model.get('intervals').map(interval => {
            return { id: interval.id, name: i18next.t('tariffs.interval_message', { from: interval.from, to: interval.to || i18next.t('common.now') }) };
        });
        this.ui.interval.html(WiseMetering.utils.optionsFromArray(options, id));
    }
});
