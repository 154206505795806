import * as React from 'react';
import { Icon } from '@/components/wm';

const ContentHeader = ({ icon, title }) => {
    return (
        <div className="tw-flex tw-items-center tw-mb-6">
            <Icon icon={icon} color="sideMenu" />
            <h1 className="tw-text-xl tw-inline-block tw-text-grey tw-ml-2">{title}</h1>
        </div>
    );
};

export { ContentHeader };
