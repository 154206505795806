WiseMetering.Views.SimulateCosts = WiseMetering.Views.Content.extend({
    template: 'templates/contracts/simulate_costs',
    currentTotals: null,
    period: {
        from: null,
        to: null
    },

    ui: {
        costsTable: '#costs-table tbody',
        costsTitle: '#costs-title',
        fromField: '.date_time_from',
        loader: '.costs-loader',
        simulateTable: '#simulate-table tbody',
        simulateTitle: '#simulate-title',
        tariffField: '#select-tariff',
        tariffSection: '#select-tariff-section',
        timeSubmit: '.submit-time',
        toField: '.date_time_to'
    },

    events: {
        'click .submit-time': 'populateTables',
        'change @ui.tariffField': 'populateSimulateTable'
    },

    buildTotalRow: function(table, withoutVat, withVat) {
        let totals = {
            withoutVat: formatValue(withoutVat, '€'),
            withVat: formatValue(withVat, '€')
        };

        this.ui[`${table}Table`].append(this.renderTotalTemplate(totals));

        if (table === 'simulate') {
            const
                percentWithoutVat = this.percentageDifference(this.currentTotals.withoutVat, withoutVat),
                percentWithVat = this.percentageDifference(this.currentTotals.withVat, withVat);

            let comparedTotals = {
                withoutVat: formatValue( withoutVat - this.currentTotals.withoutVat, '€' ),
                withVat: formatValue( withVat - this.currentTotals.withVat, '€' ),
                percentWithoutVat: formatValue(percentWithoutVat, '%', 2),
                percentWithVat: formatValue(percentWithVat, '%', 2)
            }

            this.ui[`${table}Table`].append(this.renderTotalResultTemplate(comparedTotals));
        }
    },

    fetchData: function(endPoint, data, success, error) {
        const
            url = `${this.model.url()}/${endPoint}`,
            type = 'GET',
            dataType = 'json',
            contentType = 'application/json';

        return $.ajax({ contentType, data, dataType, error, success, type, url });
    },

    getConsumption: function(type, details = {}) {
        let element = this.$el.find(`#${type}-consumption`);
        element.html('loading...');

        let value = details[`${type}_consumption`]?.total || details[`${type}_consumption`], unit;

        switch (type) {
            case 'active':
                unit = 'Wh';
                break;
            case 'capacitive':
            case 'inductive':
                unit = 'VArh';
                break;
            case 'contracted_power':
            case 'peak_power':
                unit = 'Wh';
                break;
            case 'variable':
            case 'variable_rsu':
                unit = 'm³';
                break;
            case 'vat':
                if (details.vat) {
                    value = details.vat;
                    unit = '%';
                }
                break;
            case 'vat6':
                value = 6;
                unit = '%';
                break;
            case 'vat21':
                value = 21;
                unit = '%';
                break;
            case 'vat23':
                value = 23;
                unit = '%';
                break;
        }

        let html = '-';
        if (value !== undefined && value !== null) {
            html = formatValue(value, unit, 2, 3);
        }

        element.html(html);
    },

    onShow: function() {
        this.period.from = this.model.get('start');
        this.period.to = this.model.get('finish');

        this.setupTariffs();

        this.setupFromField();
        this.setupToField();
        this.updateFields();
        this.populateCostTable();
        this.populateSimulateTable();
    },

    percentageDifference: function(current, simulated) {
        return ((simulated - current) / current) * 100;
    },

    populateCostTable: function() {
        if (this.ui.costsTable) {
            this.ui.costsTable.empty();
        }

        const params = {
            from: this.ui.fromField.val(),
            to: this.ui.toField.val()
        };

        WiseMetering.utils.addLoader(this.ui.loader, 0, 0, 38);

        this.ui.costsTitle.html(`
            <span class="half-table-title">${this.model.get('name')}</span><br>
            <span class="half-table-legend">${i18next.t('common.in_use').capitalize()}</span>
        `);

        this.fetchData('costs', params, costs => {
            this.currentTotals = { withVat: costs.total, withoutVat: costs.total_without_vat };
            this.renderTable('costs', costs);
        }, errors => this.renderError(errors));
    },

    populateSimulateTable: function() {
        const dataTable = this.ui.simulateTable;

        if (this.ui.tariffField.val() == '') {
            this.ui.simulateTitle.html(`
                <span class="half-table-title">${i18next.t('ui.please_select_option')}</span>
            `);
            dataTable.html(this.renderEmptyTemplate());
            return;
        }

        if (this.ui.simulateTable) {
            this.ui.simulateTable.empty();
        }

        const params = {
            from: this.ui.fromField.val(),
            to: this.ui.toField.val(),
            tariff_id: this.ui.tariffField.val()
        };

        WiseMetering.utils.addLoader(this.ui.loader, 0, 0, 38);

        this.ui.simulateTitle.html(`
            <span class="half-table-title">${this.ui.tariffField.find('option:selected').text()}</span><br>
            <span class="half-table-legend">${i18next.t('common.simulated').capitalize()}</span>
        `);


        this.fetchData('simulate_costs', params, costs => this.renderTable('simulate', costs), errors => this.renderError(errors));
    },

    populateTables: function() {
        this.populateCostTable();

        if (this.ui.tariffField.val() != '') {
            this.populateSimulateTable();
        }
    },

    renderEmptyTemplate: function() {
        return `
            <tr style="background-color: #F2F2F2">
                <td class="align-center" colspan="3">
                  <div>No data available</div>
                </td>
            </tr>
        `;
    },

    renderError: function(errors) {
        let dataTable = this.ui.dataTable;
        dataTable.empty();

        this.ui.loader.empty();
        dataTable.html(`
            <tr style="background-color: #F2F2F2">
                <td class="align-center" colspan="3">
                  <div style="color: #E94547">Error fetching data</div>
                </td>
            </tr>
        `);

    },

    renderLineTemplate: function({ className, name, type, cost }) {
        return `
            <tr class="${className}">
                <td>${name}</td>
                <td id="${type}-consumption" class="align-right" style="padding-right: 20px">-</td>
                <td id="${type}-cost" class="align-right" style="padding-right: 20px">${cost}</td>
            </tr>
        `;
    },

    renderTable: function(table, data) {
        let dataTable = this.ui[`${table}Table`];
        dataTable.empty();

        this.ui.loader.empty();

        if (Object.keys(data).length === 0) {
            dataTable.html(this.renderEmptyTemplate());
            return;
        }

        const { details, total, total_without_vat, ...costs } = data;

        Object.entries(costs).forEach(([key, val = 0.0], i) => {
            const template = this.renderLineTemplate({
                name: key.splitCapitalize('_'),
                type: key,
                cost: formatValue(val, '€'),
                className: i % 2 === 0 ? 'odd' : 'even'
            });

            dataTable.append(template);

            this.getConsumption(key, details);
        });

        this.buildTotalRow(table, total_without_vat, total);
    },

    renderTotalResultTemplate: function(totals) {
        return `
            <tr id="total" class="half-variance">
                <td>${i18next.t('common.variance').capitalize()}</td>
                <td colspan="2" id="total-cost" class="align-right" style="padding-right:20px">
                  <div>${totals.withVat} (${totals.percentWithVat})</div>
                  <div style='font-weight:normal;font-size:10px'>without VAT ${totals.withoutVat} (${totals.percentWithoutVat})</div>
                </td>
            </tr>
        `;
    },

    renderTotalTemplate: function(totals) {
        return `
            <tr id="total" class="half-totals">
                <td>Total</td>
                <td colspan="2" id="total-cost" class="align-right" style="padding-right:20px">
                  <div>${totals.withVat}</div>
                  <div style='font-weight:normal;font-size:10px'>without VAT ${totals.withoutVat || totals.withVat}</div>
                </td>
            </tr>
        `;
    },

    setupDatepicker: function(field, callback) {
        field.datepicker({
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            minDate: this.period.from,
            maxDate: this.period.to,
            onSelect: callback
        });
    },

    setupFromField: function() {
        this.ui.fromField.val(this.period.from);
        this.setupDatepicker(this.ui.fromField, dateText => {
            this.period.from = dateText;
            this.updateFields();
        });
    },

    setupToField: function() {
        this.ui.toField.val(this.period.to);
        this.setupDatepicker(this.ui.toField, dateText => {
            this.period.to = dateText;
            this.updateFields();
        });
    },

    setupTariffs: function() {
        const
            contractedTariff = WiseMetering.tariffs.get(this.model.get('tariff_id')),
            utilityKindId = this.model.get('utility_kind_id'),
            tariffs = WiseMetering.tariffs.filter( tariff => {
                return tariff.get('utility_kind_id') === utilityKindId &&
                       tariff.id != contractedTariff.get('id') &&
                       tariff.get('country_code') === contractedTariff.get('country_code');
            }),
            options = tariffs.map( tariff => ({id: tariff.id, name: tariff.get('name')}));

        this.ui.tariffField.html(WiseMetering.utils.optionsFromArray( options, null, 'id', 'name', true ));
    },

    updateFields: function() {
        this.ui.fromField.datepicker('option', 'maxDate', this.ui.toField.val());
        this.ui.toField.datepicker('option', 'minDate', this.ui.fromField.val());
    }
});
