import navigate from '@/utils/navigate';
import percentage from '@/utils/percentage';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import React from 'react';

const Component = props => {
    const { complexes, etls, global, other, raws, stopped, total, unused } = props.data;

    return (
        <Card>
            <CardContent sx={{ height: 260 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Indicators
                </Typography>
                <Typography variant="h5" component="div">
                    Total: {total}
                </Typography>
                <br/>
                <Typography variant="body2">
                    Raws: {raws} ({percentage(raws, total)})
                    <br/>
                    Complexes: {complexes} ({percentage(complexes, total)})
                    <br/>
                    ETLs: {etls} ({percentage(etls, total)})
                    <br/>
                    Other: {other} ({percentage(other, total)})
                    <br/>
                </Typography>
                <br/>
                <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    Global: {global}
                    <br/>
                    Stopped: {stopped}
                    <br/>
                    Unused: {unused}
                </Typography>
            </CardContent>
            <CardActions sx={{ height: 20 }}>
                <Button onClick={() => navigate('/indicators')}>View Indicators</Button>
            </CardActions>
        </Card>
    );
};

export default Component;
