module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="grp_ctrl wmui-chart-toolbar" style="border-width:1px 0; border-style:solid; border-color:rgb(227, 227, 227); border-left:0; margin:10px; width: auto;">\n    <div class="wmui-chart-control-date_time">\n        <ul class="ctrl date_time-selector">\n            <li class="label">From:</li>\n            <li class="field border">\n                <label>\n                    <input class="date_time_from" maxlength="10" name="date-time-from" type="text"/>\n                </label>\n            </li>\n            <li class="label">To:</li>\n            <li class="field border">\n                <label>\n                    <input class="date_time_to" maxlength="10" name="date-time-to" type="text"/>\n                </label>\n            </li>\n            <li>\n                <div class="submit-time wmui-btn" style="margin: 0 5px">Ok</div>\n            </li>\n        </ul>\n    </div>\n    <div class="wmui-chart-control" id="select-view-section">\n        <ul class="ctrl">\n            <li class="label">View type:</li>\n            <li class="mode current" id="chart-view" data="Chart">\n                ' +
((__t = ( WiseMetering.icon('chartLine') )) == null ? '' : __t) +
'\n            </li>\n            <li class="mode" id="table-view" data="Table">\n                ' +
((__t = ( WiseMetering.icon('table') )) == null ? '' : __t) +
'\n            </li>\n        </ul>\n    </div>\n    <div class="wmui-chart-control-tariff" id="select-omie-kind-section" style="float:right">\n        <ul class="ctrl" style="display:flex;align-items:center">\n            <li class="label">OMIE:</li>\n            <li class="border">\n                <select id="select-omie-kind" style="font-size:80%;"></select>\n            </li>\n        </ul>\n    </div>\n    <div class="costs-loader" style="padding:0"></div>\n</div>\n\n<div id="content-area"></div>\n';

}
return __p
}