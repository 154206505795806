WiseMetering.Views.Tipper = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/layout/tipper',
    icons: {
        alert: 'fas fa-exclamation-circle',
        error: 'fas fa-times-circle',
        info: 'fas fa-info-circle',
        success: 'fas fa-check-circle'
    },

    initialize: function() {
        if (!['alert', 'error', 'info', 'success'].includes(this.options.type)) {
            throw new Error('invalid tipper type.');
        }
    },

    className: function() {
        return 'tipper ' + this.options.type;
    },

    serializeData: function() {
        return {
            message: this.options.message,
            icon: this.icons[this.options.type]
        }
    },

    onShow: function() {
        const that = this;

        if (that.options.timeout !== false) {
            setTimeout(function() {
                    that.$el.fadeOut(400, function() {
                        that.close();
                    });
                },
                that.options.timeout || 3000
            );
        }
    }
});
