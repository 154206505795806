import { AllSubstringsIndexStrategy, Search } from 'js-search';
import queryString from 'query-string';

window.MapService = {
    buildingIds: [],
    events: _.extend({}, Backbone.Events),
    filter: '',
    lat: null,
    lng: null,
    searchIndexer: null,
    zoom: null,

    init: function() {
        const { lat, lng, zoom } = queryString.parseUrl(window.location.href).query;
        if (zoom && `${parseInt(zoom)}` === zoom) {
            this.zoom = parseInt(zoom);
        }
        if (lat && `${parseFloat(lat)}` === lat) {
            this.lat = parseFloat(lat);
        }
        if (lng && `${parseFloat(lng)}` === lng) {
            this.lng = parseFloat(lng);
        }
        this.buildingIds = WiseMetering.zones.activeSites().pluck('id');
        this.rebuildSearch();
        this.events.on('map:search:start', this.setFilter.bind(this));
        WiseMetering.zones.on('add change remove', this.rebuildSearch.bind(this));
    },

    rebuildSearch: function() {
        this.searchIndexer = new Search('id');
        this.searchIndexer.indexStrategy = new AllSubstringsIndexStrategy();

        ['address', 'name', 'tags'].forEach(key => this.searchIndexer.addIndex(key));

        const buildings = WiseMetering.zones.activeSites().map(building => ({
            address: WiseMetering.utils.searchableText(building.get('address').full_address),
            id: building.id,
            name: WiseMetering.utils.searchableText(building.get('name')),
            tags: building.get('tag_names').map(tag => WiseMetering.utils.searchableText(tag))
        }));

        this.searchIndexer.addDocuments(buildings);
    },

    set: function(params) {
        const newParams = Object.assign({ lat: this.lat, lng: this.lng, zoom: this.zoom }, params);

        this.lat = newParams.lat;
        this.lng = newParams.lng;
        this.zoom = newParams.zoom;

        const newUrl = WiseMetering.utils.replaceQueryString(newParams);
        Backbone.history.navigate(newUrl, { replace: true, trigger: false });
    },

    setFilter: function(filter) {
        if (filter === '') {
            this.buildingIds = WiseMetering.zones.activeSites().pluck('id');
        } else {
            const results = this.searchIndexer.search(WiseMetering.utils.searchableText(filter));
            this.buildingIds = results.map(result => result.id);
        }
        this.filter = filter;
        this.events.trigger('map:search:end', this.buildingIds);
    }
};
