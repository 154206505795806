module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'ui-schedule\' id=\'schedule_definition\'>\n    <table class=\'ui-schedule-table\'>\n        <thead>\n        <tr>\n            <th class=\'day\' rowspan=\'2\'>Day</th>\n            ';
 WiseMetering.weekdays.forEach(function(weekday) { ;
__p += '\n                <th colspan=\'4\'>' +
((__t = ( i18next.t(`weekdays.${weekday}`) )) == null ? '' : __t) +
'</th>\n            ';
 }); ;
__p += '\n        </tr>\n        <tr>\n            ';
 for(var i = 0; i < WiseMetering.weekdays.length; i++) { ;
__p += '\n                ';
 ['00', '15', '30', '45'].forEach(function(interval) { ;
__p += '\n                    <th>' +
((__t = ( interval )) == null ? '' : __t) +
'</th>\n                ';
 }) ;
__p += '\n            ';
 } ;
__p += '\n        </tr>\n        </thead>\n        <tbody>\n        ';
 for(i = 0; i <= 23; i++) { ;
__p += '\n            <tr>\n                <td class=\'day\'>\n                    ' +
((__t = ( WiseMetering.utils.pad2(i) + ":00" )) == null ? '' : __t) +
'\n                    ';
 _.each(WiseMetering.weekdays, function(weekday, index) { ;
__p += '\n                        ';
 for(var j = 0; j <= 3; j++) { ;
__p += '\n                        <td\n                            class="input' +
((__t = ( editable ? '' : ' pointer-show' )) == null ? '' : __t) +
'' +
((__t = ( j % 4 === 3 ? ' day-change' : '' )) == null ? '' : __t) +
'"\n                            data-x=\'' +
((__t = ( index * 4 + j )) == null ? '' : __t) +
'\' data-y=\'' +
((__t = ( i )) == null ? '' : __t) +
'\'\n                            style="background-color: ' +
((__t = ( colors[values[weekday][i * 4 + j]] )) == null ? '' : __t) +
';"\n                        >\n                            <input\n                                id=\'circuit-schedule-' +
((__t = ( weekday )) == null ? '' : __t) +
'-' +
((__t = ( i * 4 + j )) == null ? '' : __t) +
'\'\n                                name=\'circuit[schedule][' +
((__t = ( weekday )) == null ? '' : __t) +
'][]\'\n                                type=\'hidden\'\n                                value=\'' +
((__t = ( values ? values[weekday][i * 4 + j] : "off" )) == null ? '' : __t) +
'\'\n                            >\n                        </td>\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                </td>\n            </tr>\n        ';
 } ;
__p += '\n        </tbody>\n    </table>\n</div>\n\n<div class=\'schedule-legend\'>\n    <div class=\'schedule-legend-label\'>\n        ' +
((__t = ( editable ? i18next.t('circuits.define_schedule') : i18next.t('common.legend') )) == null ? '' : __t) +
'\n    </div>\n    <b>\n        ';
 periods.forEach(function(period) { ;
__p += '\n            <div class=\'color-legend\' name=\'' +
((__t = ( period )) == null ? '' : __t) +
'\'>\n                <span class=\'color-box\' style=\'background-color: ' +
((__t = ( colors[period] )) == null ? '' : __t) +
'\'>&nbsp;</span>\n                <span class=\'color-text\'>' +
((__t = ( period )) == null ? '' : __t) +
'</span>\n            </div>\n        ';
 }) ;
__p += '\n    </b>\n    <div id=\'current_position\'></div>\n</div>\n';

}
return __p
}