module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.active') )) == null ? '' : __t) +
'?</dt>\n    <dd>' +
((__t = ( i18next.t(`common.${enabled ? 'yes' : 'no'}`) )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.token') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( token )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.rate') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( rate )) == null ? '' : __t) +
' (' +
((__t = ( rateFormatted )) == null ? '' : __t) +
')\n        ';
 if (rate === 900) { ;
__p += '\n            <span class=\'svg_icon\' style=\'color:' +
((__t = ( WiseMetering.baseColors.orange )) == null ? '' : __t) +
'\'>' +
((__t = ( WiseMetering.icon('exclamation') )) == null ? '' : __t) +
'</span>- legacy rate\n        ';
 } ;
__p += '\n    </dd>\n    <dt>' +
((__t = ( i18next.t('glossary.groups') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( groups || i18next.t('users.no_groups') )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.buildings') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( buildings || i18next.t('users.no_buildings') )) == null ? '' : __t) +
'</dd>\n</dl>\n';

}
return __p
}