WiseMetering.Views.Ui.Widget.Table = Backbone.View.extend({
    templateID: 'templates/ui/widgets/table',
    editMode: false,
    widgetPreferences: null,

    build: function(){
    },

    initialize: function(params) {
        $.extend(this, params);
        this.$el.on(`click`, `#options-${this.options.widgetPreferences.id}`, this.widgetOptions.bind(this));
    },

    render: function() {
        let aaData;

        if (this.options.modelSerializer && this.collection.length > 0) {
            aaData = this.collection.map(this.options.modelSerializer);
        } else if (this.options.arrayData && this.options.arrayData.length > 0) {
            aaData = this.options.arrayData;
        } else {
            aaData = [];
        }

        this.$el = $(JST[this.templateID]({
            title: this.options.title,
            widgetPreferences: this.options.widgetPreferences,
            rows: aaData,
            headers: this.options.headers || null,
        }));

        this.editMode ? $(`#wrapper-${this.options.widgetPreferences.id}`).replaceWith(this.$el) : $(this.options.el).append(this.$el);
        this.editMode = false;
    },

    cancelEdit: function() {
        this.editMode = true;

        this.render();
    },

    close: function() {
        this.$el.remove();
    },

    updateData: function(utilityKindSlug) {
        this.editMode = true;
        this.collection = WiseMetering.Analytics.getLatestEvents(utilityKindSlug);
        this.render();

        WiseMetering.layout.showTipper('info', i18next.t('widgets.events.events_refresh'));
    },

    updatePreferences: function(newPreferences) {
        this.editMode = true;
        this.widgetPreferences = newPreferences;
        this.render();
    },

    widgetOptions: function() {
        this.optionsView = new WiseMetering.Views.Ui.WidgetOptions({
            el: `#region-${this.widgetPreferences.id}`,
            preferences: this.widgetPreferences,
            title: this.options.title,
        });

        this.listenTo(this.optionsView, 'save:preferences', this.updatePreferences);
        this.listenTo(this.optionsView, 'cancel:preferences', this.cancelEdit);
        this.optionsView.render();
    }
});
