WiseMetering.Views.IndexTenantInvoices = WiseMetering.Views.TableIndex.extend({
    icon: 'Invoices',
    name: () => i18next.t('glossary.invoices'),

    createTable: function createTable() {
        this.collection = this.model ? WiseMetering.tenantInvoices.findByBuildingId(this.model.get('building_id')) : WiseMetering.tenantInvoices;

        return new WiseMetering.Views.Table({
            id: 'allocations',
            collection: this.collection,
            modelSerializer: function(model) {
                const
                    json = model.toJSON(),
                    timezone = model.timezone();

                json.file = json.file && model.fileLink();
                json.from = WiseMetering.utils.formatDate(json.from, timezone, 'YYYY/MM/DD');
                json.to = WiseMetering.utils.formatDate(json.to, timezone, 'YYYY/MM/DD');
                json.updated_at = WiseMetering.utils.formatDate(json.updated_at, timezone, 'YYYY/MM/DD HH:mm');
                return json;
            },
            columns: this.columns(),
            sort: {
                column: 'from',
                order: 'desc'
            },
            toolbar_actions: {
                select_all: {},
                refresh: {},
                generate: {},
                export: {},
                zip: {},
                delete_all: {}
            }
        });
    },

    columns: function() {
        return this.model ? this.columnsByBuilding() : this.columnsByOrganization();
    },

    columnsByOrganization: function() {
        return {
            file: {
                title: i18next.t('common.file'),
                className: 'table-icon center file',
                width: '5%',
                mRender: function(file) {
                    if (!file) {
                        return '';
                    }
                    return `<a class='link' href='${file}' target='_blank'>${WiseMetering.icon('file')}</a>`;
                }
            },
            tenant_id: {
                title: i18next.t('glossary.tenant'),
                mRender: function(tenant_id) {
                    return WiseMetering.tenants.findWhere({ id: tenant_id }).get('name');

                }
            },
            building_id: {
                title: i18next.t('glossary.building'),
                mRender: function(building_id) {
                    const building = WiseMetering.zones.findWhere({ id: building_id });
                    return building ? building.get('name') : '-';

                }
            },
            interval: i18next.t('common.interval'),
            from: i18next.t('common.from'),
            to: i18next.t('common.to'),
            cost: {
                title: i18next.t('common.cost'),
                mRender: function(cost) {
                    return Lmit.Utils.formatNumber(cost, '€');
                }
            },
            updated_at: i18next.t('glossary.last_change')
        };
    },

    columnsByBuilding: function() {
        return {
            file: {
                title: i18next.t('common.file'),
                className: 'table-icon center file',
                width: '5%',
                mRender: function(file) {
                    if (!file) {
                        return '';
                    }
                    return `<a class='link' href='${file}' target='_blank'>${WiseMetering.icon('file')}</a>`;
                }
            },
            tenant_id: {
                title: i18next.t('glossary.tenant'),
                mRender: function(tenant_id) {
                    return WiseMetering.tenants.findWhere({ id: tenant_id }).get('name');

                }
            },
            interval: i18next.t('common.interval'),
            from: i18next.t('common.from'),
            to: i18next.t('common.to'),
            cost: {
                title: i18next.t('common.cost'),
                mRender: function(cost) {
                    return Lmit.Utils.formatNumber(cost, '€');
                }
            },
            updated_at: i18next.t('glossary.last_change')
        };
    }
});
