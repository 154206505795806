module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('tiers') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('glossary.tiers') )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner wmui-card-view tiers-accordion\'>\n                ';
 _.each(['essentials', 'standard', 'advanced', 'premium'], function(tier){ ;
__p += '\n                    <div class=\'card tier ';
 if (tierName === tier) { ;
__p += ' selected ';
 } ;
__p += '\'>\n                        ';
 if (tierName === tier) { ;
__p += '\n                            <div class="ribbon"></div>\n                        ';
 } ;
__p += '\n                        <div class=\'tier-title\'>' +
((__t = ( tier )) == null ? '' : __t) +
'</div>\n                        <div class=\'tier-description\'>\n                            <div class=\'features\'>' +
((__t = ( i18next.t('glossary.features') )) == null ? '' : __t) +
':</div>\n                            ';
 _.each(WiseMetering.tiers.get(tier_id).features(), function(item) { ;
__p += '\n                                ';
 if (item[tier]) { ;
__p += '\n                                    <div class=\'item\'>\n                                        <div>\n                                            <i class=\'fas fa-check\'></i>\n                                        </div>\n                                        <div>' +
((__t = ( item['description'] )) == null ? '' : __t) +
'</div>\n                                    </div>\n                                ';
 } ;
__p += '\n                            ';
 }) ;
__p += '\n                        </div>\n                        ';
 if (WiseMetering.currentUser.isAdmin() || WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n                            ';
 if (tierName !== tier) { ;
__p += '\n                            <div class="tier-changer">\n                                <button class=\'tier-changer-button\' id=\'change-tier\' data-tier=' +
((__t = ( tier )) == null ? '' : __t) +
' ';
 if (!allowedTransitions[tier]) { ;
__p += 'disabled';
 } ;
__p += '>\n                                    ';
 if (allowedTransitions[tier]) { ;
__p += '\n                                        ' +
((__t = ( WiseMetering.icon('arrowUpCircle') )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t(`ui.buttons.upgrade_to_${tier}`) )) == null ? '' : __t) +
'\n                                    ';
 } else { ;
__p += '\n                                        ' +
((__t = ( WiseMetering.icon('exclamationCircle') )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t(`ui.buttons.not_upgradable_to_${tier}`) )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </button>\n                            </div>\n                            ';
 } ;
__p += '\n                        ';
 } ;
__p += '\n                    </div>\n                ';
 }); ;
__p += '\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}