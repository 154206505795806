WiseMetering.Views.ShowCircuitSchedule = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/circuits/schedules/show',
    title: 'Show Circuit Schedule',
    width: 1020,
    buttons: ['close', 'edit'],
    ui: {
        schedule: '#circuit-schedule-test'
    },

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            fullPath: this.model.fullPath()
        };
    },

    onRender: function() {
        const view = new WiseMetering.Views.CircuitScheduleHistory({ model: this.model });
        view.render();
        this.ui.schedule.html(view.el);
    },

    edit: function() {
        this.block();
        _.defer(function() {
            new WiseMetering.Views.EditCircuitSchedule({ model: this.model });
        }.bind(this));
    }
});
