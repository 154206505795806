WiseMetering.Views.Node = Backbone.Marionette.CompositeView.extend({
    template: 'templates/ui/layout/tree',
    tagName: 'ul',
    treeRendered: false,
    current: false,
    opened: false,

    listenersList: {
        zone: ['Indicator', 'Zone', 'Circuit', 'Folder', 'Tenant', 'Seu'],
        building: ['Indicator', 'Zone', 'Circuit', 'Folder', 'Tenant', 'Seu', 'Utility'],
        'root-object': ['Zone', 'Circuit', 'Folder', 'Tenant', 'Seu', 'Utility'],
        folder: ['Folder'],
        circuit: ['Indicator'],
        utility: ['Contract'],
        contract: []
    },

    ui: {
        icon: 'li.item:first .svg_icon',
        item: 'li.item:first',
        handler: '.tree_handler:first',
        spacer: '.spacer:first',
        title: '.title:first'
    },

    events: {
        'click .tree_handler': 'toggleChild',
        'click li.item:first': 'openContent'
    },

    modelEvents: {
        'change:name': 'renderName'
    },

    renderName: function() {
        this.ui.title.text(this.model.get('name'));
    },

    initialize: function() {
        // http://stackoverflow.com/a/11275689/1787187
        this.collection = new WiseMetering.Collection;
        // A little long but ensures the best performance, because only binds to the EXACT events this should happen
        this.addListeners();
        if (this.model.type() === 'zone' || this.model.type() === 'building') {
            this.listenTo(this.model, 'change:category_id', this.changeIcon);
        } else if (this.model.type() === 'folder') { // For folders, listen to
            this.listenTo(this.model, 'change:indicator_ids', this.changeIndicators);
        }
        this.listenTo(this.model, 'node:close', this.closeNode);
        this.listenTo(this.model, 'node:select', this.selectNode);
        this.listenTo(this.model, 'node:expand', this.expandNode);

        // Buildings listens to node-related stuff
        if (this.model.type() === 'building') {
            this.listenTo(window.MapService.events, 'map:search:end', function(buildingIds) {
                this.$el[buildingIds.includes(this.model.id) ? 'show' : 'hide']();
            }.bind(this));
        }

        Backbone.history.on('route', function() {
            if (window.location.pathname.match(this.model.id)) {
                if (this.model.type() === 'building' && ['-circuits', '-folders', '-seus', '-tenants', '-utilities', '-zones'].some(slug => window.location.pathname.match(slug))) {
                    this.$('li:first').removeClass('current');
                    return;
                }
                this.expandNode();
                this.selectNode();
            } else {
                this.$('li:first').removeClass('current');
            }
        }.bind(this));
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.current = this.current;
        json.hasChildren = this.model.children().length;
        json.showLock = this.model.type() === 'root-object' && !this.model.isAvailable();
        json.icon = this.model.icon();
        json.noCaret = ['contract', 'indicator', 'tenant', 'seu'].includes(this.model.type());
        if (this.model instanceof WiseMetering.Model.Zone) {
            json.isActivated = this.model.isActivated();
        }

        if (this.model instanceof WiseMetering.Model.Zone && this.model.isBuilding()) {
            json.buildingStatus = json.active;
        } else {
            json.buildingStatus = 'n/a';
        }

        return json;
    },

    onShow: function() {
        if (window.location.pathname.match(this.model.id)) {
            this.$('li:first').addClass('current');
        }
    },

    addListeners: function() {
        if (this.listenersList[this.model.type()]) {
            this.listenersList[this.model.type()].forEach(name => {
                this.listenTo(this.model, `change:children:add${name}`, this.addNode);
                this.listenTo(this.model, `change:children:remove${name}`, this.removeNode);
            });
        }
    },

    addNode: function(node) {
        this.collection.add(node);
        this.treeRendered = false;
        this.openChild();
        // If it's the first node, add the handler icon with the view closed
        if (this.collection.length === 1) {
            this.ui.handler.removeClass('hidden');
            this.ui.spacer.addClass('hidden');
            this.$('li:first').removeClass('expanded');
            // If not, maintain state - only show the children if the view is expanded
        } else if (this.$('li:first').hasClass('expanded')) {
            this.$el.children('ul').removeClass('hidden');
        }
    },

    // Do not delete this method. It is being used elsewhere!!
    appendHtml: function(collectionView, itemView) {
        collectionView.$('li:first').after(itemView.el);
    },

    attributes: function() {
        let className = 'items default',
            isIndicator = this.model instanceof WiseMetering.Model.Indicator,
            isTenant = this.model instanceof WiseMetering.Model.Tenant,
            isSeu = this.model instanceof WiseMetering.Model.Seu;

        if (this.model.get('parent_id') !== null && !isIndicator && !isTenant && !isSeu) {
            className += ' left-18 hidden';
        }
        return { class: className };

    },

    changeIndicators: function() {
        this.treeRendered = false;
        this.openChild();
    },

    changeIcon: function() {
        const icon = this.model.icon();
        this.ui.icon.html(WiseMetering.icon(icon));
    },

    closeNode: function() {
        if (this.opened) {
            this.toggleChild();
        }
        this.$('li:first').removeClass('current');
    },

    openChild: function() {
        this.opened = true;
        this.renderNode();
        this.$('li:first').addClass('expanded');
        this.$('li:first div.tree_handler').empty();
        let icon = this.model.children().length > 0 ? 'chevron_down' : 'chevron_right';
        this.$('li:first div.tree_handler').append(`<i class='${WiseMetering.icons[icon]}' aria-hidden='true'>`);
        this.$el.children('ul').removeClass('hidden');
    },

    expandNode: function() {
        this.openChild();
        this.renderNode();
    },

    openContent: function() {
        $('li.item.current').removeClass('current');
        const url = this.model.nodeUrl(true);
        if (window.location.pathname !== url) {
            Backbone.history.navigate(url, { trigger: true });
        }
        this.current = true;
    },

    removeNode: function(node) {
        if (this.collection.models.includes(node)) {
            this.collection.remove(node);
            this.treeRendered = false;
            this.openChild();
            // If was the last node, remove the handler icon
            if (this.collection.length === 0) {
                this.ui.handler.addClass('hidden');
                this.ui.spacer.removeClass('hidden');
                this.$('li:first').removeClass('expanded');
                // If not, maintain state - only show the children if the view is expanded
            } else if (this.$('li:first').hasClass('expanded')) {
                this.$el.children('ul').removeClass('hidden');
            }
        }
    },

    renderNode: function() {
        if (!this.treeRendered) {
            this.collection = this.model.children();
            this.render();
            if (this.current) {
                this.$('li:first').addClass('current');
            }
            this.treeRendered = true;
        }
    },

    selectNode: function() {
        this.$('li:first').addClass('current');
        if (this.model.parent()) {
            this.model.parent().trigger('node:expand');
        }
    },

    toggleChild: function(event) {
        this.opened = !this.opened;
        this.renderNode();
        this.$('li:first').toggleClass('expanded');
        this.$('li:first div.tree_handler').empty();
        let icon = this.opened && this.model.children().length > 0 ? 'chevron_down' : 'chevron_right';
        this.$('li:first div.tree_handler').append(`<i class='${WiseMetering.icons[icon]}' aria-hidden='true'>`);
        this.opened ? this.$el.children('ul').removeClass('hidden') : this.$el.children('ul').addClass('hidden');
        if (event) {
            event.stopPropagation(); // Just the first level
        }
    }
});
