WiseMetering.Views.AnalysisAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/seus/analysis',

    modelEvents: {
        'change': 'render'
    },

    getUnits: function () {
        return {
            xAxis: this.unit(this.model.variable()),
            yAxis: this.unit(this.model.indicator())
        };
    },

    getPearsonEvaluationColor: function() {
        const evaluation = this.model.get('analysis').pearson_evaluation;
        if (evaluation === 'very strong') {
            return WiseMetering.baseColors.green;
        } else if (evaluation === 'strong') {
            return WiseMetering.baseColors.green;
        } else if (evaluation === 'moderate') {
            return WiseMetering.baseColors.orange;
        } else {
            return WiseMetering.baseColors.red;
        }
    },

    getRSquaredEvaluationColor: function() {
        const evaluation = this.model.get('analysis').r_squared_evaluation;
        if (evaluation === 'very strong') {
            return WiseMetering.baseColors.green;
        } else if (evaluation === 'reasonable') {
            return WiseMetering.baseColors.green;
        } else {
            return WiseMetering.baseColors.red;
        }
    },

    isWh: function(type='indicator') {
        const object = type === 'indicator' ? this.model.indicator() : this.model.variable();
        return object.get('unit') === 'Wh';
    },

    onRender: function() {
        if (this.model.get('analysis')) {
            this.model.getRegressionChartData().done(function(response) {
                const
                    el = '#regression-chart',
                    units = this.getUnits(),
                    data = this.processData(response.regression_data),
                    regressionLineData = this.regressionLineData(response.regression_data),
                    regressionLineFormula = this.model.regressionLineFormula();

                $('#spinner').hide();
                chartView = new WiseMetering.Views.Ui.Widget.Scatter({ el, data, units, regressionLineData, regressionLineFormula});
                chartView.render();
            }.bind(this));
        }
    },

    processData: function(data) {
        if (this.isWh('indicator') || this.isWh('variable')) {
            return data.map(function([x, y]) {
                return [
                    this.isWh('variable') ? x / 1000 : x,
                    this.isWh('indicator') ? y / 1000 : y
                ]
            }.bind(this))
        }
        return data;
    },

    regressionLineData: function(data) {
        const minX = Math.min(...data.map(el => el.at(0))),
              maxX = Math.max(...data.map(el => el.at(0))),
              y = x => this.model.get('analysis').b * x + this.model.get('analysis').a;

        return [
            [this.isWh('variable') ? minX / 1000 : minX, this.isWh() ? y(minX) / 1000 : y(minX)],
            [this.isWh('variable') ? maxX / 1000 : maxX, this.isWh() ? y(maxX) / 1000 : y(maxX)]
        ]
    },

    serializeData: function() {
        let json = this.model.toJSON();
        if (this.model.get('analysis')) {
            json.pearsonEvaluationColor = this.getPearsonEvaluationColor();
            json.pearsonCoefficient = this.model.get('analysis').pearson_coefficient.toFixed(4);
            json.rSquared = this.model.getRSquared();
            json.rSquaredEvaluationColor = this.getRSquaredEvaluationColor();
            json.regression_data = this.model.get('regression_data') || null;
            json.from = moment(this.model.get('analysis').from).format('YYYY-MM-DD');
            json.to = moment(this.model.get('analysis').to).format('YYYY-MM-DD');
            json.dataPoints = this.model.get('analysis').data_points;
        }
        return json;
    },

    unit: function(indicator) {
        return indicator.get('unit') === 'Wh' ? 'kWh' : indicator.get('unit');
    }
});
