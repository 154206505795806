WiseMetering.Views.Crud.Save = Backbone.Marionette.ItemView.extend({
    buttons: [{name: 'cancel', float: 'left'}, {name: 'save', float: 'right'}],

    formSerializer: function(excludeParams = []) {
        if (this.input_readers) {
            this.input_readers.forEach(ir => Backbone.Syphon.InputReaders.register(ir.type, ir.func));
        }

        return Backbone.Syphon.serialize(this, { exclude: excludeParams })[this.object];
    },

    initialize: function(attributes) {
        $.extend(this, attributes);
        this.view = new WiseMetering.Views.Crud.Base({
            afterSave: this.afterSave,
            building_id: this.building_id,
            buttons: this.buttons,
            circuit_id: this.circuit_id,
            cancel: this.cancel,
            collection: this.collection,
            formSerializer: this.formSerializer,
            folder_id: this.folder_id,
            form_prefix: this.form_prefix,
            indicator_id: this.indicator_id,
            input_readers: this.input_readers,
            model: this.model,
            options: this.options,
            parent: this.parent,
            reset: this.reset,
            save: this.save.bind(this),
            title: this.title,
            to_edit: this.to_edit,
            validate: this.validate ? this.validate.bind(this) : null,
            width: this.width,
            zone_id: this.zone_id
        });

        WiseMetering.layout.content.show(this.view);
        this.view.body.show(this);
    },

    getFormData: function() {
        return this.formSerializer();
    },

    computeChangedAttributes: function(data) {
        return this.model.changedAttributes(data);
    },

    complete: function() {
        this.$('label').removeClass('err');
        this.$('label').find('.err-help').remove();
    },

    save: function() {
        let data = this.getFormData(),
            changed = this.computeChangedAttributes(data);

        if (!changed) {
            return;
        }

        this.submitData(changed)
            .done(function(data) {
                this.complete();

                if (this.collection) {
                    this.collection.add(data);
                }

                const done = () => {
                    WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_saved'));
                };

                if (this.view.afterSave && typeof (this.view.afterSave) === 'function') {
                    const afterSave = this.view.afterSave();
                    afterSave ? afterSave.done(done) : done();
                } else {
                    done();
                }
            }.bind(this))
            .fail(function(xhr) {
                this.complete();

                let errors = {};
                try {
                    errors = JSON.parse(xhr.responseText);
                } catch (e) {
                }

                _(errors.errors).each((message, param) => {
                    let errorLabel = this.$(`label[for='${param}']`);

                    if (errorLabel.length === 0) {
                        errorLabel = this.$(`label[for='${this.form_prefix}_${param}']`);
                    }

                    if (errorLabel.length === 0) {
                        errorLabel = this.$(`label[for='${this.form_prefix}-${param}']`);
                    }

                    if (errorLabel.length === 0) {
                        errors.error = message;
                    }

                    errorLabel.addClass('err');
                    errorLabel.append(`<span class="err-help" id="question-${this.form_prefix}_${param}"><i class="${WiseMetering.icons.exclamationCircle}" aria-hidden='true'></i></span>`);
                    errorLabel.find(`#question-${this.form_prefix}_${param}`).mouseover(() => {
                        $.stt({ target: errorLabel, text: message });
                    });
                    errorLabel.find(`#question-${this.form_prefix}_${param}`).mouseout(() => {
                        $('div.stt').remove();
                    });
                });
            }.bind(this));
    },

    submitData: function(data) {
        return this.model.save(data, { wait: true, patch: true });
    }
});
