WiseMetering.Views.TenantDetailsTab = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    template: 'templates/tenants/details_tab',

    regions: {
        details: '#details-accordion',
        invoicing: '#invoicing-accordion',
        companies: '#companies-accordion',
        allocations: '#allocations-accordion',
        system: '#system-info-accordion'
    },

    onRender: function() {
        this.details.show(new WiseMetering.Views.TenantDetailsAccordion({ model: this.model }));
        this.invoicing.show(new WiseMetering.Views.InvoicingDetailsAccordion({ model: this.model }));
        this.companies.show(new WiseMetering.Views.CompanyAccordion({ model: this.model }));
        this.allocations.show(new WiseMetering.Views.AllocationsAccordion({
            collection: WiseMetering.allocations.activeAllocations(this.model.id),
            model: this.model
        }));
        this.system.show(new WiseMetering.Views.SystemInfoAccordion({ model: this.model }));
    }
});
