// Wise Indicator
Lmit.Wise.Chart.Control.DateTime = function(controller, container, options) {
    this.options = options;
    this.controller = controller;
    this.container = container;
    this.view = null;
    this.fromField = null;
    this.toField = null;
    this.visible = null;
    this.period = null;
    this.init();
};

Lmit.Wise.Chart.Control.DateTime.prototype = {
    init: function() {
        this.observers = [];
        if (this.options.observers) {
            this.options.observers.forEach(observer => this.register(observer));
        }
        this.view = $('<div>').addClass('wmui-chart-control-date_time');
        this.container.append(this.view);
        this.visible = true;
        this.period = { from: null, to: null };
        this.build();
    },

    register: function(observer) {
        this.observers.push(observer);
        return true;
    },

    notifyObservers: function() {
        this.observers.forEach(observer => observer.onPeriodChange(this.period.from, this.period.to));
    },

    build: function() {
        const
            control = $('<ul>').addClass('ctrl').addClass('date_time-selector'),
            button = $('<button>Ok</button>');

        button.click(() => {
            this.notifyObservers();
            this.updateFields();
        });

        control.append($('<li>').addClass('label').text(i18next.t('common.interval') + ':'));

        this.fromField = $('<input id="date-time-from" maxlength="10">');
        control.append($('<li>').addClass('field border').append(this.fromField));

        control.append($('<li>').addClass('separator').text('~')) ;

        this.toField = $('<input id="date-time-to" maxlength="10">');
        control.append($('<li>').addClass('field border').append(this.toField));

        control.append(button);

        this.view.append(control);

        this.fromField.datepicker({
            dateFormat: 'yy.mm.dd',
            changeMonth: true,
            changeYear: true,
            minDate: '2010.01.01',
            showOtherMonths: true,
            onSelect: dateText => {
                this.period.from = moment.tz(dateText.replace(/\./g, '-'), this.controller.chart.timezone).toDate();
                this.updateFields();
            }
        });

        this.toField.datepicker({
            dateFormat: 'yy.mm.dd',
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            onSelect: dateText => {
                this.period.to = moment.tz(
                    dateText.replace(/\./g, '-'),
                    this.controller.chart.timezone
                ).endOf('day').toDate();
                this.updateFields();
            }
        });

    },

    onPeriodChange: function(from, to) {
        this.period.from = from;
        this.period.to = to;
        this.updateFields();
    },

    updateFields: function() {
        const
            timezone = this.controller.chart.timezone,
            from = moment.tz(this.period.from, timezone).format('YYYY.MM.DD'),
            to = moment.tz(this.period.to, timezone).format('YYYY.MM.DD');

        this.fromField.datepicker('setDate', from);
        this.toField.datepicker('setDate', to);
        this.fromField.datepicker('option', 'maxDate', this.toField.val());
        this.toField.datepicker('option', 'minDate', this.fromField.val());

        ParamsService.setParams({ from: this.fromField.val(), to: this.toField.val() });
    }

};
