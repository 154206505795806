window.Lmit = {};
window.Lmit.Wise = {};
window.Lmit.Wise.Alarms = {};
window.Lmit.Wise.Analyze = {};
window.Lmit.Wise.Chart = {};
window.Lmit.Wise.Chart.Control = {};
window.Lmit.Wise.Components = {};
window.Lmit.Wise.Costs = {};
window.Lmit.Wise.Export = {};
window.Lmit.Wise.Validate = {};
window.Lmit.Wise.Visualization = {};
window.Lmit.UI = {};
window.Lmit.UI.Svg = {};
window.Lmit.UI.Board = {};
window.Lmit.UI.Board.Chart = {};
