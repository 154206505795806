module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'indicator_id\'>' +
((__t = ( i18next.t('indicators.imported_indicator') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input type="hidden" id="indicator_id" name="indicator[id]" value="' +
((__t = ( id )) == null ? '' : __t) +
'">\n            <input class=\'text\' type="text" value="' +
((__t = ( name )) == null ? '' : __t) +
'" disabled>\n        </dd>\n        <dt>\n            <label for=\'indicator_raw\'>' +
((__t = ( i18next.t('indicators.target_indicator') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'indicator_raw\' name=\'indicator[raw_indicator_id]\'>\n                ';
 rawIndicators.each(function(indicator) { ;
__p += '\n                        <option value="' +
((__t = ( indicator.get('id') )) == null ? '' : __t) +
'">\n                            ' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'\n                        </option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}