WiseMetering.Views.ShowEtl = WiseMetering.Views.Modal.Show.extend({
    buttons: ['close', 'edit'],
    className: 'mbx-info text-left',
    template: 'templates/etls/details',
    title: () => i18next.t('modal.title.etls.edit'),
    width: 500,

    edit: function() {
        switch (this.model.get('etl_driver')) {
            case 'eredes':
                return new WiseMetering.Views.ERedesEtlEdit({ model: this.model });
            default:
                return new WiseMetering.Views.EditEtl({ model: this.model });
        }
    },

    serializeData: function() {
        const json = this.model.toJSON();
        const creator = this.model.creator();
        const updater = this.model.updater();
        const last_run = this.model.get('last_run');

        json.consent = json.extra.consent ? i18next.t('common.yes') : i18next.t('common.no');
        json.created_at = WiseMetering.utils.formatDate(this.model.get('created_at'));
        json.creator_name = creator?.get('name') || i18next.t('ui.system_user');
        json.last_processed_file = json.extra.last_processed_file || '-';
        json.last_run = last_run ? WiseMetering.utils.formatDate(this.model.get('last_run')) : i18next.t('etls.never_executed');
        json.rate = this.model.getHumanEtlRate(json.rate);
        json.updated_at = WiseMetering.utils.formatDate(this.model.get('updated_at'));
        json.updater_name = updater?.get('name') || i18next.t('ui.system_user');
        json.username = json.username || '-';
        json.indicators = this.model.indicators().map(indicator => {
            return {
                id: indicator.id,
                name: indicator.get('name'),
                url: indicator.nodeUrl()
            };
        });
        return json;
    }
});
