WiseMetering.Views.EditIndicatorMetal = WiseMetering.Views.Modal.Save.extend({
    form_prefix: 'indicator_metal', template: 'templates/indicators/edit_metal', title: () => i18next.t('modal.title.indicators.edit_metal'), width: 400,

    save: function() {
        const
            value = parseFloat(this.$('input#metal_value').val()),
            error = this.$('input#metal_error').prop('checked'),
            timestamp = parseInt(this.options.timestamp);

        this.model
        .editMetal({ error, timestamp, value })
        .then(function() {
            this.options.target.trigger('customEdit', { value, error });
            this.modal.close();
        }.bind(this));
    },

    serializeData: function() {
        return {
            error: this.options.error,
            timestamp: this.options.timestamp,
            unit: this.model.get('unit'),
            value: this.options.value
        };
    }
});
