module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    ';
 if (actuator_id) { ;
__p += '\n        <div class=\'accordion-group\'>\n            <div class=\'accordion-heading\'>\n                <div class=\'accordion-toggle\'>\n                    <div class=\'svg_icon\'>\n                        ' +
((__t = ( WiseMetering.icon('circuitConditional') )) == null ? '' : __t) +
'\n                    </div>\n                    Conditional\n                    <div class=\'wmui-btn\' id=\'manage-conditional\' style=\'float: right;\'>\n                        ' +
((__t = ( i18next.t('ui.buttons.manage') )) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            </div>\n            <div class=\'accordion-body\'>\n                <div class=\'accordion-inner\' id=\'circuit-conditional\'>\n                    <div class=\'per-box\'>\n                        ';
 if (input.id) { ;
__p += '\n                            <dl>\n                                <dt>' +
((__t = ( i18next.t('common.input') )) == null ? '' : __t) +
'</dt>\n                                <dd>' +
((__t = ( input.name )) == null ? '' : __t) +
'</dd>\n                                <dt>' +
((__t = ( i18next.t('common.invert') )) == null ? '' : __t) +
'?</dt>\n                                <dd>' +
((__t = ( i18next.t(`common.${input.invert ? 'yes' : 'no'}`) )) == null ? '' : __t) +
'</dd>\n                            </dl>\n                        ';
 } else { ;
__p += '\n                            ' +
((__t = ( i18next.t('circuits.no_override') )) == null ? '' : __t) +
'\n                        ';
 } ;
__p += '\n                    </div>\n                </div>\n            </div>\n        </div>\n    ';
 } ;
__p += '\n\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('circuits') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('glossary.current_schedule') )) == null ? '' : __t) +
'\n                <div class=\'wmui-btn\' id=\'edit-schedule\' style=\'float: right;\'>\n                    ' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\' id=\'circuit-schedule\' style=\'height: 560px;\'>\n\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}