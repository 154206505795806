WiseMetering.Views.NewCircuitTag = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/circuit_tags/new',
    className: 'mbx-info text-left',
    form_prefix: 'circuit_tag',
    title: () => i18next.t('modal.title.circuit_types.new'),

    onRender: function() {
        this.$('#circuit_tag_system_category_id').html(WiseMetering.utils.optionsFromModels(WiseMetering.systemCategories.models, 'name'));
    }
});
