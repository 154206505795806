WiseMetering.Views.ActivateBuilding = WiseMetering.Views.Modal.Custom.extend({
    template: 'templates/buildings/activate_building',
    title: () => i18next.t('modal.title.buildings.activate'),
    buttons: ['yes', 'cancel'],
    className: 'mbx-info',

    yes: function() {
        this.model.activate().done(function () {
            this.collection.remove(this.model);
            this.close();
        }.bind(this))
    }
});
