module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for="integration_service_id">' +
((__t = ( i18next.t('glossary.integration') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_service_id\' name=\'integration_service[id]\'>\n                ';
 integrationOptions.forEach(function (integration) { ;
__p += '\n                    <option value=\'' +
((__t = ( integration.id )) == null ? '' : __t) +
'\'>' +
((__t = ( integration.name )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n            <label for="asset-search">' +
((__t = ( i18next.t('ui.search_keywords') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'asset-search\' placeholder=\'' +
((__t = ( i18next.t('ui.search_keywords_placeholder') )) == null ? '' : __t) +
'\' value="">\n        </dd>\n        <span id=\'spinner\' style=\'color:#224C4F\' hidden>\n            <i class=\'far fa-spinner fa-spin\'></i> ' +
((__t = ( i18next.t('integration_assets.fetching_third_party') )) == null ? '' : __t) +
'\n        </span>\n        <dt>\n            <label>' +
((__t = ( i18next.t('glossary.assets') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd id=\'assets-list\'>\n            <p>' +
((__t = ( i18next.t('integration_assets.search_first') )) == null ? '' : __t) +
'</p>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}