Analytics.Operation = function(options) {
    var thiz = this;
    // defaults
    this.analyticsUrl = '/analytics/data.json';
    this.operation = null;
    this.rate = 900;
    this.ratio = 1000;
    this.data = {
        response: null,
        data: null
    };
    this.from = null;
    this.to = null;
    // $.ajax success
    this.success = function(response) {
        thiz.data.response = response;
    };
    // $.ajax error
    this.error = function(data) {
        alert(data);
    };
    // initializer
    this.init(options);
};

Analytics.Operation.prototype = {

    init: function(options) {
        // override defaults
        $.extend(this, options);
    },

    // data fetching function. refreshes data with current operation configuration
    fetch: function() {
        var thiz = this;
        var request = { requests: [] };
        var params = {};
        params[Lmit.Utils.generateCode()] = {
            operation: this.operation,
            rate: this.rate,
            type: 'realtime',
            from: this.from,
            to: this.to
        };
        request.requests.push(params);
        return $.ajax({
            url: thiz.analyticsUrl,
            type: 'POST',
            async: true,
            data: JSON.stringify(request),
            contentType: "application/json",
            dataType: 'json',
            error: thiz.error,
            success: thiz.success
        });
    },

    max: function() {
        if (this.data.data.data[0] && this.data.data.data[0].length === 2) {
            return _.max(this.data.data.data, function(a) {
                return a[1];
            })[1];
        } else {
            return _.max(this.data.data.data, function(a) {
                return a;
            });
        }
    },

    min: function() {
        if (this.data.data.data[0] && this.data.data.data[0].length === 2) {
            if (_.select(this.data.data.data, function(a) {
                return a[1] !== null;
            }).length < this.data.data.data) return 0;
            return _.min(_.select(this.data.data.data, function(a) {
                return a[1] !== null;
            }), function(a) {
                return a[1];
            })[1];
        } else {
            return _.min(_.select(this.data.data.data, function(a) {
                return a !== null;
            }), function(a) {
                return a;
            });
        }
    },

    average: function() {
        return this.sum() / this.data.data.data.length;
    },

    sum: function() {
        if (this.data.data.data[0] && this.data.data.data[0].length === 2) {
            return _.reduce(this.data.data.data, function(memo, a) {
                return memo + a[1];
            }, 0);
        } else {
            return _.reduce(this.data.data.data, function(memo, a) {
                return memo + a;
            }, 0);
        }
    }

};