module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<input id=\'indicator-operation-value\' name=\'indicator[operation]\' type=\'hidden\' value=\'' +
((__t = ( operation )) == null ? '' : __t) +
'\'>\n<dt>\n    <label for=\'indicator-operation\'>' +
((__t = ( i18next.t('indicators.operation') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <div class=\'text big\' id=\'indicator-operation\'></div>\n</dd>\n<dt>\n    <label for="indicator-search">' +
((__t = ( i18next.t('ui.search_box') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input class=\'text\' id=\'indicator-search\' placeholder=\'' +
((__t = ( i18next.t('indicators.search_placeholder') )) == null ? '' : __t) +
'\' value="">\n</dd>\n<dt>\n    <label for="indicators-list">' +
((__t = ( i18next.t('glossary.indicators') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'indicators-list\'></select>\n</dd>\n<dd>\n    <a class=\'lnk operation\' data-id=\'add\'>' +
((__t = ( i18next.t('common.add') )) == null ? '' : __t) +
'</a>\n    <a class=\'lnk operation\' data-id=\'del\'>' +
((__t = ( i18next.t('common.del') )) == null ? '' : __t) +
'</a>\n    <a class=\'lnk operation\' data-id=\'clear\'>' +
((__t = ( i18next.t('common.clear') )) == null ? '' : __t) +
'</a>\n    <a class=\'lnk operation\' data-id=\'flft\'><<</a>\n    <a class=\'lnk operation\' data-id=\'lft\'><</a>\n    <a class=\'lnk operation\' data-id=\'rgt\'>></a>\n    <a class=\'lnk operation\' data-id=\'frgt\'>>></a>\n</dd>\n<dt>\n    <label>Operators</label>\n</dt>\n<dd>\n    <a class=\'lnk operation\' data-id=\'(\'>(</a>\n    <a class=\'lnk operation\' data-id=\')\'>)</a>\n    <a class=\'lnk operation\' data-id=\'+\'>+</a>\n    <a class=\'lnk operation\' data-id=\'-\'>-</a>\n    <a class=\'lnk operation\' data-id=\'*\'>*</a>\n    <a class=\'lnk operation\' data-id=\'/\'>/</a>\n    <a class=\'lnk operation\' data-id=\'.abs\'>.abs</a>\n</dd>\n<dd>\n    <a class=\'lnk operation\' data-id=\'.\'>.</a>\n    <a class=\'lnk operation\' data-id=\'0\'>0</a>\n    <a class=\'lnk operation\' data-id=\'1\'>1</a>\n    <a class=\'lnk operation\' data-id=\'2\'>2</a>\n    <a class=\'lnk operation\' data-id=\'3\'>3</a>\n    <a class=\'lnk operation\' data-id=\'4\'>4</a>\n    <a class=\'lnk operation\' data-id=\'5\'>5</a>\n    <a class=\'lnk operation\' data-id=\'6\'>6</a>\n    <a class=\'lnk operation\' data-id=\'7\'>7</a>\n    <a class=\'lnk operation\' data-id=\'8\'>8</a>\n    <a class=\'lnk operation\' data-id=\'9\'>9</a>\n</dd>\n';

}
return __p
}