WiseMetering.Views.ManageObjective = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    events: {
        'change #analysis_type': 'onAnalysisTypeChanged',
        'change #insertion-type': 'onInsertionTypeChanged',
        'change #objective_year': 'onYearChanged',
        'click #add-notification': 'onAddNotification',
        'click #remove-notification': 'onRemoveNotification'
    },
    form_prefix: 'objective',
    template: 'templates/objectives/manage',
    title: () => i18next.t('modal.title.objectives.manage'),
    ui: {
        analysis_type: '#analysis_type',
        insertionType: '#insertion-type',
        monthlyInsertions: '#monthly-insertions',
        notifications: '#notifications',
        percentage: '#percentage',
        percentageValue: '#percentage-value',
        objectiveIndicator: '#objective_indicator',
        year: '#objective_year',
        spinner: "#spinner"
    },
    width: '650',

    addNotification: function(userId = null) {
        this.ui.notifications.append(JST['templates/objectives/notification']({ userId }));
    },

    autoFill: function() {
        if (!this.previousYearData) return;

        Object.keys(WiseMetering.MonthsEN).forEach((month, index) => {
            let value = this.previousYearData[index];
            if (this.getConsumptionUnit() === 'kWh' && this.selectedAnalysisType === 'consumption') {
                value /= 1000;
            }
            value *= this.selectedPercentage;
            $('#objective_' + month.toLowerCase()).attr('value', Math.round(value));
        });
    },

    cleanAndHideFormInfo: function() {
        Object.keys(WiseMetering.MonthsEN).forEach(month => $(`#objective_${month.toLowerCase()}`).attr('value', ''));
        if (this.selectedInsertionType === 'auto') {
            this.ui.monthlyInsertions.hide();
        }
        if (this.selectedAnalysisType && this.selectedYear) {
            this.setAutoOption();
        }
        this.fillPlaceholder();
    },

    disableUnselectedOptions: function(uis) {
        uis.forEach(function(ui) {
            this.ui[ui].find('option').not(':selected').attr('disabled', 'disabled');
        }.bind(this));
    },

    fillPlaceholder: function() {
        let unit = '';
        if (this.selectedAnalysisType === 'cost') {
            unit = WiseMetering.getCurrency();
        } else if (this.selectedAnalysisType === 'consumption') {
            unit = this.getConsumptionUnit();
        }
        Object.keys(WiseMetering.MonthsEN).forEach(month => $('#objective_' + month.toLowerCase()).attr('placeholder', unit));
    },

    getConsumptionUnit: function() {
        const utilityKindSlug = this.model.indicator().get('kind_slug');
        return ['water', 'gas_volume', 'oil'].includes(utilityKindSlug) ? 'm³' : 'kWh';
    },

    getUtilityKind: function() {
        return this.model.indicator().utilityKind()
    },

    getFormData: function() {
        const data = this.formSerializer() || {};
        if (!data.user_ids) {
            data.user_ids = [];
        }
        data.indicator_id = this.model.get('indicator_id');
        return data;
    },

    getZone: function() {
        return WiseMetering.zones.get(this.model.get('zone_id'));
    },

    onAddNotification: function() {
        this.addNotification();
    },

    onAnalysisTypeChanged: function(event) {
        event.preventDefault();

        this.selectedAnalysisType = this.ui.analysis_type.val();
        this.model.set({analysis_type: this.selectedAnalysisType});
        this.cleanAndHideFormInfo();
    },

    onInsertionTypeChanged: function(event) {
        event.preventDefault();

        this.selectedInsertionType = event.currentTarget.value;
        this.disableUnselectedOptions(['insertionType']);
        if (this.selectedInsertionType === 'auto') {
            this.ui.spinner.show();
            this.model.fetchRealData().then(function(response) {
                this.ui.spinner.hide();
                this.setMonthlyData(this.selectedAnalysisType === 'consumption' ? response.data : response.total);
                this.disableUnselectedOptions(['year', 'analysis_type']);
                this.ui.percentage.show();
                this.ui.percentageValue.keyup(function() {
                    this.setPercentageValue();
                    this.ui.monthlyInsertions.show();
                    this.autoFill();
                }.bind(this));
            }.bind(this));
        } else if (this.selectedInsertionType === 'manual') {
            this.ui.monthlyInsertions.show();
        }
    },

    onRemoveNotification: function(event) {
        event.preventDefault();
        $(event.currentTarget).parent().remove();
    },

    onRender: function() {
        if (this.model.get('user_ids')) {
            this.model.get('user_ids').forEach(userId => this.addNotification(userId));
        }
    },

    onYearChanged: function(event) {
        event.preventDefault();
        this.selectedYear = event.currentTarget.value;
        this.model.set({year: this.selectedYear});
        this.cleanAndHideFormInfo();
    },

    serializeData: function() {
        return {
            analysis_type: this.model.get('analysis_type'),
            created_at: this.model.get('created_at') ? WiseMetering.utils.formatDate(this.model.get('created_at')) : null,
            created_by: this.model.creator().name(),
            id: this.model.get('id'),
            indicators: this.options.indicators,
            monthsArray: i18next.t('highcharts.months', { returnObjects: true }),
            shortMonthsArray: Object.keys(WiseMetering.MonthsEN).map(month => month.toLowerCase()),
            type: this.type,
            unit: this.model.get('unit'),
            updated_at: this.model.get('updated_at') ? WiseMetering.utils.formatDate(this.model.get('updated_at')) : null,
            updated_by: this.model.updater().name(),
            utilityKindName: this.type === 'edit' ? this.getUtilityKind().get('name') : null,
            values: this.model.getTargetDataArray(),
            year: this.model.get('year') || moment().year()
        };
    },

    setAutoOption: function() {
        const el = $('option[value=\'auto\']')
        el.removeAttr('disabled')
    },

    setMonthlyData: function(realData) {
        this.previousYearData = Array(12).fill(0);
        if (!realData || realData.length === 0) return;

        if (this.selectedAnalysisType === 'consumption') {
            this.previousYearData = realData.map(monthly_data => monthly_data.v)
        } else {
            this.previousYearData = realData.map(monthly_data => monthly_data[1])
        }
    },

    setPercentageValue: function() {
        const isNumber = Lmit.Utils.isNumber(parseFloat(this.ui.percentageValue.val())),
              value = isNumber ? parseFloat(this.ui.percentageValue.val() / 100) : -1;

        this.selectedPercentage = value >= 0 ? value : 0;
    }
});
