WiseMetering.Views.IndexCircuitTags = WiseMetering.Views.TableIndex.extend({
    icon: 'circuits',
    name: () => i18next.t('glossary.circuit_types'),
    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'tags',
            collection: WiseMetering.circuitTags,
            modelSerializer: model => {
                const circuits = WiseMetering.circuits.where({ tag_id: model.id });
                const json = model.toJSON();
                json.used = circuits.length > 0;
                json.circuits = circuits.length;
                return json;
            },
            columns: {
                used: {
                    mRender: used => JST['templates/ui/components/circle']({
                        color: WiseMetering.Colors[used ? 'green' : 'orange'],
                        title: used ? 'Used' : 'Unused'
                    }),
                    width: '8px'
                },
                name: i18next.t('common.name'),
                system_category_name: i18next.t('common.system'),
                circuits: `# ${i18next.t('glossary.circuits')}`
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.NewCircuitTag({
                        collection: WiseMetering.circuitTags,
                        model: new WiseMetering.Model.CircuitTag
                    })
                },
                delete: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.DestroyCircuitTag({ model })
                }
            }
        });
    }
});
