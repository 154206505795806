WiseMetering.Views.FolderDetailsTab = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    template: 'templates/folders/tab',
    regions: {
        details: '#details-accordion',
        indicators: '#indicators-accordion',
        folders: '#folders-accordion',
        system: '#system-info-accordion'
    },

    onRender: function() {
        this.details.show(new WiseMetering.Views.FolderDetailsAccordion({ model: this.model }));
        this.indicators.show(new WiseMetering.Views.IndicatorAccordion({ model: this.model }));
        this.folders.show(new WiseMetering.Views.FolderAccordion({ model: this.model }));
        this.system.show(new WiseMetering.Views.SystemInfoAccordion({ model: this.model }));
    }
});
