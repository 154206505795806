WiseMetering.Views.MirrorIndicator = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/types/mirror',

    events: {
        'change #indicator_search_by_building': 'search',
        'keyup #indicator_search_by_name': 'checkEnter',
        'change #indicators_list': 'setName'
    },

    checkEnter: function(event) {
        if ((event.keyCode || event.which) === 13) {
            this.search();
        }
    },

    onRender: function() {
        this.search();
    },

    serializeData: function() {
        return { buildings: WiseMetering.zones.buildings() };
    },

    search: function() {
        const building_id = this.$('#indicator_search_by_building').val();
        if (!building_id) {
            this.$('#indicators_list').html(`<option>${i18next.t('indicators.select_building_first')}</option>`);
            return;
        }

        this.$('#indicator_search_by_name').removeAttr('disabled');
        let name = this.$('#indicator_search_by_name').val();
        let searchName = new RegExp(name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

        const indicators = WiseMetering.indicators.filter(function(indicator) {
            if (indicator.get('kind_id') !== this.model.get('kind_id')) {
                return false;
            }

            if (!['etl', 'raw'].includes(indicator.get('type'))) {
                return false;
            }

            if (indicator.get('building_id') === building_id) {
                return name ? indicator.get('name').match(searchName) : true;
            }

            return false;
        }.bind(this));

        let response = '';
        indicators.forEach(indicator => {
            response += `<option value='${indicator.id}'>${indicator.get('name')}</option>`;
        });

        this.$('#indicators_list').html(response || `<option value>${i18next.t('indicators.no_results')}</option>`);

        this.setName();
    },

    setName: function() {
        const
            buildingId = $('#indicator_building').val(),
            indicatorId = this.$('#indicators_list').val();

        if (indicatorId && buildingId) {
            $('#indicator_name').val(`${WiseMetering.indicators.get(indicatorId).get('name')} - ${WiseMetering.zones.get(buildingId).get('name')}`);
        }
    }
});
