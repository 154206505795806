module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'content\' id=\'dashboard-content\' style=\'margin: 20px 0 0 27px; position: relative;\'>\n  ';
 if(widgets.costPeriod){ ;
__p += '\n  <div class=\'small\' id=\'' +
((__t = ( widgets.costPeriod.el.substr(1) )) == null ? '' : __t) +
'\' style=\'float: left; margin: 0 5px 5px 0;\'></div>\n  ';
 } ;
__p += '\n  ';
 if(widgets.costType){ ;
__p += '\n  <div class=\'small\' id=\'' +
((__t = ( widgets.costType.el.substr(1) )) == null ? '' : __t) +
'\' style=\'float: left; margin: 0 5px 5px 0;\'></div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}