WiseMetering.Views.Ui.Option = Backbone.View.extend({
    tagName: 'option',

    selectView: null,

    initialize: function() {
        if (this.options.getName) {
            this.getName = this.options.getName;
        }
        if (this.options.getValue) {
            this.getValue = this.options.getValue;
        }
        _.bindAll(this, 'render');
    },

    render: function() {
        $(this.el).attr({ value: this.getValue() }).html(this.getName());
        return this;
    }
});
