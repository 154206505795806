module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        ';
 if (isNew) { ;
__p += '\n            <input name=\'tariff[organization_id]\' type=\'hidden\' value=\'' +
((__t = ( WiseMetering.currentOrganization.id )) == null ? '' : __t) +
'\'>\n            <dt>\n                <label for=\'tariff-utility-kind\'>\n                    ' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'\n                </label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'tariff-utility-kind\'>\n                    <option value=\'\'>\n                        ' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'\n                    </option>\n                </select>\n            </dd>\n            <dt>\n                <label for=\'tariff-type\'>\n                    ' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'\n                </label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'tariff-type\' name=\'tariff[tariff_type_id]\'>\n                    <option value=\'\'>\n                        ' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'\n                    </option>\n                </select>\n            </dd>\n        ';
 } ;
__p += '\n        <dt>\n            <label for=\'tariff_name\'>\n                ' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'\n            </label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'tariff_name\' name=\'tariff[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for=\'tariff_value\'>\n                ' +
((__t = ( i18next.t('common.values') )) == null ? '' : __t) +
'\n            </label>\n        </dt>\n        <dd>\n            <div id=\'intervals\'></div>\n            <div class=\'remove wmui-btn\'>\n                ' +
((__t = ( i18next.t('ui.buttons.remove') )) == null ? '' : __t) +
'\n            </div>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}