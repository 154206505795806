WiseMetering.Model.Tier = WiseMetering.Model.extend({
    urlRoot: '/tiers',

    features: () => [
        {
            description: i18next.t('tiers.metering_data_analysis'),
            essentials: true,
            standard: true,
            advanced: true,
            premium: true
        },
        {
            description: i18next.t('tiers.alarms'),
            essentials: true,
            standard: true,
            advanced: true,
            premium: true
        },
        {
            description: i18next.t('tiers.opportunities'),
            essentials: true,
            standard: true,
            advanced: true,
            premium: true
        },
        {
            description: i18next.t('tiers.invoices'),
            essentials: true,
            standard: true,
            advanced: true,
            premium: true
        },
        {
            description: i18next.t('tiers.essentials_reports'),
            essentials: true,
            standard: false,
            advanced: false,
            premium: false
        },
        {
            description: i18next.t('tiers.objectives'),
            essentials: false,
            standard: true,
            advanced: true,
            premium: true
        },
        {
            description: i18next.t('tiers.utilities_tariffs_costs'),
            essentials: false,
            standard: true,
            advanced: true,
            premium: true
        },
        {
            description: i18next.t('tiers.standard_reports'),
            essentials: false,
            standard: true,
            advanced: false,
            premium: false
        },
        {
            description: i18next.t('tiers.tenants'),
            essentials: false,
            standard: false,
            advanced: true,
            premium: true
        },
        {
            description: i18next.t('tiers.iso_50001_processes'),
            essentials: false,
            standard: false,
            advanced: true,
            premium: true
        },
        {
            description: i18next.t('tiers.advanced_reports'),
            essentials: false,
            standard: false,
            advanced: true,
            premium: false
        },
        {
            description: i18next.t('tiers.advanced_api'),
            essentials: false,
            standard: false,
            advanced: true,
            premium: false
        },
        {
            description: i18next.t('tiers.premium_reports'),
            essentials: false,
            standard: false,
            advanced: false,
            premium: true
        },
        {
            description: i18next.t('tiers.premium_api'),
            essentials: false,
            standard: false,
            advanced: false,
            premium: true
        },
        {
            description: i18next.t('tiers.technical_support'),
            essentials: false,
            standard: false,
            advanced: false,
            premium: true
        },
        {
            description: i18next.t('tiers.two_report_customizations_included'),
            essentials: false,
            standard: false,
            advanced: false,
            premium: true
        },
        {
            description: i18next.t('tiers.integration_with_other_softwares'),
            essentials: false,
            standard: false,
            advanced: false,
            premium: true
        }
    ]
});
