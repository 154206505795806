module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        ';
 _.each(fields.list, function (field) { ;
__p += '\n            ';
 if (field.type === 'select') { ;
__p += '\n                <dt>\n                    <label for=\'' +
((__t = ( field.name )) == null ? '' : __t) +
'\'>' +
((__t = ( field.label )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <select class=\'text\' id=\'' +
((__t = ( field.name )) == null ? '' : __t) +
'\' name=' +
((__t = ( fields.object )) == null ? '' : __t) +
'[' +
((__t = ( field.name )) == null ? '' : __t) +
']>\n                        <option selected disabled>' +
((__t = ( i18next.t('ui.choose_option') )) == null ? '' : __t) +
'</option>\n                        ';
 _.each(field.options, function(option) { ;
__p += '\n                            <option value=\'' +
((__t = ( option[field.slug] || option )) == null ? '' : __t) +
'\'>' +
((__t = ( option.name || option )) == null ? '' : __t) +
'</option>\n                        ';
 }); ;
__p += '\n                    </select>\n                </dd>\n            ';
 } else if (field.type === 'custom') { ;
__p += '\n                <dt>\n                    <label for=\'' +
((__t = ( field.name )) == null ? '' : __t) +
'\'>' +
((__t = ( field.label )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd id="' +
((__t = ( field.name )) == null ? '' : __t) +
'"></dd>\n            ';
 } else if (field.type === 'workflow') { ;
__p += '\n                <dt>\n                    <label for=\'' +
((__t = ( field.name )) == null ? '' : __t) +
'\'>' +
((__t = ( field.label )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <div class="btn-group btn-group-' +
((__t = ( fields.object )) == null ? '' : __t) +
'" role="group" aria-label="...">\n                        ';
 _.each(field.options, function(option) { ;
__p += '\n                            <button type="button" class="btn btn-tiers btn-' +
((__t = ( option.name )) == null ? '' : __t) +
'" value="' +
((__t = ( option.id )) == null ? '' : __t) +
'">\n                                ' +
((__t = ( option.name )) == null ? '' : __t) +
'\n                            </button>\n                        ';
 }); ;
__p += '\n                    </div>\n                </dd>\n            ';
 } else if (field.type === 'file') { ;
__p += '\n                <dt>\n                    <label class=\'decapitalize\' for="' +
((__t = ( field.name )) == null ? '' : __t) +
'">' +
((__t = ( field.label )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <input accept=\'image/png\' class=\'input-file text\' type=\'file\' id="' +
((__t = ( fields.object )) == null ? '' : __t) +
'-' +
((__t = ( field.name )) == null ? '' : __t) +
'">\n                </dd>\n                ';
 if (field.preview) { ;
__p += '\n                    <div class=\'preview-image-wrapper\'>\n                        <dt>\n                            <label for=\'preview-image\'>' +
((__t = ( i18next.t('common.preview') )) == null ? '' : __t) +
'</label>\n                        </dt>\n                        <dd class=\'preview\'>\n                            <img class=\'preview-image\' id=\'preview-image\' src=\'#\' alt=\'logo\'/>\n                        </dd>\n                    </div>\n                ';
 } ;
__p += '\n            ';
 } else { ;
__p += '\n                <dt>\n                    <label for=\'' +
((__t = ( field.name )) == null ? '' : __t) +
'\'>' +
((__t = ( field.label )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd>\n                    <input class=\'' +
((__t = ( field.type )) == null ? '' : __t) +
'\' id=\'' +
((__t = ( field.name )) == null ? '' : __t) +
'\' name=' +
((__t = ( fields.object )) == null ? '' : __t) +
'[' +
((__t = ( field.name )) == null ? '' : __t) +
']\n                            type=\'text\' value=\'' +
((__t = ( field.value )) == null ? '' : __t) +
'\'>\n                </dd>\n            ';
 } ;
__p += '\n        ';
 }) ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}