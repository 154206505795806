Lmit.Section = function(_title, _container, _options) {
    this.id = _options.id || Lmit.Utils.generateGuid();
    this.title = _title;
    this.options = _options;
    this.items = [];
    this.itemListElement = null;
    this.container = _container;
    this.bodyElement = null;
    this.sectionElement = null;
    this.sectionBodyElement = null;
    this.currentItem = null;
    this.closed = _options.closed || false;
    this.delegate = _options.delegate;
    this.group = _options.group || this.title;
    this.contextMenuElement = null;
    this.settingOptions = [];
    this.sectionToolbar = null;
    this.init();
};

Lmit.Section.prototype = {

    init: function() {
        this.buildAndAppendSection();
    },

    buildAndAppendSection: function() {
        var thiz = this;
        this.sectionElement = $('<div>').addClass("section");
        if (this.options.style) {
            this.sectionElement.addClass(this.options.style);
        }
        var sectionHead = $('<div>').addClass("head");
        this.sectionToolbar = $("<ul>").addClass("toolbar rgt");
        if (this.options.settings && this.options.settings.length > 0) {
            this.sectionToolbar.append($("<li>").addClass("btn icon settings"));
            this.buildAndAppendSettings(this.options.settings);
        }

        var _icon_handler = $("<li>").addClass("icon handler top");
        _icon_handler.append("<i class='fa fa-caret-up fa-2x' aria-hidden='true'></i>");
        this.sectionToolbar.append(_icon_handler);

        sectionHead.append(this.sectionToolbar);
        sectionHead.append($("<div>").addClass("title").html(this.title));
        this.sectionElement.append(sectionHead);
        this.sectionBodyElement = $('<div>').addClass("body");
        if (this.options.html) this.sectionBodyElement.html(this.options.html);
        this.sectionElement.append(this.sectionBodyElement);
        // exception for events
        // TODO refactor (even if it's a little hammer)
        if (this.options.event !== null && this.options.event) {
            sectionHead.attr({ 'style': 'background-color: #8bc53f !important'});
            sectionHead.children('div.title').css({ 'color': 'white !important'});
            _icon_handler.empty();
            _icon_handler.append("<i class='fa fa-caret-down fa-2x' aria-hidden='true'></i>");

            if (this.options.eventInspectors.length > 0 && this.options.eventPoint !== null) {
                var numberOfEventsBefore = _.select(this.options.eventInspectors, function(ei) {
                    return ei.eventPoint.x < thiz.options.eventPoint.x;
                }).length;
                var eventSection = _.first(this.container.find('.wmui-event-inspector:first').closest('div.section.wmui-section').prev());
                for (var i = 0; i < numberOfEventsBefore; i++) {
                    eventSection = $(eventSection).next();
                }
                $(eventSection).after(this.sectionElement);
            } else {
                this.container.append(this.sectionElement);
            }
        } else {
            this.container.append(this.sectionElement);
        }

        this.bindEvents();

        if (this.options.display === false) {
          this.hide();
        }
    },

    toggleLoader: function() {
        if (this.container.find('img.sec_ld').size() == 0) {
            this.sectionToolbar.prepend($("<img>").attr({
                src: '/images/ui/m_loader.gif'
            }).css({
                width: '16px',
                height: '16px',
                style: "display:'inline-block'"
            }).addClass('icon sec_ld'));
        } else {
            this.container.find('img.sec_ld').remove();
        }
    },

    buildAndAppendSettings: function(settings) {
        this.contextMenuElement = $('<div>').addClass('context_menu').css({
            display: 'none',
            width: '150px',
            right: '27px',
            marginTop: '5px'
        });
        this.contextMenuElement.append("<div class='icon btn on settings right'></div>");
        var optionsList = $('<ul>').css({
            overflow: 'auto'
        });
        for (var i = settings.length - 1;
             i >= 0;
             i--) {
            var optionElement = $('<li>').attr({
                "data-optid": i
            });
            optionElement.append($('<a>').attr({
                href: settings[i][1]
            }).text(settings[i][0]));
            this.settingOptions.push({
                options: settings[i],
                optid: i
            });
            optionsList.append(optionElement);
        }
        this.contextMenuElement.append(optionsList);
        this.sectionElement.append(this.contextMenuElement);
    },

    bindEvents: function() {
        var that = this;
        this.sectionElement.click(function(e) {
            var tgt = $(e.target);
            var element = tgt.closest('div.section');
            if (tgt.hasClass('handler') || tgt.hasClass('head') || tgt.hasClass('title')) {
                element.toggleClass('expanded');
                element.find('ul.toolbar li.icon').empty();
                if (element.hasClass('expanded')) {
                  element.find('ul.toolbar li.icon').append("<i class='fa fa-caret-up fa-2x' aria-hidden='true'></i>")
                } else {
                  element.find('ul.toolbar li.icon').append("<i class='fa fa-caret-down fa-2x' aria-hidden='true'></i>")
                }
                if (that.contextMenuElement) {
                    that.contextMenuElement.hide();
                }
            }
        });
        if (this.options.settings && this.options.settings.length > 0) {
            var settingsIcon = this.sectionToolbar.find('li.settings');
            settingsIcon.click(function() {
                that.contextMenuElement.show();
            });
            var contextMenuIcon = that.contextMenuElement.find("div.settings");
            contextMenuIcon.click(function() {
                that.contextMenuElement.hide();
            });
            var contextMenuOptions = that.contextMenuElement.find("ul li");
            contextMenuOptions.click(function(e) {
                var optionTgt = $(e.target).closest("li");
                for (var i = that.settingOptions.length - 1;
                     i >= 0;
                     i--) {
                    if (optionTgt.attr("data-optid") == that.settingOptions[i].optid) {
                        that.settingOptions[i].options[2].onSelect();
                    }
                }
                that.contextMenuElement.hide();
                e.preventDefault();
            });
        }
    },

    toggle: function(element) {
        var box = element.children("div.body");
        if (box.css('display') == 'none') {
            box.slideDown(400);
        } else {
            box.slideUp(400);
        }
        //box.toggle();
        element.toggleClass('expanded');
    },

    hide: function(callback) {
        var thiz = this;
        this.sectionBodyElement.slideUp(400, function() {
            thiz.sectionElement.addClass('expanded');
            if (typeof callback == 'function') {
                callback();
            }
        });
    },

    show: function() {
        var _icon_handler = this.sectionToolbar.find(".icon.handler");
        _icon_handler.empty();
        _icon_handler.append("<i class='fa fa-caret-down fa-2x' aria-hidden='true'></i>");
        this.sectionBodyElement.slideDown(400, function() {
            this.sectionElement.removeClass('expanded');
        }.bind(this));
    },

    addSectionItem: function(title, uri, _options_) {
        return new Lmit.SectionItem(this, title, uri, _options_);
    },

    addItem: function(item) {
        if (this.itemListElement == null) {
            this.itemListElement = $("<ul>").addClass("items");
            this.sectionBodyElement.append(this.itemListElement);
        }
        this.itemListElement.append(item.element);
        this.items.push(item);
    },

    findItemByID: function(_id_) {
        for (i in this.items) {
            if (this.items[i].id === _id_) {
                return this.items[i];
            }
        }
        return false;
    },

    selectItem: function(selectedItem, e) {
        for (i in this.items) {
            var item = this.items[i];
            if (item.id == selectedItem.id) {
                item.current();
                this.currentItem = item;
                if (this.delegate) {
                    this.delegate.onSelect(this, item, e);
                }
            } else {
                item.idle();
            }
        }
    },

    highlightItem: function(selectedItem) {
        for (i in this.items) {
            var item = this.items[i];
            if ((item instanceof Lmit.Tree)) {
                item.highlightItem(selectedItem);
            } else {
                if (item === selectedItem) {
                    item.current();
                    this.currentItem = item;
                } else {
                    item.idle();
                }
            }
        }
    },

    selectItemByTitle: function(title) {
        for (i in this.items) {
            var item = this.items[i];
            if (item.title === title) {
                item.current();
                this.currentItem = item;
                if (this.delegate) {
                    this.delegate.onSelect(this, item);
                }
            } else {
                item.idle();
            }
        }
    },

    unSelectItem: function(selectedItem) {
        for (i in this.items) {
            var item = this.items[i];
            if (item === selectedItem) {
                item.idle();
            }
        }
    },

    unSelectAll: function() {
        for (i in this.items) {
            this.items[i].idle();
        }
    },

    // hide the whole section (including header) sliding up
    close: function() {
        this.sectionElement.slideUp(400, function() {

        });
    },

    // show the whole section (including header) sliding down
    open: function(options) {
        if (options && options.showContent) {
            this.show();
        } else {
            this.hide();
        }
        this.sectionElement.slideDown(600, function() {
        });
    },

    // destroys section element and bindings
    destroy: function() {
        var thiz = this;
        try {
            thiz.bodyElement.remove();
        } catch (e) {
        }
        try {
            thiz.sectionBodyElement.remove();
        } catch (e) {
        }
        try {
            thiz.sectionToolbar.remove();
        } catch (e) {
        }
        try {
            thiz.sectionElement.remove();
        } catch (e) {
        }
        thiz = null;
    }
};
