module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<tr class=\'spacer range_' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'\'></tr>\n<tr class=\'range_' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'\'>\n    <th>\n        ' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'\n    </th>\n    <td>\n        <input class="disabled range_start" name="tariff[intervals][' +
((__t = ( intervalIndex )) == null ? '' : __t) +
'][values][ranges][' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( from )) == null ? '' : __t) +
'">\n    </td>\n</tr>\n<tr class=\'range_' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'\'>\n    <th>\n        ' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'\n    </th>\n    <td>\n        <input class="' +
((__t = ( disableTo ? 'disabled ' : '' )) == null ? '' : __t) +
'range_end" name="tariff[intervals][' +
((__t = ( intervalIndex )) == null ? '' : __t) +
'][values][ranges][' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( to )) == null ? '' : __t) +
'">\n    </td>\n</tr>\n<tr class=\'range_' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'\'>\n    <th>\n        ' +
((__t = ( i18next.t('common.value') )) == null ? '' : __t) +
'\n    </th>\n    <td>\n        <input name="tariff[intervals][' +
((__t = ( intervalIndex )) == null ? '' : __t) +
'][values][ranges][' +
((__t = ( rangeIndex )) == null ? '' : __t) +
'][value]" type="text" value="' +
((__t = ( value )) == null ? '' : __t) +
'">\n    </td>\n</tr>\n';

}
return __p
}