WiseMetering.Views.IndexApiKeys = WiseMetering.Views.TableIndex.extend({
    icon: 'api_keys',
    name: () => i18next.t('glossary.api_keys'),

    createTable: function() {
        this.collection = new WiseMetering.Collection(WiseMetering.users.where({ integration: true }));

        this.table = new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: this.collection,
            columns: {
                state: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({ color, title }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                buildings: i18next.t('glossary.buildings'),
                groups: i18next.t('glossary.groups'),
                created_at: i18next.t('glossary.created_at')
            },
            id: 'integrations',
            modelSerializer: function(model) {
                const
                    json = model.toJSON(),
                    buildings = model.buildings(),
                    groups = model.groups();

                json.buildings = buildings.pluck('name').join(', ');
                json.groups = groups.pluck('name').join(', ');
                json.created_at = WiseMetering.utils.formatDate(model.get('created_at'));

                if (json.enabled === false) {
                    json.state = {
                        color: 'red',
                        title: i18next.t('glossary.disabled')
                    };
                } else if (buildings.length) {
                    json.state = {
                        color: 'green',
                        title: i18next.t('glossary.enabled')
                    };
                } else {
                    json.state = {
                        color: 'orange',
                        title: i18next.t('ui.table.warning')
                    };
                }

                return json;
            },
            toolbar_actions: {
                new: {
                    permission: WiseMetering.currentOrganization.is(['essentials', 'standard']) ? 'no-access'  : 'superuser',
                    view_call: () => new WiseMetering.Views.NewApiKey({
                        collection: WiseMetering.users,
                        model: new WiseMetering.Model.User({ integration: true, organization_id: WiseMetering.currentOrganization.id })
                    })
                },
                show: {
                    view_call: model => {
                        model.fetch().then(function() {
                            new WiseMetering.Views.ShowApiKey({ model });
                        });
                    }
                },
                edit: {
                    permission: WiseMetering.currentOrganization.is('standard') ? 'no-access'  : 'superuser',
                    view_call: model => new WiseMetering.Views.EditApiKey({ model })
                },
                customDisable: {
                    icon: 'disable',
                    label: 'Disable',
                    permission: 'superuser',
                    view_call: model => {
                        if (!model.get('enabled')) {
                            return;
                        }

                        new WiseMetering.Views.DisableUser({ model: model, callback: () => this.table.uncheckAll() });
                    }
                },
                delete: {
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.api_keys.destroy')
                    })
                }
            }
        });


        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.users);
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.users, 'add change remove', this.createTable.bind(this));
        this.createTable();
    }
});
