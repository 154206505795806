WiseMetering.Model.IntegrationService = WiseMetering.Model.extend({
    urlRoot: '/integrations',

    building: function() {
        return WiseMetering.zones.get(this.get("building_id"));
    },

    integrationPartner: function() {
        return WiseMetering.integrationPartners.get(this.get("partner_id"));
    },
});
