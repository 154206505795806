WiseMetering.Views.ActivateEtl = WiseMetering.Views.Modal.Custom.extend({
    className: 'mbx-info',
    buttons: ['yes', 'no'],
    form_prefix: 'etl',
    template: 'templates/etls/activate',
    title: 'Activate Etl',

    yes: function() {
        this.block();
        let success = function() {
            WiseMetering.layout.showTipper('success', 'Successfully activated etl');
            this.unblock();
            this.close();
        }.bind(this);
        let error = function() {
            WiseMetering.layout.showTipper('error', 'Unable to activate etl');
            this.unblock();
            this.close();
        }.bind(this);

        this.model.activate({
            success: success.bind(this),
            error: error.bind(this)
        }).then(data => {
            this.model.set(data);
            this.options.callback();
        });
    }
});
