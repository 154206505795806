WiseMetering.Views.IndexBuildingTags = WiseMetering.Views.TableIndex.extend({
    icon: 'tags',

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'building_tags',
            collection: WiseMetering.buildingTags,
            columns: {
                name: i18next.t('common.name'),
                buildingNames: i18next.t('glossary.buildings')
            },
            modelSerializer: function(model) {
                return {
                    buildingNames: model.buildingNames().sort().join(' | '),
                    id: model.id,
                    name: model.get('name')
                };
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.NewBuildingTag({
                        collection: WiseMetering.buildingTags,
                        model: new WiseMetering.Model.BuildingTag
                    })
                },
                delete: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.building_tags.destroy')
                    })

                }
            }
        });
    },

    name: function() {
        return i18next.t('glossary.building_tags');
    }
});
