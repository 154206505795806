WiseMetering.Views.DeleteAll = WiseMetering.Views.Modal.Destroy.extend({
    title: () => i18next.t('ui.table.please_confirm'),

    yes: function() {
        const that = this;
        that.block();
        const models = _(that.ids).map(function(id) { return that.collection.get(id); });
        $.when.apply($, _.map(models, function(model) { return model.destroy({ wait: true }); })).then(function() {
            WiseMetering.layout.showTipper('success', i18next.t('ui.table.everything_removed'));
        }).fail(function() {
            WiseMetering.layout.showTipper('error', i18next.t('ui.table.error_while_removing'));
        }).always(function() {
            that.close();
        });
    }
});
