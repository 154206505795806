WiseMetering.Views.SaveTariff = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/tariffs/save',
    className: 'mbx-info text-left',
    form_prefix: 'tariff',
    title: () => i18next.t('modal.title.tariffs.manage'),
    width: 481,
    ui: {
        intervals: '#intervals'
    },

    events: {
        'change #tariff-utility-kind': 'utilityKindChange',
        'change #tariff-type': 'typeChange',
        'click .remove': 'removeInterval'
    },

    getFormData: function() {
        const
            formData = this.formSerializer(),
            intervals = Object.values(formData.intervals),
            data = { ...formData };

        data.intervals = intervals.map(interval => {
            const values = WiseMetering.utils.convertObjectStringToNumber(interval.values);

            if (values.ranges) {
                values.ranges = Object.values(values.ranges);
            }

            return { ...interval, values };
        });

        this.model.get('intervals')?.forEach(interval => {
            if (intervals.map(({ id }) => id).includes(interval.id)) {
                return;
            }

            data.intervals.push({ id: interval.id, _destroy: true });
        });

        return data;
    },

    serializeData: function() {
        return {
            isNew: this.model.isNew(),
            name: this.model.get('name')
        };
    },

    onRender: function() {
        const
            { optionsFromArray, optionsFromModels } = WiseMetering.utils,
            tariffTypes = WiseMetering.tariffTypes;

        if (this.model.isNew()) {
            this.$('#tariff-utility-kind').append(optionsFromModels(WiseMetering.utilityKinds.models, 'name'));
        } else {
            const utilityKindOptions = optionsFromModels(WiseMetering.utilityKinds.models, 'name', this.model.get('utility_kind_id'));
            const typeOptions = optionsFromArray(tariffTypes, this.model.get('type'), 'id', 'type');

            const disableEl = (el, options) => {
                el.html(options).attr('disabled', 'disabled');
            };

            disableEl(this.$('#tariff-utility-kind'), utilityKindOptions);
            disableEl(this.$('#tariff-type'), typeOptions);

            this.template = this.model.tariffType().template();
            this.model.get('intervals').forEach(interval => this.addInterval(interval));
        }
    },

    utilityKindChange: function() {
        this.ui.intervals.empty();
        const utilityKindId = this.$('#tariff-utility-kind').val();

        if (_.isEmpty(utilityKindId)) {
            this.$('#tariff-type')
            .html(`<option>${i18next.t('ui.please_select_option')}</option>`)
            .attr('disabled', 'disabled');
        } else {
            const tariffTypes = WiseMetering.tariffTypes.where({ utility_kind_id: utilityKindId });
            // The ternary operator takes too much width space, and it's not very readable
            if (_.isEmpty(tariffTypes)) {
                this.$('#tariff-type').html(`<option>${i18next.t('tariffs.unavailable_for_that_utility_kind')}</option>`).attr('disabled', 'disabled');
            } else {
                this.$('#tariff-type').html(WiseMetering.utils.optionsFromModels(tariffTypes)).removeAttr('disabled');
                this.typeChange();
            }
        }

        this.modal.redraw();
    },

    typeChange: function() {
        this.ui.intervals.empty();
        const tariffType = WiseMetering.tariffTypes.get(this.$('#tariff-type').val());
        if (tariffType) {
            this.template = tariffType.template();
            this.addInterval({ from: '' });
        }
    },

    addInterval: function(interval) {
        const
            index = this.$('table.interval').length,
            utility_kind = this.model.utilityKind() || WiseMetering.utilityKinds.find({ id: this.$('#tariff-utility-kind').val() }),
            unit = utility_kind.get('unit'),
            ranges = [];

        const template = JST[`templates/tariffs/${this.template}`]({ index, interval, ranges, unit });
        this.ui.intervals.append(template);
        this.bindPickers(index);

        if (this.$('.delRange').length !== 0) {
            this.addRangeTemplate(index, interval?.values?.ranges || null);
        }

        this.modal.redraw();
    },

    addRangeTemplate: function(index, data, rangeIndex = 0) {
        const lastIntervalEl = this.ui.intervals.find('.interval').last();
        const rangeCount = { index: rangeIndex };

        if (data) {
            const intervalRanges = Object.values(data).map((range, index, arr) => (index !== arr.length - 1) ? { ...range, disableTo: true } : range);
            intervalRanges.forEach(range => this.buildRangeTemplate(index, lastIntervalEl, rangeCount, range));
        } else {
            this.buildRangeTemplate(index, lastIntervalEl, rangeCount, { from: 0 });
        }

        lastIntervalEl.find('.delRange').click(() => {
            if (rangeCount.index === 1) {
                return;
            }
            rangeCount.index--;
            lastIntervalEl.find(`.range_${rangeCount.index}`).remove();
            lastIntervalEl.find(`.range_${rangeCount.index - 1} .range_end`).val('').prop('disabled', false);
        });
    },

    buildRangeTemplate: function(intervalIndex, interval$, rangeCount, range) {
        const html = JST['templates/tariffs/range']({ disableTo: true, from: range.from, intervalIndex, rangeIndex: rangeCount.index, to: range.to, value: range.value });
        const template = $(html);

        template.find('.range_end').on('change', event => {
            const startValue = parseInt(template.find('.range_start').val());
            const endValue = parseInt(event.currentTarget.value);

            if (isNaN(endValue) || endValue < startValue) {
                event.currentTarget.value = '';
                return;
            }
            template.find('.range_end').prop('disabled', true);
            this.buildRangeTemplate(intervalIndex, interval$, rangeCount, { from: endValue });
        });

        rangeCount.index++;
        interval$.find('.spacer.last').before(template);
    },

    bindPickers: function(index) {
        const firstFrom$ = this.$('.from:first');
        const firstFromValue = this.$('.from:first').val();
        const firstTo$ = this.$('.to:first');
        const minFromDate = moment().add(-2, 'years').startOf('year').toDate();

        if (index === 0 && firstFromValue === '') {
            firstFrom$.removeAttr('disabled');
            firstTo$.attr('disabled', 'disabled');

            firstFrom$.datepicker({ dateFormat: 'dd/mm/yy', changeMonth: true, changeYear: true, minDate: minFromDate });
            firstFrom$.bind('change', event => {
                const target = $(event.target);
                const from = moment(target.val(), 'DD/MM/YYYY').startOf('month');
                target.val(from.format('DD/MM/YYYY'));
                target.attr('disabled', 'disabled');
                this.setupTo();
            });
        } else {
            firstFrom$.attr('disabled', 'disabled');
            firstFrom$.unbind('change');
            this.$('.to:not(:last)').attr('disabled', 'disabled');
            this.setupTo();
        }
    },

    setupTo: function() {
        const to$ = this.$('.to:last');
        to$.removeAttr('disabled');
        to$.datepicker({
            dateFormat: 'dd/mm/yy', changeMonth: true, changeYear: true, minDate: moment(this.$('.from:last').val(), 'DD/MM/YYYY').endOf('month').toDate()
        });
        to$.unbind('change');
        to$.bind('change', event => {
            const target = $(event.target);
            const to = moment(target.val(), 'DD/MM/YYYY').endOf('month');
            target.val(to.format('DD/MM/YYYY'));
            const from = to.add(1, 'days').format('DD/MM/YYYY');
            this.addInterval({ from: from }, true);
        });
    },

    removeInterval: function() {
        if (this.$('table.interval').length !== 1) {
            this.$('table.interval:last').remove();
        }
        this.$('input.to:last').val('').removeAttr('disabled');
        this.modal.redraw();
    }
});
