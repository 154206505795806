WiseMetering.Views.OrganizationDetailsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/organizations/details_accordion',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click #edit_attributes': 'editAttributes',
        'click #edit_attributes_old': 'editAttributesOld'
    },

    serializeData: function () {
        const json = this.model.toJSON();
        json.country =  WiseMetering.countries.findWhere({country_code: json.country_code}).get('name');
        return json;
    },

    editAttributes: function() {
        const url = `${this.model.url()}/edit`;
        Backbone.history.navigate(url, { trigger: true });
    }
});
