WiseMetering.Views.IndexActive = WiseMetering.Views.TableIndex.extend({
    icon: 'markers',
    name: function() {
        return i18next.t('glossary.markers');
    },

    createTable: function() {
        this.collection = WiseMetering.markers.activeMarkers();

        this.table = new WiseMetering.Views.Table({
            id: 'markers',
            collection: this.collection,
            modelSerializer: function(model) {
                const
                    building = model.building(),
                    json = model.toJSON(),
                    timezone = building.get('timezone');

                json.status = model.states[model.get('state')];
                json.building = building.get('name');
                json.indicator = model.indicator().get('name');
                json.from = WiseMetering.utils.formatDate(model.get('marker_at'), timezone);
                json.to = model.get('end_date') ? WiseMetering.utils.formatDate(model.get('end_date'), timezone) : '-';
                json.results = model.results() ? model.results().replace('\n', '<br/>') : '-';
                return json;
            },
            columns: {
                status: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({ color, title }),
                    sWidth: '20px'
                },
                name: i18next.t('glossary.title'),
                from: i18next.t('common.from'),
                to: i18next.t('common.until'),
                results: i18next.t('common.results'),
                indicator: i18next.t('glossary.indicator'),
                building: i18next.t('glossary.building')
            },
            types: {
                from: 'date',
                end_date: 'date'
            },
            toolbar_actions: {
                refresh: {
                    disabled: false,
                    fetchCollection: WiseMetering.markers
                },
                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => new WiseMetering.Views.ShowMarker({ model })
                },
                edit: {
                    label: i18next.t('ui.actions.edit'),
                    view_call: model => new WiseMetering.Views.EditMarker({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.markers.destroy')
                    })
                }
            }
        });
        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.markers);
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.markers, 'remove add change', this.createTable.bind(this));
        this.createTable();
    }
});
