WiseMetering.Views.IndexTariff = WiseMetering.Views.TableIndex.extend({
    icon: 'tariffs',
    name: () => i18next.t('glossary.tariffs'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'tariffs',
            collection: WiseMetering.tariffs,
            modelSerializer: function(model) {
                const
                    json = {},
                    buildings = model.buildings(),
                    contracts = model.contracts(),
                    tenants = model.tenants();

                json.id = model.id;
                json.utility_kind = model.utilityKind().get('name');
                json.name = model.get('name');
                json.tariffType = model.tariffType().get('name');
                json.intervals = model.get('intervals').length;
                json.buildings = buildings.length;
                json.contracts = contracts.length;
                json.tenants = tenants.length;
                json.used = tenants.length + contracts.length > 0;
                json.from = model.get('intervals')[0].from;
                json.updated_at = WiseMetering.utils.formatDate(model.get('updated_at'));

                return json;
            },
            columns: {
                used: {
                    mRender: used => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[used ? 'green' : 'orange'],
                        title: i18next.t(`common.${used ? 'in_use' : 'unused'}`)
                    }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                utility_kind: i18next.t('glossary.utility_kind'),
                tariffType: i18next.t('common.type'),
                intervals: i18next.t('common.intervals'),
                buildings: i18next.t('glossary.buildings'),
                contracts: i18next.t('glossary.contracts'),
                tenants: i18next.t('glossary.tenants'),
                from: i18next.t('common.since'),
                updated_at: i18next.t('glossary.last_change')
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.SaveTariff({
                        model: new WiseMetering.Model.Tariff,
                        collection: WiseMetering.tariffs
                    })
                },
                edit: {
                    view_call: model => new WiseMetering.Views.SaveTariff({ model })
                },
                show: {
                    view_call: model => new WiseMetering.Views.ShowTariff({ model })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        title: () => i18next.t('modal.title.tariffs.destroy')
                    })
                }
            }
        });
    }
});
