module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <div class="mbx-info text-left">\n        <dl class="stepped zero">\n            <dt>\n                <label for="utility-name">' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'&nbsp;*</label>\n            </dt>\n            <dd>\n                <input class="text" id="utility-name" name="utility[name]" type="text">\n            </dd>\n            <dt>\n                <label for="utility-kind">' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'&nbsp;*</label>\n            </dt>\n            <dd>\n                <select class="text" id="utility-kind" name="utility[utility_kind_id]">\n                    <option value="">\n                        ' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'\n                    </option>\n                </select>\n            </dd>\n            <dt>\n                <label for="utility-indicator">' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'&nbsp;*</label>\n            </dt>\n            <dd>\n                <select class="text" disabled id="utility-indicator" name="utility[indicator_id]">\n                    <option value="">\n                        ' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'\n                    </option>\n                </select>\n            </dd>\n            <div id="utility-extra"></div>\n        </dl>\n    </div>\n</form>\n';

}
return __p
}