WiseMetering.Views.ShowInfraspeakIntegration = WiseMetering.Views.Modal.Show.extend({
    buttons: ['close', 'edit'],
    className: 'mbx-info text-left',
    template: 'templates/integration_services/infraspeak/show',
    title: () => i18next.t('modal.title.integration_services.show'),


    edit: function() {
        this.block();
        _.defer(function() {
            new WiseMetering.Views.EditInfraspeakIntegration({ model: this.model });
        }.bind(this));
    },

    serializeData: function () {
        return Object.assign(
            this.model.toJSON(), {
                organization_name: WiseMetering.currentOrganization.get('name'),
                building_name: this.model.building().get('name'),
                integration_partner_name: this.model.integrationPartner().get('name')
            }
        )
    },
});
