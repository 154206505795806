module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(options, function(option) { ;
__p += '\n    <div class="small-card">\n        <div class="label">' +
((__t = ( option.label )) == null ? '' : __t) +
'</div>\n        <div class="sub-label ' +
((__t = ( option.subLabel ? option.subLabel.style : '' )) == null ? '' : __t) +
'">' +
((__t = ( option.subLabel ? option.subLabel.value : '' )) == null ? '' : __t) +
'</div>\n        <div class="value">' +
((__t = ( option.value )) == null ? '' : __t) +
'</div>\n        <div class="bottom-label green" style="min-height:18px;">' +
((__t = ( option.bottomLabel )) == null ? '' : __t) +
'</div>\n\n        ';
 if (option.progressBar) { ;
__p += '\n            <div class="progress-container">\n                <div class="progress">\n                    <div class="progress-bar" role="progressbar" aria-valuenow="' +
((__t = ( option.progressBar.value )) == null ? '' : __t) +
'" aria-valuemin="0" aria-valuemax="100" style="width:' +
((__t = ( option.progressBar.value )) == null ? '' : __t) +
'%;">\n                        ' +
((__t = ( option.progressBar.value )) == null ? '' : __t) +
'%\n                    </div>\n                </div>\n            </div>\n        ';
 } ;
__p += '\n    </div>\n';
 }) ;
__p += '\n';

}
return __p
}