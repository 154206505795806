Lmit.SectionItem = function(_container_or_section, _title, _uri, _options) {
    this.title = _title;
    this.text = _title;
    this.uri = _uri;
    this.id =  Lmit.Utils.generateCode();
    this.options= _options;
    this.kind= _options.kind || null;
    this.container = _container_or_section;
    this.section = null;
    this.group = _options.group || this.container.group;
    this.element = null;
    this.init();
};

Lmit.SectionItem.prototype = {
    init:function(){
        this.buildItem();
    },

    buildItem:function(){
        this.element = $('<li>').addClass("item " + this.options.style);
        if (this.options.id){ this.element.attr({id:this.options.id});}

        //add title
        if(this.options.icon !== undefined){
            this.element.append($("<div>").addClass("icon " + this.options.icon));
        } else if (this.options.svg !== undefined){
            new Lmit.UI.Icon(this.element, { icon:this.options.svg, w:this.options.w || 32, h:this.options.h || 32, fillColor:this.options.color || "#a1a1a1", scale:this.options.scale || 1, divClass:'svg_icon' });
        } else{
            this.element.append($("<div>"));
        }
        this.element.append($("<div>").addClass("title").text(this.title));

        (this.container instanceof Lmit.Section) ? this.container.addItem(this) : this.container.append(this.element);
    },

    bindEvents:function() {
        var that = this;
        this.element.click(function(e) {
            if(!that.element.hasClass('current')) that.container.selectItem(that,e);
            e.preventDefault();
            e.stopPropagation();
        });
    },

    current:function(){
        this.element.addClass("current");
    },

    idle:function(){
        this.element.removeClass("current");
    }

};

