WiseMetering.Views.Content = Backbone.Marionette.Layout.extend({
    template: 'templates/ui/layout/content',
    regions: {
        menu: '#content-menu',
        banner: '#content-banner',
        body: '#content-body'
    },
    events: {
        'click ul.breadcrumb a': 'openURL',
        'click ul.nav.content a': 'changeTab',
        'click div.dropdown-section.content a': 'changeDropdowTab',
        'click #close-content': 'closeView'
    },
    modelEvents: {
        'change:category_id': 'updateIcon',
        'change:name': 'updateName',
        'destroy': 'closeView'
    },

    getTabs: function() {
        let tabs = this.tabs;

        if (typeof tabs === 'function') {
            tabs = tabs();
        }

        return tabs ? tabs.filter(tab => !tab.dropdownTab) : tabs;
    },

    serializeData: function() {
        let { icon, name } = this;

        if (!icon && this.model && this.model.type) {
            icon = this.model.type();
        }

        if (typeof name === 'function') {
            name = name();
        } else if (!name && this.model) {
            name = this.title || this.model.get('name');
        }

        const tabs = this.getTabs();
        const options = this.getDropdowTabs();
        const activeTab = tabs ? tabs.find(tab => tab.active) : null;
        const bannerInfo = this.bannerInfo ? this.bannerInfo() : null;

        return { activeTab, icon, name, bannerInfo, tabs, options };
    },

    onShow: function() {
        const
            tabs = this.getAllTabs(),
            tab = tabs.find(tab => tab.active) || tabs[0],
            view = tab && tab.view;

        if (view) {
            this.body.show(
                new view({
                    alias: this.alias,
                    model: this.model,
                    period: this.period
                })
            );
        }
    },

    changeDropdowTab: function(event) {
        event.stopPropagation();

        this.$('ul.nav > li.active').removeClass('active');

        const target = $(event.currentTarget),
            option = this.getDropdowTab(target);

        this.showBody(option);
    },

    changeTab: function(event) {
        event.preventDefault();

        const target = $(event.currentTarget);
        if (!target.hasClass('active')) {
            this.$('ul.nav > li').removeClass('active');
            target.parent('li:first').addClass('active');
        }
        const tab = this.getTab(target);
        this.showBody(tab);
    },

    closeView: function() {
        Backbone.history.navigate('/', { trigger: true });
    },

    getDropdowTab: function(target) {
        const
            id = target.prop('id').replace('-tab', ''),
            options = this.getDropdowTabs();

        return options.find(option => option.id === id);
    },

    getDropdowTabs: function() {
        const tabs = this.getAllTabs();
        return tabs ? tabs.filter(tab => tab.dropdownTab) : tabs;
    },

    getTab: function(target) {
        const
            id = target.prop('id').replace('-tab', ''),
            tabs = this.getTabs();

        return tabs.find(tab => tab.id === id);
    },

    getAllTabs: function() {
        let tabs = this.tabs;

        if (typeof tabs === 'function') {
            tabs = tabs();
        }

        return tabs;
    },

    openURL: function(event) {
        event.preventDefault();
        const href = $(event.currentTarget).attr('href');
        if (href) {
            Backbone.history.navigate(`${href}${location.search}`, { trigger: true });
        }
    },

    showBody: function(tab) {
        if (tab) {
            if (tab.modal) {
                tab.modal();
            } else if (tab.href) {
                Backbone.history.navigate(`${tab.href}${location.search}`, { trigger: true });
            } else {
                this.body.show(
                    new tab.view({
                        alias: this.alias,
                        model: this.model,
                        period: this.period
                    })
                );
            }
        } else {
            this.closeView();
        }
    },

    updateIcon: function() {
        if (this.model.icon) {
            this.$('#content-icon').html(WiseMetering.icon(this.model.icon()));
        }
    },

    updateName: function() {
        if (this.model) {
            this.$('#content-name').html(this.model.get('name'));
        }
    }
});
