Lmit.Wise.Chart.RealTime = function(_controller, _container, _settings, _options) {
    var that = this;
    this.init(_controller, _container, _settings, _options);
    if (_options.observers) {
        _.each(_options.observers, function(_observer) {
            that.register(_observer);
        });
    }
};
Lmit.Wise.Chart.RealTime.prototype = new Lmit.Wise.Chart.Generic();
Lmit.Wise.Chart.RealTime.prototype.type_of = 'realtime';
Lmit.Wise.Chart.RealTime.prototype.name = 'Realtime Data';

Lmit.Wise.Chart.RealTime.prototype.show = function() {
    this.plotData();
};

Lmit.Wise.Chart.RealTime.prototype.destroy = function() {
    if (this.chart || this.view.find('.highcharts-container').length > 0) {
        var thiz = this;
        $(this.view).find('.highcharts-container').parent().animate({ opacity: 0 }, 400, function() {
            if (thiz.chart !== null && thiz.chart !== undefined && thiz.chart.destroy) {
                thiz.chart.destroy();
            }
            thiz.view.remove();
        }).delay(400);
    }
};

Lmit.Wise.Chart.RealTime.prototype.plotData = function() {
    this.chart = new Highcharts.Chart(this.highchartsSettings());
};
Lmit.Wise.Chart.RealTime.prototype.axisForUnit = function(_unit) {
    return _.indexOf(this.units, _unit);
};
Lmit.Wise.Chart.RealTime.prototype.onPointClick = function(_point) {
    var thiz = this;
    var indicator = thiz.controller.indicatorController;
    indicator.showEvent(_point);
};

Lmit.Wise.Chart.RealTime.prototype.reloadPlotBands = function() {
    var l = this.controller.plotBands.length;
    for (var i = 0; i < l; i++) {
        this.chart.xAxis[0].addPlotBand(this.controller.plotBands[i]);
    }
};

Lmit.Wise.Chart.RealTime.prototype.addPlotBand = function(_pb) {
    this.controller.plotBands.push(_pb);
    this.chart.xAxis[0].addPlotBand(_pb);
};

Lmit.Wise.Chart.RealTime.prototype.removePlotBand = function(_id) {
    var _ptemp = [];
    var a = this.controller.plotBands.length;
    for (var i = 0; i < a; i++) {
        if (this.controller.plotBands[i].id !== _id) {
            _ptemp.push(this.controller.plotBands[i]);
        }
    }
    this.chart.xAxis[0].removePlotBand(_id);
    this.controller.plotBands = _ptemp;
};

Lmit.Wise.Chart.RealTime.prototype.plotBandExist = function(_id, _point) {
    var a = this.controller.plotBands.length;
    for (var i = 0; i < a; i++) {
        if (this.controller.plotBands[i].id === _id && this.controller.plotBands[i]._ref === _point.options.id) {
            return true;
        }
    }
    return false;
};

Lmit.Wise.Chart.RealTime.prototype.addSeries = function(result) {
    let chartType = this.controller.chartType === null ? 'line' : this.controller.chartType;
    let _value, _min, _max, _avg, _sum, _compactData;
    let _color = result.info.color || Lmit.Wise.Chart.Defaults.defaultPlotColors[result.info.index || 0];
    let chart_params = {
        type: chartType,
        id: result.info.id,
        code: result.info.code,
        name: result.info.name,
        yAxis: this.axisForUnit(result.info.unit),
        unit: this.parseUnit(result.info.unit),
        kind: result.info.kind,
        color: _color,
        showInLegend: true
    };

    const indicator = WiseMetering.indicators.get(result.info.id);
    const timezone = indicator?.timezone() || result.args.timezone;

    if (result.rate <= 3600 && !(result.data[0] instanceof Array)) {
        chart_params.pointInterval = (result.rate * 1000);
        let start;
        if (!!result.isHomologous) {
            start = moment.tz(result.from * 1000, timezone).add(1, 'years').unix() * 1000;
        } else {
            start = result.from * 1000;
        }
        chart_params.pointStart = start;
        chart_params.data = result.data;
        _compactData = _.without(result.data, null);
    } else {
        chart_params.data = _.map(result.data, function(item) {
            let timestamp = item[0];
            if (!!result.isHomologous) {
                timestamp = moment.tz(item[0] * 1000, timezone).add(1, 'years').unix();
            }
            return [timestamp * 1000, item[1]];
        });

        _compactData = _.without(_.map(result.data, function(data) {
            return data[1];
        }), null);
    }

    _value = _.last(_compactData);
    _min = _.min(_compactData).toFixed(2);
    _max = _.max(_compactData).toFixed(2);

    _sum = null;

    var tariff = WiseMetering.tariffs.get(result.info.id);

    if ((indicator && indicator.kind().get('cumulative')) || tariff) {
        _sum = _.reduce(_compactData, function(m, n) {
            return m + n;
        }, 0);
    }

    _avg = 0;
    if (_compactData.length) {
        _avg = (_.reduce(_compactData, function(m, n) {
            return m + n;
        }, 0) / _compactData.length).toFixed(2);
    }

    this.chart.addSeries(chart_params);

    this.addResumeInfo({
        value: _value,
        max: _max,
        color: _color,
        min: _min,
        sum: _sum,
        avg: _avg,
        unit: this.parseUnit(result.info.unit),
        id: result.info.code,
        index: result.info.index || 0
    });
};

Lmit.Wise.Chart.RealTime.prototype.parseUnit = function(_unit) {
    switch (_unit) {
        case 'N/A':
            return _unit;
        case '€':
            return '€';
        case '£':
            return '£';
        case 'Kz':
            return 'Kz';
        case '$':
            return '$';
        case 'R$':
            return 'R$';
        default:
            return (this.wrapper !== null && this.wrapper !== 'N/A' && this.wrapper !== undefined) ? _unit + '/' + this.wrapper : _unit;
    }
};

Lmit.Wise.Chart.RealTime.prototype.addResumeInfo = function(args) {
    this.controller.resumeController.add([args]);
};

Lmit.Wise.Chart.RealTime.prototype.highchartsSettings = function() {
    var that = this;
    const timezone = WiseMetering.indicators.get(this.uid).timezone();

    var chartSettings = {
        time: {
            timezone
        },
        chart: {
            renderTo: this.id,
            events: {
                addSeries: function() {
                    $(this.container).parent().delay(400).animate({ opacity: 1 }, 400);
                }
            }
        }
    };
    jQuery.extend(true, chartSettings, this.highchartsDefaultSettings());

    _.each(this.units, function(_unit, i) {
        chartSettings.yAxis.push({
            tickColor: '#8bc53f',
            tickWidth: 2,
            id: i,
            opposite: i % 2 === 1,
            minorGridLineWidth: 0,
            minorTickInterval: 'auto',
            minorTickColor: '#A3A3A3',
            title: {
                text: ''
            },
            labels: {
                style: {
                    fontSize: '9px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'none'
                },
                formatter: function() {
                    return Lmit.Utils.formatNumber(this.value, that.parseUnit(_unit));
                },
                align: i % 2 === 1 ? 'right' : 'left',
                x: i % 2 === 1 ? -5 : 5, y: -2
            }
        });
    });
    chartSettings.yAxis.push({
        labels: {
            enabled: false
        },
        endOnTick: true,
        max: 0,
        min: 0,
        startOnTick: true,
        id: 'event',
        opposite: true,
        title: {
            text: ''
        }
    });

    return chartSettings;
};

Lmit.Wise.Chart.RealTime.prototype.highchartsDefaultSettings = function() {
    const that = this;
    return {
        chart: {
            backgroundColor: '#FFFFFF',
            margin: [20, 30, 70, 30],
            zoomType: 'x',
            events: {
                selection: function(event) {
                    const extremesObject = event.xAxis[0], min = extremesObject.min, max = extremesObject.max;
                    that.notifyObservers(new Date(min), new Date(max));
                    return false;
                }
            }
        },
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: true,
            symbolWidth: 10,
            borderWidth: 0,
            labelFormatter: function() {
                return this.name;
            },
            align: 'center',
            itemStyle: {
                fontSize: '10px',
                color: '#000000',
                fontWeight: 'normal'
            },
            events: {
                mouseOver: function(item) {
                    that.controller.resumeController.highlight([null, null, null, null, item.options.code]);
                },

                mouseOut: function(item) {
                    that.controller.resumeController.removeHighlight();
                }
            }
        },
        plotOptions: {
            series: {
                events: {
                    show: function(event) {
                        that.onShow(this.options.code);
                    },
                    hide: function(event) {
                        that.onHide(this.options.code);
                    }
                },
                point: {
                    events: {
                        mouseOut: function() {
                            that.onMouseOut();
                        },
                        mouseOver: function() {
                            if (this.series.type === 'scatter') {
                                return false;
                            }
                            const value = formatValue(this.y, this.series.options.unit);
                            that.onMouseOver(this.x, value, this.series.options.name, this.series.options.color, this.series.options.code);
                        },
                        click: function() {
                            if (this.series.type !== 'scatter') {
                                return false;
                            }
                            that.onPointClick(this);
                        }
                    }
                }
            },
            line: {
                animation: false,
                lineWidth: 1,
                shadow: false,
                step: true,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true,
                            fillColor: '#8bc53f',
                            lineColor: '#8bc53f',
                            radius: 3
                        }
                    }
                },
                states: {
                    hover: {
                        lineWidth: 0
                    }
                }
            },
            column: {},
            scatter: {
                cursor: 'pointer',
                marker: {
                    radius: 4,
                    symbol: 'triangle'
                }
            }
        },
        series: [],
        xAxis: {
            lineColor: '#797979',
            type: 'datetime',
            dateTimeLabelFormats: {
                minute: '%e. %b, %H:%M',
                hour: '%e %b, %Hh',
                day: '%e. %b',
                week: '%e. %b'
            },
            tickPixelInterval: 100,
            tickColor: '#797979',
            plotLines: [{
                color: 'red',
                width: 2,
                dashStyle: 'dashed'
            }],
            labels: {
                x: 0,
                y: 20,
                style: {
                    fontSize: '10px',
                    color: 'black',
                    fontWeight: 'normal'
                }
            }
        },
        yAxis: [],
        tooltip: {
            borderRadius: 0,
            borderColor: '#A1A1A1',
            borderWidth: 1,
            shadow: false,
            snap: 10,
            style: {
                color: 'black',
                fontSize: '10px',
                whiteSpace: 'normal',
                padding: '10px',
                width: 100
            },
            enabled: true,
            crosshairs: {
                width: 1,
                color: '#8bc53f',
                dashStyle: 'shortdot'
            },
            formatter: function() {
                if (this.series.type === 'scatter') {
                    return '<p>' + this.point.name + '<br>' + this.point.options.from + '</p>';
                } else {
                    return false;
                }
            }
        },
        exporting: {
            enabled: true,
            buttons: {
                printButton: {
                    enabled: false
                }
            }
        }
    };

};
