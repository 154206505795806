import queryString from 'query-string';

window.ParamsService = {
    from: moment().format('YYYY.01.01'),
    rate: 86400,
    to: moment().format('YYYY.MM.DD'),
    events: _.extend({}, Backbone.Events),

    setParams: function(params) {
        const
            newParams = Object.assign(
                queryString.parseUrl(window.location.href).query,
                { from: this.from, rate: this.rate, to: this.to },
                params
            ),
            oldParams = Object.assign({}, { from: this.from, rate: this.rate, to: this.to });

        this.from = newParams.from;
        this.rate = parseInt(newParams.rate);
        this.to = newParams.to;

        const newUrl = WiseMetering.utils.replaceQueryString(newParams);
        Backbone.history.navigate(newUrl, { replace: true, trigger: false });

        if (this.rate !== oldParams.rate) {
            this.events.trigger('change:rate', this.rate);
        }

        if (this.from !== oldParams.from) {
            this.events.trigger('change:from', this.from);
        }

        if (this.to !== oldParams.to) {
            this.events.trigger('change:to', this.to);
        }
    }
};
