import queryString from 'query-string';

window.searchService = {
    initialized: false,
    searches: {},

    fetch: function(entry) {
        if (this.initialized) {
            return this.searches[entry] || '';
        }

        this.initialized = true
        this.searches[entry] = queryString.parseUrl(window.location.href).query.search || '';
        return this.searches[entry];
    },

    update: function(entry, searchValue) {
        this.searches[entry] = searchValue;

        const newParams = Object.assign(queryString.parseUrl(window.location.href).query, { search: searchValue });
        if (!newParams.search) {
            delete newParams.search;
        }

        Backbone.history.navigate(WiseMetering.utils.replaceQueryString(newParams), { replace: true, trigger: false });
    }
};
