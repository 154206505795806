WiseMetering.Views.ShowGroup = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/groups/details',
    className: 'mbx-info text-left',
    title: () => i18next.t('modal.title.groups.show'),

    buttons: function() {
        const buttons = ['close'];
        if (WiseMetering.currentUser.get('administrator')) buttons.push('edit');
        return buttons;
    },

    edit: function() {
        const that = this;
        that.block();
        _.defer(function() { new WiseMetering.Views.ManageGroup({ model: that.model }); });
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json.users = this.model.users().pluck('name').join(', ');
        json.buildings = this.model.buildings().pluck('name').join(', ');
        return json;
    }
});
