module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dt>\n    <label for=\'etl_dsn\'>ShopperTrak ID</label>\n</dt>\n<dd>\n    <input class=\'text\' id=\'etl_dsn\' name=\'etl[dsn]\' placeholder=\'Shoppertak ID eg: PTR123 \'>\n</dd>\n<dt>\n    <label for=\'etl_username\'>' +
((__t = ( i18next.t('etls.connection_username') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input class=\'text\' id=\'etl_username\' name=\'etl[username]\'>\n</dd>\n<dt>\n    <label for=\'etl_secret\'>' +
((__t = ( i18next.t('common.password') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input class=\'text\' id=\'etl_secret\' name=\'etl[secret]\'>\n</dd>\n<dt>\n    <label for=\'etl_indicators\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'etl_indicators\' name=\'etl[indicator_id]\'>\n        ';
 Object.keys(indicators).sort().forEach(function(buildingName) { ;
__p += '\n            <optgroup label=\'' +
((__t = ( buildingName )) == null ? '' : __t) +
'\'>\n                ';
 indicators[buildingName].forEach(function(indicator) { ;
__p += '\n                    <option value=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\'>' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </optgroup>\n        ';
 }); ;
__p += '\n    </select>\n</dd>\n\n<input class=\'text\' hidden id="etl_start_at" maxlength="10" type="text" name=\'etl[start_at]\' value="' +
((__t = ( start_at )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id="etl_rate" type="number" name=\'etl[rate]\' value="' +
((__t = ( rate )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id="etl_mode" type="text" name=\'etl[mode]\' value="' +
((__t = ( mode )) == null ? '' : __t) +
'">\n';

}
return __p
}