module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="widget-options-wrapper">\n    <div class="title">\n        <div class="title-text">\n            ' +
((__t = ( i18next.t('common.options').capitalize() )) == null ? '' : __t) +
' - ' +
((__t = ( title )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <div class="mbx-info text-left">\n        <form>\n            <dl class=\'stepped zero\'>\n                <dt style=\'margin-bottom: 0.5em;\'>\n                    <input ' +
((__t = ( preferences.hidden ? 'checked' : '' )) == null ? '' : __t) +
' id=\'user_preferences_hidden\' name=\'user_preferences[hidden]\' type=\'checkbox\'>\n                    <label for=\'user_preferences_hidden\'>' +
((__t = ( i18next.t('common.hide').capitalize() )) == null ? '' : __t) +
'</label>\n                </dt>\n            </dl>\n        </form>\n    </div>\n    <div class=\'widget-options-buttons\'>\n        <input class=\'widget-options-button\' id=\'save\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.save') )) == null ? '' : __t) +
'\'>\n        <input class=\'widget-options-button\' id=\'cancel\' type=\'button\' value=\'' +
((__t = ( i18next.t('ui.buttons.cancel') )) == null ? '' : __t) +
'\'>\n    </div>\n</div>\n\n';

}
return __p
}