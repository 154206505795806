WiseMetering.Views.NewLoraEtl = Backbone.Marionette.ItemView.extend({
    template: 'templates/etls/types/lora',

    serializeData: function() {
        return {
            rate: WiseMetering.etlRates[0],
            start_at: moment().format('YYYY-MM-DD'),
            mode: 'remote'
        };
    }
});
