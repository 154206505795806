module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dt>\n    <label for="indicator_base_temperature">' +
((__t = ( i18next.t('indicators.base_temperature') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input id=\'indicator_base_temperature\' class="text" name="indicator[base_temperature]" value=\'' +
((__t = ( base_temperature )) == null ? '' : __t) +
'\'/>\n</dd>\n';

}
return __p
}