module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'basic-widget grid-half\'>\n    <div class=\'title\'>\n        <span class=\'basic-widget-options\' id=\'widget-options-value\'>' +
((__t = ( WiseMetering.icon('gear') )) == null ? '' : __t) +
'</span>\n    </div>\n    <button class="carousel-button prev" id="button-prev">❮</button>\n    <div class="carousel-container">\n        <div class="carousel-wrapper" id="charts"></div>\n    </div>\n    <button class="carousel-button next" id="button-next">❯</button>\n</div>\n';

}
return __p
}