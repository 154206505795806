WiseMetering.Collection.Indicators = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Indicator,
    url: '/indicators',

    etl: function() {
        return new WiseMetering.Collection.Indicators(this.where({ type: 'etl' }));
    },

    globalIndicators: function() {
        return new WiseMetering.Collection.Indicators(this.filter(function(i) {
            const type = i.get('type');
            return i.get('building_id') === null && (type === 'complex' || type === 'raw');
        }));
    },

    weatherApiIndicators: function() {
        return new WiseMetering.Collection.Indicators(this.where({ type: 'weather' }));
    },

    degreeDaysIndicators: function() {
        return new WiseMetering.Collection.Indicators(this.where({ type: 'degree-day' }));
    },

    raw: function() {
        return new WiseMetering.Collection.Indicators(this.where({ type: 'raw' }));
    },

    indicatorsByUtilityKind: function( utilityKindSlug ) {
        let kindSlug = utilityKindSlug === 'electricity' ? 'active_energy' : utilityKindSlug;
        const indicatorKind = WiseMetering.indicatorKinds.findWhere({ slug: kindSlug });

        return WiseMetering.indicators.where({kind_id: indicatorKind.id});
    },

    buildingLevelIndicators: function(utilityKind = null) {
        let parentIds =  WiseMetering.zones.buildings().pluck('id');

        if (utilityKind === 'electricity') {
            utilityKind = 'active_energy';
        }

        let indicators = WiseMetering.indicators.filter(indicator => {
            let isUtility = indicator.hasUtility();
                isBuilding = parentIds.includes(indicator.get('parent_id')),
                filterByUtilityKind = utilityKind ? (indicator.get('kind_slug') === utilityKind) : true;

            return isUtility && isBuilding && filterByUtilityKind;
        });

        return new WiseMetering.Collection.Indicators(indicators);
    }
});
