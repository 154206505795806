module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'zone_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'zone_name\' name=\'zone[name]\' type=\'text\'>\n        </dd>\n        <dt>\n            <label for=\'zone_category_id\'>' +
((__t = ( i18next.t('common.category') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'zone_category_id\' name=\'zone[category_id]\'>\n                <option></option>\n            </select>\n        </dd>\n        ';
 if (isBuilding){ ;
__p += '\n            <dt>\n                <label for=\'zone_tariff_id\'>Tariff</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'zone_tariff_id\' name=\'zone[tariff_id]\'>\n                    <option></option>\n                </select>\n            </dd>\n        ';
 } ;
__p += '\n        <dt>\n            <label for=\'zone_description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text\' id=\'zone_description\' name=\'zone[description]\'></textarea>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}