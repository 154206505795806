WiseMetering.Views.ManageCircuitConditional = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/circuits/manageConditional',
    form_prefix: 'circuit',
    title: () => i18next.t('modal.title.circuits.manage_conditional'),
    className: 'mbx-info text-left',

    serializeData: function() {
        return {
            availableInputs: this.options.availableInputs,
            currentInput: this.model.get('input') || {}
        };
    },

    submitData: function(data) {
        return this.model.save(data, {
            wait: true,
            patch: true,
            type: 'PUT',
            url: this.model.url() + '/set_input'
        });
    }
});
