module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion-group\'>\n    <div class=\'accordion-heading\'>\n        <div class=\'accordion-toggle\'>\n            <div class=\'svg_icon\'>\n                ' +
((__t = ( WiseMetering.icon('devices') )) == null ? '' : __t) +
'\n            </div>\n            WiseBoxes\n        </div>\n    </div>\n    <div class=\'accordion-body\'>\n        <div class=\'accordion-inner\'>\n            <div class=\'per-box\'>\n                <dl>\n                    ';
 wiseBoxes.forEach(function(wiseBox) { ;
__p += '\n                        <dt class=\'wisebox-name\'>' +
((__t = ( wiseBox.get('name') )) == null ? '' : __t) +
'</dt>\n                        <dd>Firmware ' +
((__t = ( wiseBox.get('firmware_version') )) == null ? '' : __t) +
'</dd>\n                    ';
 }) ;
__p += '\n                </dl>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}