WiseMetering.Views.Ui.Widget.Omie = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/objective',

    initialize: function(params) {
        params.unit = params.unit ? params.unit : WiseMetering.getCurrency();
        WiseMetering.Views.Ui.Widget.Base.prototype.initialize.call(this, params);
    },

    values: function(data) {
        const
            length = data.length,
            values = length < 360 ? this.data.map(el => [el.t * 1000, el.v]) : this.reduceArray(data);

        return values;
    },

    reduceArray: function(data){
        const
            reducedArray = [],
            targetLength = 360,
            chunkSize = Math.ceil(data.length / targetLength);

        for (let i = 0; i < data.length; i += chunkSize){
            const
                chunk = data.slice(i, i + chunkSize),
                sum = chunk.reduce((acc, val) => acc + val.v, 0),
                average = sum / chunk.length;

            reducedArray.push({x: chunk[0].t * 1000, y: average, z: (chunk[chunk.length - 1].t * 1000) - 1000});
        }

        return reducedArray;
    },

    drawChart: function() {
        const
            { unit, data } = this,
            values = this.values(data);

        this.chart = new Highcharts.Chart(jQuery.extend(true, {}, this.defaultChartOptions, {
            xAxis: {
                type : "datetime",
                labels: {
                    formatter: function() {
                        return moment(this.value).format('ll')
                    }
                }
            },
            yAxis: {
                labels: {
                    formatter: point => formatValue(point.value, unit)
                },
                title: {
                    enabled: true,
                    text: `${WiseMetering.getCurrency()}/KWh`
                },
                min: null
            },
            tooltip: {
                formatter: function() {
                    let times = '';

                    if (this.point.options.z) {
                        times = `<span style="color: #777777"> ${moment(this.x).format('lll')}</span> -
                                <span style="color: #777777"> ${moment(this.point.options.z).format('lll')}</span>`
                    } else {
                        times =
                            `<span style="color: #777777"> ${moment(this.x).format('lll')}</span>`
                    };

                    return `
                        ${times}<br>
                        <span style="font-weight: bold; color: ${this.point.color}">
                            ${i18next.t('common.cost').capitalize()}: ${ formatValue(this.y, WiseMetering.getCurrency(), 3) }
                        </span>
                    `;
                }
            },
            series: [
                {
                    turboThreshold: 'number',
                    type: 'spline',
                    data: values,
                    color: this.colors[0],
                    marker: {
                        enabled: false
                    }
                }
            ]
        }));
    }
});
