import LoginPage from '@/pages/Login';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

WiseMetering.Views.Login = Backbone.Marionette.View.extend({
		id: 'react-container',
        className: 'tailwind-container',

		onShow: function() {
            const root = createRoot(document.getElementById(this.id));

            root.render(<LoginPage initialAuthState={this.options.initialAuthState} code={ this.options.code } />);
		}
});
