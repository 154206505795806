module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'tenant_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'tenant_name\' name=\'tenant[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'company\'>' +
((__t = ( i18next.t('glossary.company') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'company\' name=\'tenant[company_id]\' class=\'companies text\'>\n                ';
 WiseMetering.companies.each(function (company) { ;
__p += '\n                    <option ' +
((__t = ( company.id === company_id ? 'selected' : '' )) == null ? '' : __t) +
' value=\'' +
((__t = ( company.id )) == null ? '' : __t) +
'\'>' +
((__t = ( company.get("name") )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'tenant_area\'>' +
((__t = ( i18next.t('common.area') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'tenant_area\' name=\'tenant[area]\' type=\'text\' value=\'' +
((__t = ( area )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'tenant_day\'>' +
((__t = ( i18next.t('tenants.invoicing_day_of_month') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'tenant_day\' name=\'tenant[day]\' type=\'text\' value=\'' +
((__t = ( day )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'tenant_interval\'>' +
((__t = ( i18next.t('tenants.invoicing_interval') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'tenant_interval\' name=\'tenant[interval]\' class=\'intervals text\'>\n                ';
 WiseMetering.tenantIntervals.forEach(function (tenantInterval) { ;
__p += '\n                    <option ' +
((__t = ( tenantInterval === interval ? 'selected' : '' )) == null ? '' : __t) +
' value=\'' +
((__t = ( tenantInterval )) == null ? '' : __t) +
'\'>' +
((__t = ( tenantInterval )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n\n        <dt>\n            <label for=\'tenant_invoicing_notes\'>' +
((__t = ( i18next.t('tenants.invoicing_notes') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text\' id=\'tenant_invoicing_notes\' name=\'tenant[invoicing_notes]\'>' +
((__t = ( invoicing_notes )) == null ? '' : __t) +
'</textarea>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}