module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('special_features') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('organizations.special_features') )) == null ? '' : __t) +
'\n                ';
 if (WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n                    <div class=\'wmui-btn\' id=\'edit_special_features_attributes\' style=\'float: right;\'>\n                        ' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                <div class=\'per-box\'>\n                    <dl>\n                        ';
 if (hasSpecialFeatures) { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('glossary.automation') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( i18next.t(`common.${automation ? 'yes' : 'no'}`) )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n                        <dt>' +
((__t = ( i18next.t('organizations.password_valid_within') )) == null ? '' : __t) +
':</dt>\n                        ';
 if (password_valid_within <= 365) { ;
__p += '\n                            <dd>' +
((__t = ( password_valid_within )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t('common.days') )) == null ? '' : __t) +
'</dd>\n                            ';
 } else { ;
__p += '\n                            <dd>' +
((__t = ( i18next.t('organizations.never_expire') )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n                    </dl>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}