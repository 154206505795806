import axios from 'axios';

export const apiRequest = (url = '/', method = 'GET', data) => {
    const requestOptions = {
        method: method,
        url: process.env.API_URL + url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*'
        },
        withCredentials: true
    };

    if (data) {
        requestOptions['data'] = data;
    }

    return axios(requestOptions);
};
