import * as React from 'react';
import { Button, Content, ContentHeader, Form, FormLabel, PasswordInput, PasswordRequirements } from '@/components/wm';
import { apiRequest } from '@/utils/request';
import { useState } from 'react';

export function Password() {
    const currentUser = WiseMetering.currentUser;
    const
        [loading, setLoading] = useState(false),
        [currentPassword, setCurrentPassword] = useState(''),
        [newPassword, setNewPassword] = useState(''),
        [passwordConfirmation, setPasswordConfirmation] = useState(''),
        [canSubmit, setCanSubmit] = useState(false);

    const handlePasswordChange = (newPwd, confirmPwd) => {
        setNewPassword(newPwd);
        setPasswordConfirmation(confirmPwd);
    };

    const handleRequirementsMet = (isMet) => {
        setCanSubmit(isMet && currentPassword !== '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            current_password: currentPassword,
            password: newPassword,
            password_confirmation: passwordConfirmation
        };

        try {
            await apiRequest(currentUser.url() + '/change_password', 'PUT', data);

            WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_saved'));
        } catch(err) {
            const errorMessage = err.response?.data?.error?.capitalize() || 'An error occurred';

            WiseMetering.layout.showTipper('error', errorMessage);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
        <Content>
            <ContentHeader icon="password" title={ i18next.t('modal.title.passwords.edit').capitalize() } />
            <Form>
                <FormLabel htmlFor="current-password">{ i18next.t('common.current_password') }</FormLabel>
                <PasswordInput
                    id="current-password"
                    required
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <FormLabel htmlFor="new-password">{ i18next.t('common.new_password') }</FormLabel>
                <PasswordInput
                    id="new-password"
                    required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <FormLabel htmlFor="confirm-password">{ i18next.t('glossary.password_confirmation') }</FormLabel>
                <PasswordInput
                    id="confirm-password"
                    required
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                <PasswordRequirements
                    newPassword={newPassword}
                    confirmPassword={passwordConfirmation}
                    onPasswordChange={handlePasswordChange}
                    onRequirementsMet={handleRequirementsMet}
                />
                <div className="tw-mt-6">
                    <Button type="submit" loading={loading} className="tw-w-fit" onClick={handleSubmit} disabled={!canSubmit}>
                        { i18next.t('ui.buttons.save') }
                    </Button>
                </div>
            </Form>
        </Content>
    );
}
