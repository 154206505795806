// Wise Indicator
Lmit.Wise.Chart.Control.Activity = function (_controller_, _container_, _options_) {
    this.options = _options_;
    this.controller = _controller_;
    this.container = _container_;
    // view elements
    this.view = null;
    this.iconElement = null;
    this.textElement = null;
    this.message = "";
    this.queue = null;
    this.state = "idle";
    this.retain = 0;
    this.init();
};

Lmit.Wise.Chart.Control.Activity.prototype = {
    init: function () {
        this.view = $('<div>').addClass("wmui-activity-box " + this.style || "");
        this.queue = [];
        this.iconElement = $('<img>').addClass('wmui-icon-activity').attr({ src: "/images/wisemetering/loader.svg" });
        this.textElement = $('<h4>').text(this.message);
        this.view.append(this.iconElement);
        this.view.append(this.textElement);
        this.container.append(this.view);
        this.view.hide();
    },

    getStyle: function () {
        return this.state;
    },

    setState: function (_state, _msg, _callback) {
        this.message = _msg;
        this.state = _state;
        this.show(_callback);
    },

    show: function (_callback) {
        this.view.attr({ 'class': 'wmui-chart-control-activity ' + this.style });
        if (this.message === 'Loading') {
            this.view.addClass('loading');
            this.textElement.hide();
            this.iconElement.show();
        }
        else {
            this.view.removeClass('loading');
            this.textElement.show().text(this.message);
            this.iconElement.hide();
        }
        this.retain++;
        $(this.view).show(); if (_callback) { _callback(); }
    },


    idle: function () {
        this.retain--;
        if (this.state != "idle") {
            if (this.retain === 0) { $(this.view).fadeOut(500); }
        }
    }
}
