Lmit.UI.Loader = function(_target, _options){
  this.target = _target;
  this.element = null;
  this.options = _options || {};
  this.msg = this.options.text || null;
  this.init();
};

Lmit.UI.Loader.prototype = {

  init: function(){
          // this.target.empty();
          this.add();
        },

  add: function(){
         this.element=$('<div>').addClass("ldr_il crn_all");
         if (this.options.style){ this.element.css(this.options.style);}
         var _ldr_=$("<div>").css({'background':this.options.color || '#eeeeee', width:0, height:'6px'});
         this.element.append(_ldr_);
         this.target.append(this.element);
         this.animateLoader(_ldr_);
       },

  remove: function(){
            this.element.remove();
          },

  animateLoader: function(_ldr){
                   _ldr.css({width:0});
                   _ldr.animate({width: "100%"}, 10000, 'linear', function(){animateLoader(_ldr);});
                 }



};

