module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'holiday_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'holiday_name\' name=\'holiday[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}