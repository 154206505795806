WiseMetering.Views.ContractDetailsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/contracts/details_accordion',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click #edit_attributes': 'editAttributes'
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json.contractedPower = this.model.formatContractedPower();
        json.lastChange = this.model.lastChange();
        json.tariff = this.model.tariff();
        return json;
    },

    editAttributes: function(event) {
        event.stopPropagation();
        new WiseMetering.Views.EditContract({ model: this.model });
    }
});
