module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    ';
 if (isNew) { ;
__p += '\n        <input name=\'group[organization_id]\' type=\'hidden\' value=\'' +
((__t = ( WiseMetering.currentOrganization.id )) == null ? '' : __t) +
'\'>\n    ';
 } ;
__p += '\n    <dl class=\'stepped zero\'>\n        <div style=\'width: 100%; margin-bottom: 5px;\'>\n            <dt>\n                <label for=\'group_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'group_name\' name=\'group[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n            </dd>\n            <dt>\n                <label for=\'group_description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <textarea class=\'text small\' id=\'group_description\' name=\'group[description]\'>' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n            </dd>\n        </div>\n        <div style=\'width:200px; max-width:200px; min-width:200px; float:left; padding-right:14px;\'>\n            <dt>\n                <input id=\'all_users\' type=\'checkbox\'>\n                <label for=\'all_users\'>' +
((__t = ( i18next.t('glossary.users').capitalize() )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <div id=\'users\'>\n                    ';
 if(allUsers.length === 0){ ;
__p += '\n                        There are no users available.\n                    ';
 } else { ;
__p += '\n                        ';
 allUsers.each(function(user){ ;
__p += '\n                            <input ' +
((__t = ( myUsers.contains(user) ? 'checked' : '' )) == null ? '' : __t) +
' id=\'' +
((__t = ( user.id )) == null ? '' : __t) +
'\' name=\'group[user_ids][]\' style=\'margin-left: 0;\' type=\'checkbox\' value=\'' +
((__t = ( user.id )) == null ? '' : __t) +
'\'>\n                            <label for=\'' +
((__t = ( user.id )) == null ? '' : __t) +
'\'>' +
((__t = ( user.get("name") + " (" + user.role() + ")" )) == null ? '' : __t) +
'<br></label>\n                        ';
 }); ;
__p += '\n                    ';
 } ;
__p += '\n                </div>\n            </dd>\n\n            <dt>\n                <input id=\'all_api_keys\' type=\'checkbox\'>\n                <label for=\'all_api_keys\'>' +
((__t = ( i18next.t('glossary.api_keys') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <div id=\'api_keys\'>\n                    ';
 if(allApiKeys.length === 0){ ;
__p += '\n                        There are no users available.\n                    ';
 } else { ;
__p += '\n                        ';
 allApiKeys.each(function(key){ ;
__p += '\n                            <input ' +
((__t = ( myUsers.contains(key) ? 'checked' : '' )) == null ? '' : __t) +
' id=\'' +
((__t = ( key.id )) == null ? '' : __t) +
'\' name=\'group[user_ids][]\' style=\'margin-left: 0;\' type=\'checkbox\' value=\'' +
((__t = ( key.id )) == null ? '' : __t) +
'\'>\n                            <label for=\'' +
((__t = ( key.id )) == null ? '' : __t) +
'\'>' +
((__t = ( key.get("name") )) == null ? '' : __t) +
'<br></label>\n                        ';
 }); ;
__p += '\n                    ';
 } ;
__p += '\n                </div>\n            </dd>\n        </div>\n        <div style=\'width:200px; max-width:200px; min-width:200px; float:left;\'>\n            <dt>\n                <input id=\'all_buildings\' type=\'checkbox\'>\n                <label for=\'all_buildings\'>' +
((__t = ( i18next.t('glossary.buildings').capitalize() )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <div id=\'buildings\'>\n                    ';
 if(allBuildings.length === 0){ ;
__p += '\n                        There are no buildings available.\n                    ';
 } else { ;
__p += '\n                        ';
 allBuildings.each(function(building){ ;
__p += '\n                            ';
 if(myBuildings.contains(building)){ ;
__p += '\n                                <input checked=\'checked\' id=\'' +
((__t = ( building.id )) == null ? '' : __t) +
'\' name=\'group[building_ids][]\' style=\'margin-left: 0;\' type=\'checkbox\' value=\'' +
((__t = ( building.id )) == null ? '' : __t) +
'\'>\n                            ';
 } else { ;
__p += '\n                                <input id=\'' +
((__t = ( building.id )) == null ? '' : __t) +
'\' name=\'group[building_ids][]\' style=\'margin-left: 0;\' type=\'checkbox\' value=\'' +
((__t = ( building.id )) == null ? '' : __t) +
'\'>\n                            ';
 } ;
__p += '\n                            <label for=\'' +
((__t = ( building.id )) == null ? '' : __t) +
'\'>\n                                ' +
((__t = ( building.get("name") )) == null ? '' : __t) +
'\n                                <br>\n                            </label>\n                        ';
 }); ;
__p += '\n                    ';
 } ;
__p += '\n                </div>\n            </dd>\n        </div>\n    </dl>\n</form>\n';

}
return __p
}