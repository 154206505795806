WiseMetering.Model.Tenant = WiseMetering.Model.extend({
    urlRoot: '/tenants',

    utilityKindToKind: {
        'Electricity': 'active_energy',
        'Gas Energy': 'gas_energy',
        'Hot Water Energy': 'hot_water_energy',
        'Cooled Water Energy': 'cooled_water_energy',
        'Water': 'water',
        'Gas Volume': 'gas_volume',
        'Oil': 'oil'
    },

    building: function () {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    children: function () {
        let children = new Backbone.Collection;
        children.add(this.tenants().models.reverse());
        return children;
    },

    company: function () {
        return WiseMetering.companies.get(this.get('company_id'));
    },

    icon: function () {
        return 'tenants';
    },

    indicatorsByUtilityKind: function (utilityKindName) {
        let kind = this.utilityKindToKind[utilityKindName], filter;

        if (this.get('building_id')) {
          filter = { building_id: this.get('building_id'), kind_slug: kind };
        } else {
          filter = { kind_slug: kind }
        }
        return WiseMetering.indicators.where(filter);
    },

    nodeUrl: function (skipSearch) {
        return `/tenants/${this.get('id')}${skipSearch ? '' : location.search}`;
    },

    allTenantsNodeUrl: function (skipSearch) {
        return `/all_tenants/${this.get('id')}${skipSearch ? '' : location.search}`;
    },

    parent: function () {
        return WiseMetering.rootObjects.findWhere({building_id: this.get('building_id'), slug: 'tenants'});
    },

    tariffs: function () {
        return new WiseMetering.Collection.Tariffs(WiseMetering.tariffs.where({organization_id: this.get('organization_id')}));
    },

    tenants: function () {
        return new Backbone.Collection(WiseMetering.tenants.where({parent_id: this.id}));
    },

    timezone: function () {
        return this.building() ? this.building().get('timezone') : WiseMetering.currentOrganization.get('timezone');
    },

    type: function () {
        return 'tenant';
    }
});
