// Wise Indicator
Lmit.Wise.Chart.Control.Legend = function(controller, data, container, options) {
    this.options = options;
    this.data = data;
    this.controller = controller;
    this.container = container;
    this.id = Lmit.Utils.generateCode();
    this.items = [];
    this.list = null;
    this.chart = null;
    this.result = null;
    this.init();
};

Lmit.Wise.Chart.Control.Legend.prototype = {
    init: function() {
        if (this.container instanceof Lmit.Section) {
            this.element = $('<li>').addClass('wmui-chart-legend');
            this.container.addItem(this);
        } else {
            this.element = $('<div>').addClass('wmui-chart-legend');
            this.container.append(this.element);
        }
        this.build();
    },

    build: function() {
        this.list = $("<ul>");
        this.element.append(this.list);
    },

    addSeries: function(args) {
        args.forEach(arg => this.addItem(arg));
    },

    isViewActive: function(id) {
        return this.controller.controller.chartController.dataExists(id);

    },

    iconParamsForView: function(id) {
        if (this.isViewActive(id)) {
            return {
                icon: "view_on",
                fillColor: "#000000",
                strokeColor: "#ffffff",
                strokeWidth: 1,
                w: 16,
                h: 16,
                scale: 0.5,
                offset: [0, 0],
                delegate: viewIconDelegate
            };
        } else {
            return {
                icon: "view_off",
                fillColor: "#e3e3e3",
                strokeColor: "#000000",
                strokeWidth: 2,
                w: 16,
                h: 16,
                scale: 0.5,
                offset: [0, 0],
                delegate: viewIconDelegate
            };
        }
    },

    addItem: function(args) {
        this.items.push(args);
        window.viewIconDelegate = {
            onSelect: icon => {
                this.toggle(args);
                icon.attr(this.iconParamsForView(args.id));
            }

        };

        let openIconDelegate = {
            onSelect: () => Backbone.history.navigate(`/indicators/${args.id}/plot`, { trigger: true })
        };

        let item = $("<li class='component'>").addClass("item");
        item.append($("<div>").addClass("color-box").css({ backgroundColor: args.color }));
        new Lmit.UI.Icon(item, this.iconParamsForView(args.id));

        item.append($("<div class='label'>").text(args.name));
        this.list.append(item);
        new Lmit.UI.Icon(item, {
            icon: "jump",
            fillColor: "#a1a1a1",
            strokeColor: "#ffffff",
            w: 16,
            h: 16,
            scale: 0.5,
            offset: [0, 0],
            delegate: openIconDelegate,
            style: { float: 'right', width: '35px' }
        });
    },

    destroy: function() {
        this.element.remove();
    },

    toggle: function(args) {
        if (this.controller.controller.chartController.dataExists(args.id)) {
            this.controller.controller.chartController.removeDataById(args.id);
            return false;
        } else {
            this.controller.controller.chartController.addData({
                id: args.id,
                url: "/analytics/data.json",
                unit: args.unit,
                kind: args.kind,
                name: args.name,
                rate: args.rate,
                color: args.color,
                operation: args.operation,
                code: args.code
            }, true);
            return true;
        }
    }

};
