WiseMetering.Views.CloneOperation = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/operations/clone',

    ui: {
        indicators: '#indicators-list',
        operation: '#indicator-operation-value'
    },

    events: {
        'change #indicators-list': 'updateIndicator'
    },

    onRender: function() {
        this.renderIndicators();
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.isNew = this.model.isNew();
        return json;
    },

    renderIndicators: function() {
        const
            buildingId = this.model.get('building_id'),
            kindId = this.model.get('kind_id'),
            usedIndicatorIds = this.model.get('operation')?.match(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g) || [];

        let indicators = WiseMetering.indicators.map(indicator => indicator.attributes).filter(indicator => usedIndicatorIds.includes(indicator.id) || indicator.kind_id === kindId);
        if (buildingId !== null) {
            indicators = indicators.filter(indicator => usedIndicatorIds.includes(indicator.id) || indicator.building_id === buildingId);
        }

        if (indicators.length === 0) {
            this.ui.indicators.html(`<option disabled selected>${i18next.t('indicators.no_indicators_found')}</option>`);
            return;
        }

        indicators.unshift({ id: '', name: i18next.t('ui.please_select_option') });
        this.ui.indicators.html(WiseMetering.utils.optionsFromArray(indicators, this.model.get('operation'), 'id', 'name'));
    },

    updateIndicator: function() {
        this.ui.operation.val(this.ui.indicators.val());
    }
});
