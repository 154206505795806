WiseMetering.Views.IndicatorMetals = WiseMetering.Views.Content.extend({
    fromField: null,
    template: 'templates/indicators/metals',
    toField: null,

    hasFullPermissions: function() {
        const type = this.model.get('type');
        if (WiseMetering.currentUser.isSuperUser() || type === 'import') {
            return true;
        }
    },

    createTable: function() {
        const timezone = this.model.timezone();
        WiseMetering.utils.addLoader('#metals-table', 80);

        const mode = WiseMetering.currentUser.isSuperUser() ? 'extended' : 'standard';

        this.model.getMetals(
            moment.tz(this.fromField.val().replace(/\./g, '-'), timezone).unix(),
            moment.tz(this.toField.val().replace(/\./g, '-'), timezone).endOf('day').unix(),
            mode
        ).done(metals => {
            let data = metals.map(row => ({
                computed_value: row.cv,
                date: moment.tz(row.t * 1000, timezone).format('YYYY-MM-DD HH:mm'),
                difference: row.d,
                timestamp: row.t,
                value: row.v,
                ignored: row.e ? 'yes' : null
            }));

            let toolbar_actions = {},
                columns = {
                    date: 'Date',
                    value: `Value (${this.model.get('unit')})`
                };

            if (mode === 'extended') {
                columns = { timestamp: 'Timestamp', date: 'Date', value: 'Value', computed_value: 'Computed Value', ignored: 'Ignored?' };
                if (this.model.kind().get('cumulative')) {
                    columns['difference'] = 'Difference';
                }
            }

            if (this.hasFullPermissions()) {
                toolbar_actions = {
                    select_all: {
                        label: 'Select All'
                    },
                    new: {
                        label: 'New',
                        view_call: () => new WiseMetering.Views.NewIndicatorMetal({ indicator_id: this.model.id })
                    },
                    custom_edit: {
                        label: 'Edit',
                        view_call: ({ error, target, timestamp, value }) => new WiseMetering.Views.EditIndicatorMetal({ model: this.model, error, target, timestamp, value })
                    },
                    show: {
                        view_call: ({ error, target, timestamp, value }) => new WiseMetering.Views.EditIndicatorMetal({ model: this.model, error, target, timestamp, value })
                    },
                    custom_delete: {
                        label: 'Delete',
                        permission: 'manager',
                        view_call: ({ target, timestamps }) => new WiseMetering.Views.RemoveIndicatorMetal({ model: this.model, target, timestamps })
                    }
                };
            }

            this.table = new WiseMetering.Views.Table({
                id: 'metals',
                collection: data,
                toolbar_actions,
                columns,
                custom: {
                    checkbox: true
                },
                aLengthMenu: [96, 96 * 7, 96 * 14, 48, 24, 12]
            });
            $(this.body.el).find('div#metals-table').html(this.table.el);
        });
    },

    onClose: function() {
        if (this.xhr && this.xhr.abort && typeof (this.xhr.abort) === 'function') {
            this.xhr.abort();
        }
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        const now = moment();
        let { from, to } = ParamsService;

        this.showMetals();

        from = from ? moment(from, 'YYYY.MM.DD') : now.startOf('year');
        to = to ? moment(to, 'YYYY.MM.DD') : now;

        this.fromField = $(this.body.el).find('#date_time_from');
        this.fromField.val(from.format('YYYY.MM.DD'));

        this.toField = $(this.body.el).find('#date_time_to');
        this.toField.val(to.format('YYYY.MM.DD'));

        $(this.body.el).find('#submit_time').click(() => {
            this.updateFields();
            if (this.fromField.val() && this.toField.val()) {
                this.createTable();
            }
        });

        this.fromField.datepicker({ dateFormat: 'yy.mm.dd', changeMonth: true, changeYear: true, showOtherMonths: true, onSelect: from => ParamsService.setParams({ from }) });
        this.toField.datepicker({ dateFormat: 'yy.mm.dd', changeMonth: true, changeYear: true, showOtherMonths: true, onSelect: to => ParamsService.setParams({ to }) });

        this.createTable();
    },

    showMetals: function() {
        if (!WiseMetering.currentUser.isSuperUser()) {
            return;
        }

        let text = `<b>Indicator Max Value:</b> ${this.model.get('max_value')} ${this.model.get('unit')}, <b>Indicator Min Value:</b> ${this.model.get('min_value')} ${this.model.get('unit')}`;

        this.model.get('metals').forEach(data => {
            let metal = '<br>';

            metal += Object.keys(data).map(key => {
                let value = data[key], response = `<b>${key.splitCapitalize('_')}</b>: ${value}`;

                if (key === 'from' || key === 'to' && value) {
                    response += ` (${moment.unix(value).utc().format()})`;
                }

                return response;
            }).join(', ');

            text += metal;
        });

        this.$('#metals-info').html(JST['templates/ui/components/notice']({ text }));
    },

    updateFields: function() {
        this.fromField.datepicker('option', 'maxDate', this.toField.val());
        this.toField.datepicker('option', 'minDate', this.fromField.val());
    }
});
