WiseMetering.Views.IndicatorsTabs = WiseMetering.Views.Content.extend({
    icon: 'indicators',

    name: function() {
        return i18next.t('glossary.indicators');
    },

    tabs: function() {
        const
            activeLength = WiseMetering.indicators.where({used: true}).length,
            inactiveLength = WiseMetering.indicators.filter(function(indicator) {
                return !indicator.get('used') && indicator.get('type') !== 'tmp';
            }).length,
            tempLength = WiseMetering.indicators.where({type: 'tmp'}).length;

        let tabs = [{
            id: 'active',
            name: `${i18next.t('ui.table.in_use')} (${activeLength})`,
            active: true,
            view: WiseMetering.Views.IndicatorsActive
        }];

        if (inactiveLength > 0){
            tabs.push({
                id: 'inactive',
                name: `${i18next.t('ui.table.unused')} (${inactiveLength})`,
                view: WiseMetering.Views.IndicatorsInactive
            });
        };

        if (tempLength > 0){
            tabs.push({
                id: 'temporary',
                name: `${i18next.t('common.temporary')} (${tempLength})`,
                view: WiseMetering.Views.IndicatorsTemporary
            });
        };

        return tabs;
    }
});
