WiseMetering.Model.Group = WiseMetering.Model.extend({

    urlRoot: "/groups",

    defaults: {
        building_ids: [],
        description: null,
        name: null,
        user_ids: []
    },

    buildings: function() {
        return new WiseMetering.Collection.Zones(this.get("building_ids").map(id => WiseMetering.zones.get(id)));
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.model.get("indicator_id"));
    },

    users: function() {
        let users = this.get("user_ids").map( id => WiseMetering.users.get(id) );
        users = users.filter(user => !!user)
        return new WiseMetering.Collection.Users(users)
    }

});
