WiseMetering.Views.IndexIntegrationAssets = WiseMetering.Views.Content.extend({
    template: 'templates/integration_assets/index',

    createTable: function() {
        this.collection = new WiseMetering.Collection(WiseMetering.integrationAssets.where({ indicator_id: this.model.id }));

        this.table = new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: this.collection,
            columns: {
                state: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({ color, title }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                partner: i18next.t('common.partner'),
                orders_created_count: i18next.t('integration_assets.orders_created_count'),
                first_sync: i18next.t('glossary.synced_since')
            },
            id: 'integration-assets',
            modelSerializer: function(model) {
                const
                    json = model.toJSON();

                json.first_sync = WiseMetering.utils.formatDate(model.get('first_sync'));
                json.partner = model.integrationPartner().get('name');

                if (json.state === 'disabled') {
                    json.state = {
                        color: 'red',
                        title: i18next.t('glossary.disabled')
                    };
                } else {
                    json.state = {
                        color: 'Green',
                        title: i18next.t('glossary.enabled')
                    };
                }

                return json;
            },
            toolbar_actions: {
                new: {
                    permission: 'manager',
                    view_call: () => {
                        new WiseMetering.Views.NewIntegrationAsset({
                            collection: WiseMetering.integrationAssets,
                            indicator: this.model,
                            model: new WiseMetering.Model.IntegrationAsset,
                            building: this.model.building(),
                        });
                    }
                },
                show: {
                    view_call: model => {
                        model.fetch().then(function() {
                            new WiseMetering.Views.ShowIntegrationAsset({ model });
                        });
                    }
                },
                edit: {
                    permission: 'manager',
                    view_call: model => new WiseMetering.Views.EditIntegrationAsset({ model })
                },
                delete: { permission: 'manager' }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
    },

    onShow: function() {
        this.listenTo(WiseMetering.integrationAssets, 'add', this.createTable.bind(this));
        this.createTable();
    },

    updateFields: function() {
    }
});
