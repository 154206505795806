module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table class=\'interval\'>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">\n            ' +
((__t = ( i18next.t("common.interval") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("common.from") )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t("common.to") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( interval.from )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class="to" name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( interval.to )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n\n    <tr class=\'spacer\'></tr>\n    <tr>\n        <th class=\'capitalize head\' colspan=\'2\'>\n            ' +
((__t = ( i18next.t("common.components") )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th>\n            ' +
((__t = ( i18next.t("tariffs.fixed_component", { currency: WiseMetering.getCurrency() }) )) == null ? '' : __t) +
'\n        </th>\n        <th>\n            ' +
((__t = ( i18next.t("tariffs.variable_component", { currency: WiseMetering.getCurrency(), unit: unit }) )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][fixed]" type="text" value="' +
((__t = ( interval.values?.fixed )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][variable]" type="text" value="' +
((__t = ( interval.values?.variable )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n</table>\n';

}
return __p
}