module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('common.attributes') )) == null ? '' : __t) +
'\n                <div class=\'wmui-btn\' id=\'edit_attributes\' style=\'float: right;\'>\n                    ' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner organization-details\'>\n                <div class=\'per-box\'>\n                    <dl>\n                        <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
':</dt>\n                        <dd style="text-transform: capitalize;">' +
((__t = ( type )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('common.country') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( country )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('glossary.timezone') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( timezone )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('common.currency') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( currency )) == null ? '' : __t) +
'</dd>\n                    </dl>\n                </div>\n                <div class=\'per-box\'>\n                    <dl>\n                        <dd><img class="preview-logo-image" src="' +
((__t = ( logo_url )) == null ? '' : __t) +
'" alt="logo"/></dd>\n                    </dl>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}