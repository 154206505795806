module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dt>\n    <label for=\'indicator_search_by_building\'>' +
((__t = ( i18next.t('indicators.origin_building') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'indicator_search_by_building\'>\n        <option disabled selected>' +
((__t = ( i18next.t('indicators.search_by_building') )) == null ? '' : __t) +
'</option>\n        ';
 buildings.each(function(building) { ;
__p += '\n            <option value=\'' +
((__t = ( building.id )) == null ? '' : __t) +
'\'>' +
((__t = ( building.get('name') )) == null ? '' : __t) +
'</option>\n        ';
 }) ;
__p += '\n    </select>\n</dd>\n<dt>\n    <label for="indicator_search_by_name">' +
((__t = ( i18next.t('ui.search_box') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input autocomplete=\'off\' class=\'text\' disabled id=\'indicator_search_by_name\' placeholder=\'' +
((__t = ( i18next.t('indicators.search_placeholder') )) == null ? '' : __t) +
'\'>\n</dd>\n<dt>\n    <label for=\'indicators_list\'>' +
((__t = ( i18next.t('indicators.indicator_to_mirror') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'indicators_list\' name=\'indicator[origin_id]\'>\n        <option disabled selected>' +
((__t = ( i18next.t('indicators.select_building_first') )) == null ? '' : __t) +
'</option>\n    </select>\n</dd>\n';

}
return __p
}