module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'tipper-icon\'>\n    <i class=\'' +
((__t = ( icon )) == null ? '' : __t) +
'\'>&nbsp;</i>\n</div>\n<span>' +
((__t = ( message )) == null ? '' : __t) +
'</span>\n';

}
return __p
}