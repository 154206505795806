Lmit.UI.Header = function(_text, _container, _options) {
    this.text = _text;
    this.options= _options;

    this.kind = this.options.kind;
    this.icon = this.options.icon;
    this.container = _container;
    this.element = null;
    this.init();
};


Lmit.UI.Header.prototype = {
    init:function(){
        this.build();
    },

    build:function(){
        var _this_ = this;

        this.element = $("<div>").addClass("wmui-header");

        // We'll do a switch because it can grow to other kinds, in that case it'll be better then a simple if
        // Also, we repeat the append and not only switch the html header because different kinds might need extra formatting
        switch(this.kind) {
            case "indicator":
                this.element.append($('<h4>').append($('<b>').text(this.text)));
                break;
            default:
                this.element.append($('<h2>').text(this.text));
        }

        if (this.options.id){ this.element.attr({id:this.options.id}); }
        this.container.append(this.element);
        this.bindEvents();
    },

    update: function(_args){
        if (_args.text) {
            this.text = _args.text;
            this.element.find("H2").text(_args.text);
        }
        if (_args.kind){ this.kind = _args.kind; }
        if (_args.icon){ this.kind = _args.icon; }
        return true;
    },

    bindEvents: function() {
        var _this_ = this;
    }
};