WiseMetering.Views.IndexTenantInvoicesByTenant = WiseMetering.Views.TableIndex.extend({
    icon: 'Invoices',
    name: () => i18next.t('glossary.invoices'),

    createTable: function createTable() {
        this.collection = WiseMetering.tenantInvoices.findByTenantId(this.model.get('id'));

        return new WiseMetering.Views.Table({
            id: 'allocations',
            disableSelect: false,
            collection: this.collection,
            modelSerializer: function(model) {
                const
                    json = model.toJSON(),
                    timezone = model.timezone();

                json.file = json.file && model.fileLink();
                json.from = WiseMetering.utils.formatDate(json.from, timezone, 'YYYY/MM/DD');
                json.to = WiseMetering.utils.formatDate(json.to, timezone, 'YYYY/MM/DD');
                json.updated_at = WiseMetering.utils.formatDate(json.updated_at, timezone, 'YYYY/MM/DD HH:mm');
                return json;
            },
            columns: {
                file: {
                    title: i18next.t('common.file'),
                    className: 'table-icon center file',
                    width: '5%',
                    mRender: function(file) {
                        if (!file) {
                            return '';
                        }
                        return `<a class='link' href='${file}' target='_blank'>${WiseMetering.icon('file')}</a>`;
                    }
                },
                interval: i18next.t('common.interval'),
                from: i18next.t('common.from'),
                to: i18next.t('common.to'),
                cost: {
                    title: i18next.t('common.cost'),
                    mRender: function(cost) {
                        return Lmit.Utils.formatNumber(cost, '€');
                    }
                },
                updated_at: i18next.t('glossary.last_change')
            },
            sort: {
                column: 'from',
                order: 'desc'
            },
            toolbar_actions: {
                select_all: {},
                generate: {},
                delete_all: {}
            }
        });
    }
});
