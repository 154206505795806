module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <form>\n        <dt>\n            <label for=\'folder_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'folder_name\' name=\'folder[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for=\'folder_description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text\' id=\'folder_description\' name=\'folder[description]\'>' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n        </dd>\n    </form>\n</dl>\n';

}
return __p
}