import * as React from 'react';
import {
    Button,
    CheckboxGroup,
    Content,
    ContentHeader,
    Form
} from '@/components/wm';
import { useState, useMemo } from 'react';

export function Notifications() {
    const userPreferences = WiseMetering.userPreferences;

    const
        [loading, setLoading] = useState(false),
        [notifications, setNotifications] = useState({
            alarms_notifications: userPreferences.get('alarms_notifications') || false,
            daily_report_notifications: userPreferences.get('daily_report_notifications') || false,
            objectives_notifications: userPreferences.get('objectives_notifications') || false,
            reports_notifications: userPreferences.get('reports_notifications') || false,
        });

    const notificationOptions = [
        { key: 'alarms_notifications', label: i18next.t('users.receive_alarms_notifications') },
        { key: 'daily_report_notifications', label: i18next.t('users.receive_daily_report') },
        { key: 'objectives_notifications', label: i18next.t('users.receive_objectives_notifications') },
        { key: 'reports_notifications', label: i18next.t('users.receive_reports_notifications') },
    ];

    const getChangedAttributes = () => {
        return userPreferences.changedAttributes({
            ...notifications,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const changed = getChangedAttributes();

        if (!changed){
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }

        try {
            await userPreferences.save(changed, { wait: true, patch: true });
            WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_saved'));
        } catch (err) {
            const errors = err.responseJSON.errors || null;
            let errorMessage = '';

            if (errors) {
                Object.keys(errors).forEach((key) => {
                    errorMessage += `${key}: ${errors[key]} <br>`
                });
            } else {
                errorMessage = 'An error ocurred'
            };

            WiseMetering.layout.showTipper('error', errorMessage);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const hasChanges = useMemo(() => {
        return (
            Object.entries(notifications).some(
                ([key, value]) => value !== userPreferences.get(key)
            )
        );
    }, [
        notifications,
        userPreferences.get('alarms_notifications'),
        userPreferences.get('daily_report_notifications'),
        userPreferences.get('objectives_notifications'),
        userPreferences.get('reports_notifications')
    ]);

    return (
        <Content>
            <ContentHeader icon="notifications" title={ i18next.t('ui.notification_preferences').capitalize() } />
            <Form>
                <CheckboxGroup
                    options={notificationOptions}
                    values={notifications}
                    onChange={setNotifications}
                />
                <div className="tw-mt-6">
                    <Button type="submit" loading={loading} className="tw-w-fit" onClick={handleSubmit} disabled={!hasChanges}>
                        { i18next.t('ui.buttons.save') }
                    </Button>
                </div>
            </Form>
        </Content>
    );
}
