WiseMetering.Collection.Allocations = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Allocation,
    url: '/allocations',
    comparator: 'name',

    activeAllocations: function (tenantId = null) {
        if (tenantId) {
            return new Backbone.Collection(WiseMetering.allocations.where({active: true, tenant_id: tenantId}))
        }
        return new Backbone.Collection(WiseMetering.allocations.where({active: true}))
    },

    inactiveAllocations: function (tenantId = null) {
        if (tenantId) {
            return new Backbone.Collection(WiseMetering.allocations.where({active: false, tenant_id: tenantId}))
        }
        return new Backbone.Collection(WiseMetering.allocations.where({active: false}))
    }
});
