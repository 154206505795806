WiseMetering.Views.NewIndicatorMetal = WiseMetering.Views.Modal.Custom.extend({
    template: 'templates/indicators/new_metal',
    form_prefix: 'indicator_metal',
    width: 600,
    title: () => i18next.t('modal.title.indicators.new_metal'),
    buttons: ['save', 'cancel'],

    // ----------------------------------------------------------------------------
    serializeData: function() {
        const indicator = WiseMetering.indicators.get(this.indicator_id);
        return {
            timezone: indicator.timezone(),
            unit: indicator.get('unit')
        };
    },

    // ----------------------------------------------------------------------------
    onRender: function() {
        this.$('input#metal_timestamp').datetimepicker({
            dateFormat: 'yy.mm.dd',
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            timeFormat: 'HH:mm',
            stepMinute: 15,
            maxDate: moment().format('yy.mm.dd')
        });
    },
    // ----------------------------------------------------------------------------
    save: function() {
        const
            that = this,
            url = WiseMetering.indicators.get(this.indicator_id).url() + '/new_metals',
            $errors = this.$('.err');

        const success = function() {
            WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_added'));
            that.close();
        };

        const error = function(xhr) {
            let error = i18next.t('ui.unspecified_error');
            try {
                error = JSON.parse(xhr.responseText).error;
            } catch (e) {
            }
            $errors.html(error);
            that.unblock();
        };

        const file = this.$('input#metal_xls').prop('files')[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            $.ajax({
                contentType: false,
                data: formData,
                error,
                processData: false,
                success,
                type: 'POST',
                url
            });
        } else if (this.$('input#metal_timestamp').val() && this.$('input#metal_value').val()) {
            let data = {};
            data['timestamp'] = this.$('input#metal_timestamp').val().replace(/\.|:/g, '');
            data['value'] = this.$('input#metal_value').val();
            $.ajax({ data, dataType: 'json', error, success, type: 'POST', url });
        } else {
            $errors.html(i18next.t('indicators.specify_timestamp_and_value'));
        }
    }
});
