module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped\'>\n    <div class=\'schedule-left\'>\n        <dt>\n            <label>Circuit</label>\n        </dt>\n        <dd>' +
((__t = ( fullPath )) == null ? '' : __t) +
'</dd>\n\n        ';
 if(system_category_name === 'Lighting'){ ;
__p += '\n            <dt>\n                <label>After Sunrise</label>\n            </dt>\n            <dd>' +
((__t = ( schedule.extra.delay )) == null ? '' : __t) +
' minutes</dd>\n            <dt>\n                <label>Before Sunset</label>\n            </dt>\n            <dd>' +
((__t = ( schedule.extra.advance )) == null ? '' : __t) +
' minutes</dd>\n        ';
 } ;
__p += '\n    </div>\n    <div class=\'schedule-right\' id=\'circuit-schedule-test\'></div>\n</dl>\n';

}
return __p
}