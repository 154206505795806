WiseMetering.Views.OmieTable = Backbone.Marionette.Layout.extend({
    template: 'templates/tariffs/omie_table',
    data: null,
    period: {
        from: null,
        to: null
    },

    ui: {
        dataTable: '.dataTable tbody'
    },

    initialize: function(data) {
        this.data = data.reverse();
    },

    onShow: function() {
        this.renderTable();
    },

    renderEmptyTemplate: function() {
        return `
            <tr style="background-color: #F2F2F2">
                <td class="align-center" colspan="3">
                  <div>No data available</div>
                </td>
            </tr>
        `;
    },

    renderLineTemplate: function({ day, time, value, className }) {
        return `
            <tr class="${className}">
                <td>${day}</td>
                <td>${time}</td>
                <td class="align-right" style="padding-right: 20px">${value}</td>
            </tr>
        `;
    },

    renderTable: function() {
        if (this.ui.dataTable) {
            this.ui.dataTable.empty();
        }

        let dataTable = this.ui.dataTable;
        dataTable.empty();

        if (Object.keys(this.data).length === 0) {
            dataTable.html(this.renderEmptyTemplate());
            return;
        }

        Object.entries(this.data).forEach(([key, val], i) => {
            const template = this.renderLineTemplate({
                day: WiseMetering.utils.formatDate(val.t, null, 'YYYY/MM/DD'),
                time: WiseMetering.utils.formatDate(val.t, null, 'HH:mm'),
                value: formatValue(val.v, '€'),
                className: i % 2 === 0 ? 'odd' : 'even'
            });

            dataTable.append(template);
        });
    }
});
