Lmit.Wise.Chart.Analyze = function(_controller, _container, _settings, _options) {
    this.init(_controller, _container, _settings, _options);
};

Lmit.Wise.Chart.Analyze.prototype = new Lmit.Wise.Chart.Generic();
Lmit.Wise.Chart.Analyze.prototype.type_of = 'analyze';
Lmit.Wise.Chart.Analyze.prototype.name = 'Analyze View';

Lmit.Wise.Chart.Analyze.prototype.show = function() {
    this.plotData();
};

Lmit.Wise.Chart.Analyze.prototype.destroy = function() {
    if (this.chart || this.view.find('.highcharts-container').length > 0) {
        var thiz = this;
        $(thiz.view).find('.highcharts-container').fadeOut(400, function() {
            if (thiz.chart !== null && this.thiz !== undefined && thiz.chart.destroy) {
                thiz.chart.destroy();
            }
            $(thiz.view).remove();
        }).delay(400);
    }
};

Lmit.Wise.Chart.Analyze.prototype.plotData = function() {
    this.chart = new Highcharts.Chart(this.highchartsSettings());
};

Lmit.Wise.Chart.Analyze.prototype.parseUnit = function(_unit) {
    switch (_unit) {
        case 'N/A':
            return _unit;
        case '€':
            return '€';
        case '£':
            return '£';
        case 'Kz':
            return 'Kz';
        case '$':
            return '$';
        case 'R$':
            return 'R$';
        default:
            return (this.wrapper !== null && this.wrapper != 'N/A' && this.wrapper !== undefined) ? _unit + '/' + this.wrapper : _unit;
    }
};

Lmit.Wise.Chart.Analyze.prototype.categoryByName = function(_cat) {

    switch (_cat) {
        case ('5_minutes'):
            return Lmit.Wise.Chart.Defaults.minutes;
        case ('15_minutes'):
            return Lmit.Wise.Chart.Defaults.minutes;
        case ('hour'):
            return Lmit.Wise.Chart.Defaults.hour;
        case ('day_of_week'):
            return Lmit.Wise.Chart.Defaults.day_of_week;
        case ('day_type'):
            return Lmit.Wise.Chart.Defaults.day_type;
        case ('day_of_month'):
            return Lmit.Wise.Chart.Defaults.day_of_month;
        case ('month'):
            return Lmit.Wise.Chart.Defaults.month;
        case ('year'):
            return Lmit.Wise.Chart.Defaults.year;
    }
};

Lmit.Wise.Chart.Analyze.prototype.addSeries = function(result) {
    var set = this.analyzeData(result);
    var keys = Object.keys(set);
    var colors = Lmit.Wise.Chart.Defaults.defaultPlotColors.slice(1);
    keys.forEach((_name, i) => {
        var params = {
            type: 'column',
            id: result.info.id,
            code: result.info.code,
            name: _name,
            unit: this.parseUnit(result.info.unit),
            kind: result.info.kind,
            color: colors[i],
            data: Object.values(set[_name])
        };
        this.chart.addSeries(params);
    });
};

Lmit.Wise.Chart.Analyze.prototype.nameByCategoryAndIndex = function(_cat, _ind) {
    if (_cat == 'day_type') {
        return _ind.toLowerCase().replace(/\b[a-z]/g, function(letter) { return letter.toUpperCase(); });
    }
    if (_cat == 'year') {
        return '' + _ind;
    }
    if (_cat == 'month') {
        _ind--;
    }
    var str = Lmit.Wise.Chart.Defaults[_cat][_ind];
    return str;
};
Lmit.Wise.Chart.Analyze.prototype.getName = function(_cat, _index, _prefix) {
    _index = _index || null;
    var str = (_index === null) ? _cat.split('_').join(' ') : this.nameByCategoryAndIndex(_cat, _index);
    if (_prefix) {
        str = _prefix + '-' + str;
    }
    return str;
};

Lmit.Wise.Chart.Analyze.prototype.analyzeData = function(_result) {

    var _this_ = this;
    var set = {};
    var _cat = _this_.categoryByName(_this_.category);
    if (_result.grouped_by.length > 1) {
        _cat = _this_.categoryByName(_result.grouped_by[1]);
        var collection = _.sortBy(_.keys(_result.data), function(num) {
            Number(num);
        });
        _.each(collection, function(k, i) {
            var _name;
            if (_result.grouped_by.length > 2) {
                _name = _this_.getName(_result.grouped_by[1], k.split(',')[1], _result.grouped_by[0][0].toUpperCase() + k.split(',')[0]);
            } else {
                _name = _this_.getName(_result.grouped_by[0], k);
            }
            // _name = (_result.grouped_by.length>2) ? _this_.getName(_result.grouped_by[1], k.split(",")[1],  _result.grouped_by[0][0].toUpperCase() + k.split(",")[0]) : _this_.getName(_result.grouped_by[0], k);
            set['' + _name] = _this_.dataForColumn(_this_.category, _result.data[k], _cat);
        });
    } else {
        var _name = this.getName(_result.grouped_by[0]);
        set[_name] = _this_.dataForColumn(_name, _result.data, _cat);
    }

    return set;
};

Lmit.Wise.Chart.Analyze.prototype.dataForColumn = function(_name, _set, _cat) {
    var _this_ = this;
    var d = _.map(_.keys(_set), function(k, i) {
        var _v = _this_.getColumnIndexByName(_name, _cat, k);
        return [Number(_v), _set[k]];
    });
    return d;
};

Lmit.Wise.Chart.Analyze.prototype.getColumnIndexByName = function(_name, _cat, _k) {

    var _v;

    if (_name == 'month') {
        _v = Number(_k) - 1;
    } else if (_name == 'hour') {
        _v = Number(_k) + ':00';
    } else {
        _v = _k;
    }

    if (_name == '15 minutes' || _name == '15_minutes' || _name == 'hour' || _name == 'day of month' || _name == 'day_of_month' || _name == 'year') {
        _v = _cat.indexOf('' + _v);
    }

    if (_name == 'day type' || _name == 'day_type') {
        _v = _cat.indexOf(_k.charAt(0).toUpperCase() + _k.substr(1));
    }

    return _v;
};

Lmit.Wise.Chart.Analyze.prototype.highchartsDefaultSettings = function() {
    var _this_ = this;
    return {
        chart: {
            zoomType: 'x',
            margin: [30, 50, 90, 80],
            backgroundColor: '#fff'
        },
        global: {
            useUTC: false
        },
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: true,
            itemMarginBottom: 10,
            symbolWidth: 10,
            borderWidth: 0,
            labelFormatter: function() {
                return this.name;
            },
            align: 'center',
            itemStyle: {
                fontSize: '10px',
                color: '#000000',
                fontWeight: 'normal',
                textTransform: 'capitalize'
            }
        },
        plotOptions: {
            series: {
                pointPadding: 0.1,
                groupPadding: 0,
                borderWidth: 0,
                shadow: false,
                point: {
                    events: {
                        mouseOut: function() {
                            _this_.onMouseOut();
                        },
                        mouseOver: function() {
                            var _value_ = formatValue(this.y, this.series.options.unit);
                            _this_.onMouseOver(this.series.name + ' - ' + this.category, _value_, this.series.options.name, new Lmit.Color(this.series.options.color).darker());
                        }
                    }
                }
            },
            bar: {
                borderWidth: 0
            },
            line: {
                fillOpacity: 0.75,
                animation: false,
                lineWidth: 1,
                shadow: false,
                step: true,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true,
                            fillColor: '#8bc53f',
                            lineColor: '#8bc53f',
                            radius: 3
                        }
                    }
                },
                states: {
                    hover: {
                        lineWidth: 0
                    }
                }
            },
            column: {}
        },
        series: [],
        xAxis: {
            color: '#797979',
            tickColor: '#797979',
            categories: _this_.categoryByName(_this_.category),
            labels: {
                x: 0,
                step: (_this_.category == '15_minutes') ? 4 : null,
                y: 20,
                style: {
                    fontSize: '10px',
                    color: 'black',
                    fontWeight: 'normal'
                }
            }
        },
        yAxis: [],
        tooltip: {
            formatter: function() {
                return false;
            },
            crosshairs: {
                width: 1,
                color: '#8bc53f',
                dashStyle: 'shortdot'
            }
        },
        exporting: {
            enabled: true,
            buttons: {
                printButton: {
                    enabled: false
                }
            }
        }
    };
};

Lmit.Wise.Chart.Analyze.prototype.highchartsSettings = function() {
    var thiz = this;
    var chartSettings = {
        chart: {
            renderTo: this.id,
            //            style: { opacity: 0 }, // opacity must be used instead of display due to highcharts legend rendering problems.. TODO check newer versions
            events: {
                addSeries: function(e) {
                    $(this.container).parent().delay(400).animate({ opacity: 1 }, 400);
                }
            }
        }
    };
    jQuery.extend(true, chartSettings, this.highchartsDefaultSettings());
    chartSettings.yAxis.push({
        tickColor: '#8bc53f',
        tickWidth: 2,
        minorGridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickColor: '#A3A3A3',
        title: {
            text: ''
        },
        labels: {
            style: {
                fontSize: '9px'
            },
            formatter: function() {
                return Lmit.Utils.formatNumber(this.value, thiz.settings.unit);
            },
            align: 'right', y: -5
        }
    });
    return chartSettings;
};
