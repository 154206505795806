Lmit.Wise.Chart.Control.Resolution = function(controller, container, options) {
    this.options = options;
    this.controller = controller;
    this.container = container;
    this.view = null;
    this.resolutions = {
        '15m': 900,
        '1h': 3600,
        '1d': 3600 * 24,
        '1m': 3600 * 24 * 30,
        '1y': 3600 * 24 * 365
    };
    this.alias = ParamsService.rate;
    this.init();
};

Lmit.Wise.Chart.Control.Resolution.prototype = {
    init: function() {
        ParamsService.events.on('change:rate', rate => {
            this.alias = rate;
            this.updateView();
        });
        this.view = $('<div>').addClass('wmui-chart-control-resolution');
        this.container.append(this.view);
        this.build();
    },

    idle: function() {
        this.view.fadeOut(400);
    },

    enable: function() {
        this.view.fadeIn(400);
    },

    build: function() {
        let element = $('<ul>').addClass("ctrl");
        element.append($('<li>').addClass("label").text(`${i18next.t("common.resolution")}:`));
        const resolutions = [
            { value: 900, text: i18next.t('periods.15m') },
            { value: 3600, text: i18next.t('periods.1h') },
            { value: 3600 * 24, text: i18next.t('periods.1d') },
            { value: 3600 * 24 * 30, text: i18next.t('periods.1m') },
            { value: 3600 * 24 * 365, text: i18next.t('periods.1y') }
        ];
       resolutions.forEach(resolution => {
            const resolutionElement = $('<li>').addClass("mode").attr({ "data-resolution": resolution.value }).text(resolution.text);
            element.append(resolutionElement);
            this.bindItemEvent(resolutionElement);
        });
        this.view.append(element);
    },

    bindItemEvent: function(item) {
        item.click(() => {
            if (!$(item).hasClass("locked")) {
                this.setResolution($(item).attr("data-resolution"), true);
            }
        });
    },

    updateView: function() {
        this.view.children("ul").children("li").removeClass("current");
        this.view.children("ul").children(("li[data-resolution=" + this.alias + "]")).addClass("current");
    },

    setResolution: function(alias, fetch) {
        let rate;
        if (parseInt(alias).toString() === alias) {
            rate = parseInt(alias);
        } else {
            rate = this.lockOptionsByPeriod(this.controller.period);
        }
        ParamsService.setParams({ rate });
        this.alias = alias;
        if (fetch) {
            this.controller.onResolutionUpdate(this.alias);
        }
        this.updateView();
    },

    setResolutionByPeriod: function(period) {
        this.setResolution(this.lockOptionsByPeriod(period), false);
    },

    lockOptionsByPeriod: function(period) {
        const locks = this.matrixByPeriod((period.to.getTime() - period.from.getTime()) / 1000);
        let modes = this.view.children("ul").children("li.mode");
        modes.removeClass("locked");
        modes.removeClass("current");
        let resolution = null, validRate = false;
        modes.each((index, item) => {
            const
                locked = locks[index] !== "1",
                value = parseInt($(item).attr("data-resolution"));

            if (!locked && value === ParamsService.rate) {
                validRate = true;
            }

            if (locked) {
                $(item).addClass("locked");
            } else if (!resolution) {
                resolution = value;
            }
        });
        return validRate ? ParamsService.rate : resolution;
    },

    matrixByPeriod: function(period) {
        let lock = "00000";
        switch (this.controller.getCurrentVisualization()) {
            case 'plot':
                if (period <= 3600) {
                    lock = "11000";
                } else if (period <= 3600 * 24) {
                    lock = "11000";
                } else if (period <= 3600 * 24 * 7) {
                    lock = "11100";
                } else if (period <= 3600 * 24 * 31) {
                    lock = "01100";
                } else if (period <= 3600 * 24 * 31 * 3) {
                    lock = "01110";
                } else if (period <= 3600 * 24 * 31 * 12) {
                    lock = "00110";
                } else {
                    lock = "00011";
                }
                break;
            case 'heatmap':
                if (period <= 3600 * 24 * 45) {
                    lock = "01000";
                } else {
                    lock = "00100";
                }
                break;
        }
        return lock.split("");
    },

    reset: function() {
        if (ParamsService.rate) {
            this.setResolution(ParamsService.rate);
        } else {
            this.setResolutionByPeriod(this.controller.period);
        }
    }

};
