WiseMetering.Views.Ui.Select = Backbone.View.extend({
    tagName: 'select',
    className: '_select_view',

    events: {
        'change': 'selectOption'
    },

    getOptionName: function() { // method for returning the value for the option name
        return this.model.get('name');
    },

    getOptionValue: function() { // method for returning the value for the option value
        return this.model.id;
    },

    selectOption: function(event) { },

    initialize: function() {
        if (this.options.selectOption) {
            this.selectOption = this.options.selectOption;
        }
        if (this.options.getOptionName) {
            this.getOptionName = this.options.getOptionName;
        }
        if (this.options.getOptionValue) {
            this.getOptionValue = this.options.getOptionValue;
        }
        if (this.options.name) {
            $(this.el).attr({ name: this.options.name });
        }
        $(this.el).html($('<option>').attr({ 'value': '' }).html(i18next.t('ui.please_wait')));
        _.bindAll(this, 'addOne', 'addAll', 'selectOption');
        this.collection.bind('reset', this.addAll, this);
        this.addAll();
    },

    addOne: function(model) {
        $(this.el).append(new WiseMetering.Views.Ui.Option({
            model: model, getName: this.getOptionName, getValue: this.getOptionValue
        }).render().el);
    },

    addAll: function() {
        const el = $(this.el);
        el.children('option:first').html(i18next.t('ui.please_select_option'));
        this.collection.each(this.addOne);
        el.removeAttr('disabled');
    },

    render: function() {
        this.addAll();
        return this;
    }
});
