module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <div class=\'two-column\'>\n        <div class=\'left-side\'>\n            <dt>ID</dt>\n            <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n\n            <dt>' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( from )) == null ? '' : __t) +
'</dd>\n\n            <dt>' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( to )) == null ? '' : __t) +
'</dd>\n\n            <dt>' +
((__t = ( i18next.t('common.consumption') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( consumption )) == null ? '' : __t) +
'</dd>\n\n            <dt>' +
((__t = ( i18next.t('common.cost') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( cost )) == null ? '' : __t) +
'</dd>\n\n            <dt>' +
((__t = ( i18next.t('common.reference') )) == null ? '' : __t) +
'</dt>\n            <dd>\n                <label class=\'switch\'>\n                    <input disabled style=\'height:20px\' type=\'checkbox\' id=\'invoice-reference\' name=\'invoice[reference]\' ' +
((__t = ( reference ? 'checked' : '' )) == null ? '' : __t) +
'/>\n                    <span class=\'slider round\'></span>\n                </label>\n            </dd>\n\n            <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( updated_at )) == null ? '' : __t) +
'</dd>\n\n            ';
 if (file) { ;
__p += '\n                <dt style="margin-top:15px"></dt>\n                <dd class=\'file-link\' id=\'file-link\'>\n                    <span style=\'color:#8BC53F\'>' +
((__t = ( WiseMetering.icon('download') )) == null ? '' : __t) +
'</span>\n                    <a class=\'link\' href=' +
((__t = ( file_link )) == null ? '' : __t) +
' target="_blank">' +
((__t = ( i18next.t('ui.click_here_to_download_file') )) == null ? '' : __t) +
'</a>\n                    <a href=\'#\' id=\'remove-file\' style=\'color:#224C4F\'> (' +
((__t = ( i18next.t('common.remove') )) == null ? '' : __t) +
') </a>\n                </dd>\n                <span id=\'spinner\' style=\'display:none;color:#224C4F\'>\n                    <i class=\'far fa-spinner fa-spin\'></i>\n                    ' +
((__t = ( file ? i18next.t('common.removing') : i18next.t('common.uploading') )) == null ? '' : __t) +
' ' +
((__t = ( i18next.t('common.file') )) == null ? '' : __t) +
'...\n                </span>\n            ';
 } ;
__p += '\n        </div>\n\n        <div class=\'right-side\' align=\'right\'>\n            <div class=\'check ' +
((__t = ( statusColor )) == null ? '' : __t) +
'\'>\n                <span>' +
((__t = ( WiseMetering.icon(statusIcon) )) == null ? '' : __t) +
'</span>\n            </div>\n            <div class=\'status\'>' +
((__t = ( invoiceValidationStatus )) == null ? '' : __t) +
'!</div>\n            ';
 if (check.consumption || check.consumption === 0) { ;
__p += '\n                <div>' +
((__t = ( i18next.t('glossary.consumption_deviation') )) == null ? '' : __t) +
' = ' +
((__t = ( check.consumption.toFixed(2) )) == null ? '' : __t) +
'%</div>\n            ';
 } ;
__p += '\n            ';
 if (check.cost || check.cost === 0) { ;
__p += '\n                <div>' +
((__t = ( i18next.t('glossary.cost_deviation') )) == null ? '' : __t) +
' = ' +
((__t = ( check.cost.toFixed(2) )) == null ? '' : __t) +
'%</div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</dl>\n';

}
return __p
}