Lmit.UI.Toolbar = function(_title_, _data_, _container_, _options_) {
    this.name = _title_;
    this.id = _options_.id || null;
    this.data = _data_;
    this.options = _options_;
    this.container = _container_;
    this.element = null;
    this.elements = null;
    this.init();
};

Lmit.UI.Toolbar.prototype = {
    init : function() {
        this.elements = [];
        this.build();
    },

    build : function() {
        var _this_ = this;
        this.element = $("<ul>").addClass("toolbar");
        this.element.addClass((this.options.style || 'regular'));
        _.each(this.data, function(item) {
            switch(item[0]){
                case 'wmui-label':
                    _this_.elements.push(new Lmit.UI.Label(item[1], _this_.element, item[2]));
                    break;
                case 'wmui-btn':
                    _this_.elements.push(new Lmit.UI.Button(item[1], _this_.element, item[2], item[3]));
                    break;
                case 'wmui-select-box':
                    _this_.elements.push(new Lmit.UI.SelectBox(item[1], _this_.element, { data : item[2].data, disabled : item[2].disabled }, { onChange : item[3].onChange }));
                    break;
                case 'wmui-check-box':
                    _this_.elements.push(new Lmit.UI.Input(item[1], _this_.element, { label: item[2].label, type : "checkbox" }, { onChange : item[3].onChange }));
                    break;
            }
        });
        if (this.options.id){ this.element.attr({id : this.options.id});}
        if (this.container instanceof Lmit.Section) {
            this.container.addItem(this);
        } else {
            this.container.append(this.element);
        }
        this.bindEvents();
    },

    bindEvents : function() {
        var _this_ = this;
        var toolbar = this.element.find('ul.toolbar li');
        $(toolbar).click(function(e) {
            $(this).toggleClass("active");
        });
    },

    enableBox : function() {
    },

	remove:function(){
	  this.element.remove();
	},
	
	hide:function(){
	  this.element.hide();
	},
	
	show:function(){
	  this.element.show();
	},
	
	toggle:function(){
	  this.element.toggle();
	},
	
	to_s:function(){
      
	}
		
};
