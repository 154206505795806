module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dl class=\'stepped\'>\n    <div class=\'schedule-left\'>\n        <dt>\n            <label for=\'alarm_schedule_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            ' +
((__t = ( name )) == null ? '' : __t) +
'\n        </dd>\n        <dt>\n            <label for=\'alarm_schedule_alarms\'>\n                ' +
((__t = ( i18next.t('alarms.used_by_alarms') )) == null ? '' : __t) +
'\n            </label>\n        </dt>\n        <dd>\n            ' +
((__t = ( (alarms || []).map(function(alarm) { return alarm.get('name'); }).join(', ') || i18next.t('common.none') )) == null ? '' : __t) +
'\n        </dd>\n    </div>\n    <div class=\'schedule-right\'>\n        <dt>\n            <label>' +
((__t = ( i18next.t('glossary.alarm_schedule') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <div id=\'schedule\'></div>\n        </dd>\n    </div>\n</dl>\n';

}
return __p
}