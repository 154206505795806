WiseMetering.Views.IndexOrganizationDisabledSites = WiseMetering.Views.TableIndex.extend({
    icon: 'buildings',
    name: () => i18next.t('glossary.buildings'),

    createTable: function() {
        this.collection = WiseMetering.zones.disabledSites();

        this.table = new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: this.collection,
            columns: this.getColumns(),
            id: 'buildings',
            modelSerializer: function(model) {
                let json = {};

                const
                    sitePackage = WiseMetering.sitePackages.findWhere({building_id: model.id}),
                    pack = WiseMetering.packages.get(sitePackage.get('package_id'));

                json.id = model.id;
                json.name = model.get('name');
                json.packageName = pack.get('name');
                json.wiseboxes_default_limit = pack.get('wiseboxes');
                json.wiseboxes_extra_limit = sitePackage.get('wiseboxes_extra_limit');
                json.wiseboxes_used = sitePackage.getUsed('wiseboxes');
                json.devices_default_limit = pack.get('devices');
                json.devices_extra_limit =  sitePackage.get('devices_extra_limit');
                json.devices_used = sitePackage.getUsed('devices');
                json.output_default_limit = pack.defaultOutputs();
                json.output_used = sitePackage.getUsed('control_outputs');
                json.current_sensor_default_limit = pack.defaultCurrentSensors();
                json.current_sensor_used = sitePackage.getUsed('current_sensors');

                return json;
            }.bind(this),
            toolbar_actions: {
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                },
                activate: {
                    permission: 'superuser',
                    icon: 'play',
                    title: i18next.t('buildings.activate_site'),
                    view_call: function(building) {
                        new WiseMetering.Views.ActivateBuilding({ collection: this.collection, model: building });
                    }.bind(this)
                },
            }
        });
        $(this.body.el).html(this.table.el);
    },

    getColumns: function() {
        if (WiseMetering.currentOrganization.showControlOutputs() ) {
            return Object.assign(this.getDefaultColumns(), this.getRetailColumns());
        } else {
            return this.getDefaultColumns();
        }
    },

    getDefaultColumns: function() {
        return {
            name: i18next.t('common.name'),
            packageName: {
                title: i18next.t('buildings.package_name'),
                mRender: function (packageName) {
                    return `<div style="text-transform: capitalize">${packageName}</div>`;
                }
            },
            wiseboxes_default_limit: {
                title: i18next.t('buildings.wise_boxes_limit'),
                className: 'align-right'
            },
            wiseboxes_extra_limit: {
                title: i18next.t('buildings.extra_wise_boxes_limit'),
                className: 'align-right'
            },
            wiseboxes_used: {
                title: i18next.t('buildings.used_wise_boxes'),
                className: 'align-right',
                mRender: function ({value, deviation, color}) {
                    return deviation === 100 ? value : this.usedEl(value, deviation, color);
                }.bind(this)
            },
            devices_default_limit: {
                title: i18next.t('buildings.devices_limit'),
                className: 'align-right'
            },
            devices_extra_limit: {
                title: i18next.t('buildings.extra_devices_limit'),
                className: 'align-right'
            },
            devices_used: {
                title: i18next.t('buildings.used_devices'),
                className: 'align-right',
                mRender: function ({value, deviation, color}) {
                    return deviation === 100 ? value : this.usedEl(value, deviation, color, 30);
                }.bind(this)
            }
        }
    },

    getRetailColumns: function() {
        return {
            output_default_limit: {
                title: i18next.t('buildings.control_outputs_limit'),
                className: 'align-right'
            },
            output_used: {
                title: i18next.t('buildings.used_control_outputs'),
                className: 'align-right',
                mRender: function ({value, deviation, color}) {
                    return deviation === 100 ? value : this.usedEl(value, deviation, color);
                }.bind(this)
            },
            current_sensor_default_limit: {
                title: i18next.t('buildings.current_sensors_limit'),
                className: 'align-right'
            },
            current_sensor_used: {
                title: i18next.t('buildings.used_current_sensors'),
                className: 'align-right',
                mRender: function ({value, deviation, color}) {
                    return deviation === 100 ? value : this.usedEl(value, deviation, color);
                }.bind(this)
            }
        }
    },

    onClose: function() {
        this.stopListening(WiseMetering.zones);
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.zones, 'add change', this.createTable.bind(this));
        this.createTable();
    },

    usedEl: function(value, deviation, color, width = 15) {
        return `
            <b style="font-size:8px;color:${color}">(${Number(deviation).toFixed(1)}%)</b>
            <div style="display:inline-block;width:${width}px">${value}</div>
        `;
    },
});
