WiseMetering.Collection.RootObjects = WiseMetering.Collection.extend({
    comparator: 'order',
    build: function() {
        let models = [];
        WiseMetering.zones.buildings().forEach(function(building, index) {
            models.push(building.rootObjects(index).models);
        });

        return new WiseMetering.Collection.RootObjects(models.flat());
    }
});
