module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<div id=\'toolbar\'>\n    <a class=\'main-logo\' href=\'https://www.wisemetering.com\' target=\'_blank\'>\n        <img src="/images/wisemetering/negative-logo-horizontal.png">\n    </a>\n    <div id=\'tipper\'></div>\n    <div class=\'menu\'>\n        <div class=\'item\'>\n            <div class=\'dropdown\'>\n                <div class=\'dropdown-main\'>\n                    <div class=\'dropdown-main-icon\'>\n                        ' +
((__t = ( WiseMetering.icon('features') )) == null ? '' : __t) +
'\n                    </div>\n                    ' +
((__t = ( i18next.t('glossary.features') )) == null ? '' : __t) +
'\n                </div>\n                <div class=\'dropdown-section\'>\n                    <a href=\'/home\'>\n                        <div class=\'dropdown-section-item\'>\n                            <div class=\'dropdown-section-icon\'>\n                                ' +
((__t = ( WiseMetering.icon('home') )) == null ? '' : __t) +
'\n                            </div>\n                            ' +
((__t = ( i18next.t('common.home').capitalize() )) == null ? '' : __t) +
'\n                        </div>\n                    </a>\n                    <a href=\'/map\'>\n                        <div class=\'dropdown-section-item\'>\n                            <div class=\'dropdown-section-icon\'>\n                                ' +
((__t = ( WiseMetering.icon('map') )) == null ? '' : __t) +
'\n                            </div>\n                            ' +
((__t = ( i18next.t('common.map') )) == null ? '' : __t) +
'\n                        </div>\n                    </a>\n                    <a href=\'/tree\'>\n                        <div class=\'dropdown-section-item\'>\n                            <div class=\'dropdown-section-icon\'>\n                                ' +
((__t = ( WiseMetering.icon('tree') )) == null ? '' : __t) +
'\n                            </div>\n                            ' +
((__t = ( i18next.t('common.tree') )) == null ? '' : __t) +
'\n                        </div>\n                    </a>\n                    <a href=\'/alarms\'>\n                        <div class=\'dropdown-section-item\'>\n                            <div class=\'dropdown-section-icon\'>\n                                ' +
((__t = ( WiseMetering.icon('alarms') )) == null ? '' : __t) +
'\n                            </div>\n                            ' +
((__t = ( i18next.t('glossary.alarms') )) == null ? '' : __t) +
'\n                        </div>\n                    </a>\n                    ';
 if (WiseMetering.currentOrganization.get('automation')) { ;
__p += '\n                        <a href=\'/circuits\'>\n                            <div class=\'dropdown-section-item dropdown\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('circuits') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('glossary.circuits') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                    ';
 } ;
__p += '\n                    <a href=\'/events\'>\n                        <div class=\'dropdown-section-item\'>\n                            <div class=\'dropdown-section-icon\'>\n                                ' +
((__t = ( WiseMetering.icon('events') )) == null ? '' : __t) +
'\n                            </div>\n                            ' +
((__t = ( i18next.t('glossary.events') )) == null ? '' : __t) +
'\n                        </div>\n                    </a>\n                    <a href=\'/indicators\'>\n                        <div class=\'dropdown-section-item\'>\n                            <div class=\'dropdown-section-icon\'>\n                                ' +
((__t = ( WiseMetering.icon('indicators') )) == null ? '' : __t) +
'\n                            </div>\n                            ' +
((__t = ( i18next.t('glossary.indicators') )) == null ? '' : __t) +
'\n                        </div>\n                    </a>\n                    <a href=\'/markers\'>\n                        <div class=\'dropdown-section-item dropdown-link\' data-href=\'/markers\'>\n                            <div class=\'dropdown-section-icon\'>\n                                ' +
((__t = ( WiseMetering.icon('markers') )) == null ? '' : __t) +
'\n                            </div>\n                            ' +
((__t = ( i18next.t('glossary.markers') )) == null ? '' : __t) +
'\n                        </div>\n                    </a>\n                    <a href=\'/reports\'>\n                        <div class=\'dropdown-section-item\'>\n                            <div class=\'dropdown-section-icon\'>\n                                ' +
((__t = ( WiseMetering.icon('reports') )) == null ? '' : __t) +
'\n                            </div>\n                            ' +
((__t = ( i18next.t('glossary.reports') )) == null ? '' : __t) +
'\n                        </div>\n                    </a>\n                    ';
 if (WiseMetering.currentUser.get('manager')) { ;
__p += '\n                        <a href=\'/tariffs\'>\n                            <div class=\'dropdown-section-item dropdown-link\' data-href=\'/tariffs\'>\n                                <div class="dropdown-item">\n                                    <div class="dropdown-title">\n                                        <div class=\'dropdown-section-icon\'>\n                                            ' +
((__t = ( WiseMetering.icon('tariffs') )) == null ? '' : __t) +
'\n                                        </div>\n                                        ' +
((__t = ( i18next.t('glossary.tariffs') )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div>\n                                        ';
 if (!WiseMetering.currentOrganization.hasStandardPermissions()) { ;
__p += '\n                                            <span class=\'lock\'>' +
((__t = ( WiseMetering.icon('lock') )) == null ? '' : __t) +
'</span>\n                                        ';
 } ;
__p += '\n                                    </div>\n                                </div>\n                            </div>\n                        </a>\n                    ';
 } ;
__p += '\n                        <a href=\'/all_tenants\'>\n                            <div class=\'dropdown-section-item dropdown-link\' data-href=\'/all_tenants\'>\n                                <div class="dropdown-item">\n                                    <div class="dropdown-title">\n                                        <div class=\'dropdown-section-icon\'>\n                                            ' +
((__t = ( WiseMetering.icon('tenants') )) == null ? '' : __t) +
'\n                                        </div>\n                                        ' +
((__t = ( i18next.t('glossary.tenants') )) == null ? '' : __t) +
'\n\n                                    </div>\n                                    <div>\n                                        ';
 if (!WiseMetering.currentOrganization.hasAdvancedPermissions()) { ;
__p += '\n                                            <span class=\'lock\'>' +
((__t = ( WiseMetering.icon('lock') )) == null ? '' : __t) +
'</span>\n                                        ';
 } ;
__p += '\n                                    </div>\n                                </div>\n                            </div>\n                        </a>\n                </div>\n            </div>\n        </div>\n        ';
 if (WiseMetering.currentUser.get('administrator')) { ;
__p += '\n            <div class=\'item\'>\n                <div class=\'dropdown\'>\n                    <div class=\'dropdown-main\'>\n                        <div class=\'dropdown-main-icon\'>\n                            ' +
((__t = ( WiseMetering.icon('administration') )) == null ? '' : __t) +
'\n                        </div>\n                        ' +
((__t = ( i18next.t('glossary.administration') )) == null ? '' : __t) +
'\n                    </div>\n                    <div class=\'dropdown-section\'>\n                        <a href=\'/circuit_types\'>\n                            <div class=\'dropdown-section-item\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('circuits') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('glossary.circuit_types') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                        <a href=\'/companies\'>\n                            <div class=\'dropdown-section-item dropdown-link\' data-href=\'/companies\'>\n                                <div class="dropdown-item">\n                                    <div class="dropdown-title">\n                                        <div class=\'dropdown-section-icon\'>\n                                            ' +
((__t = ( WiseMetering.icon('companies') )) == null ? '' : __t) +
'\n                                        </div>\n                                        ' +
((__t = ( i18next.t('glossary.companies') )) == null ? '' : __t) +
'\n\n                                    </div>\n                                    <div>\n                                        ';
 if (!WiseMetering.currentOrganization.hasAdvancedPermissions()) { ;
__p += '\n                                            <span class=\'lock\'>' +
((__t = ( WiseMetering.icon('lock') )) == null ? '' : __t) +
'</span>\n                                        ';
 } ;
__p += '\n                                    </div>\n                                </div>\n                            </div>\n                        </a>\n                        <a href=\'/devices\'>\n                            <div class=\'dropdown-section-item\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('devices') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('glossary.devices') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                        <a href=\'/global_indicators\'>\n                            <div class=\'dropdown-section-item dropdown\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('global') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('glossary.global_indicators') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                        <a href=\'/building_tags\'>\n                            <div class=\'dropdown-section-item\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('tags') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('glossary.building_tags') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                        <a href=\'/zone_attributes\'>\n                            <div class=\'dropdown-section-item\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('glossary.zone_attributes') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                        <a href=\'/groups\'>\n                            <div class=\'dropdown-section-item\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('groups') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('glossary.groups') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                        <a href=\'/users\'>\n                            <div class=\'dropdown-section-item\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('users') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('glossary.users') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                        <a href=\'/api_keys\'>\n                            <div class=\'dropdown-section-item dropdown-link\' data-href=\'/tariffs\'>\n                                <div class="dropdown-item">\n                                    <div class="dropdown-title">\n                                        <div class=\'dropdown-section-icon\'>\n                                            ' +
((__t = ( WiseMetering.icon('api_keys') )) == null ? '' : __t) +
'\n                                        </div>\n                                        ' +
((__t = ( i18next.t('glossary.api_keys') )) == null ? '' : __t) +
'\n                                    </div>\n                                    <div>\n                                        ';
 if (!WiseMetering.currentOrganization.hasAdvancedPermissions()) { ;
__p += '\n                                            <span class=\'lock\'>' +
((__t = ( WiseMetering.icon('lock') )) == null ? '' : __t) +
'</span>\n                                        ';
 } ;
__p += '\n                                    </div>\n                                </div>\n                            </div>\n                        </a>\n                    </div>\n                </div>\n            </div>\n        ';
 } ;
__p += '\n        <div class=\'item username\'>\n            <div class=\'dropdown\'>\n                <div class=\'dropdown-main current_user\'>\n                    ' +
((__t = ( WiseMetering.currentUser.getInitialsName() )) == null ? '' : __t) +
'\n                </div>\n                <div class=\'dropdown-section menu-right\'>\n                    <div class=\'dropdown-section-item user_profile\'>\n                        <div class=\'dropdown-section-icon\'>\n                            ' +
((__t = ( WiseMetering.icon('profile') )) == null ? '' : __t) +
'\n                        </div>\n                        ' +
((__t = ( i18next.t('ui.menu.my_profile') )) == null ? '' : __t) +
'\n                    </div>\n                    ';
 if (WiseMetering.currentUser.get('administrator')) { ;
__p += '\n                        <a href=\'/organizations/' +
((__t = ( WiseMetering.currentOrganization.id )) == null ? '' : __t) +
'\'>\n                            <div class=\'dropdown-section-item view-organization\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('cog') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('ui.menu.setup') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                    ';
 } ;
__p += '\n                    ';
 if (WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n                        <a href=\'/organizations/create\'>\n                            <div class=\'dropdown-section-item integrations\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('add') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('ui.menu.new_organization'))) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                    ';
 } ;
__p += '\n                    ';
 if (WiseMetering.currentUser.get('config')) { ;
__p += '\n                        <a href=\'https://newconfig.wisemetering.com\' target=\'_blank\'>\n                            <div class=\'dropdown-section-item route-config\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('config') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('ui.menu.config_app') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                    ';
 } ;
__p += '\n                    ';
 if (WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n                        <a href=\'' +
((__t = ( WiseMetering.apiUrl + '/static/report.html' )) == null ? '' : __t) +
'\' target=\'_blank\'>\n                            <div class=\'dropdown-section-item\'>\n                                <div class=\'dropdown-section-icon\'>\n                                    ' +
((__t = ( WiseMetering.icon('delays') )) == null ? '' : __t) +
'\n                                </div>\n                                ' +
((__t = ( i18next.t('ui.menu.delays_report') )) == null ? '' : __t) +
'\n                            </div>\n                        </a>\n                    ';
 } ;
__p += '\n                    <div class=\'dropdown-section-item logout\'>\n                        <div class=\'dropdown-section-icon\'>\n                            ' +
((__t = ( WiseMetering.icon('logout') )) == null ? '' : __t) +
'\n                        </div>\n                        ' +
((__t = ( i18next.t('glossary.logout') )) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\'item messages\'>\n            <div class=\'mail\'>\n                ' +
((__t = ( WiseMetering.icon('notifications', 'fa-2x') )) == null ? '' : __t) +
'\n            </div>\n            <div class=\'badge\' style=\'display: none;\'></div>\n        </div>\n        ';
 if (WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n            <div class=\'item hub\'>\n                <div class=\'hub\'>\n                    ' +
((__t = ( WiseMetering.icon('hub', 'fa-2x') )) == null ? '' : __t) +
'\n                </div>\n            </div>\n        ';
 } ;
__p += '\n        ';
 if (WiseMetering.currentUser.get('organizations').length > 1) { ;
__p += '\n            <div class=\'item change-organization\'>\n                <select class="text" id="organizations">\n                    ';
 if (WiseMetering.currentUser.get('superuser')) { ;
__p += '\n                        <optgroup label="' +
((__t = ( i18next.t('ui.menu.active_organizations') )) == null ? '' : __t) +
'">\n                            ';
 enabled.forEach(function(org){ ;
__p += '\n                                <option ' +
((__t = ( org.current ? 'selected ' : '' )) == null ? '' : __t) +
'value="' +
((__t = ( org.id )) == null ? '' : __t) +
'">\n                                    ' +
((__t = ( org.name )) == null ? '' : __t) +
'\n                                </option>\n                            ';
 }) ;
__p += '\n                        </optgroup>\n                        <optgroup label="' +
((__t = ( i18next.t('ui.menu.inactive_organizations') )) == null ? '' : __t) +
'">\n                            ';
 disabled.forEach(function(org){ ;
__p += '\n                                <option ' +
((__t = ( org.current ? 'selected ' : '' )) == null ? '' : __t) +
'value="' +
((__t = ( org.id )) == null ? '' : __t) +
'">\n                                    ' +
((__t = ( org.name )) == null ? '' : __t) +
'\n                                </option>\n                            ';
 }) ;
__p += '\n                        </optgroup>\n                    ';
 } else { ;
__p += '\n                        ';
 enabled.forEach(function(org){ ;
__p += '\n                            <option ' +
((__t = ( org.current ? 'selected ' : '' )) == null ? '' : __t) +
'value="' +
((__t = ( org.id )) == null ? '' : __t) +
'">\n                                ' +
((__t = ( org.name )) == null ? '' : __t) +
'\n                            </option>\n                        ';
 }) ;
__p += '\n                    ';
 } ;
__p += '\n                </select>\n            </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n\n<div id=\'wrapper\'>\n    <div id=\'elm_sidebar\'>\n        <div class=\'panels_container\'>\n            <div class=\'section\'>\n                <div class=\'head\'>\n                    <input class=\'search\' placeholder=\'' +
((__t = ( i18next.t('ui.tree_search') )) == null ? '' : __t) +
'\'>\n                </div>\n                <div class=\'body\' id=\'tree\'></div>\n            </div>\n        </div>\n    </div>\n    <div id=\'elm_content\'>\n        <div class=\'panels_container regular\' id=\'content\'></div>\n    </div>\n</div>\n';

}
return __p
}