module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion-group\'>\n    <div class=\'accordion-heading\'>\n        <div class=\'accordion-toggle\'>\n            <div class=\'svg_icon\'>\n                ' +
((__t = ( WiseMetering.icon('map') )) == null ? '' : __t) +
'\n            </div>\n            ' +
((__t = ( i18next.t('common.location') )) == null ? '' : __t) +
'\n            ';
 if (canEdit){ ;
__p += '\n                <div class=\'wmui-btn\' id=\'edit_location\' style=\'float: right;\'>' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'</div>\n            ';
 } ;
__p += '\n            ';
 if ( WiseMetering.currentUser.isSuperUser() ) { ;
__p += '\n                <div class=\'wmui-btn edit-panorama\' id=\'edit-panorama\' style="width:90px;margin-right:5px;">' +
((__t = ( i18next.t('ui.buttons.edit_panorama') )) == null ? '' : __t) +
'</div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n    <div id=\'building-map\' style="width: 100%; height: 250px;">\n    </div>\n</div>\n';

}
return __p
}