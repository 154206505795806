module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'widget\'>\n    <div class=\'title\'>\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\n    </div>\n    <div class=\'content\'>\n        <div class=\'chart\' id=\'' +
((__t = ( id )) == null ? '' : __t) +
'\'></div>\n    </div>\n</div>\n';

}
return __p
}