(function ($) {

	$.msg= function (options) {
		return $.msg.def.init(options);
	};

	$.msg.clear= function () {
		$.msg.def.clear(true);
	};

	$.msg.defaults = {
		text:null,
		style:'soft',
		closable:true,
		anim:false,
		fadeIn:1000,
		fadeOut:1000,
		time:3,
		delay:0,
		target:'body',
		width:410
	};

	$.msg.def= {
		opts: null,
		init: function (options) {
			// add_to_Queue	
			this.close();
			this.opts = $.extend({}, $.msg.defaults, options);	
			this.create();
			this.show();
			return this;			
		},
		create: function(){		
			var std=$('<div>').addClass('msg_box');
			std.append("<h4 class='" +  this.opts.style  + " crn_all'><a href='#' class='icn_cls spt _icn_'></a>" +  this.opts.text  +"</h4>");
			std.css({opacity:0, width:this.opts.width, left: (($(this.opts.target).width()/2)-(this.opts.width/2)) + "px"});			
			std.appendTo($(this.opts.target));		
			$('a.icn_cls').bind('click', function(){$.msg.def.close();});
			this.show();			
		},	
		position:function(){
			$('div.msg_box').remove();	
		},
		
		close:function(){
			$('div.msg_box').remove();	
		},
				
		clear:function(){
			$('div.msg_box').fadeOut('fast',function(){$('div.msg_box').remove();});
		},
		
		show:function(){
			if (this.opts.anim){
			  $('div.msg_box').animate({opacity:1}, this.opts.fadeIn,"linear");
			}
		},
		
		hide:function(){
			
		}
		
	};	

})(jQuery);

