WiseMetering.Views.CircuitAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/circuits/accordion',

    onRender: function() {
        const collection = this.model.circuits();

        this.table = new WiseMetering.Views.Table({
            id: 'circuits',
            collection: collection,
            columns: {
                name: i18next.t('common.name'),
                system_category_name: i18next.t('glossary.circuit_type')
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.CircuitAdd({
                        collection: WiseMetering.circuits,
                        model: new WiseMetering.Model.Circuit({ zone_id: `${this.model.id}` })
                    })
                },
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.Modal.Destroy({
                        model,
                        afterDestroy: function() {
                            WiseMetering.actuators.fetch();
                            WiseMetering.indicators.fetch();
                        }
                    })
                }
            }
        });

        this.$('#circuits').html(this.table.el);

        this.listenTo(this.model, 'change:children:addCircuit', function(node) {
            collection.add(node);
        });
        this.listenTo(this.model, 'change:children:removeCircuit', function(node) {
            collection.remove(node);
        });
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    }

});
