WiseMetering.Model.Circuit = WiseMetering.Model.extend({
    urlRoot: '/circuits',

    activeEnergy: function() {
        const activeEnergyKind = WiseMetering.indicatorKinds.findWhere({ name: 'Active Energy' });
        if (!activeEnergyKind) {
            return activeEnergyKind;
        }
        return WiseMetering.indicators.findWhere({ parent_id: this.id, kind_id: activeEnergyKind.id });
    },

    actuator: function() {
        return WiseMetering.actuators.get(this.get('actuator_id'));
    },

    active_energy: function() {
        return WiseMetering.indicators.get(this.get('active_energy'));
    },

    actuatorName: function() {
        const actuator = this.actuator();
        return actuator ? actuator.get('name') : null;
    },

    building: function() {
        return this.zone().building();
    },

    children: function() {
        return new WiseMetering.Collection(this.indicators().models.reverse());
    },

    currentMonth: function() {
        try {
            const year = moment().year(), month = moment().month();
            return this.get('data').active_energy[year][month];
        } catch (e) {
            return null;
        }
    },

    currentYear: function() {
        try {
            const year = moment().year();
            return this.get('data').active_energy[year].reduce((acc, value) => acc + value, 0);
        } catch (e) {
            return null;
        }
    },

    fullPath: function() {
        let path = [this.get('name')], parent = this.parent();

        while (parent) {
            path.unshift(parent.get('name'));
            parent = parent.parent();
        }

        return path.join(' > ');
    },

    icon: function() {
        let icon = this.tag().get('system_category_name').toLowerCase().split(' ').join('_');
        if (WiseMetering.icons[icon]) {
            return icon;
        } else {
            return 'circuits';
        }
    },

    indicators: function() {
        return new WiseMetering.Collection.Indicators(WiseMetering.indicators.where({ parent_id: this.id }));
    },

    nodeUrl: function(skipSearch) {
        return `/circuits/${this.get('id')}${skipSearch ? '' : location.search}`;
    },

    parent: function() {
        const parentId = this.get('parent_id'),
            rootObject = WiseMetering.rootObjects.findWhere({ building_id: parentId, slug: 'circuits' });

        return rootObject || WiseMetering.zones.get(parentId);
    },

    searchString: function() {
        const building = this.building();
        const tag = this.tag();

        let search = building.get('name');
        search += ` ${building.get('tag_names').join(' ')}`;
        search += ` ${this.get('name')}`;
        search += ` ${tag.get('name')}`;

        return search;
    },

    type: function() {
        return 'circuit';
    },

    zone: function() {
        return WiseMetering.zones.get(this.get('parent_id'));
    },

    tag: function() {
        return WiseMetering.circuitTags.get(this.get('tag_id'));
    }
});
