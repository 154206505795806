module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    ';
 if (showHeader) { ;
__p += '\n        <dt>\n            ' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'\n        </dt>\n        <dd>\n            ' +
((__t = ( utility_kind )) == null ? '' : __t) +
'\n        </dd>\n        <dt>\n            ' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'\n        </dt>\n        <dd>\n            ' +
((__t = ( tariffType )) == null ? '' : __t) +
'\n        </dd>\n        <dt>\n            ' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'\n        </dt>\n        <dd>\n            ' +
((__t = ( name )) == null ? '' : __t) +
'\n        </dd>\n        <dt>\n            ' +
((__t = ( i18next.t('glossary.buildings') )) == null ? '' : __t) +
'\n        </dt>\n        <dd>\n            ' +
((__t = ( buildings || i18next.t('tariffs.no_buildings') )) == null ? '' : __t) +
'\n        </dd>\n        <dt>\n            ' +
((__t = ( i18next.t('glossary.contracts') )) == null ? '' : __t) +
'\n        </dt>\n        <dd>\n            ' +
((__t = ( contracts || i18next.t('tariffs.no_contracts') )) == null ? '' : __t) +
'\n        </dd>\n        <dt>\n            ' +
((__t = ( i18next.t('glossary.tenants') )) == null ? '' : __t) +
'\n        </dt>\n        <dd>\n            ' +
((__t = ( tenants || i18next.t('tariffs.no_tenants') )) == null ? '' : __t) +
'\n        </dd>\n        <dt>\n            ' +
((__t = ( i18next.t('glossary.timezone') )) == null ? '' : __t) +
'\n        </dt>\n        <dd>\n            ' +
((__t = ( timezone )) == null ? '' : __t) +
'\n        </dd>\n    ';
 } ;
__p += '\n    <dt>\n        <label for="tariff-interval">\n            ' +
((__t = ( i18next.t('common.intervals') )) == null ? '' : __t) +
'\n        </label>\n    </dt>\n    <dd>\n        <select class="text" id="tariff-interval" style="text-transform: none">\n        </select>\n    </dd>\n    <div class=\'' +
((__t = ( showHeader ? '' : ' no-border' )) == null ? '' : __t) +
'\' id="tariff-values">\n    </div>\n</dl>\n\n\n';

}
return __p
}