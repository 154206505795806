WiseMetering.Views.ComplexIndicator = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/types/complex',

    ui: {
        container: '#operation-container',
        type: '#operation-type'
    },

    events: {
        'change #operation-type': 'onOperationTypeChange',
        'click #operation-container': 'disableType'
    },

    disableType: function() {
        this.ui.type.attr('disabled', 'disabled');
    },

    onOperationTypeChange: function() {
        $('select#indicator_building').attr('disabled', 'disabled');

        let view;

        switch (this.ui.type.val()) {
            case 'advanced':
                view = new WiseMetering.Views.AdvancedOperation({ modal: this.options.modal, model: this.model });
                break;
            case 'aggregation':
                view = new WiseMetering.Views.AggregationOperation({ modal: this.options.modal, model: this.model });
                break;
            case 'clone':
                view = new WiseMetering.Views.CloneOperation({ modal: this.options.modal, model: this.model});
                break;
            default:
                break;
        }

        view ? view.setElement(this.ui.container).render() : this.ui.container.empty();

        this.options.modal.redraw();
    }
});
