Lmit.UI.Button = function(_text_, _container_, _options, _actions_)
{
  this.text = _text_;
  this.options = _options || {
  };
  this.id=_options.id || Lmit.Utils.generateCode();
  this.value = _text_;
  this.actions = _actions_;
  this.container = _container_;
  this.element = null;
  this.init();
};
Lmit.UI.Button.prototype = {
  init:function()
  {
    this.build();
  },

  build:function()
  { 
    if(this.options.block){
      this.element = $("<div>");
    } else {
      this.element = $("<li>");
    }  
    this.element.addClass("wmui-btn");
    
    var lnk = $("<a>").attr({href:"/"}).text(this.text);
    this.element.append(lnk);

    if (this.options.style) {this.element.css(this.options.style);}
    if (this.options["class"] || this.options.klass){this.element.addClass(this.options["class"] || this.options.klass);}
    if (this.options.id) {this.element.attr({id:this.options.id});}
    if (this.options.disable) {this.disable();}
    
    this.container.append(this.element);
    this.bindEvents();
  },

  bindEvents: function()
  {
    var _this_ = this;
    $(this.element).click(function(e){
        e.preventDefault();
        e.stopPropagation();
        _this_.actions.onSelect(_this_.value,e,_this_);
    });
  },
  disable:function()
  {
    this.element.hide();
  },
  enable:function()
  {
    this.element.show();
  },
  toggle: function()
  {
    this.element.toggle();
  }
};
