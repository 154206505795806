WiseMetering.Views.Activities = WiseMetering.Views.TableIndex.extend({
    createTable: function() {
        const collection = this.model.activities(),
            timezone = this.model.timezone();

        this.table = new WiseMetering.Views.Table({
            id: 'activities',
            collection: collection,
            modelSerializer: function(model) {
                const json = model.toJSON();
                json.date = model.get('date') ? WiseMetering.utils.formatDate(model.get('date'), timezone) : '-';
                json.results = model.get('results') ? model.get('results').replace('\n', '<br/>') : '-';
                return json;
            },
            columns: {
                state: {
                    title: i18next.t('common.state'),
                    className: 'center',
                    width: '5%',
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({ color, title }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                type: i18next.t('common.type'),
                results: i18next.t('common.results'),
                date: i18next.t('common.date')
            },
            types: {
                from: 'date',
                end_date: 'date'
            },
            toolbar_actions: {
                show: {
                    view_call: function(model) {
                        if (model.get('type') === 'marker') {
                            new WiseMetering.Views.ShowMarker({
                                model: WiseMetering.markers.get(model.id)
                            });
                        } else {
                            new WiseMetering.Views.OpportunityModalDetails({
                                model: WiseMetering.opportunities.get(model.id)
                            });
                        }
                    }.bind(this)
                }
            }
        });
        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.markers);
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.markers, 'remove add change', this.createTable.bind(this));
        this.createTable();
    }
});
