Lmit.UI.Input = function(_name_, _container_, _options_,_actions_) {
	this.name = _name_;
	this.id = _options_.id || null;
	this.options= _options_;	
	this.value = null;
	this.actions= _actions_;
	this.container = _container_ ;
  this.input = null;
	this.element = null;
	this.init();
};	


Lmit.UI.Input.prototype = {
	init:function(){
		this.build();
	},
	
	build:function(){ 
    this.element = $("<li>").addClass("wmui-input-" + this.options.type);
    this.input = $("<input>").attr({name:this.name, id:this.id, type:this.options.type});
    if (this.options.label){
      this.label = $("<label>").text(this.options.label);
      this.element.append(this.label);
    }
    this.element.append(this.input);
    this.container.append(this.element);
    this.bindEvents();
	},
	
	bindEvents: function() {
	  var _this_ = this;
	  $(this.input).change(function(e){
        _this_.actions.onChange(this.value, e);
	  });
	  
  },
	
	disable:function(){
      // this.element.removeClass("current");
	},
	
	enable:function(){
	},
	
	remove:function(){
	  
	},
	
	to_s:function(){
      
	}
		
};
    
