WiseMetering.Views.EditInvoice = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/invoices/edit',
    title: () => i18next.t('modal.title.invoices.edit'),
    form_prefix: 'invoice',
    className: 'mbx-info text-left',

    ui: {
        fileLink: '.file-link',
        removeFile: '#remove-file',
        spinner: '#spinner',
        uploadFile: '#upload-file'
    },

    events: {
        'change #upload-file': 'onUploadFile',
        'click #remove-file': 'onRemoveFile'
    },

    modelEvents: {
        'change': 'render'
    },

    serializeData: function() {
        const json = this.model.toJSON();

        if (this.model.unit() === 'kWh') {
            json.consumption = json.consumption / 1000;
        }

        json.file_link = this.model.fileLink();

        return json;
    },

    getFormData: function() {
        let data = this.formSerializer();

        data = _.omit(data, 'file');

        if (this.model.unit() === 'kWh') {
            data.consumption = data.consumption * 1000;
        }

        return data;
    },

    onRemoveFile() {
        this.ui.fileLink.hide();
        this.ui.removeFile.hide();
        this.ui.spinner.show();
        $('.toolbar_button').attr('disabled', 'disabled');
        WiseMetering.layout.showTipper('info', i18next.t('ui.removing_file'), false);

        this.model.removeFile().done(function() {
            WiseMetering.layout.showTipper('success', i18next.t('ui.file_removed_successfully'));
            $('.toolbar_button').removeAttr('disabled', 'disabled');
        }.bind(this));
    },

    onUploadFile: function() {
        const file = this.ui.uploadFile.prop('files')[0];
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            WiseMetering.layout.showTipper('info', i18next.t('ui.uploading_file'), false);
            this.ui.spinner.show();
            this.ui.uploadFile.hide();
            $('.toolbar_button').attr('disabled', 'disabled');

            this.model.uploadFile(formData).done(() => {
                WiseMetering.layout.showTipper('success', i18next.t('ui.file_uploaded_successfully'));
                $('.toolbar_button').removeAttr('disabled', 'disabled');
            }).fail((response) => {
                let message = JSON.parse(response.responseText)?.errors?.file || i18next.t('invoices.failed_to_update');
                WiseMetering.layout.showTipper('error', message, 5000);
            });
        }
    }
});
