WiseMetering.Views.Costs = WiseMetering.Views.Content.extend({
    template: 'templates/ui/costs',

    currentTariff: null,
    period: {
        from: null,
        to: null
    },

    ui: {
        dataTable: '.dataTable tbody',
        fromField: '.date_time_from',
        loader: '.costs-loader',
        utilityKindField: '#select-utility-kind',
        utilityKindSection: '#select-utility-kind-section',
        tariffField: '.select_tariff',
        timeSubmit: '.submit-time',
        toField: '.date_time_to'
    },

    events: {
        'click .submit-time': 'populateTable',
        'change @ui.utilityKindField': 'populateTable'
    },

    buildTotalRow: function(withoutVat, withVat) {
        let totals = {
            withoutVat: formatValue(withoutVat, '€'),
            withVat: formatValue(withVat, '€')
        };

        this.ui.dataTable.append(this.renderTotalTemplate(totals));
    },

    fetchCosts: function(data, success, error) {
        const
            url = `${this.model.url()}/costs`,
            type = 'GET',
            dataType = 'json',
            contentType = 'application/json';

        return $.ajax({ contentType, data, dataType, error, success, type, url });
    },

    getConsumption: function(type, details = {}) {
        let element = this.$el.find(`#${type}-consumption`);
        element.html('loading...');

        let value = details[`${type}_consumption`]?.total || details[`${type}_consumption`], unit;

        switch (type) {
            case 'active':
                unit = 'Wh';
                break;
            case 'capacitive':
            case 'inductive':
                unit = 'VArh';
                break;
            case 'contracted_power':
            case 'peak_power':
                unit = 'Wh';
                break;
            case 'variable':
            case 'variable_rsu':
                unit = 'm³';
                break;
            case 'vat':
                if (details.vat) {
                    value = details.vat;
                    unit = '%';
                }
                break;
            case 'vat6':
                value = 6;
                unit = '%';
                break;
            case 'vat21':
                value = 21;
                unit = '%';
                break;
            case 'vat23':
                value = 23;
                unit = '%';
                break;
        }

        let html = '-';
        if (value !== undefined && value !== null) {
            html = formatValue(value, unit, 2, 3);
        }

        element.html(html);
    },

    onShow: function() {
        this.period.from = this.options.minDate;
        this.period.to = this.options.maxDate;

        this.setupUtilityKind();

        this.setupFromField();
        this.setupToField();
        this.updateFields();
        this.populateTable();
    },

    populateTable: function() {
        if (this.ui.dataTable) {
            this.ui.dataTable.empty();
        }

        const params = {
            from: this.ui.fromField.val(),
            to: this.ui.toField.val()
        };

        if (this.options.withUtilityKind) {
            params.utility_kind = this.ui.utilityKindField.val();
        }

        WiseMetering.utils.addLoader(this.ui.loader, 0, 0, 38);
        this.fetchCosts(params, costs => this.renderTable(costs), errors => this.renderError(errors));
    },

    renderEmptyTemplate: function() {
        return `
            <tr style="background-color: #F2F2F2">
                <td class="align-center" colspan="3">
                  <div>No data available</div>
                </td>
            </tr>
        `;
    },

    renderError: function(errors) {
        let dataTable = this.ui.dataTable;
        dataTable.empty();

        this.ui.loader.empty();
        dataTable.html(`
            <tr style="background-color: #F2F2F2">
                <td class="align-center" colspan="3">
                  <div style="color: #E94547">Error fetching data</div>
                </td>
            </tr>
        `);

    },

    renderLineTemplate: function({ className, name, type, cost }) {
        return `
            <tr class="${className}">
                <td>${name}</td>
                <td id="${type}-consumption" class="align-right" style="padding-right: 20px">-</td>
                <td id="${type}-cost" class="align-right" style="padding-right: 20px">${cost}</td>
            </tr>
        `;
    },

    renderTable: function(data) {
        let dataTable = this.ui.dataTable;
        dataTable.empty();

        this.ui.loader.empty();

        if (Object.keys(data).length === 0) {
            dataTable.html(this.renderEmptyTemplate());
            return;
        }

        const { details, total, total_without_vat, ...costs } = data;

        Object.entries(costs).forEach(([key, val = 0.0], i) => {
            const template = this.renderLineTemplate({
                name: key.splitCapitalize('_'),
                type: key,
                cost: formatValue(val, '€'),
                className: i % 2 === 0 ? 'odd' : 'even'
            });

            dataTable.append(template);

            this.getConsumption(key, details);
        });

        //Create total line
        this.buildTotalRow(total_without_vat, total);
    },

    renderTotalTemplate: function(totals) {
        return `
            <tr id="total" style="background-color: #8BC53F">
                <td colspan="2" style="color: white; font-weight:bold; font-size:12px">Total</td>
                <td id="total-cost" class="align-right" style="color:white;font-weight:bold;font-size:12px;padding-right:20px">
                  <div>${totals.withVat}</div>
                  <div style='font-weight:normal;font-size:10px'>(without VAT ${totals.withoutVat || totals.withVat})</div>
                </td>
            </tr>
        `;
    },

    serializeData: function() {
        return {
            title: this.type
        };
    },

    setupDatepicker: function(field, callback) {
        field.datepicker({
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            minDate: this.period.from,
            maxDate: this.period.to,
            onSelect: callback
        });
    },

    setupFromField: function() {
        this.ui.fromField.val(this.period.from);
        this.setupDatepicker(this.ui.fromField, dateText => {
            this.period.from = dateText;
            this.updateFields();
        });
    },

    //Build Total Row
    setupToField: function() {
        this.ui.toField.val(this.period.to);
        this.setupDatepicker(this.ui.toField, dateText => {
            this.period.to = dateText;
            this.updateFields();
        });
    },

    setupUtilityKind: function() {
        if (this.options.withUtilityKind) {
            const options = WiseMetering.utilityKinds.map(utilityKind => ({ id: utilityKind.get('slug'), name: utilityKind.get('name') }));
            this.ui.utilityKindField.html(WiseMetering.utils.optionsFromArray(options, 'electricity'));
        } else {
            this.ui.utilityKindSection.remove();
        }
    },

    updateFields: function() {
        this.ui.fromField.datepicker('option', 'maxDate', this.ui.toField.val());
        this.ui.toField.datepicker('option', 'minDate', this.ui.fromField.val());
    }
});
