import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import * as React from 'react';

export default function NestedList() {
    return (
        <List component="nav">
            <ListItemButton selected>
                <ListItemIcon sx={{ minWidth: 24 }}>
                    <FontAwesomeIcon icon="ballot-check"/>
                </ListItemIcon>
                <ListItemText primary="Summary"/>
            </ListItemButton>
        </List>
    );
}
