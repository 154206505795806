module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span style="font-weight:bold; color:' +
((__t = ( colors[1] )) == null ? '' : __t) +
'">Objective:</span> ' +
((__t = ( objectiveValue )) == null ? '' : __t) +
'\n<br>\n<span style="font-weight:bold; color:' +
((__t = ( colors[0] )) == null ? '' : __t) +
'">';
 if (unit === '€') { ;
__p += 'Cost';
 } else { ;
__p += 'Consumption';
 } ;
__p += ':</span> ' +
((__t = ( realValue )) == null ? '' : __t) +
'\n<span style="font-weight:bold; color:' +
((__t = ( positive ? WiseMetering.SemaphorColors.red : WiseMetering.SemaphorColors.green )) == null ? '' : __t) +
'">\n    (' +
((__t = ( positive ? '+' : '' )) == null ? '' : __t) +
'' +
((__t = ( percentage )) == null ? '' : __t) +
')\n</span>\n';

}
return __p
}