// Basic Routing setup
WiseMetering.Router = Backbone.Marionette.AppRouter.extend({
    appRoutes: {
        '': 'drawDefault',
        'actuators': 'drawActuators',
        'admin': 'drawAdmin',
        'alarms': 'drawAlarms',
        'all_tenants': 'drawTenants',
        'all_tenants/:id': 'drawTenant',
        'api_keys': 'drawApiKeys',
        'buildings/:id/:view': 'drawBuilding',
        'building_tags': 'drawBuildingTags',
        'circuits': 'drawCircuits',
        'circuits/:id?*querystring': 'drawCircuit',
        'circuits/:id': 'drawCircuit',
        'circuit_types': 'drawCircuitTags',
        'companies': 'drawCompanies',
        'contracts/:id/:view?*querystring': 'drawContract',
        'contracts/:id/:view': 'drawContract',
        'dashboard': 'drawDashboard',
        'devices': 'drawDevices',
        'events': 'drawEvents',
        'events?*querystring': 'drawEvents',
        'etls': 'drawEtls',
        'folders/:id': 'drawFolder',
        'global_indicators': 'drawGlobalIndicators',
        'groups': 'drawGroups',
        'holidays': 'drawHolidays',
        'home': 'drawDashboard',
        'hub': 'drawIntegrationPartners',
        'indicators': 'drawIndicators',
        'indicators/:id/:view?*querystring': 'drawIndicator',
        'indicators/:id/:view': 'drawIndicator',
        'integration_partners/:id/integrations': 'drawIntegrations',
        'login': 'drawLogin',
        'old_login': 'drawOldLogin',
        'markers': 'drawMarkers',
        'messages': 'drawMessages',
        'organizations/create': 'createOrganization',
        'organizations/:id': 'drawOrganization',
        'organizations/:id/edit': 'editOrganization',
        'readings': 'drawReadings',
        'reports': 'drawReports',
        'seus/:id': 'drawSeu',
        'tariffs': 'drawTariffs',
        'reset_password/:id': 'drawResetPassword',
        'root_objects/:id/:view': 'drawRootObject',
        'root_objects/:id': 'drawRootObject',
        'tenants/:id': 'drawTenant',
        'tree': 'drawTree',
        'users': 'drawUsers',
        'user_profile': 'drawUserProfile',
        'utilities/:id/:view': 'drawUtility',
        'utilities/:id/:view?*querystring': 'drawUtility',
        'zone_attributes': 'drawZoneAttributes',
        'zones/:id/:view': 'drawZone',
        'zones/:id/:view?*querystring': 'drawZone',
        'map': 'drawMap',
        '*path': 'drawDefault'
    }
});

WiseMetering.addInitializer(() => {
    $(document).on('click', 'a[href^="/"]', function(event) {
        event.preventDefault();
        const href = $(event.currentTarget).attr('href');
        Backbone.history.navigate(href, { trigger: true });
    });

    new WiseMetering.Router({ controller: WiseMetering.controller });
    Backbone.history.start({ pushState: true, silent: true });
});
