module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(options, function(option) { ;
__p += '\n    <div class="small-card">\n        <div class="label">' +
((__t = ( option.label )) == null ? '' : __t) +
'</div>\n        <div class="value">' +
((__t = ( option.value )) == null ? '' : __t) +
'</div>\n        <div class="bottom-label green">' +
((__t = ( option.bottomLabel )) == null ? '' : __t) +
'</div>\n    </div>\n';
 }) ;
__p += '\n';

}
return __p
}