WiseMetering.Views.ShowTar = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    form_prefix: 'tar',
    template: 'templates/tars/show',
    ui: {
        interval: '#tar-interval',
        values: '#tar-values'
    },
    events: {
        'change #tar-interval': 'changeInterval'
    },
    title: () => i18next.t('modal.title.tars.show'),

    buttons: function() {
        return ['close'];
    },

    changeInterval: function() {
        const tarStart = this.ui.interval.val();
        this.renderValues(tarStart);
    },

    serializeData: function() {
        return {
            tarType: this.model.get('slug').capitalize(),
            tarName: i18next.t(`glossary.${this.model.get('slug')}`).capitalize(),
            showHeader: true
        };
    },

    onRender: function() {
        const tarStart = this.model.lastestTar().start;

        this.renderSelect(tarStart);
        this.renderValues(tarStart);
    },

    renderValues: function(startDate) {
        const
            index = null,
            interval = this.model.get('intervals').find(interval => interval.start === startDate),
            unit = '€',
            ranges = interval || [];

        const html = JST[`templates/tars/${this.model.get('slug')}`]({ index, interval, ranges, unit });
        this.ui.values.html(html);

        this.$('.delRange').remove();
        this.$('input').attr('disabled', 'disabled');
    },

    renderSelect: function(startDate) {
        const options = this.model.get('intervals').map(interval => {
            return { id: interval.start, name: i18next.t('tariffs.interval_message', { from: interval.start, to: interval.finish }) };
        });

        this.ui.interval.html(WiseMetering.utils.optionsFromArray(options, startDate));
    }
});
