WiseMetering.Collection.Opportunities = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Opportunity,
    url: '/opportunities',

    findByBuildingId: function(buildingId) {
        return new WiseMetering.Collection.Opportunities(
            this.filter(opportunity => opportunity.get('building_id') === buildingId)
        );
    }
});
