WiseMetering.Views.ShowIntegrationAsset = WiseMetering.Views.Modal.Show.extend({
    buttons: ['close', 'edit'],
    className: 'mbx-info text-left',
    template: 'templates/integration_assets/show',
    title: () => i18next.t('modal.title.integration_assets.show'),

    edit: function() {
        this.block();
        _.defer(function() {
            new WiseMetering.Views.EditIntegrationAsset({ model: this.model });
        }.bind(this));
    },

    onRender: function() {
        const $container = this.$('.sliders-container');

        this.model.get('settings').forEach(function(setting) {
            const settingView = new WiseMetering.Views.IntegrationAssetSetting({ model: new Backbone.Model(setting), readOnly: true });

            settingView.render();
            $container.append(settingView.el);
        }, this);
    },

    serializeData: function () {
        return Object.assign(
            this.model.toJSON(), {
                building_name: this.model.building().get('name'),
                integration_name: this.model.integrationService().get('name'),
                integration_partner_name: this.model.integrationPartner().get('name'),
                organization_name: WiseMetering.currentOrganization.get('name')
            }
        )
    }
});
