// const { default: i18next } = require("i18next");

WiseMetering.Views.UtilityDetails = Backbone.Marionette.ItemView.extend({
    template: 'templates/utilities/details',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click #edit_attributes': 'editAttributes'
    },

    serializeData: function() {
        return {
            capacitiveEnergy: this.model.capacitiveEnergy(),
            contracts: this.model.contracts().length,
            indicator: this.model.indicator(),
            inductiveEnergy: this.model.inductiveEnergy(),
            lastChange: this.model.lastChange(),
            name: this.model.get('name'),
            utilityKindName: i18next.t(`utility_kinds.${this.model.utilityKind().get('slug')}`).capitalize(),
            utilityKindSlug: this.model.utilityKind().get('slug')
        };
    },

    editAttributes: function(event) {
        event.stopPropagation();
        new WiseMetering.Views.EditUtility({ model: this.model });
    }
});
