module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <input name=\'zone_attribute[organization_id]\' type=\'hidden\' value=\'' +
((__t = ( WiseMetering.currentOrganization.id )) == null ? '' : __t) +
'\'>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'zone_attribute_name\'>Name</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'zone_attribute_name\' name=\'zone_attribute[name]\' type=\'text\'>\n        </dd>\n\n        <dt>\n            <label for=\'zone_attribute_metadata_type\'>Attribute Type</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'zone_attribute_metadata_type\' name=\'zone_attribute[metadata_type]\'>\n                ';
 metadataTypes.forEach(function(metadata_type){ ;
__p += '\n                    <option value=\'' +
((__t = ( metadata_type )) == null ? '' : __t) +
'\'>' +
((__t = ( metadata_type )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <label for=\'zone_attribute_unit_id\'>Attribute Unit</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'zone_attribute_unit_id\' name=\'zone_attribute[unit_id]\'>\n                ';
 WiseMetering.units.each(function(unit){ ;
__p += '\n                    <option value=\'' +
((__t = ( unit.id )) == null ? '' : __t) +
'\'>' +
((__t = ( unit.get('symbol') )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n        <dd class=\'enumerator_setup\' style=\'display:none;\'>\n            <label for=\'zone_attribute_enumerator\'>Enumerator Members</label>\n            <ul class=\'enumerator_list\' style=\'width:250px; list-style:none outside none; list-style-type:none; margin:0;\'></ul>\n            <a class=\'new_enumerator_member\' href=\'#\'>New Member</a>\n            <br>\n            <input class=\'new_member text\' style=\'display:none;\' type=\'text\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}