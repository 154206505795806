WiseMetering.Views.Circuits = WiseMetering.Views.TableIndex.extend({
    icon: 'circuits',
    name: () => i18next.t('glossary.circuits'),

    createTable: function() {
        return new WiseMetering.Views.Table({
            id: 'circuit-schedules',
            collection: WiseMetering.circuits,
            modelSerializer: function(model) {
                const json = model.toJSON(), updater = model.updater();
                json.circuitName = model.fullPath();
                json.updater = updater ? updater.get('name') : 'System';
                json.updated_at = WiseMetering.utils.formatDate(model.get('updated_at'));
                return json;
            },
            columns: {
                circuitName: i18next.t('glossary.circuit'),
                updater: i18next.t('glossary.updated_by'),
                updated_at: i18next.t('glossary.updated_at')
            },
            toolbar_actions: {
                new: {
                    label: i18next.t('circuits.force_schedule'),
                    icon: 'forceSchedule',
                    view_call: () => new WiseMetering.Views.ForceCircuitSchedule
                },
                show: {
                    view_call: model => new WiseMetering.Views.ShowCircuitSchedule({ model })
                },
                edit: {
                    permission: 'administrator',
                    view_call: model => {
                        model.fetch({
                            success: model => new WiseMetering.Views.EditCircuitSchedule({ model: model })
                        });
                    }
                }
            }
        });
    }
});
