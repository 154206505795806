WiseMetering.Model.Contract = WiseMetering.Model.extend({
    urlRoot: '/contracts',

    children: function() {
        return new WiseMetering.Collection;
    },

    formatContractedPower: function() {
        if (!this.get('extra')) {
            return null;
        }

        if (this.get('extra').contracted_power) {
            const value = this.get('extra').contracted_power;
            return formatValue(value, 'W', 2, 3);
        }

        const contracted_power = [];

        Object.entries(this.get('extra')).forEach(([key, value]) => {
            const name = key.split('_').pop().capitalize();
            contracted_power.push(`${name}: ${formatValue(value, 'W', 2, 3)}`);
        });

        return contracted_power.join(', ');
    },

    icon: function() {
        return 'contracts';
    },

    indicator: function() {
        return this.utility().indicator();
    },

    nodeUrl: function() {
        return `/contracts/${this.get('id')}/details${location.search}`;
    },

    parent: function() {
        return this.utility();
    },

    tariff: function() {
        return WiseMetering.tariffs.get(this.get('tariff_id'));
    },

    type: function() {
        return 'contract';
    },

    utility: function() {
        return WiseMetering.utilities.get(this.get('utility_id'));
    }
});
