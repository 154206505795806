WiseMetering.Model.GlobalReportType = WiseMetering.Model.extend({
    urlRoot: '/report_types/global_report_types',

    getDescription: function() {
        let reportDescription = 'The following report ';

        if (this.get('type') === 'GlobalUtilityReport') {
            reportDescription += 'provides an analysis to building utility_kinds, comparing with previous year and objectives. This report can be generated either in terms of consumption or in terms of cost.';
        } else if (this.get('type') === 'GlobalEventsReport') {
            reportDescription += 'lists all alarm events triggered in the chosen period.';
        } else if (this.get('type') === 'GlobalDetailedReport') {
            reportDescription += 'details utility_kind consumption in a multi-site analysis.';
        } else if (this.get('type') === 'GlobalOptimiserReport') {
            reportDescription += 'analyses WiseMetering implementation to determine how your company uses certain features. We then identified a few ways that you can improve your implementation for your company.';
        } else if (this.isCustomisedReport()) {
            reportDescription += 'is a customized report.';
        }

        return reportDescription;
    },

    isCustomisedReport: function() {
        return this.get('category') === 'Custom';
    }
});
