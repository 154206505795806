Lmit.Wise.Chart.Generic = function() {
    this.id = null;
    this.cid = null;
    this.options = null;
    this.controller = null;
    this.container = null;
    this.type_of = null;
    this.name = null;
    this.code = null;
    this.category = null;
    // settings
    this.view = null;
    this.observers = null;
    this.chart = null;
    //the base indicator settings
    this.settings = null;
    this.yAxis = null;
    this.request_id = null;
    //some defaults, this should be a global objecct Lmit.Wise.Chart.Options
};

Lmit.Wise.Chart.Generic.prototype = {

    init: function(_controller, _container, _settings, _options) {
        this.observers = [];
        this.chart = null;
        this.uid = _options.id;
        this.settings = _settings;
        this.id = Lmit.Utils.generateCode();
        this.options = _options;
        this.controller = _controller;
        this.container = _container;
        this.code = _options.code;
        this.units = _options.units || [];
        this.request_id = _options.request_id;
        if (_options.category) {
            this.category = _options.category;
        }
        this.build();
    },

    register: function(item) {
        this.observers.push(item);
        return true;
    },

    notifyObservers: function(_from, _to) {
        var _this_ = this;
        _from = Lmit.Utils.normalizeDateTime(_from);
        _to = Lmit.Utils.normalizeDateTime(_to);
        _.each(_this_.observers, function(item) {
            item.onPeriodChange(_from, _to, true);
        });
    },

    remove: function() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
            this.view.remove();
        }
    },

    toString: function() {
        return ' - ' + this.type_of + ', ' + this.name;
    },

    build: function() {
        this.view = $('<div>').addClass('wmui-hc wmui-hc-' + this.type_of).attr({ id: this.id }).css({ opacity: 0 });
        this.container.append(this.view);
    },

    redraw: function() {
    },

    resize: function(args) {
        if (this.chart && this.chart.setSize) {
            this.chart.setSize(args[0] - 10, args[1], false);
        }
    },

    onMouseOver: function(_x, _y, _name, _color, _code) {
        this.controller.onChartCurveOver([_x, _y, _name, _color, _code]);
    },

    onHide: function(_id_) {
        this.controller.resumeController.hideBoard(_id_);
    },

    onShow: function(_id_) {
        this.controller.resumeController.showBoard(_id_);
    },

    onMouseOut: function() {
        this.controller.onChartCurveOut();
    },

    removeSeries: function() {
        for (let i = this.chart.series.length - 1; i >= 0; i--) {
            this.chart.series[i].remove();
        }
    },

    removeSeriesById: function(id) {
        for (let i = this.chart.series.length - 1; i >= 0; i--) {
            if (this.chart.series[i].options.id === id) {
                this.chart.series[i].remove();
            }
        }
    },

    removeSeriesByCode: function(code) {
        for (let i = this.chart.series.length - 1; i >= 0; i--) {
            if (this.chart.series[i].options.code === code) {
                this.chart.series[i].remove();
            }
        }
    }

};

