WiseMetering.Model.Marker = WiseMetering.Model.extend({
    actions: {
        activate: 'active',
        disable: 'disabled',
        reset: 'pending'
    },

    states: {
        pending: {
            color: WiseMetering.SemaphorColors['blue'],
            title: 'Pending'
        },
        ongoing: {
            color: WiseMetering.SemaphorColors['orange'],
            title: 'Ongoing'
        },
        active: {
            color: WiseMetering.SemaphorColors['green'],
            title: 'Active'
        },
        discarded: {
            color: WiseMetering.SemaphorColors['red'],
            title: 'Discarded'
        }
    },

    urlRoot: "/markers",

    activate: function(collection) {
        this.changeState('activate', collection);
    },


    building: function() {
        return WiseMetering.zones.get(this.get("building_id"));
    },

    discard: function(collection) {
        this.changeState('discard', collection);
    },

    changeState: function(action, collection) {
        return this.save({}, { url: `${this.urlRoot}/${this.id}/${action}`, wait: true, patch: true }).done(() => {
            WiseMetering.layout.showTipper('success', `State changed to ${this.actions[action]}`);
            collection.remove(this)
        }).fail((xhr) => {
            const errors = JSON.parse(xhr.responseText),
                  message =  errors.errors ? errors.errors.state : errors.error;

             WiseMetering.layout.showTipper('error', message);
        });
    },

    icon: function() {
        return 'markers';
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get("indicator_id"));
    },

    calculateImpact: function() {
        return this.fetch({url: `${this.urlRoot}/${this.id}/calculate_impact`});
    },

    getCalculatedImpact: function(params, form_prefix) {
        if (this.id) {
            let clone = this.clone();
            clone.set(params)
            params = {
                name: clone.get('name'),
                duration: clone.get('duration'),
                indicator_id: clone.get('indicator_id'),
                marker_at: clone.get('marker_at'),
                marker_type_id: clone.get('marker_type_id'),
                reference_period_start: clone.get('reference_period_start'),
            }
        }

        return $.ajax({
            url: `${this.urlRoot}/measure_impact`,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(params)
        }).done((response) => {
            let calculatedResults = i18next.t('markers.no_impact_available');
            if (response.calculated_impact) {
                calculatedResults = this.results(response.calculated_impact);
                if (calculatedResults) {
                    calculatedResults = calculatedResults.replace("\n", '<br/>')
                }
            }

            $('#calculated-results').html(calculatedResults);
            $('#calculated-results').show();
            $('.fa-spinner').hide();
        }).fail((xhr) => {
            let errors = {};
            try {
                errors = JSON.parse(xhr.responseText);
            } catch (e) {
            }

            _(errors.errors).each((message, param) => {
                let errorLabel = $(`label[for='${param}']`);

                if (errorLabel.length === 0) {
                    errorLabel = $(`label[for='${form_prefix}_${param}']`);
                }

                if (errorLabel.length === 0) {
                    errorLabel = $(`label[for='${form_prefix}-${param}']`);
                }

                errorLabel.addClass('err');
                errorLabel.append( `<span class="err-help" id="question-${form_prefix}_${param}"><i class="${WiseMetering.icons.question_circle}" aria-hidden='true'></i></span>` )
                errorLabel.find(`#question-${form_prefix}_${param}`).mouseover(() => {
                    $.stt({ target: errorLabel, text: message });
                });
                errorLabel.find(`#question-${form_prefix}_${param}`).mouseout(() => {
                    $('div.stt').remove();
                });

                $('#calculated-results').html(i18next.t('markers.error_fetching_results'));
                $('#calculated-results').show();
                $('.fa-spinner').hide();
            });
        });
    },

    reset: function(collection) {
        this.changeState('reset', collection);
    },

    results: function(options=null) {
        let results = options ? options : this.get('results')
        if (results) {
            return results.split('\n').map(result => {
                if (result.includes('↓')) {
                    return `<span style="color: ${WiseMetering.Colors.green}">${result}</span>`;
                } else if (result.includes('↑')) {
                    return `<span style="color: ${WiseMetering.Colors.red}">${result}</span>`;
                } else {
                    return result;
                }
            }).join('\n');
        }
    }
});
