Lmit.UI.Label = function(_text_, _container_, _options) {
    this.text = _text_;
    this.options= _options || {};
    this.value = _text_;
    this.container = _container_ ;
    this.element = null;
    this.init();
};

Lmit.UI.Label.prototype = {
    init:function(){
        this.build();
    },

    build:function(){
        this.element = $("<li>")
        if(this.options.style) this.element.css(this.options.style)
        this.element.addClass("wmui-label").text(this.text);
        if (this.options.id) this.element.attr({id:this.options.id});
        if (this.options.disable) this.disable();
        this.container.append(this.element);
    },

    disable:function(){
        this.element.hide()
    },

    enable:function(){
        this.element.show()
    }

};
