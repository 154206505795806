(function ($) {
    $.mbx= function (options) {
        if (options.data===null) {
            return $.mbx.def.init(null, options);
        } else {
            return $.mbx.def.init(options.data, options);
        }
    };
    $.mbx.close= function () {
        $.mbx.def.onClose({id:"Close"});
    };
    $.mbx.clear= function () {
        $.mbx.def.clear();
    };

    $.mbx.remove= function () {
        if (typeof $.mbx.close == "function"){ $.mbx.close(); }
        else { $.mbx.def.close(); }
    };

    $.mbx.block= function (msg) {
        $.mbx.def.addBlock(msg);
    };

    $.mbx.resize= function () {
        $.mbx.def.resize();
    };

    $.mbx.unblock= function () {
        $.mbx.def.removeBlock();
    };

    $.mbx.append= function (content) {
        $.mbx.def.append(content);
    };

    $.mbx.html= function (content) {
        $.mbx.def.html(content);
    };

    $.mbx.title= function (value) {
        $.mbx.def.title(value);
    };

    $.fn.mbx= function(options) {
        return $.mbx.def.init(this, options);
    };

    $.mbx.buttons= {
        OKCANCEL:{Ok:true,Cancel:false},
        SAVECANCEL:{Save:true,Cancel:false},
        SAVE:{Save:true},
        OK:{Ok:true},
        CANCEL:{Cancel:true},
        YES:{Yes:true},
        NO:{No:true},
        YESNOCANCEL:{Yes:true,No:true,Cancel:true},
        YESNO:{Yes:true,No:true},
        APPLY:{Apply:true},
        CLOSE:{Close:true}
    };

    $.mbx.defaults = {
        mode:"alert",
        content:null,
        locked:false,
        auto_close: true,
        buttons: null,
        id:null,
        title:null,
        text:null,
        msg:null,
        klass:null,
        style:null,
        icon:null,
        multiline: true,
        draggable: true,
        value:"",
        fn: function(){},
        onOk: null,
        onCancel: function(){$.mbx.close();},
        onYes: null,
        onNo: null,
        onClose: null,
        onApply: null,
        selectedBtn:true,
        progress:false,
        closable:true,
        width:256,
        height:'auto',
        top: null,
        right: null,
        left: null,
        bottom: null
    };

    $.mbx.def= {
        opts: null,
        dialog: {},
        init: function (data, options) {
            this.opts = $.extend({}, $.mbx.defaults, options);
            if (data===null || data===undefined){
                data = this.build();
            } else {
                if (typeof data == 'object') {
                    data = data instanceof jQuery ? data : $(data);
                } else if (typeof data == 'string' || typeof data == 'number') {
                    data = $('<div>').html(data);
                } else {
                    return false;
                }
            }
            this.dialog.data = data;
            data = null;
            this.create();
            this.open();
            if ($.isFunction(this.opts.onShow)) {
                this.opts.onShow.apply(this, [this.dialog]);
            }
            return this;
        },

        build:function(){
            var std=$('<div>');
            if (this.opts.type == 'prompt' ) {
                std.append($('<label>').html(this.opts.msg));
                std.append('<br>');
                if (this.opts.multiline){
                    std.append($('<textarea>').addClass('mbx_text').attr('name', 'mbx_text_input').attr('rows', '5'));
                    $('textarea.mbx_text').val("");
                } else {
                    std.append($('<input>').addClass('mbx_text').attr('name', 'mbx_text_input').attr('maxlength', '250'));
                }
            }
            if (this.opts.type == 'show' ) {
                if (this.opts.icon){
                    std.append($('<div>').addClass('mbx_icon mbx_' +this.opts.icon));
                }
                std.append($('<div>').addClass('mbx_msg').append(this.opts.msg));
            }
            return std;
        },

        create: function(){
            var icon_close = (this.opts.closable ? $('<div>').addClass('mbx_toolbox').append($('<a id="Close">').addClass('mbx_btn_close').attr({'href':'#'})) : "");
            this.dialog.mask=$('<div>').addClass('mbx_mask').css({height:$(document).height()}).hide().appendTo('body');
            this.dialog.box= $('<div>').attr('id', this.opts.id || "lmit_mbx").addClass('mbx').css({
                position: 'fixed',
                width:this.opts.width,
                height:'auto',
                zIndex: 9000
            });
            if (this.opts.klass) { this.dialog.box.addClass(this.opts.klass);}
            this.dialog.box.append($('<div>').addClass('mbx_head').append(icon_close).append(this.opts.title))
                .append($('<div>').addClass('mbx_body'))
                .hide().appendTo('body');
            var dataPh=$('<div>').addClass('mbx_cont').css({height:this.opts.height}).append(this.dialog.data.hide());
            $('div.mbx_body').append(dataPh);
            $('div.mbx_body').append($('<div>').addClass('mbx_btns').html(this.buttons()));
            if(this.opts.draggable === true) this.dialog.box.draggable({ handle: 'div.mbx_head' });

            // help section
            // %div.help{ :style => 'width:290px;padding-right:0;display:inline-block !important;height:300px;margin-top:12px;vertical-align:top;background-color:#EEF4FD;border: 1px solid #ccc;padding:5px;border-radius:3px;' }
            if (this.dialog.data.find('div.mbx_help').length == 1){
                // set mbx body and content css properties for properly receiving the help placeholder
                var mbxContent = $('div.mbx_cont');
                var mbx = $('#lmit_mbx');
                mbxContent.css({ 'position': 'relative', 'overflow': 'hidden' });
                // clone help placeholder and destroy the remaining element
                var help = this.dialog.data.find('div.mbx_help').clone();
                this.dialog.data.find('div.mbx_help').remove();
                // set help css properties according to mbx size
                var mbxWidth = this.dialog.box.width();
                var helpWidth = 300;
                help.attr({ 'id': 'mbx_help' }).removeClass('mbx_help').css({ 'width': helpWidth+'px', 'top': '20px', 'bottom': '20px', 'left': this.dialog.box.width()+'px', 'padding': '5px 20px 5px 10px', 'background-color': '#EEF4FD', 'border-radius': '3px', 'border': '1px solid #ccc', 'position': 'absolute', 'vertical-align': 'top' });
                mbxContent.append(help);

                // create help tooltips
                var helpTooltips = $('dt.help');
                _.each(helpTooltips, function(e){
                    var tipId = $(e).attr('id');
                    if (tipId !== 'undefined' && tipId != ''){
                        // %a.wmui-icon{ :href => '#', 'data-section' => 'what_happened' }
                        var tipPlaceHolder = $(e);
                        var tip = $('<a>').addClass('wmui-icon').attr({ 'href': '#', 'data-help_section': tipId }).css({ 'margin-left': '5px' });
                        tipPlaceHolder.children('label').after(tip);
                        new Lmit.UI.Icon(tip, { icon: 'help', strokeColor: '#999999', w: 19, scale: 0.6 });
                        // bind events for tooltip
                        tip.bind('click', function(){
                            var section = help.find('.sections > .'+tipId);
                            var sectionIsToggled = section.attr('data-toggled');
                            if (typeof sectionIsToggled !== 'undefined' && sectionIsToggled !== false && section.is(':visible')){
                                section.removeAttr('data-toggled');//.fadeOut(200);
                                $(this).removeAttr('data-toggled');
                                if (_.size(help.find(".sections .section[data-toggled='']")) == 0){
                                    help.removeAttr('data-toggled');
                                }
                            } else {
                                help.attr('data-toggled', '');
                                section.attr('data-toggled','').fadeIn(100);
                                $(this).attr('data-toggled', '');
                            }
                        });
                        var tIn;
                        var tOut;
                        var tOut2;
                        var tipIn = function(){
                            var section = help.find('.sections > .'+tipId);
                            var helpIsToggled = help.attr('data-toggled');
                            section.fadeIn(200);
                            if (typeof helpIsToggled === 'undefined' || helpIsToggled === false || helpIsToggled == ''){
                                mbx.stop().animate({ 'width': mbxWidth+helpWidth+10+'px' }, 400 - (mbx.width() - mbxWidth));
                            }
                        };
                        tip.mouseover(function(){
                            clearTimeout(tOut);
                            clearTimeout(tOut2);
                            var section = help.find('.sections > .'+tipId);
                            var sectionIsToggled = section.attr('data-toggled');
                            if (typeof sectionIsToggled === 'undefined' || sectionIsToggled === false){
                                help.attr('intent','');
                                tIn = setTimeout(tipIn, 400);
                            }
                        });
                        var tipOut = function(){
                            var section = help.find('.sections > .'+tipId);
                            var sectionIsToggled = section.attr('data-toggled');
                            if (typeof sectionIsToggled === 'undefined' || sectionIsToggled === false){
                                section.fadeOut(200);
                            }
                        };
                        var helpOut = function(){
                            if (mbx.width() > mbxWidth && help.attr('intent') === undefined){
                                var section = help.find('.sections > .'+tipId);
                                var sectionIsToggled = section.attr('data-toggled');
                                var helpIsToggled = help.attr('data-toggled');
                                if (typeof sectionIsToggled === 'undefined' || sectionIsToggled === false){
                                    if (typeof helpIsToggled === 'undefined' || helpIsToggled === false){
                                        mbx.stop().animate({ 'width': mbxWidth+'px' }, 400 + (mbx.width() - mbxWidth));
                                    }
                                }
                            }
                        };
                        tip.mouseout(function(){
                            var section = help.find('.sections > .'+tipId);
                            var sectionIsToggled = section.attr('data-toggled');
                            if (typeof sectionIsToggled === 'undefined' || sectionIsToggled === false){
                                clearTimeout(tIn);
                            }
                            help.removeAttr('intent');
                            tOut2 = setTimeout(tipOut, 200);
                            tOut = setTimeout(helpOut, 1000);
                        });
                    }
                });

            }

        },

        buttons:function(){
            if (this.opts.buttons!==null){
                var btns_row=$('<div>').addClass('mbx_btns_pod');
                for (var i in this.opts.buttons) {
                    if (i) btns_row.append($('<input>').attr({type:'button', 'id':i, 'text':i, 'value':i}).addClass('mbx_btn toolbar_button item_float btn_mbx_' + (i.toLowerCase())));
                }
                return btns_row;
            }
        },

        forceCenterBtns:function(){
            $('div.mbx_btns_pod').css({marginRight: ((($('div.mbx_btns').width()-$('div.mbx_btns_pod').width()))/2) +  'px'});
        },

        close:function(){
            if (!this.dialog.data) return false;
            this.dialog.mask.remove();
            this.dialog.box.remove();
            this.dialog = {};
            return true;
        },

        clear:function(){
            $('div.mbx_body').html('');

        },

        html:function(content){
            $('div.mbx_body').html(content);
        },

        append: function(content){
            $('div.mbx_body').append(content);
        },

        bindEvents: function () {
            if (this.opts.closable){
                var mbx=this;
                $('input.mbx_btn_close,a.mbx_btn_close').click(function() {
                    mbx.onClose(this);
                });
                $('input.mbx_btn').click(function() {
                    mbx.onClick(this);
                    return false;
                });
            }
            $('.mbx_text').val(this.opts.value);
            $('.mbx_head').bind( 'drag', function( event ){
                $( this ).parent().css({
                    top:(event.offsetY-document.body.scrollTop),
                    left:(event.offsetX-document.body.scrollLeft)
                });
            });
        },

        addBlock:function(msg){
            msg=msg || i18next.t('ui.please_wait')	;
            $('div.mbx_blk').remove();
            $('div.mbx_body').append($('<div>').addClass('mbx_blk').css({"z-index":1001,position:'absolute',top:'0',left:'0',height:'100%',width:'100%'}));
            $('div.mbx_blk').append($('<div>').addClass('blk_bck').css({opacity:'0.7',  background:'white',height:'100%',width:'100%'}));
            $('div.mbx_blk').append($('<div>').addClass('blk_frt'));
            $('div.blk_frt').css({ position:'absolute', top:'0', width:'100%',  margin:'auto'	});
            $('div.blk_frt').append($('<h3>').addClass('purple').css({'text-align':'center', paddingTop:((($('div.mbx_body').height())/2) - (25))}).append('<img src="/images/ui/anm.gif" style="margin:auto;"/><br/><br/><span style="clear:left">' + msg + '</span>'));
        },
        removeBlock:function(){
            $('div.mbx_blk').remove();
        },

        open:function(){
            this.dialog.box.show();
            this.dialog.data.show();
            this.bindEvents();
            this.setPosition();
        },

        resize:function(){
            if (this.opts && this.opts.width && this.dialog.box){ this.dialog.box.css({ width: this.opts.width + "px" }); }
            this.setPosition();
        },

        setPosition:function(){
            if (this.opts && this.dialog.box){
                if(this.opts && this.opts.top){
                    this.dialog.box.css({top:this.opts.top});
                } else {
                    this.dialog.box.css({top:(($(window).height()/2)-($('div#lmit_mbx').height()/2)) + "px"});
                }
                if(this.opts.left ){
                    this.dialog.box.css({left:this.opts.left});
                } else if (!this.opts.right) {
                    this.dialog.box.css({left:(($(document).width()/2)-(this.opts.width/2)) + "px"});
                }
                if(this.opts.right){this.dialog.box.css({right:this.opts.right});}
                if(this.opts.bottom){this.dialog.box.css({bottom:this.opts.bottom});}
            }
        },

        onClick:function(e){
            var value = this.opts.multiline ? $('textarea.mbx_text').val() : $('input.mbx_text_input').val();
            this.dispatch(e, value);
            if (this.opts.auto_close){
                this.close();
            }
        },

        onClose:function(e){
            this.close();
            if(this.opts && this.opts.onClose){this.dispatch(e, 'null');}
        },

        onEsc:function(){
            this.close();
            if (this.opts && this.opts.onClose){this.opts.onClose();}
        },

        lock:function(){
            this.opts.locked=true;
        },

        unlock:function(){
            this.opts.locked=false;
        },

        title:function(value){
            (value!='default')? $('div.mbx_head').text(value) : $('div.mbx_head').text(this.opts.title);
        },

        title:function(title){
            $('div.mbx_head').html(title);
        },

        reset_title:function(){
            $('div.mbx_head').html(this.opts.title);
        },

        activityOn:function(){
        },

        activityOff:function(){
            this.unlock();
            this.reset_title();
        },

        dispatch:function(e, value){

            if (!this.opts.locked){
                if (e.id=='Ok' && $.isFunction(this.opts.onOk)){
                    this.opts.onOk(value);
                }
                if (e.id=='Save' && $.isFunction(this.opts.onSave)){
                    this.opts.onSave(value);
                }
                if (e.id=='Cancel' && $.isFunction(this.opts.onCancel)){
                    this.opts.onCancel(value);
                }
                if (e.id=='Yes' && $.isFunction(this.opts.onYes)){
                    this.opts.onYes(value);
                }
                if (e.id=='No' && $.isFunction(this.opts.onNo)){
                    this.opts.onNo(value);
                }
                if (e.id=='Close'){
                    if(this.opts.onNo){ this.opts.onClose(value); }
                    else if(this.opts.onClose){ this.opts.onClose(value); }
                }
                if (e.id=='Apply' && $.isFunction(this.opts.onNo)){
                    this.opts.onApply(value);
                }
                if ($.isFunction(this.opts.fn)){
                    this.opts.fn(e.id,value);
                }
            }
        }
    };
})(jQuery);
