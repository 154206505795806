WiseMetering.Views.IntegrationAssetSetting =  Backbone.Marionette.ItemView.extend({
    template: 'templates/integration_assets/setting',

    serializeData: function () {
        return Object.assign(
            this.model.toJSON(), {
                name: WiseMetering.utils.humanizeText(this.model.get('key')),
                readOnly: this.options.readOnly,
            }
        )
    }

});
