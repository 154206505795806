WiseMetering.Views.ShowEvent = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    template: 'templates/events/show',
    width: 500,
    title: () => i18next.t('modal.title.events.show'),

    buttons: function() {
        const buttons = ['close'];
        if (this.model.indicator()) {
            buttons.push('open');
        }
        return buttons;
    },

    open: function() {
        this.close();
        Backbone.history.navigate(this.model.indicator().nodeUrl(), { trigger: true });
    },

    serializeData: function() {
        const creator = this.model.creator();
        const indicator = this.model.indicator();
        return Object.assign(
            this.model.toJSON(), {
                building: this.model.building().get('name'),
                creator: creator.get('name'),
                description: this.model.get('description') ? this.model.get('description').split('|').join('<br>') : 'No description',
                indicator: indicator ? indicator.get('name') : indicator,
                trigger: this.model.alarm().get('name')
            }
        );
    }
});
