module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<br>\n<span style="font-weight:bold; color:' +
((__t = ( color )) == null ? '' : __t) +
'">Consumption ' +
((__t = ( year )) == null ? '' : __t) +
':</span> ' +
((__t = ( currentValue )) == null ? '' : __t) +
'\n';
 if (deviation) { ;
__p += '\n<span style="font-weight:bold; color:' +
((__t = ( positive ? WiseMetering.SemaphorColors.red : WiseMetering.SemaphorColors.green )) == null ? '' : __t) +
'">\n    (' +
((__t = ( positive ? '+' : '' )) == null ? '' : __t) +
'' +
((__t = ( deviation )) == null ? '' : __t) +
')\n</span>\n';
 } ;
__p += '\n';

}
return __p
}