WiseMetering.Collection.ZoneReportTypes = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.ZoneReportType,
    url: '/report_types/zone_report_types',

    filterTypes: function(buildingId) {
        const buildingHasReports = WiseMetering.reports.where({type: 'ZoneInvoiceReport', building_id: buildingId}),
              hasAccess = this.isException(buildingId) ? false : buildingHasReports.length > 0,
              types = hasAccess ? this : this.standardGlobalReportSuite();

        types.each(report => {
            let active = true;
            if (['ZoneDetailedReport', 'ZoneFolderReport', 'ZoneMeteringSchemaReport', 'ZoneSchemaReport'].includes(report.get('type'))) {
                active = WiseMetering.currentOrganization.hasStandardPermissions();
            }
            report.set({ active });
        });
        return types.toJSON();
    },

    //to be deleted once all clients stop using invoice reporting
    isException: function(buildingId) {
        const
            isIosa = ['iosa'].includes(WiseMetering.currentOrganization.get('subdomain')),
            isRamazzotti = buildingId === 'a6b00283-1412-4c50-8261-e78f30f69874';

        return isIosa || isRamazzotti;
    },

    standardGlobalReportSuite: function() {
        let reports = this.filter((report) => report.get('type') !== 'ZoneInvoiceReport');
        return new WiseMetering.Collection.ZoneReportTypes(reports);
    }
});
