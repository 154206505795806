WiseMetering.Views.UtilityDetailsTab = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    template: 'templates/utilities/details_tab',

    regions: {
        details: '#details-accordion',
        contracts: '#contracts-accordion'
    },

    onRender: function() {
        this.details.show(new WiseMetering.Views.UtilityDetails({ model: this.model }));
        this.contracts.show(new WiseMetering.Views.ContractsAccordion({ model: this.model }));
    }
});
