WiseMetering.Model.Building = WiseMetering.Model.extend({
    buildingUrlRoot: '/buildings',

    activate: function() {
        return this.save({}, {
            url: this.url() + '/activate',
            patch: true,
            success: function() {
                WiseMetering.layout.showTipper('success', 'Building successfully activated');
            },
            error: function(model, e) {
                WiseMetering.layout.showTipper('error', Lmit.Utils.getMessageFromException(e));
            }.bind(this)
        });
    },

    availableUtilityKinds: function() {
        const availableUtilityKindIds = this.utilities().reduce((set, utility) => set.add(utility.get('utility_kind_id')), new Set);
        return WiseMetering.utilityKinds.filter(utilityKind => availableUtilityKindIds.has(utilityKind.id));
    },

    disable: function() {
        return this.save(null, {
            url: this.url() + '/disable',
            patch: true,
            success() {
                WiseMetering.layout.showTipper('success', 'Building successfully disabled');
            },
            error() {
                WiseMetering.layout.showTipper('error', 'Failed to disable this building');
            }
        });
    },

    getSitePackage: function() {
        return WiseMetering.sitePackages.findWhere({building_id: this.id});
    },

    hasActuators: function() {
        return WiseMetering.actuators.where({ building_id: this.id }).length > 0;
    },

    isActive: function() {
        return this.isBuilding() ? this.get('state') === 'active' : true;
    },

    isActivated: function() {
        return this.isBuilding() ? !!this.get('activated_at') : true;
    },

    isBuilding: function() {
        return !this.get('parent_id');
    },

    isDisabled: function() {
        return this.isBuilding() ? this.get('activated_at') && !this.get('active') : true;
    },

    rootObjects: function() {
        let rootObjects = new Backbone.Collection;

        const availableRootObjects = ['zones', 'folders', 'circuits', 'utilities'];
        if (WiseMetering.currentOrganization.hasAdvancedPermissions()) {
            availableRootObjects.push('tenants');
            availableRootObjects.push('seus');
        }

        availableRootObjects.forEach(function(slug, index) {
            let model = new WiseMetering.Model.RootObject({
                id: `${this.id}-${slug}`,
                building_id: this.id,
                name: i18next.t(`glossary.${slug}`).capitalize(),
                slug: slug,
                order: index
            });
            rootObjects.add(model);
        }.bind(this));

        return rootObjects;
    },
});
