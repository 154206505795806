WiseMetering.Views.MaintenanceActivities = WiseMetering.Views.TableIndex.extend({
    createTable: function() {
        this.collection = this.model.maintenanceActivities();

        activateEvent = (id) => {
            const marker = WiseMetering.markers.findWhere({id: id});
            marker.activate(this.collection);
        };

        discardEvent = (id) => {
            const marker = WiseMetering.markers.findWhere({id: id});
            marker.discard(this.collection);
        };

        resetEvent = (id) => {
            const marker = WiseMetering.markers.findWhere({id: id});
            marker.reset(this.collection);
        };

        this.table = new WiseMetering.Views.Table({
            id: 'markers',
            collection: this.collection,
            modelSerializer: function(model) {
                const
                    json = model.toJSON(),
                    timezone = model.building().get('timezone');

                json.markerAt = WiseMetering.utils.formatDate(model.get('marker_at'), timezone);
                json.status = model.states[model.get('state')];
                return json;
            },
            columns: {
                status: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({ color, title }),
                    sWidth: '20px'
                },
                name:  i18next.t('common.name'),
                markerAt:i18next.t('common.date'),
                id: {
                    title: i18next.t('ui.table.actions'),
                    mRender: (id) => {
                        const marker = WiseMetering.markers.get(id);
                        if (marker.get('state') === 'pending') {
                            return  `<input class="table-activate-button" onclick="event.stopPropagation(); activateEvent('${id}')" type="button" value="Activate"/><input class="table-discard-button" onclick="event.stopPropagation(); discardEvent('${id}')" type="button" value="Discard"/>`;
                        } else if (marker.get('state') === 'discarded') {
                            return `<input class="table-reset-button" onclick="event.stopPropagation(); resetEvent('${id}')" type="button" value="Reset"/>`;
                        } else {
                            return '';
                        }
                    },
                    className: 'align-left',
                    sWidth: '120px'
                },
            },
            types: {
                from: 'date',
                end_date: 'date'
            },
            toolbar_actions: {
                refresh: {
                    disabled: false,
                    fetchCollection: WiseMetering.markers
                },
                show: {
                    view_call: model => new WiseMetering.Views.ShowMarker({ model })
                },
                edit: {
                    view_call: model => new WiseMetering.Views.EditMarker({ model })
                },
                delete: {}
            }
        });
        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.markers);
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.markers, 'remove add change', this.createTable.bind(this));
        this.createTable();
    }
});
