WiseMetering.Views.IndicatorDetailsTab = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/details_tab',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click #edit_attributes': 'editAttributes'
    },

    ui: {
        details: '#indicator-details-content'
    },

    onRender: function() {
        this.ui.details.html(new WiseMetering.Views.IndicatorDetails({ model: this.model }).render().el);
    },

    editAttributes: function(event) {
        event.stopPropagation();
        new WiseMetering.Views.EditIndicator({ title: 'Edit ' + this.model.get('name'), model: this.model });
    }
});
