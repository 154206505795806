WiseMetering.Views.AllocationsAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/allocations/accordion',

    events: {
        'click .allocation': 'onAllocationClicked',
        'click .indicator': 'onIndicatorCLicked',
        'click .tariff': 'onTariffCLicked'
    },

    serializeData: function() {
        const json = this.model.toJSON();
        json.length = this.collection.length;
        return json;
    },

    onRender: function() {
        this.table = new WiseMetering.Views.Table({
            id: 'active-allocations',
            collection: this.collection,
            modelSerializer: function(model) {
                const json = model.toJSON(),
                    timezone = model.timezone();

                json.from = WiseMetering.utils.formatDate(json.from, timezone, 'YYYY/MM/DD');
                json.to = WiseMetering.utils.formatDate(json.to, timezone, 'YYYY/MM/DD');
                return json;
            },
            columns: {
                id: {
                    title: i18next.t('common.name'),
                    mRender: function(id) {
                        const allocation = WiseMetering.allocations.get(id);
                        return `<a class='link allocation' id='${id}' href='#'>${allocation.get('name')}</a>`;
                    }
                },
                indicator_id: {
                    title: i18next.t('glossary.indicator'),
                    mRender: function(indicator_id) {
                        const indicator = WiseMetering.indicators.get(indicator_id);
                        return `<a class='link indicator' id='${indicator_id}' href='#'>${indicator.get('name')}</a>`;
                    }
                },
                tariff_id: {
                    title: i18next.t('glossary.tariff'),
                    mRender: function(tariff_id) {
                        const tariff = WiseMetering.tariffs.get(tariff_id);
                        return `<a class='link tariff' href='#'>${tariff.get('name')}</a>`;
                    }
                },
                fraction: i18next.t('glossary.fraction'),
                from: i18next.t('common.from')
            },
            toolbar_actions: {
                new: {
                    view_call: () => {
                        new WiseMetering.Views.AddAllocation({
                            collection: WiseMetering.allocations,
                            allocations: this.collection,
                            indicators: new WiseMetering.Collection.Indicators,
                            model: new WiseMetering.Model.Allocation({
                                active: true,
                                building_id: this.model.get('building_id'),
                                fraction: 1,
                                organization_id: this.model.get('organization_id'),
                                tenant_id: this.model.id
                            }),
                            tariffs: new WiseMetering.Collection.Tariffs,
                            tenant: this.model
                        });
                    }
                },
                edit: {
                    view_call: model => {
                        model.fetch({
                            success: model => new WiseMetering.Views.EditAllocation({
                                active: true,
                                collection: this.collection,
                                indicators: new WiseMetering.Collection.Indicators,
                                model: model,
                                tariffs: new WiseMetering.Collection.Tariffs,
                                tenant: this.model
                            })
                        });
                    }
                },
                delete: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.DestroyAllocation({ model })
                }
            }
        });

        this.$(`#active-allocations`).html(this.table.el);

        this.listenTo(this.model, 'change:children:addAllocation', function(node) {
            this.collection.add(node);
        });
        this.listenTo(this.model, 'change:children:removeAllocation', function(node) {
            this.collection.remove(node);
        });
    },

    onAllocationClicked: function(event) {
        event.preventDefault();

        let allocation_id = $(event.currentTarget).attr('id'),
            allocation = WiseMetering.allocations.get(allocation_id);

        allocation.fetch({
            success: function() {
                new WiseMetering.Views.ShowAllocation({ model: allocation });
            }
        });
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onIndicatorCLicked: function(event) {
        event.preventDefault();

        let indicator_id = $(event.currentTarget).attr('id');
        Backbone.history.navigate(`/indicators/${indicator_id}/plot${location.search}`, { trigger: true });
    },

    onTariffCLicked: function(event) {
        event.preventDefault();

        Backbone.history.navigate('/tariffs', { trigger: true });
    }
});
