WiseMetering.Views.CircuitDetailsTab = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    template: 'templates/circuits/details_tab',

    regions: {
        details: '#details-accordion',
        indicators: '#indicators-accordion',
        system: '#system-info-accordion'
    },

    onRender: function() {
        this.details.show(new WiseMetering.Views.CircuitDetailsAccordion({ model: this.model }));
        this.indicators.show(new WiseMetering.Views.IndicatorAccordion({ model: this.model }));
        this.system.show(new WiseMetering.Views.SystemInfoAccordion({ model: this.model }));
    }
});
