WiseMetering.Views.ShowCompany = WiseMetering.Views.Modal.Show.extend({
    template: 'templates/companies/show',
    className: 'mbx-info text-left',
    width: 500,
    title: () => i18next.t('modal.title.companies.show'),
    buttons: ['edit', 'close'],

    serializeData: function() {
        let json = this.model.toJSON();
        json.from = this.model.getDate('from');
        json.to = this.model.getDate('to');
        json.createdAt = this.model.getDateTime('created_at');
        json.updatedAt = this.model.getDateTime('updated_at');
        return json;
    },

    edit: function() {
        this.block();
        _.defer(function() {
            new WiseMetering.Views.EditCompany({ model: this.model });
        }.bind(this));
    }
});
