WiseMetering.Views.AlarmTabs = WiseMetering.Views.Content.extend({
    icon: 'alarms',

    name: function() {
        return i18next.t('glossary.alarms');
    },

    tabs: function() {
        return [
            {
                id: 'alarms',
                name: i18next.t('glossary.alarms'),
                view: WiseMetering.Views.IndexAlarm,
                active: true
            },
            {
                id: 'templates',
                name: i18next.t('glossary.templates'),
                view: WiseMetering.Views.IndexAlarmSchedule
            }
        ];
    }
});
