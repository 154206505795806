module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'mbx-info green\'>\n    ';
 if(groups.length === 0){ ;
__p += '\n        ' +
((__t = ( i18next.t('zones.no_groups_have_access_to_this_building') )) == null ? '' : __t) +
'\n    ';
 } else { ;
__p += '\n        ' +
((__t = ( i18next.t('zones.groups_with_access_to_this_building', { count: groups.length }) )) == null ? '' : __t) +
': <b>' +
((__t = ( groups.join(', ') )) == null ? '' : __t) +
'</b>\n    ';
 } ;
__p += '\n</div>\n<div id=\'permissions-table\'></div>\n';

}
return __p
}