module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dt>\n    <label for=\'etl_extra\'>' +
((__t = ( i18next.t('glossary.methodology') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'etl_extra\' name=\'etl[extra][emission_factor_type]\'>\n        ';
 ["lifecycle", "direct"].forEach(function(type) { ;
__p += '\n                <option value="' +
((__t = ( type )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( i18next.t(`integration_partners.partners.electricity_maps.${type}`) )) == null ? '' : __t) +
'\n                    ';
 if(type === "direct"){ ;
__p += '\n                        (Not recommended)\n                    ';
 } ;
__p += '\n                </option>\n        ';
 }); ;
__p += '\n    </select>\n    <div id="etl_extra_container" class="modal-description-container">\n        <span class="modal-description-title">\n            <i class="' +
((__t = ( WiseMetering.icons.info )) == null ? '' : __t) +
'" style="margin-right: 10px; color: #2a81c0; font-size: 2em;" aria-hidden=\'true\'></i>\n            <p id="title_text"></p>\n        </span>\n        <p id="etl_extra_description"></p>\n    </div>\n</dd>\n<dt>\n    <label for=\'etl_indicators\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'etl_indicators\' name=\'etl[dsn]\'>\n        ';
 Object.keys(indicators).sort().forEach(function(buildingName) { ;
__p += '\n            <optgroup label=\'' +
((__t = ( buildingName )) == null ? '' : __t) +
'\'>\n                ';
 indicators[buildingName].forEach(function(indicator) { ;
__p += '\n                    <option value=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\'>' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </optgroup>\n        ';
 }); ;
__p += '\n    </select>\n</dd>\n\n<input class=\'text\' hidden id=\'etl_username\' name=\'etl[username]\' value="' +
((__t = ( username )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id=\'etl_secret\' name=\'etl[secret]\' value="' +
((__t = ( secret )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id="etl_start_at" maxlength="10" type="text" name=\'etl[start_at]\' value="' +
((__t = ( start_at )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id="etl_rate" type="number" name=\'etl[rate]\' value="' +
((__t = ( rate )) == null ? '' : __t) +
'">\n<input class=\'text\' hidden id="etl_mode" type="text" name=\'etl[mode]\' value="' +
((__t = ( mode )) == null ? '' : __t) +
'">\n\n';

}
return __p
}