WiseMetering.Views.CreateOrganization = WiseMetering.Views.Crud.Save.extend({
    template: 'templates/ui/crud/create',
    className: 'crud-info',
    object: 'organization',
    title: () => i18next.t('modal.title.organizations.new'),

    events: {
        'change select#country_code': 'changeCountry',
        'change select#type': 'changeType',
        'change .input-file': 'changeLogo',
        'click .btn-tiers': 'changeTier',
        'keyup #name': 'changeName'
    },

    ui: {
        logoPreviewImage: '.preview-image',
        PreviewImageSection: '.preview-image-wrapper',
        subdomain: '#subdomain',
        tiersSection: '#tier',
        tiers: '.btn-tiers',
        timezones: '#timezone'
    },

    afterSave: function() {
        WiseMetering.currentUser.save({ organization_id: this.model.id }, {
            patch: true,
            success: () => {
                WiseMetering.currentUser.get('organizations').push({
                    disabled: false,
                    id: this.model.id,
                    name: this.model.get('name'),
                    subdomain: this.model.get('subdomain')
                });

                WiseMetering.Views.Layouts.Root.prototype.navigateToOrganization(this.model.attributes);
            },
            error: () => {
                WiseMetering.layout.showTipper('error', i18next.t('organizations.unable_to_change'), 5000);
                this.close();
            }
        });
    },

    changeCountry: function(event) {
        const timezones = WiseMetering.timezones[event.target.value];
        this.ui.timezones.html(timezones.map(tz => `<option value='${tz}'>${tz}</option>`));
    },

    changeLogo: function(event) {
        const [file] = event.target.files;
        if (file) {
            this.ui.PreviewImageSection.show();
            this.ui.logoPreviewImage.attr('src', URL.createObjectURL(file));
            this.logoFile = file;
        }
    },

    changeName: function(event) {
        const name = event.currentTarget.value,
            subdomain = WiseMetering.utils.toSnakeCase(name);

        this.ui.subdomain.val(subdomain);
    },

    changeTier: function(event) {
        event.preventDefault();

        this.deselectAllTiers();
        const tierId = event.currentTarget.value;
        this.selectedTier = WiseMetering.tiers.get(tierId);
        this.$(`.btn-${this.selectedTier.get('name')}`).addClass('selected');
    },

    changeType: function(event) {
        event.preventDefault();

        this.ui.tiersSection.empty();
        this.selectedTier = '';
        WiseMetering.utils.addLoader('#tier', top = 0, bottom = 0, width = '60');
        setTimeout(() => {
            this.ui.tiersSection.empty();
            this.ui.tiersSection.append(JST['templates/tiers/tiers']({ object: 'organization', tiers: this.getTiers() }));
        }, 500);
    },

    deselectAllTiers: function() {
        WiseMetering.tiers.forEach((tier) => {
            this.$(`.btn-${tier.get('name')}`).removeClass('selected');
        });
    },

    fields: function() {
        return {
            object: 'organization',
            list: [
                { name: 'name', label: i18next.t('common.name'), value: this.model.get('name'), type: 'text' },
                { name: 'subdomain', label: i18next.t('glossary.subdomain'), value: this.model.get('subdomain'), type: 'text' },
                { name: 'country_code', label: i18next.t('common.country'), options: WiseMetering.countries.toJSON(), slug: 'country_code', type: 'select' },
                { name: 'timezone', label: i18next.t('glossary.timezone'), options: [], type: 'select' },
                { name: 'currency', label: i18next.t('common.currency'), options: WiseMetering.currencies, type: 'select' },
                { name: 'type', label: i18next.t('common.type'), options: WiseMetering.organizationTypes, slug: 'slug', type: 'select' },
                { name: 'tier', field: 'tier_id', label: i18next.t('glossary.tiers'), options: WiseMetering.tiers.toJSON(), type: 'custom' },
                { name: 'logo', label: i18next.t('organizations.logo_label'), type: 'file', preview: true }
            ]
        };
    },

    getFormData: function() {
        const data = this.formSerializer();

        if (this.logoFile) {
            data.logo = this.logoFile;
        }

        data.tier_id = this.selectedTier ? this.selectedTier.id : '';

        return data;
    },

    getTiers: function() {
        const type = this.getFormData().type;
        if (!type) {
            return;
        }

        if (type === 'building') {
            return WiseMetering.tiers.toJSON();
        } else {
            return WiseMetering.tiers.toJSON().filter(tier => tier.name !== 'essentials');
        }
    },

    onShow: function() {
        this.logoFile = null;
        this.ui.PreviewImageSection.hide();
        this.ui.tiersSection.append(JST['templates/tiers/tiers']({ object: 'organization', tiers: this.getTiers() }));
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.fields = this.fields();
        return json;
    },

    submitData: function(data) {
        let formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }

        // https://stopbyte.com/t/how-to-send-multipart-formdata-with-jquery-and-ajax/58/2
        return $.ajax({
            contentType: false,
            data: formData,
            processData: false,
            type: 'POST',
            url: '/organizations',
            success: function(data) {
                this.model.set(data);
            }.bind(this)
        });
    }
});
