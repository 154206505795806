WiseMetering.Views.ManageGroup = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/groups/manage',
    className: 'mbx-info text-left',
    form_prefix: 'group',
    input_readers: [{ type: 'checkbox', func: function(el) { if (el.prop('checked')) return el.val(); } }],

    events: {
        'click #all_api_keys': 'toggleApiKeys',
        'click #all_buildings': 'toggleBuildings',
        'click #all_users': 'toggleUsers'
    },

    title: function() {
        return i18next.t(`modal.title.groups.${this.model.isNew() ? 'new' : 'edit'}`);
    },

    ui: {
        allApiKeys: '#all_api_keys',
        apiKeys: '#api_keys',
        allBuildings: '#all_buildings',
        allUsers: '#all_users',
        buildings: '#buildings',
        users: '#users'
    },

    serializeData: function() {
        const json = {};
        json.isNew = this.model.isNew();
        json.name = this.model.get('name');
        json.description = this.model.get('description');
        json.allUsers = WiseMetering.users.assignable();
        json.allApiKeys = WiseMetering.users.apiKeysAssignable();
        json.myUsers = this.model.users();
        json.allBuildings = WiseMetering.zones.buildings();
        json.myBuildings = this.model.buildings();
        return json;
    },

    toggleApiKeys: function() {
        const selected = this.ui.allApiKeys.prop('checked');

        _.each(this.ui.apiKeys.children('input'), function(input) {
            $(input).prop('checked', selected);
        });
    },

    toggleBuildings: function() {
        const selected = this.ui.allBuildings.prop('checked');
        _.each(this.ui.buildings.children('input'), function(input) {
            $(input).prop('checked', selected);
        });
    },

    toggleUsers: function() {
        const selected = this.ui.allUsers.prop('checked');

        _.each(this.ui.users.children('input'), function(input) {
            $(input).prop('checked', selected);
        });
    }
});
