WiseMetering.Views.CardsIndex = WiseMetering.Views.Content.extend({
    onShow: function () {
        this.cards = this.createCards();
        $(this.body.el).html(this.cards.el);
    },

    onClose: function () {
        if (this.cards && this.cards.remove && typeof (this.cards.remove) === "function") {
            this.cards.remove();
        }
    }
});
