WiseMetering.Model.BuildingTag = WiseMetering.Model.extend({
    urlRoot: '/building_tags',

    buildingNames: function() {
        return WiseMetering.zones.buildings().reduce((list, building) => {
            if (building.get('tag_ids').includes(this.id)) {
                list.push(building.get('name'));
            }

            return list;
        }, []);
    }
});
