module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'form_content\'>\n    <form>\n        <input name=\'zone_attribute_value[zone_id]\' type=\'hidden\' value=\'' +
((__t = ( zone_id )) == null ? '' : __t) +
'\'>\n        <select id=\'zone_attribute_value_zone_attribute_id\' name=\'zone_attribute_value[zone_attribute_id]\'>\n            <option>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n        </select>\n        <div id=\'zone_attribute_type\'></div>\n        <div class=\'wmui-btn\' id=\'remove_value\' style=\'margin-top: 5px;\'>' +
((__t = ( i18next.t('ui.buttons.remove') )) == null ? '' : __t) +
'</div>\n    </form>\n</div>\n';

}
return __p
}