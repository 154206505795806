module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'input-id\'>\n                ' +
((__t = ( i18next.t('common.input') )) == null ? '' : __t) +
'\n            </label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'input-id\' name=\'circuit[input_id]\'>\n                <option value=\'\'>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 availableInputs.forEach(function(input) { ;
__p += '\n                    <option ' +
((__t = ( input.id === currentInput.id ? "selected" : null )) == null ? '' : __t) +
' value=\'' +
((__t = ( input.id )) == null ? '' : __t) +
'\'>' +
((__t = ( input.name )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <input ' +
((__t = ( currentInput.invert === false ? null : "checked" )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'input-invert\' name=\'circuit[input_invert]\' type=\'checkbox\'>\n            <label for=\'input-invert\'>\n                ' +
((__t = ( i18next.t('circuits.inverted') )) == null ? '' : __t) +
'?</label>\n        </dt>\n    </dl>\n</form>\n';

}
return __p
}