module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = ( i18next.t('users.enable_confirmation_message', { name: name }) )) == null ? '' : __t) +
'\n';

}
return __p
}