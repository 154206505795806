module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = ( i18next.t('tenant_invoices.regenerate_confirmation') )) == null ? '' : __t) +
'\n';

}
return __p
}