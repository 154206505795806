WiseMetering.Views.IndexOrganizationLogs = WiseMetering.Views.TableIndex.extend({
    createTable: function() {
        return new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: WiseMetering.organizationLogs,
            columns: {
                description: i18next.t('common.description'),
                creator: i18next.t('glossary.created_by'),
                created_at: i18next.t('glossary.created_at')
            },
            id: 'organization-logs',
            modelSerializer: function(model) {
                const
                    json = model.toJSON(),
                    timezone = WiseMetering.currentOrganization.get('timezone'),
                    creator = model.creator();

                json.creator = creator?.get('name') || i18next.t('ui.system_user');
                json.created_at = WiseMetering.utils.formatDate(model.get('created_at'), timezone, 'YYYY/MM/DD HH:mm');
                return json;
            },
            toolbar_actions: {
                refresh: {
                    disabled: false
                }
            }
        });
    }
});
