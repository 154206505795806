WiseMetering.Views.NewIndicator = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'indicator',
    template: 'templates/indicators/new',
    title: () => i18next.t('modal.title.indicators.add'),

    ui: {
        building: 'select#indicator_building',
        container: '#type-container',
        kind: 'select#indicator_kind',
        name: '#indicator_name',
        type: 'select#indicator_type'
    },

    events: {
        'change select#indicator_building': 'onBuildingChange',
        'change select#indicator_kind': 'onKindChange',
        'change select#indicator_type': 'onTypeChange'
    },

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    onRender: function() {
        this.onKindChange();
    },

    serializeData: function() {
        return {
            isNew: true,
            building_id: this.model.get('building_id')
        };
    },

    getFormData: function() {
        const data = this.formSerializer();
        if (data.building_id === 'multi-building') {
            this.model.unset('building_id');
            delete data.building_id;
            data.organization_id = WiseMetering.currentOrganization.id;
        }

        if (data.type !== 'complex') {
            delete data.operation;
            this.model.unset('operation');
        }

        return data;
    },

    onBuildingChange: function() {
        this.ui.type.attr('disabled', 'disabled');

        let building = this.ui.building.val();
        if (building === 'multi-building') {
            building = null;
        }
        this.model.set({ building_id: building });

        this.typeChange();
    },

    onKindChange: function() {
        const kind = WiseMetering.indicatorKinds.get(this.ui.kind.val());
        this.model.set({ kind_id: kind.id });
        this.$('#indicator_name').val(kind?.get('default') || '');
    },

    onTypeChange: function() {
        this.ui.kind.attr('disabled', 'disabled');
        const type = this.ui.type.val();
        this.model.set({ type });

        if (this.model.get('building_id'))  {
            this.typeChange();
        } else {
            let buildings = WiseMetering.zones.activeSites().map(building => ({ id: building.id, name: building.get('name') }));
            if (type === 'complex' && buildings.length > 1) {
                buildings.unshift({ id: 'multi-building', name: 'Multi Building' });
            }
            buildings.unshift({ id: '', name: i18next.t('ui.please_select_option') });
            this.ui.building.html(WiseMetering.utils.optionsFromArray(buildings));
        }
    },

    typeChange: function() {
        let view;
        switch (this.ui.type.val()) {
            case 'complex':
                view = new WiseMetering.Views.ComplexIndicator({ modal: this.modal, model: this.model });
                break;
            case 'etl':
                view = new WiseMetering.Views.EtlIndicator({ modal: this.modal, model: this.model });
                break;
            case 'mirror':
                view = new WiseMetering.Views.MirrorIndicator({ modal: this.modal, model: this.model });
                break;
            default:
                break;
        }

        if (view) {
            view.setElement(this.ui.container).render();
            this.modal.redraw();
        }
    }
});
