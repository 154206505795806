import { cn } from '@/utils';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import * as React from 'react';

const Checkbox = React.forwardRef(({ className, ...props }, ref) => (
        <CheckboxPrimitive.Root
                ref={ref}
                className={cn(
                        'tw-peer tw-h-4 tw-w-4 tw-shrink-0 tw-rounded-sm tw-border tw-border-primary tw-ring-offset-background focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-primary data-[state=checked]:tw-text-primary-foreground',
                        className
                )}
                {...props}>
            <CheckboxPrimitive.Indicator
                    className={cn('tw-flex tw-items-center tw-justify-center tw-text-current')}>
                <Check className="tw-h-4 tw-w-4"/>
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
