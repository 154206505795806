module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('common.attributes') )) == null ? '' : __t) +
'\n                <div class=\'wmui-btn\' id=\'edit_attributes\' style=\'float: right;\'>' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                <div class=\'per-box\'>\n                    <dl>\n                        <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( utilityKindName )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t(utilityKindSlug === 'electricity' ? 'indicatorKinds.active_energy' : 'glossary.indicator') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( `<a id='${indicator.id}' href='${indicator.nodeUrl()}'>${indicator.get('name')}</a>` )) == null ? '' : __t) +
'</dd>\n                        ';
 if (capacitiveEnergy) { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('indicatorKinds.capacitive_energy') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( `<a id='${capacitiveEnergy.id}' href='${capacitiveEnergy.nodeUrl()}'>${capacitiveEnergy.get('name')}</a>` )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n                        ';
 if (inductiveEnergy) { ;
__p += '\n                            <dt>' +
((__t = ( i18next.t('indicatorKinds.inductive_energy') )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( `<a id='${inductiveEnergy.id}' href='${inductiveEnergy.nodeUrl()}'>${inductiveEnergy.get('name')}</a>` )) == null ? '' : __t) +
'</dd>\n                        ';
 } ;
__p += '\n                        <dt>' +
((__t = ( i18next.t('glossary.contracts') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( contracts )) == null ? '' : __t) +
'</dd>\n                        <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
':</dt>\n                        <dd>' +
((__t = ( lastChange )) == null ? '' : __t) +
'</dd>\n                    </dl>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}