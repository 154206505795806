WiseMetering.Views.ZoneAttributesAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/zones/attributes',

    modelEvents: {
        'change': 'render',
        'change:addAttributeValue': 'render',
        'change:changeAttributeValue': 'render',
        'change:removeAttributeValue': 'render'
    },

    events: {
        'click div#manage_attributes': 'manageAttributes'
    },

    serializeData: function() {
        const json = this.model.toJSON();

        json['attributes'] = this.model.zone_attributes().chain().collect(function(attribute) {
            return {
                name: attribute.zoneAttributeName(),
                value: attribute.formattedValue(),
                symbol: attribute.symbol()
            };
        }).sortBy('name').value();

        return json;
    },

    manageAttributes: function(event) {
        event.stopPropagation();

        new WiseMetering.Views.ZoneAttributesManage({
            collection: WiseMetering.zone_attribute_values,
            zone: this.model,
            model: new WiseMetering.Model.ZoneAttributeValue
        });
    }
});
