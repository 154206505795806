module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'svg_icon ' +
((__t = ( disabled ? ' disabled_icon' : null )) == null ? '' : __t) +
'\' id=\'ui-button-' +
((__t = ( action )) == null ? '' : __t) +
'\' title=\'' +
((__t = ( label )) == null ? '' : __t) +
'\'>\n    ' +
((__t = ( WiseMetering.icon(icon) )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}