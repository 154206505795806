module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for="event_name">' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class="text" id="event_name" name="event[name]" size="40" type="text" value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'/>\n        </dd>\n\n        <dt>\n            <label for="event-description">' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class="text" id="event-description" name="event[description]" cols="40" rows="20">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n        </dd>\n\n        <dt class="help" id="what_happened">\n            <label for="event-event-type-id">' +
((__t = ( i18next.t('markers.what_happened') )) == null ? '' : __t) +
'?</label>\n        </dt>\n        <dd class="marker_type">\n            <select class="text" id="event-event-type-id" name="event[marker_type_id]">\n                ';
 WiseMetering.markerTypes.forEach(function (markerType){ ;
__p += '\n                    <option value="' +
((__t = ( markerType.id )) == null ? '' : __t) +
'" ' +
((__t = ( marker_type_id === markerType.id ? 'selected' : '' )) == null ? '' : __t) +
'>\n                        ' +
((__t = ( markerType.get('name') )) == null ? '' : __t) +
'\n                    </option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n\n        <div id="event-has-length-form">\n            <dt>\n                <label for="event-has-length">' +
((__t = ( i18next.t('markers.marker_length') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd id="event-has-length" style="padding:5px">\n                <input type="radio" class="has-no-length-checkbox" ' +
((__t = ( duration ? '' : 'checked' )) == null ? '' : __t) +
' name="event-has-length" value="0" style="vertical-align:initial" disabled>\n                <span style="margin-right:5px">' +
((__t = ( i18next.t('common.no') )) == null ? '' : __t) +
'</span>\n                </input>\n                <input type="radio" class="has-length-checkbox" ' +
((__t = ( duration ? 'checked' : '' )) == null ? '' : __t) +
' name="event-has-length" value="1" style="vertical-align:initial" disabled>\n                <span style="margin-right:5px">' +
((__t = ( i18next.t('common.yes') )) == null ? '' : __t) +
'</span>\n                </input>\n            </dd>\n        </div>\n\n        ';
 if (duration) { ;
__p += '\n            <div id="event-test-length-form">\n                <dt>\n                    <label for="event-test-length">' +
((__t = ( i18next.t('markers.marker_period') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dd id="event-test-length-options" style="padding:5px">\n                    ';
 [[86400, 'day', i18next.t('common.day')], [604800, 'week', i18next.t('common.week')], [2592000, 'month', i18next.t('common.month')]].forEach(function([value, type, text]) { ;
__p += '\n                        <input id="one-' +
((__t = ( type )) == null ? '' : __t) +
'" type="radio" name="event-test-length" value="' +
((__t = ( value )) == null ? '' : __t) +
'"\n                               style="vertical-align:initial" ';
 if (duration === value) { ;
__p += 'checked\n                                ';
 } ;
__p += '\n                        >\n                        <span style="margin-right:5px">1 ' +
((__t = ( text )) == null ? '' : __t) +
'</span>\n                        </input>\n                    ';
 }) ;
__p += '\n                </dd>\n            </div>\n        ';
 } ;
__p += '\n\n        <div id="event-date-and-time">\n            <dt class="help" id="when_did_it_happen">\n                <label for="event-marker_at">' +
((__t = ( i18next.t('markers.when_did_it_happened') )) == null ? '' : __t) +
'?</label>\n            </dt>\n            <dd>\n                <ul class="ctrl inline dp" style="padding:0">\n                    <li class="field border" style="width:100%">\n                        <input class="text" id="event-at-date" maxlength="10" name="event[marker_at]" readonly type="text" value="' +
((__t = ( marker_at )) == null ? '' : __t) +
'">\n                    </li>\n                </ul>\n            </dd>\n        </div>\n\n        ';
 if (duration) { ;
__p += '\n            <div id="event-with-length">\n                <dt class="help">\n                    <label for="event-reference_period_start">' +
((__t = ( i18next.t('markers.measure_test_impact') )) == null ? '' : __t) +
'?</label>\n                </dt>\n                <dd>\n                    <div style="display:flex">\n                        <select class="text" style="width:20% !important" id="event-has-measurable-impact" disabled>\n                            ';
 if (reference_period_start) { ;
__p += '\n                                <option value="1">' +
((__t = ( i18next.t('common.yes') )) == null ? '' : __t) +
'</option>\n                            ';
 } else { ;
__p += '\n                                <option value="0">' +
((__t = ( i18next.t('common.no') )) == null ? '' : __t) +
'</option>\n                            ';
 } ;
__p += '\n                        </select>\n                        ';
 if (reference_period_start) { ;
__p += '\n                            <div class="reference-period" style="margin-left:5px;width: -webkit-fill-available">\n                                <select class="text" id="event-reference-period"></select>\n                            </div>\n                        ';
 } ;
__p += '\n                    </div>\n                    ';
 if (reference_period_start) { ;
__p += '\n                        <div class="custom-reference-period" style="margin-top: 5px;">\n                            <ul class="ctrl inline dp" style="padding:0;">\n                                <li class="field border" style="width:100%">\n                                    <input class="text" id="event-custom-reference-period-start" maxlength="10" readonly type="text" value="' +
((__t = ( moment().format('YYYY-MM-DD') )) == null ? '' : __t) +
'">\n                                </li>\n                            </ul>\n                        </div>\n                    ';
 } ;
__p += '\n                </dd>\n            </div>\n\n            <input id="event-duration" name="event[duration]" type="hidden" value=""/>\n            <input id="event-reference-period-start-value" name="event[reference_period_start]" type="hidden"/>\n        ';
 } ;
__p += '\n\n        ';
 if (reference_period_start) { ;
__p += '\n            <div class="impact-analysis-box">\n                <dt>' +
((__t = ( i18next.t('markers.measure_before_saving') )) == null ? '' : __t) +
'?</dt>\n                <dd class="results">\n                    <div>\n                        <i class="fas fa-spinner fa-spin" aria-hidden="true" style="display:none"></i>\n                        <span id="calculated-results"></span>\n                    </div>\n                </dd>\n            </div>\n        ';
 } ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}