// Wise Indicator
Lmit.Wise.Chart.Control.Operation = function(_controller_, _container_, _options_) {
    this.options = _options_;
    this.controller = _controller_;
    this.container =  _container_;
    // view elements
    this.view= null;
    //
    this.wrappers = this.options.wrappers;
    this.wrapper = null;
    this.symbol = null;
    this.init();
};

Lmit.Wise.Chart.Control.Operation.prototype = {
    init: function(){
      this.view = $('<div>').addClass('wmui-chart-control-operation');
      this.container.append(this.view);
      this.build();
    },

    idle: function(){
      this.view.hide();
    },

    enable: function(){
      this.view.show();
    },

   build: function(){
     var _this=this;
     var _ctrl = $('<ul>').addClass("ctrl")
     _.each(this.wrappers, function(item,i){
       var operationCtrl= $('<li>').addClass("mode " + item[2]).attr({"data-wrapper":item[1],"data-unit":item[2]}).text(item[0])

       _ctrl.append(operationCtrl);
       _this.bindItemEvent(operationCtrl)
     })
     this.view.append(_ctrl);
   },

   bindItemEvent: function(item){
     var _this=this;
     item.click(function(){
       if(!$(this).hasClass("current")){
         _this.setOperation($(this).attr("data-wrapper"),$(this).attr("data-unit"), true)
       } else {
         _this.idleOperation()
       }
     })
   },

   updateView: function(){
     this.view.children("ul").children("li").removeClass("current");
     this.view.children("ul").children(("li[data-wrapper=" + this.wrapper.wop + "]")).addClass("current");
   },

   setOperation: function(_wrapper, _unit, fetch){
     this.wrapper = {wop:_wrapper, wu:_unit};
     if (fetch) this.controller.onOperationUpdate(this.wrapper);
     this.updateView();
   },

   idleOperation: function(){
     this.view.children("ul").children("li").removeClass("current");
     this.wrapper = null;
     this.controller.onOperationUpdate(null);
   }


}