module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="interval">\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="capitalize head" colspan="2">\n            ' +
((__t = ( i18next.t('common.interval') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
'\n        </th>\n        <th class="capitalize">\n            ' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
'\n        </th>\n    </tr>\n    <tr>\n        <td>\n            <input class="from" disabled name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][from]" type="text" value="' +
((__t = ( interval.from )) == null ? '' : __t) +
'">\n        </td>\n        <td>\n            <input class="to" name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][to]" type="text" value="' +
((__t = ( interval.to )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            Potencia (' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/kWh)\n        </th>\n    </tr>\n    ';
 ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'].forEach(function(period) { ;
__p += '\n        <tr>\n            <th>\n                Coste ' +
((__t = ( period )) == null ? '' : __t) +
' (' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/kW.ano)\n            </th>\n            <td>\n                <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][contracted_power][' +
((__t = ( period.toLowerCase() )) == null ? '' : __t) +
']" type="text" value="' +
((__t = ( interval.values?.contracted_power[period.toLowerCase()] )) == null ? '' : __t) +
'">\n            </td>\n        </tr>\n    ';
 }) ;
__p += '\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            Energia Activa (' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/kWh)\n        </th>\n    </tr>\n    ';
 ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'].forEach(function(period) { ;
__p += '\n        <tr>\n            <th>\n                Periodo ' +
((__t = ( period )) == null ? '' : __t) +
'\n            </th>\n            <td>\n                <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][active][' +
((__t = ( period.toLowerCase() )) == null ? '' : __t) +
']" type="text" value="' +
((__t = ( interval.values?.active[period.toLowerCase()] )) == null ? '' : __t) +
'">\n            </td>\n        </tr>\n    ';
 }) ;
__p += '\n    <tr class="spacer"></tr>\n    <tr>\n        <th class="head" colspan="2">\n            Otros Costes\n        </th>\n    </tr>\n    <tr>\n        <th>\n            Alquiler de equipos de medida (' +
((__t = ( WiseMetering.getCurrency() )) == null ? '' : __t) +
'/mes)\n        </th>\n        <td>\n            <input name="tariff[intervals][' +
((__t = ( index )) == null ? '' : __t) +
'][values][other][rental]" type="text" value="' +
((__t = ( interval.values?.other.rental )) == null ? '' : __t) +
'">\n        </td>\n    </tr>\n</table>\n';

}
return __p
}