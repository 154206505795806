WiseMetering.Views.IndexNextbittIntegration = WiseMetering.Views.Content.extend({
    icon: 'hub',
    name: () => 'Nextbitt',

    initialize: function(id) {
        this.id = id
    },

    createTable: function() {
        this.table = new WiseMetering.Views.Table({
            aLengthMenu: [25, 50, 100],
            collection: WiseMetering.integrationServices.filterIntegrations(this.id),

            modelSerializer: function(model) {
                const json = model.toJSON();
                json.last_run = WiseMetering.utils.formatDate(model.get('last_sync'));

                if (json.state === 'disabled') {
                    json.state = {
                        color: 'red',
                        title: i18next.t('glossary.disabled')
                    };
                } else {
                    json.state = {
                        color: 'Green',
                        title: i18next.t('glossary.enabled')
                    };
                }

                // json.partner = model.integrationPartner().get('name');
                return json;
            },
            columns: {
                state: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({ color, title }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                building_id: {
                    title: i18next.t('glossary.building'),
                    mRender: function(building_id) {
                        const building = WiseMetering.zones.get(building_id);
                        return building.get('name');
                    }.bind(this)
                },
                external_building_ref: i18next.t('glossary.external_id'),
                category: i18next.t('common.category'),
            },
            id: 'integration_services',
            toolbar_actions: {
                new: {
                    permission: 'superuser',
                    view_call: () => {
                        buildings = WiseMetering.zones.activeSites();
                        new WiseMetering.Views.NewNextbittIntegration({
                            collection: WiseMetering.integrationServices,
                            model: new WiseMetering.Model.IntegrationService,
                            buildings
                        });
                    }
                },
                show: {
                    view_call: model => {
                        model.fetch().then(function() {
                            new WiseMetering.Views.ShowNextbittIntegration({ model });
                        });
                    }
                },
                delete: { permission: 'superuser' }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
    },

    onShow: function() {
        this.listenTo(WiseMetering.integrationServices, 'add', this.createTable.bind(this));
        this.createTable();
    },

    updateFields: function() {
    }
});
