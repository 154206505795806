WiseMetering.Views.AdvancedOperation = Backbone.Marionette.ItemView.extend({
    template: 'templates/indicators/operations/advanced',

    ui: {
        indicators: '#indicators-list',
        operation: '#indicator-operation-value',
        operationBox: '#indicator-operation',
        search: 'input#indicator-search'
    },

    events: {
        'keyup input#indicator-search': 'search',
        'click a.operation': 'onOperatorClick'
    },

    idRegex: /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/,
    operationRegex: /(\+|-|\/|\*|\(|\)|\.abs)$/,

    onRender: function() {
        this.operationArray = [];
        this.currentPosition = 0;

        if (!this.model.isNew()) {
            this.populateArrays();
            this.updateIndicator();
            this.ui.operationBox.animate({ scrollTop: this.ui.operationBox.scrollHeight });
        }

        this.search();
    },

    serializeData: function() {
        let json = this.model.toJSON();
        json.isNew = this.model.isNew();
        return json;
    },

    onOperatorClick: function(event) {
        event.preventDefault();

        this.ui.operationBox.focus();

        const operation = $(event.currentTarget).data('id');

        if (operation === 'clear') {
            this.operationArray = [];
            this.currentPosition = 0;
        } else if (operation === 'del') {
            if (this.currentPosition > 0) {
                this.currentPosition--;
            }
            this.operationArray.splice(this.currentPosition, 1);
        } else if (operation === 'rgt') {
            if (this.currentPosition < this.operationArray.length) {
                this.currentPosition++;
            }
        } else if (operation === 'frgt') {
            this.currentPosition = this.operationArray.length;
        } else if (operation === 'lft') {
            if (this.currentPosition > 0) {
                this.currentPosition--;
            }
        } else if (operation === 'flft') {
            this.currentPosition = 0;
        } else if (operation === 'add') {
            this.operationArray.splice(this.currentPosition, 0, this.$('#indicators-list :selected').val());
            this.currentPosition++;
        } else {
            this.operationArray.splice(this.currentPosition, 0, String(operation));
            this.currentPosition++;
        }
        this.updateIndicator();
    },

    populateArrays: function() {
        this.operationArray = [];
        let currentOperation = this.ui.operation.val();

        while (currentOperation) {
            let match;
            if (match = this.idRegex.exec(currentOperation)) {
                this.operationArray.unshift(match[0]);
                currentOperation = currentOperation.substring(0, currentOperation.length - 36);
            } else if (match = /[0-9]{1}|\./.exec(currentOperation.substring(currentOperation.length - 1))) {
                this.operationArray.unshift(match[0]);
                currentOperation = currentOperation.substring(0, currentOperation.length - 1);
            } else if (match = this.operationRegex.exec(currentOperation)) {
                this.operationArray.unshift(match[0]);
                currentOperation = currentOperation.substring(0, currentOperation.length - match[0].length);
            } else {
                alert('A bug has occurred, please notify support@wisemetering.com');
            }
        }

        this.currentPosition = this.operationArray.length;
    },

    search: function(event) {
        if (event && (event.keyCode || event.which) !== 13) {
            return;
        }

        const buildingId = this.model.get('building_id');

        let indicators = WiseMetering.indicators.map(indicator => indicator.attributes);
        if (buildingId !== null) {
            indicators = indicators.filter(indicator => indicator.building_id === buildingId);
        }

        // Name filter
        let name = this.ui.search.val();
        // Inspired by http://stackoverflow.com/a/9310752/1787187
        if (name) {
            name = new RegExp(name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
            indicators = indicators.filter(indicator => indicator.name.match(name));
        }

        if (indicators.length === 0) {
            this.ui.indicators.html(`<option disabled selected>${i18next.t('indicators.no_indicators_found')}</option>`);
            return;
        }

        this.ui.indicators.html(WiseMetering.utils.optionsFromArray(indicators));
    },

    updateIndicator: function() {
        if (this.operationArray.length === 0) {
            this.ui.operationBox.empty();
            return;
        }

        this.ui.operation.val(this.operationArray.join(''));

        const operationTextArray = this.operationArray.map(function(operator) {
            if (operator.match(this.idRegex)) {
                return WiseMetering.indicators.get(operator).get('name');
            }
            return operator;
        }.bind(this));

        let index = this.currentPosition - 1;
        if (index < 0) {
            index = 0;
        }
        operationTextArray[index] = `<span style='color: red'>${operationTextArray[index]}</span>`;
        this.ui.operationBox.html(operationTextArray.join(''));
    }
});
