module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dt>\n    <label for="indicator_ratio">' +
((__t = ( i18next.t('glossary.ratio') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input id=\'indicator_ratio\' class="text" name="indicator[ratio]" value=\'' +
((__t = ( ratio )) == null ? '' : __t) +
'\'/>\n</dd>\n';

}
return __p
}