WiseMetering.Views.Buildings = Backbone.Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'tree default items',
    itemView: WiseMetering.Views.Node,
    panel: null,

    initialize: function() {
        this.panel = this.$('.panels_container');
        this.listenTo(WiseMetering.zones, 'add change', zone => {
            if (zone.type() === 'building' && zone.isActive()) {
                this.collection.add(zone);
            } else if (zone.type() === 'building' && !zone.isActive()) {
                this.collection.remove(zone);
            }
        });
    }
});
