WiseMetering.Collection.Users = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.User,
    url: '/users',

    assignable: function() {
        let users = this.where({ administrator: false, integration: false }).filter(user => user.get('name') !== 'System');
        return new WiseMetering.Collection(users);
    },

    apiKeysAssignable: function() {
        return new WiseMetering.Collection(this.where({ integration: true }));
    }
});
