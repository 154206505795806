module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'circle\' title=\'' +
((__t = ( typeof title === 'undefined' ? '' : title )) == null ? '' : __t) +
'\'\n     style=\'width: ' +
((__t = ( typeof size === 'undefined' ? 8 : size )) == null ? '' : __t) +
'px;\n        height: ' +
((__t = ( typeof size === 'undefined' ? 8 : size )) == null ? '' : __t) +
'px;\n        background: ' +
((__t = ( color )) == null ? '' : __t) +
';\n        border-radius: 50%;\'>\n</div>\n';

}
return __p
}