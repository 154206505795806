import React from 'react';
import clsx from 'clsx';
import { cva } from 'class-variance-authority';
import {
    AlertCircle,
    Bell,
    CheckCircle,
    CircleArrowLeft,
    CircleCheckBig,
    FolderCog,
    Globe,
    Info,
    KeyRound,
    UserRoundPen
} from 'lucide-react';

const iconStyles = cva('tw-inline-flex tw-mr-1', {
    variants: {
        color: {
            default: 'tw-text-primary',
            destructive: 'tw-text-destructive',
            info: 'tw-text-sky-800',
            seconday: 'tw-text-seconday',
            sideMenu: 'tw-text-grey',
            success: 'tw-text-green-600',
            warning: 'tw-text-amber-800'
        },
        size: {
            small: 'tw-w-4 tw-h-4',
            medium: 'tw-w-6 tw-h-6',
            large: 'tw-w-8 tw-h-8',
        },
    },
    defaultVariants: {
        color: 'default',
        size: 'medium',
    },
});

const iconMap = {
    arrowLeftCircle: CircleArrowLeft,
    circleCheck: CheckCircle,
    destructive: AlertCircle,
    exclamationCircle: AlertCircle,
    notifications: Bell,
    globe: Globe,
    info: Info,
    password: KeyRound,
    preferences: FolderCog,
    success: CircleCheckBig,
    user: UserRoundPen,
    warning: AlertCircle
};

const Icon = ({ color, className = '', icon, size, ...props }) => {
    const IconComponent = iconMap[icon];
    const iconClassName = clsx(iconStyles({ color, size }), className);

    return IconComponent ? (
        <IconComponent
            className={iconClassName}
            {...props}
        />
    ) : (
        <span>Invalid icon</span> // Return this when an invalid icon is passed
    );
};

Icon.displayName = 'Icon';

export { Icon };
