
import * as React from 'react';
import  { Container, ContentArea, SidebarMenu } from "@/components/wm";
import { Password } from '@/pages/UserProfile/Content/password';
import { Notifications } from '@/pages/UserProfile/Content/notifications';
import { Language } from '@/pages/UserProfile/Content/language';
import { Profile } from '@/pages/UserProfile/Content/profile';
import { useState } from 'react';

export default function UserProfile() {
    const [currentView, setCurrentView] = useState("profile"); // Profile, Password, Preferences

    const menuItems = [
        {
            key: 'profile',
            title: i18next.t('common.details').capitalize(),
            icon: 'user',
        },
        {
            key: 'password',
            title: i18next.t('common.password').capitalize(),
            icon: 'password',
        },
        {
            key: 'notifications',
            title: i18next.t('common.notifications').capitalize(),
            icon: 'notifications',
        },
        {
            key: 'language',
            title: i18next.t('common.language').capitalize(),
            icon: 'globe',
        }
    ];

    const handleViewChange = (view) => {
        setCurrentView(view)
    };

    return (
        <Container variant="full">
            <SidebarMenu
                items={menuItems}
                currentView={currentView}
                handleViewChange={handleViewChange}
            />
            <ContentArea>
                { currentView === "profile" && (
                    <Profile/>
                )}
                { currentView === "password" && (
                    <Password/>
                )}
                { currentView === "notifications" && (
                    <Notifications/>
                )}
                { currentView === "language" && (
                    <Language/>
                )}
            </ContentArea>
        </Container>
    );
}

