WiseMetering.Views.ZoneDistribution = Backbone.Marionette.ItemView.extend({

    template: "templates/zones/widgets/distribution",
    className: "dashboard full-height",
    widgets: {},

    serializeData: function(){
        return { widgets: this.widgets };
    },

    onBeforeRender: function(){
        if(this.model.activeEnergy()){
            if(this.model.zones().length > 0)    this.widgets["zones"]    = new WiseMetering.Views.Ui.Widget.Distribution({ el: "#" + generateUUID(), type: "zone" });
            if(this.model.circuits().length > 0) this.widgets["circuits"] = new WiseMetering.Views.Ui.Widget.Distribution({ el: "#" + generateUUID(), type: "circuit" });
        }
    },

    onShow: function() {
        _.each(this.widgets, function(widget){ widget.render(); });
    },

    onClose: function(){
        _.each(this.widgets, function(widget){ widget.close(); });
    }

});