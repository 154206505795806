WiseMetering.Views.SortWidgets = WiseMetering.Views.Modal.Show.extend({

    className: 'mbx-info text-left',
    form_prefix: 'user',
    template: 'templates/zones/sort_widgets',
    title: 'Sort Widgets',
    events: {
        'click input[type=checkbox]': 'toggleWidget'
    },

    onShow: function() {
        this.$('.sortable').sortable({ update: this.sortWidgets.bind(this) });
    },

    reloadDashboard: function() {
        Backbone.history.loadUrl(Backbone.history.fragment);
    },

    savePreferences: function(building_widgets) {
        this.model
        .save({ building_widgets }, { patch: true })
        .done(() => WiseMetering.layout.showTipper('success', 'Successfully saved preferences', 250))
        .fail(() => WiseMetering.layout.showTipper('error', 'Error saving preferences', 250));
    },

    serializeData: function() {
        const preferences = this.model.buildingWidgets();
        const widgets = preferences.widgets.map(type => ({
            active: preferences.active.includes(type),
            name: WiseMetering.AvailableWidgets[type],
            type
        }));
        return { widgets };
    },

    sortWidgets: function() {
        const widgets = [];
        this.$('.sortable .member').each((_, element) => widgets.push(element.dataset.value));
        this.savePreferences(Object.assign({}, this.model.buildingWidgets(), { widgets }));
        this.reloadDashboard();
    },

    toggleWidget: function(event) {
        const
            preferences = this.model.buildingWidgets(),
            active = [...preferences.active], // clone object
            widget = event.target.dataset.value;

        if (event.target.checked) {
            active.push(widget);
        } else {
            const index = active.indexOf(widget);
            if (index !== -1) {
                active.splice(index, 1);
            }
        }

        this.savePreferences(Object.assign({}, this.model.buildingWidgets(), { active }));
        this.reloadDashboard();
    }

});
