WiseMetering.Views.Layouts.OldLogin = Backbone.Marionette.Layout.extend({
    template: 'templates/ui/login',

    events: {
        'click input.btn_login': 'login',
        'click a.pw_lnk_rst': 'reset'
    },

    ui: {
        button: 'input.btn_login',
        form: '#login_form',
        message: 'div.msg',
        messageText: 'div.msg_text',
        loader: 'div.loader',
        email: 'input[name=email]',
        password: 'input[name=password]'
    },

    login: function(event) {
        event.preventDefault();

        const email = this.ui.email.val(), password = this.ui.password.val();
        let error;

        if (!email || !password) {
            error = 'Please enter both your email and password';
        } else if (email !== email.replace(/\s+/g, ' ').trim()) {
            error = 'email has extra spaces, please remove them';
        }

        if (error) {
            this.ui.message.removeClass('active').addClass('error');
            this.ui.messageText.html('<div class="spt icon exclamation"></div><div>' + error + '</div>');
            return;
        }

        this.ui.message.addClass('active');
        this.ui.loader.attr({ 'class': 'loader' }).show();
        this.ui.messageText.html('Authenticating...');
        this.ui.button.attr({ disabled: true });

        $.ajax({
            url: '/login',
            type: 'POST',
            data: {
                email,
                password
            },
            crossDomain: false,
            dataType: 'json',
            success: function() {
                this.ui.loader.hide();
                this.ui.message.removeClass('active').removeClass('error').addClass('success');
                this.ui.messageText.html('<div class="spt icon success"></div><div>Logged in...</div>');
                Backbone.history.navigate(WiseMetering.targetUrl || '/');
                delete WiseMetering.targetUrl;
                WiseMetering.startApp();
            }.bind(this),
            error: function(xhr) {
                let error = 'Unable to log in';
                try {
                    error = JSON.parse(xhr.responseText).error;
                } catch (e) {
                    console.error(e);
                }
                this.ui.loader.hide();
                this.ui.message.removeClass('active').addClass('error');
                this.ui.messageText.html('<div class="spt icon exclamation"></div><div>' + error + '</div>');
                this.ui.button.attr({ disabled: false });
            }.bind(this)
        });
    },

    reset: function() {
        const template = JST['templates/ui/login/password']({ email: this.ui.email.val() });
        const mbx = $(template).mbx({
            type: 'show',
            title: 'Password Recover',
            width: 500,
            height: 165,
            auto_close: false,
            buttons: $.mbx.buttons.OKCANCEL,
            onOk: function() {
                const email = $('input#registered-email').val();
                $.mbx.block('Please wait...');
                $.ajax({
                    url: '/users/reset_password',
                    type: 'POST',
                    data: { email },
                    crossDomain: false,
                    success: function() {
                        $.mbx.unblock();
                        $.mbx.close();
                        $.mbx({
                            type: 'show',
                            msg: 'An email has been sent to you.<br/><br/>Please check your mailbox.',
                            title: 'Password Request Reset',
                            width: 500,
                            auto_close: true,
                            buttons: $.mbx.buttons.CLOSE
                        });
                    },
                    error: function() {
                        $.mbx.close();
                        $.mbx({
                            type: 'show',
                            msg: 'An error as occurred.<br/><br/>Please try again later.',
                            title: 'Password Request Reset',
                            width: 500,
                            auto_close: true,
                            buttons: $.mbx.buttons.CLOSE
                        });
                    }
                });
            }
        });

        $('body').append('<div class="mbx_tgt_cnt">').append(mbx);
    }
});
