WiseMetering.Model.Actuator = WiseMetering.Model.extend({

    urlRoot: '/actuators',

    plotBands: function(params = {}, success, error) {
        $.ajax({
            url: this.url() + '/plot_bands',
            type: 'GET',
            data: params,
            contentType: 'application/json',
            success: success,
            error: error
        });
    },

    bindChange: function() {
        this.on('change:circuit_id', function() {
            if (this.previousCircuit()) this.previousCircuit().trigger('change:removeActuator', this);
            if (this.circuit()) this.circuit().trigger('change:addActuator', this);
        });
    },

    previousCircuit: function() {
        return WiseMetering.circuits.get(this.previous('circuit_id'));
    },

    circuit: function() {
        return WiseMetering.circuits.findWhere({ actuator_id: this.id });
    },

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    schedule: function() {
        const circuit = this.circuit();

        return circuit ? circuit.schedule() : null;
    },

    kind: function() {
        return WiseMetering.actuatorKinds.get(this.get('actuator_kind_id'));
    }

});
