WiseMetering.Views.UploadData = WiseMetering.Views.Modal.Custom.extend({
    MAX_FILE_SIZE: 128 * 1024 * 1024,

    buttons: ['ok', 'cancel'],
    className: 'mbx-info text-left',
    template: 'templates/ui/upload_data',
    title: () => i18next.t('modal.title.upload_data'),
    width: 760,

    events: {
        'change #file': 'onFileChange'
    },

    serializeData: function() {
        return {
            buildings: WiseMetering.zones.buildings().map(building => ({
                id: building.id,
                name: building.get('name')
            })),
            indicatorKinds: WiseMetering.indicatorKinds.map(indicatorKind => ({
                name: indicatorKind.get('name'),
                slug: indicatorKind.get('slug')
            }))
        };
    },

    onFileChange: function() {
        const files = this.$('#file').prop('files');
        if (files[0] && files[0].size > this.MAX_FILE_SIZE) {
            alert(i18next.t('upload_data.file_size_error', { size: this.MAX_FILE_SIZE / 1024 / 1024 }));
            currentTarget.value = '';
        }
    },

    ok: function() {
        this.block();
        this.$('#errors').empty();

        const errors = [];
        const formData = new FormData();

        const building_id = this.$('#building').val();
        if (!WiseMetering.zones.buildings().pluck('id').includes(building_id)) {
            errors.push(i18next.t('upload_data.invalid_building'));
        }

        const ratio = this.$('#ratio').val();
        formData.append('ratio', ratio);

        const indicatorKindSlug = this.$('#indicator-kind').val();
        if (!indicatorKindSlug) {
            errors.push(i18next.t('upload_data.invalid_indicator_kind'));
        } else {
            formData.append('indicator_kind_slug', indicatorKindSlug);
        }

        const file = this.$('#file').prop('files')[0];
        if (!file) {
            errors.push(i18next.t('upload_data.upload_file_error'));
        }

        if (errors.length > 0) {
            this.showErrors(errors);
            this.unblock();
            return;
        }

        formData.append('file', file);

        const promise = $.ajax({
            contentType: false,
            data: formData,
            processData: false,
            type: 'POST',
            url: `/buildings/${building_id}/upload_data`
        });

        promise.always(() => {
            this.unblock();
        });
        promise.done(function(data) {
            WiseMetering.layout.showTipper('success', i18next.t(`upload_data.${data.text}`));
            this.close();
        }.bind(this));
        promise.fail(function(data) {
            let json = { errors: [i18next.t('upload_data.server_error')] };
            try {
                json = JSON.parse(data.responseText);
            } catch (error) {
            }
            //WiseMetering.layout.showTipper('error', i18next.t('upload_data.errors_found'));
            let errors = ['Unknown errors'];

            if (json.error) {
                errors = [json.error];
            } else if (json.errors) {
                errors = json.errors;
            }

            this.showErrors(errors);
        }.bind(this));
    },

    showErrors: function(list) {
        this.$('#errors').html(list.join('<br/>') + '<br/><br/>');
    }
});
