Lmit.Wise.Chart.Control.Text = function(_controller_, _container_, _options_) {
    this.options = _options_;
    this.controller = _controller_;
    this.container = _container_;
    this.from = null;
    this.to = null;
    // view elements
    this.view = null;
    this.visible = null;
    this.control = null;
    this.textElement = null;
    //defaults
    this.init();
};

Lmit.Wise.Chart.Control.Text.prototype = {
    init: function() {
        this.view = $('<div>').addClass('wmui-chart-control-text').css({ 'padding': '0 !important' });
        this.container.append(this.view);
        this.visible = true;
        this.build();
    },

    build: function() {
        this.ctrl = $('<ul>').addClass('ctrl').css({ 'overflow': 'hidden', 'margin-left': '15px', 'padding': '0 0 0 0' });
        this.textElement = $('<li>').addClass('legend').html('&nbsp;');
        this.ctrl.append(this.textElement);
        this.view.append(this.ctrl);
    },

    print: function(date, consumption, color) {
        const html = `<span class="capitalize" style="color:${color}">${date} ● ${consumption}</span>`;
        this.textElement.html(html);
    },

    show: function() {
        if (!this.visible) {
            this.ctrl.show();
            this.visible = true;
        }
    },

    hide: function() {
        if (this.visible) {
            this.textElement.html('&nbsp;');
            this.visible = false;
        }
    }
};
