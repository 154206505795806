module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card">\n    <div class="card-content">\n        ';
 if (imgSrc) { ;
__p += '\n            <div class="card-image">\n                <img src="' +
((__t = ( imgSrc )) == null ? '' : __t) +
'" alt="Card image">\n            </div>\n        ';
 } ;
__p += '\n\n        <div class="card-title-container">\n            ';
 if (title) { ;
__p += '\n                <div class="card-title">\n                    <p>' +
((__t = ( title )) == null ? '' : __t) +
'</p>\n                </div>\n            ';
 } ;
__p += '\n\n            <div class="card-links">\n                ';
 if (website) { ;
__p += '\n                    <div class="card-website">\n                        <a href="' +
((__t = ( website )) == null ? '' : __t) +
'" class="link" target="_blank">\n                            ' +
((__t = ( WiseMetering.icon('website') )) == null ? '' : __t) +
'\n                        </a>\n                    </div>\n                ';
 } ;
__p += '\n                ';
 if (linkedin) { ;
__p += '\n                    <div class="card-linkedin">\n                        <a href="' +
((__t = ( linkedin )) == null ? '' : __t) +
'" class="link" target="_blank">\n                            ' +
((__t = ( WiseMetering.icon('linkedin') )) == null ? '' : __t) +
'\n                        </a>\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n\n        ';
 if (emphasis) { ;
__p += '\n            <h3 class="card-emphasis">' +
((__t = ( emphasis )) == null ? '' : __t) +
'</h3>\n        ';
 } ;
__p += '\n\n        ';
 if (description) { ;
__p += '\n            <div class="card-description">\n                <p>' +
((__t = ( description )) == null ? '' : __t) +
'</p>\n            </div>\n        ';
 } ;
__p += '\n\n        <div class="text-left card-list">\n            <dl class="stepped zero">\n                ';
 if (attributes) { ;
__p += '\n                    ';
 attributes.forEach(function(attribute) { ;
__p += '\n                        <dt>' +
((__t = ( attribute.label )) == null ? '' : __t) +
'</dt>\n                        <dd>' +
((__t = ( attribute.value )) == null ? '' : __t) +
'</dd>\n                    ';
 }) ;
__p += '\n                ';
 } ;
__p += '\n            </dl>\n        </div>\n    </div>\n    <div class="card-footer">\n        ';
 if (button) { ;
__p += '\n            <button class="card-cta ' +
((__t = ( button.name )) == null ? '' : __t) +
'">\n                ' +
((__t = ( WiseMetering.icon(button.icon) )) == null ? '' : __t) +
' ' +
((__t = ( button.label )) == null ? '' : __t) +
'\n\n            </button>\n        ';
 } else { ;
__p += '\n            <button class="card-cta coming-soon" disabled>' +
((__t = ( state.translated )) == null ? '' : __t) +
'</button>\n        ';
 } ;
__p += '\n        </div>\n</div>\n\n';

}
return __p
}