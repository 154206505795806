WiseMetering.Views.NewIntegrationAsset = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration_asset',
    template: 'templates/integration_assets/new',
    title: () => i18next.t('modal.title.integration_assets.new'),
    integration_id: null,

    ui: {
        assetsList: '#assets-list',
        buildingChoices: '#integration_service_id',
        name: 'input#integration_asset_name',
        search: 'input#asset-search',
        spinner: '#spinner'
    },

    events: {
        'keyup input#asset-search': 'search'
    },

    initialize: function(options) {
        WiseMetering.Views.Modal.Save.prototype.initialize.call(this, options);

        this.externalAssets = null;
        this.indicator_id = options.indicator.id;
        this.collection = new Backbone.Collection();
    },

    fetchExternalAssets: function(searchText, integrationId) {
        const url = `/integrations/${integrationId}/external_assets?search_text=${searchText}`;
        this.modal.block();

        $.ajax({
            url: url,
            success: function(data) {
                this.externalAssets = data;
                this.ui.assetsList.empty();
                this.externalAssets.forEach(function (asset, index) {
                    this.ui.assetsList.append(
                        `<input class="vertical-middle" id="integration_asset[assets][${ index }]" name="integration_asset[assets][]" type="checkbox" value="${ index }"></input>`,
                        `&nbsp;`,
                        `<label for='integration_asset[assets][${ index }]'>${asset.name}</label><br/>`
                        );
                }.bind(this));
                this.modal.unblock();
            }.bind(this),
            error: function() {
                WiseMetering.layout.showTipper('error', 'Validation failed.');
                this.modal.unblock();
            }.bind(this)
        });
    },

    getFormData: function() {
        var data = this.formSerializer();
        const selectedAssets = [];
        const currentAssets = this.externalAssets;
        const self = this;

        data.assets.forEach((selected, index) => {
            if (selected) {
                const asset = currentAssets[index];
                asset['indicator_id'] = self.indicator_id;
                asset['settings'] = { "markers_integration": true };
                delete asset.id;
                selectedAssets.push(asset);
            }
        });

        return selectedAssets;
    },

    save: function () {
        let data = this.getFormData();
        this.modal.block();
        WiseMetering.integrationAssets.bulkCreate(data).done(data => {
            this.modal.unblock();
            this.modal.close();
        }).fail(xhr => {
            // try to parse errors
            let errors = {};
            try {
                errors = JSON.parse(xhr.responseText);
            } catch (e) {
            }

             // show them
             let errorMessage = '';
             _(errors.errors).each((message, param) => {
                errorMessage += param + ': ' + message + '<br/>';
            });

            if (errorMessage.length > 0) {
                $(this.modal.errors.el).addClass('active').html(errorMessage);
            }

            this.modal.unblock();
        });
    },

    search: function(event) {
        if (event && (event.keyCode || event.which) !== 13) {
            return;
        }

        if (this.ui.search.val().length < 3) {
            return;
        }
        this.externalAssets = this.fetchExternalAssets(this.ui.search.val(), this.ui.buildingChoices.val());
    },

    serializeData: function() {
        const scoped_integrations = WiseMetering.integrationServices.where({ building_id: this.building.id });

        return {
            assets: this.externalAssets,
            integrationOptions: scoped_integrations.map(integration => ({
                id: integration.id,
                name: integration.get('name')
            }))
        };
    }
});


