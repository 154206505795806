WiseMetering.Views.CompanyAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/companies/accordion',

    events: {
      'click .company': 'onCompanyClicked'
    },

    modelEvents: {
        'change': 'render'
    },

    onCompanyClicked: function(event) {
      event.preventDefault();

      Backbone.history.navigate('/companies', { trigger: true });
    },

    serializeData: function() {
        return this.model.company().toJSON();
    }
});
