WiseMetering.Collection.Tiers = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Tier,
    url: '/tiers',

    comparator: function (model) {
        const sort = ['essentials', 'standard', 'advanced', 'premium'];
        return sort.indexOf(model.get('name'));
    }
});
