WiseMetering.Collection.Holidays = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Holiday,
    url: '/holidays',

    comparator: function(model) {
        return model.get('date');
    },

    getHoliday: function(timestamp, city) {
        if (!city) city = null;
        return this.find(function(holiday) {
            return holiday.get('date') === moment(timestamp).format('YYYY-MM-DD') && (!holiday.get('city') || holiday.get('city') === city);
        });
    },

    isHoliday: function(timestamp, city) {
        return !!this.getHoliday(timestamp, city);
    }
});
