WiseMetering.Collection.Alarms = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Alarm,
    url: '/alarms',
    comparator: 'start',

    activeAlarms: function() {
        return new WiseMetering.Collection.Alarms(this.where({ active: true }));
    },
});
