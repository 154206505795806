module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'circuit-name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'circuit-name\' name=\'circuit[name]\'/>\n        </dd>\n        <dt>\n            <label for=\'circuit-tag\'>Tag</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'circuit-tag\' name=\'circuit[tag_id]\'>\n                <option></option>\n            </select>\n        </dd>\n        <dt>\n            <label for=\'circuit_description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text\' id=\'circuit_description\' name=\'circuit[description]\'></textarea>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}