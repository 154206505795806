module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <form>\n        <dt>\n            <label for=\'seu-name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'seu-name\' name=\'seu[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label for=\'seu-utility-kind\'>' +
((__t = ( i18next.t('glossary.utility_kind') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'seu-utility-kind\' disabled class=\'utility_kinds text\'>\n                <option value=\'\' selected=\'selected\' disabled>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                ';
 utility_kinds.forEach(function (utilityKind) { ;
__p += '\n                    <option name=\'seu[utility_kind_name]\' value=\'' +
((__t = ( utilityKind.get('slug') )) == null ? '' : __t) +
'\' ' +
((__t = ( utilityKind.get('slug') === selectedUtilityKindSlug ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( utilityKind.get('name') )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n\n        <div class="indicator-container" ' +
((__t = ( selectedUtilityKindSlug ? '' : 'style="display: none;"' )) == null ? '' : __t) +
'>\n            <dt>\n                <label for="indicator-search">' +
((__t = ( i18next.t('ui.search_box') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'indicator-search\' placeholder=\'' +
((__t = ( i18next.t('ui.search_keywords_placeholder') )) == null ? '' : __t) +
'\' value="">\n            </dd>\n            <dt>\n                <label for="seu-indicator">' +
((__t = ( i18next.t('glossary.indicators') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'indicators text\' id=\'seu-indicator\' name=\'seu[indicator_id]\'>\n                    <option value=\'\' selected=\'selected\' disabled>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                    ';
 indicators.forEach(function (indicator) { ;
__p += '\n                        <option value=\'' +
((__t = ( indicator.id )) == null ? '' : __t) +
'\' ' +
((__t = ( indicator.id === selectedIndicator.id ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</option>\n                    ';
 }); ;
__p += '\n                </select>\n            </dd>\n        </div>\n\n        <div class="indicator-container" ' +
((__t = ( _.isEmpty(selectedIndicator) ? 'style="display: none;"' : '' )) == null ? '' : __t) +
'>\n            <dt>\n                <label for="variable-search">' +
((__t = ( i18next.t('ui.search_box') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'variable-search\' placeholder=\'' +
((__t = ( i18next.t('ui.search_keywords_placeholder') )) == null ? '' : __t) +
'\' value="">\n            </dd>\n            <dt>\n                <label for="seu-variable">' +
((__t = ( i18next.t('seus.independent_variable') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'variables text\' id=\'seu-variable\' name=\'seu[variable_id]\'>\n                    <option value=\'\' selected=\'selected\'>' +
((__t = ( i18next.t('seus.no_variable') )) == null ? '' : __t) +
'</option>\n                    ';
 Object.keys(variables).sort().forEach(function(key) { ;
__p += '\n                        <optgroup label=\'' +
((__t = ( key )) == null ? '' : __t) +
'\'>\n                            ';
 variables[key].forEach(function(variable) { ;
__p += '\n                                <option value=\'' +
((__t = ( variable.id )) == null ? '' : __t) +
'\' ' +
((__t = ( variable.id === selectedVariable.id ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( variable.get('name') )) == null ? '' : __t) +
'</option>\n                            ';
 }); ;
__p += '\n                        </optgroup>\n                    ';
 }); ;
__p += '\n                </select>\n            </dd>\n        </div>\n    </form>\n</dl>\n';

}
return __p
}