module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dd class="asset-settings">\n            <span class="asset-status">Active?</span>\n            <label class=\'switch\'>\n                <input style=\'height:20px\' type=\'checkbox\' id=\'asset-state\' name=\'asset-state\' ' +
((__t = ( state === 'active' ? 'checked' : null )) == null ? '' : __t) +
'>\n                <span class=\'slider round\'></span>\n            </label>\n        </dd>\n        <dt>\n            <label for=\'integration_asset_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'> *</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_asset_name\' name=\'integration_asset[name]\' placeholder=\'Insert Name\' value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </dd>\n        <dt>Settings</dt>\n        <div class="sliders-container"></div>\n    </dl>\n</form>\n';

}
return __p
}