Lmit.Wise.Indicator = function(data, container) {
    this.uid = data.id;
    this.indicator = WiseMetering.indicators.get(this.uid);
    this.container = container;
    this.data = data;
    this.__uri__ = null;
    this.data.color = '#' + this.data.code.substring(0, 6);
    this.element = null;
    this.main = null;
    this.chartController = null;
    this.analyzeController = null;
    this.componentsController = null;
    this.costsController = null;
    this.alarmsController = null;
    this.exportController = null;
    this.chart = null;
    this.header = null;
    this.sections = null;
    this.period = data.period;
    this.alias = data.alias || 'ytd';
    // inspector panel event management
    this.eventInspectors = [];
    this.init();
};

Lmit.Wise.Indicator.prototype = {
    init: function() {
        var that = this;
        var element = $('<div>').addClass('wmui-split_view').css('background', '#f3f3f3');
        var leftTarget = $('<div>').addClass('ph_left').css({ 'margin-right': 254 });
        var rightTarget = $('<div>').addClass('ph_right').css({ top: 39, width: 254 });
        var mainPanel = new Lmit.UI.View(that, {});
        var inspectorPanel = new Lmit.UI.View(that, {});

        leftTarget.append(mainPanel.element);
        rightTarget.append(inspectorPanel.element);
        element.append(leftTarget);
        element.append(rightTarget);

        that.container.append(element);
        this.sections = {};
        this.mainPanel = mainPanel.element;
        this.inspectorPanel = inspectorPanel.element;

        this.buildInspectorSections();
        this.addChart();
        this.setObservers();
        // CALL CONTENT
        this.chartController.periodController.setPeriod(this.alias, this.period);
    },

    setObservers: function() {
        this.chartController.compareController.register(this.exportController);
    },

    resize: function(args) {
        this.chartController.chart.resize([args[0], 400]);
        if (this.componentsController) {
            this.componentsController.chart.resize([args[1], 300]);
        }
    },

    buildInspectorSections: function() {
        if (this.data.is_complex) {
            this.initComponentsSection();
        }

        this.initAnalyzeSection();

        if (this.data.type == 'tmp'){
            this.initValidateSection();
        } else {
            this.initExportSection();
            this.initTriggersSection();
        }
    },

    addChart: function() {
        this.chartController = new Lmit.Wise.Chart.Controller(this.data.id, 'Lmit.UI.RealTime', this, this.mainPanel, this.data);
    },

    initComponentsSection: function() {
        if (this.data.aggregation) {
            this.sections.components = new Lmit.Section(i18next.t('indicators.components'), this.inspectorPanel, { display: false, style: 'wmui-section' });
            this.componentsController = new Lmit.Wise.Components.Controller(this, this.data.components, this.sections.components, { style: 'regular' });
        }
    },

    initAnalyzeSection: function() {
        this.sections.analyze = new Lmit.Section(i18next.t('glossary.analysis'), this.inspectorPanel, { display: false, style: 'wmui-section' });
        this.analyzeController = new Lmit.Wise.Analyze.Controller(this, this.sections.analyze);
    },

    initTriggersSection: function() {
        this.sections.triggers = new Lmit.Section(i18next.t('glossary.alarms'), this.inspectorPanel, { display: false, style: 'wmui-section' });
        this.alarmsController = new Lmit.Wise.Alarms.Controller(this, this.sections.triggers, { indexURL: '/alarms', indicatorUUID: this.data.id, unit: this.unit, cumulative: this.data.cumulative });
    },

    initExportSection: function() {
        this.sections.exporter = new Lmit.Section(i18next.t('glossary.export'), this.inspectorPanel, { display: false, style: 'wmui-section' });
        this.exportController = new Lmit.Wise.Export.Controller(
            this, this.sections.exporter, {
                indicator: this.indicator,
                uri: `/indicators/${this.uid}/exports`,
                components: this.data.is_complex ? this.data.components.map(c => c.id) : null
            });
        this.analyzeController.register(this.exportController);
    },

    initValidateSection: function() {
        this.sections.validate = new Lmit.Section(i18next.t('common.validation').capitalize(), this.inspectorPanel, { display: false, style: 'wmui-section' });
        this.validateController = new Lmit.Wise.Validate.Controller(this, this.sections.validate, { indexURL: '/validade', indicatorUUID: this.data.id, unit: this.unit, cumulative: this.data.cumulative, admin: this.data.admin });
    },

    onPeriodUpdate: function() {
        if (this.componentsController) {
            this.componentsController.redrawChart();
        }
    },

    open: function({ id }) {
        Backbone.history.navigate(`/indicators/${id}/plot${location.search}`, { trigger: true });
    },

    showEvent: function(eventPoint) {
        if (_.find(this.eventInspectors, event => event.id === eventPoint.id)) {
            return;
        }

        const marker = WiseMetering.markers.get(eventPoint.id);

        const eventData = {};
        eventData[`${i18next.t('common.name')}`] = marker.get('name');
        eventData[`${i18next.t('common.type')}`] = marker.get('type');
        eventData[`${i18next.t('common.length')}`] = marker.get('length');
        eventData[`${i18next.t('common.from')}`] = WiseMetering.utils.formatDate(marker.get('marker_at'));
        eventData[`${i18next.t('common.until')}`] = marker.get('end_date') && WiseMetering.utils.formatDate(marker.get('end_date'));
        eventData[`${i18next.t('common.results')}`] = marker.get('results');
        eventData[`${i18next.t('markers.reference_period_start')}`] = marker.get('reference_period_start') && WiseMetering.utils.formatDate(marker.get('reference_period_start'));
        eventData[`${i18next.t('glossary.created_by')}`] = marker.creator()?.get('name') || i18next.t('ui.system_user');
        eventData[`${i18next.t('common.category')}`] = marker.get('type');
        eventData[`${i18next.t('common.description')}`] = marker.get('description');

        // event doesn't exist yet, add event to inspector panel
        const eventDate = new Date(eventPoint.x);
        const eventSection = new Lmit.Section(moment(eventDate).format('YYYY-MM-DD ') + eventPoint.name, this.inspectorPanel, {
            display: true,
            style: 'wmui-section',
            event: true,
            eventPoint: eventPoint,
            eventInspectors: this.eventInspectors
        });
        const eventInspector = new Lmit.UI.EventInspector(
            '',
            eventData,
            this,
            eventSection,
            {
                id: eventPoint.id,
                eventPoint: eventPoint,
                editable: false
            });
        // track events
        const event = {
            id: eventPoint.id,
            section: eventSection,
            inspector: eventInspector,
            eventPoint: eventPoint,
            eventData: eventData
        };
        // add event to management
        this.eventInspectors.push(event);
    },

    removeEvent: function(id) {
        const marker = _.find(this.eventInspectors, marker => marker.id === id);
        if (marker) {
            this.eventInspectors = _.reject(this.eventInspectors, marker => marker.id === id);
            marker.inspector.destroy();
        }
    }
};

Lmit.Wise.Indicator.prototype.close = function() {
    let mainChart = this.chartController.chart ? this.chartController.chart : {};
    let componentsChart = this.componentsController && this.componentsController.chart ? this.componentsController.chart : {};

    if (mainChart.chart) {
        mainChart.remove();
    }
    if (componentsChart.chart) {
        componentsChart.remove();
    }
};
