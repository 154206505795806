WiseMetering.Views.FolderAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/folders/accordion',

    onRender: function() {
        const collection = this.model.folders(), type = this.model.type() === 'folder' ? 'folder_id' : 'zone_id';

        this.table = new WiseMetering.Views.Table({
            id: 'folders',
            collection: collection,
            columns: {
                'name': i18next.t('common.name')
            },
            toolbar_actions: {
                new: {
                    view_call: () => {
                        new WiseMetering.Views.FolderManage({
                            collection: WiseMetering.folders,
                            model: new WiseMetering.Model.Folder({
                                [type]: this.model.id
                            })
                        });
                    }
                },
                show: {
                    view_call: model => Backbone.history.navigate(model.nodeUrl(), { trigger: true })
                },
                delete: {}
            }
        });

        this.$('#folders').html(this.table.el);

        this.listenTo(this.model, 'change:children:addFolder', function(node) {
            collection.add(node);
        });

        this.listenTo(this.model, 'change:children:removeFolder', function(node) {
            collection.remove(node);
        });
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    }
});
