WiseMetering.Model.Alarm = WiseMetering.Model.extend({
    urlRoot: '/alarms',

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    integrationAssets: function() {
        return this.get('integration_asset_ids').reduce((obj, asset_id) => {
            const asset = WiseMetering.integrationAssets.get(asset_id),
                  integration = WiseMetering.integrationServices.get(asset?.get('integration_id')),
                  partner = WiseMetering.integrationPartners.get(integration?.get('partner_id')),
                  name = `${asset?.get('name')}`;

            if (asset){
                if (!obj[partner?.get('name')]) {
                    obj[partner?.get('name')] = [];
                }
                obj[partner?.get('name')].push(name);
            }
            return obj;
        }, []);
    },

    last_checked_humanized: function() {
        let last_run = this.get('last_checked') * 1000;
        return last_run === 0 ? i18next.t('alarms.never_executed') : moment.duration(moment().diff(last_run)).humanize() + ' ' + i18next.t('common.ago');
    },

    notifications: function() {
        return this.get('user_ids').reduce((array, user_id) => {
            const user = WiseMetering.users.get(user_id)?.get('name');
            return user ? array.concat(user) : array;
        }, []);
    },

    schedule: function() {
        return WiseMetering.alarmSchedules.get(this.get('schedule_id'));
    },

    valid_alarm_integration: function() {
        const indicator_id = this.indicator().id,
        assets = _.find(WiseMetering.integrationAssets.models, function(model) {
            return model.get('indicator_id') === indicator_id;
        });

        if (!assets || this.get('average')) {
            return false;
        }

        return true
    }
});
