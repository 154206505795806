import { AnimatePresence, motion } from 'framer-motion';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert';
import { Icon} from '@/components/wm';
import React, { useEffect } from 'react';

const AlertBox = ({
    active,
    animation = true,
    animationProps = {},
    deactivateAlert,
    children,
    duration = 0, // Duration in milliseconds, 0 means permanent
    icon = null,
    title = null,
    variant = ''
}) => {

    useEffect(() => {
        let timer;
        if (active && duration > 0 ) {
            timer = setTimeout(() => {
                deactivateAlert();
            }, duration);
        }
        return () => clearTimeout(timer);
    }, [active, duration, deactivateAlert]);

    const defaultAnimationProps = {
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        initial: { opacity: 0 },
        transition: { duration: 1 }
    };

    const mergedAnimationProps = animation ? { ...defaultAnimationProps, ...animationProps } : {};

    return (
        <AnimatePresence>
            {active && (
                <motion.div {...mergedAnimationProps} >
                    <Alert variant={variant} className="tw-flex tw-flex-col tw-p-4 tw-mt-4">
                        {icon && (
                            <Icon icon={variant}/>
                        )}
                        {title && (
                            <AlertTitle className="tw-text-base">{title}</AlertTitle>
                        )}
                        <AlertDescription className="tw-flex-grow">{children}</AlertDescription>
                    </Alert>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export { AlertBox };
