WiseMetering.Views.ZonePermissions = Backbone.Marionette.ItemView.extend({
    template: 'templates/zones/permissions',

    serializeData: function() {
        return {
            groups: this.model.groups().pluck('name')
        };
    },

    onShow: function() {
        const users = this.model.users();
        if (users.length !== 0) {
            this.table = new WiseMetering.Views.Table({
                id: 'groups',
                collection: users,
                modelSerializer: function(model) {
                    const json = model.toJSON();
                    json.groups = model.groups().pluck('name').join(', ');
                    return json;
                },
                columns: {
                    name: i18next.t('common.name'),
                    groups: i18next.t('glossary.groups')
                },
                toolbar_actions: {}
            });
            this.$('#permissions-table').append(this.table.el);
        }
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') this.table.remove();
    }
});
