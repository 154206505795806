module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'indicator_kind\'>' +
((__t = ( i18next.t('glossary.indicator_kind') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'indicator_kind\' name=\'indicator[kind_id]\'>\n                ';
 WiseMetering.indicatorKinds.each(function(kind) { ;
__p += '\n                    <option value="' +
((__t = ( kind.get('id') )) == null ? '' : __t) +
'">' +
((__t = ( kind.get("name") )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n        <dt>\n            <label for=\'indicator_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'indicator_name\' name=\'indicator[name]\'>\n        </dd>\n        <dt>\n            <label for=\'indicator_type\'>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'indicator_type\' name=\'indicator[type]\'>\n                <option disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                <option value=\'complex\'>' +
((__t = ( i18next.t('indicators.complex') )) == null ? '' : __t) +
'</option>\n                <option value=\'import\'>' +
((__t = ( i18next.t('indicators.import') )) == null ? '' : __t) +
'</option>\n                ';
 if(WiseMetering.currentUser.get('administrator')) { ;
__p += '\n                    <option value=\'mirror\'>' +
((__t = ( i18next.t('indicators.mirror') )) == null ? '' : __t) +
'</option>\n                ';
 } ;
__p += '\n                ';
 if(WiseMetering.currentUser.get('superuser')) { ;
__p += '\n                    <option value=\'etl\'>' +
((__t = ( i18next.t('indicators.etl') )) == null ? '' : __t) +
'</option>\n                ';
 } ;
__p += '\n            </select>\n        </dd>\n        ';
 if (isNew && !building_id) { ;
__p += '\n            <dt>\n                <label for=\'indicator_building\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <select class=\'text\' id=\'indicator_building\' name=\'indicator[building_id]\'>\n                    <option disabled selected>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n                </select>\n            </dd>\n        ';
 } ;
__p += '\n        <div id=\'type-container\'></div>\n    </dl>\n</form>\n';

}
return __p
}