WiseMetering.Views.NewInfraspeakIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration_service',
    template: 'templates/integration_services/infraspeak/new',
    title: () => i18next.t('modal.title.integration_services.new'),
    buttons: ['validate', 'save', 'cancel'],

    events: {
        'click #validate': 'validate',
    },

    ui: {
        building: '#integration_service_building_id',
        name: '#integration_service_name',
        partner: '#integration_service_partner_id',
        token: '#integration_service_token',
        url: '#integration_service_url'
    },

    initialize: function(options) {
        WiseMetering.Views.Modal.Save.prototype.initialize.call(this, options);
        this.partner = WiseMetering.integrationPartners.findWhere({slug: 'infraspeak'});
        $('#save').prop('disabled', true);
    },

    fetchExternalBuildings: function(baseUrl, token, partner) {
        const encodedBaseUrl = encodeURIComponent(baseUrl),
              encodedToken = encodeURIComponent(token),
              encodedPartner = encodeURIComponent(partner),
              url = `/integrations/external_buildings?url=${encodedBaseUrl}&token=${encodedToken}&partner_id=${encodedPartner}`;

        this.modal.block();

        $.ajax({
            url: url,
            success: function(data) {
                this.externalBuildings = data;
                $('#save').prop('disabled', false);
                this.ui.token.prop('disabled', true);
                this.ui.building.prop('disabled', false);
                this.ui.url.prop('disabled', true);
                this.ui.building.html(WiseMetering.utils.optionsFromArray(data, null, 'building_id', 'building_name'));
                this.modal.unblock();
            }.bind(this),
            error: function() {
                WiseMetering.layout.showTipper('error', i18next.t('integration_services.validation_failed'));
                $('#save').prop('disabled', true);
                this.ui.token.prop('disabled', false);
                this.ui.url.prop('disabled', false);
                this.modal.unblock();
            }.bind(this)
        });
    },

    getFormData: function() {
        const formData = this.formSerializer();
        formData.name = `Integration of ${this.partner.get('name')}'s ${this.integratedBuilgingName()} site`;
        formData.external_info = { building_id: this.ui.building.val() };
        formData.category = 'maintenance';
        formData.partner_id = this.partner.id;

        return formData;
    },

    integratedBuilgingName: function() {
        return this.ui.building.find('option:selected').text()
    },

    validate: function() {
        const baseUrl = this.ui.url.val(),
              token = this.ui.token.val(),
              partner = this.partner.id;

        if (baseUrl.length < 1 || baseUrl === i18next.t('integration_services.insert_url')) {
            $('#integration_service_url').val( i18next.t('integration_services.insert_url') )
            return;
        }

        if (token.length < 1 || token === i18next.t('integration_services.insert_token')) {
            $('#integration_service_token').val( i18next.t('integration_services.insert_token') )
            return;
        }

        this.externalBuildings = this.fetchExternalBuildings(baseUrl, token, partner);
    }
});
