WiseMetering.Views.IndexReport = Backbone.Marionette.ItemView.extend({
    collection: null,
    template: 'templates/ui/layout/empty',

    initialize: function() {
        this.collection = WiseMetering.reports;

        if (this.collection.length) {
            this.bindEvents();
        } else {
            this.collection.fetch().then(function() {
                this.bindEvents();
                this.createTable();
            }.bind(this));
        }

        Backbone.Marionette.ItemView.prototype.initialize.call(this);
    },

    bindEvents: function() {
        this.listenTo(this.collection, 'add change remove', this.createTable.bind(this));
    },

    createTable: function() {
        this.table = new WiseMetering.Views.Table({
            id: 'reports',
            collection: this.collection.findByBuildingId(this.model ? this.model.id : null),
            modelSerializer: model => {
                const
                    json = model.toJSON(),
                    timezone = json.building_id ? WiseMetering.zones.find({ id: json.building_id }).get('timezone') : WiseMetering.currentOrganization.get('timezone');

                let creator = WiseMetering.users.get(json.created_by);

                return {
                    ...json,
                    created_at: WiseMetering.utils.formatDate(json.created_at, timezone),
                    created_by: creator ? creator.get('name') : 'System'
                };
            },
            createdRow: (row, data) => {
                if (!data.available) {
                    $(row).css('cursor', 'default');
                }
            },
            sort: {
                column: 'created_at',
                order: 'desc'
            },
            columns: {
                available: {
                    title: i18next.t('common.state'),
                    className: 'center',
                    width: '5%',
                    mRender: available => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[available ? 'green' : 'red'],
                        title: i18next.t(`common.${available ? 'available' : 'unavailable'}`)
                    })
                },
                name: {
                    title: i18next.t('common.name'),
                    width: '30%'
                },
                language: {
                    title: i18next.t('common.language'),
                    width: '10%'
                },
                interval: {
                    title: i18next.t('common.interval'),
                    width: '20%'
                },
                created_by: {
                    title: i18next.t('glossary.created_by'),
                    className: 'center',
                    width: '20%'
                },
                created_at: {
                    title: i18next.t('glossary.created_at'),
                    className: 'center',
                    width: '20%'
                }
            },
            types: {
                period: 'date'
            },
            toolbar_actions: {
                select_all: {},
                show: {
                    view_call: model => {
                        if (model.get('available')) {
                            window.open(model.get('view_url'));
                        }
                    }
                },
                new: {
                    view_call: () => {
                        let newReport = new WiseMetering.Model.Report();
                        if (this.model) {
                            newReport.set({ building_id: this.model.id });
                        }
                        return new WiseMetering.Views.NewReport({
                            buildingId: this.model && this.model.id,
                            collection: this.collection,
                            isSchedule: false,
                            model: newReport,
                            title: i18next.t('reports.new_report')
                        });
                    }
                },
                delete_all: {}
            }
        });

        $(this.el).html(this.table.el);
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
        if (this.poller) {
            clearInterval(this.poller);
        }
    },

    onShow: function() {
        this.createTable();
        this.poller = setInterval(() => WiseMetering.reports.fetch(), 15000);
    }
});
