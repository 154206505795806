module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'offset\' style=\'margin-bottom: 5px;\'>\n    <select name=\'alarm[user_ids][]\' class=\'text\' style=\'display:inline-block; width: calc(100% - 31px) !important;\' ' +
((__t = ( userId ? 'disabled' : '' )) == null ? '' : __t) +
'>\n        <option value=\'\' disabled selected>- ' +
((__t = ( i18next.t('alarms.select_recipient') )) == null ? '' : __t) +
' -</option>\n        ';
 WiseMetering.users.each(function(user) { ;
__p += '\n            <option ' +
((__t = ( (user.id === userId) ? 'selected ' : '' )) == null ? '' : __t) +
'value=\'' +
((__t = ( user.id )) == null ? '' : __t) +
'\'>\n                ' +
((__t = ( user.get('name') )) == null ? '' : __t) +
'\n            </option>\n        ';
 }); ;
__p += '\n    </select>\n    <div class=\'wmui-btn rm wmui-symbol strong\' id=\'remove-notification\'>✖</div>\n</div>\n';

}
return __p
}