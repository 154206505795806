WiseMetering.Views.Ui.Widget.Distribution = WiseMetering.Views.Ui.Widget.Base.extend({
    templateID: 'templates/ui/widgets/distribution',
    colors: WiseMetering.WidgetColors.MultiColor,

    initialize: function(params) {
        WiseMetering.Views.Ui.Widget.Base.prototype.initialize.call(this, params);
    },

    legend_template: _.template('<strong><%= name %></strong>'),

    drawChart: function() {
        let that = this,
            data = _.map(that.data, function(child, index) {
                return {
                    name: child.name,
                    y: child.value,
                    color: that.colors[index]
                };
            });

        let total = data.reduce((acc, value) => acc + value.y, 0);

        that.chart = new Highcharts.Chart(jQuery.extend(true, that.defaultChartOptions, {
            exporting: {
                allowHTML: true,
                chartOptions: {
                    title: { text: that.title },
                    legend: { enabled: false }
                }
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter: function() {
                            if (this.percentage <= 0) return;
                            const position = this.point.dataLabel?.dataLabelPosition?.alignment || 'left';
                            return `
                                <div style="text-align: ${position}">
                                    ${position === 'left' ? `<span style="display: inline-block; width: 6px; height: 6px; margin: 5px; background: ${this.point.color};"></span>` : ``}
                                    <div style="display: inline-block;">
                                        <span>${this.point.name}</span><br>
                                        <span>${formatValue(this.y, 'Wh')} (${formatValue(this.percentage)}%)</span>
                                    </div>
                                    ${position === 'right' ? `<span style="display: inline-block; width: 6px; height: 6px; margin: 5px; background: ${this.point.color};"></span>` : ``}
                                </div>
                            `;
                        },
                        connectorWidth: 0
                    },
                    innerSize: '75%',
                    size: '75%'
                }
            },
            series: [{ type: 'pie', name: null, data: data }],
            title: {
                text: total ? formatValue(total, this.options.unit) : '',
                align: 'center',
                verticalAlign: 'middle',
                y: 15,
                style: {
                    fontWeight: 'bold'
                }
            },
            tooltip: {
                formatter: function() {
                    return JST['templates/ui/widgets/distribution_tooltip']({
                        fomattedValue: formatValue(this.y, 'Wh'),
                        notes: that.getNotes(this.point.name),
                        opacity: that.opacity,
                        point: this.point,
                        value: this.percentage.toFixed(2)
                    });
                }
            }
        }));
    },

    getNotes: function(name) {
        if (['Combined Zones', 'Combined Circuits'].includes(name)) {
            return i18next.t('widgets.new_metal_chart_render_notes', { type: this.type });
        } else if (name === 'Others') {
            return i18next.t('widgets.new_metal_chart_others_notes', { type: this.type });
        }
    }
});
