WiseMetering.Collection.UtilityKinds = WiseMetering.Collection.extend({
    comparator: '',
    model: WiseMetering.Model.UtilityKind,

    activeUtilityKinds: function() {
        let buildings = WiseMetering.zones.activeSites(),
            activeUtilityKinds = new Set();

        buildings.forEach(building => {
            let utilities = building.availableUtilityKinds();

            utilities.forEach(utility => {
                activeUtilityKinds.add(utility);
            });
        });
        return Array.from(activeUtilityKinds);
    },

    dashboardFormat: function() {
        return this.map(function(utilityKind) {
            let unit = utilityKind.get('unit');

            if (unit === 'kWh') {
                unit = 'Wh';
            } else if (unit === 'm3') {
                unit = 'm³';
            }

            return {
                name: utilityKind.get('name'),
                slug: utilityKind.get('slug'),
                unit
            };
        });
    }
});
