window.NumberFormatter = (function() {
    class Formatter {
        constructor(locale) {
            this.locale = locale;
        }

        // ------------------------------------------------------------------------------
        /* Formats a number to human format - this is locale dependant
         * @param number Number - mandatory
         * @param decimalPoints Number - optional (defaults to class defined value)
         * @return String number in humanized form (formated according to locale)
         *
         * Ex: In PT locale, 123456.7891 ==> "123.456,789.1"
         *     In UK locale, 123456.7891 ==> "123,456.789,1"
         */
        humanizeNumber(number, maximumFractionDigits = 2) {
            number = number.toFixed(maximumFractionDigits).toString();

            const signal = (number.charAt(0) === '-' ? '-' : '');
            const parts = number.split('.');
            const integerGroups = this.splitIntoGroups(parts[0]);
            const result = [signal, integerGroups.join(this.locale.digit_group_separator)];

            if (parts[1] !== undefined) { // has decimal part
                const decimalGroups = this.splitIntoGroups(parts[1], false);

                result.push(this.locale.decimal_separator);
                result.push(decimalGroups.join(this.locale.digit_group_separator));
            }

            return result.join('');
        }

        factorizeValue(value, powers, maxPower = 100) {
            maxPower = Math.pow(10, maxPower);
            powers = powers.filter(power => power.pow <= maxPower);

            let scale = '';
            for (let i = 0; i < powers.length; i++) {
                if (Math.abs(value) >= powers[i].pow) {
                    value /= powers[i].pow;
                    scale = powers[i].scale;
                    break;
                }
            }

            return [value, scale];
        }

        // ------------------------------------------------------------------------------
        /* Factorizes and formats a number to human format (locale dependant)
         * @param value Number - mandatory
         * @param maximumFractionDigits Number - optional (defaults to class defined value)
         * @param power Number - mandatory
         * @return String number factorized and in human format
         *
         * Ex: factorizeNumber(12345678.1234, 2) // => "12.34 M"
         */
        formatNumber(value, power, maximumFractionDigits = 2, maxPower) {
            let powers = [
                { scale: 'P', pow: Math.pow(10, 15 * power) },
                { scale: 'T', pow: Math.pow(10, 12 * power) },
                { scale: 'G', pow: Math.pow(10, 9 * power) },
                { scale: 'M', pow: Math.pow(10, 6 * power) },
                { scale: 'k', pow: Math.pow(10, 3 * power) }
            ];

            let [factorizedValue, scale] = this.factorizeValue(value, powers, maxPower);

            return `${this.humanizeNumber(factorizedValue, maximumFractionDigits)} ${scale}`;
        }

        formatMass(value, power, maximumFractionDigits = 2, maxPower) {
            const powers = [
                { scale: 't', pow: Math.pow(10, 6 * power) },
                { scale: 'kg', pow: Math.pow(10, 3 * power) },
                { scale: 'g', pow: Math.pow(10, 1 * power) }
            ];

            let [factorizedValue, scale] = this.factorizeValue(value, powers, maxPower);

            return `${this.humanizeNumber(factorizedValue, maximumFractionDigits)} ${scale}`;
        }

        formatCurrency(value, maximumFractionDigits = 2, maxPower = 0) {
            let powers = [
                { scale: 'B', pow: Math.pow(10, 9) },
                { scale: 'M', pow: Math.pow(10, 6) },
                { scale: 'K', pow: Math.pow(10, 3) }
            ];

            let [factorizedValue, scale] = this.factorizeValue(value, powers, maxPower);

            return `${this.humanizeNumber(factorizedValue, maximumFractionDigits)}${scale}`;
        }

        // ------------------------------------------------------------------------------
        /* Find the exponential value of a unit
         * @param unit String
         * @return Number
         *
         * This is just to use with physical quantities, the max exponential is 3!
         *
         * Ex: getPower("m³") // => "3"
         */
        getPower(unit) {
            if (unit === undefined || unit === null) {
                return 1;
            } else if (unit.indexOf('²') !== -1) {
                return 2;
            } else if (unit.indexOf('³') !== -1) {
                return 3;
            }

            return 1;
        }

        // ------------------------------------------------------------------------------
        /* Reverses a string
         * @param str String - mandatory
         * @return String - reversed version of the string
         *
         * Ex: reverseString("hello world") // => "dlrow olleh"
         */
        reverseString(str) {
            return str.split('').reverse().join('');
        }

        // ------------------------------------------------------------------------------
        /* Splits numbers into groups of given length. By default, operates right-to-left
         * @param number Number or String - mandatory
         * @param rtl boolean - optional (defaults to true)
         * @param groupLength Number - optional (defaults to 3)
         * @return Array of Strings - Array containing the number split into groups of
         *                            groupLength size
         *
         * Ex: splitIntoGroups(12345678)        // => ["12","345","678"]
         *     splitIntoGroups(12345678, false) // => ["123","456","78"]
         */
        splitIntoGroups(number, rtl = true) {
            number = number.toString();
            if (rtl) {
                number = this.reverseString(number);
            }

            const revGroups = number.match(new RegExp(/\d{1,3}/, 'g'));

            if (!revGroups) {
                return [];
            }

            return revGroups.reduce(function(acc, val) {
                if (rtl) {
                    acc.unshift(this.reverseString(val));
                } else {
                    acc.push(val);
                }

                return acc;
            }.bind(this), []);
        }

        seconds(value) {
            return `${this.humanizeNumber(value / 3600)} h`;
        }

        // ------------------------------------------------------------------------------
        currency(value, _, maximumFractionDigits = 2, maxPower = 0) {
            value = this.formatCurrency(value, maximumFractionDigits, maxPower);

            return this.locale.currency.template({ value: value });
        }

        // ------------------------------------------------------------------------------
        simple(value, unit, maximumFractionDigits = 2) {
            value = this.humanizeNumber(value, maximumFractionDigits);

            if (unit === 'N/A') {
                return value;
            } else {
                return [value, ' ', unit].join('').trim();
            }
        }

        // ------------------------------------------------------------------------------
        factorization(value, unit, maximumFractionDigits = 2, maxPower) {
            const power = this.getPower(unit);

            if (unit === 'g') {
                value = this.formatMass(value, power, maximumFractionDigits, maxPower);
            } else {
                value = this.formatNumber(value, power, maximumFractionDigits, maxPower);
            }

            if (unit !== undefined && unit !== null && unit !== 'g') {
                value = value.replace(/( ?[A-Za-z]|)$/, function(_, p) {
                    return ' ' + p.trim() + unit;
                });
            }

            return value;
        }
    }

    return Formatter;
})();
