WiseMetering.Views.EditContract = WiseMetering.Views.Modal.Save.extend({
    form_prefix: 'contract',
    template: 'templates/contracts/edit',
    title: () => i18next.t('modal.title.contracts.edit'),
    ui: {
        finish: '#contract-finish'
    },

    onRender: function() {
        if (this.model.utility().contracts().last().id === this.model.id) {
            this.ui.finish.attr('disabled', false);
        }
    },

    serializeData: function() {
        const json = this.model.toJSON();
        if (json.extra) {
            Object.entries(json.extra).map(([key, value]) => {
                json.extra[key] = value / 1000;
            });
        }
        return json;
    }
});
