module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'zone_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'zone_name\' name=\'zone[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'/>\n        </dd>\n\n        <dt>\n            <label for=\'zone_description\'>' +
((__t = ( i18next.t('common.description') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <textarea class=\'text small\' id=\'zone_description\' name=\'zone[description]\'></textarea>\n        </dd>\n\n        <dt>\n            <label for=\'zone_category_id\'>' +
((__t = ( i18next.t('common.category') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'zone_category_id\' name=\'zone[category_id]\'>\n                <option selected disabled>' +
((__t = ( i18next.t('ui.please_select_option') )) == null ? '' : __t) +
'</option>\n            </select>\n        </dd>\n        <input name=\'zone[organization_id]\' type=\'hidden\' value=\'' +
((__t = ( WiseMetering.currentOrganization.id )) == null ? '' : __t) +
'\'>\n\n        <dt>\n            <label for=\'zone_area\'>' +
((__t = ( i18next.t('common.area') )) == null ? '' : __t) +
' (m2)</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'zone_area\' name=\'zone[area]\' type=\'text\' value=\'' +
((__t = ( area )) == null ? '' : __t) +
'\'/>\n        </dd>\n\n        <dt>\n            <label for=\'zone_capacity\'>' +
((__t = ( i18next.t('common.capacity') )) == null ? '' : __t) +
' (' +
((__t = ( i18next.t('common.persons') )) == null ? '' : __t) +
')</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'zone_capacity\' name=\'zone[capacity]\' type=\'text\' value=\'' +
((__t = ( capacity )) == null ? '' : __t) +
'\'/>\n        </dd>\n\n        ';
 if (WiseMetering.buildingTags.length) { ;
__p += '\n            <dt>\n                <label for=\'zone_tag_ids\'>' +
((__t = ( i18next.t('glossary.building_tags') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                ';
 WiseMetering.buildingTags.forEach(function(tag) { ;
__p += '\n                    <dt style="margin-top: 5px !important;">\n                        <input ' +
((__t = ( tag_ids.includes(tag.id) ? 'checked' : '' )) == null ? '' : __t) +
' class="vertical-middle" id="tag_' +
((__t = ( tag.id )) == null ? '' : __t) +
'" name="zone[tag_ids][' +
((__t = ( tag.id )) == null ? '' : __t) +
']" type="checkbox" value="';
 tag.id ;
__p += '">\n                        <label for="tag_' +
((__t = ( tag.id )) == null ? '' : __t) +
'">' +
((__t = ( tag.get('name') )) == null ? '' : __t) +
'</label>\n                    </dt>\n                ';
 }) ;
__p += '\n            </dd>\n        ';
 } ;
__p += '\n\n       <dt>\n            <label class=\'decapitalize\' for="building-image">' +
((__t = ( i18next.t('buildings.image_label') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input accept="image/png, image/jpeg, image/jpg" id="building-image" class=\'input-image text\' type=\'file\'>\n        </dd>\n\n        <input id=\'zone_latitude\' name=\'zone[latitude]\' type=\'hidden\' value=\'' +
((__t = ( latitude )) == null ? '' : __t) +
'\'>\n        <input id=\'zone_longitude\' name=\'zone[longitude]\' type=\'hidden\' value=\'' +
((__t = ( longitude )) == null ? '' : __t) +
'\'>\n\n        <dt>\n            <label for=\'address\'>' +
((__t = ( i18next.t('common.address') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'address\' placeholder=\'' +
((__t = ( i18next.t('zones.please_insert_an_address') )) == null ? '' : __t) +
'\'\n                    style=\'width: 536px;\' value=\'' +
((__t = ( address ? address.street : '' )) == null ? '' : __t) +
'\'>\n        </dd>\n\n        <dt>\n            <label>' +
((__t = ( i18next.t('common.map') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <div id=\'map_canvas\' style=\'height:400px;\'></div>\n        </dd>\n        <dd class=\'last\'>\n            ' +
((__t = ( i18next.t('zones.please_drag_the_marker') )) == null ? '' : __t) +
'\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}