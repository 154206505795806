module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'company_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'company_name\' name=\'company[name]\' type=\'text\'>\n        </dd>\n\n        <dt>\n            <label for=\'company_address\'>' +
((__t = ( i18next.t('common.address') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'company_address\' name=\'company[address]\' type=\'text\'>\n        </dd>\n\n        <dt>\n            <label for=\'company_postal_code\'>' +
((__t = ( i18next.t('common.postal_code') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'company_postal_code\' name=\'company[postal_code]\' type=\'text\'>\n        </dd>\n\n        <dt>\n            <label for=\'company_city\'>' +
((__t = ( i18next.t('common.city') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'company_city\' name=\'company[city]\' type=\'text\'>\n        </dd>\n\n        <dt>\n            <label for=\'company_country\'>' +
((__t = ( i18next.t('common.country') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'company_country\' name=\'company[country]\' type=\'text\'>\n        </dd>\n\n        <dt>\n            <label for=\'company_vat\'>' +
((__t = ( i18next.t('common.vat_number') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'company_vat\' name=\'company[vat]\' type=\'text\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}