WiseMetering.Views.IndexInactiveTenantsByOrganization = WiseMetering.Views.TableIndex.extend({
    icon: 'tenants',
    name: () => i18next.t('glossary.tenants'),

    createTable: function createTable() {
        let collection = WiseMetering.tenants.inactive();

        return new WiseMetering.Views.Table({
            id: 'tenants',
            collection: collection,
            columns: {
                name: i18next.t('common.name'),
                building_id: {
                    title: i18next.t('glossary.building'),
                    mRender: function(building_id) {
                        const building = WiseMetering.zones.get(building_id);
                        return building ? building.get('name') : '-';
                    }
                },
                company_id: {
                    title: i18next.t('glossary.company'),
                    mRender: function(company_id) {
                        const company = WiseMetering.companies.get(company_id);
                        return company.get('name');
                    }
                },
                area: {
                    title: i18next.t('common.area'),
                    className: 'align-right'
                }
            },
            sort: {
                column: 'from',
                order: 'desc'
            },
            toolbar_actions: {
                new: {
                    view_call: () => {
                        new WiseMetering.Views.AddTenant({
                            collection: WiseMetering.tenants,
                            model: new WiseMetering.Model.Tenant({
                                organization_id: WiseMetering.currentOrganization.id
                            })
                        });
                    }
                },
                edit: {
                    view_call: model => {
                        model.fetch({
                            success: model => new WiseMetering.Views.EditTenant({ model: model })
                        });
                    }
                },
                delete: {
                    view_call: model => new WiseMetering.Views.DestroyTenant({ model })
                },
                show: {
                    view_call: model => Backbone.history.navigate(model.allTenantsNodeUrl(), { trigger: true })
                }
            }
        });
    }
});
